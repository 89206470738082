// source: Wilqo_API_Mortgage_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Models_CustomWrappers_pb = require('./Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('./Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_BrandAdminModels_pb = require('./Wilqo.Shared.Models/BrandAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_BrandAdminModels_pb);
var Wilqo_Shared_Models_WilqoPartyType_pb = require('./Wilqo.Shared.Models/WilqoPartyType_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyType_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('./Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage.ActiveBPD', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ActivityConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddressFormatEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AddressUsage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AmortizationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AppraisalManualTriggerPriorityEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AssetBankRetirementEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AssetOwner', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusProvider', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusProviderOptions', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusReport', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusResult', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusResultTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusSelectedSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusUxDisplay', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AusUxOption', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.AutomatedUnderwriting', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Badge', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BankRetirementAsset', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BooleanTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerApplicationInformation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerAssets', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerCreditSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerDetailsInformation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerDetailsSectionEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerExpenses', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerIncomeSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerIntentToProceed', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerMilitaryService', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerPersonalInformation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerREOSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerResidencyBasisEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BorrowerResidencyEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.BuyingProcessStageEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CalculationListItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CardListItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ChangeHistoryItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CitizenshipResidencyEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CommunityLendingProductType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceCondition', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceConditionSeverityEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestListItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestSourceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestSuite', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestSuitePurposeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ComplianceTestSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ConditionSatisfactionResponsiblePartyEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ConditionSatisfactionTimeframeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ConditionStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ConditionSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ContactMethod', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditBorrower', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditBureausEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditCardOtherDebtLeaseEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditPullTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditReport', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditReportOnEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditReportSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditRequestTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CreditVendorEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.CurrentFee', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DOTItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DOTSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DOTSideSheetSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealCommentAuthor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealComments', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealMortgageTenancy', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealScope', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DealSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosureDeliveryMethodEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosureDocument', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryMethodEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosurePackagesDocument', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisclosureRequestEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DisplayType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Document', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentCategory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentCategoryEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentCategorySummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentEnumDescriptionValue', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentFormSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentSourceType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DocumentsSubscription', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.DomesticRelationshipEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EmployerInBorrower', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EmploymentClassificationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EmploymentDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EmploymentInformation', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EmploymentStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EscrowItemEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.EscrowPremiumRatePercentBasisEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ExpanderDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ExpanderDetailItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Fee', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeCalculationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeePaymentPaidByEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeePercentBasisEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeSectionTotal', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeSourceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeToleranceCategoryType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeeTotalSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSectionType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesComparisonDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesComparisonFee', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesComparisonSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesOriginationDiscountSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesOriginationDiscountSectionType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FeesTotalSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FloodProductEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FraudContentPanel', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.FraudRoleEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GetLendersResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.GovernmentRefinanceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IncomeDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IncomeSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IndexEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Individual', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IndividualEscrowPayment', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IndividualFeePayment', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IndividualFeeTotalSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IndividualPrepaidPayment', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InfoCard', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.InfoCardItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IntegrationHistory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IntegrationTypeLocalEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IntegrationVendorEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.IntentToProceedCommunicationMethodEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.JointAssetLiabilityReportingEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Lender', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LenderCondition', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LenderConditionMapping', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LenderOption', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LiabilityOwner', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LienPriorityEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanAppraisalDataSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanAsset', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanAssetSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanAvailableFeeInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanBorrower', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanConsumer', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanDisclosureDataSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanEscrowFee', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanEscrowFeeCategory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanFee', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanFeeCategory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanFeeCategoryTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanFeeDataSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanFloodDataSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanGroupEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanLender', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanLiability', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanLiabilitySummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanOriginator', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanPhaseEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanPrepaidFee', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanPrepaidFeeCategory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanProgressEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanPurposeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanSelectedProduct', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanSummaryGroup', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.LoanTermType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.MIDurationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.MIPremiumSourceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.MaritalStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.MismoType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.MortgageEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.MortgageNotificationMethod', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Name', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.NotificationSourceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ObjectStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ObjectStatusMessageTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Originator', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OtherAssetAndCredit', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OtherAssetAndCredit.AssetTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OtherAssetCreditTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OtherLiabilitiesAndExpensesTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OwnedPropertyDispositionStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OwnedPropertyHasRentalIncome', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OwnedPropertyLienInstallmentItemization', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OwnedPropertyMaintenanceExpenseItemization', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.OwnedPropertyRentalIncomeDocumentationSource', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PaidBy', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PaidByItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PanelDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartyRoleEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartySharedInfoCard', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartySourceTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartySummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartySummaryLink', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartyType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PartyTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PreQualSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PrepaidItemEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PrepaidItemPerDiemCalculationMethodEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProcessNotification', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProcessNotification.NotificationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProductChannel', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProductPriceProductStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProductPriceTargetEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProductPriceVendorEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProductPricingSearchParams', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProductSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressItemStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressItemSubscriptionContext', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressSectionDocument', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressSectionIconProperties', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressSectionItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProgressSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProjectDesignEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ProjectLegalStructureEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PropertyCurrentUsageEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PropertyDispositionStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PropertyTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.PropertyUsageEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RateDetail', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RateStatus', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RealEstateOwned', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RecordOwner', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RefinanceCashOutDeterminationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ResidenceOwner', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.RolesAndInformationInParty', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SampleDealProfileIdentifierEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SimpleOption', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.StorageTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.SubTextItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.Thread', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.ThreadComment', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TotalRequiredAndLenderCure', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TridSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TridTracking', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TridTrackingBorrowerSummary', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.TridTrackingStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UpsertEmployer', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UxDisplayBadge', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.UxDisplayStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.VerifiedListItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.WilqoPartyCategory', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanLender = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanLender.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanLender, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanLender.displayName = 'proto.wilqo.api.mortgage.LoanLender';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AutomatedUnderwriting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AutomatedUnderwriting.displayName = 'proto.wilqo.api.mortgage.AutomatedUnderwriting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ConditionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ConditionSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ConditionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ConditionSummary.displayName = 'proto.wilqo.api.mortgage.ConditionSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanSummary.displayName = 'proto.wilqo.api.mortgage.LoanSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProgressSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ProgressSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProgressSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProgressSummary.displayName = 'proto.wilqo.api.mortgage.ProgressSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProgressSectionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ProgressSectionItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProgressSectionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProgressSectionItem.displayName = 'proto.wilqo.api.mortgage.ProgressSectionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.displayName = 'proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DisclosurePackagesDocument.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DisclosurePackagesDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DisclosurePackagesDocument.displayName = 'proto.wilqo.api.mortgage.DisclosurePackagesDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProgressSectionDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProgressSectionDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProgressSectionDocument.displayName = 'proto.wilqo.api.mortgage.ProgressSectionDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProgressSectionIconProperties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProgressSectionIconProperties.displayName = 'proto.wilqo.api.mortgage.ProgressSectionIconProperties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanConsumer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanConsumer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanConsumer.displayName = 'proto.wilqo.api.mortgage.LoanConsumer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanBorrower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanBorrower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanBorrower.displayName = 'proto.wilqo.api.mortgage.LoanBorrower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CreditBorrower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CreditBorrower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CreditBorrower.displayName = 'proto.wilqo.api.mortgage.CreditBorrower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CreditReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CreditReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CreditReport.displayName = 'proto.wilqo.api.mortgage.CreditReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusReport.displayName = 'proto.wilqo.api.mortgage.AusReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DocumentSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentSummary.displayName = 'proto.wilqo.api.mortgage.DocumentSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.displayName = 'proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Badge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Badge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Badge.displayName = 'proto.wilqo.api.mortgage.Badge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Originator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Originator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Originator.displayName = 'proto.wilqo.api.mortgage.Originator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ActiveBPD = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ActiveBPD, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ActiveBPD.displayName = 'proto.wilqo.api.mortgage.ActiveBPD';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RecordOwner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.RecordOwner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RecordOwner.displayName = 'proto.wilqo.api.mortgage.RecordOwner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealMortgageTenancy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DealMortgageTenancy.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealMortgageTenancy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealMortgageTenancy.displayName = 'proto.wilqo.api.mortgage.DealMortgageTenancy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanOriginator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanOriginator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanOriginator.displayName = 'proto.wilqo.api.mortgage.LoanOriginator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Individual = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Individual, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Individual.displayName = 'proto.wilqo.api.mortgage.Individual';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ContactMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ContactMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ContactMethod.displayName = 'proto.wilqo.api.mortgage.ContactMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Name = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Name, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Name.displayName = 'proto.wilqo.api.mortgage.Name';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Lender = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Lender, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Lender.displayName = 'proto.wilqo.api.mortgage.Lender';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PartySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PartySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PartySummary.displayName = 'proto.wilqo.api.mortgage.PartySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ComplianceTestSuite = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ComplianceTestSuite.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ComplianceTestSuite, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ComplianceTestSuite.displayName = 'proto.wilqo.api.mortgage.ComplianceTestSuite';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ComplianceTestSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ComplianceTestSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ComplianceTestSummary.displayName = 'proto.wilqo.api.mortgage.ComplianceTestSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ComplianceTest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ComplianceTest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ComplianceTest.displayName = 'proto.wilqo.api.mortgage.ComplianceTest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ComplianceCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ComplianceCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ComplianceCondition.displayName = 'proto.wilqo.api.mortgage.ComplianceCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UxDisplayBadge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UxDisplayBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UxDisplayBadge.displayName = 'proto.wilqo.api.mortgage.UxDisplayBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ComplianceTestListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ComplianceTestListItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ComplianceTestListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ComplianceTestListItem.displayName = 'proto.wilqo.api.mortgage.ComplianceTestListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ChangeHistoryItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ChangeHistoryItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ChangeHistoryItem.displayName = 'proto.wilqo.api.mortgage.ChangeHistoryItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProgressItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ProgressItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProgressItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProgressItem.displayName = 'proto.wilqo.api.mortgage.ProgressItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SubTextItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SubTextItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SubTextItem.displayName = 'proto.wilqo.api.mortgage.SubTextItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PartySummaryLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PartySummaryLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PartySummaryLink.displayName = 'proto.wilqo.api.mortgage.PartySummaryLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IntegrationHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.IntegrationHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IntegrationHistory.displayName = 'proto.wilqo.api.mortgage.IntegrationHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeeSummary.displayName = 'proto.wilqo.api.mortgage.FeeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Fee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.Fee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Fee.displayName = 'proto.wilqo.api.mortgage.Fee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BankRetirementAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BankRetirementAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BankRetirementAsset.displayName = 'proto.wilqo.api.mortgage.BankRetirementAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.OtherAssetAndCredit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.OtherAssetAndCredit.displayName = 'proto.wilqo.api.mortgage.OtherAssetAndCredit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.displayName = 'proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.displayName = 'proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TridSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TridSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TridSummary.displayName = 'proto.wilqo.api.mortgage.TridSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusUxDisplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AusUxDisplay.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusUxDisplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusUxDisplay.displayName = 'proto.wilqo.api.mortgage.AusUxDisplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusResult.displayName = 'proto.wilqo.api.mortgage.AusResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusUxOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusUxOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusUxOption.displayName = 'proto.wilqo.api.mortgage.AusUxOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusProviderOptions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.AusProviderOptions.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusProviderOptions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusProviderOptions.displayName = 'proto.wilqo.api.mortgage.AusProviderOptions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PaidByItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PaidByItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PaidByItem.displayName = 'proto.wilqo.api.mortgage.PaidByItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PaidBy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PaidBy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PaidBy.displayName = 'proto.wilqo.api.mortgage.PaidBy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeeItem.displayName = 'proto.wilqo.api.mortgage.FeeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeeSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FeeSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeeSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeeSection.displayName = 'proto.wilqo.api.mortgage.FeeSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DocumentCategory.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentCategory.displayName = 'proto.wilqo.api.mortgage.DocumentCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocType.displayName = 'proto.wilqo.api.mortgage.DocType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentEnumDescriptionValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.displayName = 'proto.wilqo.api.mortgage.DocumentEnumDescriptionValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PartyType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PartyType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PartyType.displayName = 'proto.wilqo.api.mortgage.PartyType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanFee.displayName = 'proto.wilqo.api.mortgage.LoanFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanFeeCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanFeeCategory.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanFeeCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanFeeCategory.displayName = 'proto.wilqo.api.mortgage.LoanFeeCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanFeeDataSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanFeeDataSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanFeeDataSummary.displayName = 'proto.wilqo.api.mortgage.LoanFeeDataSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanFloodDataSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanFloodDataSummary.displayName = 'proto.wilqo.api.mortgage.LoanFloodDataSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.MismoType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.MismoType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.MismoType.displayName = 'proto.wilqo.api.mortgage.MismoType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.RolesAndInformationInParty.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.RolesAndInformationInParty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RolesAndInformationInParty.displayName = 'proto.wilqo.api.mortgage.RolesAndInformationInParty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentCategorySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DocumentCategorySummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentCategorySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentCategorySummary.displayName = 'proto.wilqo.api.mortgage.DocumentCategorySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.Document.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Document.displayName = 'proto.wilqo.api.mortgage.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DisclosureDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DisclosureDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DisclosureDocument.displayName = 'proto.wilqo.api.mortgage.DisclosureDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProductSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProductSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProductSummary.displayName = 'proto.wilqo.api.mortgage.ProductSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanSelectedProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanSelectedProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanSelectedProduct.displayName = 'proto.wilqo.api.mortgage.LoanSelectedProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RateDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.RateDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RateDetail.displayName = 'proto.wilqo.api.mortgage.RateDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.displayName = 'proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerIntentToProceed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerIntentToProceed.displayName = 'proto.wilqo.api.mortgage.BorrowerIntentToProceed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FraudContentPanel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FraudContentPanel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FraudContentPanel.displayName = 'proto.wilqo.api.mortgage.FraudContentPanel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SimpleOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.SimpleOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SimpleOption.displayName = 'proto.wilqo.api.mortgage.SimpleOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.TridTrackingBorrowerSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.displayName = 'proto.wilqo.api.mortgage.TridTrackingBorrowerSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TridTracking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.TridTracking.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.TridTracking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TridTracking.displayName = 'proto.wilqo.api.mortgage.TridTracking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanAppraisalDataSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanAppraisalDataSummary.displayName = 'proto.wilqo.api.mortgage.LoanAppraisalDataSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanDisclosureDataSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanDisclosureDataSummary.displayName = 'proto.wilqo.api.mortgage.LoanDisclosureDataSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AusSelectedSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AusSelectedSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AusSelectedSummary.displayName = 'proto.wilqo.api.mortgage.AusSelectedSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerPersonalInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerPersonalInformation.displayName = 'proto.wilqo.api.mortgage.BorrowerPersonalInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerMilitaryService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerMilitaryService.displayName = 'proto.wilqo.api.mortgage.BorrowerMilitaryService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.BorrowerDetailsInformation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerDetailsInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerDetailsInformation.displayName = 'proto.wilqo.api.mortgage.BorrowerDetailsInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerIncomeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerIncomeSummary.displayName = 'proto.wilqo.api.mortgage.BorrowerIncomeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerAssets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerAssets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerAssets.displayName = 'proto.wilqo.api.mortgage.BorrowerAssets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerCreditSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerCreditSummary.displayName = 'proto.wilqo.api.mortgage.BorrowerCreditSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerExpenses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerExpenses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerExpenses.displayName = 'proto.wilqo.api.mortgage.BorrowerExpenses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerREOSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerREOSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerREOSummary.displayName = 'proto.wilqo.api.mortgage.BorrowerREOSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.BorrowerApplicationInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.BorrowerApplicationInformation.displayName = 'proto.wilqo.api.mortgage.BorrowerApplicationInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanAssetSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanAssetSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanAssetSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanAssetSummary.displayName = 'proto.wilqo.api.mortgage.LoanAssetSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanAsset.displayName = 'proto.wilqo.api.mortgage.LoanAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IncomeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.IncomeSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.IncomeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IncomeSummary.displayName = 'proto.wilqo.api.mortgage.IncomeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IncomeDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.IncomeDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IncomeDetail.displayName = 'proto.wilqo.api.mortgage.IncomeDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.EmploymentInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.EmploymentInformation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.EmploymentInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.EmploymentInformation.displayName = 'proto.wilqo.api.mortgage.EmploymentInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.EmploymentDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.EmploymentDetail.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.EmploymentDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.EmploymentDetail.displayName = 'proto.wilqo.api.mortgage.EmploymentDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.EmployerInBorrower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.EmployerInBorrower.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.EmployerInBorrower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.EmployerInBorrower.displayName = 'proto.wilqo.api.mortgage.EmployerInBorrower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.UpsertEmployer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.UpsertEmployer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.UpsertEmployer.displayName = 'proto.wilqo.api.mortgage.UpsertEmployer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InfoCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.InfoCard.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.InfoCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InfoCard.displayName = 'proto.wilqo.api.mortgage.InfoCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.InfoCardItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.InfoCardItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.InfoCardItem.displayName = 'proto.wilqo.api.mortgage.InfoCardItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IndividualFeePayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.IndividualFeePayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IndividualFeePayment.displayName = 'proto.wilqo.api.mortgage.IndividualFeePayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.IndividualEscrowPayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IndividualEscrowPayment.displayName = 'proto.wilqo.api.mortgage.IndividualEscrowPayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.IndividualPrepaidPayment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IndividualPrepaidPayment.displayName = 'proto.wilqo.api.mortgage.IndividualPrepaidPayment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CardListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CardListItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CardListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CardListItem.displayName = 'proto.wilqo.api.mortgage.CardListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.VerifiedListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.VerifiedListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.VerifiedListItem.displayName = 'proto.wilqo.api.mortgage.VerifiedListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CalculationListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CalculationListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CalculationListItem.displayName = 'proto.wilqo.api.mortgage.CalculationListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CurrentFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CurrentFee.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CurrentFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CurrentFee.displayName = 'proto.wilqo.api.mortgage.CurrentFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.IndividualFeeTotalSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.IndividualFeeTotalSummary.displayName = 'proto.wilqo.api.mortgage.IndividualFeeTotalSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ExpanderDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.ExpanderDetail.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.ExpanderDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ExpanderDetail.displayName = 'proto.wilqo.api.mortgage.ExpanderDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ExpanderDetailItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ExpanderDetailItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ExpanderDetailItem.displayName = 'proto.wilqo.api.mortgage.ExpanderDetailItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PanelDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.PanelDetail.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.PanelDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PanelDetail.displayName = 'proto.wilqo.api.mortgage.PanelDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeeSectionTotal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeeSectionTotal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeeSectionTotal.displayName = 'proto.wilqo.api.mortgage.FeeSectionTotal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeeTotalSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FeeTotalSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeeTotalSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeeTotalSummary.displayName = 'proto.wilqo.api.mortgage.FeeTotalSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DOTSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DOTSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DOTSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DOTSection.displayName = 'proto.wilqo.api.mortgage.DOTSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DOTItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DOTItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DOTItem.displayName = 'proto.wilqo.api.mortgage.DOTItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanAvailableFeeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanAvailableFeeInfo.displayName = 'proto.wilqo.api.mortgage.LoanAvailableFeeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealScope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealScope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealScope.displayName = 'proto.wilqo.api.mortgage.DealScope';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanLiabilitySummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanLiabilitySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanLiabilitySummary.displayName = 'proto.wilqo.api.mortgage.LoanLiabilitySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanLiability = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanLiability, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanLiability.displayName = 'proto.wilqo.api.mortgage.LoanLiability';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeesTotalSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeesTotalSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeesTotalSummary.displayName = 'proto.wilqo.api.mortgage.FeesTotalSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanSummaryGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanSummaryGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanSummaryGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanSummaryGroup.displayName = 'proto.wilqo.api.mortgage.LoanSummaryGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PartySharedInfoCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.PartySharedInfoCard.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.PartySharedInfoCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PartySharedInfoCard.displayName = 'proto.wilqo.api.mortgage.PartySharedInfoCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.displayName = 'proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.AssetOwner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.AssetOwner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.AssetOwner.displayName = 'proto.wilqo.api.mortgage.AssetOwner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LiabilityOwner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LiabilityOwner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LiabilityOwner.displayName = 'proto.wilqo.api.mortgage.LiabilityOwner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ResidenceOwner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ResidenceOwner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ResidenceOwner.displayName = 'proto.wilqo.api.mortgage.ResidenceOwner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.RealEstateOwned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.RealEstateOwned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.RealEstateOwned.displayName = 'proto.wilqo.api.mortgage.RealEstateOwned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanEscrowFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanEscrowFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanEscrowFee.displayName = 'proto.wilqo.api.mortgage.LoanEscrowFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanEscrowFeeCategory.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanEscrowFeeCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanEscrowFeeCategory.displayName = 'proto.wilqo.api.mortgage.LoanEscrowFeeCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanPrepaidFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanPrepaidFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanPrepaidFee.displayName = 'proto.wilqo.api.mortgage.LoanPrepaidFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LoanPrepaidFeeCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.displayName = 'proto.wilqo.api.mortgage.LoanPrepaidFeeCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DOTSideSheetSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DOTSideSheetSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DOTSideSheetSummary.displayName = 'proto.wilqo.api.mortgage.DOTSideSheetSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LenderCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.LenderCondition.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.LenderCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LenderCondition.displayName = 'proto.wilqo.api.mortgage.LenderCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LenderConditionMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LenderConditionMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LenderConditionMapping.displayName = 'proto.wilqo.api.mortgage.LenderConditionMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ActivityConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ActivityConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ActivityConfig.displayName = 'proto.wilqo.api.mortgage.ActivityConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CreditReportSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CreditReportSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CreditReportSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CreditReportSummary.displayName = 'proto.wilqo.api.mortgage.CreditReportSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentFormSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DocumentFormSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentFormSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentFormSection.displayName = 'proto.wilqo.api.mortgage.DocumentFormSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProgressItemSubscriptionContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.displayName = 'proto.wilqo.api.mortgage.ProgressItemSubscriptionContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealSummary.displayName = 'proto.wilqo.api.mortgage.DealSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.LenderOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.LenderOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.LenderOption.displayName = 'proto.wilqo.api.mortgage.LenderOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.GetLendersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.GetLendersResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.GetLendersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.GetLendersResponse.displayName = 'proto.wilqo.api.mortgage.GetLendersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.PreQualSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.PreQualSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.PreQualSummary.displayName = 'proto.wilqo.api.mortgage.PreQualSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.displayName = 'proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeesComparisonDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeesComparisonDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeesComparisonDetail.displayName = 'proto.wilqo.api.mortgage.FeesComparisonDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeesComparisonFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeesComparisonFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeesComparisonFee.displayName = 'proto.wilqo.api.mortgage.FeesComparisonFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeesComparisonSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FeesComparisonSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeesComparisonSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeesComparisonSection.displayName = 'proto.wilqo.api.mortgage.FeesComparisonSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.TotalRequiredAndLenderCure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.displayName = 'proto.wilqo.api.mortgage.TotalRequiredAndLenderCure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.displayName = 'proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.FeesOriginationDiscountSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.FeesOriginationDiscountSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.FeesOriginationDiscountSection.displayName = 'proto.wilqo.api.mortgage.FeesOriginationDiscountSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProcessNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProcessNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProcessNotification.displayName = 'proto.wilqo.api.mortgage.ProcessNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ProductPricingSearchParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ProductPricingSearchParams.displayName = 'proto.wilqo.api.mortgage.ProductPricingSearchParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealComments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DealComments.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealComments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealComments.displayName = 'proto.wilqo.api.mortgage.DealComments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.Thread = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.Thread.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.Thread, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.Thread.displayName = 'proto.wilqo.api.mortgage.Thread';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.ThreadComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.ThreadComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.ThreadComment.displayName = 'proto.wilqo.api.mortgage.ThreadComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DealCommentAuthor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.DealCommentAuthor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DealCommentAuthor.displayName = 'proto.wilqo.api.mortgage.DealCommentAuthor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentsSubscription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DocumentsSubscription.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentsSubscription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentsSubscription.displayName = 'proto.wilqo.api.mortgage.DocumentsSubscription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.displayName = 'proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.WilqoPartyCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.WilqoPartyCategory.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.WilqoPartyCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.WilqoPartyCategory.displayName = 'proto.wilqo.api.mortgage.WilqoPartyCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.displayName = 'proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.displayName = 'proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanLender.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanLender.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanLender} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanLender.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    questionnaireSubmitted: (f = msg.getQuestionnaireSubmitted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    loanVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    loanPurpose: jspb.Message.getFieldWithDefault(msg, 7, 0),
    monthlyPayment: jspb.Message.getFieldWithDefault(msg, 8, 0),
    monthlyPaymentTaxesInsurance: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loanAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    downPayment: (f = msg.getDownPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    debtToIncomeRatio: (f = msg.getDebtToIncomeRatio()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanToValueRatio: (f = msg.getLoanToValueRatio()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    productName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    interestRate: (f = msg.getInterestRate()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalFees: jspb.Message.getFieldWithDefault(msg, 16, 0),
    totalPrepaids: jspb.Message.getFieldWithDefault(msg, 17, 0),
    propertyType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    propertyUsage: jspb.Message.getFieldWithDefault(msg, 19, 0),
    propertyState: jspb.Message.getFieldWithDefault(msg, 20, ""),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.wilqo.api.mortgage.ConditionSummary.toObject, includeInstance),
    loanNumber: jspb.Message.getFieldWithDefault(msg, 22, ""),
    automatedUnderwriting: (f = msg.getAutomatedUnderwriting()) && proto.wilqo.api.mortgage.AutomatedUnderwriting.toObject(includeInstance, f),
    loanPhaseType: jspb.Message.getFieldWithDefault(msg, 24, 0),
    tridConditionsMetDate: (f = msg.getTridConditionsMetDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanLender}
 */
proto.wilqo.api.mortgage.LoanLender.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanLender;
  return proto.wilqo.api.mortgage.LoanLender.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanLender} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanLender}
 */
proto.wilqo.api.mortgage.LoanLender.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQuestionnaireSubmitted(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (reader.readEnum());
      msg.setLoanPurpose(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyPayment(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyPaymentTaxesInsurance(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoanAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setDownPayment(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setDebtToIncomeRatio(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLoanToValueRatio(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 15:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setInterestRate(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalFees(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPrepaids(value);
      break;
    case 18:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyTypeEnum} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    case 19:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (reader.readEnum());
      msg.setPropertyUsage(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyState(value);
      break;
    case 21:
      var value = new proto.wilqo.api.mortgage.ConditionSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.ConditionSummary.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanNumber(value);
      break;
    case 23:
      var value = new proto.wilqo.api.mortgage.AutomatedUnderwriting;
      reader.readMessage(value,proto.wilqo.api.mortgage.AutomatedUnderwriting.deserializeBinaryFromReader);
      msg.setAutomatedUnderwriting(value);
      break;
    case 24:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanPhaseEnum} */ (reader.readEnum());
      msg.setLoanPhaseType(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTridConditionsMetDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanLender.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanLender} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanLender.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuestionnaireSubmitted();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLoanVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLoanPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMonthlyPaymentTaxesInsurance();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLoanAmount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDownPayment();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getDebtToIncomeRatio();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanToValueRatio();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInterestRate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalFees();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getTotalPrepaids();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getPropertyUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getPropertyState();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.wilqo.api.mortgage.ConditionSummary.serializeBinaryToWriter
    );
  }
  f = message.getLoanNumber();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAutomatedUnderwriting();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.wilqo.api.mortgage.AutomatedUnderwriting.serializeBinaryToWriter
    );
  }
  f = message.getLoanPhaseType();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getTridConditionsMetDate();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string borrower_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp questionnaire_submitted = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getQuestionnaireSubmitted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setQuestionnaireSubmitted = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearQuestionnaireSubmitted = function() {
  return this.setQuestionnaireSubmitted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasQuestionnaireSubmitted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 loan_version = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string borrower_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional LoanPurposeEnum loan_purpose = 7;
 * @return {!proto.wilqo.api.mortgage.LoanPurposeEnum}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanPurpose = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPurposeEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 monthly_payment = 8;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getMonthlyPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 monthly_payment_taxes_insurance = 9;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getMonthlyPaymentTaxesInsurance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setMonthlyPaymentTaxesInsurance = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 loan_amount = 10;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal down_payment = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getDownPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setDownPayment = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearDownPayment = function() {
  return this.setDownPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasDownPayment = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal debt_to_income_ratio = 12;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getDebtToIncomeRatio = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 12));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setDebtToIncomeRatio = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearDebtToIncomeRatio = function() {
  return this.setDebtToIncomeRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasDebtToIncomeRatio = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal loan_to_value_ratio = 13;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanToValueRatio = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 13));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanToValueRatio = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearLoanToValueRatio = function() {
  return this.setLoanToValueRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasLoanToValueRatio = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string product_name = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal interest_rate = 15;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getInterestRate = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 15));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setInterestRate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearInterestRate = function() {
  return this.setInterestRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasInterestRate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int32 total_fees = 16;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getTotalFees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setTotalFees = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 total_prepaids = 17;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getTotalPrepaids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setTotalPrepaids = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional PropertyTypeEnum property_type = 18;
 * @return {!proto.wilqo.api.mortgage.PropertyTypeEnum}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getPropertyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional PropertyUsageEnum property_usage = 19;
 * @return {!proto.wilqo.api.mortgage.PropertyUsageEnum}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getPropertyUsage = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyUsageEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setPropertyUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string property_state = 20;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getPropertyState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setPropertyState = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated ConditionSummary conditions = 21;
 * @return {!Array<!proto.wilqo.api.mortgage.ConditionSummary>}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ConditionSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ConditionSummary, 21));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ConditionSummary>} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ConditionSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ConditionSummary}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.wilqo.api.mortgage.ConditionSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional string loan_number = 22;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional AutomatedUnderwriting automated_underwriting = 23;
 * @return {?proto.wilqo.api.mortgage.AutomatedUnderwriting}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getAutomatedUnderwriting = function() {
  return /** @type{?proto.wilqo.api.mortgage.AutomatedUnderwriting} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.AutomatedUnderwriting, 23));
};


/**
 * @param {?proto.wilqo.api.mortgage.AutomatedUnderwriting|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setAutomatedUnderwriting = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearAutomatedUnderwriting = function() {
  return this.setAutomatedUnderwriting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasAutomatedUnderwriting = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional LoanPhaseEnum loan_phase_type = 24;
 * @return {!proto.wilqo.api.mortgage.LoanPhaseEnum}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getLoanPhaseType = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanPhaseEnum} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPhaseEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.setLoanPhaseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional google.protobuf.Timestamp trid_conditions_met_date = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.getTridConditionsMetDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
*/
proto.wilqo.api.mortgage.LoanLender.prototype.setTridConditionsMetDate = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanLender} returns this
 */
proto.wilqo.api.mortgage.LoanLender.prototype.clearTridConditionsMetDate = function() {
  return this.setTridConditionsMetDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLender.prototype.hasTridConditionsMetDate = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AutomatedUnderwriting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AutomatedUnderwriting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendationDescription: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eligibilityType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AutomatedUnderwriting}
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AutomatedUnderwriting;
  return proto.wilqo.api.mortgage.AutomatedUnderwriting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AutomatedUnderwriting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AutomatedUnderwriting}
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendationDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEligibilityType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AutomatedUnderwriting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AutomatedUnderwriting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendationDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEligibilityType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string recommendation_description = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.prototype.getRecommendationDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AutomatedUnderwriting} returns this
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.prototype.setRecommendationDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string eligibility_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.prototype.getEligibilityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AutomatedUnderwriting} returns this
 */
proto.wilqo.api.mortgage.AutomatedUnderwriting.prototype.setEligibilityType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ConditionSummary.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ConditionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ConditionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ConditionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    conditionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updatedIn: (f = msg.getUpdatedIn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    category: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sourceDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    conditionInstanceId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lenderConditionId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    businessProcessIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ConditionSummary}
 */
proto.wilqo.api.mortgage.ConditionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ConditionSummary;
  return proto.wilqo.api.mortgage.ConditionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ConditionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ConditionSummary}
 */
proto.wilqo.api.mortgage.ConditionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.ConditionStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedIn(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionInstanceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderConditionId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ConditionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ConditionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ConditionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUpdatedIn();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSourceDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConditionInstanceId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLenderConditionId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBusinessProcessIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string condition_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ConditionStatusEnum status = 3;
 * @return {!proto.wilqo.api.mortgage.ConditionStatusEnum}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.ConditionStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ConditionStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp updated_in = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getUpdatedIn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
*/
proto.wilqo.api.mortgage.ConditionSummary.prototype.setUpdatedIn = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.clearUpdatedIn = function() {
  return this.setUpdatedIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.hasUpdatedIn = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string source_description = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getSourceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setSourceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string condition_instance_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getConditionInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setConditionInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lender_condition_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getLenderConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setLenderConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string business_process_ids = 9;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.getBusinessProcessIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.setBusinessProcessIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.addBusinessProcessIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ConditionSummary} returns this
 */
proto.wilqo.api.mortgage.ConditionSummary.prototype.clearBusinessProcessIdsList = function() {
  return this.setBusinessProcessIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanSummary.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    loanPurpose: jspb.Message.getFieldWithDefault(msg, 6, 0),
    buyingProcessStage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    propertyState: jspb.Message.getFieldWithDefault(msg, 8, ""),
    propertyUsage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    propertyType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    monthlyIncome: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    retirements: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    checkingsAndSavings: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    rate: jspb.Message.getFieldWithDefault(msg, 14, ""),
    ratePercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    appraisalOrderId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    address: jspb.Message.getFieldWithDefault(msg, 17, ""),
    loanNumber: jspb.Message.getFieldWithDefault(msg, 18, ""),
    creationDate: (f = msg.getCreationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    loanPhaseType: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.LoanSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanSummary;
  return proto.wilqo.api.mortgage.LoanSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.LoanSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (reader.readEnum());
      msg.setLoanPurpose(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.BuyingProcessStageEnum} */ (reader.readEnum());
      msg.setBuyingProcessStage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyState(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (reader.readEnum());
      msg.setPropertyUsage(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyTypeEnum} */ (reader.readEnum());
      msg.setPropertyType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthlyIncome(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRetirements(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCheckingsAndSavings(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRatePercentage(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppraisalOrderId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanNumber(value);
      break;
    case 19:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationDate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 21:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanPhaseEnum} */ (reader.readEnum());
      msg.setLoanPhaseType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLoanPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getBuyingProcessStage();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPropertyState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPropertyUsage();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPropertyType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getMonthlyIncome();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRetirements();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getCheckingsAndSavings();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRatePercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getAppraisalOrderId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLoanNumber();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCreationDate();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getLoanPhaseType();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 loan_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getLoanVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setLoanVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string borrower_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string borrower_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional LoanPurposeEnum loan_purpose = 6;
 * @return {!proto.wilqo.api.mortgage.LoanPurposeEnum}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getLoanPurpose = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPurposeEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setLoanPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional BuyingProcessStageEnum buying_process_stage = 7;
 * @return {!proto.wilqo.api.mortgage.BuyingProcessStageEnum}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getBuyingProcessStage = function() {
  return /** @type {!proto.wilqo.api.mortgage.BuyingProcessStageEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BuyingProcessStageEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setBuyingProcessStage = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string property_state = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getPropertyState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setPropertyState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional PropertyUsageEnum property_usage = 9;
 * @return {!proto.wilqo.api.mortgage.PropertyUsageEnum}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getPropertyUsage = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyUsageEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setPropertyUsage = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional PropertyTypeEnum property_type = 10;
 * @return {!proto.wilqo.api.mortgage.PropertyTypeEnum}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getPropertyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setPropertyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional double monthly_income = 11;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getMonthlyIncome = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setMonthlyIncome = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double retirements = 12;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getRetirements = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setRetirements = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double checkings_and_savings = 13;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getCheckingsAndSavings = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setCheckingsAndSavings = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string rate = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional double rate_percentage = 15;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getRatePercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setRatePercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional string appraisal_order_id = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getAppraisalOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setAppraisalOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string address = 17;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string loan_number = 18;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getLoanNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setLoanNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional google.protobuf.Timestamp creation_date = 19;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getCreationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 19));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
*/
proto.wilqo.api.mortgage.LoanSummary.prototype.setCreationDate = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.clearCreationDate = function() {
  return this.setCreationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.hasCreationDate = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated string organization_ids = 20;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional LoanPhaseEnum loan_phase_type = 21;
 * @return {!proto.wilqo.api.mortgage.LoanPhaseEnum}
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.getLoanPhaseType = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanPhaseEnum} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPhaseEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanSummary} returns this
 */
proto.wilqo.api.mortgage.LoanSummary.prototype.setLoanPhaseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ProgressSummary.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProgressSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProgressSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    estimatedClosingDate: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanPhase: jspb.Message.getFieldWithDefault(msg, 3, ""),
    progressItemsList: jspb.Message.toObjectList(msg.getProgressItemsList(),
    proto.wilqo.api.mortgage.ProgressItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProgressSummary}
 */
proto.wilqo.api.mortgage.ProgressSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProgressSummary;
  return proto.wilqo.api.mortgage.ProgressSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProgressSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProgressSummary}
 */
proto.wilqo.api.mortgage.ProgressSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstimatedClosingDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanPhase(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.ProgressItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.ProgressItem.deserializeBinaryFromReader);
      msg.addProgressItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProgressSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProgressSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEstimatedClosingDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanPhase();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgressItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.ProgressItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSummary} returns this
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string estimated_closing_date = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.getEstimatedClosingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSummary} returns this
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.setEstimatedClosingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string loan_phase = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.getLoanPhase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSummary} returns this
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.setLoanPhase = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ProgressItem progress_items = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.ProgressItem>}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.getProgressItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ProgressItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ProgressItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ProgressItem>} value
 * @return {!proto.wilqo.api.mortgage.ProgressSummary} returns this
*/
proto.wilqo.api.mortgage.ProgressSummary.prototype.setProgressItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ProgressItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ProgressItem}
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.addProgressItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.ProgressItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ProgressSummary} returns this
 */
proto.wilqo.api.mortgage.ProgressSummary.prototype.clearProgressItemsList = function() {
  return this.setProgressItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ProgressSectionItem.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProgressSectionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProgressSectionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSectionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    leadingText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fieldPath: jspb.Message.getFieldWithDefault(msg, 6, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 7, ""),
    leadingIconProperties: (f = msg.getLeadingIconProperties()) && proto.wilqo.api.mortgage.ProgressSectionIconProperties.toObject(includeInstance, f),
    trailingIconProperties: (f = msg.getTrailingIconProperties()) && proto.wilqo.api.mortgage.ProgressSectionIconProperties.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 10, ""),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.wilqo.api.mortgage.ProgressSectionDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProgressSectionItem;
  return proto.wilqo.api.mortgage.ProgressSectionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProgressSectionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadingText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldPath(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.ProgressSectionIconProperties;
      reader.readMessage(value,proto.wilqo.api.mortgage.ProgressSectionIconProperties.deserializeBinaryFromReader);
      msg.setLeadingIconProperties(value);
      break;
    case 9:
      var value = new proto.wilqo.api.mortgage.ProgressSectionIconProperties;
      reader.readMessage(value,proto.wilqo.api.mortgage.ProgressSectionIconProperties.deserializeBinaryFromReader);
      msg.setTrailingIconProperties(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 11:
      var value = new proto.wilqo.api.mortgage.ProgressSectionDocument;
      reader.readMessage(value,proto.wilqo.api.mortgage.ProgressSectionDocument.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProgressSectionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProgressSectionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSectionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLeadingText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFieldPath();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLeadingIconProperties();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.ProgressSectionIconProperties.serializeBinaryToWriter
    );
  }
  f = message.getTrailingIconProperties();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.api.mortgage.ProgressSectionIconProperties.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.wilqo.api.mortgage.ProgressSectionDocument.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string leading_text = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getLeadingText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setLeadingText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 order = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string field_path = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getFieldPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setFieldPath = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string caption = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ProgressSectionIconProperties leading_icon_properties = 8;
 * @return {?proto.wilqo.api.mortgage.ProgressSectionIconProperties}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getLeadingIconProperties = function() {
  return /** @type{?proto.wilqo.api.mortgage.ProgressSectionIconProperties} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.ProgressSectionIconProperties, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.ProgressSectionIconProperties|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
*/
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setLeadingIconProperties = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.clearLeadingIconProperties = function() {
  return this.setLeadingIconProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.hasLeadingIconProperties = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ProgressSectionIconProperties trailing_icon_properties = 9;
 * @return {?proto.wilqo.api.mortgage.ProgressSectionIconProperties}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getTrailingIconProperties = function() {
  return /** @type{?proto.wilqo.api.mortgage.ProgressSectionIconProperties} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.ProgressSectionIconProperties, 9));
};


/**
 * @param {?proto.wilqo.api.mortgage.ProgressSectionIconProperties|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
*/
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setTrailingIconProperties = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.clearTrailingIconProperties = function() {
  return this.setTrailingIconProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.hasTrailingIconProperties = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string url = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated ProgressSectionDocument documents = 11;
 * @return {!Array<!proto.wilqo.api.mortgage.ProgressSectionDocument>}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ProgressSectionDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ProgressSectionDocument, 11));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ProgressSectionDocument>} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
*/
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ProgressSectionDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument}
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.api.mortgage.ProgressSectionDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionItem} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionItem.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    generatedDateTime: (f = msg.getGeneratedDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    generatedBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deliveryMethod: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deliveryStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deliveryStatusDateTime: (f = msg.getDeliveryStatusDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails;
  return proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setGeneratedDateTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGeneratedBy(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryMethodEnum} */ (reader.readEnum());
      msg.setDeliveryMethod(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryStatusEnum} */ (reader.readEnum());
      msg.setDeliveryStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDeliveryStatusDateTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneratedDateTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGeneratedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeliveryMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDeliveryStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDeliveryStatusDateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp generated_date_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.getGeneratedDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
*/
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.setGeneratedDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.clearGeneratedDateTime = function() {
  return this.setGeneratedDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.hasGeneratedDateTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string generated_by = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.getGeneratedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.setGeneratedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DisclosureDocumentsDeliveryMethodEnum delivery_method = 3;
 * @return {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryMethodEnum}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.getDeliveryMethod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryMethodEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryMethodEnum} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.setDeliveryMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DisclosureDocumentsDeliveryStatusEnum delivery_status = 4;
 * @return {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryStatusEnum}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.getDeliveryStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.setDeliveryStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp delivery_status_date_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.getDeliveryStatusDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
*/
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.setDeliveryStatusDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.clearDeliveryStatusDateTime = function() {
  return this.setDeliveryStatusDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.hasDeliveryStatusDateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string document_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DisclosurePackagesDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    packageid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 3, ""),
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage.Badge.toObject(includeInstance, f),
    disclosueDetailsList: jspb.Message.toObjectList(msg.getDisclosueDetailsList(),
    proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DisclosurePackagesDocument;
  return proto.wilqo.api.mortgage.DisclosurePackagesDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.Badge;
      reader.readMessage(value,proto.wilqo.api.mortgage.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails;
      reader.readMessage(value,proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.deserializeBinaryFromReader);
      msg.addDisclosueDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DisclosurePackagesDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPackageid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage.Badge.serializeBinaryToWriter
    );
  }
  f = message.getDisclosueDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string packageId = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.getPackageid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.setPackageid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string caption = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Badge badge = 4;
 * @return {?proto.wilqo.api.mortgage.Badge}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage.Badge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.Badge, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.Badge|undefined} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
*/
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DisclosurePackageDocumentDetails disclosue_details = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails>}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.getDisclosueDetailsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails>} value
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
*/
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.setDisclosueDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails}
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.addDisclosueDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage.DisclosurePackageDocumentDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DisclosurePackagesDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosurePackagesDocument.prototype.clearDisclosueDetailsList = function() {
  return this.setDisclosueDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProgressSectionDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProgressSectionDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProgressSectionDocument;
  return proto.wilqo.api.mortgage.ProgressSectionDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProgressSectionDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProgressSectionDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProgressSectionDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument} returns this
*/
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionDocument} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProgressSectionDocument.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProgressSectionIconProperties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProgressSectionIconProperties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    variant: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionIconProperties}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProgressSectionIconProperties;
  return proto.wilqo.api.mortgage.ProgressSectionIconProperties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProgressSectionIconProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProgressSectionIconProperties}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProgressSectionIconProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProgressSectionIconProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVariant();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionIconProperties} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionIconProperties} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string variant = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.getVariant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressSectionIconProperties} returns this
 */
proto.wilqo.api.mortgage.ProgressSectionIconProperties.prototype.setVariant = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanConsumer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanConsumer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanConsumer.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    loanPurpose: jspb.Message.getFieldWithDefault(msg, 6, 0),
    monthlyPayment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    monthlypaymentTaxesInsurance: jspb.Message.getFieldWithDefault(msg, 8, 0),
    loanAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loanToValueRatio: (f = msg.getLoanToValueRatio()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    interestRate: (f = msg.getInterestRate()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalFees: jspb.Message.getFieldWithDefault(msg, 12, 0),
    totalPrepaids: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanConsumer}
 */
proto.wilqo.api.mortgage.LoanConsumer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanConsumer;
  return proto.wilqo.api.mortgage.LoanConsumer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanConsumer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanConsumer}
 */
proto.wilqo.api.mortgage.LoanConsumer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (reader.readEnum());
      msg.setLoanPurpose(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlyPayment(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonthlypaymentTaxesInsurance(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoanAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLoanToValueRatio(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setInterestRate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalFees(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPrepaids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanConsumer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanConsumer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanConsumer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLoanPurpose();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMonthlypaymentTaxesInsurance();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLoanAmount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLoanToValueRatio();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getInterestRate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalFees();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getTotalPrepaids();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 loan_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getLoanVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setLoanVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string borrower_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string borrower_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional LoanPurposeEnum loan_purpose = 6;
 * @return {!proto.wilqo.api.mortgage.LoanPurposeEnum}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getLoanPurpose = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPurposeEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setLoanPurpose = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 monthly_payment = 7;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getMonthlyPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 monthlyPayment_taxes_insurance = 8;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getMonthlypaymentTaxesInsurance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setMonthlypaymentTaxesInsurance = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 loan_amount = 9;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getLoanAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setLoanAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal loan_to_value_ratio = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getLoanToValueRatio = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
*/
proto.wilqo.api.mortgage.LoanConsumer.prototype.setLoanToValueRatio = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.clearLoanToValueRatio = function() {
  return this.setLoanToValueRatio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.hasLoanToValueRatio = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal interest_rate = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getInterestRate = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
*/
proto.wilqo.api.mortgage.LoanConsumer.prototype.setInterestRate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.clearInterestRate = function() {
  return this.setInterestRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.hasInterestRate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 total_fees = 12;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getTotalFees = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setTotalFees = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 total_prepaids = 13;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.getTotalPrepaids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanConsumer} returns this
 */
proto.wilqo.api.mortgage.LoanConsumer.prototype.setTotalPrepaids = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanBorrower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanBorrower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanBorrower.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    borrowerLastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    borrowerFirstName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    borrowerDisplayName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    borrowerCurrentAddressLine1: jspb.Message.getFieldWithDefault(msg, 8, ""),
    borrowerCurrentAddressLine2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateOfBirth: jspb.Message.getFieldWithDefault(msg, 10, ""),
    socialLastFour: jspb.Message.getFieldWithDefault(msg, 11, ""),
    monthlyGrossIncome: (f = msg.getMonthlyGrossIncome()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    authorizedSoftCreditPull: (f = msg.getAuthorizedSoftCreditPull()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanBorrower}
 */
proto.wilqo.api.mortgage.LoanBorrower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanBorrower;
  return proto.wilqo.api.mortgage.LoanBorrower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanBorrower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanBorrower}
 */
proto.wilqo.api.mortgage.LoanBorrower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerFirstName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerDisplayName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerCurrentAddressLine1(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerCurrentAddressLine2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateOfBirth(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialLastFour(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyGrossIncome(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAuthorizedSoftCreditPull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanBorrower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanBorrower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanBorrower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBorrowerLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBorrowerFirstName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBorrowerDisplayName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBorrowerCurrentAddressLine1();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBorrowerCurrentAddressLine2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSocialLastFour();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMonthlyGrossIncome();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedSoftCreditPull();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 loan_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getLoanVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setLoanVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string borrower_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string borrower_last_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getBorrowerLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setBorrowerLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string borrower_first_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getBorrowerFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setBorrowerFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string borrower_display_name = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getBorrowerDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setBorrowerDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string borrower_current_address_line_1 = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getBorrowerCurrentAddressLine1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setBorrowerCurrentAddressLine1 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string borrower_current_address_line_2 = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getBorrowerCurrentAddressLine2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setBorrowerCurrentAddressLine2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_of_birth = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getDateOfBirth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string social_last_four = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getSocialLastFour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.setSocialLastFour = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Int32Value monthly_gross_income = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getMonthlyGrossIncome = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
*/
proto.wilqo.api.mortgage.LoanBorrower.prototype.setMonthlyGrossIncome = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.clearMonthlyGrossIncome = function() {
  return this.setMonthlyGrossIncome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.hasMonthlyGrossIncome = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp authorized_soft_credit_pull = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.getAuthorizedSoftCreditPull = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
*/
proto.wilqo.api.mortgage.LoanBorrower.prototype.setAuthorizedSoftCreditPull = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanBorrower} returns this
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.clearAuthorizedSoftCreditPull = function() {
  return this.setAuthorizedSoftCreditPull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanBorrower.prototype.hasAuthorizedSoftCreditPull = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CreditBorrower.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CreditBorrower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CreditBorrower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditBorrower.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CreditBorrower}
 */
proto.wilqo.api.mortgage.CreditBorrower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CreditBorrower;
  return proto.wilqo.api.mortgage.CreditBorrower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CreditBorrower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CreditBorrower}
 */
proto.wilqo.api.mortgage.CreditBorrower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CreditBorrower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CreditBorrower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CreditBorrower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditBorrower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditBorrower.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditBorrower} returns this
 */
proto.wilqo.api.mortgage.CreditBorrower.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditBorrower.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditBorrower} returns this
 */
proto.wilqo.api.mortgage.CreditBorrower.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CreditReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CreditReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditBorrowerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    orderDate: (f = msg.getOrderDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CreditReport}
 */
proto.wilqo.api.mortgage.CreditReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CreditReport;
  return proto.wilqo.api.mortgage.CreditReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CreditReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CreditReport}
 */
proto.wilqo.api.mortgage.CreditReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditBorrowerId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CreditReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CreditReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrderDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReport} returns this
 */
proto.wilqo.api.mortgage.CreditReport.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string credit_borrower_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.getCreditBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReport} returns this
 */
proto.wilqo.api.mortgage.CreditReport.prototype.setCreditBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp order_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.getOrderDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CreditReport} returns this
*/
proto.wilqo.api.mortgage.CreditReport.prototype.setOrderDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CreditReport} returns this
 */
proto.wilqo.api.mortgage.CreditReport.prototype.clearOrderDate = function() {
  return this.setOrderDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.hasOrderDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string document_url = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReport} returns this
 */
proto.wilqo.api.mortgage.CreditReport.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string document_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReport.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReport} returns this
 */
proto.wilqo.api.mortgage.CreditReport.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusReport.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderDate: (f = msg.getOrderDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusReport}
 */
proto.wilqo.api.mortgage.AusReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusReport;
  return proto.wilqo.api.mortgage.AusReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusReport}
 */
proto.wilqo.api.mortgage.AusReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOrderDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusReport.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusReport} returns this
 */
proto.wilqo.api.mortgage.AusReport.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp order_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.AusReport.prototype.getOrderDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.AusReport} returns this
*/
proto.wilqo.api.mortgage.AusReport.prototype.setOrderDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AusReport} returns this
 */
proto.wilqo.api.mortgage.AusReport.prototype.clearOrderDate = function() {
  return this.setOrderDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusReport.prototype.hasOrderDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string document_url = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusReport.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusReport} returns this
 */
proto.wilqo.api.mortgage.AusReport.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string document_name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusReport.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusReport} returns this
 */
proto.wilqo.api.mortgage.AusReport.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DocumentSummary.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdDatetime: (f = msg.getCreatedDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    statusDatetime: (f = msg.getStatusDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    category: jspb.Message.getFieldWithDefault(msg, 9, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    validPeriod: jspb.Message.getFieldWithDefault(msg, 11, 0),
    documentExpirationDatetime: (f = msg.getDocumentExpirationDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentEffectiveDate: (f = msg.getDocumentEffectiveDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    associatedBorrowersList: jspb.Message.toObjectList(msg.getAssociatedBorrowersList(),
    proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary}
 */
proto.wilqo.api.mortgage.DocumentSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentSummary;
  return proto.wilqo.api.mortgage.DocumentSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary}
 */
proto.wilqo.api.mortgage.DocumentSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDatetime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStatusDatetime(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (reader.readEnum());
      msg.setValidPeriod(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDocumentExpirationDatetime(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDocumentEffectiveDate(value);
      break;
    case 14:
      var value = new proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation;
      reader.readMessage(value,proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.deserializeBinaryFromReader);
      msg.addAssociatedBorrowers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedDatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatusDatetime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getValidPeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getDocumentExpirationDatetime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedBorrowersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation}
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation;
  return proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation}
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string borrower_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string borrower_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_datetime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getCreatedDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
*/
proto.wilqo.api.mortgage.DocumentSummary.prototype.setCreatedDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.clearCreatedDatetime = function() {
  return this.setCreatedDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.hasCreatedDatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DocumentStatusEnum status = 6;
 * @return {!proto.wilqo.api.mortgage.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp status_datetime = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getStatusDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
*/
proto.wilqo.api.mortgage.DocumentSummary.prototype.setStatusDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.clearStatusDatetime = function() {
  return this.setStatusDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.hasStatusDatetime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UxDisplayBadge badge = 8;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
*/
proto.wilqo.api.mortgage.DocumentSummary.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string category = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional DocumentEnum document_type = 10;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional DocumentValidPeriodInDaysEnum valid_period = 11;
 * @return {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getValidPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.setValidPeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp document_expiration_datetime = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getDocumentExpirationDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
*/
proto.wilqo.api.mortgage.DocumentSummary.prototype.setDocumentExpirationDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.clearDocumentExpirationDatetime = function() {
  return this.setDocumentExpirationDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.hasDocumentExpirationDatetime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp document_effective_date = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getDocumentEffectiveDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
*/
proto.wilqo.api.mortgage.DocumentSummary.prototype.setDocumentEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.clearDocumentEffectiveDate = function() {
  return this.setDocumentEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.hasDocumentEffectiveDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated BorrowerAssociation associated_borrowers = 14;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation>}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.getAssociatedBorrowersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation, 14));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation>} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
*/
proto.wilqo.api.mortgage.DocumentSummary.prototype.setAssociatedBorrowersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation}
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.addAssociatedBorrowers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.api.mortgage.DocumentSummary.BorrowerAssociation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentSummary} returns this
 */
proto.wilqo.api.mortgage.DocumentSummary.prototype.clearAssociatedBorrowersList = function() {
  return this.setAssociatedBorrowersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Badge.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Badge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Badge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Badge.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: (f = msg.getType()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Badge}
 */
proto.wilqo.api.mortgage.Badge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Badge;
  return proto.wilqo.api.mortgage.Badge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Badge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Badge}
 */
proto.wilqo.api.mortgage.Badge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Badge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Badge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Badge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Badge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Badge.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Badge} returns this
 */
proto.wilqo.api.mortgage.Badge.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UxDisplayBadge type = 2;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.Badge.prototype.getType = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.Badge} returns this
*/
proto.wilqo.api.mortgage.Badge.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Badge} returns this
 */
proto.wilqo.api.mortgage.Badge.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Badge.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Originator.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Originator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Originator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Originator.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Originator}
 */
proto.wilqo.api.mortgage.Originator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Originator;
  return proto.wilqo.api.mortgage.Originator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Originator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Originator}
 */
proto.wilqo.api.mortgage.Originator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Originator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Originator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Originator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Originator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Originator.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Originator} returns this
 */
proto.wilqo.api.mortgage.Originator.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.Originator.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Originator} returns this
 */
proto.wilqo.api.mortgage.Originator.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ActiveBPD.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ActiveBPD.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ActiveBPD} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ActiveBPD.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ActiveBPD}
 */
proto.wilqo.api.mortgage.ActiveBPD.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ActiveBPD;
  return proto.wilqo.api.mortgage.ActiveBPD.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ActiveBPD} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ActiveBPD}
 */
proto.wilqo.api.mortgage.ActiveBPD.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ActiveBPD.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ActiveBPD.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ActiveBPD} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ActiveBPD.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ActiveBPD.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ActiveBPD} returns this
 */
proto.wilqo.api.mortgage.ActiveBPD.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ActiveBPD.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ActiveBPD} returns this
 */
proto.wilqo.api.mortgage.ActiveBPD.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RecordOwner.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RecordOwner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RecordOwner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RecordOwner.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RecordOwner}
 */
proto.wilqo.api.mortgage.RecordOwner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RecordOwner;
  return proto.wilqo.api.mortgage.RecordOwner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RecordOwner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RecordOwner}
 */
proto.wilqo.api.mortgage.RecordOwner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RecordOwner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RecordOwner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RecordOwner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RecordOwner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.RecordOwner.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RecordOwner} returns this
 */
proto.wilqo.api.mortgage.RecordOwner.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.RecordOwner.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RecordOwner} returns this
 */
proto.wilqo.api.mortgage.RecordOwner.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealMortgageTenancy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealMortgageTenancy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.toObject = function(includeInstance, msg) {
  var f, obj = {
    originator: (f = msg.getOriginator()) && proto.wilqo.api.mortgage.Originator.toObject(includeInstance, f),
    activeBpd: (f = msg.getActiveBpd()) && proto.wilqo.api.mortgage.ActiveBPD.toObject(includeInstance, f),
    recordOwner: (f = msg.getRecordOwner()) && proto.wilqo.api.mortgage.RecordOwner.toObject(includeInstance, f),
    authorizedInstitutionsIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealMortgageTenancy;
  return proto.wilqo.api.mortgage.DealMortgageTenancy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealMortgageTenancy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.Originator;
      reader.readMessage(value,proto.wilqo.api.mortgage.Originator.deserializeBinaryFromReader);
      msg.setOriginator(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.ActiveBPD;
      reader.readMessage(value,proto.wilqo.api.mortgage.ActiveBPD.deserializeBinaryFromReader);
      msg.setActiveBpd(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.RecordOwner;
      reader.readMessage(value,proto.wilqo.api.mortgage.RecordOwner.deserializeBinaryFromReader);
      msg.setRecordOwner(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuthorizedInstitutionsIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealMortgageTenancy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealMortgageTenancy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.mortgage.Originator.serializeBinaryToWriter
    );
  }
  f = message.getActiveBpd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.ActiveBPD.serializeBinaryToWriter
    );
  }
  f = message.getRecordOwner();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage.RecordOwner.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedInstitutionsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional Originator originator = 1;
 * @return {?proto.wilqo.api.mortgage.Originator}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.getOriginator = function() {
  return /** @type{?proto.wilqo.api.mortgage.Originator} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.Originator, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage.Originator|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
*/
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.setOriginator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.clearOriginator = function() {
  return this.setOriginator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.hasOriginator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActiveBPD active_bpd = 2;
 * @return {?proto.wilqo.api.mortgage.ActiveBPD}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.getActiveBpd = function() {
  return /** @type{?proto.wilqo.api.mortgage.ActiveBPD} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.ActiveBPD, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.ActiveBPD|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
*/
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.setActiveBpd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.clearActiveBpd = function() {
  return this.setActiveBpd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.hasActiveBpd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RecordOwner record_owner = 3;
 * @return {?proto.wilqo.api.mortgage.RecordOwner}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.getRecordOwner = function() {
  return /** @type{?proto.wilqo.api.mortgage.RecordOwner} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.RecordOwner, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.RecordOwner|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
*/
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.setRecordOwner = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.clearRecordOwner = function() {
  return this.setRecordOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.hasRecordOwner = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string authorized_institutions_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.getAuthorizedInstitutionsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.setAuthorizedInstitutionsIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.addAuthorizedInstitutionsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DealMortgageTenancy} returns this
 */
proto.wilqo.api.mortgage.DealMortgageTenancy.prototype.clearAuthorizedInstitutionsIdsList = function() {
  return this.setAuthorizedInstitutionsIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanOriginator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanOriginator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanOriginator.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanOriginator}
 */
proto.wilqo.api.mortgage.LoanOriginator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanOriginator;
  return proto.wilqo.api.mortgage.LoanOriginator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanOriginator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanOriginator}
 */
proto.wilqo.api.mortgage.LoanOriginator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanOriginator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanOriginator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanOriginator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanOriginator} returns this
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanOriginator} returns this
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanOriginator} returns this
 */
proto.wilqo.api.mortgage.LoanOriginator.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Individual.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Individual.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Individual} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Individual.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto.wilqo.api.mortgage.Name.toObject(includeInstance, f),
    contactMethod: (f = msg.getContactMethod()) && proto.wilqo.api.mortgage.ContactMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Individual}
 */
proto.wilqo.api.mortgage.Individual.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Individual;
  return proto.wilqo.api.mortgage.Individual.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Individual} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Individual}
 */
proto.wilqo.api.mortgage.Individual.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.Name;
      reader.readMessage(value,proto.wilqo.api.mortgage.Name.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.ContactMethod;
      reader.readMessage(value,proto.wilqo.api.mortgage.ContactMethod.deserializeBinaryFromReader);
      msg.setContactMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Individual.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Individual.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Individual} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Individual.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.Name.serializeBinaryToWriter
    );
  }
  f = message.getContactMethod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage.ContactMethod.serializeBinaryToWriter
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Individual.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Individual} returns this
 */
proto.wilqo.api.mortgage.Individual.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Name name = 2;
 * @return {?proto.wilqo.api.mortgage.Name}
 */
proto.wilqo.api.mortgage.Individual.prototype.getName = function() {
  return /** @type{?proto.wilqo.api.mortgage.Name} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.Name, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.Name|undefined} value
 * @return {!proto.wilqo.api.mortgage.Individual} returns this
*/
proto.wilqo.api.mortgage.Individual.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Individual} returns this
 */
proto.wilqo.api.mortgage.Individual.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Individual.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ContactMethod contact_method = 3;
 * @return {?proto.wilqo.api.mortgage.ContactMethod}
 */
proto.wilqo.api.mortgage.Individual.prototype.getContactMethod = function() {
  return /** @type{?proto.wilqo.api.mortgage.ContactMethod} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.ContactMethod, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.ContactMethod|undefined} value
 * @return {!proto.wilqo.api.mortgage.Individual} returns this
*/
proto.wilqo.api.mortgage.Individual.prototype.setContactMethod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Individual} returns this
 */
proto.wilqo.api.mortgage.Individual.prototype.clearContactMethod = function() {
  return this.setContactMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Individual.prototype.hasContactMethod = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ContactMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ContactMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ContactMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    personalEmail: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobilePhone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    homePhone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    workPhone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    workPhoneExtension: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ContactMethod}
 */
proto.wilqo.api.mortgage.ContactMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ContactMethod;
  return proto.wilqo.api.mortgage.ContactMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ContactMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ContactMethod}
 */
proto.wilqo.api.mortgage.ContactMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonalEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobilePhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomePhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkPhoneExtension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ContactMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ContactMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ContactMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonalEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMobilePhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHomePhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWorkPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWorkPhoneExtension();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string personal_email = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.getPersonalEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ContactMethod} returns this
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.setPersonalEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mobile_phone = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.getMobilePhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ContactMethod} returns this
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.setMobilePhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string home_phone = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.getHomePhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ContactMethod} returns this
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.setHomePhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string work_phone = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.getWorkPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ContactMethod} returns this
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.setWorkPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string work_phone_extension = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.getWorkPhoneExtension = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ContactMethod} returns this
 */
proto.wilqo.api.mortgage.ContactMethod.prototype.setWorkPhoneExtension = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Name.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Name.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Name} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Name.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Name}
 */
proto.wilqo.api.mortgage.Name.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Name;
  return proto.wilqo.api.mortgage.Name.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Name} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Name}
 */
proto.wilqo.api.mortgage.Name.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Name.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Name.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Name} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Name.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Name.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Name} returns this
 */
proto.wilqo.api.mortgage.Name.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.Name.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Name} returns this
 */
proto.wilqo.api.mortgage.Name.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string full_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.Name.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Name} returns this
 */
proto.wilqo.api.mortgage.Name.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Lender.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Lender.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Lender} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Lender.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Lender}
 */
proto.wilqo.api.mortgage.Lender.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Lender;
  return proto.wilqo.api.mortgage.Lender.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Lender} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Lender}
 */
proto.wilqo.api.mortgage.Lender.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Lender.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Lender.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Lender} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Lender.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Lender.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Lender} returns this
 */
proto.wilqo.api.mortgage.Lender.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.Lender.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Lender} returns this
 */
proto.wilqo.api.mortgage.Lender.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string legal_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.Lender.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Lender} returns this
 */
proto.wilqo.api.mortgage.Lender.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PartySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PartySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    initials: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyTypes: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partySummaryLink: (f = msg.getPartySummaryLink()) && proto.wilqo.api.mortgage.PartySummaryLink.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scopeId: (f = msg.getScopeId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.PartySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PartySummary;
  return proto.wilqo.api.mortgage.PartySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PartySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.PartySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitials(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyTypes(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.PartySummaryLink;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartySummaryLink.deserializeBinaryFromReader);
      msg.setPartySummaryLink(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setScopeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PartySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PartySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitials();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyTypes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartySummaryLink();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage.PartySummaryLink.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScopeId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string initials = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.getInitials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
 */
proto.wilqo.api.mortgage.PartySummary.prototype.setInitials = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
 */
proto.wilqo.api.mortgage.PartySummary.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string party_types = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.getPartyTypes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
 */
proto.wilqo.api.mortgage.PartySummary.prototype.setPartyTypes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PartySummaryLink party_summary_link = 4;
 * @return {?proto.wilqo.api.mortgage.PartySummaryLink}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.getPartySummaryLink = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartySummaryLink} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartySummaryLink, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartySummaryLink|undefined} value
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
*/
proto.wilqo.api.mortgage.PartySummary.prototype.setPartySummaryLink = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
 */
proto.wilqo.api.mortgage.PartySummary.prototype.clearPartySummaryLink = function() {
  return this.setPartySummaryLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.hasPartySummaryLink = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
 */
proto.wilqo.api.mortgage.PartySummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Int32Value scope_id = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.getScopeId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
*/
proto.wilqo.api.mortgage.PartySummary.prototype.setScopeId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PartySummary} returns this
 */
proto.wilqo.api.mortgage.PartySummary.prototype.clearScopeId = function() {
  return this.setScopeId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PartySummary.prototype.hasScopeId = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ComplianceTestSuite.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSuite} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceTestSourceType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    testDate: (f = msg.getTestDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    complianceTestsList: jspb.Message.toObjectList(msg.getComplianceTestsList(),
    proto.wilqo.api.mortgage.ComplianceTest.toObject, includeInstance),
    complianceTestSuitePurposeType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    complianceConditionsList: jspb.Message.toObjectList(msg.getComplianceConditionsList(),
    proto.wilqo.api.mortgage.ComplianceCondition.toObject, includeInstance),
    displayName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ComplianceTestSuite;
  return proto.wilqo.api.mortgage.ComplianceTestSuite.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSuite} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.ComplianceTestSourceEnum} */ (reader.readEnum());
      msg.setComplianceTestSourceType(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTestDate(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.ComplianceTest;
      reader.readMessage(value,proto.wilqo.api.mortgage.ComplianceTest.deserializeBinaryFromReader);
      msg.addComplianceTests(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.ComplianceTestSuitePurposeEnum} */ (reader.readEnum());
      msg.setComplianceTestSuitePurposeType(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.ComplianceCondition;
      reader.readMessage(value,proto.wilqo.api.mortgage.ComplianceCondition.deserializeBinaryFromReader);
      msg.addComplianceConditions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.ComplianceTestEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ComplianceTestSuite.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSuite} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceTestSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTestDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComplianceTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.ComplianceTest.serializeBinaryToWriter
    );
  }
  f = message.getComplianceTestSuitePurposeType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getComplianceConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage.ComplianceCondition.serializeBinaryToWriter
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional ComplianceTestSourceEnum compliance_test_source_type = 1;
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSourceEnum}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getComplianceTestSourceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ComplianceTestSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSourceEnum} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setComplianceTestSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp test_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getTestDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setTestDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.clearTestDate = function() {
  return this.setTestDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.hasTestDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ComplianceTest compliance_tests = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.ComplianceTest>}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getComplianceTestsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ComplianceTest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ComplianceTest, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ComplianceTest>} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setComplianceTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ComplianceTest}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.addComplianceTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.ComplianceTest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.clearComplianceTestsList = function() {
  return this.setComplianceTestsList([]);
};


/**
 * optional ComplianceTestSuitePurposeEnum compliance_test_suite_purpose_type = 4;
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuitePurposeEnum}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getComplianceTestSuitePurposeType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ComplianceTestSuitePurposeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSuitePurposeEnum} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setComplianceTestSuitePurposeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated ComplianceCondition compliance_conditions = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.ComplianceCondition>}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getComplianceConditionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ComplianceCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ComplianceCondition, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ComplianceCondition>} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setComplianceConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.addComplianceConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage.ComplianceCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.clearComplianceConditionsList = function() {
  return this.setComplianceConditionsList([]);
};


/**
 * optional string display_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ComplianceTestEnum type = 7;
 * @return {!proto.wilqo.api.mortgage.ComplianceTestEnum}
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ComplianceTestEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestEnum} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSuite} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSuite.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ComplianceTestSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceReportFirstOrderedDate: (f = msg.getComplianceReportFirstOrderedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    complianceReportLastOrderedDate: (f = msg.getComplianceReportLastOrderedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    complianceReportFirstReceivedDate: (f = msg.getComplianceReportFirstReceivedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    complianceReportLastReceivedDate: (f = msg.getComplianceReportLastReceivedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fraudReportFirstOrderedDate: (f = msg.getFraudReportFirstOrderedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fraudReportLastOrderedDate: (f = msg.getFraudReportLastOrderedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fraudReportFirstReceivedDate: (f = msg.getFraudReportFirstReceivedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fraudReportLastReceivedDate: (f = msg.getFraudReportLastReceivedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ComplianceTestSummary;
  return proto.wilqo.api.mortgage.ComplianceTestSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setComplianceReportFirstOrderedDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setComplianceReportLastOrderedDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setComplianceReportFirstReceivedDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setComplianceReportLastReceivedDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFraudReportFirstOrderedDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFraudReportLastOrderedDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFraudReportFirstReceivedDate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFraudReportLastReceivedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ComplianceTestSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ComplianceTestSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceReportFirstOrderedDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComplianceReportLastOrderedDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComplianceReportFirstReceivedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getComplianceReportLastReceivedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFraudReportFirstOrderedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFraudReportLastOrderedDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFraudReportFirstReceivedDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFraudReportLastReceivedDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp compliance_report_first_ordered_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getComplianceReportFirstOrderedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setComplianceReportFirstOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearComplianceReportFirstOrderedDate = function() {
  return this.setComplianceReportFirstOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasComplianceReportFirstOrderedDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp compliance_report_last_ordered_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getComplianceReportLastOrderedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setComplianceReportLastOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearComplianceReportLastOrderedDate = function() {
  return this.setComplianceReportLastOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasComplianceReportLastOrderedDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp compliance_report_first_received_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getComplianceReportFirstReceivedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setComplianceReportFirstReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearComplianceReportFirstReceivedDate = function() {
  return this.setComplianceReportFirstReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasComplianceReportFirstReceivedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp compliance_report_last_received_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getComplianceReportLastReceivedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setComplianceReportLastReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearComplianceReportLastReceivedDate = function() {
  return this.setComplianceReportLastReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasComplianceReportLastReceivedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp fraud_report_first_ordered_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getFraudReportFirstOrderedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setFraudReportFirstOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearFraudReportFirstOrderedDate = function() {
  return this.setFraudReportFirstOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasFraudReportFirstOrderedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp fraud_report_last_ordered_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getFraudReportLastOrderedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setFraudReportLastOrderedDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearFraudReportLastOrderedDate = function() {
  return this.setFraudReportLastOrderedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasFraudReportLastOrderedDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp fraud_report_first_received_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getFraudReportFirstReceivedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setFraudReportFirstReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearFraudReportFirstReceivedDate = function() {
  return this.setFraudReportFirstReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasFraudReportFirstReceivedDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp fraud_report_last_received_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.getFraudReportLastReceivedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.setFraudReportLastReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestSummary} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.clearFraudReportLastReceivedDate = function() {
  return this.setFraudReportLastReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestSummary.prototype.hasFraudReportLastReceivedDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ComplianceTest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ComplianceTest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    complianceTestStatusType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    resultDate: (f = msg.getResultDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    score: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    complianceTestStatusDescription: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ComplianceTest}
 */
proto.wilqo.api.mortgage.ComplianceTest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ComplianceTest;
  return proto.wilqo.api.mortgage.ComplianceTest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ComplianceTest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ComplianceTest}
 */
proto.wilqo.api.mortgage.ComplianceTest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.ComplianceTestStatusEnum} */ (reader.readEnum());
      msg.setComplianceTestStatusType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResultDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceTestStatusDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ComplianceTest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ComplianceTest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComplianceTestStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getResultDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getScore();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getComplianceTestStatusDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ComplianceTestStatusEnum compliance_test_status_type = 3;
 * @return {!proto.wilqo.api.mortgage.ComplianceTestStatusEnum}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getComplianceTestStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ComplianceTestStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.setComplianceTestStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp result_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getResultDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
*/
proto.wilqo.api.mortgage.ComplianceTest.prototype.setResultDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.clearResultDate = function() {
  return this.setResultDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.hasResultDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string score = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getScore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.setScore = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string compliance_test_status_description = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.getComplianceTestStatusDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTest} returns this
 */
proto.wilqo.api.mortgage.ComplianceTest.prototype.setComplianceTestStatusDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ComplianceCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ComplianceCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subCategory: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actionDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    analysisDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    severity: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition}
 */
proto.wilqo.api.mortgage.ComplianceCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ComplianceCondition;
  return proto.wilqo.api.mortgage.ComplianceCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ComplianceCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition}
 */
proto.wilqo.api.mortgage.ComplianceCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubCategory(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnalysisDescription(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage.ComplianceConditionSeverityEnum} */ (reader.readEnum());
      msg.setSeverity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ComplianceCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ComplianceCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubCategory();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActionDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAnalysisDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSeverity();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_category = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getSubCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setSubCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string action_description = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getActionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setActionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string analysis_description = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getAnalysisDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setAnalysisDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional ComplianceConditionSeverityEnum severity = 8;
 * @return {!proto.wilqo.api.mortgage.ComplianceConditionSeverityEnum}
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.getSeverity = function() {
  return /** @type {!proto.wilqo.api.mortgage.ComplianceConditionSeverityEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceConditionSeverityEnum} value
 * @return {!proto.wilqo.api.mortgage.ComplianceCondition} returns this
 */
proto.wilqo.api.mortgage.ComplianceCondition.prototype.setSeverity = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UxDisplayBadge.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UxDisplayBadge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UxDisplayBadge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UxDisplayBadge.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UxDisplayBadge;
  return proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UxDisplayBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UxDisplayBadge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UxDisplayBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.UxDisplayBadge.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.UxDisplayBadge} returns this
 */
proto.wilqo.api.mortgage.UxDisplayBadge.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UxDisplayStatusEnum status = 2;
 * @return {!proto.wilqo.api.mortgage.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage.UxDisplayBadge.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.UxDisplayBadge} returns this
 */
proto.wilqo.api.mortgage.UxDisplayBadge.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ComplianceTestListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ComplianceTestListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    testsList: jspb.Message.toObjectList(msg.getTestsList(),
    proto.wilqo.api.mortgage.ComplianceTestListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ComplianceTestListItem;
  return proto.wilqo.api.mortgage.ComplianceTestListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ComplianceTestListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.ComplianceTestListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.ComplianceTestListItem.deserializeBinaryFromReader);
      msg.addTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ComplianceTestListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ComplianceTestListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage.ComplianceTestListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UxDisplayStatusEnum status = 3;
 * @return {!proto.wilqo.api.mortgage.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional UxDisplayBadge badge = 4;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ComplianceTestListItem tests = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.getTestsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ComplianceTestListItem, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ComplianceTestListItem>} value
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
*/
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.setTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ComplianceTestListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem}
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.addTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage.ComplianceTestListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ComplianceTestListItem} returns this
 */
proto.wilqo.api.mortgage.ComplianceTestListItem.prototype.clearTestsList = function() {
  return this.setTestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ChangeHistoryItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ChangeHistoryItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    entityJson: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actorName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ChangeHistoryItem;
  return proto.wilqo.api.mortgage.ChangeHistoryItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ChangeHistoryItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityJson(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ChangeHistoryItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ChangeHistoryItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActorName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
*/
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string entity_json = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.getEntityJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.setEntityJson = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.clearEntityJson = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.hasEntityJson = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string actor_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.getActorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ChangeHistoryItem} returns this
 */
proto.wilqo.api.mortgage.ChangeHistoryItem.prototype.setActorName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ProgressItem.repeatedFields_ = [4,11,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProgressItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProgressItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    loanPhasesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    consumerDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lenderDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    screenOrder: jspb.Message.getFieldWithDefault(msg, 8, 0),
    subText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    subTextLabel: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subTextItemsList: jspb.Message.toObjectList(msg.getSubTextItemsList(),
    proto.wilqo.api.mortgage.SubTextItem.toObject, includeInstance),
    pageId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 13, ""),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    subTextsList: jspb.Message.toObjectList(msg.getSubTextsList(),
    Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProgressItem}
 */
proto.wilqo.api.mortgage.ProgressItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProgressItem;
  return proto.wilqo.api.mortgage.ProgressItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProgressItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProgressItem}
 */
proto.wilqo.api.mortgage.ProgressItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.ProgressItemStatusEnum} */ (reader.readEnum());
      msg.setStatusType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoanPhases(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderDescription(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScreenOrder(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubTextLabel(value);
      break;
    case 11:
      var value = new proto.wilqo.api.mortgage.SubTextItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.SubTextItem.deserializeBinaryFromReader);
      msg.addSubTextItems(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 15:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue.deserializeBinaryFromReader);
      msg.addSubTexts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProgressItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProgressItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLoanPhasesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getConsumerDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLenderDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScreenOrder();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSubTextLabel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubTextItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.wilqo.api.mortgage.SubTextItem.serializeBinaryToWriter
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSubTextsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ProgressItemStatusEnum status_type = 3;
 * @return {!proto.wilqo.api.mortgage.ProgressItemStatusEnum}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProgressItemStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProgressItemStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string loan_phases = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getLoanPhasesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setLoanPhasesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.addLoanPhases = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.clearLoanPhasesList = function() {
  return this.setLoanPhasesList([]);
};


/**
 * optional UxDisplayBadge badge = 5;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
*/
proto.wilqo.api.mortgage.ProgressItem.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string consumer_description = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getConsumerDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setConsumerDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string lender_description = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getLenderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setLenderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 screen_order = 8;
 * @return {number}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getScreenOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setScreenOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string sub_text = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sub_text_label = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getSubTextLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setSubTextLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated SubTextItem sub_text_items = 11;
 * @return {!Array<!proto.wilqo.api.mortgage.SubTextItem>}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getSubTextItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.SubTextItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.SubTextItem, 11));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.SubTextItem>} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
*/
proto.wilqo.api.mortgage.ProgressItem.prototype.setSubTextItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.SubTextItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.SubTextItem}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.addSubTextItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.api.mortgage.SubTextItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.clearSubTextItemsList = function() {
  return this.setSubTextItemsList([]);
};


/**
 * optional string page_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string scope_token = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool disabled = 14;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated wilqo.shared.models.DisplayRenderableValue sub_texts = 15;
 * @return {!Array<!proto.wilqo.shared.models.DisplayRenderableValue>}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.getSubTextsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DisplayRenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.DisplayRenderableValue, 15));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DisplayRenderableValue>} value
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
*/
proto.wilqo.api.mortgage.ProgressItem.prototype.setSubTextsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.wilqo.shared.models.DisplayRenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue}
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.addSubTexts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.wilqo.shared.models.DisplayRenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ProgressItem} returns this
 */
proto.wilqo.api.mortgage.ProgressItem.prototype.clearSubTextsList = function() {
  return this.setSubTextsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SubTextItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SubTextItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SubTextItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubTextItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    subText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subTextLabel: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SubTextItem}
 */
proto.wilqo.api.mortgage.SubTextItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SubTextItem;
  return proto.wilqo.api.mortgage.SubTextItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SubTextItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SubTextItem}
 */
proto.wilqo.api.mortgage.SubTextItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubTextLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SubTextItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SubTextItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SubTextItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SubTextItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubTextLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string sub_text = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubTextItem.prototype.getSubText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubTextItem} returns this
 */
proto.wilqo.api.mortgage.SubTextItem.prototype.setSubText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sub_text_label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SubTextItem.prototype.getSubTextLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SubTextItem} returns this
 */
proto.wilqo.api.mortgage.SubTextItem.prototype.setSubTextLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PartySummaryLink.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PartySummaryLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PartySummaryLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartySummaryLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tabId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PartySummaryLink}
 */
proto.wilqo.api.mortgage.PartySummaryLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PartySummaryLink;
  return proto.wilqo.api.mortgage.PartySummaryLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PartySummaryLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PartySummaryLink}
 */
proto.wilqo.api.mortgage.PartySummaryLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PartySummaryLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PartySummaryLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PartySummaryLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartySummaryLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTabId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySummaryLink.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySummaryLink} returns this
 */
proto.wilqo.api.mortgage.PartySummaryLink.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tab_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySummaryLink.prototype.getTabId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySummaryLink} returns this
 */
proto.wilqo.api.mortgage.PartySummaryLink.prototype.setTabId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IntegrationHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IntegrationHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IntegrationHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    badge: (f = msg.getBadge()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    lastStatusDate: (f = msg.getLastStatusDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory}
 */
proto.wilqo.api.mortgage.IntegrationHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IntegrationHistory;
  return proto.wilqo.api.mortgage.IntegrationHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IntegrationHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory}
 */
proto.wilqo.api.mortgage.IntegrationHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastStatusDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IntegrationHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IntegrationHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IntegrationHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getLastStatusDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string integration_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getIntegrationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setIntegrationName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
*/
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UxDisplayBadge badge = 6;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
*/
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp last_status_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.getLastStatusDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
*/
proto.wilqo.api.mortgage.IntegrationHistory.prototype.setLastStatusDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IntegrationHistory} returns this
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.clearLastStatusDate = function() {
  return this.setLastStatusDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IntegrationHistory.prototype.hasLastStatusDate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeSummaryTotalFeesAmount: (f = msg.getFeeSummaryTotalFeesAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeeSummary}
 */
proto.wilqo.api.mortgage.FeeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeeSummary;
  return proto.wilqo.api.mortgage.FeeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeeSummary}
 */
proto.wilqo.api.mortgage.FeeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeSummaryTotalFeesAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeSummaryTotalFeesAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_summary_total_fees_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeSummary.prototype.getFeeSummaryTotalFeesAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeSummary} returns this
*/
proto.wilqo.api.mortgage.FeeSummary.prototype.setFeeSummaryTotalFeesAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeSummary} returns this
 */
proto.wilqo.api.mortgage.FeeSummary.prototype.clearFeeSummaryTotalFeesAmount = function() {
  return this.setFeeSummaryTotalFeesAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeSummary.prototype.hasFeeSummaryTotalFeesAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Fee.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Fee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Fee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Fee.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeDescription: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feeType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    feeSpecifiedFixedAmount: (f = msg.getFeeSpecifiedFixedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeTotalPercent: (f = msg.getFeeTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Fee}
 */
proto.wilqo.api.mortgage.Fee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Fee;
  return proto.wilqo.api.mortgage.Fee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Fee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Fee}
 */
proto.wilqo.api.mortgage.Fee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeDescription(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeEnum} */ (reader.readEnum());
      msg.setFeeType(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeSpecifiedFixedAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeTotalPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Fee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Fee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Fee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Fee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeeType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFeeSpecifiedFixedAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeTotalPercent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fee_description = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Fee.prototype.getFeeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Fee} returns this
 */
proto.wilqo.api.mortgage.Fee.prototype.setFeeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FeeEnum fee_type = 2;
 * @return {!proto.wilqo.api.mortgage.FeeEnum}
 */
proto.wilqo.api.mortgage.Fee.prototype.getFeeType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeEnum} value
 * @return {!proto.wilqo.api.mortgage.Fee} returns this
 */
proto.wilqo.api.mortgage.Fee.prototype.setFeeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_specified_fixed_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Fee.prototype.getFeeSpecifiedFixedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Fee} returns this
*/
proto.wilqo.api.mortgage.Fee.prototype.setFeeSpecifiedFixedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Fee} returns this
 */
proto.wilqo.api.mortgage.Fee.prototype.clearFeeSpecifiedFixedAmount = function() {
  return this.setFeeSpecifiedFixedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Fee.prototype.hasFeeSpecifiedFixedAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_total_percent = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.Fee.prototype.getFeeTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.Fee} returns this
*/
proto.wilqo.api.mortgage.Fee.prototype.setFeeTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Fee} returns this
 */
proto.wilqo.api.mortgage.Fee.prototype.clearFeeTotalPercent = function() {
  return this.setFeeTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Fee.prototype.hasFeeTotalPercent = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BankRetirementAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BankRetirementAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BankRetirementAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankRetirementAssetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    financialInstitution: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assetValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BankRetirementAsset;
  return proto.wilqo.api.mortgage.BankRetirementAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BankRetirementAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankRetirementAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFinancialInstitution(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.AssetBankRetirementEnum} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAssetValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BankRetirementAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BankRetirementAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BankRetirementAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankRetirementAssetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFinancialInstitution();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string bank_retirement_asset_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.getBankRetirementAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset} returns this
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.setBankRetirementAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string financial_institution = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.getFinancialInstitution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset} returns this
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.setFinancialInstitution = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AssetBankRetirementEnum account_type = 3;
 * @return {!proto.wilqo.api.mortgage.AssetBankRetirementEnum}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.getAccountType = function() {
  return /** @type {!proto.wilqo.api.mortgage.AssetBankRetirementEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AssetBankRetirementEnum} value
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset} returns this
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string account_number = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset} returns this
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double asset_value = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.getAssetValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.BankRetirementAsset} returns this
 */
proto.wilqo.api.mortgage.BankRetirementAsset.prototype.setAssetValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.OtherAssetAndCredit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.OtherAssetAndCredit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.toObject = function(includeInstance, msg) {
  var f, obj = {
    otherAssetCreditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appliesToLoan: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    assetType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    otherAssetCreditType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    assetValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.OtherAssetAndCredit;
  return proto.wilqo.api.mortgage.OtherAssetAndCredit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.OtherAssetAndCredit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherAssetCreditId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAppliesToLoan(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.OtherAssetAndCredit.AssetTypeEnum} */ (reader.readEnum());
      msg.setAssetType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.OtherAssetCreditTypeEnum} */ (reader.readEnum());
      msg.setOtherAssetCreditType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAssetValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.OtherAssetAndCredit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.OtherAssetAndCredit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOtherAssetCreditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppliesToLoan();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAssetType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOtherAssetCreditType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAssetValue();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.AssetTypeEnum = {
  ASSET: 0,
  CREDIT: 1
};

/**
 * optional string other_asset_credit_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.getOtherAssetCreditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit} returns this
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.setOtherAssetCreditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool applies_to_loan = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.getAppliesToLoan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit} returns this
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.setAppliesToLoan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional AssetTypeEnum asset_type = 3;
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit.AssetTypeEnum}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.getAssetType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OtherAssetAndCredit.AssetTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OtherAssetAndCredit.AssetTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit} returns this
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional OtherAssetCreditTypeEnum other_asset_credit_type = 4;
 * @return {!proto.wilqo.api.mortgage.OtherAssetCreditTypeEnum}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.getOtherAssetCreditType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OtherAssetCreditTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OtherAssetCreditTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit} returns this
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.setOtherAssetCreditType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional double asset_value = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.getAssetValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.OtherAssetAndCredit} returns this
 */
proto.wilqo.api.mortgage.OtherAssetAndCredit.prototype.setAssetValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditCardOtherDebtLeaseId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appliesToLoan: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    accountType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    companyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unpaidBalance: (f = msg.getUnpaidBalance()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidOffAtOrBeforeClosing: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease;
  return proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditCardOtherDebtLeaseId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAppliesToLoan(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.CreditCardOtherDebtLeaseEnum} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUnpaidBalance(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaidOffAtOrBeforeClosing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditCardOtherDebtLeaseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppliesToLoan();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnpaidBalance();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidOffAtOrBeforeClosing();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string credit_card_other_debt_lease_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getCreditCardOtherDebtLeaseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setCreditCardOtherDebtLeaseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool applies_to_loan = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getAppliesToLoan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setAppliesToLoan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional CreditCardOtherDebtLeaseEnum account_type = 3;
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtLeaseEnum}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getAccountType = function() {
  return /** @type {!proto.wilqo.api.mortgage.CreditCardOtherDebtLeaseEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.CreditCardOtherDebtLeaseEnum} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string company_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_number = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal unpaid_balance = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getUnpaidBalance = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
*/
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setUnpaidBalance = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.clearUnpaidBalance = function() {
  return this.setUnpaidBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.hasUnpaidBalance = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool paid_off_at_or_before_closing = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.getPaidOffAtOrBeforeClosing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease} returns this
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtAndLease.prototype.setPaidOffAtOrBeforeClosing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.toObject = function(includeInstance, msg) {
  var f, obj = {
    otherLiabilitiesAndExpensesId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    otherLiabilitiesExpenseType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    appliesToLoan: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense;
  return proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherLiabilitiesAndExpensesId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpensesTypeEnum} */ (reader.readEnum());
      msg.setOtherLiabilitiesExpenseType(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAppliesToLoan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOtherLiabilitiesAndExpensesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOtherLiabilitiesExpenseType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAppliesToLoan();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string other_liabilities_and_expenses_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.getOtherLiabilitiesAndExpensesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} returns this
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.setOtherLiabilitiesAndExpensesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OtherLiabilitiesAndExpensesTypeEnum other_liabilities_expense_type = 2;
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpensesTypeEnum}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.getOtherLiabilitiesExpenseType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpensesTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpensesTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} returns this
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.setOtherLiabilitiesExpenseType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} returns this
*/
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} returns this
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool applies_to_loan = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.getAppliesToLoan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense} returns this
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpense.prototype.setAppliesToLoan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TridSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TridSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TridSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    addresIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    addressPopulatedDate: (f = msg.getAddressPopulatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    loanAmountIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    loanAmountPopulatedDate: (f = msg.getLoanAmountPopulatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    incomeIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    incomePopulatedDate: (f = msg.getIncomePopulatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    estimatedPropertyValueIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    estimatedPropertyValuePopulatedDate: (f = msg.getEstimatedPropertyValuePopulatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    nameIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    namePopulatedDate: (f = msg.getNamePopulatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    socialSecurityNumberIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    socialSecurityNumberPopulatedDate: (f = msg.getSocialSecurityNumberPopulatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tridConditionsMetDate: (f = msg.getTridConditionsMetDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TridSummary}
 */
proto.wilqo.api.mortgage.TridSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TridSummary;
  return proto.wilqo.api.mortgage.TridSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TridSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TridSummary}
 */
proto.wilqo.api.mortgage.TridSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddresIndicator(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAddressPopulatedDate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoanAmountIndicator(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoanAmountPopulatedDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncomeIndicator(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIncomePopulatedDate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEstimatedPropertyValueIndicator(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEstimatedPropertyValuePopulatedDate(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNameIndicator(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNamePopulatedDate(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSocialSecurityNumberIndicator(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSocialSecurityNumberPopulatedDate(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTridConditionsMetDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TridSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TridSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TridSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddresIndicator();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAddressPopulatedDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLoanAmountIndicator();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLoanAmountPopulatedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIncomeIndicator();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIncomePopulatedDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedPropertyValueIndicator();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getEstimatedPropertyValuePopulatedDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNameIndicator();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getNamePopulatedDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSocialSecurityNumberIndicator();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSocialSecurityNumberPopulatedDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTridConditionsMetDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool addres_indicator = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getAddresIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.setAddresIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp address_populated_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getAddressPopulatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setAddressPopulatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearAddressPopulatedDate = function() {
  return this.setAddressPopulatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasAddressPopulatedDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool loan_amount_indicator = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getLoanAmountIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.setLoanAmountIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp loan_amount_populated_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getLoanAmountPopulatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setLoanAmountPopulatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearLoanAmountPopulatedDate = function() {
  return this.setLoanAmountPopulatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasLoanAmountPopulatedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool income_indicator = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getIncomeIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.setIncomeIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp income_populated_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getIncomePopulatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setIncomePopulatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearIncomePopulatedDate = function() {
  return this.setIncomePopulatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasIncomePopulatedDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool estimated_property_value_indicator = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getEstimatedPropertyValueIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.setEstimatedPropertyValueIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp estimated_property_value_populated_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getEstimatedPropertyValuePopulatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setEstimatedPropertyValuePopulatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearEstimatedPropertyValuePopulatedDate = function() {
  return this.setEstimatedPropertyValuePopulatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasEstimatedPropertyValuePopulatedDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool name_indicator = 9;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getNameIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.setNameIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp name_populated_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getNamePopulatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setNamePopulatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearNamePopulatedDate = function() {
  return this.setNamePopulatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasNamePopulatedDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool social_security_number_indicator = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getSocialSecurityNumberIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.setSocialSecurityNumberIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp social_security_number_populated_date = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getSocialSecurityNumberPopulatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setSocialSecurityNumberPopulatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearSocialSecurityNumberPopulatedDate = function() {
  return this.setSocialSecurityNumberPopulatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasSocialSecurityNumberPopulatedDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp trid_conditions_met_date = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.getTridConditionsMetDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
*/
proto.wilqo.api.mortgage.TridSummary.prototype.setTridConditionsMetDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridSummary} returns this
 */
proto.wilqo.api.mortgage.TridSummary.prototype.clearTridConditionsMetDate = function() {
  return this.setTridConditionsMetDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridSummary.prototype.hasTridConditionsMetDate = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AusUxDisplay.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusUxDisplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusUxDisplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusUxDisplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ausType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ausResultList: jspb.Message.toObjectList(msg.getAusResultList(),
    proto.wilqo.api.mortgage.AusResult.toObject, includeInstance),
    automatedUnderwritingId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay}
 */
proto.wilqo.api.mortgage.AusUxDisplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusUxDisplay;
  return proto.wilqo.api.mortgage.AusUxDisplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusUxDisplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay}
 */
proto.wilqo.api.mortgage.AusUxDisplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.AusResultTypeEnum} */ (reader.readEnum());
      msg.setAusType(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.AusResult;
      reader.readMessage(value,proto.wilqo.api.mortgage.AusResult.deserializeBinaryFromReader);
      msg.addAusResult(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomatedUnderwritingId(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusUxDisplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusUxDisplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusUxDisplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAusType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAusResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.AusResult.serializeBinaryToWriter
    );
  }
  f = message.getAutomatedUnderwritingId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UxDisplayStatusEnum status = 2;
 * @return {!proto.wilqo.api.mortgage.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional AusResultTypeEnum aus_type = 3;
 * @return {!proto.wilqo.api.mortgage.AusResultTypeEnum}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.getAusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.AusResultTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AusResultTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.setAusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated AusResult aus_result = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.AusResult>}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.getAusResultList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.AusResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.AusResult, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusResult>} value
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
*/
proto.wilqo.api.mortgage.AusUxDisplay.prototype.setAusResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AusResult}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.addAusResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.AusResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.clearAusResultList = function() {
  return this.setAusResultList([]);
};


/**
 * optional string automated_underwriting_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.getAutomatedUnderwritingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.setAutomatedUnderwritingId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 6;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 6));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
*/
proto.wilqo.api.mortgage.AusUxDisplay.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AusUxDisplay} returns this
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusUxDisplay.prototype.hasError = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusResult.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    characteristic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    desktopUnderwriter: (f = msg.getDesktopUnderwriter()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    loanProspector: (f = msg.getLoanProspector()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    desktopUnderwriterId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loanProspectorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    desktopUnderwriterLoanProspector: jspb.Message.getFieldWithDefault(msg, 6, ""),
    displayBadge: (f = msg.getDisplayBadge()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusResult}
 */
proto.wilqo.api.mortgage.AusResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusResult;
  return proto.wilqo.api.mortgage.AusResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusResult}
 */
proto.wilqo.api.mortgage.AusResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCharacteristic(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setDesktopUnderwriter(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setLoanProspector(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopUnderwriterId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProspectorId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesktopUnderwriterLoanProspector(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setDisplayBadge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCharacteristic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDesktopUnderwriter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getLoanProspector();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getDesktopUnderwriterId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoanProspectorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDesktopUnderwriterLoanProspector();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDisplayBadge();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string characteristic = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getCharacteristic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.setCharacteristic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UxDisplayBadge desktop_underwriter = 2;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getDesktopUnderwriter = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
*/
proto.wilqo.api.mortgage.AusResult.prototype.setDesktopUnderwriter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.clearDesktopUnderwriter = function() {
  return this.setDesktopUnderwriter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusResult.prototype.hasDesktopUnderwriter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UxDisplayBadge loan_prospector = 3;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getLoanProspector = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
*/
proto.wilqo.api.mortgage.AusResult.prototype.setLoanProspector = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.clearLoanProspector = function() {
  return this.setLoanProspector(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusResult.prototype.hasLoanProspector = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string desktop_underwriter_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getDesktopUnderwriterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.setDesktopUnderwriterId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string loan_prospector_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getLoanProspectorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.setLoanProspectorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string desktop_underwriter_loan_prospector = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getDesktopUnderwriterLoanProspector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.setDesktopUnderwriterLoanProspector = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional UxDisplayBadge display_badge = 8;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.AusResult.prototype.getDisplayBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
*/
proto.wilqo.api.mortgage.AusResult.prototype.setDisplayBadge = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AusResult} returns this
 */
proto.wilqo.api.mortgage.AusResult.prototype.clearDisplayBadge = function() {
  return this.setDisplayBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusResult.prototype.hasDisplayBadge = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusUxOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusUxOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusUxOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusUxOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusUxOption}
 */
proto.wilqo.api.mortgage.AusUxOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusUxOption;
  return proto.wilqo.api.mortgage.AusUxOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusUxOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusUxOption}
 */
proto.wilqo.api.mortgage.AusUxOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.AusTypeEnum} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusUxOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusUxOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusUxOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusUxOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AusTypeEnum id = 1;
 * @return {!proto.wilqo.api.mortgage.AusTypeEnum}
 */
proto.wilqo.api.mortgage.AusUxOption.prototype.getId = function() {
  return /** @type {!proto.wilqo.api.mortgage.AusTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AusTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.AusUxOption} returns this
 */
proto.wilqo.api.mortgage.AusUxOption.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusUxOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusUxOption} returns this
 */
proto.wilqo.api.mortgage.AusUxOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.AusProviderOptions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusProviderOptions.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusProviderOptions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusProviderOptions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusProviderOptions.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusProviderOptions}
 */
proto.wilqo.api.mortgage.AusProviderOptions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusProviderOptions;
  return proto.wilqo.api.mortgage.AusProviderOptions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusProviderOptions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusProviderOptions}
 */
proto.wilqo.api.mortgage.AusProviderOptions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.AusProvider>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderOptions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusProviderOptions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusProviderOptions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusProviderOptions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusProviderOptions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderOptionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated AusProvider provider_options = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.AusProvider>}
 */
proto.wilqo.api.mortgage.AusProviderOptions.prototype.getProviderOptionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.AusProvider>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AusProvider>} value
 * @return {!proto.wilqo.api.mortgage.AusProviderOptions} returns this
 */
proto.wilqo.api.mortgage.AusProviderOptions.prototype.setProviderOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.AusProvider} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.AusProviderOptions} returns this
 */
proto.wilqo.api.mortgage.AusProviderOptions.prototype.addProviderOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.AusProviderOptions} returns this
 */
proto.wilqo.api.mortgage.AusProviderOptions.prototype.clearProviderOptionsList = function() {
  return this.setProviderOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PaidByItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PaidByItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PaidByItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PaidByItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidByItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PaidByItem;
  return proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PaidByItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PaidByItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PaidByItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PaidByItem.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PaidByItem} returns this
 */
proto.wilqo.api.mortgage.PaidByItem.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PaidByItem.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PaidByItem} returns this
 */
proto.wilqo.api.mortgage.PaidByItem.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PaidBy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PaidBy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PaidBy.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrower: (f = msg.getBorrower()) && proto.wilqo.api.mortgage.PaidByItem.toObject(includeInstance, f),
    seller: (f = msg.getSeller()) && proto.wilqo.api.mortgage.PaidByItem.toObject(includeInstance, f),
    broker: (f = msg.getBroker()) && proto.wilqo.api.mortgage.PaidByItem.toObject(includeInstance, f),
    lender: (f = msg.getLender()) && proto.wilqo.api.mortgage.PaidByItem.toObject(includeInstance, f),
    other: (f = msg.getOther()) && proto.wilqo.api.mortgage.PaidByItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PaidBy}
 */
proto.wilqo.api.mortgage.PaidBy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PaidBy;
  return proto.wilqo.api.mortgage.PaidBy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PaidBy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PaidBy}
 */
proto.wilqo.api.mortgage.PaidBy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.PaidByItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader);
      msg.setBorrower(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.PaidByItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader);
      msg.setSeller(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.PaidByItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader);
      msg.setBroker(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.PaidByItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader);
      msg.setLender(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.PaidByItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidByItem.deserializeBinaryFromReader);
      msg.setOther(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PaidBy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PaidBy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PaidBy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrower();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter
    );
  }
  f = message.getSeller();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter
    );
  }
  f = message.getBroker();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter
    );
  }
  f = message.getLender();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter
    );
  }
  f = message.getOther();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.PaidByItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional PaidByItem borrower = 1;
 * @return {?proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.getBorrower = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidByItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidByItem, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidByItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
*/
proto.wilqo.api.mortgage.PaidBy.prototype.setBorrower = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
 */
proto.wilqo.api.mortgage.PaidBy.prototype.clearBorrower = function() {
  return this.setBorrower(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.hasBorrower = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PaidByItem seller = 2;
 * @return {?proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.getSeller = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidByItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidByItem, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidByItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
*/
proto.wilqo.api.mortgage.PaidBy.prototype.setSeller = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
 */
proto.wilqo.api.mortgage.PaidBy.prototype.clearSeller = function() {
  return this.setSeller(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.hasSeller = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PaidByItem broker = 3;
 * @return {?proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.getBroker = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidByItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidByItem, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidByItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
*/
proto.wilqo.api.mortgage.PaidBy.prototype.setBroker = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
 */
proto.wilqo.api.mortgage.PaidBy.prototype.clearBroker = function() {
  return this.setBroker(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.hasBroker = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PaidByItem lender = 4;
 * @return {?proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.getLender = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidByItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidByItem, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidByItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
*/
proto.wilqo.api.mortgage.PaidBy.prototype.setLender = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
 */
proto.wilqo.api.mortgage.PaidBy.prototype.clearLender = function() {
  return this.setLender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.hasLender = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PaidByItem other = 5;
 * @return {?proto.wilqo.api.mortgage.PaidByItem}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.getOther = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidByItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidByItem, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidByItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
*/
proto.wilqo.api.mortgage.PaidBy.prototype.setOther = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PaidBy} returns this
 */
proto.wilqo.api.mortgage.PaidBy.prototype.clearOther = function() {
  return this.setOther(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PaidBy.prototype.hasOther = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    beforeclosing: jspb.Message.getFieldWithDefault(msg, 1, ""),
    atclosing: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tolerance: jspb.Message.getFieldWithDefault(msg, 4, ""),
    paidby: (f = msg.getPaidby()) && proto.wilqo.api.mortgage.PaidBy.toObject(includeInstance, f),
    borrowercanshop: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    borrowerdidshop: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    impactsapr: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    addedby: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeeItem}
 */
proto.wilqo.api.mortgage.FeeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeeItem;
  return proto.wilqo.api.mortgage.FeeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeeItem}
 */
proto.wilqo.api.mortgage.FeeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeforeclosing(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtclosing(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTolerance(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.PaidBy;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidBy.deserializeBinaryFromReader);
      msg.setPaidby(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowercanshop(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerdidshop(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setImpactsapr(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddedby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBeforeclosing();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAtclosing();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTolerance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPaidby();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.PaidBy.serializeBinaryToWriter
    );
  }
  f = message.getBorrowercanshop();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getBorrowerdidshop();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getImpactsapr();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getAddedby();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string beforeClosing = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getBeforeclosing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setBeforeclosing = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string atClosing = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getAtclosing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setAtclosing = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tolerance = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getTolerance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setTolerance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PaidBy paidBy = 5;
 * @return {?proto.wilqo.api.mortgage.PaidBy}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getPaidby = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidBy} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidBy, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidBy|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
*/
proto.wilqo.api.mortgage.FeeItem.prototype.setPaidby = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.clearPaidby = function() {
  return this.setPaidby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.hasPaidby = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool borrowerCanShop = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getBorrowercanshop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setBorrowercanshop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool borrowerDidShop = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getBorrowerdidshop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setBorrowerdidshop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool impactsAPR = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getImpactsapr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setImpactsapr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string addedBy = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeItem.prototype.getAddedby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeItem} returns this
 */
proto.wilqo.api.mortgage.FeeItem.prototype.setAddedby = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FeeSection.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeeSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeeSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerpaid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lenderpaid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sellerpaid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    total: jspb.Message.getFieldWithDefault(msg, 6, ""),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.wilqo.api.mortgage.FeeItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeeSection}
 */
proto.wilqo.api.mortgage.FeeSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeeSection;
  return proto.wilqo.api.mortgage.FeeSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeeSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeeSection}
 */
proto.wilqo.api.mortgage.FeeSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerpaid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderpaid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellerpaid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotal(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage.FeeItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.FeeItem.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeeSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeeSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerpaid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLenderpaid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSellerpaid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTotal();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage.FeeItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
 */
proto.wilqo.api.mortgage.FeeSection.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string borrowerPaid = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.getBorrowerpaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
 */
proto.wilqo.api.mortgage.FeeSection.prototype.setBorrowerpaid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lenderPaid = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.getLenderpaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
 */
proto.wilqo.api.mortgage.FeeSection.prototype.setLenderpaid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sellerPaid = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.getSellerpaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
 */
proto.wilqo.api.mortgage.FeeSection.prototype.setSellerpaid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string total = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.getTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
 */
proto.wilqo.api.mortgage.FeeSection.prototype.setTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated FeeItem fees = 7;
 * @return {!Array<!proto.wilqo.api.mortgage.FeeItem>}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeeItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FeeItem, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeeItem>} value
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
*/
proto.wilqo.api.mortgage.FeeSection.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeeItem}
 */
proto.wilqo.api.mortgage.FeeSection.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage.FeeItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FeeSection} returns this
 */
proto.wilqo.api.mortgage.FeeSection.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DocumentCategory.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    typesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    docTypesList: jspb.Message.toObjectList(msg.getDocTypesList(),
    proto.wilqo.api.mortgage.DocType.toObject, includeInstance),
    lastEditedDate: (f = msg.getLastEditedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentCategory}
 */
proto.wilqo.api.mortgage.DocumentCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentCategory;
  return proto.wilqo.api.mortgage.DocumentCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentCategory}
 */
proto.wilqo.api.mortgage.DocumentCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage.DocType;
      reader.readMessage(value,proto.wilqo.api.mortgage.DocType.deserializeBinaryFromReader);
      msg.addDocTypes(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastEditedDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getDocTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage.DocType.serializeBinaryToWriter
    );
  }
  f = message.getLastEditedDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DocumentCategoryEnum category_type = 2;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated DocumentEnum types = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentEnum>}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentEnum>} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * repeated DocType doc_types = 7;
 * @return {!Array<!proto.wilqo.api.mortgage.DocType>}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getDocTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DocType, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocType>} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
*/
proto.wilqo.api.mortgage.DocumentCategory.prototype.setDocTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocType}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.addDocTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage.DocType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.clearDocTypesList = function() {
  return this.setDocTypesList([]);
};


/**
 * optional google.protobuf.Timestamp last_edited_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.getLastEditedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
*/
proto.wilqo.api.mortgage.DocumentCategory.prototype.setLastEditedDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocumentCategory} returns this
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.clearLastEditedDate = function() {
  return this.setLastEditedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentCategory.prototype.hasLastEditedDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocType.toObject = function(includeInstance, msg) {
  var f, obj = {
    docType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentTypeName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastEditedDate: (f = msg.getLastEditedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isConsumerFacing: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocType}
 */
proto.wilqo.api.mortgage.DocType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocType;
  return proto.wilqo.api.mortgage.DocType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocType}
 */
proto.wilqo.api.mortgage.DocType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setDocType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTypeName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastEditedDate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConsumerFacing(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentTypeName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastEditedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsConsumerFacing();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional DocumentEnum doc_type = 1;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.DocType.prototype.getDocType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
 */
proto.wilqo.api.mortgage.DocType.prototype.setDocType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string external_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocType.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
 */
proto.wilqo.api.mortgage.DocType.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string document_type_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocType.prototype.getDocumentTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
 */
proto.wilqo.api.mortgage.DocType.prototype.setDocumentTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_edited_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DocType.prototype.getLastEditedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
*/
proto.wilqo.api.mortgage.DocType.prototype.setLastEditedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
 */
proto.wilqo.api.mortgage.DocType.prototype.clearLastEditedDate = function() {
  return this.setLastEditedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocType.prototype.hasLastEditedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_consumer_facing = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocType.prototype.getIsConsumerFacing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
 */
proto.wilqo.api.mortgage.DocType.prototype.setIsConsumerFacing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocType} returns this
 */
proto.wilqo.api.mortgage.DocType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue}
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentEnumDescriptionValue;
  return proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue}
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue} returns this
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DocumentEnum value = 2;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.prototype.getValue = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.DocumentEnumDescriptionValue} returns this
 */
proto.wilqo.api.mortgage.DocumentEnumDescriptionValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PartyType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PartyType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PartyType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartyType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roleName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mismoRoleType: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PartyType}
 */
proto.wilqo.api.mortgage.PartyType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PartyType;
  return proto.wilqo.api.mortgage.PartyType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PartyType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PartyType}
 */
proto.wilqo.api.mortgage.PartyType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMismoRoleType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PartyType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PartyType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PartyType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartyType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMismoRoleType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyType} returns this
 */
proto.wilqo.api.mortgage.PartyType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyType.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyType} returns this
 */
proto.wilqo.api.mortgage.PartyType.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string role_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyType.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyType} returns this
 */
proto.wilqo.api.mortgage.PartyType.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mismo_role_type = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyType.prototype.getMismoRoleType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyType} returns this
 */
proto.wilqo.api.mortgage.PartyType.prototype.setMismoRoleType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerPaid: (f = msg.getBorrowerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    sellerPaid: (f = msg.getSellerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lenderPaid: (f = msg.getLenderPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    otherPaid: (f = msg.getOtherPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidTo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isApr: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    total: (f = msg.getTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    basedOn: jspb.Message.getFieldWithDefault(msg, 10, 0),
    source: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sourceMessage: jspb.Message.getFieldWithDefault(msg, 12, ""),
    paidBySellerCreditAmount: (f = msg.getPaidBySellerCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalPercent: (f = msg.getTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePayment: (f = msg.getFeePayment()) && proto.wilqo.api.mortgage.IndividualFeePayment.toObject(includeInstance, f),
    specialCase: jspb.Message.getFieldWithDefault(msg, 16, 0),
    isNetFeeFromFundingWire: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanFee}
 */
proto.wilqo.api.mortgage.LoanFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanFee;
  return proto.wilqo.api.mortgage.LoanFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanFee}
 */
proto.wilqo.api.mortgage.LoanFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBorrowerPaid(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSellerPaid(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLenderPaid(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOtherPaid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidTo(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApr(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.FeePercentBasisEnum} */ (reader.readEnum());
      msg.setBasedOn(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceMessage(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerCreditAmount(value);
      break;
    case 14:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalPercent(value);
      break;
    case 15:
      var value = new proto.wilqo.api.mortgage.IndividualFeePayment;
      reader.readMessage(value,proto.wilqo.api.mortgage.IndividualFeePayment.deserializeBinaryFromReader);
      msg.setFeePayment(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} */ (reader.readEnum());
      msg.setSpecialCase(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNetFeeFromFundingWire(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerPaid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSellerPaid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLenderPaid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOtherPaid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidTo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsApr();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBasedOn();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSourceMessage();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPaidBySellerCreditAmount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalPercent();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePayment();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.wilqo.api.mortgage.IndividualFeePayment.serializeBinaryToWriter
    );
  }
  f = message.getSpecialCase();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getIsNetFeeFromFundingWire();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal borrower_paid = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getBorrowerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setBorrowerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearBorrowerPaid = function() {
  return this.setBorrowerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasBorrowerPaid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal seller_paid = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getSellerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setSellerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearSellerPaid = function() {
  return this.setSellerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasSellerPaid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal lender_paid = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getLenderPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setLenderPaid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearLenderPaid = function() {
  return this.setLenderPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasLenderPaid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal other_paid = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getOtherPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setOtherPaid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearOtherPaid = function() {
  return this.setOtherPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasOtherPaid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string paid_to = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getPaidTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setPaidTo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_apr = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getIsApr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setIsApr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string fee_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional FeePercentBasisEnum based_on = 10;
 * @return {!proto.wilqo.api.mortgage.FeePercentBasisEnum}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getBasedOn = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeePercentBasisEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeePercentBasisEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setBasedOn = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional FeeSourceEnum source = 11;
 * @return {!proto.wilqo.api.mortgage.FeeSourceEnum}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getSource = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeSourceEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string source_message = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getSourceMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setSourceMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_credit_amount = 13;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getPaidBySellerCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 13));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setPaidBySellerCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearPaidBySellerCreditAmount = function() {
  return this.setPaidBySellerCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasPaidBySellerCreditAmount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_percent = 14;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 14));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearTotalPercent = function() {
  return this.setTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasTotalPercent = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional IndividualFeePayment fee_payment = 15;
 * @return {?proto.wilqo.api.mortgage.IndividualFeePayment}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getFeePayment = function() {
  return /** @type{?proto.wilqo.api.mortgage.IndividualFeePayment} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.IndividualFeePayment, 15));
};


/**
 * @param {?proto.wilqo.api.mortgage.IndividualFeePayment|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
*/
proto.wilqo.api.mortgage.LoanFee.prototype.setFeePayment = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.clearFeePayment = function() {
  return this.setFeePayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.hasFeePayment = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional LoanFeeSpecialCaseEnum special_case = 16;
 * @return {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getSpecialCase = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setSpecialCase = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional bool is_net_fee_from_funding_wire = 17;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFee.prototype.getIsNetFeeFromFundingWire = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanFee} returns this
 */
proto.wilqo.api.mortgage.LoanFee.prototype.setIsNetFeeFromFundingWire = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanFeeCategory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanFeeCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFeeCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sumTotal: (f = msg.getSumTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.wilqo.api.mortgage.LoanFee.toObject, includeInstance),
    categoryType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanFeeCategory;
  return proto.wilqo.api.mortgage.LoanFeeCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSumTotal(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.LoanFee;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanFee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanFeeCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFeeCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSumTotal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.LoanFee.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal sum_total = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.getSumTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory} returns this
*/
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.setSumTotal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.clearSumTotal = function() {
  return this.setSumTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.hasSumTotal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LoanFee fees = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanFee>}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanFee, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanFee>} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory} returns this
*/
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanFee}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};


/**
 * optional IntegratedDisclosureSectionEnum category_type = 4;
 * @return {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.getCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanFeeCategory.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanFeeDataSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanFeeDataSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemslistList: jspb.Message.toObjectList(msg.getItemslistList(),
    proto.wilqo.api.mortgage.LoanFeeCategory.toObject, includeInstance),
    totalborrowerpaid: (f = msg.getTotalborrowerpaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalsellerpaid: (f = msg.getTotalsellerpaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totallenderpaid: (f = msg.getTotallenderpaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalotherpaid: (f = msg.getTotalotherpaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalnonaprfeespaid: (f = msg.getTotalnonaprfeespaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalaprfeespaid: (f = msg.getTotalaprfeespaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    beforeclosingamount: (f = msg.getBeforeclosingamount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    atclosingamount: (f = msg.getAtclosingamount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanFeeDataSummary;
  return proto.wilqo.api.mortgage.LoanFeeDataSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanFeeDataSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.LoanFeeCategory;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanFeeCategory.deserializeBinaryFromReader);
      msg.addItemslist(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalborrowerpaid(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalsellerpaid(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotallenderpaid(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalotherpaid(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalnonaprfeespaid(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalaprfeespaid(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBeforeclosingamount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAtclosingamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanFeeDataSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanFeeDataSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.LoanFeeCategory.serializeBinaryToWriter
    );
  }
  f = message.getTotalborrowerpaid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalsellerpaid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotallenderpaid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalotherpaid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalnonaprfeespaid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalaprfeespaid();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getBeforeclosingamount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAtclosingamount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LoanFeeCategory itemsList = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getItemslistList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanFeeCategory, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setItemslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.addItemslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.LoanFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearItemslistList = function() {
  return this.setItemslistList([]);
};


/**
 * optional wilqo.shared.models.ProtoDecimal totalBorrowerPaid = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getTotalborrowerpaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setTotalborrowerpaid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearTotalborrowerpaid = function() {
  return this.setTotalborrowerpaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasTotalborrowerpaid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal totalSellerPaid = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getTotalsellerpaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setTotalsellerpaid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearTotalsellerpaid = function() {
  return this.setTotalsellerpaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasTotalsellerpaid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal totalLenderPaid = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getTotallenderpaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setTotallenderpaid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearTotallenderpaid = function() {
  return this.setTotallenderpaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasTotallenderpaid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal totalOtherPaid = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getTotalotherpaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setTotalotherpaid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearTotalotherpaid = function() {
  return this.setTotalotherpaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasTotalotherpaid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal totalNonAprFeesPaid = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getTotalnonaprfeespaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setTotalnonaprfeespaid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearTotalnonaprfeespaid = function() {
  return this.setTotalnonaprfeespaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasTotalnonaprfeespaid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal totalAprFeesPaid = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getTotalaprfeespaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setTotalaprfeespaid = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearTotalaprfeespaid = function() {
  return this.setTotalaprfeespaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasTotalaprfeespaid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal BeforeClosingAmount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getBeforeclosingamount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setBeforeclosingamount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearBeforeclosingamount = function() {
  return this.setBeforeclosingamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasBeforeclosingamount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal AtClosingAmount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.getAtclosingamount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.setAtclosingamount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFeeDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.clearAtclosingamount = function() {
  return this.setAtclosingamount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFeeDataSummary.prototype.hasAtclosingamount = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanFloodDataSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanFloodDataSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    floodInsuranceRequired: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dateOfDetermination: (f = msg.getDateOfDetermination()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    floodZone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    letterOfMapChange: jspb.Message.getFieldWithDefault(msg, 4, ""),
    letterOfMapChangeDate: (f = msg.getLetterOfMapChangeDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    floodDataRevisionCaseNumber: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanFloodDataSummary;
  return proto.wilqo.api.mortgage.LoanFloodDataSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanFloodDataSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFloodInsuranceRequired(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateOfDetermination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloodZone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLetterOfMapChange(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLetterOfMapChangeDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloodDataRevisionCaseNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanFloodDataSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanFloodDataSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloodInsuranceRequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDateOfDetermination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFloodZone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLetterOfMapChange();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLetterOfMapChangeDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFloodDataRevisionCaseNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool flood_insurance_required = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.getFloodInsuranceRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.setFloodInsuranceRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date_of_determination = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.getDateOfDetermination = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.setDateOfDetermination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.clearDateOfDetermination = function() {
  return this.setDateOfDetermination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.hasDateOfDetermination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string flood_zone = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.getFloodZone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.setFloodZone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string letter_of_map_change = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.getLetterOfMapChange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.setLetterOfMapChange = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp letter_of_map_change_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.getLetterOfMapChangeDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.setLetterOfMapChangeDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.clearLetterOfMapChangeDate = function() {
  return this.setLetterOfMapChangeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.hasLetterOfMapChangeDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string flood_data_revision_case_number = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.getFloodDataRevisionCaseNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanFloodDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanFloodDataSummary.prototype.setFloodDataRevisionCaseNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.MismoType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.MismoType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.MismoType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.MismoType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.MismoType}
 */
proto.wilqo.api.mortgage.MismoType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.MismoType;
  return proto.wilqo.api.mortgage.MismoType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.MismoType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.MismoType}
 */
proto.wilqo.api.mortgage.MismoType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.MismoType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.MismoType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.MismoType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.MismoType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DocumentEnum type = 1;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.MismoType.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.MismoType} returns this
 */
proto.wilqo.api.mortgage.MismoType.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.MismoType.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.MismoType} returns this
 */
proto.wilqo.api.mortgage.MismoType.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RolesAndInformationInParty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RolesAndInformationInParty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roleType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roleName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    informationPanelElementsList: jspb.Message.toObjectList(msg.getInformationPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance),
    helperText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RolesAndInformationInParty;
  return proto.wilqo.api.mortgage.RolesAndInformationInParty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RolesAndInformationInParty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (reader.readEnum());
      msg.setRoleType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addInformationPanelElements(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelperText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RolesAndInformationInParty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RolesAndInformationInParty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoleType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInformationPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getHelperText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string category_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PartyRoleEnum role_type = 3;
 * @return {!proto.wilqo.api.mortgage.PartyRoleEnum}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.getRoleType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PartyRoleEnum} value
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.setRoleType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string role_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated wilqo.shared.models.PanelElement information_panel_elements = 5;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.getInformationPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
*/
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.setInformationPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.addInformationPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.clearInformationPanelElementsList = function() {
  return this.setInformationPanelElementsList([]);
};


/**
 * optional string helper_text = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.getHelperText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RolesAndInformationInParty} returns this
 */
proto.wilqo.api.mortgage.RolesAndInformationInParty.prototype.setHelperText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentCategorySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentCategorySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.wilqo.api.mortgage.DocumentSummary.toObject, includeInstance),
    categoryType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentCategorySummary;
  return proto.wilqo.api.mortgage.DocumentCategorySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentCategorySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.DocumentSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.DocumentSummary.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setCategoryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentCategorySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentCategorySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.DocumentSummary.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary} returns this
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary} returns this
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DocumentSummary documents = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentSummary>}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DocumentSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentSummary>} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary} returns this
*/
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentSummary}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.DocumentSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary} returns this
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional DocumentCategoryEnum category_type = 4;
 * @return {!proto.wilqo.api.mortgage.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.getCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.DocumentCategorySummary} returns this
 */
proto.wilqo.api.mortgage.DocumentCategorySummary.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.Document.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sourceDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdDatetime: (f = msg.getCreatedDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    loanEntityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    documentStatusType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    documentStatusDatetime: (f = msg.getDocumentStatusDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    storageType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    organizationId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    progressItemTemplateIdentifier: jspb.Message.getFieldWithDefault(msg, 12, ""),
    documentExpirationDatetime: (f = msg.getDocumentExpirationDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentEffectiveDate: (f = msg.getDocumentEffectiveDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validPeriod: jspb.Message.getFieldWithDefault(msg, 15, 0),
    documentCategoryId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    documentUploadedByUserId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    visibleToConsumerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    documentTypeId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Document}
 */
proto.wilqo.api.mortgage.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Document;
  return proto.wilqo.api.mortgage.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Document}
 */
proto.wilqo.api.mortgage.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDescription(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDatetime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (reader.readEnum());
      msg.setDocumentStatusType(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDocumentStatusDatetime(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.StorageTypeEnum} */ (reader.readEnum());
      msg.setStorageType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressItemTemplateIdentifier(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDocumentExpirationDatetime(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDocumentEffectiveDate(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (reader.readEnum());
      msg.setValidPeriod(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentCategoryId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUploadedByUserId(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleToConsumerIndicator(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTypeId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSourceDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedDatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLoanEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDocumentStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDocumentStatusDatetime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStorageType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getProgressItemTemplateIdentifier();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDocumentExpirationDatetime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidPeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getDocumentCategoryId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDocumentUploadedByUserId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVisibleToConsumerIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDocumentTypeId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string document_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DocumentEnum document_type = 3;
 * @return {!proto.wilqo.api.mortgage.DocumentEnum}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string source_description = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getSourceDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setSourceDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_datetime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.Document.prototype.getCreatedDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
*/
proto.wilqo.api.mortgage.Document.prototype.setCreatedDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.clearCreatedDatetime = function() {
  return this.setCreatedDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Document.prototype.hasCreatedDatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string loan_entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getLoanEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setLoanEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string document_url = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DocumentStatusEnum document_status_type = 8;
 * @return {!proto.wilqo.api.mortgage.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp document_status_datetime = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentStatusDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
*/
proto.wilqo.api.mortgage.Document.prototype.setDocumentStatusDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.clearDocumentStatusDatetime = function() {
  return this.setDocumentStatusDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Document.prototype.hasDocumentStatusDatetime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional StorageTypeEnum storage_type = 10;
 * @return {!proto.wilqo.api.mortgage.StorageTypeEnum}
 */
proto.wilqo.api.mortgage.Document.prototype.getStorageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.StorageTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.StorageTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setStorageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string organization_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string progress_item_template_identifier = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getProgressItemTemplateIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setProgressItemTemplateIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp document_expiration_datetime = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentExpirationDatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
*/
proto.wilqo.api.mortgage.Document.prototype.setDocumentExpirationDatetime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.clearDocumentExpirationDatetime = function() {
  return this.setDocumentExpirationDatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Document.prototype.hasDocumentExpirationDatetime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp document_effective_date = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentEffectiveDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
*/
proto.wilqo.api.mortgage.Document.prototype.setDocumentEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.clearDocumentEffectiveDate = function() {
  return this.setDocumentEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Document.prototype.hasDocumentEffectiveDate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional DocumentValidPeriodInDaysEnum valid_period = 15;
 * @return {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum}
 */
proto.wilqo.api.mortgage.Document.prototype.getValidPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setValidPeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string document_category_id = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string document_uploaded_by_user_id = 17;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentUploadedByUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentUploadedByUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool visible_to_consumer_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Document.prototype.getVisibleToConsumerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setVisibleToConsumerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string document_type_id = 19;
 * @return {string}
 */
proto.wilqo.api.mortgage.Document.prototype.getDocumentTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setDocumentTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * repeated string borrower_ids = 20;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.Document.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.Document} returns this
 */
proto.wilqo.api.mortgage.Document.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DisclosureDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DisclosureDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DisclosureDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    recipients: jspb.Message.getFieldWithDefault(msg, 3, 0),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusDate: (f = msg.getStatusDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument}
 */
proto.wilqo.api.mortgage.DisclosureDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DisclosureDocument;
  return proto.wilqo.api.mortgage.DisclosureDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DisclosureDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument}
 */
proto.wilqo.api.mortgage.DisclosureDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecipients(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStatusDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DisclosureDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DisclosureDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DisclosureDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRecipients();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDocumentType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 recipients = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getRecipients = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setRecipients = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string document_url = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string document_type = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getDocumentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp status_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.getStatusDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
*/
proto.wilqo.api.mortgage.DisclosureDocument.prototype.setStatusDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DisclosureDocument} returns this
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.clearStatusDate = function() {
  return this.setStatusDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DisclosureDocument.prototype.hasStatusDate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProductSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProductSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProductSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rate: (f = msg.getRate()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    estimatedTotalFees: (f = msg.getEstimatedTotalFees()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    discount: (f = msg.getDiscount()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    insurance: (f = msg.getInsurance()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    amortizationType: (f = msg.getAmortizationType()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    loanTermYears: (f = msg.getLoanTermYears()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    costOrCreditDiscountPointsTotalAmount: (f = msg.getCostOrCreditDiscountPointsTotalAmount()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    lockDate: (f = msg.getLockDate()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    lockPeriod: (f = msg.getLockPeriod()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    costOrCreditOriginationPointsOrFeesTotalAmount: (f = msg.getCostOrCreditOriginationPointsOrFeesTotalAmount()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    investor: (f = msg.getInvestor()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    lockExpirationDate: (f = msg.getLockExpirationDate()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProductSummary}
 */
proto.wilqo.api.mortgage.ProductSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProductSummary;
  return proto.wilqo.api.mortgage.ProductSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProductSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProductSummary}
 */
proto.wilqo.api.mortgage.ProductSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setEstimatedTotalFees(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setInsurance(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setAmortizationType(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setLoanTermYears(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setCostOrCreditDiscountPointsTotalAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setLockDate(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setLockPeriod(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 14:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setCostOrCreditOriginationPointsOrFeesTotalAmount(value);
      break;
    case 15:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setInvestor(value);
      break;
    case 16:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setLockExpirationDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProductSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProductSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProductSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedTotalFees();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getInsurance();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getAmortizationType();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getLoanTermYears();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getCostOrCreditDiscountPointsTotalAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getLockDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getLockPeriod();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getCostOrCreditOriginationPointsOrFeesTotalAmount();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getInvestor();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getLockExpirationDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement rate = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getRate = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement estimated_total_fees = 4;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getEstimatedTotalFees = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setEstimatedTotalFees = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearEstimatedTotalFees = function() {
  return this.setEstimatedTotalFees(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasEstimatedTotalFees = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement apr = 5;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 5));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasApr = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement discount = 6;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getDiscount = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 6));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement insurance = 7;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getInsurance = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 7));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setInsurance = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearInsurance = function() {
  return this.setInsurance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasInsurance = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement amortization_type = 8;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getAmortizationType = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 8));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setAmortizationType = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearAmortizationType = function() {
  return this.setAmortizationType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasAmortizationType = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement loan_term_years = 9;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getLoanTermYears = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 9));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setLoanTermYears = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearLoanTermYears = function() {
  return this.setLoanTermYears(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasLoanTermYears = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement cost_or_credit_discount_points_total_amount = 10;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getCostOrCreditDiscountPointsTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 10));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setCostOrCreditDiscountPointsTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearCostOrCreditDiscountPointsTotalAmount = function() {
  return this.setCostOrCreditDiscountPointsTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasCostOrCreditDiscountPointsTotalAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement lock_date = 11;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getLockDate = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 11));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setLockDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearLockDate = function() {
  return this.setLockDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasLockDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement lock_period = 12;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getLockPeriod = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 12));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setLockPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearLockPeriod = function() {
  return this.setLockPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasLockPeriod = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement product_name = 13;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getProductName = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 13));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement cost_or_credit_origination_points_or_fees_total_amount = 14;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getCostOrCreditOriginationPointsOrFeesTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 14));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setCostOrCreditOriginationPointsOrFeesTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearCostOrCreditOriginationPointsOrFeesTotalAmount = function() {
  return this.setCostOrCreditOriginationPointsOrFeesTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasCostOrCreditOriginationPointsOrFeesTotalAmount = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement investor = 15;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getInvestor = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 15));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setInvestor = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearInvestor = function() {
  return this.setInvestor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasInvestor = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement lock_expiration_date = 16;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.getLockExpirationDate = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 16));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
*/
proto.wilqo.api.mortgage.ProductSummary.prototype.setLockExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductSummary} returns this
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.clearLockExpirationDate = function() {
  return this.setLockExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductSummary.prototype.hasLockExpirationDate = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanSelectedProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanSelectedProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    investorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    investorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    loanTermYears: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    rateDetail: (f = msg.getRateDetail()) && proto.wilqo.api.mortgage.RateDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanSelectedProduct;
  return proto.wilqo.api.mortgage.LoanSelectedProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanSelectedProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermYears(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.RateDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage.RateDetail.deserializeBinaryFromReader);
      msg.setRateDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanSelectedProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanSelectedProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvestorName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInvestorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLoanTermYears();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getRateDetail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.RateDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_code = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getProductCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setProductCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string investor_name = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getInvestorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setInvestorName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string investor_code = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getInvestorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setInvestorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 loan_term_years = 6;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getLoanTermYears = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setLoanTermYears = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional TreasuryNPVLoanAmortizationEnum amortization_type = 7;
 * @return {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional RateDetail rate_detail = 8;
 * @return {?proto.wilqo.api.mortgage.RateDetail}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.getRateDetail = function() {
  return /** @type{?proto.wilqo.api.mortgage.RateDetail} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.RateDetail, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.RateDetail|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
*/
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.setRateDetail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanSelectedProduct} returns this
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.clearRateDetail = function() {
  return this.setRateDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanSelectedProduct.prototype.hasRateDetail = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RateDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RateDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RateDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    percent: (f = msg.getPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lockDays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    productChannel: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mortgageType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    communityLendingProductType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    loanTermType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    presetLoanTerm: jspb.Message.getFieldWithDefault(msg, 11, 0),
    presetLoanMaturity: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentInterval: jspb.Message.getFieldWithDefault(msg, 13, 0),
    interestOnlyPeriod: jspb.Message.getFieldWithDefault(msg, 14, 0),
    lienPriority: jspb.Message.getFieldWithDefault(msg, 15, 0),
    maxPriceLimit: jspb.Message.getFieldWithDefault(msg, 16, 0),
    channelAllowed: jspb.Message.getFieldWithDefault(msg, 17, 0),
    cltv: (f = msg.getCltv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    downPaymentPercentage: (f = msg.getDownPaymentPercentage()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaLtv: (f = msg.getFhaLtv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaUfmip: (f = msg.getFhaUfmip()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    financedMi: (f = msg.getFinancedMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    hcltv: (f = msg.getHcltv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanTermAllowed: jspb.Message.getFieldWithDefault(msg, 24, 0),
    ltv: (f = msg.getLtv()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    mortgageTypeAllowed: jspb.Message.getFieldWithDefault(msg, 26, 0),
    totalOfAllLiensWithFixedSecond: (f = msg.getTotalOfAllLiensWithFixedSecond()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalOfAllLiensWithHeloc: (f = msg.getTotalOfAllLiensWithHeloc()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinanced: (f = msg.getUsdaFinanced()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancedGuaranteeFee: (f = msg.getUsdaFinancedGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancedGuaranteeFeeCents: (f = msg.getUsdaFinancedGuaranteeFeeCents()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentageAmount: (f = msg.getUsdaFinancePercentageAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentageGuaranteeFee: (f = msg.getUsdaFinancePercentageGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaFinancePercentagePayAtClosing: (f = msg.getUsdaFinancePercentagePayAtClosing()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaGuaranteeFee: (f = msg.getUsdaGuaranteeFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit9: (f = msg.getUsdaIncomeLimit9()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk9: (f = msg.getUsdaIncomeLimitAk9()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaPayAtClosing: (f = msg.getUsdaPayAtClosing()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseFirstMonthlyMi: (f = msg.getFhaPurchaseFirstMonthlyMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseFirstMonthlyMiMonths: jspb.Message.getFieldWithDefault(msg, 40, 0),
    fhaPurchaseFirstPremium: (f = msg.getFhaPurchaseFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaPurchaseCutoff: (f = msg.getFhaPurchaseCutoff()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiFirstMonthlyMi: (f = msg.getFhaRefiFirstMonthlyMi()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiFirstMonthlyMiMonths: jspb.Message.getFieldWithDefault(msg, 44, 0),
    fhaRefiFirstPremium: (f = msg.getFhaRefiFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    fhaRefiCutoffPercentage: (f = msg.getFhaRefiCutoffPercentage()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit1To4: (f = msg.getUsdaIncomeLimit1To4()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimit5To8: (f = msg.getUsdaIncomeLimit5To8()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk1To4: (f = msg.getUsdaIncomeLimitAk1To4()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    usdaIncomeLimitAk5To8: (f = msg.getUsdaIncomeLimitAk5To8()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaPurchaseFirstPremium: (f = msg.getVaPurchaseFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaPurchaseSubsequentPremium: (f = msg.getVaPurchaseSubsequentPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaRefiFirstPremium: (f = msg.getVaRefiFirstPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaRefiSubsequentPremium: (f = msg.getVaRefiSubsequentPremium()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    miAndFundingFeeFinancedAmount: (f = msg.getMiAndFundingFeeFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    vaFundingFee: (f = msg.getVaFundingFee()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    indexType: jspb.Message.getFieldWithDefault(msg, 57, 0),
    firstRateChangeMonthsCount: jspb.Message.getFieldWithDefault(msg, 58, 0),
    subsequentRateChangeMonthsCount: jspb.Message.getFieldWithDefault(msg, 59, 0),
    firstMaxIncreaseRatePercent: (f = msg.getFirstMaxIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    subsequentMaxIncreaseRatePercent: (f = msg.getSubsequentMaxIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    maximumIncreaseRatePercent: (f = msg.getMaximumIncreaseRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    floorRatePercent: (f = msg.getFloorRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 64, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RateDetail}
 */
proto.wilqo.api.mortgage.RateDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RateDetail;
  return proto.wilqo.api.mortgage.RateDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RateDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RateDetail}
 */
proto.wilqo.api.mortgage.RateDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPercent(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLockDays(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.RateStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage.ProductChannel} */ (reader.readEnum());
      msg.setProductChannel(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage.MortgageEnum} */ (reader.readEnum());
      msg.setMortgageType(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage.CommunityLendingProductType} */ (reader.readEnum());
      msg.setCommunityLendingProductType(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanTermType} */ (reader.readEnum());
      msg.setLoanTermType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetLoanTerm(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPresetLoanMaturity(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentInterval(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (reader.readEnum());
      msg.setInterestOnlyPeriod(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.api.mortgage.LienPriorityEnum} */ (reader.readEnum());
      msg.setLienPriority(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (reader.readEnum());
      msg.setMaxPriceLimit(value);
      break;
    case 17:
      var value = /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (reader.readEnum());
      msg.setChannelAllowed(value);
      break;
    case 18:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCltv(value);
      break;
    case 19:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setDownPaymentPercentage(value);
      break;
    case 20:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaLtv(value);
      break;
    case 21:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaUfmip(value);
      break;
    case 22:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFinancedMi(value);
      break;
    case 23:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHcltv(value);
      break;
    case 24:
      var value = /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (reader.readEnum());
      msg.setLoanTermAllowed(value);
      break;
    case 25:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLtv(value);
      break;
    case 26:
      var value = /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (reader.readEnum());
      msg.setMortgageTypeAllowed(value);
      break;
    case 27:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalOfAllLiensWithFixedSecond(value);
      break;
    case 28:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalOfAllLiensWithHeloc(value);
      break;
    case 29:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinanced(value);
      break;
    case 30:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancedGuaranteeFee(value);
      break;
    case 31:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancedGuaranteeFeeCents(value);
      break;
    case 32:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentageAmount(value);
      break;
    case 33:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentageGuaranteeFee(value);
      break;
    case 34:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaFinancePercentagePayAtClosing(value);
      break;
    case 35:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaGuaranteeFee(value);
      break;
    case 36:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit9(value);
      break;
    case 37:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk9(value);
      break;
    case 38:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaPayAtClosing(value);
      break;
    case 39:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseFirstMonthlyMi(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFhaPurchaseFirstMonthlyMiMonths(value);
      break;
    case 41:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseFirstPremium(value);
      break;
    case 42:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaPurchaseCutoff(value);
      break;
    case 43:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiFirstMonthlyMi(value);
      break;
    case 44:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFhaRefiFirstMonthlyMiMonths(value);
      break;
    case 45:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiFirstPremium(value);
      break;
    case 46:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFhaRefiCutoffPercentage(value);
      break;
    case 47:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit1To4(value);
      break;
    case 48:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimit5To8(value);
      break;
    case 49:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk1To4(value);
      break;
    case 50:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUsdaIncomeLimitAk5To8(value);
      break;
    case 51:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaPurchaseFirstPremium(value);
      break;
    case 52:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaPurchaseSubsequentPremium(value);
      break;
    case 53:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaRefiFirstPremium(value);
      break;
    case 54:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaRefiSubsequentPremium(value);
      break;
    case 55:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMiAndFundingFeeFinancedAmount(value);
      break;
    case 56:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setVaFundingFee(value);
      break;
    case 57:
      var value = /** @type {!proto.wilqo.api.mortgage.IndexEnum} */ (reader.readEnum());
      msg.setIndexType(value);
      break;
    case 58:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFirstRateChangeMonthsCount(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubsequentRateChangeMonthsCount(value);
      break;
    case 60:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFirstMaxIncreaseRatePercent(value);
      break;
    case 61:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSubsequentMaxIncreaseRatePercent(value);
      break;
    case 62:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMaximumIncreaseRatePercent(value);
      break;
    case 63:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFloorRatePercent(value);
      break;
    case 64:
      var value = /** @type {!proto.wilqo.api.mortgage.AmortizationEnum} */ (reader.readEnum());
      msg.setAmortizationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RateDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RateDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RateDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPercent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLockDays();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getProductChannel();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getMortgageType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCommunityLendingProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLoanTermType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getPresetLoanTerm();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getPresetLoanMaturity();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getPaymentInterval();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getInterestOnlyPeriod();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getLienPriority();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getMaxPriceLimit();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getChannelAllowed();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getCltv();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getDownPaymentPercentage();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaLtv();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaUfmip();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFinancedMi();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHcltv();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanTermAllowed();
  if (f !== 0.0) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = message.getLtv();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMortgageTypeAllowed();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getTotalOfAllLiensWithFixedSecond();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalOfAllLiensWithHeloc();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinanced();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancedGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancedGuaranteeFeeCents();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentageAmount();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentageGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaFinancePercentagePayAtClosing();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaGuaranteeFee();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit9();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk9();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaPayAtClosing();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseFirstMonthlyMi();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseFirstMonthlyMiMonths();
  if (f !== 0) {
    writer.writeUint32(
      40,
      f
    );
  }
  f = message.getFhaPurchaseFirstPremium();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaPurchaseCutoff();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiFirstMonthlyMi();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiFirstMonthlyMiMonths();
  if (f !== 0) {
    writer.writeUint32(
      44,
      f
    );
  }
  f = message.getFhaRefiFirstPremium();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFhaRefiCutoffPercentage();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit1To4();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimit5To8();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk1To4();
  if (f != null) {
    writer.writeMessage(
      49,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUsdaIncomeLimitAk5To8();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaPurchaseFirstPremium();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaPurchaseSubsequentPremium();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaRefiFirstPremium();
  if (f != null) {
    writer.writeMessage(
      53,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaRefiSubsequentPremium();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMiAndFundingFeeFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      55,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getVaFundingFee();
  if (f != null) {
    writer.writeMessage(
      56,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getIndexType();
  if (f !== 0.0) {
    writer.writeEnum(
      57,
      f
    );
  }
  f = message.getFirstRateChangeMonthsCount();
  if (f !== 0) {
    writer.writeUint32(
      58,
      f
    );
  }
  f = message.getSubsequentRateChangeMonthsCount();
  if (f !== 0) {
    writer.writeUint32(
      59,
      f
    );
  }
  f = message.getFirstMaxIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSubsequentMaxIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      61,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMaximumIncreaseRatePercent();
  if (f != null) {
    writer.writeMessage(
      62,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFloorRatePercent();
  if (f != null) {
    writer.writeMessage(
      63,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAmortizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      64,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasApr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal percent = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setPercent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearPercent = function() {
  return this.setPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal price = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getPrice = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 lock_days = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getLockDays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setLockDays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional RateStatus status = 6;
 * @return {!proto.wilqo.api.mortgage.RateStatus}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.RateStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.RateStatus} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ProductChannel product_channel = 7;
 * @return {!proto.wilqo.api.mortgage.ProductChannel}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getProductChannel = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProductChannel} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProductChannel} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setProductChannel = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional MortgageEnum mortgage_type = 8;
 * @return {!proto.wilqo.api.mortgage.MortgageEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getMortgageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.MortgageEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MortgageEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setMortgageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional CommunityLendingProductType community_lending_product_type = 9;
 * @return {!proto.wilqo.api.mortgage.CommunityLendingProductType}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getCommunityLendingProductType = function() {
  return /** @type {!proto.wilqo.api.mortgage.CommunityLendingProductType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.CommunityLendingProductType} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setCommunityLendingProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional LoanTermType loan_term_type = 10;
 * @return {!proto.wilqo.api.mortgage.LoanTermType}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getLoanTermType = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanTermType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanTermType} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setLoanTermType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional uint32 preset_loan_term = 11;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getPresetLoanTerm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setPresetLoanTerm = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 preset_loan_maturity = 12;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getPresetLoanMaturity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setPresetLoanMaturity = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 payment_interval = 13;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getPaymentInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setPaymentInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional BooleanTypeEnum interest_only_period = 14;
 * @return {!proto.wilqo.api.mortgage.BooleanTypeEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getInterestOnlyPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BooleanTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setInterestOnlyPeriod = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional LienPriorityEnum lien_priority = 15;
 * @return {!proto.wilqo.api.mortgage.LienPriorityEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getLienPriority = function() {
  return /** @type {!proto.wilqo.api.mortgage.LienPriorityEnum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LienPriorityEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setLienPriority = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional BooleanTypeEnum max_price_limit = 16;
 * @return {!proto.wilqo.api.mortgage.BooleanTypeEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getMaxPriceLimit = function() {
  return /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BooleanTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setMaxPriceLimit = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional BooleanTypeEnum channel_allowed = 17;
 * @return {!proto.wilqo.api.mortgage.BooleanTypeEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getChannelAllowed = function() {
  return /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BooleanTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setChannelAllowed = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal cltv = 18;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getCltv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 18));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setCltv = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearCltv = function() {
  return this.setCltv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasCltv = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal down_payment_percentage = 19;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getDownPaymentPercentage = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 19));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setDownPaymentPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearDownPaymentPercentage = function() {
  return this.setDownPaymentPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasDownPaymentPercentage = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_ltv = 20;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaLtv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 20));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaLtv = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaLtv = function() {
  return this.setFhaLtv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaLtv = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_ufmip = 21;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaUfmip = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 21));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaUfmip = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaUfmip = function() {
  return this.setFhaUfmip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaUfmip = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal financed_mi = 22;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFinancedMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 22));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFinancedMi = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFinancedMi = function() {
  return this.setFinancedMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFinancedMi = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal hcltv = 23;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getHcltv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 23));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setHcltv = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearHcltv = function() {
  return this.setHcltv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasHcltv = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional BooleanTypeEnum loan_term_allowed = 24;
 * @return {!proto.wilqo.api.mortgage.BooleanTypeEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getLoanTermAllowed = function() {
  return /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BooleanTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setLoanTermAllowed = function(value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal ltv = 25;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getLtv = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 25));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setLtv = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearLtv = function() {
  return this.setLtv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasLtv = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional BooleanTypeEnum mortgage_type_allowed = 26;
 * @return {!proto.wilqo.api.mortgage.BooleanTypeEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getMortgageTypeAllowed = function() {
  return /** @type {!proto.wilqo.api.mortgage.BooleanTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BooleanTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setMortgageTypeAllowed = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_of_all_liens_with_fixed_second = 27;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getTotalOfAllLiensWithFixedSecond = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 27));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setTotalOfAllLiensWithFixedSecond = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearTotalOfAllLiensWithFixedSecond = function() {
  return this.setTotalOfAllLiensWithFixedSecond(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasTotalOfAllLiensWithFixedSecond = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_of_all_liens_with_heloc = 28;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getTotalOfAllLiensWithHeloc = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 28));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setTotalOfAllLiensWithHeloc = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearTotalOfAllLiensWithHeloc = function() {
  return this.setTotalOfAllLiensWithHeloc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasTotalOfAllLiensWithHeloc = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed = 29;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaFinanced = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 29));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaFinanced = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaFinanced = function() {
  return this.setUsdaFinanced(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaFinanced = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed_guarantee_fee = 30;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaFinancedGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 30));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaFinancedGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaFinancedGuaranteeFee = function() {
  return this.setUsdaFinancedGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaFinancedGuaranteeFee = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_financed_guarantee_fee_cents = 31;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaFinancedGuaranteeFeeCents = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 31));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaFinancedGuaranteeFeeCents = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaFinancedGuaranteeFeeCents = function() {
  return this.setUsdaFinancedGuaranteeFeeCents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaFinancedGuaranteeFeeCents = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_amount = 32;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaFinancePercentageAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 32));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaFinancePercentageAmount = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaFinancePercentageAmount = function() {
  return this.setUsdaFinancePercentageAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaFinancePercentageAmount = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_guarantee_fee = 33;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaFinancePercentageGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 33));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaFinancePercentageGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaFinancePercentageGuaranteeFee = function() {
  return this.setUsdaFinancePercentageGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaFinancePercentageGuaranteeFee = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_finance_percentage_pay_at_closing = 34;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaFinancePercentagePayAtClosing = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 34));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaFinancePercentagePayAtClosing = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaFinancePercentagePayAtClosing = function() {
  return this.setUsdaFinancePercentagePayAtClosing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaFinancePercentagePayAtClosing = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_guarantee_fee = 35;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaGuaranteeFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 35));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaGuaranteeFee = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaGuaranteeFee = function() {
  return this.setUsdaGuaranteeFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaGuaranteeFee = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_9 = 36;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaIncomeLimit9 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 36));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaIncomeLimit9 = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaIncomeLimit9 = function() {
  return this.setUsdaIncomeLimit9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaIncomeLimit9 = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_9 = 37;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaIncomeLimitAk9 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 37));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaIncomeLimitAk9 = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaIncomeLimitAk9 = function() {
  return this.setUsdaIncomeLimitAk9(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaIncomeLimitAk9 = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_pay_at_closing = 38;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaPayAtClosing = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 38));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaPayAtClosing = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaPayAtClosing = function() {
  return this.setUsdaPayAtClosing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaPayAtClosing = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_first_monthly_mi = 39;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaPurchaseFirstMonthlyMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 39));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaPurchaseFirstMonthlyMi = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaPurchaseFirstMonthlyMi = function() {
  return this.setFhaPurchaseFirstMonthlyMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaPurchaseFirstMonthlyMi = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional uint32 fha_purchase_first_monthly_mi_months = 40;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaPurchaseFirstMonthlyMiMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaPurchaseFirstMonthlyMiMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_first_premium = 41;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaPurchaseFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 41));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaPurchaseFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaPurchaseFirstPremium = function() {
  return this.setFhaPurchaseFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaPurchaseFirstPremium = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_purchase_cutoff = 42;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaPurchaseCutoff = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 42));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaPurchaseCutoff = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaPurchaseCutoff = function() {
  return this.setFhaPurchaseCutoff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaPurchaseCutoff = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_first_monthly_mi = 43;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaRefiFirstMonthlyMi = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 43));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaRefiFirstMonthlyMi = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaRefiFirstMonthlyMi = function() {
  return this.setFhaRefiFirstMonthlyMi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaRefiFirstMonthlyMi = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional uint32 fha_refi_first_monthly_mi_months = 44;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaRefiFirstMonthlyMiMonths = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 44, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaRefiFirstMonthlyMiMonths = function(value) {
  return jspb.Message.setProto3IntField(this, 44, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_first_premium = 45;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaRefiFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 45));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaRefiFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaRefiFirstPremium = function() {
  return this.setFhaRefiFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaRefiFirstPremium = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fha_refi_cutoff_percentage = 46;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFhaRefiCutoffPercentage = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 46));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFhaRefiCutoffPercentage = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFhaRefiCutoffPercentage = function() {
  return this.setFhaRefiCutoffPercentage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFhaRefiCutoffPercentage = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_1_to_4 = 47;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaIncomeLimit1To4 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 47));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaIncomeLimit1To4 = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaIncomeLimit1To4 = function() {
  return this.setUsdaIncomeLimit1To4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaIncomeLimit1To4 = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_5_to_8 = 48;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaIncomeLimit5To8 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 48));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaIncomeLimit5To8 = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaIncomeLimit5To8 = function() {
  return this.setUsdaIncomeLimit5To8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaIncomeLimit5To8 = function() {
  return jspb.Message.getField(this, 48) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_1_to_4 = 49;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaIncomeLimitAk1To4 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 49));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaIncomeLimitAk1To4 = function(value) {
  return jspb.Message.setWrapperField(this, 49, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaIncomeLimitAk1To4 = function() {
  return this.setUsdaIncomeLimitAk1To4(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaIncomeLimitAk1To4 = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal usda_income_limit_ak_5_to_8 = 50;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getUsdaIncomeLimitAk5To8 = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 50));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setUsdaIncomeLimitAk5To8 = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearUsdaIncomeLimitAk5To8 = function() {
  return this.setUsdaIncomeLimitAk5To8(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasUsdaIncomeLimitAk5To8 = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_purchase_first_premium = 51;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getVaPurchaseFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 51));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setVaPurchaseFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearVaPurchaseFirstPremium = function() {
  return this.setVaPurchaseFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasVaPurchaseFirstPremium = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_purchase_subsequent_premium = 52;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getVaPurchaseSubsequentPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 52));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setVaPurchaseSubsequentPremium = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearVaPurchaseSubsequentPremium = function() {
  return this.setVaPurchaseSubsequentPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasVaPurchaseSubsequentPremium = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_refi_first_premium = 53;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getVaRefiFirstPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 53));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setVaRefiFirstPremium = function(value) {
  return jspb.Message.setWrapperField(this, 53, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearVaRefiFirstPremium = function() {
  return this.setVaRefiFirstPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasVaRefiFirstPremium = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_refi_subsequent_premium = 54;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getVaRefiSubsequentPremium = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 54));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setVaRefiSubsequentPremium = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearVaRefiSubsequentPremium = function() {
  return this.setVaRefiSubsequentPremium(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasVaRefiSubsequentPremium = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal mi_and_funding_fee_financed_amount = 55;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getMiAndFundingFeeFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 55));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setMiAndFundingFeeFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 55, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearMiAndFundingFeeFinancedAmount = function() {
  return this.setMiAndFundingFeeFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasMiAndFundingFeeFinancedAmount = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal va_funding_fee = 56;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getVaFundingFee = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 56));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setVaFundingFee = function(value) {
  return jspb.Message.setWrapperField(this, 56, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearVaFundingFee = function() {
  return this.setVaFundingFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasVaFundingFee = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional IndexEnum index_type = 57;
 * @return {!proto.wilqo.api.mortgage.IndexEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getIndexType = function() {
  return /** @type {!proto.wilqo.api.mortgage.IndexEnum} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IndexEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setIndexType = function(value) {
  return jspb.Message.setProto3EnumField(this, 57, value);
};


/**
 * optional uint32 first_rate_change_months_count = 58;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFirstRateChangeMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 58, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setFirstRateChangeMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 58, value);
};


/**
 * optional uint32 subsequent_rate_change_months_count = 59;
 * @return {number}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getSubsequentRateChangeMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setSubsequentRateChangeMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal first_max_increase_rate_percent = 60;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFirstMaxIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 60));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFirstMaxIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFirstMaxIncreaseRatePercent = function() {
  return this.setFirstMaxIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFirstMaxIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal subsequent_max_increase_rate_percent = 61;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getSubsequentMaxIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 61));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setSubsequentMaxIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 61, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearSubsequentMaxIncreaseRatePercent = function() {
  return this.setSubsequentMaxIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasSubsequentMaxIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal maximum_increase_rate_percent = 62;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getMaximumIncreaseRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 62));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setMaximumIncreaseRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 62, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearMaximumIncreaseRatePercent = function() {
  return this.setMaximumIncreaseRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasMaximumIncreaseRatePercent = function() {
  return jspb.Message.getField(this, 62) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal floor_rate_percent = 63;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getFloorRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 63));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
*/
proto.wilqo.api.mortgage.RateDetail.prototype.setFloorRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 63, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.clearFloorRatePercent = function() {
  return this.setFloorRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.hasFloorRatePercent = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional AmortizationEnum amortization_type = 64;
 * @return {!proto.wilqo.api.mortgage.AmortizationEnum}
 */
proto.wilqo.api.mortgage.RateDetail.prototype.getAmortizationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.AmortizationEnum} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AmortizationEnum} value
 * @return {!proto.wilqo.api.mortgage.RateDetail} returns this
 */
proto.wilqo.api.mortgage.RateDetail.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 64, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partyRole: jspb.Message.getFieldWithDefault(msg, 2, 0),
    intentToProceedSet: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    loanEstimatedReceivedDate: (f = msg.getLoanEstimatedReceivedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    loanEstimatedReceivedMethod: (f = msg.getLoanEstimatedReceivedMethod()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    intentToProceedDate: (f = msg.getIntentToProceedDate()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    intentToProceedMethod: (f = msg.getIntentToProceedMethod()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    partyType: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus;
  return proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (reader.readEnum());
      msg.setPartyRole(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIntentToProceedSet(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setLoanEstimatedReceivedDate(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setLoanEstimatedReceivedMethod(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setIntentToProceedDate(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setIntentToProceedMethod(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setPartyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartyRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIntentToProceedSet();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLoanEstimatedReceivedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getLoanEstimatedReceivedMethod();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getIntentToProceedDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getIntentToProceedMethod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PartyRoleEnum party_role = 2;
 * @return {!proto.wilqo.api.mortgage.PartyRoleEnum}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getPartyRole = function() {
  return /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PartyRoleEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setPartyRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool intent_to_proceed_set = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getIntentToProceedSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setIntentToProceedSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string borrower_name = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional wilqo.shared.models.PanelElement loan_estimated_received_date = 4;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getLoanEstimatedReceivedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
*/
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setLoanEstimatedReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.clearLoanEstimatedReceivedDate = function() {
  return this.setLoanEstimatedReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.hasLoanEstimatedReceivedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.PanelElement loan_estimated_received_method = 5;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getLoanEstimatedReceivedMethod = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 5));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
*/
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setLoanEstimatedReceivedMethod = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.clearLoanEstimatedReceivedMethod = function() {
  return this.setLoanEstimatedReceivedMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.hasLoanEstimatedReceivedMethod = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.PanelElement intent_to_proceed_date = 6;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getIntentToProceedDate = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 6));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
*/
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setIntentToProceedDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.clearIntentToProceedDate = function() {
  return this.setIntentToProceedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.hasIntentToProceedDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.PanelElement intent_to_proceed_method = 7;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getIntentToProceedMethod = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 7));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
*/
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setIntentToProceedMethod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.clearIntentToProceedMethod = function() {
  return this.setIntentToProceedMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.hasIntentToProceedMethod = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.WilqoPartyType party_type = 9;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.getPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceedStatus.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerIntentToProceed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.toObject = function(includeInstance, msg) {
  var f, obj = {
    loanEstimatedReceivedDate: (f = msg.getLoanEstimatedReceivedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    loanEstimatedReceivedMethod: jspb.Message.getFieldWithDefault(msg, 2, 0),
    intentToProceedDate: (f = msg.getIntentToProceedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    intentToProceedMethod: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerIntentToProceed;
  return proto.wilqo.api.mortgage.BorrowerIntentToProceed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoanEstimatedReceivedDate(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.DisclosureDeliveryMethodEnum} */ (reader.readEnum());
      msg.setLoanEstimatedReceivedMethod(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIntentToProceedDate(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.IntentToProceedCommunicationMethodEnum} */ (reader.readEnum());
      msg.setIntentToProceedMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerIntentToProceed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoanEstimatedReceivedDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLoanEstimatedReceivedMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIntentToProceedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIntentToProceedMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp loan_estimated_received_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.getLoanEstimatedReceivedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} returns this
*/
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.setLoanEstimatedReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.clearLoanEstimatedReceivedDate = function() {
  return this.setLoanEstimatedReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.hasLoanEstimatedReceivedDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DisclosureDeliveryMethodEnum loan_estimated_received_method = 2;
 * @return {!proto.wilqo.api.mortgage.DisclosureDeliveryMethodEnum}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.getLoanEstimatedReceivedMethod = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisclosureDeliveryMethodEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisclosureDeliveryMethodEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.setLoanEstimatedReceivedMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp intent_to_proceed_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.getIntentToProceedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} returns this
*/
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.setIntentToProceedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.clearIntentToProceedDate = function() {
  return this.setIntentToProceedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.hasIntentToProceedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IntentToProceedCommunicationMethodEnum intent_to_proceed_method = 4;
 * @return {!proto.wilqo.api.mortgage.IntentToProceedCommunicationMethodEnum}
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.getIntentToProceedMethod = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntentToProceedCommunicationMethodEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntentToProceedCommunicationMethodEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIntentToProceed} returns this
 */
proto.wilqo.api.mortgage.BorrowerIntentToProceed.prototype.setIntentToProceedMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FraudContentPanel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FraudContentPanel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FraudContentPanel.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    providerReportUrl: (f = msg.getProviderReportUrl()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FraudContentPanel}
 */
proto.wilqo.api.mortgage.FraudContentPanel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FraudContentPanel;
  return proto.wilqo.api.mortgage.FraudContentPanel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FraudContentPanel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FraudContentPanel}
 */
proto.wilqo.api.mortgage.FraudContentPanel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setProviderReportUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FraudContentPanel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FraudContentPanel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FraudContentPanel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProviderReportUrl();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FraudContentPanel} returns this
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.PanelElement provider_report_url = 2;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.getProviderReportUrl = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage.FraudContentPanel} returns this
*/
proto.wilqo.api.mortgage.FraudContentPanel.prototype.setProviderReportUrl = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FraudContentPanel} returns this
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.clearProviderReportUrl = function() {
  return this.setProviderReportUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FraudContentPanel.prototype.hasProviderReportUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SimpleOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SimpleOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SimpleOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SimpleOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SimpleOption}
 */
proto.wilqo.api.mortgage.SimpleOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SimpleOption;
  return proto.wilqo.api.mortgage.SimpleOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SimpleOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SimpleOption}
 */
proto.wilqo.api.mortgage.SimpleOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SimpleOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SimpleOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SimpleOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SimpleOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SimpleOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SimpleOption} returns this
 */
proto.wilqo.api.mortgage.SimpleOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SimpleOption.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SimpleOption} returns this
 */
proto.wilqo.api.mortgage.SimpleOption.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastUpdate: (f = msg.getLastUpdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    socialSecurityNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    income: (f = msg.getIncome()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    estimatedValue: (f = msg.getEstimatedValue()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanAmount: (f = msg.getLoanAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    tridSummary: (f = msg.getTridSummary()) && proto.wilqo.api.mortgage.TridSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TridTrackingBorrowerSummary;
  return proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSocialSecurityNumber(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setIncome(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setEstimatedValue(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLoanAmount(value);
      break;
    case 8:
      var value = new proto.wilqo.api.mortgage.TridSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.TridSummary.deserializeBinaryFromReader);
      msg.setTridSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastUpdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSocialSecurityNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIncome();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEstimatedValue();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTridSummary();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.mortgage.TridSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string borrower_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp last_update = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getLastUpdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
*/
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setLastUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.clearLastUpdate = function() {
  return this.setLastUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string social_security_number = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getSocialSecurityNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setSocialSecurityNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal income = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getIncome = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
*/
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setIncome = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.clearIncome = function() {
  return this.setIncome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.hasIncome = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal estimated_value = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getEstimatedValue = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
*/
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setEstimatedValue = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.clearEstimatedValue = function() {
  return this.setEstimatedValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.hasEstimatedValue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal loan_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getLoanAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
*/
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setLoanAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.clearLoanAmount = function() {
  return this.setLoanAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.hasLoanAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TridSummary trid_summary = 8;
 * @return {?proto.wilqo.api.mortgage.TridSummary}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.getTridSummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.TridSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.TridSummary, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage.TridSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
*/
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.setTridSummary = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary} returns this
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.clearTridSummary = function() {
  return this.setTridSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.prototype.hasTridSummary = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.TridTracking.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TridTracking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TridTracking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TridTracking.toObject = function(includeInstance, msg) {
  var f, obj = {
    tridCompliantStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tridTriggeredDate: (f = msg.getTridTriggeredDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    loanEstimateDueDate: (f = msg.getLoanEstimateDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    loanEstimateDeliveredDate: (f = msg.getLoanEstimateDeliveredDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tridTrackingBorrowerSummariesList: jspb.Message.toObjectList(msg.getTridTrackingBorrowerSummariesList(),
    proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TridTracking}
 */
proto.wilqo.api.mortgage.TridTracking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TridTracking;
  return proto.wilqo.api.mortgage.TridTracking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TridTracking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TridTracking}
 */
proto.wilqo.api.mortgage.TridTracking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.TridTrackingStatusEnum} */ (reader.readEnum());
      msg.setTridCompliantStatus(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTridTriggeredDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoanEstimateDueDate(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLoanEstimateDeliveredDate(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.TridTrackingBorrowerSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.deserializeBinaryFromReader);
      msg.addTridTrackingBorrowerSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TridTracking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TridTracking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TridTracking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTridCompliantStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTridTriggeredDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLoanEstimateDueDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLoanEstimateDeliveredDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTridTrackingBorrowerSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage.TridTrackingBorrowerSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional TridTrackingStatusEnum trid_compliant_status = 1;
 * @return {!proto.wilqo.api.mortgage.TridTrackingStatusEnum}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.getTridCompliantStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage.TridTrackingStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.TridTrackingStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
 */
proto.wilqo.api.mortgage.TridTracking.prototype.setTridCompliantStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp trid_triggered_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.getTridTriggeredDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
*/
proto.wilqo.api.mortgage.TridTracking.prototype.setTridTriggeredDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
 */
proto.wilqo.api.mortgage.TridTracking.prototype.clearTridTriggeredDate = function() {
  return this.setTridTriggeredDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.hasTridTriggeredDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp loan_estimate_due_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.getLoanEstimateDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
*/
proto.wilqo.api.mortgage.TridTracking.prototype.setLoanEstimateDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
 */
proto.wilqo.api.mortgage.TridTracking.prototype.clearLoanEstimateDueDate = function() {
  return this.setLoanEstimateDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.hasLoanEstimateDueDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp loan_estimate_delivered_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.getLoanEstimateDeliveredDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
*/
proto.wilqo.api.mortgage.TridTracking.prototype.setLoanEstimateDeliveredDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
 */
proto.wilqo.api.mortgage.TridTracking.prototype.clearLoanEstimateDeliveredDate = function() {
  return this.setLoanEstimateDeliveredDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.hasLoanEstimateDeliveredDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated TridTrackingBorrowerSummary trid_tracking_borrower_summaries = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary>}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.getTridTrackingBorrowerSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.TridTrackingBorrowerSummary, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary>} value
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
*/
proto.wilqo.api.mortgage.TridTracking.prototype.setTridTrackingBorrowerSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.TridTrackingBorrowerSummary}
 */
proto.wilqo.api.mortgage.TridTracking.prototype.addTridTrackingBorrowerSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage.TridTrackingBorrowerSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.TridTracking} returns this
 */
proto.wilqo.api.mortgage.TridTracking.prototype.clearTridTrackingBorrowerSummariesList = function() {
  return this.setTridTrackingBorrowerSummariesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanAppraisalDataSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    appraisedAmount: (f = msg.getAppraisedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    dateOfAppraisal: (f = msg.getDateOfAppraisal()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    estimatedValueAmount: (f = msg.getEstimatedValueAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    eligibleForWaiver: jspb.Message.getFieldWithDefault(msg, 4, ""),
    asIsOrSubjectTo: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanAppraisalDataSummary;
  return proto.wilqo.api.mortgage.LoanAppraisalDataSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAppraisedAmount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateOfAppraisal(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setEstimatedValueAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEligibleForWaiver(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsIsOrSubjectTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanAppraisalDataSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppraisedAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getDateOfAppraisal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedValueAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getEligibleForWaiver();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAsIsOrSubjectTo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal appraised_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.getAppraisedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.setAppraisedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.clearAppraisedAmount = function() {
  return this.setAppraisedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.hasAppraisedAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp date_of_appraisal = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.getDateOfAppraisal = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.setDateOfAppraisal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.clearDateOfAppraisal = function() {
  return this.setDateOfAppraisal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.hasDateOfAppraisal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal estimated_value_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.getEstimatedValueAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.setEstimatedValueAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.clearEstimatedValueAmount = function() {
  return this.setEstimatedValueAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.hasEstimatedValueAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string eligible_for_waiver = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.getEligibleForWaiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.setEligibleForWaiver = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string as_is_or_subject_to = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.getAsIsOrSubjectTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAppraisalDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAppraisalDataSummary.prototype.setAsIsOrSubjectTo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanDisclosureDataSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastDisclosedDate: (f = msg.getLastDisclosedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastDisclosedType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanDisclosureDataSummary;
  return proto.wilqo.api.mortgage.LoanDisclosureDataSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastDisclosedDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastDisclosedType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanDisclosureDataSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastDisclosedDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastDisclosedType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp last_disclosed_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.getLastDisclosedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary} returns this
*/
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.setLastDisclosedDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.clearLastDisclosedDate = function() {
  return this.setLastDisclosedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.hasLastDisclosedDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string last_disclosed_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.getLastDisclosedType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanDisclosureDataSummary} returns this
 */
proto.wilqo.api.mortgage.LoanDisclosureDataSummary.prototype.setLastDisclosedType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AusSelectedSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AusSelectedSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusSelectedSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    recommendation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    collateral: jspb.Message.getFieldWithDefault(msg, 3, ""),
    incomeValidation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    employmentValidation: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assetValidation: jspb.Message.getFieldWithDefault(msg, 6, ""),
    documentLevel: jspb.Message.getFieldWithDefault(msg, 7, ""),
    responseDate: (f = msg.getResponseDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AusSelectedSummary;
  return proto.wilqo.api.mortgage.AusSelectedSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AusSelectedSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecommendation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCollateral(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncomeValidation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmploymentValidation(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetValidation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentLevel(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setResponseDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AusSelectedSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AusSelectedSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AusSelectedSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRecommendation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCollateral();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIncomeValidation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmploymentValidation();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssetValidation();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDocumentLevel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getResponseDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_type = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getSystemType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setSystemType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string recommendation = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getRecommendation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setRecommendation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string collateral = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getCollateral = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setCollateral = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string income_validation = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getIncomeValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setIncomeValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string employment_validation = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getEmploymentValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setEmploymentValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string asset_validation = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getAssetValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setAssetValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string document_level = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getDocumentLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setDocumentLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp response_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.getResponseDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
*/
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.setResponseDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.AusSelectedSummary} returns this
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.clearResponseDate = function() {
  return this.setResponseDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.AusSelectedSummary.prototype.hasResponseDate = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerPersonalInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    maritalStatusType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    citizenshipResidencyType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    domesticRelationshipType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dependentCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    communityPropertyStateResidentIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    selfDeclaredMilitaryServiceIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerPersonalInformation;
  return proto.wilqo.api.mortgage.BorrowerPersonalInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.MaritalStatusEnum} */ (reader.readEnum());
      msg.setMaritalStatusType(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.CitizenshipResidencyEnum} */ (reader.readEnum());
      msg.setCitizenshipResidencyType(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DomesticRelationshipEnum} */ (reader.readEnum());
      msg.setDomesticRelationshipType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDependentCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommunityPropertyStateResidentIndicator(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelfDeclaredMilitaryServiceIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerPersonalInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaritalStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCitizenshipResidencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDomesticRelationshipType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDependentCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCommunityPropertyStateResidentIndicator();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSelfDeclaredMilitaryServiceIndicator();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional MaritalStatusEnum marital_status_type = 1;
 * @return {!proto.wilqo.api.mortgage.MaritalStatusEnum}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.getMaritalStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.MaritalStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MaritalStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.setMaritalStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional CitizenshipResidencyEnum citizenship_residency_type = 2;
 * @return {!proto.wilqo.api.mortgage.CitizenshipResidencyEnum}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.getCitizenshipResidencyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.CitizenshipResidencyEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.CitizenshipResidencyEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.setCitizenshipResidencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional DomesticRelationshipEnum domestic_relationship_type = 3;
 * @return {!proto.wilqo.api.mortgage.DomesticRelationshipEnum}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.getDomesticRelationshipType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DomesticRelationshipEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DomesticRelationshipEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.setDomesticRelationshipType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int32 dependent_count = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.getDependentCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.setDependentCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool community_property_state_resident_indicator = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.getCommunityPropertyStateResidentIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.setCommunityPropertyStateResidentIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool self_declared_military_service_indicator = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.getSelfDeclaredMilitaryServiceIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerPersonalInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerPersonalInformation.prototype.setSelfDeclaredMilitaryServiceIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerMilitaryService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerMilitaryService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.toObject = function(includeInstance, msg) {
  var f, obj = {
    veteranStatusIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    militaryLendingActCoveredBorrowerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    spousalVaBenefitsEligibilityIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerMilitaryService}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerMilitaryService;
  return proto.wilqo.api.mortgage.BorrowerMilitaryService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerMilitaryService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerMilitaryService}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVeteranStatusIndicator(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMilitaryLendingActCoveredBorrowerIndicator(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSpousalVaBenefitsEligibilityIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerMilitaryService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerMilitaryService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVeteranStatusIndicator();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMilitaryLendingActCoveredBorrowerIndicator();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSpousalVaBenefitsEligibilityIndicator();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool veteran_status_indicator = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.getVeteranStatusIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerMilitaryService} returns this
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.setVeteranStatusIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool military_lending_act_covered_borrower_indicator = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.getMilitaryLendingActCoveredBorrowerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerMilitaryService} returns this
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.setMilitaryLendingActCoveredBorrowerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool spousal_va_benefits_eligibility_indicator = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.getSpousalVaBenefitsEligibilityIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerMilitaryService} returns this
 */
proto.wilqo.api.mortgage.BorrowerMilitaryService.prototype.setSpousalVaBenefitsEligibilityIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerDetailsInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerDetailsInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerDetailsSectionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    informationPanelElementsList: jspb.Message.toObjectList(msg.getInformationPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerDetailsInformation}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerDetailsInformation;
  return proto.wilqo.api.mortgage.BorrowerDetailsInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerDetailsInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerDetailsInformation}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.BorrowerDetailsSectionEnum} */ (reader.readEnum());
      msg.setBorrowerDetailsSectionType(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addInformationPanelElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerDetailsInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerDetailsInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerDetailsSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getInformationPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional BorrowerDetailsSectionEnum borrower_details_section_type = 1;
 * @return {!proto.wilqo.api.mortgage.BorrowerDetailsSectionEnum}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.getBorrowerDetailsSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.BorrowerDetailsSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BorrowerDetailsSectionEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerDetailsInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.setBorrowerDetailsSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated wilqo.shared.models.PanelElement information_panel_elements = 2;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.getInformationPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.BorrowerDetailsInformation} returns this
*/
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.setInformationPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.addInformationPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.BorrowerDetailsInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerDetailsInformation.prototype.clearInformationPanelElementsList = function() {
  return this.setInformationPanelElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerIncomeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlaBorrowerTotalMonthlyIncomeAmount: (f = msg.getUrlaBorrowerTotalMonthlyIncomeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    urlaBorrowerTotalOtherIncomeAmount: (f = msg.getUrlaBorrowerTotalOtherIncomeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    borrowerQualifyingIncomeAmount: (f = msg.getBorrowerQualifyingIncomeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    signedAuthorizationToRequestTaxRecordsIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    authorizedIncomePull: (f = msg.getAuthorizedIncomePull()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    monthlyRetirementAmount: (f = msg.getMonthlyRetirementAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    taxRecordsObtainedIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    incomeVerificationLevelCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    monthlyMilitaryEntitlementsAmount: (f = msg.getMonthlyMilitaryEntitlementsAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    employmentVerificationLevelCode: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerIncomeSummary;
  return proto.wilqo.api.mortgage.BorrowerIncomeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaBorrowerTotalMonthlyIncomeAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaBorrowerTotalOtherIncomeAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBorrowerQualifyingIncomeAmount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSignedAuthorizationToRequestTaxRecordsIndicator(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAuthorizedIncomePull(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyRetirementAmount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTaxRecordsObtainedIndicator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIncomeVerificationLevelCode(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyMilitaryEntitlementsAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmploymentVerificationLevelCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerIncomeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlaBorrowerTotalMonthlyIncomeAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUrlaBorrowerTotalOtherIncomeAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getBorrowerQualifyingIncomeAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSignedAuthorizationToRequestTaxRecordsIndicator();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAuthorizedIncomePull();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyRetirementAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTaxRecordsObtainedIndicator();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIncomeVerificationLevelCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMonthlyMilitaryEntitlementsAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getEmploymentVerificationLevelCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_borrower_total_monthly_income_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getUrlaBorrowerTotalMonthlyIncomeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setUrlaBorrowerTotalMonthlyIncomeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.clearUrlaBorrowerTotalMonthlyIncomeAmount = function() {
  return this.setUrlaBorrowerTotalMonthlyIncomeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.hasUrlaBorrowerTotalMonthlyIncomeAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_borrower_total_other_income_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getUrlaBorrowerTotalOtherIncomeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setUrlaBorrowerTotalOtherIncomeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.clearUrlaBorrowerTotalOtherIncomeAmount = function() {
  return this.setUrlaBorrowerTotalOtherIncomeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.hasUrlaBorrowerTotalOtherIncomeAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal borrower_qualifying_income_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getBorrowerQualifyingIncomeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setBorrowerQualifyingIncomeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.clearBorrowerQualifyingIncomeAmount = function() {
  return this.setBorrowerQualifyingIncomeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.hasBorrowerQualifyingIncomeAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool signed_authorization_to_request_tax_records_indicator = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getSignedAuthorizationToRequestTaxRecordsIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setSignedAuthorizationToRequestTaxRecordsIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp authorized_income_pull = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getAuthorizedIncomePull = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setAuthorizedIncomePull = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.clearAuthorizedIncomePull = function() {
  return this.setAuthorizedIncomePull(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.hasAuthorizedIncomePull = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_retirement_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getMonthlyRetirementAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setMonthlyRetirementAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.clearMonthlyRetirementAmount = function() {
  return this.setMonthlyRetirementAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.hasMonthlyRetirementAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool tax_records_obtained_indicator = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getTaxRecordsObtainedIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setTaxRecordsObtainedIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string income_verification_level_code = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getIncomeVerificationLevelCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setIncomeVerificationLevelCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_military_entitlements_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getMonthlyMilitaryEntitlementsAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setMonthlyMilitaryEntitlementsAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.clearMonthlyMilitaryEntitlementsAmount = function() {
  return this.setMonthlyMilitaryEntitlementsAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.hasMonthlyMilitaryEntitlementsAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string employment_verification_level_code = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.getEmploymentVerificationLevelCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BorrowerIncomeSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerIncomeSummary.prototype.setEmploymentVerificationLevelCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerAssets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerAssets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerAssets.toObject = function(includeInstance, msg) {
  var f, obj = {
    urlaTotalAssetsAmount: (f = msg.getUrlaTotalAssetsAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    urlaTotalNetWorthAmount: (f = msg.getUrlaTotalNetWorthAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerAssets}
 */
proto.wilqo.api.mortgage.BorrowerAssets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerAssets;
  return proto.wilqo.api.mortgage.BorrowerAssets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerAssets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerAssets}
 */
proto.wilqo.api.mortgage.BorrowerAssets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalAssetsAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalNetWorthAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerAssets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerAssets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerAssets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrlaTotalAssetsAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUrlaTotalNetWorthAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_assets_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.getUrlaTotalAssetsAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerAssets} returns this
*/
proto.wilqo.api.mortgage.BorrowerAssets.prototype.setUrlaTotalAssetsAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerAssets} returns this
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.clearUrlaTotalAssetsAmount = function() {
  return this.setUrlaTotalAssetsAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.hasUrlaTotalAssetsAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_net_worth_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.getUrlaTotalNetWorthAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerAssets} returns this
*/
proto.wilqo.api.mortgage.BorrowerAssets.prototype.setUrlaTotalNetWorthAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerAssets} returns this
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.clearUrlaTotalNetWorthAmount = function() {
  return this.setUrlaTotalNetWorthAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerAssets.prototype.hasUrlaTotalNetWorthAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerCreditSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerCreditSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizedSoftCreditPull: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    creditReportAuthorizationIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    creditReportIdentifier: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerCreditSummary}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerCreditSummary;
  return proto.wilqo.api.mortgage.BorrowerCreditSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerCreditSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerCreditSummary}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuthorizedSoftCreditPull(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreditReportAuthorizationIndicator(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditReportIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerCreditSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerCreditSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizedSoftCreditPull();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCreditReportAuthorizationIndicator();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCreditReportIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool authorized_soft_credit_pull = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.getAuthorizedSoftCreditPull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerCreditSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.setAuthorizedSoftCreditPull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool credit_report_authorization_indicator = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.getCreditReportAuthorizationIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerCreditSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.setCreditReportAuthorizationIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string credit_report_identifier = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.getCreditReportIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.BorrowerCreditSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerCreditSummary.prototype.setCreditReportIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerExpenses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerExpenses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerExpenses.toObject = function(includeInstance, msg) {
  var f, obj = {
    housingExpensePresentTotalMonthlyPaymentAmount: (f = msg.getHousingExpensePresentTotalMonthlyPaymentAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    housingExpenseProposedTotalMonthlyPaymentAmount: (f = msg.getHousingExpenseProposedTotalMonthlyPaymentAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerExpenses}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerExpenses;
  return proto.wilqo.api.mortgage.BorrowerExpenses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerExpenses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerExpenses}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHousingExpensePresentTotalMonthlyPaymentAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHousingExpenseProposedTotalMonthlyPaymentAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerExpenses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerExpenses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerExpenses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHousingExpensePresentTotalMonthlyPaymentAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHousingExpenseProposedTotalMonthlyPaymentAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal housing_expense_present_total_monthly_payment_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.getHousingExpensePresentTotalMonthlyPaymentAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerExpenses} returns this
*/
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.setHousingExpensePresentTotalMonthlyPaymentAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerExpenses} returns this
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.clearHousingExpensePresentTotalMonthlyPaymentAmount = function() {
  return this.setHousingExpensePresentTotalMonthlyPaymentAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.hasHousingExpensePresentTotalMonthlyPaymentAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal housing_expense_proposed_total_monthly_payment_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.getHousingExpenseProposedTotalMonthlyPaymentAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerExpenses} returns this
*/
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.setHousingExpenseProposedTotalMonthlyPaymentAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerExpenses} returns this
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.clearHousingExpenseProposedTotalMonthlyPaymentAmount = function() {
  return this.setHousingExpenseProposedTotalMonthlyPaymentAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerExpenses.prototype.hasHousingExpenseProposedTotalMonthlyPaymentAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerREOSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerREOSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerTotalMortgagedPropertiesCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    urlaTotalReoLienInstallmentAmount: (f = msg.getUrlaTotalReoLienInstallmentAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    urlaTotalReoLienUpbAmount: (f = msg.getUrlaTotalReoLienUpbAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    urlaTotalReoMarketValueAmount: (f = msg.getUrlaTotalReoMarketValueAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    urlaTotalReoRentalIncomeGrossAmount: (f = msg.getUrlaTotalReoRentalIncomeGrossAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    urlaTotalReoRentalIncomeNetAmount: (f = msg.getUrlaTotalReoRentalIncomeNetAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerREOSummary;
  return proto.wilqo.api.mortgage.BorrowerREOSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerREOSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBorrowerTotalMortgagedPropertiesCount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalReoLienInstallmentAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalReoLienUpbAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalReoMarketValueAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalReoRentalIncomeGrossAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setUrlaTotalReoRentalIncomeNetAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerREOSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerREOSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerTotalMortgagedPropertiesCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrlaTotalReoLienInstallmentAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUrlaTotalReoLienUpbAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUrlaTotalReoMarketValueAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUrlaTotalReoRentalIncomeGrossAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getUrlaTotalReoRentalIncomeNetAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 borrower_total_mortgaged_properties_count = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.getBorrowerTotalMortgagedPropertiesCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.setBorrowerTotalMortgagedPropertiesCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_reo_lien_installment_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.getUrlaTotalReoLienInstallmentAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.setUrlaTotalReoLienInstallmentAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.clearUrlaTotalReoLienInstallmentAmount = function() {
  return this.setUrlaTotalReoLienInstallmentAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.hasUrlaTotalReoLienInstallmentAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_reo_lien_upb_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.getUrlaTotalReoLienUpbAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.setUrlaTotalReoLienUpbAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.clearUrlaTotalReoLienUpbAmount = function() {
  return this.setUrlaTotalReoLienUpbAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.hasUrlaTotalReoLienUpbAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_reo_market_value_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.getUrlaTotalReoMarketValueAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.setUrlaTotalReoMarketValueAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.clearUrlaTotalReoMarketValueAmount = function() {
  return this.setUrlaTotalReoMarketValueAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.hasUrlaTotalReoMarketValueAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_reo_rental_income_gross_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.getUrlaTotalReoRentalIncomeGrossAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.setUrlaTotalReoRentalIncomeGrossAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.clearUrlaTotalReoRentalIncomeGrossAmount = function() {
  return this.setUrlaTotalReoRentalIncomeGrossAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.hasUrlaTotalReoRentalIncomeGrossAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal urla_total_reo_rental_income_net_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.getUrlaTotalReoRentalIncomeNetAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
*/
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.setUrlaTotalReoRentalIncomeNetAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerREOSummary} returns this
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.clearUrlaTotalReoRentalIncomeNetAmount = function() {
  return this.setUrlaTotalReoRentalIncomeNetAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerREOSummary.prototype.hasUrlaTotalReoRentalIncomeNetAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.BorrowerApplicationInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    intentToProceedWithLoanTransactionIndicatedDate: (f = msg.getIntentToProceedWithLoanTransactionIndicatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    borrowerApplicationSignedDate: (f = msg.getBorrowerApplicationSignedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    mortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    housingCounselingAgenciesListProvidedDate: (f = msg.getHousingCounselingAgenciesListProvidedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hmdaDemographicCollectionDate: (f = msg.getHmdaDemographicCollectionDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    typeOfCredit: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.BorrowerApplicationInformation;
  return proto.wilqo.api.mortgage.BorrowerApplicationInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIntentToProceedWithLoanTransactionIndicatedDate(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBorrowerApplicationSignedDate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setHousingCounselingAgenciesListProvidedDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setHmdaDemographicCollectionDate(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.JointAssetLiabilityReportingEnum} */ (reader.readEnum());
      msg.setTypeOfCredit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.BorrowerApplicationInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntentToProceedWithLoanTransactionIndicatedDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBorrowerApplicationSignedDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHousingCounselingAgenciesListProvidedDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHmdaDemographicCollectionDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTypeOfCredit();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp intent_to_proceed_with_loan_transaction_indicated_date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.getIntentToProceedWithLoanTransactionIndicatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
*/
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.setIntentToProceedWithLoanTransactionIndicatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.clearIntentToProceedWithLoanTransactionIndicatedDate = function() {
  return this.setIntentToProceedWithLoanTransactionIndicatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.hasIntentToProceedWithLoanTransactionIndicatedDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp borrower_application_signed_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.getBorrowerApplicationSignedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
*/
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.setBorrowerApplicationSignedDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.clearBorrowerApplicationSignedDate = function() {
  return this.setBorrowerApplicationSignedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.hasBorrowerApplicationSignedDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool mortgage_originator_first_time_home_buyer_eligibility_indicator = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.getMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.setMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp housing_counseling_agencies_list_provided_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.getHousingCounselingAgenciesListProvidedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
*/
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.setHousingCounselingAgenciesListProvidedDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.clearHousingCounselingAgenciesListProvidedDate = function() {
  return this.setHousingCounselingAgenciesListProvidedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.hasHousingCounselingAgenciesListProvidedDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp hmda_demographic_collection_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.getHmdaDemographicCollectionDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
*/
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.setHmdaDemographicCollectionDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.clearHmdaDemographicCollectionDate = function() {
  return this.setHmdaDemographicCollectionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.hasHmdaDemographicCollectionDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional JointAssetLiabilityReportingEnum type_of_credit = 6;
 * @return {!proto.wilqo.api.mortgage.JointAssetLiabilityReportingEnum}
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.getTypeOfCredit = function() {
  return /** @type {!proto.wilqo.api.mortgage.JointAssetLiabilityReportingEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.JointAssetLiabilityReportingEnum} value
 * @return {!proto.wilqo.api.mortgage.BorrowerApplicationInformation} returns this
 */
proto.wilqo.api.mortgage.BorrowerApplicationInformation.prototype.setTypeOfCredit = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanAssetSummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanAssetSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanAssetSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAssetSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryTotalValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.wilqo.api.mortgage.LoanAsset.toObject, includeInstance),
    displayType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    iscollapsed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanAssetSummary;
  return proto.wilqo.api.mortgage.LoanAssetSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanAssetSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryTotalValue(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.LoanAsset;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanAsset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplayType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscollapsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanAssetSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanAssetSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAssetSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryTotalValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.LoanAsset.serializeBinaryToWriter
    );
  }
  f = message.getDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIscollapsed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_total_value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.getCategoryTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.setCategoryTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LoanAsset assets = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanAsset>}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanAsset, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanAsset>} value
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary} returns this
*/
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanAsset}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * optional uint32 display_type = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.getDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool isCollapsed = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.getIscollapsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanAssetSummary} returns this
 */
proto.wilqo.api.mortgage.LoanAssetSummary.prototype.setIscollapsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountTotalValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: (f = msg.getStatus()) && proto.wilqo.api.mortgage.UxDisplayBadge.toObject(includeInstance, f),
    displayType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanAsset}
 */
proto.wilqo.api.mortgage.LoanAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanAsset;
  return proto.wilqo.api.mortgage.LoanAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanAsset}
 */
proto.wilqo.api.mortgage.LoanAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountTotalValue(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.UxDisplayBadge;
      reader.readMessage(value,proto.wilqo.api.mortgage.UxDisplayBadge.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplayType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountTotalValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage.UxDisplayBadge.serializeBinaryToWriter
    );
  }
  f = message.getDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string account_type = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAsset} returns this
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_total_value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.getAccountTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAsset} returns this
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.setAccountTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional UxDisplayBadge status = 3;
 * @return {?proto.wilqo.api.mortgage.UxDisplayBadge}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.getStatus = function() {
  return /** @type{?proto.wilqo.api.mortgage.UxDisplayBadge} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.UxDisplayBadge, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.UxDisplayBadge|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAsset} returns this
*/
proto.wilqo.api.mortgage.LoanAsset.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAsset} returns this
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 display_type = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.getDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanAsset} returns this
 */
proto.wilqo.api.mortgage.LoanAsset.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.IncomeSummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IncomeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IncomeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IncomeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    detailList: jspb.Message.toObjectList(msg.getDetailList(),
    proto.wilqo.api.mortgage.IncomeDetail.toObject, includeInstance),
    sumTotal: jspb.Message.getFieldWithDefault(msg, 4, ""),
    displayType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IncomeSummary}
 */
proto.wilqo.api.mortgage.IncomeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IncomeSummary;
  return proto.wilqo.api.mortgage.IncomeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IncomeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IncomeSummary}
 */
proto.wilqo.api.mortgage.IncomeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.IncomeDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage.IncomeDetail.deserializeBinaryFromReader);
      msg.addDetail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSumTotal(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (reader.readEnum());
      msg.setDisplayType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IncomeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IncomeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IncomeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.IncomeDetail.serializeBinaryToWriter
    );
  }
  f = message.getSumTotal();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDisplayType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IncomeSummary} returns this
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated IncomeDetail detail = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.IncomeDetail>}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.getDetailList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.IncomeDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.IncomeDetail, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.IncomeDetail>} value
 * @return {!proto.wilqo.api.mortgage.IncomeSummary} returns this
*/
proto.wilqo.api.mortgage.IncomeSummary.prototype.setDetailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.IncomeDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.IncomeDetail}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.addDetail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.IncomeDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.IncomeSummary} returns this
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.clearDetailList = function() {
  return this.setDetailList([]);
};


/**
 * optional string sum_total = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.getSumTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IncomeSummary} returns this
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.setSumTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DisplayType display_type = 5;
 * @return {!proto.wilqo.api.mortgage.DisplayType}
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.getDisplayType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisplayType} value
 * @return {!proto.wilqo.api.mortgage.IncomeSummary} returns this
 */
proto.wilqo.api.mortgage.IncomeSummary.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IncomeDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IncomeDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IncomeDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    verified: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    totalValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    displayType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IncomeDetail}
 */
proto.wilqo.api.mortgage.IncomeDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IncomeDetail;
  return proto.wilqo.api.mortgage.IncomeDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IncomeDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IncomeDetail}
 */
proto.wilqo.api.mortgage.IncomeDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerified(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalValue(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (reader.readEnum());
      msg.setDisplayType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IncomeDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IncomeDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IncomeDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVerified();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTotalValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDisplayType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IncomeDetail} returns this
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool verified = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.getVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IncomeDetail} returns this
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.setVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string total_value = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.getTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IncomeDetail} returns this
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.setTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DisplayType display_type = 5;
 * @return {!proto.wilqo.api.mortgage.DisplayType}
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.getDisplayType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisplayType} value
 * @return {!proto.wilqo.api.mortgage.IncomeDetail} returns this
 */
proto.wilqo.api.mortgage.IncomeDetail.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.EmploymentInformation.repeatedFields_ = [1,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.EmploymentInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.EmploymentInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.EmploymentInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    proto.wilqo.api.mortgage.CardListItem.toObject, includeInstance),
    employmentDetailsList: jspb.Message.toObjectList(msg.getEmploymentDetailsList(),
    proto.wilqo.api.mortgage.EmploymentDetail.toObject, includeInstance),
    detailsOverviewList: jspb.Message.toObjectList(msg.getDetailsOverviewList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation}
 */
proto.wilqo.api.mortgage.EmploymentInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.EmploymentInformation;
  return proto.wilqo.api.mortgage.EmploymentInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.EmploymentInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation}
 */
proto.wilqo.api.mortgage.EmploymentInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.CardListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.EmploymentDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage.EmploymentDetail.deserializeBinaryFromReader);
      msg.addEmploymentDetails(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addDetailsOverview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.EmploymentInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.EmploymentInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.EmploymentInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter
    );
  }
  f = message.getEmploymentDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.EmploymentDetail.serializeBinaryToWriter
    );
  }
  f = message.getDetailsOverviewList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CardListItem card_list = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CardListItem, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation} returns this
*/
proto.wilqo.api.mortgage.EmploymentInformation.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation} returns this
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};


/**
 * repeated EmploymentDetail employment_details = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.EmploymentDetail>}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.getEmploymentDetailsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.EmploymentDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.EmploymentDetail, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.EmploymentDetail>} value
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation} returns this
*/
proto.wilqo.api.mortgage.EmploymentInformation.prototype.setEmploymentDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.EmploymentDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.addEmploymentDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.EmploymentDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation} returns this
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.clearEmploymentDetailsList = function() {
  return this.setEmploymentDetailsList([]);
};


/**
 * repeated wilqo.shared.models.PanelElement details_overview = 4;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.getDetailsOverviewList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation} returns this
*/
proto.wilqo.api.mortgage.EmploymentInformation.prototype.setDetailsOverviewList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.addDetailsOverview = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.EmploymentInformation} returns this
 */
proto.wilqo.api.mortgage.EmploymentInformation.prototype.clearDetailsOverviewList = function() {
  return this.setDetailsOverviewList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.EmploymentDetail.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.EmploymentDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.EmploymentDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.EmploymentDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partyRole: jspb.Message.getFieldWithDefault(msg, 2, 0),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    employersList: jspb.Message.toObjectList(msg.getEmployersList(),
    proto.wilqo.api.mortgage.EmployerInBorrower.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail}
 */
proto.wilqo.api.mortgage.EmploymentDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.EmploymentDetail;
  return proto.wilqo.api.mortgage.EmploymentDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.EmploymentDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail}
 */
proto.wilqo.api.mortgage.EmploymentDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (reader.readEnum());
      msg.setPartyRole(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.EmployerInBorrower;
      reader.readMessage(value,proto.wilqo.api.mortgage.EmployerInBorrower.deserializeBinaryFromReader);
      msg.addEmployers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.EmploymentDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.EmploymentDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.EmploymentDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartyRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmployersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.EmployerInBorrower.serializeBinaryToWriter
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail} returns this
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PartyRoleEnum party_role = 2;
 * @return {!proto.wilqo.api.mortgage.PartyRoleEnum}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.getPartyRole = function() {
  return /** @type {!proto.wilqo.api.mortgage.PartyRoleEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PartyRoleEnum} value
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail} returns this
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.setPartyRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string borrower_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail} returns this
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated EmployerInBorrower employers = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.EmployerInBorrower>}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.getEmployersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.EmployerInBorrower>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.EmployerInBorrower, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.EmployerInBorrower>} value
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail} returns this
*/
proto.wilqo.api.mortgage.EmploymentDetail.prototype.setEmployersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.EmployerInBorrower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.EmployerInBorrower}
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.addEmployers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.EmployerInBorrower, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.EmploymentDetail} returns this
 */
proto.wilqo.api.mortgage.EmploymentDetail.prototype.clearEmployersList = function() {
  return this.setEmployersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.EmployerInBorrower.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.EmployerInBorrower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.EmployerInBorrower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.EmployerInBorrower.toObject = function(includeInstance, msg) {
  var f, obj = {
    employerIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    detailFieldsList: jspb.Message.toObjectList(msg.getDetailFieldsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.EmployerInBorrower}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.EmployerInBorrower;
  return proto.wilqo.api.mortgage.EmployerInBorrower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.EmployerInBorrower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.EmployerInBorrower}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEmployerIndex(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addDetailFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.EmployerInBorrower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.EmployerInBorrower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.EmployerInBorrower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployerIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDetailFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 employer_index = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.getEmployerIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.EmployerInBorrower} returns this
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.setEmployerIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated wilqo.shared.models.PanelElement detail_fields = 2;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.getDetailFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.EmployerInBorrower} returns this
*/
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.setDetailFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.addDetailFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.EmployerInBorrower} returns this
 */
proto.wilqo.api.mortgage.EmployerInBorrower.prototype.clearDetailFieldsList = function() {
  return this.setDetailFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.UpsertEmployer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.UpsertEmployer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertEmployer.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusBadge: (f = msg.getStatusBadge()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    statusType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    classificationType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    employmentStartDate: (f = msg.getEmploymentStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    employmentEndDate: (f = msg.getEmploymentEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    yearsOnJobCount: (f = msg.getYearsOnJobCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthsOnJobCount: (f = msg.getMonthsOnJobCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    timeInLineOfWorkYearsCount: (f = msg.getTimeInLineOfWorkYearsCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    timeInLineOfWorkMonthsCount: (f = msg.getTimeInLineOfWorkMonthsCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    positionDescription: (f = msg.getPositionDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    employerName: (f = msg.getEmployerName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    selfEmployedIndicator: (f = msg.getSelfEmployedIndicator()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    specialBorrowerEmployerRelationshipIndicator: (f = msg.getSpecialBorrowerEmployerRelationshipIndicator()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer}
 */
proto.wilqo.api.mortgage.UpsertEmployer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.UpsertEmployer;
  return proto.wilqo.api.mortgage.UpsertEmployer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.UpsertEmployer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer}
 */
proto.wilqo.api.mortgage.UpsertEmployer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStatusBadge(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.EmploymentStatusEnum} */ (reader.readEnum());
      msg.setStatusType(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.EmploymentClassificationEnum} */ (reader.readEnum());
      msg.setClassificationType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEmploymentStartDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEmploymentEndDate(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setYearsOnJobCount(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthsOnJobCount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTimeInLineOfWorkYearsCount(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTimeInLineOfWorkMonthsCount(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPositionDescription(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmployerName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSelfEmployedIndicator(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSpecialBorrowerEmployerRelationshipIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.UpsertEmployer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.UpsertEmployer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.UpsertEmployer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusBadge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage.EmploymentStatusEnum} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage.EmploymentClassificationEnum} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getEmploymentStartDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEmploymentEndDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getYearsOnJobCount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthsOnJobCount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTimeInLineOfWorkYearsCount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTimeInLineOfWorkMonthsCount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPositionDescription();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmployerName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSelfEmployedIndicator();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSpecialBorrowerEmployerRelationshipIndicator();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue status_badge = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getStatusBadge = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setStatusBadge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearStatusBadge = function() {
  return this.setStatusBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasStatusBadge = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EmploymentStatusEnum status_type = 2;
 * @return {!proto.wilqo.api.mortgage.EmploymentStatusEnum}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.EmploymentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.EmploymentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setStatusType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearStatusType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasStatusType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EmploymentClassificationEnum classification_type = 3;
 * @return {!proto.wilqo.api.mortgage.EmploymentClassificationEnum}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.EmploymentClassificationEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.EmploymentClassificationEnum} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setClassificationType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearClassificationType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasClassificationType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp employment_start_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getEmploymentStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setEmploymentStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearEmploymentStartDate = function() {
  return this.setEmploymentStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasEmploymentStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp employment_end_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getEmploymentEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setEmploymentEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearEmploymentEndDate = function() {
  return this.setEmploymentEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasEmploymentEndDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value years_on_job_count = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getYearsOnJobCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setYearsOnJobCount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearYearsOnJobCount = function() {
  return this.setYearsOnJobCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasYearsOnJobCount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value months_on_job_count = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getMonthsOnJobCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setMonthsOnJobCount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearMonthsOnJobCount = function() {
  return this.setMonthsOnJobCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasMonthsOnJobCount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value time_in_line_of_work_years_count = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getTimeInLineOfWorkYearsCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setTimeInLineOfWorkYearsCount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearTimeInLineOfWorkYearsCount = function() {
  return this.setTimeInLineOfWorkYearsCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasTimeInLineOfWorkYearsCount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value time_in_line_of_work_months_count = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getTimeInLineOfWorkMonthsCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setTimeInLineOfWorkMonthsCount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearTimeInLineOfWorkMonthsCount = function() {
  return this.setTimeInLineOfWorkMonthsCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasTimeInLineOfWorkMonthsCount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue position_description = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getPositionDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setPositionDescription = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearPositionDescription = function() {
  return this.setPositionDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasPositionDescription = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue employer_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getEmployerName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setEmployerName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearEmployerName = function() {
  return this.setEmployerName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasEmployerName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.BoolValue self_employed_indicator = 12;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getSelfEmployedIndicator = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 12));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setSelfEmployedIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearSelfEmployedIndicator = function() {
  return this.setSelfEmployedIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasSelfEmployedIndicator = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.BoolValue special_borrower_employer_relationship_indicator = 13;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.getSpecialBorrowerEmployerRelationshipIndicator = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 13));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
*/
proto.wilqo.api.mortgage.UpsertEmployer.prototype.setSpecialBorrowerEmployerRelationshipIndicator = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.UpsertEmployer} returns this
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.clearSpecialBorrowerEmployerRelationshipIndicator = function() {
  return this.setSpecialBorrowerEmployerRelationshipIndicator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.UpsertEmployer.prototype.hasSpecialBorrowerEmployerRelationshipIndicator = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.InfoCard.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InfoCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InfoCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InfoCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    overline: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage.InfoCardItem.toObject, includeInstance),
    infoCardType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InfoCard}
 */
proto.wilqo.api.mortgage.InfoCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InfoCard;
  return proto.wilqo.api.mortgage.InfoCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InfoCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InfoCard}
 */
proto.wilqo.api.mortgage.InfoCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverline(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.InfoCardItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.InfoCardItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.InfoCardTypeEnum} */ (reader.readEnum());
      msg.setInfoCardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InfoCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InfoCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InfoCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOverline();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.InfoCardItem.serializeBinaryToWriter
    );
  }
  f = message.getInfoCardType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string overline = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.getOverline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InfoCard} returns this
 */
proto.wilqo.api.mortgage.InfoCard.prototype.setOverline = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InfoCard} returns this
 */
proto.wilqo.api.mortgage.InfoCard.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated InfoCardItem items = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.InfoCardItem>}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.InfoCardItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.InfoCardItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.InfoCardItem>} value
 * @return {!proto.wilqo.api.mortgage.InfoCard} returns this
*/
proto.wilqo.api.mortgage.InfoCard.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.InfoCardItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.InfoCardItem}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.InfoCardItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.InfoCard} returns this
 */
proto.wilqo.api.mortgage.InfoCard.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional wilqo.shared.models.InfoCardTypeEnum info_card_type = 4;
 * @return {!proto.wilqo.shared.models.InfoCardTypeEnum}
 */
proto.wilqo.api.mortgage.InfoCard.prototype.getInfoCardType = function() {
  return /** @type {!proto.wilqo.shared.models.InfoCardTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.InfoCardTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.InfoCard} returns this
 */
proto.wilqo.api.mortgage.InfoCard.prototype.setInfoCardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.InfoCardItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.InfoCardItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.InfoCardItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InfoCardItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.InfoCardItem}
 */
proto.wilqo.api.mortgage.InfoCardItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.InfoCardItem;
  return proto.wilqo.api.mortgage.InfoCardItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.InfoCardItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.InfoCardItem}
 */
proto.wilqo.api.mortgage.InfoCardItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.InfoCardItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.InfoCardItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.InfoCardItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.InfoCardItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.InfoCardItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InfoCardItem} returns this
 */
proto.wilqo.api.mortgage.InfoCardItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.InfoCardItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.InfoCardItem} returns this
 */
proto.wilqo.api.mortgage.InfoCardItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IndividualFeePayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IndividualFeePayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualFeePayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    paidByBorrowerFinancedAmount: (f = msg.getPaidByBorrowerFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerOutsideOfClosingAmount: (f = msg.getPaidByBorrowerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerAtClosingTotalAmount: (f = msg.getPaidByBorrowerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalAmount: (f = msg.getPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerOutsideOfClosingAmount: (f = msg.getPaidBySellerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerCreditAmount: (f = msg.getPaidBySellerCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerAtClosingTotalAmount: (f = msg.getPaidBySellerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalAmount: (f = msg.getPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderOutsideOfClosingAmount: (f = msg.getPaidByLenderOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderAtClosingTotalAmount: (f = msg.getPaidByLenderAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalAmount: (f = msg.getPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherOutsideOfClosingAmount: (f = msg.getPaidByOtherOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherAtClosingTotalAmount: (f = msg.getPaidByOtherAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalAmount: (f = msg.getPaidByOtherTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaymentPaidByOtherType: jspb.Message.getFieldWithDefault(msg, 15, 0),
    feeCalculationType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    feePercentBasisType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    feeType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    integratedDisclosureSectionType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    integratedDisclosureLineNumberValue: jspb.Message.getFieldWithDefault(msg, 20, ""),
    regulationZPointsAndFeesIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    borrowerChosenProviderIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    paymentDate: (f = msg.getPaymentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    payeePartySummary: (f = msg.getPayeePartySummary()) && proto.wilqo.api.mortgage.PartySummary.toObject(includeInstance, f),
    payeeContactInfo: (f = msg.getPayeeContactInfo()) && proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.toObject(includeInstance, f),
    feeSpecifiedFixedAmount: (f = msg.getFeeSpecifiedFixedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeTotalPercent: (f = msg.getFeeTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeSource: jspb.Message.getFieldWithDefault(msg, 28, 0),
    feeToleranceCategoryType: jspb.Message.getFieldWithDefault(msg, 29, 0),
    paidByLenderCreditAmount: (f = msg.getPaidByLenderCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    providerSelectedFromLenderListIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    defaultFeeToleranceCategoryType: jspb.Message.getFieldWithDefault(msg, 32, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IndividualFeePayment;
  return proto.wilqo.api.mortgage.IndividualFeePayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IndividualFeePayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerFinancedAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerOutsideOfClosingAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerAtClosingTotalAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerOutsideOfClosingAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerCreditAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerAtClosingTotalAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderOutsideOfClosingAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderAtClosingTotalAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalAmount(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherOutsideOfClosingAmount(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherAtClosingTotalAmount(value);
      break;
    case 14:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalAmount(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.api.mortgage.FeePaymentPaidByEnum} */ (reader.readEnum());
      msg.setFeePaymentPaidByOtherType(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} */ (reader.readEnum());
      msg.setFeeCalculationType(value);
      break;
    case 17:
      var value = /** @type {!proto.wilqo.api.mortgage.FeePercentBasisEnum} */ (reader.readEnum());
      msg.setFeePercentBasisType(value);
      break;
    case 18:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeEnum} */ (reader.readEnum());
      msg.setFeeType(value);
      break;
    case 19:
      var value = /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSectionType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegratedDisclosureLineNumberValue(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegulationZPointsAndFeesIndicator(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerChosenProviderIndicator(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentDate(value);
      break;
    case 24:
      var value = new proto.wilqo.api.mortgage.PartySummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartySummary.deserializeBinaryFromReader);
      msg.setPayeePartySummary(value);
      break;
    case 25:
      var value = new proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.deserializeBinaryFromReader);
      msg.setPayeeContactInfo(value);
      break;
    case 26:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeSpecifiedFixedAmount(value);
      break;
    case 27:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeTotalPercent(value);
      break;
    case 28:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (reader.readEnum());
      msg.setFeeSource(value);
      break;
    case 29:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} */ (reader.readEnum());
      msg.setFeeToleranceCategoryType(value);
      break;
    case 30:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderCreditAmount(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProviderSelectedFromLenderListIndicator(value);
      break;
    case 32:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} */ (reader.readEnum());
      msg.setDefaultFeeToleranceCategoryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IndividualFeePayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IndividualFeePayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualFeePayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaidByBorrowerFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerCreditAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalAmount();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaymentPaidByOtherType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getFeeCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getFeePercentBasisType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getFeeType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getIntegratedDisclosureSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getIntegratedDisclosureLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRegulationZPointsAndFeesIndicator();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getBorrowerChosenProviderIndicator();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getPaymentDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPayeePartySummary();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.wilqo.api.mortgage.PartySummary.serializeBinaryToWriter
    );
  }
  f = message.getPayeeContactInfo();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.serializeBinaryToWriter
    );
  }
  f = message.getFeeSpecifiedFixedAmount();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeTotalPercent();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeSource();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getFeeToleranceCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getPaidByLenderCreditAmount();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getProviderSelectedFromLenderListIndicator();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getDefaultFeeToleranceCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      32,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_financed_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByBorrowerFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByBorrowerFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByBorrowerFinancedAmount = function() {
  return this.setPaidByBorrowerFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByBorrowerFinancedAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_outside_of_closing_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByBorrowerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByBorrowerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByBorrowerOutsideOfClosingAmount = function() {
  return this.setPaidByBorrowerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByBorrowerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_at_closing_total_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByBorrowerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByBorrowerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByBorrowerAtClosingTotalAmount = function() {
  return this.setPaidByBorrowerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByBorrowerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByBorrowerTotalAmount = function() {
  return this.setPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_outside_of_closing_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidBySellerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidBySellerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidBySellerOutsideOfClosingAmount = function() {
  return this.setPaidBySellerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidBySellerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_credit_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidBySellerCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidBySellerCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidBySellerCreditAmount = function() {
  return this.setPaidBySellerCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidBySellerCreditAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_at_closing_total_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidBySellerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidBySellerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidBySellerAtClosingTotalAmount = function() {
  return this.setPaidBySellerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidBySellerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidBySellerTotalAmount = function() {
  return this.setPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_outside_of_closing_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByLenderOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByLenderOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByLenderOutsideOfClosingAmount = function() {
  return this.setPaidByLenderOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByLenderOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_at_closing_total_amount = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByLenderAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByLenderAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByLenderAtClosingTotalAmount = function() {
  return this.setPaidByLenderAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByLenderAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_amount = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByLenderTotalAmount = function() {
  return this.setPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_outside_of_closing_amount = 12;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByOtherOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 12));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByOtherOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByOtherOutsideOfClosingAmount = function() {
  return this.setPaidByOtherOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByOtherOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_at_closing_total_amount = 13;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByOtherAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 13));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByOtherAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByOtherAtClosingTotalAmount = function() {
  return this.setPaidByOtherAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByOtherAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_amount = 14;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByOtherTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 14));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByOtherTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByOtherTotalAmount = function() {
  return this.setPaidByOtherTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByOtherTotalAmount = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional FeePaymentPaidByEnum fee_payment_paid_by_other_type = 15;
 * @return {!proto.wilqo.api.mortgage.FeePaymentPaidByEnum}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeePaymentPaidByOtherType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeePaymentPaidByEnum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeePaymentPaidByEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeePaymentPaidByOtherType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional FeeCalculationTypeEnum fee_calculation_type = 16;
 * @return {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeeCalculationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeeCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional FeePercentBasisEnum fee_percent_basis_type = 17;
 * @return {!proto.wilqo.api.mortgage.FeePercentBasisEnum}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeePercentBasisType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeePercentBasisEnum} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeePercentBasisEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeePercentBasisType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional FeeEnum fee_type = 18;
 * @return {!proto.wilqo.api.mortgage.FeeEnum}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeeType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeEnum} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional IntegratedDisclosureSectionEnum integrated_disclosure_section_type = 19;
 * @return {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getIntegratedDisclosureSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setIntegratedDisclosureSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string integrated_disclosure_line_number_value = 20;
 * @return {string}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getIntegratedDisclosureLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setIntegratedDisclosureLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool regulation_z_points_and_fees_indicator = 21;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getRegulationZPointsAndFeesIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setRegulationZPointsAndFeesIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool borrower_chosen_provider_indicator = 22;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getBorrowerChosenProviderIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setBorrowerChosenProviderIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional google.protobuf.Timestamp payment_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaymentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaymentDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaymentDate = function() {
  return this.setPaymentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaymentDate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PartySummary payee_party_summary = 24;
 * @return {?proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPayeePartySummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartySummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartySummary, 24));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartySummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPayeePartySummary = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPayeePartySummary = function() {
  return this.setPayeePartySummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPayeePartySummary = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PartyFeePayeeContactInfoCardData payee_contact_info = 25;
 * @return {?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPayeeContactInfo = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData, 25));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPayeeContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPayeeContactInfo = function() {
  return this.setPayeeContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPayeeContactInfo = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_specified_fixed_amount = 26;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeeSpecifiedFixedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 26));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeeSpecifiedFixedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearFeeSpecifiedFixedAmount = function() {
  return this.setFeeSpecifiedFixedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasFeeSpecifiedFixedAmount = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_total_percent = 27;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeeTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 27));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeeTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearFeeTotalPercent = function() {
  return this.setFeeTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasFeeTotalPercent = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional FeeSourceEnum fee_source = 28;
 * @return {!proto.wilqo.api.mortgage.FeeSourceEnum}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeeSource = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeSourceEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeeSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional FeeToleranceCategoryType fee_tolerance_category_type = 29;
 * @return {!proto.wilqo.api.mortgage.FeeToleranceCategoryType}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getFeeToleranceCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setFeeToleranceCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_credit_amount = 30;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getPaidByLenderCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 30));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
*/
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setPaidByLenderCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.clearPaidByLenderCreditAmount = function() {
  return this.setPaidByLenderCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.hasPaidByLenderCreditAmount = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool provider_selected_from_lender_list_indicator = 31;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getProviderSelectedFromLenderListIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setProviderSelectedFromLenderListIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional FeeToleranceCategoryType default_fee_tolerance_category_type = 32;
 * @return {!proto.wilqo.api.mortgage.FeeToleranceCategoryType}
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.getDefaultFeeToleranceCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeePayment} returns this
 */
proto.wilqo.api.mortgage.IndividualFeePayment.prototype.setDefaultFeeToleranceCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 32, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IndividualEscrowPayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IndividualEscrowPayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    paidByBorrowerFinancedAmount: (f = msg.getPaidByBorrowerFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerOutsideOfClosingAmount: (f = msg.getPaidByBorrowerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerAtClosingTotalAmount: (f = msg.getPaidByBorrowerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalAmount: (f = msg.getPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerOutsideOfClosingAmount: (f = msg.getPaidBySellerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerCreditAmount: (f = msg.getPaidBySellerCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerAtClosingTotalAmount: (f = msg.getPaidBySellerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalAmount: (f = msg.getPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderOutsideOfClosingAmount: (f = msg.getPaidByLenderOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderAtClosingTotalAmount: (f = msg.getPaidByLenderAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalAmount: (f = msg.getPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    escrowCalculationType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    escrowType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    integratedDisclosureSectionType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    integratedDisclosureLineNumberValue: jspb.Message.getFieldWithDefault(msg, 20, ""),
    regulationZPointsAndFeesIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    borrowerChosenProviderIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    paymentDate: (f = msg.getPaymentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    payeePartySummary: (f = msg.getPayeePartySummary()) && proto.wilqo.api.mortgage.PartySummary.toObject(includeInstance, f),
    payeeContactInfo: (f = msg.getPayeeContactInfo()) && proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.toObject(includeInstance, f),
    escrowTotalPercent: (f = msg.getEscrowTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    escrowSource: jspb.Message.getFieldWithDefault(msg, 27, 0),
    paidByLenderCreditAmount: (f = msg.getPaidByLenderCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    providerSelectedFromLenderListIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 29, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IndividualEscrowPayment;
  return proto.wilqo.api.mortgage.IndividualEscrowPayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IndividualEscrowPayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerFinancedAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerOutsideOfClosingAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerAtClosingTotalAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerOutsideOfClosingAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerCreditAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerAtClosingTotalAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderOutsideOfClosingAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderAtClosingTotalAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalAmount(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} */ (reader.readEnum());
      msg.setEscrowCalculationType(value);
      break;
    case 18:
      var value = /** @type {!proto.wilqo.api.mortgage.EscrowItemEnum} */ (reader.readEnum());
      msg.setEscrowType(value);
      break;
    case 19:
      var value = /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSectionType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegratedDisclosureLineNumberValue(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegulationZPointsAndFeesIndicator(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerChosenProviderIndicator(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentDate(value);
      break;
    case 24:
      var value = new proto.wilqo.api.mortgage.PartySummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartySummary.deserializeBinaryFromReader);
      msg.setPayeePartySummary(value);
      break;
    case 25:
      var value = new proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.deserializeBinaryFromReader);
      msg.setPayeeContactInfo(value);
      break;
    case 26:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setEscrowTotalPercent(value);
      break;
    case 27:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (reader.readEnum());
      msg.setEscrowSource(value);
      break;
    case 28:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderCreditAmount(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProviderSelectedFromLenderListIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IndividualEscrowPayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IndividualEscrowPayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaidByBorrowerFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerCreditAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getEscrowCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getEscrowType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getIntegratedDisclosureSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getIntegratedDisclosureLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRegulationZPointsAndFeesIndicator();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getBorrowerChosenProviderIndicator();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getPaymentDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPayeePartySummary();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.wilqo.api.mortgage.PartySummary.serializeBinaryToWriter
    );
  }
  f = message.getPayeeContactInfo();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.serializeBinaryToWriter
    );
  }
  f = message.getEscrowTotalPercent();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getEscrowSource();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getPaidByLenderCreditAmount();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getProviderSelectedFromLenderListIndicator();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_financed_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByBorrowerFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByBorrowerFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByBorrowerFinancedAmount = function() {
  return this.setPaidByBorrowerFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByBorrowerFinancedAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_outside_of_closing_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByBorrowerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByBorrowerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByBorrowerOutsideOfClosingAmount = function() {
  return this.setPaidByBorrowerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByBorrowerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_at_closing_total_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByBorrowerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByBorrowerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByBorrowerAtClosingTotalAmount = function() {
  return this.setPaidByBorrowerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByBorrowerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByBorrowerTotalAmount = function() {
  return this.setPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_outside_of_closing_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidBySellerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidBySellerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidBySellerOutsideOfClosingAmount = function() {
  return this.setPaidBySellerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidBySellerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_credit_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidBySellerCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidBySellerCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidBySellerCreditAmount = function() {
  return this.setPaidBySellerCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidBySellerCreditAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_at_closing_total_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidBySellerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidBySellerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidBySellerAtClosingTotalAmount = function() {
  return this.setPaidBySellerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidBySellerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidBySellerTotalAmount = function() {
  return this.setPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_outside_of_closing_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByLenderOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByLenderOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByLenderOutsideOfClosingAmount = function() {
  return this.setPaidByLenderOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByLenderOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_at_closing_total_amount = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByLenderAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByLenderAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByLenderAtClosingTotalAmount = function() {
  return this.setPaidByLenderAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByLenderAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_amount = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByLenderTotalAmount = function() {
  return this.setPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional FeeCalculationTypeEnum escrow_calculation_type = 16;
 * @return {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getEscrowCalculationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setEscrowCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional EscrowItemEnum escrow_type = 18;
 * @return {!proto.wilqo.api.mortgage.EscrowItemEnum}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getEscrowType = function() {
  return /** @type {!proto.wilqo.api.mortgage.EscrowItemEnum} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.EscrowItemEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setEscrowType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional IntegratedDisclosureSectionEnum integrated_disclosure_section_type = 19;
 * @return {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getIntegratedDisclosureSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setIntegratedDisclosureSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string integrated_disclosure_line_number_value = 20;
 * @return {string}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getIntegratedDisclosureLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setIntegratedDisclosureLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool regulation_z_points_and_fees_indicator = 21;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getRegulationZPointsAndFeesIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setRegulationZPointsAndFeesIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool borrower_chosen_provider_indicator = 22;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getBorrowerChosenProviderIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setBorrowerChosenProviderIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional google.protobuf.Timestamp payment_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaymentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaymentDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaymentDate = function() {
  return this.setPaymentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaymentDate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PartySummary payee_party_summary = 24;
 * @return {?proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPayeePartySummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartySummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartySummary, 24));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartySummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPayeePartySummary = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPayeePartySummary = function() {
  return this.setPayeePartySummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPayeePartySummary = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PartyFeePayeeContactInfoCardData payee_contact_info = 25;
 * @return {?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPayeeContactInfo = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData, 25));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPayeeContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPayeeContactInfo = function() {
  return this.setPayeeContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPayeeContactInfo = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal escrow_total_percent = 26;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getEscrowTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 26));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setEscrowTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearEscrowTotalPercent = function() {
  return this.setEscrowTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasEscrowTotalPercent = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional FeeSourceEnum escrow_source = 27;
 * @return {!proto.wilqo.api.mortgage.FeeSourceEnum}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getEscrowSource = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeSourceEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setEscrowSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_credit_amount = 28;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getPaidByLenderCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 28));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setPaidByLenderCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.clearPaidByLenderCreditAmount = function() {
  return this.setPaidByLenderCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.hasPaidByLenderCreditAmount = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional bool provider_selected_from_lender_list_indicator = 29;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.getProviderSelectedFromLenderListIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualEscrowPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualEscrowPayment.prototype.setProviderSelectedFromLenderListIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IndividualPrepaidPayment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.toObject = function(includeInstance, msg) {
  var f, obj = {
    paidByBorrowerFinancedAmount: (f = msg.getPaidByBorrowerFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerOutsideOfClosingAmount: (f = msg.getPaidByBorrowerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerAtClosingTotalAmount: (f = msg.getPaidByBorrowerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalAmount: (f = msg.getPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerOutsideOfClosingAmount: (f = msg.getPaidBySellerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerCreditAmount: (f = msg.getPaidBySellerCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerAtClosingTotalAmount: (f = msg.getPaidBySellerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalAmount: (f = msg.getPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderOutsideOfClosingAmount: (f = msg.getPaidByLenderOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderAtClosingTotalAmount: (f = msg.getPaidByLenderAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalAmount: (f = msg.getPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    prepaidCalculationType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    prepaidType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    integratedDisclosureSectionType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    integratedDisclosureLineNumberValue: jspb.Message.getFieldWithDefault(msg, 20, ""),
    regulationZPointsAndFeesIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    borrowerChosenProviderIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    paymentDate: (f = msg.getPaymentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    payeePartySummary: (f = msg.getPayeePartySummary()) && proto.wilqo.api.mortgage.PartySummary.toObject(includeInstance, f),
    payeeContactInfo: (f = msg.getPayeeContactInfo()) && proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.toObject(includeInstance, f),
    prepaidTotalAmount: (f = msg.getPrepaidTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    prepaidSource: jspb.Message.getFieldWithDefault(msg, 27, 0),
    paidByLenderCreditAmount: (f = msg.getPaidByLenderCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    providerSelectedFromLenderListIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 29, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IndividualPrepaidPayment;
  return proto.wilqo.api.mortgage.IndividualPrepaidPayment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerFinancedAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerOutsideOfClosingAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerAtClosingTotalAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerOutsideOfClosingAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerCreditAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerAtClosingTotalAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderOutsideOfClosingAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderAtClosingTotalAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalAmount(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} */ (reader.readEnum());
      msg.setPrepaidCalculationType(value);
      break;
    case 18:
      var value = /** @type {!proto.wilqo.api.mortgage.PrepaidItemEnum} */ (reader.readEnum());
      msg.setPrepaidType(value);
      break;
    case 19:
      var value = /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSectionType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegratedDisclosureLineNumberValue(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegulationZPointsAndFeesIndicator(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerChosenProviderIndicator(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentDate(value);
      break;
    case 24:
      var value = new proto.wilqo.api.mortgage.PartySummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartySummary.deserializeBinaryFromReader);
      msg.setPayeePartySummary(value);
      break;
    case 25:
      var value = new proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData;
      reader.readMessage(value,proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.deserializeBinaryFromReader);
      msg.setPayeeContactInfo(value);
      break;
    case 26:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPrepaidTotalAmount(value);
      break;
    case 27:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (reader.readEnum());
      msg.setPrepaidSource(value);
      break;
    case 28:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderCreditAmount(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProviderSelectedFromLenderListIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IndividualPrepaidPayment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaidByBorrowerFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerCreditAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrepaidCalculationType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getPrepaidType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getIntegratedDisclosureSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getIntegratedDisclosureLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRegulationZPointsAndFeesIndicator();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getBorrowerChosenProviderIndicator();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getPaymentDate();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPayeePartySummary();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.wilqo.api.mortgage.PartySummary.serializeBinaryToWriter
    );
  }
  f = message.getPayeeContactInfo();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.serializeBinaryToWriter
    );
  }
  f = message.getPrepaidTotalAmount();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPrepaidSource();
  if (f !== 0.0) {
    writer.writeEnum(
      27,
      f
    );
  }
  f = message.getPaidByLenderCreditAmount();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getProviderSelectedFromLenderListIndicator();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_financed_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByBorrowerFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByBorrowerFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByBorrowerFinancedAmount = function() {
  return this.setPaidByBorrowerFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByBorrowerFinancedAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_outside_of_closing_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByBorrowerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByBorrowerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByBorrowerOutsideOfClosingAmount = function() {
  return this.setPaidByBorrowerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByBorrowerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_at_closing_total_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByBorrowerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByBorrowerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByBorrowerAtClosingTotalAmount = function() {
  return this.setPaidByBorrowerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByBorrowerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByBorrowerTotalAmount = function() {
  return this.setPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_outside_of_closing_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidBySellerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidBySellerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidBySellerOutsideOfClosingAmount = function() {
  return this.setPaidBySellerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidBySellerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_credit_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidBySellerCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidBySellerCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidBySellerCreditAmount = function() {
  return this.setPaidBySellerCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidBySellerCreditAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_at_closing_total_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidBySellerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidBySellerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidBySellerAtClosingTotalAmount = function() {
  return this.setPaidBySellerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidBySellerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidBySellerTotalAmount = function() {
  return this.setPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_outside_of_closing_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByLenderOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByLenderOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByLenderOutsideOfClosingAmount = function() {
  return this.setPaidByLenderOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByLenderOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_at_closing_total_amount = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByLenderAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByLenderAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByLenderAtClosingTotalAmount = function() {
  return this.setPaidByLenderAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByLenderAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_amount = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByLenderTotalAmount = function() {
  return this.setPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional FeeCalculationTypeEnum prepaid_calculation_type = 16;
 * @return {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPrepaidCalculationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeCalculationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPrepaidCalculationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional PrepaidItemEnum prepaid_type = 18;
 * @return {!proto.wilqo.api.mortgage.PrepaidItemEnum}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPrepaidType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PrepaidItemEnum} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PrepaidItemEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPrepaidType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional IntegratedDisclosureSectionEnum integrated_disclosure_section_type = 19;
 * @return {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getIntegratedDisclosureSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setIntegratedDisclosureSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string integrated_disclosure_line_number_value = 20;
 * @return {string}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getIntegratedDisclosureLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setIntegratedDisclosureLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool regulation_z_points_and_fees_indicator = 21;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getRegulationZPointsAndFeesIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setRegulationZPointsAndFeesIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool borrower_chosen_provider_indicator = 22;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getBorrowerChosenProviderIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setBorrowerChosenProviderIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional google.protobuf.Timestamp payment_date = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaymentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaymentDate = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaymentDate = function() {
  return this.setPaymentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaymentDate = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional PartySummary payee_party_summary = 24;
 * @return {?proto.wilqo.api.mortgage.PartySummary}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPayeePartySummary = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartySummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartySummary, 24));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartySummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPayeePartySummary = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPayeePartySummary = function() {
  return this.setPayeePartySummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPayeePartySummary = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PartyFeePayeeContactInfoCardData payee_contact_info = 25;
 * @return {?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPayeeContactInfo = function() {
  return /** @type{?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData, 25));
};


/**
 * @param {?proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPayeeContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPayeeContactInfo = function() {
  return this.setPayeeContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPayeeContactInfo = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal prepaid_total_amount = 26;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPrepaidTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 26));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPrepaidTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPrepaidTotalAmount = function() {
  return this.setPrepaidTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPrepaidTotalAmount = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional FeeSourceEnum prepaid_source = 27;
 * @return {!proto.wilqo.api.mortgage.FeeSourceEnum}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPrepaidSource = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeSourceEnum} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPrepaidSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 27, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_credit_amount = 28;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getPaidByLenderCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 28));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
*/
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setPaidByLenderCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.clearPaidByLenderCreditAmount = function() {
  return this.setPaidByLenderCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.hasPaidByLenderCreditAmount = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional bool provider_selected_from_lender_list_indicator = 29;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.getProviderSelectedFromLenderListIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.IndividualPrepaidPayment} returns this
 */
proto.wilqo.api.mortgage.IndividualPrepaidPayment.prototype.setProviderSelectedFromLenderListIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CardListItem.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CardListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CardListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CardListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    infoCardType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    verifiedListList: jspb.Message.toObjectList(msg.getVerifiedListList(),
    proto.wilqo.api.mortgage.VerifiedListItem.toObject, includeInstance),
    value: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    isEditable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.CardListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CardListItem;
  return proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CardListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.models.InfoCardTypeEnum} */ (reader.readEnum());
      msg.setInfoCardType(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage.VerifiedListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.VerifiedListItem.deserializeBinaryFromReader);
      msg.addVerifiedList(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CardListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getInfoCardType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getVerifiedListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage.VerifiedListItem.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getIsEditable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional wilqo.shared.models.InfoCardTypeEnum info_card_type = 6;
 * @return {!proto.wilqo.shared.models.InfoCardTypeEnum}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getInfoCardType = function() {
  return /** @type {!proto.wilqo.shared.models.InfoCardTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.models.InfoCardTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setInfoCardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated VerifiedListItem verified_list = 7;
 * @return {!Array<!proto.wilqo.api.mortgage.VerifiedListItem>}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getVerifiedListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.VerifiedListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.VerifiedListItem, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.VerifiedListItem>} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
*/
proto.wilqo.api.mortgage.CardListItem.prototype.setVerifiedListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.VerifiedListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.addVerifiedList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage.VerifiedListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.clearVerifiedListList = function() {
  return this.setVerifiedListList([]);
};


/**
 * optional string value = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional DisplayType type = 9;
 * @return {!proto.wilqo.api.mortgage.DisplayType}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisplayType} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional bool is_editable = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CardListItem.prototype.getIsEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CardListItem} returns this
 */
proto.wilqo.api.mortgage.CardListItem.prototype.setIsEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.VerifiedListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.VerifiedListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.VerifiedListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    value: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isEditable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem}
 */
proto.wilqo.api.mortgage.VerifiedListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.VerifiedListItem;
  return proto.wilqo.api.mortgage.VerifiedListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.VerifiedListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem}
 */
proto.wilqo.api.mortgage.VerifiedListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.VerifiedListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.VerifiedListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.VerifiedListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsEditable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem} returns this
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem} returns this
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem} returns this
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional DisplayType type = 5;
 * @return {!proto.wilqo.api.mortgage.DisplayType}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisplayType} value
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem} returns this
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string value = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem} returns this
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_editable = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.getIsEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.VerifiedListItem} returns this
 */
proto.wilqo.api.mortgage.VerifiedListItem.prototype.setIsEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CalculationListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CalculationListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CalculationListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 5, 0),
    value: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CalculationListItem}
 */
proto.wilqo.api.mortgage.CalculationListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CalculationListItem;
  return proto.wilqo.api.mortgage.CalculationListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CalculationListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CalculationListItem}
 */
proto.wilqo.api.mortgage.CalculationListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CalculationListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CalculationListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CalculationListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CalculationListItem} returns this
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CalculationListItem} returns this
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.CalculationListItem} returns this
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional DisplayType type = 5;
 * @return {!proto.wilqo.api.mortgage.DisplayType}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisplayType} value
 * @return {!proto.wilqo.api.mortgage.CalculationListItem} returns this
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string value = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CalculationListItem} returns this
 */
proto.wilqo.api.mortgage.CalculationListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CurrentFee.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CurrentFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CurrentFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CurrentFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    paidByBorrowerFinancedAmount: (f = msg.getPaidByBorrowerFinancedAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerOutsideOfClosingAmount: (f = msg.getPaidByBorrowerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerAtClosingTotalAmount: (f = msg.getPaidByBorrowerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalAmount: (f = msg.getPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerOutsideOfClosingAmount: (f = msg.getPaidBySellerOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerCreditAmount: (f = msg.getPaidBySellerCreditAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerAtClosingTotalAmount: (f = msg.getPaidBySellerAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalAmount: (f = msg.getPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderOutsideOfClosingAmount: (f = msg.getPaidByLenderOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderAtClosingTotalAmount: (f = msg.getPaidByLenderAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalAmount: (f = msg.getPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherOutsideOfClosingAmount: (f = msg.getPaidByOtherOutsideOfClosingAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherAtClosingTotalAmount: (f = msg.getPaidByOtherAtClosingTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalAmount: (f = msg.getPaidByOtherTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    itemslistList: jspb.Message.toObjectList(msg.getItemslistList(),
    proto.wilqo.api.mortgage.LoanFeeCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CurrentFee}
 */
proto.wilqo.api.mortgage.CurrentFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CurrentFee;
  return proto.wilqo.api.mortgage.CurrentFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CurrentFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CurrentFee}
 */
proto.wilqo.api.mortgage.CurrentFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerFinancedAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerOutsideOfClosingAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerAtClosingTotalAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerOutsideOfClosingAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerCreditAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerAtClosingTotalAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderOutsideOfClosingAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderAtClosingTotalAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalAmount(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherOutsideOfClosingAmount(value);
      break;
    case 13:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherAtClosingTotalAmount(value);
      break;
    case 14:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalAmount(value);
      break;
    case 15:
      var value = new proto.wilqo.api.mortgage.LoanFeeCategory;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanFeeCategory.deserializeBinaryFromReader);
      msg.addItemslist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CurrentFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CurrentFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CurrentFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaidByBorrowerFinancedAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerCreditAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherOutsideOfClosingAmount();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherAtClosingTotalAmount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalAmount();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getItemslistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.wilqo.api.mortgage.LoanFeeCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_financed_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByBorrowerFinancedAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByBorrowerFinancedAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByBorrowerFinancedAmount = function() {
  return this.setPaidByBorrowerFinancedAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByBorrowerFinancedAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_outside_of_closing_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByBorrowerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByBorrowerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByBorrowerOutsideOfClosingAmount = function() {
  return this.setPaidByBorrowerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByBorrowerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_at_closing_total_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByBorrowerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByBorrowerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByBorrowerAtClosingTotalAmount = function() {
  return this.setPaidByBorrowerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByBorrowerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByBorrowerTotalAmount = function() {
  return this.setPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_outside_of_closing_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidBySellerOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidBySellerOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidBySellerOutsideOfClosingAmount = function() {
  return this.setPaidBySellerOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidBySellerOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_credit_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidBySellerCreditAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidBySellerCreditAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidBySellerCreditAmount = function() {
  return this.setPaidBySellerCreditAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidBySellerCreditAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_at_closing_total_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidBySellerAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidBySellerAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidBySellerAtClosingTotalAmount = function() {
  return this.setPaidBySellerAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidBySellerAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidBySellerTotalAmount = function() {
  return this.setPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_outside_of_closing_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByLenderOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByLenderOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByLenderOutsideOfClosingAmount = function() {
  return this.setPaidByLenderOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByLenderOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_at_closing_total_amount = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByLenderAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByLenderAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByLenderAtClosingTotalAmount = function() {
  return this.setPaidByLenderAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByLenderAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_amount = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByLenderTotalAmount = function() {
  return this.setPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_outside_of_closing_amount = 12;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByOtherOutsideOfClosingAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 12));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByOtherOutsideOfClosingAmount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByOtherOutsideOfClosingAmount = function() {
  return this.setPaidByOtherOutsideOfClosingAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByOtherOutsideOfClosingAmount = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_at_closing_total_amount = 13;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByOtherAtClosingTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 13));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByOtherAtClosingTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByOtherAtClosingTotalAmount = function() {
  return this.setPaidByOtherAtClosingTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByOtherAtClosingTotalAmount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_amount = 14;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getPaidByOtherTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 14));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setPaidByOtherTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearPaidByOtherTotalAmount = function() {
  return this.setPaidByOtherTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.hasPaidByOtherTotalAmount = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated LoanFeeCategory itemsList = 15;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.getItemslistList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanFeeCategory, 15));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanFeeCategory>} value
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
*/
proto.wilqo.api.mortgage.CurrentFee.prototype.setItemslistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanFeeCategory}
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.addItemslist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.wilqo.api.mortgage.LoanFeeCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CurrentFee} returns this
 */
proto.wilqo.api.mortgage.CurrentFee.prototype.clearItemslistList = function() {
  return this.setItemslistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.IndividualFeeTotalSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalFeeAmount: (f = msg.getTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalFeeAmount: (f = msg.getPaidByBorrowerTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalFeeAmount: (f = msg.getPaidBySellerTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalFeeAmount: (f = msg.getPaidByLenderTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalFeeAmount: (f = msg.getPaidByOtherTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    cardListItem: (f = msg.getCardListItem()) && proto.wilqo.api.mortgage.CardListItem.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.IndividualFeeTotalSummary;
  return proto.wilqo.api.mortgage.IndividualFeeTotalSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalFeeAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalFeeAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalFeeAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalFeeAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalFeeAmount(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.CardListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader);
      msg.setCardListItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.IndividualFeeTotalSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getCardListItem();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_fee_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.getTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.setTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.clearTotalFeeAmount = function() {
  return this.setTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.hasTotalFeeAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_fee_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.getPaidByBorrowerTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.setPaidByBorrowerTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.clearPaidByBorrowerTotalFeeAmount = function() {
  return this.setPaidByBorrowerTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.hasPaidByBorrowerTotalFeeAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_fee_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.getPaidBySellerTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.setPaidBySellerTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.clearPaidBySellerTotalFeeAmount = function() {
  return this.setPaidBySellerTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.hasPaidBySellerTotalFeeAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_fee_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.getPaidByLenderTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.setPaidByLenderTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.clearPaidByLenderTotalFeeAmount = function() {
  return this.setPaidByLenderTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.hasPaidByLenderTotalFeeAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_fee_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.getPaidByOtherTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.setPaidByOtherTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.clearPaidByOtherTotalFeeAmount = function() {
  return this.setPaidByOtherTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.hasPaidByOtherTotalFeeAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CardListItem card_list_item = 6;
 * @return {?proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.getCardListItem = function() {
  return /** @type{?proto.wilqo.api.mortgage.CardListItem} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CardListItem, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage.CardListItem|undefined} value
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.setCardListItem = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.IndividualFeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.clearCardListItem = function() {
  return this.setCardListItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.IndividualFeeTotalSummary.prototype.hasCardListItem = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.ExpanderDetail.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ExpanderDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ExpanderDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExpanderDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage.ExpanderDetailItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail}
 */
proto.wilqo.api.mortgage.ExpanderDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ExpanderDetail;
  return proto.wilqo.api.mortgage.ExpanderDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ExpanderDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail}
 */
proto.wilqo.api.mortgage.ExpanderDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.ExpanderDetailItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.ExpanderDetailItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ExpanderDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ExpanderDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExpanderDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage.ExpanderDetailItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail} returns this
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ExpanderDetailItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.ExpanderDetailItem>}
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ExpanderDetailItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ExpanderDetailItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ExpanderDetailItem>} value
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail} returns this
*/
proto.wilqo.api.mortgage.ExpanderDetail.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ExpanderDetailItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ExpanderDetailItem}
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.ExpanderDetailItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.ExpanderDetail} returns this
 */
proto.wilqo.api.mortgage.ExpanderDetail.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ExpanderDetailItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ExpanderDetailItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ExpanderDetailItem}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ExpanderDetailItem;
  return proto.wilqo.api.mortgage.ExpanderDetailItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ExpanderDetailItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ExpanderDetailItem}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ExpanderDetailItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ExpanderDetailItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ExpanderDetailItem} returns this
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ExpanderDetailItem} returns this
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DisplayType type = 3;
 * @return {!proto.wilqo.api.mortgage.DisplayType}
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage.DisplayType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.DisplayType} value
 * @return {!proto.wilqo.api.mortgage.ExpanderDetailItem} returns this
 */
proto.wilqo.api.mortgage.ExpanderDetailItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.PanelDetail.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PanelDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PanelDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PanelDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    detailFieldsList: jspb.Message.toObjectList(msg.getDetailFieldsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PanelDetail}
 */
proto.wilqo.api.mortgage.PanelDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PanelDetail;
  return proto.wilqo.api.mortgage.PanelDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PanelDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PanelDetail}
 */
proto.wilqo.api.mortgage.PanelDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addDetailFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PanelDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PanelDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PanelDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetailFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PanelDetail} returns this
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated wilqo.shared.models.PanelElement detail_fields = 2;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.getDetailFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.PanelDetail} returns this
*/
proto.wilqo.api.mortgage.PanelDetail.prototype.setDetailFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.addDetailFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.PanelDetail} returns this
 */
proto.wilqo.api.mortgage.PanelDetail.prototype.clearDetailFieldsList = function() {
  return this.setDetailFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeeSectionTotal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeeSectionTotal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeSectionTotal.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sumTotal: (f = msg.getSumTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeeSectionTotal;
  return proto.wilqo.api.mortgage.FeeSectionTotal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeeSectionTotal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSumTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeeSectionTotal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeeSectionTotal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeSectionTotal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSumTotal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal} returns this
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal sum_total = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.getSumTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal} returns this
*/
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.setSumTotal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal} returns this
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.clearSumTotal = function() {
  return this.setSumTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeSectionTotal.prototype.hasSumTotal = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FeeTotalSummary.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeeTotalSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeeTotalSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeTotalSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    paidByBorrowerTotalAmount: (f = msg.getPaidByBorrowerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalAmount: (f = msg.getPaidBySellerTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalAmount: (f = msg.getPaidByLenderTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalAmount: (f = msg.getPaidByOtherTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    aprTotalAmount: (f = msg.getAprTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeSectionTotalsList: jspb.Message.toObjectList(msg.getFeeSectionTotalsList(),
    proto.wilqo.api.mortgage.FeeSectionTotal.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeeTotalSummary;
  return proto.wilqo.api.mortgage.FeeTotalSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeeTotalSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAprTotalAmount(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.FeeSectionTotal;
      reader.readMessage(value,proto.wilqo.api.mortgage.FeeSectionTotal.deserializeBinaryFromReader);
      msg.addFeeSectionTotals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeeTotalSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeeTotalSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeeTotalSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaidByBorrowerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAprTotalAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeSectionTotalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage.FeeSectionTotal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.getPaidByBorrowerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.setPaidByBorrowerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.clearPaidByBorrowerTotalAmount = function() {
  return this.setPaidByBorrowerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.hasPaidByBorrowerTotalAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.getPaidBySellerTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.setPaidBySellerTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.clearPaidBySellerTotalAmount = function() {
  return this.setPaidBySellerTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.hasPaidBySellerTotalAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.getPaidByLenderTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.setPaidByLenderTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.clearPaidByLenderTotalAmount = function() {
  return this.setPaidByLenderTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.hasPaidByLenderTotalAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.getPaidByOtherTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.setPaidByOtherTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.clearPaidByOtherTotalAmount = function() {
  return this.setPaidByOtherTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.hasPaidByOtherTotalAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr_total_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.getAprTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.setAprTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.clearAprTotalAmount = function() {
  return this.setAprTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.hasAprTotalAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated FeeSectionTotal fee_section_totals = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.FeeSectionTotal>}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.getFeeSectionTotalsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeeSectionTotal>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FeeSectionTotal, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeeSectionTotal>} value
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.setFeeSectionTotalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeSectionTotal=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeeSectionTotal}
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.addFeeSectionTotals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.FeeSectionTotal, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FeeTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeeTotalSummary.prototype.clearFeeSectionTotalsList = function() {
  return this.setFeeSectionTotalsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DOTSection.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DOTSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DOTSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DOTSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage.DOTItem.toObject, includeInstance),
    displayType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isCollapsed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DOTSection}
 */
proto.wilqo.api.mortgage.DOTSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DOTSection;
  return proto.wilqo.api.mortgage.DOTSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DOTSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DOTSection}
 */
proto.wilqo.api.mortgage.DOTSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.DOTItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.DOTItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplayType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCollapsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DOTSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DOTSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DOTSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.DOTItem.serializeBinaryToWriter
    );
  }
  f = message.getDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getIsCollapsed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DOTSection} returns this
 */
proto.wilqo.api.mortgage.DOTSection.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DOTSection} returns this
 */
proto.wilqo.api.mortgage.DOTSection.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated DOTItem items = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.DOTItem>}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DOTItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DOTItem, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DOTItem>} value
 * @return {!proto.wilqo.api.mortgage.DOTSection} returns this
*/
proto.wilqo.api.mortgage.DOTSection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DOTItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DOTItem}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.DOTItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DOTSection} returns this
 */
proto.wilqo.api.mortgage.DOTSection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 display_type = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.getDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.DOTSection} returns this
 */
proto.wilqo.api.mortgage.DOTSection.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool is_collapsed = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DOTSection.prototype.getIsCollapsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.DOTSection} returns this
 */
proto.wilqo.api.mortgage.DOTSection.prototype.setIsCollapsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DOTItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DOTItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DOTItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DOTItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DOTItem}
 */
proto.wilqo.api.mortgage.DOTItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DOTItem;
  return proto.wilqo.api.mortgage.DOTItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DOTItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DOTItem}
 */
proto.wilqo.api.mortgage.DOTItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplayType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DOTItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DOTItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DOTItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DOTItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DOTItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DOTItem} returns this
 */
proto.wilqo.api.mortgage.DOTItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DOTItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DOTItem} returns this
 */
proto.wilqo.api.mortgage.DOTItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 display_type = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.DOTItem.prototype.getDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.DOTItem} returns this
 */
proto.wilqo.api.mortgage.DOTItem.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanAvailableFeeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adminFeeTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerPaid: (f = msg.getBorrowerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    sellerPaid: (f = msg.getSellerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lenderPaid: (f = msg.getLenderPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    otherPaid: (f = msg.getOtherPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isApr: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    total: (f = msg.getTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalPercent: (f = msg.getTotalPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    basedOn: jspb.Message.getFieldWithDefault(msg, 11, 0),
    paidBy: (f = msg.getPaidBy()) && proto.wilqo.api.mortgage.PaidBy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanAvailableFeeInfo;
  return proto.wilqo.api.mortgage.LoanAvailableFeeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminFeeTemplateId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBorrowerPaid(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSellerPaid(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLenderPaid(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOtherPaid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApr(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalPercent(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.FeePercentBasisEnum} */ (reader.readEnum());
      msg.setBasedOn(value);
      break;
    case 12:
      var value = new proto.wilqo.api.mortgage.PaidBy;
      reader.readMessage(value,proto.wilqo.api.mortgage.PaidBy.deserializeBinaryFromReader);
      msg.setPaidBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanAvailableFeeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdminFeeTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerPaid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSellerPaid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLenderPaid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOtherPaid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsApr();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalPercent();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getBasedOn();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPaidBy();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.wilqo.api.mortgage.PaidBy.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fee_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string admin_fee_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getAdminFeeTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setAdminFeeTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal borrower_paid = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getBorrowerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setBorrowerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearBorrowerPaid = function() {
  return this.setBorrowerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasBorrowerPaid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal seller_paid = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getSellerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setSellerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearSellerPaid = function() {
  return this.setSellerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasSellerPaid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal lender_paid = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getLenderPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setLenderPaid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearLenderPaid = function() {
  return this.setLenderPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasLenderPaid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal other_paid = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getOtherPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setOtherPaid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearOtherPaid = function() {
  return this.setOtherPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasOtherPaid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string paid_to = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getPaidTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setPaidTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_apr = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getIsApr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setIsApr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_percent = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getTotalPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setTotalPercent = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearTotalPercent = function() {
  return this.setTotalPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasTotalPercent = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional FeePercentBasisEnum based_on = 11;
 * @return {!proto.wilqo.api.mortgage.FeePercentBasisEnum}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getBasedOn = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeePercentBasisEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeePercentBasisEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setBasedOn = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional PaidBy paid_by = 12;
 * @return {?proto.wilqo.api.mortgage.PaidBy}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.getPaidBy = function() {
  return /** @type{?proto.wilqo.api.mortgage.PaidBy} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.PaidBy, 12));
};


/**
 * @param {?proto.wilqo.api.mortgage.PaidBy|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
*/
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.setPaidBy = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanAvailableFeeInfo} returns this
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.clearPaidBy = function() {
  return this.setPaidBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanAvailableFeeInfo.prototype.hasPaidBy = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealScope.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealScope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealScope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealScope.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealScope}
 */
proto.wilqo.api.mortgage.DealScope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealScope;
  return proto.wilqo.api.mortgage.DealScope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealScope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealScope}
 */
proto.wilqo.api.mortgage.DealScope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealScope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealScope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealScope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealScope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealScope.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealScope} returns this
 */
proto.wilqo.api.mortgage.DealScope.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealScope.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealScope} returns this
 */
proto.wilqo.api.mortgage.DealScope.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanLiabilitySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanLiabilitySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryTotalValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    liabilitiesList: jspb.Message.toObjectList(msg.getLiabilitiesList(),
    proto.wilqo.api.mortgage.LoanLiability.toObject, includeInstance),
    displayType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    iscollapsed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanLiabilitySummary;
  return proto.wilqo.api.mortgage.LoanLiabilitySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanLiabilitySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryTotalValue(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.LoanLiability;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanLiability.deserializeBinaryFromReader);
      msg.addLiabilities(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDisplayType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscollapsed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanLiabilitySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanLiabilitySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryTotalValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLiabilitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage.LoanLiability.serializeBinaryToWriter
    );
  }
  f = message.getDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIscollapsed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary} returns this
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_total_value = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.getCategoryTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary} returns this
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.setCategoryTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LoanLiability liabilities = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanLiability>}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.getLiabilitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanLiability>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanLiability, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanLiability>} value
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary} returns this
*/
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.setLiabilitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanLiability=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanLiability}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.addLiabilities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.LoanLiability, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary} returns this
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.clearLiabilitiesList = function() {
  return this.setLiabilitiesList([]);
};


/**
 * optional uint32 display_type = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.getDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary} returns this
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool isCollapsed = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.getIscollapsed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanLiabilitySummary} returns this
 */
proto.wilqo.api.mortgage.LoanLiabilitySummary.prototype.setIscollapsed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanLiability.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanLiability} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanLiability.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 4, ""),
    balanceDisplayType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    payment: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymentDisplayType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    payoffStatus: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanLiability}
 */
proto.wilqo.api.mortgage.LoanLiability.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanLiability;
  return proto.wilqo.api.mortgage.LoanLiability.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanLiability} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanLiability}
 */
proto.wilqo.api.mortgage.LoanLiability.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBalanceDisplayType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayment(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPaymentDisplayType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayoffStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanLiability.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanLiability} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanLiability.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBalanceDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPayment();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymentDisplayType();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getPayoffStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string creditor = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getCreditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setCreditor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_number = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string balance = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 balance_display_type = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getBalanceDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setBalanceDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string payment = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getPayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setPayment = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 payment_display_type = 7;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getPaymentDisplayType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setPaymentDisplayType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string payoff_status = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.getPayoffStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanLiability} returns this
 */
proto.wilqo.api.mortgage.LoanLiability.prototype.setPayoffStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeesTotalSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeesTotalSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesTotalSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalFeeAmount: (f = msg.getTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByBorrowerTotalFeeAmount: (f = msg.getPaidByBorrowerTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidBySellerTotalFeeAmount: (f = msg.getPaidBySellerTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByLenderTotalFeeAmount: (f = msg.getPaidByLenderTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    paidByOtherTotalFeeAmount: (f = msg.getPaidByOtherTotalFeeAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeesTotalSummary;
  return proto.wilqo.api.mortgage.FeesTotalSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeesTotalSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalFeeAmount(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByBorrowerTotalFeeAmount(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidBySellerTotalFeeAmount(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByLenderTotalFeeAmount(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPaidByOtherTotalFeeAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeesTotalSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeesTotalSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesTotalSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByBorrowerTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidBySellerTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByLenderTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getPaidByOtherTotalFeeAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fee_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_fee_amount = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.getTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.setTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.clearTotalFeeAmount = function() {
  return this.setTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.hasTotalFeeAmount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_borrower_total_fee_amount = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.getPaidByBorrowerTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.setPaidByBorrowerTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.clearPaidByBorrowerTotalFeeAmount = function() {
  return this.setPaidByBorrowerTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.hasPaidByBorrowerTotalFeeAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_seller_total_fee_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.getPaidBySellerTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.setPaidBySellerTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.clearPaidBySellerTotalFeeAmount = function() {
  return this.setPaidBySellerTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.hasPaidBySellerTotalFeeAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_lender_total_fee_amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.getPaidByLenderTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.setPaidByLenderTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.clearPaidByLenderTotalFeeAmount = function() {
  return this.setPaidByLenderTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.hasPaidByLenderTotalFeeAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal paid_by_other_total_fee_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.getPaidByOtherTotalFeeAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
*/
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.setPaidByOtherTotalFeeAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesTotalSummary} returns this
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.clearPaidByOtherTotalFeeAmount = function() {
  return this.setPaidByOtherTotalFeeAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesTotalSummary.prototype.hasPaidByOtherTotalFeeAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanSummaryGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanSummaryGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanSummariesList: jspb.Message.toObjectList(msg.getLoanSummariesList(),
    proto.wilqo.api.mortgage.LoanSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanSummaryGroup}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanSummaryGroup;
  return proto.wilqo.api.mortgage.LoanSummaryGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanSummaryGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanSummaryGroup}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.LoanSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanSummary.deserializeBinaryFromReader);
      msg.addLoanSummaries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanSummaryGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanSummaryGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.LoanSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummaryGroup} returns this
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanSummaryGroup} returns this
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LoanSummary loan_summaries = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanSummary>}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.getLoanSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanSummary>} value
 * @return {!proto.wilqo.api.mortgage.LoanSummaryGroup} returns this
*/
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.setLoanSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanSummary}
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.addLoanSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanSummaryGroup} returns this
 */
proto.wilqo.api.mortgage.LoanSummaryGroup.prototype.clearLoanSummariesList = function() {
  return this.setLoanSummariesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PartySharedInfoCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PartySharedInfoCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PartySharedInfoCard;
  return proto.wilqo.api.mortgage.PartySharedInfoCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PartySharedInfoCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PartySharedInfoCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PartySharedInfoCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard} returns this
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard} returns this
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.PanelElement panel_elements = 3;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard} returns this
*/
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.PartySharedInfoCard} returns this
 */
proto.wilqo.api.mortgage.PartySharedInfoCard.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address: jspb.Message.getFieldWithDefault(msg, 5, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData;
  return proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} returns this
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} returns this
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} returns this
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} returns this
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} returns this
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address_additional_line_text = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData} returns this
 */
proto.wilqo.api.mortgage.PartyFeePayeeContactInfoCardData.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.AssetOwner.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.AssetOwner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.AssetOwner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AssetOwner.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.AssetOwner}
 */
proto.wilqo.api.mortgage.AssetOwner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.AssetOwner;
  return proto.wilqo.api.mortgage.AssetOwner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.AssetOwner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.AssetOwner}
 */
proto.wilqo.api.mortgage.AssetOwner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.AssetOwner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.AssetOwner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.AssetOwner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.AssetOwner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssetOwner.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssetOwner} returns this
 */
proto.wilqo.api.mortgage.AssetOwner.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.AssetOwner.prototype.getAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.AssetOwner} returns this
 */
proto.wilqo.api.mortgage.AssetOwner.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LiabilityOwner.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LiabilityOwner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LiabilityOwner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LiabilityOwner.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liabilityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LiabilityOwner}
 */
proto.wilqo.api.mortgage.LiabilityOwner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LiabilityOwner;
  return proto.wilqo.api.mortgage.LiabilityOwner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LiabilityOwner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LiabilityOwner}
 */
proto.wilqo.api.mortgage.LiabilityOwner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiabilityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LiabilityOwner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LiabilityOwner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LiabilityOwner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LiabilityOwner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiabilityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LiabilityOwner.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LiabilityOwner} returns this
 */
proto.wilqo.api.mortgage.LiabilityOwner.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string liability_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.LiabilityOwner.prototype.getLiabilityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LiabilityOwner} returns this
 */
proto.wilqo.api.mortgage.LiabilityOwner.prototype.setLiabilityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ResidenceOwner.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ResidenceOwner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ResidenceOwner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResidenceOwner.toObject = function(includeInstance, msg) {
  var f, obj = {
    partyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    residenceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ResidenceOwner}
 */
proto.wilqo.api.mortgage.ResidenceOwner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ResidenceOwner;
  return proto.wilqo.api.mortgage.ResidenceOwner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ResidenceOwner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ResidenceOwner}
 */
proto.wilqo.api.mortgage.ResidenceOwner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResidenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ResidenceOwner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ResidenceOwner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ResidenceOwner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ResidenceOwner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResidenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string party_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResidenceOwner.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResidenceOwner} returns this
 */
proto.wilqo.api.mortgage.ResidenceOwner.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string residence_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ResidenceOwner.prototype.getResidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ResidenceOwner} returns this
 */
proto.wilqo.api.mortgage.ResidenceOwner.prototype.setResidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.RealEstateOwned.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.RealEstateOwned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.RealEstateOwned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RealEstateOwned.toObject = function(includeInstance, msg) {
  var f, obj = {
    realEstateOwnedId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.RealEstateOwned}
 */
proto.wilqo.api.mortgage.RealEstateOwned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.RealEstateOwned;
  return proto.wilqo.api.mortgage.RealEstateOwned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.RealEstateOwned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.RealEstateOwned}
 */
proto.wilqo.api.mortgage.RealEstateOwned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealEstateOwnedId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.RealEstateOwned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.RealEstateOwned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.RealEstateOwned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.RealEstateOwned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRealEstateOwnedId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string real_estate_owned_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.RealEstateOwned.prototype.getRealEstateOwnedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.RealEstateOwned.prototype.setRealEstateOwnedId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanEscrowFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanEscrowFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerPaid: (f = msg.getBorrowerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    sellerPaid: (f = msg.getSellerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lenderPaid: (f = msg.getLenderPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    timeframe: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paidTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isApr: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    total: (f = msg.getTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    specialCase: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sourceMessage: jspb.Message.getFieldWithDefault(msg, 12, ""),
    escrowPayment: (f = msg.getEscrowPayment()) && proto.wilqo.api.mortgage.IndividualEscrowPayment.toObject(includeInstance, f),
    calculatedTimeframe: jspb.Message.getFieldWithDefault(msg, 14, ""),
    manualOverrideTimeframe: jspb.Message.getFieldWithDefault(msg, 15, 0),
    calculatedTotalAmount: (f = msg.getCalculatedTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    manualOverrideTotalAmount: (f = msg.getManualOverrideTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    manualOverrideIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanEscrowFee;
  return proto.wilqo.api.mortgage.LoanEscrowFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBorrowerPaid(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSellerPaid(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLenderPaid(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeframe(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApr(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} */ (reader.readEnum());
      msg.setSpecialCase(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceMessage(value);
      break;
    case 13:
      var value = new proto.wilqo.api.mortgage.IndividualEscrowPayment;
      reader.readMessage(value,proto.wilqo.api.mortgage.IndividualEscrowPayment.deserializeBinaryFromReader);
      msg.setEscrowPayment(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalculatedTimeframe(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualOverrideTimeframe(value);
      break;
    case 16:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCalculatedTotalAmount(value);
      break;
    case 17:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setManualOverrideTotalAmount(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualOverrideIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanEscrowFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanEscrowFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerPaid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSellerPaid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLenderPaid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTimeframe();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPaidTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsApr();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSpecialCase();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSourceMessage();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEscrowPayment();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.wilqo.api.mortgage.IndividualEscrowPayment.serializeBinaryToWriter
    );
  }
  f = message.getCalculatedTimeframe();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getManualOverrideTimeframe();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCalculatedTotalAmount();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getManualOverrideTotalAmount();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getManualOverrideIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal borrower_paid = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getBorrowerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setBorrowerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearBorrowerPaid = function() {
  return this.setBorrowerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasBorrowerPaid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal seller_paid = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getSellerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setSellerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearSellerPaid = function() {
  return this.setSellerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasSellerPaid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal lender_paid = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getLenderPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setLenderPaid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearLenderPaid = function() {
  return this.setLenderPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasLenderPaid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 timeframe = 6;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getTimeframe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setTimeframe = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string paid_to = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getPaidTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setPaidTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_apr = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getIsApr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setIsApr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string fee_id = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional LoanFeeSpecialCaseEnum special_case = 11;
 * @return {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getSpecialCase = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setSpecialCase = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string source_message = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getSourceMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setSourceMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional IndividualEscrowPayment escrow_payment = 13;
 * @return {?proto.wilqo.api.mortgage.IndividualEscrowPayment}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getEscrowPayment = function() {
  return /** @type{?proto.wilqo.api.mortgage.IndividualEscrowPayment} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.IndividualEscrowPayment, 13));
};


/**
 * @param {?proto.wilqo.api.mortgage.IndividualEscrowPayment|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setEscrowPayment = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearEscrowPayment = function() {
  return this.setEscrowPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasEscrowPayment = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string calculated_timeframe = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getCalculatedTimeframe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setCalculatedTimeframe = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 manual_override_timeframe = 15;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getManualOverrideTimeframe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setManualOverrideTimeframe = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal calculated_total_amount = 16;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getCalculatedTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 16));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setCalculatedTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearCalculatedTotalAmount = function() {
  return this.setCalculatedTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasCalculatedTotalAmount = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal manual_override_total_amount = 17;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getManualOverrideTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 17));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setManualOverrideTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.clearManualOverrideTotalAmount = function() {
  return this.setManualOverrideTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.hasManualOverrideTotalAmount = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool manual_override_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.getManualOverrideIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFee.prototype.setManualOverrideIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanEscrowFeeCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sumTotal: (f = msg.getSumTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.wilqo.api.mortgage.LoanEscrowFee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanEscrowFeeCategory;
  return proto.wilqo.api.mortgage.LoanEscrowFeeCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSumTotal(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.LoanEscrowFee;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanEscrowFee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanEscrowFeeCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSumTotal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.LoanEscrowFee.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal sum_total = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.getSumTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.setSumTotal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.clearSumTotal = function() {
  return this.setSumTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.hasSumTotal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LoanEscrowFee fees = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanEscrowFee>}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanEscrowFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanEscrowFee, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanEscrowFee>} value
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} returns this
*/
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanEscrowFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFee}
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanEscrowFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanEscrowFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanEscrowFeeCategory.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanPrepaidFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerPaid: (f = msg.getBorrowerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    sellerPaid: (f = msg.getSellerPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lenderPaid: (f = msg.getLenderPaid()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    timeframe: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paidTo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isApr: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    total: (f = msg.getTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feeId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    specialCase: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sourceMessage: jspb.Message.getFieldWithDefault(msg, 12, ""),
    prepaidPayment: (f = msg.getPrepaidPayment()) && proto.wilqo.api.mortgage.IndividualPrepaidPayment.toObject(includeInstance, f),
    calculatedTimeframe: jspb.Message.getFieldWithDefault(msg, 14, ""),
    manualOverrideTimeframe: jspb.Message.getFieldWithDefault(msg, 15, 0),
    calculatedTotalAmount: (f = msg.getCalculatedTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    manualOverrideTotalAmount: (f = msg.getManualOverrideTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    manualOverrideIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanPrepaidFee;
  return proto.wilqo.api.mortgage.LoanPrepaidFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBorrowerPaid(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSellerPaid(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLenderPaid(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeframe(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidTo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsApr(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 11:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} */ (reader.readEnum());
      msg.setSpecialCase(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceMessage(value);
      break;
    case 13:
      var value = new proto.wilqo.api.mortgage.IndividualPrepaidPayment;
      reader.readMessage(value,proto.wilqo.api.mortgage.IndividualPrepaidPayment.deserializeBinaryFromReader);
      msg.setPrepaidPayment(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalculatedTimeframe(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setManualOverrideTimeframe(value);
      break;
    case 16:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCalculatedTotalAmount(value);
      break;
    case 17:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setManualOverrideTotalAmount(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualOverrideIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanPrepaidFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerPaid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSellerPaid();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLenderPaid();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTimeframe();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPaidTo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsApr();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSpecialCase();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSourceMessage();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPrepaidPayment();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.wilqo.api.mortgage.IndividualPrepaidPayment.serializeBinaryToWriter
    );
  }
  f = message.getCalculatedTimeframe();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getManualOverrideTimeframe();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCalculatedTotalAmount();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getManualOverrideTotalAmount();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getManualOverrideIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal borrower_paid = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getBorrowerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setBorrowerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearBorrowerPaid = function() {
  return this.setBorrowerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasBorrowerPaid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal seller_paid = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getSellerPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setSellerPaid = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearSellerPaid = function() {
  return this.setSellerPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasSellerPaid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal lender_paid = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getLenderPaid = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setLenderPaid = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearLenderPaid = function() {
  return this.setLenderPaid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasLenderPaid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal amount = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 timeframe = 6;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getTimeframe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setTimeframe = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string paid_to = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getPaidTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setPaidTo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_apr = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getIsApr = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setIsApr = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string fee_id = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional LoanFeeSpecialCaseEnum special_case = 11;
 * @return {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getSpecialCase = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setSpecialCase = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional string source_message = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getSourceMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setSourceMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional IndividualPrepaidPayment prepaid_payment = 13;
 * @return {?proto.wilqo.api.mortgage.IndividualPrepaidPayment}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getPrepaidPayment = function() {
  return /** @type{?proto.wilqo.api.mortgage.IndividualPrepaidPayment} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.IndividualPrepaidPayment, 13));
};


/**
 * @param {?proto.wilqo.api.mortgage.IndividualPrepaidPayment|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setPrepaidPayment = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearPrepaidPayment = function() {
  return this.setPrepaidPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasPrepaidPayment = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string calculated_timeframe = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getCalculatedTimeframe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setCalculatedTimeframe = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 manual_override_timeframe = 15;
 * @return {number}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getManualOverrideTimeframe = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setManualOverrideTimeframe = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal calculated_total_amount = 16;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getCalculatedTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 16));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setCalculatedTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearCalculatedTotalAmount = function() {
  return this.setCalculatedTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasCalculatedTotalAmount = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal manual_override_total_amount = 17;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getManualOverrideTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 17));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setManualOverrideTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.clearManualOverrideTotalAmount = function() {
  return this.setManualOverrideTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.hasManualOverrideTotalAmount = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool manual_override_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.getManualOverrideIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFee.prototype.setManualOverrideIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    category: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sumTotal: (f = msg.getSumTotal()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.wilqo.api.mortgage.LoanPrepaidFee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LoanPrepaidFeeCategory;
  return proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSumTotal(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.LoanPrepaidFee;
      reader.readMessage(value,proto.wilqo.api.mortgage.LoanPrepaidFee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSumTotal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.LoanPrepaidFee.serializeBinaryToWriter
    );
  }
};


/**
 * optional string category = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal sum_total = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.getSumTotal = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.setSumTotal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.clearSumTotal = function() {
  return this.setSumTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.hasSumTotal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated LoanPrepaidFee fees = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.LoanPrepaidFee>}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LoanPrepaidFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LoanPrepaidFee, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LoanPrepaidFee>} value
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} returns this
*/
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPrepaidFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFee}
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.LoanPrepaidFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LoanPrepaidFeeCategory} returns this
 */
proto.wilqo.api.mortgage.LoanPrepaidFeeCategory.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DOTSideSheetSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DOTSideSheetSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    sectionsList: jspb.Message.toObjectList(msg.getSectionsList(),
    proto.wilqo.api.mortgage.DOTSection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DOTSideSheetSummary}
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DOTSideSheetSummary;
  return proto.wilqo.api.mortgage.DOTSideSheetSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DOTSideSheetSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DOTSideSheetSummary}
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.DOTSection;
      reader.readMessage(value,proto.wilqo.api.mortgage.DOTSection.deserializeBinaryFromReader);
      msg.addSections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DOTSideSheetSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DOTSideSheetSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.DOTSection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DOTSection sections = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.DOTSection>}
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.prototype.getSectionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DOTSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DOTSection, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DOTSection>} value
 * @return {!proto.wilqo.api.mortgage.DOTSideSheetSummary} returns this
*/
proto.wilqo.api.mortgage.DOTSideSheetSummary.prototype.setSectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DOTSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DOTSection}
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.prototype.addSections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.DOTSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DOTSideSheetSummary} returns this
 */
proto.wilqo.api.mortgage.DOTSideSheetSummary.prototype.clearSectionsList = function() {
  return this.setSectionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.LenderCondition.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LenderCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LenderCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LenderCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appliedEventCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    conditionDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, 0),
    activityConfigsList: jspb.Message.toObjectList(msg.getActivityConfigsList(),
    proto.wilqo.api.mortgage.ActivityConfig.toObject, includeInstance),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    code: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastPublishedDate: (f = msg.getLastPublishedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    conditionSatisfactionResponsiblePartyType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    conditionSatisfactionTimeframeType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    internalName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    lastPublishedBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    lastPublishedByDisplayName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LenderCondition}
 */
proto.wilqo.api.mortgage.LenderCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LenderCondition;
  return proto.wilqo.api.mortgage.LenderCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LenderCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LenderCondition}
 */
proto.wilqo.api.mortgage.LenderCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppliedEventCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.ActivityConfig;
      reader.readMessage(value,proto.wilqo.api.mortgage.ActivityConfig.deserializeBinaryFromReader);
      msg.addActivityConfigs(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPublishedDate(value);
      break;
    case 10:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage.ConditionSatisfactionResponsiblePartyEnum} */ (reader.readEnum());
      msg.setConditionSatisfactionResponsiblePartyType(value);
      break;
    case 13:
      var value = /** @type {!proto.wilqo.api.mortgage.ConditionSatisfactionTimeframeEnum} */ (reader.readEnum());
      msg.setConditionSatisfactionTimeframeType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPublishedBy(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPublishedByDisplayName(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LenderCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LenderCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LenderCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppliedEventCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getConditionDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActivityConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage.ActivityConfig.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastPublishedDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getConditionSatisfactionResponsiblePartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getConditionSatisfactionTimeframeType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLastPublishedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getLastPublishedByDisplayName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 applied_event_count = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getAppliedEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setAppliedEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string condition_description = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getConditionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setConditionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ConditionTemplateCategoryEnum category = 4;
 * @return {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated ActivityConfig activity_configs = 5;
 * @return {!Array<!proto.wilqo.api.mortgage.ActivityConfig>}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getActivityConfigsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ActivityConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ActivityConfig, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ActivityConfig>} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
*/
proto.wilqo.api.mortgage.LenderCondition.prototype.setActivityConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ActivityConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ActivityConfig}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.addActivityConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage.ActivityConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.clearActivityConfigsList = function() {
  return this.setActivityConfigsList([]);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string code = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp last_published_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getLastPublishedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
*/
proto.wilqo.api.mortgage.LenderCondition.prototype.setLastPublishedDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.clearLastPublishedDate = function() {
  return this.setLastPublishedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.hasLastPublishedDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 10;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 10));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
*/
proto.wilqo.api.mortgage.LenderCondition.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string business_process_template_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional ConditionSatisfactionResponsiblePartyEnum condition_satisfaction_responsible_party_type = 12;
 * @return {!proto.wilqo.api.mortgage.ConditionSatisfactionResponsiblePartyEnum}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getConditionSatisfactionResponsiblePartyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ConditionSatisfactionResponsiblePartyEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ConditionSatisfactionResponsiblePartyEnum} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setConditionSatisfactionResponsiblePartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional ConditionSatisfactionTimeframeEnum condition_satisfaction_timeframe_type = 13;
 * @return {!proto.wilqo.api.mortgage.ConditionSatisfactionTimeframeEnum}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getConditionSatisfactionTimeframeType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ConditionSatisfactionTimeframeEnum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ConditionSatisfactionTimeframeEnum} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setConditionSatisfactionTimeframeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional string internal_name = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string last_published_by = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getLastPublishedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setLastPublishedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string last_published_by_display_name = 16;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getLastPublishedByDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setLastPublishedByDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool archived = 17;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.LenderCondition} returns this
 */
proto.wilqo.api.mortgage.LenderCondition.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LenderConditionMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LenderConditionMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LenderConditionMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appliedEventCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lenderConditionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ausConditionSource: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ausConditionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ausConditionDescription: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LenderConditionMapping;
  return proto.wilqo.api.mortgage.LenderConditionMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LenderConditionMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppliedEventCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderConditionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusConditionSource(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusConditionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAusConditionDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LenderConditionMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LenderConditionMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LenderConditionMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppliedEventCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLenderConditionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAusConditionSource();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAusConditionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAusConditionDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 applied_event_count = 2;
 * @return {number}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.getAppliedEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.setAppliedEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string lender_condition_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.getLenderConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.setLenderConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string aus_condition_source = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.getAusConditionSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.setAusConditionSource = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string aus_condition_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.getAusConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.setAusConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string aus_condition_description = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.getAusConditionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderConditionMapping} returns this
 */
proto.wilqo.api.mortgage.LenderConditionMapping.prototype.setAusConditionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ActivityConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ActivityConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ActivityConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityTemplateVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activityInstanceTag: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ActivityConfig}
 */
proto.wilqo.api.mortgage.ActivityConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ActivityConfig;
  return proto.wilqo.api.mortgage.ActivityConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ActivityConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ActivityConfig}
 */
proto.wilqo.api.mortgage.ActivityConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityTemplateVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityInstanceTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ActivityConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ActivityConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ActivityConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getActivityInstanceTag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 activity_template_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.getActivityTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.setActivityTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string activity_instance_tag = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.getActivityInstanceTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ActivityConfig} returns this
 */
proto.wilqo.api.mortgage.ActivityConfig.prototype.setActivityInstanceTag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CreditReportSummary.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CreditReportSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CreditReportSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditReportSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    creditReferenceNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creditReportOn: jspb.Message.getFieldWithDefault(msg, 2, 0),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pullType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    creditReportDate: (f = msg.getCreditReportDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    creditBureausList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    loanLevelCreditScore: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary}
 */
proto.wilqo.api.mortgage.CreditReportSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CreditReportSummary;
  return proto.wilqo.api.mortgage.CreditReportSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CreditReportSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary}
 */
proto.wilqo.api.mortgage.CreditReportSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditReferenceNumber(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.CreditReportOnEnum} */ (reader.readEnum());
      msg.setCreditReportOn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.CreditPullTypeEnum} */ (reader.readEnum());
      msg.setPullType(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreditReportDate(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.CreditBureausEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCreditBureaus(values[i]);
      }
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanLevelCreditScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CreditReportSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CreditReportSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CreditReportSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreditReferenceNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreditReportOn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPullType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreditReportDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreditBureausList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getLoanLevelCreditScore();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string credit_reference_number = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getCreditReferenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setCreditReferenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CreditReportOnEnum credit_report_on = 2;
 * @return {!proto.wilqo.api.mortgage.CreditReportOnEnum}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getCreditReportOn = function() {
  return /** @type {!proto.wilqo.api.mortgage.CreditReportOnEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.CreditReportOnEnum} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setCreditReportOn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string borrower_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CreditPullTypeEnum pull_type = 4;
 * @return {!proto.wilqo.api.mortgage.CreditPullTypeEnum}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getPullType = function() {
  return /** @type {!proto.wilqo.api.mortgage.CreditPullTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.CreditPullTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setPullType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp credit_report_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getCreditReportDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
*/
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setCreditReportDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.clearCreditReportDate = function() {
  return this.setCreditReportDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.hasCreditReportDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated CreditBureausEnum credit_bureaus = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.CreditBureausEnum>}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getCreditBureausList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.CreditBureausEnum>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CreditBureausEnum>} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setCreditBureausList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.CreditBureausEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.addCreditBureaus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.clearCreditBureausList = function() {
  return this.setCreditBureausList([]);
};


/**
 * optional string loan_level_credit_score = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.getLoanLevelCreditScore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CreditReportSummary} returns this
 */
proto.wilqo.api.mortgage.CreditReportSummary.prototype.setLoanLevelCreditScore = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DocumentFormSection.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentFormSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentFormSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentFormSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentFormSection}
 */
proto.wilqo.api.mortgage.DocumentFormSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentFormSection;
  return proto.wilqo.api.mortgage.DocumentFormSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentFormSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentFormSection}
 */
proto.wilqo.api.mortgage.DocumentFormSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentFormSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentFormSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentFormSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentFormSection} returns this
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated wilqo.shared.models.PanelElement panel_elements = 2;
 * @return {!Array<!proto.wilqo.shared.models.PanelElement>}
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElement>} value
 * @return {!proto.wilqo.api.mortgage.DocumentFormSection} returns this
*/
proto.wilqo.api.mortgage.DocumentFormSection.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.PanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentFormSection} returns this
 */
proto.wilqo.api.mortgage.DocumentFormSection.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext}
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProgressItemSubscriptionContext;
  return proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext}
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConsumer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsConsumer();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext} returns this
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_consumer = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.prototype.getIsConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.ProgressItemSubscriptionContext} returns this
 */
proto.wilqo.api.mortgage.ProgressItemSubscriptionContext.prototype.setIsConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdDate: (f = msg.getCreatedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    subjectLoanNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    primaryBorrowerFullname: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealSummary}
 */
proto.wilqo.api.mortgage.DealSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealSummary;
  return proto.wilqo.api.mortgage.DealSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealSummary}
 */
proto.wilqo.api.mortgage.DealSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectLoanNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryBorrowerFullname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSubjectLoanNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrimaryBorrowerFullname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealSummary} returns this
 */
proto.wilqo.api.mortgage.DealSummary.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealSummary} returns this
 */
proto.wilqo.api.mortgage.DealSummary.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.getCreatedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealSummary} returns this
*/
proto.wilqo.api.mortgage.DealSummary.prototype.setCreatedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealSummary} returns this
 */
proto.wilqo.api.mortgage.DealSummary.prototype.clearCreatedDate = function() {
  return this.setCreatedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.hasCreatedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string subject_loan_number = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.getSubjectLoanNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealSummary} returns this
 */
proto.wilqo.api.mortgage.DealSummary.prototype.setSubjectLoanNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string primary_borrower_fullname = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealSummary.prototype.getPrimaryBorrowerFullname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealSummary} returns this
 */
proto.wilqo.api.mortgage.DealSummary.prototype.setPrimaryBorrowerFullname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.LenderOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.LenderOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.LenderOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LenderOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.LenderOption}
 */
proto.wilqo.api.mortgage.LenderOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.LenderOption;
  return proto.wilqo.api.mortgage.LenderOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.LenderOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.LenderOption}
 */
proto.wilqo.api.mortgage.LenderOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.LenderOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.LenderOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.LenderOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.LenderOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderOption} returns this
 */
proto.wilqo.api.mortgage.LenderOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.LenderOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.LenderOption} returns this
 */
proto.wilqo.api.mortgage.LenderOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.GetLendersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.GetLendersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.GetLendersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.GetLendersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLendersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lendersResponseList: jspb.Message.toObjectList(msg.getLendersResponseList(),
    proto.wilqo.api.mortgage.LenderOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.GetLendersResponse}
 */
proto.wilqo.api.mortgage.GetLendersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.GetLendersResponse;
  return proto.wilqo.api.mortgage.GetLendersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.GetLendersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.GetLendersResponse}
 */
proto.wilqo.api.mortgage.GetLendersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.LenderOption;
      reader.readMessage(value,proto.wilqo.api.mortgage.LenderOption.deserializeBinaryFromReader);
      msg.addLendersResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.GetLendersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.GetLendersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.GetLendersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.GetLendersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLendersResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.LenderOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LenderOption lenders_response = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.LenderOption>}
 */
proto.wilqo.api.mortgage.GetLendersResponse.prototype.getLendersResponseList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.LenderOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.LenderOption, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.LenderOption>} value
 * @return {!proto.wilqo.api.mortgage.GetLendersResponse} returns this
*/
proto.wilqo.api.mortgage.GetLendersResponse.prototype.setLendersResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.LenderOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.LenderOption}
 */
proto.wilqo.api.mortgage.GetLendersResponse.prototype.addLendersResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.LenderOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.GetLendersResponse} returns this
 */
proto.wilqo.api.mortgage.GetLendersResponse.prototype.clearLendersResponseList = function() {
  return this.setLendersResponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.PreQualSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.PreQualSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PreQualSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalMonthlyCurrentHousingExpenseAmount: (f = msg.getTotalMonthlyCurrentHousingExpenseAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ltvRatioPercent: (f = msg.getLtvRatioPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    combinedLtvRatioPercent: (f = msg.getCombinedLtvRatioPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    homeEquityCombinedLtvRatioPercent: (f = msg.getHomeEquityCombinedLtvRatioPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanLevelCreditScoreValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    totalDueFromBorrowerAmount: (f = msg.getTotalDueFromBorrowerAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    housingExpenseRatioPercent: (f = msg.getHousingExpenseRatioPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalDebtExpenseRatioPercent: (f = msg.getTotalDebtExpenseRatioPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary}
 */
proto.wilqo.api.mortgage.PreQualSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.PreQualSummary;
  return proto.wilqo.api.mortgage.PreQualSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.PreQualSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary}
 */
proto.wilqo.api.mortgage.PreQualSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalMonthlyCurrentHousingExpenseAmount(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLtvRatioPercent(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCombinedLtvRatioPercent(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHomeEquityCombinedLtvRatioPercent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanLevelCreditScoreValue(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalDueFromBorrowerAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHousingExpenseRatioPercent(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalDebtExpenseRatioPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.PreQualSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.PreQualSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.PreQualSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalMonthlyCurrentHousingExpenseAmount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLtvRatioPercent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getCombinedLtvRatioPercent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHomeEquityCombinedLtvRatioPercent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanLevelCreditScoreValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTotalDueFromBorrowerAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHousingExpenseRatioPercent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalDebtExpenseRatioPercent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_monthly_current_housing_expense_amount = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getTotalMonthlyCurrentHousingExpenseAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setTotalMonthlyCurrentHousingExpenseAmount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearTotalMonthlyCurrentHousingExpenseAmount = function() {
  return this.setTotalMonthlyCurrentHousingExpenseAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasTotalMonthlyCurrentHousingExpenseAmount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal ltv_ratio_percent = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getLtvRatioPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setLtvRatioPercent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearLtvRatioPercent = function() {
  return this.setLtvRatioPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasLtvRatioPercent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal combined_ltv_ratio_percent = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getCombinedLtvRatioPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setCombinedLtvRatioPercent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearCombinedLtvRatioPercent = function() {
  return this.setCombinedLtvRatioPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasCombinedLtvRatioPercent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal home_equity_combined_ltv_ratio_percent = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getHomeEquityCombinedLtvRatioPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setHomeEquityCombinedLtvRatioPercent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearHomeEquityCombinedLtvRatioPercent = function() {
  return this.setHomeEquityCombinedLtvRatioPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasHomeEquityCombinedLtvRatioPercent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string loan_level_credit_score_value = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getLoanLevelCreditScoreValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.setLoanLevelCreditScoreValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_due_from_borrower_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getTotalDueFromBorrowerAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setTotalDueFromBorrowerAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearTotalDueFromBorrowerAmount = function() {
  return this.setTotalDueFromBorrowerAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasTotalDueFromBorrowerAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal housing_expense_ratio_percent = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getHousingExpenseRatioPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setHousingExpenseRatioPercent = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearHousingExpenseRatioPercent = function() {
  return this.setHousingExpenseRatioPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasHousingExpenseRatioPercent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_debt_expense_ratio_percent = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.getTotalDebtExpenseRatioPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
*/
proto.wilqo.api.mortgage.PreQualSummary.prototype.setTotalDebtExpenseRatioPercent = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.PreQualSummary} returns this
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.clearTotalDebtExpenseRatioPercent = function() {
  return this.setTotalDebtExpenseRatioPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.PreQualSummary.prototype.hasTotalDebtExpenseRatioPercent = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    address: (f = msg.getAddress()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.toObject(includeInstance, f),
    currentResidence: (f = msg.getCurrentResidence()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.toObject(includeInstance, f),
    priorResidence: (f = msg.getPriorResidence()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.toObject(includeInstance, f),
    realEstateOwned: (f = msg.getRealEstateOwned()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.toObject(includeInstance, f),
    mailingAddress: (f = msg.getMailingAddress()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.toObject(includeInstance, f),
    subjectPropertyIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    addressToBeDeterminedIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.deserializeBinaryFromReader);
      msg.setCurrentResidence(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.deserializeBinaryFromReader);
      msg.setPriorResidence(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.deserializeBinaryFromReader);
      msg.setRealEstateOwned(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.deserializeBinaryFromReader);
      msg.setMailingAddress(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubjectPropertyIndicator(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddressToBeDeterminedIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.serializeBinaryToWriter
    );
  }
  f = message.getCurrentResidence();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.serializeBinaryToWriter
    );
  }
  f = message.getPriorResidence();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.serializeBinaryToWriter
    );
  }
  f = message.getRealEstateOwned();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.serializeBinaryToWriter
    );
  }
  f = message.getMailingAddress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.serializeBinaryToWriter
    );
  }
  f = message.getSubjectPropertyIndicator();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAddressToBeDeterminedIndicator();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addressUsageTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    addressLineText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    addressAdditionalLineText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addressUnitIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cityName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    countyName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    countyCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    countryName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    addressFormatType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    stateCode: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.AddressUsage>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAddressUsageTypes(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressLineText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressAdditionalLineText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressUnitIdentifier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryName(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage.AddressFormatEnum} */ (reader.readEnum());
      msg.setAddressFormatType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddressUsageTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getAddressLineText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddressAdditionalLineText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddressUnitIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCityName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCountyName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountyCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCountryName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAddressFormatType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string address_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getAddressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AddressUsage address_usage_types = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.AddressUsage>}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getAddressUsageTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.AddressUsage>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.AddressUsage>} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setAddressUsageTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.AddressUsage} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.addAddressUsageTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.clearAddressUsageTypesList = function() {
  return this.setAddressUsageTypesList([]);
};


/**
 * optional string address_line_text = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getAddressLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setAddressLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address_additional_line_text = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getAddressAdditionalLineText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setAddressAdditionalLineText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address_unit_identifier = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getAddressUnitIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setAddressUnitIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string city_name = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getCityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setCityName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string state_name = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string postal_code = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string county_name = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getCountyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setCountyName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string county_code = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getCountyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setCountyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string country_name = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional AddressFormatEnum address_format_type = 12;
 * @return {!proto.wilqo.api.mortgage.AddressFormatEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getAddressFormatType = function() {
  return /** @type {!proto.wilqo.api.mortgage.AddressFormatEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.AddressFormatEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setAddressFormatType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string state_code = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    associationsList: jspb.Message.toObjectList(msg.getAssociationsList(),
    proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.toObject, includeInstance),
    residence: (f = msg.getResidence()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.deserializeBinaryFromReader);
      msg.addAssociations(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.deserializeBinaryFromReader);
      msg.setResidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.serializeBinaryToWriter
    );
  }
  f = message.getResidence();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Association associations = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.getAssociationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.setAssociationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.addAssociations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.clearAssociationsList = function() {
  return this.setAssociationsList([]);
};


/**
 * optional Residence residence = 2;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.getResidence = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.setResidence = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.clearResidence = function() {
  return this.setResidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo.prototype.hasResidence = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.toObject = function(includeInstance, msg) {
  var f, obj = {
    borrowerResidencyType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    borrowerResidencyBasisType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    borrowerResidencyDurationYearsCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    borrowerResidencyDurationMonthsCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    borrowerResidencyReportedDate: (f = msg.getBorrowerResidencyReportedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    monthlyRentAmount: (f = msg.getMonthlyRentAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    borrowerCommunityPropertyStateResidentIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    borrowerResidencyStartDate: (f = msg.getBorrowerResidencyStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    borrowerResidencyEndDate: (f = msg.getBorrowerResidencyEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.BorrowerResidencyEnum} */ (reader.readEnum());
      msg.setBorrowerResidencyType(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.BorrowerResidencyBasisEnum} */ (reader.readEnum());
      msg.setBorrowerResidencyBasisType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBorrowerResidencyDurationYearsCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBorrowerResidencyDurationMonthsCount(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBorrowerResidencyReportedDate(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyRentAmount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBorrowerCommunityPropertyStateResidentIndicator(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBorrowerResidencyStartDate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBorrowerResidencyEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBorrowerResidencyType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBorrowerResidencyBasisType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBorrowerResidencyDurationYearsCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBorrowerResidencyDurationMonthsCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBorrowerResidencyReportedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyRentAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getBorrowerCommunityPropertyStateResidentIndicator();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBorrowerResidencyStartDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBorrowerResidencyEndDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional BorrowerResidencyEnum borrower_residency_type = 1;
 * @return {!proto.wilqo.api.mortgage.BorrowerResidencyEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyType = function() {
  return /** @type {!proto.wilqo.api.mortgage.BorrowerResidencyEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BorrowerResidencyEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional BorrowerResidencyBasisEnum borrower_residency_basis_type = 2;
 * @return {!proto.wilqo.api.mortgage.BorrowerResidencyBasisEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyBasisType = function() {
  return /** @type {!proto.wilqo.api.mortgage.BorrowerResidencyBasisEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.BorrowerResidencyBasisEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyBasisType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 borrower_residency_duration_years_count = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyDurationYearsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyDurationYearsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 borrower_residency_duration_months_count = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyDurationMonthsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyDurationMonthsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp borrower_residency_reported_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyReportedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyReportedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.clearBorrowerResidencyReportedDate = function() {
  return this.setBorrowerResidencyReportedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.hasBorrowerResidencyReportedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_rent_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getMonthlyRentAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setMonthlyRentAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.clearMonthlyRentAmount = function() {
  return this.setMonthlyRentAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.hasMonthlyRentAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool borrower_community_property_state_resident_indicator = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerCommunityPropertyStateResidentIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerCommunityPropertyStateResidentIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp borrower_residency_start_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.clearBorrowerResidencyStartDate = function() {
  return this.setBorrowerResidencyStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.hasBorrowerResidencyStartDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp borrower_residency_end_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.getBorrowerResidencyEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.setBorrowerResidencyEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.clearBorrowerResidencyEndDate = function() {
  return this.setBorrowerResidencyEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Residence.prototype.hasBorrowerResidencyEndDate = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    associationsList: jspb.Message.toObjectList(msg.getAssociationsList(),
    proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.toObject, includeInstance),
    realEstateOwned: (f = msg.getRealEstateOwned()) && proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.deserializeBinaryFromReader);
      msg.addAssociations(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.deserializeBinaryFromReader);
      msg.setRealEstateOwned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.serializeBinaryToWriter
    );
  }
  f = message.getRealEstateOwned();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Association associations = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.getAssociationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.setAssociationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.addAssociations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.clearAssociationsList = function() {
  return this.setAssociationsList([]);
};


/**
 * optional RealEstateOwned real_estate_owned = 2;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.getRealEstateOwned = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.setRealEstateOwned = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.clearRealEstateOwned = function() {
  return this.setRealEstateOwned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo.prototype.hasRealEstateOwned = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.toObject = function(includeInstance, msg) {
  var f, obj = {
    realEstateOwnedId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownedPropertyDispositionStatusType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ownedPropertyCurrentUsageType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ownedPropertyProposedUsageType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ownedPropertySubjectIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    ownedPropertyLienUpbAmount: (f = msg.getOwnedPropertyLienUpbAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyLienInstallmentAmount: (f = msg.getOwnedPropertyLienInstallmentAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyMaintenanceExpenseAmount: (f = msg.getOwnedPropertyMaintenanceExpenseAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyEstimatedValueAmount: (f = msg.getOwnedPropertyEstimatedValueAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyRentalIncomeGrossAmount: (f = msg.getOwnedPropertyRentalIncomeGrossAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyRentalIncomeNetAmount: (f = msg.getOwnedPropertyRentalIncomeNetAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyOwnedUnitCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    objectStatusType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    mortgagesList: jspb.Message.toObjectList(msg.getMortgagesList(),
    proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.toObject, includeInstance),
    ownedPropertyOwnershipPercent: (f = msg.getOwnedPropertyOwnershipPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyOccupancyRatePercent: (f = msg.getOwnedPropertyOccupancyRatePercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyLeaseAgreementUsedInLieuOfTaxReturnForRentalIncomeIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    ownedPropertyAccessoryUnitRentalIncomeIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    ownedPropertyBoarderRentalIncomeIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    propertyInsuranceMonthlyAmount: (f = msg.getPropertyInsuranceMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertySupplementalPropertyInsuranceMonthlyAmount: (f = msg.getOwnedPropertySupplementalPropertyInsuranceMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    realEstateTaxMonthlyAmount: (f = msg.getRealEstateTaxMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyMiPremiumMonthlyAmount: (f = msg.getOwnedPropertyMiPremiumMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    hoaAndProjectFeesMonthlyAmount: (f = msg.getHoaAndProjectFeesMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyOtherMonthlyAmount: (f = msg.getOwnedPropertyOtherMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount: (f = msg.getOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount: (f = msg.getOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyLienInstallmentItemizationType: jspb.Message.getFieldWithDefault(msg, 28, 0),
    ownedPropertyPendingSaleProjectedClosingDate: (f = msg.getOwnedPropertyPendingSaleProjectedClosingDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ownedPropertySoldActualClosingDate: (f = msg.getOwnedPropertySoldActualClosingDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ownedPropertyPendingSaleOrSoldSalesContractAmount: (f = msg.getOwnedPropertyPendingSaleOrSoldSalesContractAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyPendingSaleOrSoldNetProceedsCalculationPercent: (f = msg.getOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyPendingSaleOrSoldNetProceedsAmount: (f = msg.getOwnedPropertyPendingSaleOrSoldNetProceedsAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    ownedPropertyRentalIncomeDocumentationSourceType: jspb.Message.getFieldWithDefault(msg, 34, 0),
    ownedPropertyMaintenanceExpenseItemizationType: jspb.Message.getFieldWithDefault(msg, 35, 0),
    ownedPropertyHasRentalIncome: jspb.Message.getFieldWithDefault(msg, 36, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealEstateOwnedId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.OwnedPropertyDispositionStatusEnum} */ (reader.readEnum());
      msg.setOwnedPropertyDispositionStatusType(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyCurrentUsageEnum} */ (reader.readEnum());
      msg.setOwnedPropertyCurrentUsageType(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (reader.readEnum());
      msg.setOwnedPropertyProposedUsageType(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnedPropertySubjectIndicator(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyLienUpbAmount(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyLienInstallmentAmount(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyMaintenanceExpenseAmount(value);
      break;
    case 9:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyEstimatedValueAmount(value);
      break;
    case 10:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyRentalIncomeGrossAmount(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyRentalIncomeNetAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOwnedPropertyOwnedUnitCount(value);
      break;
    case 13:
      var value = /** @type {!proto.wilqo.api.mortgage.ObjectStatusEnum} */ (reader.readEnum());
      msg.setObjectStatusType(value);
      break;
    case 14:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.deserializeBinaryFromReader);
      msg.addMortgages(value);
      break;
    case 15:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyOwnershipPercent(value);
      break;
    case 16:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyOccupancyRatePercent(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnedPropertyLeaseAgreementUsedInLieuOfTaxReturnForRentalIncomeIndicator(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnedPropertyAccessoryUnitRentalIncomeIndicator(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnedPropertyBoarderRentalIncomeIndicator(value);
      break;
    case 20:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPropertyInsuranceMonthlyAmount(value);
      break;
    case 21:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertySupplementalPropertyInsuranceMonthlyAmount(value);
      break;
    case 22:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setRealEstateTaxMonthlyAmount(value);
      break;
    case 23:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyMiPremiumMonthlyAmount(value);
      break;
    case 24:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setHoaAndProjectFeesMonthlyAmount(value);
      break;
    case 25:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyOtherMonthlyAmount(value);
      break;
    case 26:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount(value);
      break;
    case 27:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount(value);
      break;
    case 28:
      var value = /** @type {!proto.wilqo.api.mortgage.OwnedPropertyLienInstallmentItemization} */ (reader.readEnum());
      msg.setOwnedPropertyLienInstallmentItemizationType(value);
      break;
    case 29:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOwnedPropertyPendingSaleProjectedClosingDate(value);
      break;
    case 30:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOwnedPropertySoldActualClosingDate(value);
      break;
    case 31:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyPendingSaleOrSoldSalesContractAmount(value);
      break;
    case 32:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent(value);
      break;
    case 33:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setOwnedPropertyPendingSaleOrSoldNetProceedsAmount(value);
      break;
    case 34:
      var value = /** @type {!proto.wilqo.api.mortgage.OwnedPropertyRentalIncomeDocumentationSource} */ (reader.readEnum());
      msg.setOwnedPropertyRentalIncomeDocumentationSourceType(value);
      break;
    case 35:
      var value = /** @type {!proto.wilqo.api.mortgage.OwnedPropertyMaintenanceExpenseItemization} */ (reader.readEnum());
      msg.setOwnedPropertyMaintenanceExpenseItemizationType(value);
      break;
    case 36:
      var value = /** @type {!proto.wilqo.api.mortgage.OwnedPropertyHasRentalIncome} */ (reader.readEnum());
      msg.setOwnedPropertyHasRentalIncome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRealEstateOwnedId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnedPropertyDispositionStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOwnedPropertyCurrentUsageType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getOwnedPropertyProposedUsageType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getOwnedPropertySubjectIndicator();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOwnedPropertyLienUpbAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyLienInstallmentAmount();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyMaintenanceExpenseAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyEstimatedValueAmount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyRentalIncomeGrossAmount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyRentalIncomeNetAmount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyOwnedUnitCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getObjectStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getMortgagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyOwnershipPercent();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyOccupancyRatePercent();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyLeaseAgreementUsedInLieuOfTaxReturnForRentalIncomeIndicator();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getOwnedPropertyAccessoryUnitRentalIncomeIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getOwnedPropertyBoarderRentalIncomeIndicator();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getPropertyInsuranceMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertySupplementalPropertyInsuranceMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRealEstateTaxMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyMiPremiumMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getHoaAndProjectFeesMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyOtherMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyLienInstallmentItemizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getOwnedPropertyPendingSaleProjectedClosingDate();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertySoldActualClosingDate();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyPendingSaleOrSoldSalesContractAmount();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyPendingSaleOrSoldNetProceedsAmount();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getOwnedPropertyRentalIncomeDocumentationSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getOwnedPropertyMaintenanceExpenseItemizationType();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
  f = message.getOwnedPropertyHasRentalIncome();
  if (f !== 0.0) {
    writer.writeEnum(
      36,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.toObject = function(includeInstance, msg) {
  var f, obj = {
    liabilityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    liabilityDisplayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isAssociated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    liabilityMonthlyPaymentAmount: (f = msg.getLiabilityMonthlyPaymentAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiabilityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiabilityDisplayName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAssociated(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLiabilityMonthlyPaymentAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiabilityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLiabilityDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsAssociated();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLiabilityMonthlyPaymentAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string liability_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.getLiabilityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.setLiabilityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string liability_display_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.getLiabilityDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.setLiabilityDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_associated = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.getIsAssociated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.setIsAssociated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal liability_monthly_payment_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.getLiabilityMonthlyPaymentAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.setLiabilityMonthlyPaymentAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.clearLiabilityMonthlyPaymentAmount = function() {
  return this.setLiabilityMonthlyPaymentAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage.prototype.hasLiabilityMonthlyPaymentAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string real_estate_owned_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getRealEstateOwnedId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setRealEstateOwnedId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OwnedPropertyDispositionStatusEnum owned_property_disposition_status_type = 2;
 * @return {!proto.wilqo.api.mortgage.OwnedPropertyDispositionStatusEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyDispositionStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OwnedPropertyDispositionStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OwnedPropertyDispositionStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyDispositionStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional PropertyCurrentUsageEnum owned_property_current_usage_type = 3;
 * @return {!proto.wilqo.api.mortgage.PropertyCurrentUsageEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyCurrentUsageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyCurrentUsageEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyCurrentUsageEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyCurrentUsageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional PropertyUsageEnum owned_property_proposed_usage_type = 4;
 * @return {!proto.wilqo.api.mortgage.PropertyUsageEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyProposedUsageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyUsageEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyProposedUsageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional bool owned_property_subject_indicator = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertySubjectIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertySubjectIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_lien_upb_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyLienUpbAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyLienUpbAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyLienUpbAmount = function() {
  return this.setOwnedPropertyLienUpbAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyLienUpbAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_lien_installment_amount = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyLienInstallmentAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyLienInstallmentAmount = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyLienInstallmentAmount = function() {
  return this.setOwnedPropertyLienInstallmentAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyLienInstallmentAmount = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_maintenance_expense_amount = 8;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyMaintenanceExpenseAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyMaintenanceExpenseAmount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyMaintenanceExpenseAmount = function() {
  return this.setOwnedPropertyMaintenanceExpenseAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyMaintenanceExpenseAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_estimated_value_amount = 9;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyEstimatedValueAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 9));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyEstimatedValueAmount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyEstimatedValueAmount = function() {
  return this.setOwnedPropertyEstimatedValueAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyEstimatedValueAmount = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_rental_income_gross_amount = 10;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyRentalIncomeGrossAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 10));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyRentalIncomeGrossAmount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyRentalIncomeGrossAmount = function() {
  return this.setOwnedPropertyRentalIncomeGrossAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyRentalIncomeGrossAmount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_rental_income_net_amount = 11;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyRentalIncomeNetAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 11));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyRentalIncomeNetAmount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyRentalIncomeNetAmount = function() {
  return this.setOwnedPropertyRentalIncomeNetAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyRentalIncomeNetAmount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 owned_property_owned_unit_count = 12;
 * @return {number}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyOwnedUnitCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyOwnedUnitCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional ObjectStatusEnum object_status_type = 13;
 * @return {!proto.wilqo.api.mortgage.ObjectStatusEnum}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getObjectStatusType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ObjectStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ObjectStatusEnum} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setObjectStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * repeated Mortgage mortgages = 14;
 * @return {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage>}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getMortgagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage, 14));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage>} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setMortgagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.addMortgages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.Mortgage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearMortgagesList = function() {
  return this.setMortgagesList([]);
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_ownership_percent = 15;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyOwnershipPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 15));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyOwnershipPercent = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyOwnershipPercent = function() {
  return this.setOwnedPropertyOwnershipPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyOwnershipPercent = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_occupancy_rate_percent = 16;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyOccupancyRatePercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 16));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyOccupancyRatePercent = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyOccupancyRatePercent = function() {
  return this.setOwnedPropertyOccupancyRatePercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyOccupancyRatePercent = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool owned_property_lease_agreement_used_in_lieu_of_tax_return_for_rental_income_indicator = 17;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyLeaseAgreementUsedInLieuOfTaxReturnForRentalIncomeIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyLeaseAgreementUsedInLieuOfTaxReturnForRentalIncomeIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool owned_property_accessory_unit_rental_income_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyAccessoryUnitRentalIncomeIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyAccessoryUnitRentalIncomeIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool owned_property_boarder_rental_income_indicator = 19;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyBoarderRentalIncomeIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyBoarderRentalIncomeIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal property_insurance_monthly_amount = 20;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getPropertyInsuranceMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 20));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setPropertyInsuranceMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearPropertyInsuranceMonthlyAmount = function() {
  return this.setPropertyInsuranceMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasPropertyInsuranceMonthlyAmount = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_supplemental_property_insurance_monthly_amount = 21;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertySupplementalPropertyInsuranceMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 21));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertySupplementalPropertyInsuranceMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertySupplementalPropertyInsuranceMonthlyAmount = function() {
  return this.setOwnedPropertySupplementalPropertyInsuranceMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertySupplementalPropertyInsuranceMonthlyAmount = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal real_estate_tax_monthly_amount = 22;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getRealEstateTaxMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 22));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setRealEstateTaxMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearRealEstateTaxMonthlyAmount = function() {
  return this.setRealEstateTaxMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasRealEstateTaxMonthlyAmount = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_mi_premium_monthly_amount = 23;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyMiPremiumMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 23));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyMiPremiumMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyMiPremiumMonthlyAmount = function() {
  return this.setOwnedPropertyMiPremiumMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyMiPremiumMonthlyAmount = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal hoa_and_project_fees_monthly_amount = 24;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getHoaAndProjectFeesMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 24));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setHoaAndProjectFeesMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearHoaAndProjectFeesMonthlyAmount = function() {
  return this.setHoaAndProjectFeesMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasHoaAndProjectFeesMonthlyAmount = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_other_monthly_amount = 25;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyOtherMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 25));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyOtherMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyOtherMonthlyAmount = function() {
  return this.setOwnedPropertyOtherMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyOtherMonthlyAmount = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_first_mortgage_principal_and_interest_monthly_amount = 26;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 26));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount = function() {
  return this.setOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyFirstMortgagePrincipalAndInterestMonthlyAmount = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_other_mortgage_loans_principal_and_interest_monthly_amount = 27;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 27));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount = function() {
  return this.setOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyOtherMortgageLoansPrincipalAndInterestMonthlyAmount = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional OwnedPropertyLienInstallmentItemization owned_property_lien_installment_itemization_type = 28;
 * @return {!proto.wilqo.api.mortgage.OwnedPropertyLienInstallmentItemization}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyLienInstallmentItemizationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OwnedPropertyLienInstallmentItemization} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OwnedPropertyLienInstallmentItemization} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyLienInstallmentItemizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional google.protobuf.Timestamp owned_property_pending_sale_projected_closing_date = 29;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyPendingSaleProjectedClosingDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 29));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyPendingSaleProjectedClosingDate = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyPendingSaleProjectedClosingDate = function() {
  return this.setOwnedPropertyPendingSaleProjectedClosingDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyPendingSaleProjectedClosingDate = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.Timestamp owned_property_sold_actual_closing_date = 30;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertySoldActualClosingDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 30));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertySoldActualClosingDate = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertySoldActualClosingDate = function() {
  return this.setOwnedPropertySoldActualClosingDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertySoldActualClosingDate = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_pending_sale_or_sold_sales_contract_amount = 31;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyPendingSaleOrSoldSalesContractAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 31));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyPendingSaleOrSoldSalesContractAmount = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyPendingSaleOrSoldSalesContractAmount = function() {
  return this.setOwnedPropertyPendingSaleOrSoldSalesContractAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyPendingSaleOrSoldSalesContractAmount = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_pending_sale_or_sold_net_proceeds_calculation_percent = 32;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 32));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent = function() {
  return this.setOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyPendingSaleOrSoldNetProceedsCalculationPercent = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal owned_property_pending_sale_or_sold_net_proceeds_amount = 33;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyPendingSaleOrSoldNetProceedsAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 33));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyPendingSaleOrSoldNetProceedsAmount = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.clearOwnedPropertyPendingSaleOrSoldNetProceedsAmount = function() {
  return this.setOwnedPropertyPendingSaleOrSoldNetProceedsAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.hasOwnedPropertyPendingSaleOrSoldNetProceedsAmount = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional OwnedPropertyRentalIncomeDocumentationSource owned_property_rental_income_documentation_source_type = 34;
 * @return {!proto.wilqo.api.mortgage.OwnedPropertyRentalIncomeDocumentationSource}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyRentalIncomeDocumentationSourceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OwnedPropertyRentalIncomeDocumentationSource} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OwnedPropertyRentalIncomeDocumentationSource} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyRentalIncomeDocumentationSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional OwnedPropertyMaintenanceExpenseItemization owned_property_maintenance_expense_itemization_type = 35;
 * @return {!proto.wilqo.api.mortgage.OwnedPropertyMaintenanceExpenseItemization}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyMaintenanceExpenseItemizationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.OwnedPropertyMaintenanceExpenseItemization} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OwnedPropertyMaintenanceExpenseItemization} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyMaintenanceExpenseItemizationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};


/**
 * optional OwnedPropertyHasRentalIncome owned_property_has_rental_income = 36;
 * @return {!proto.wilqo.api.mortgage.OwnedPropertyHasRentalIncome}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.getOwnedPropertyHasRentalIncome = function() {
  return /** @type {!proto.wilqo.api.mortgage.OwnedPropertyHasRentalIncome} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.OwnedPropertyHasRentalIncome} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwned.prototype.setOwnedPropertyHasRentalIncome = function(value) {
  return jspb.Message.setProto3EnumField(this, 36, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
    associationsList: jspb.Message.toObjectList(msg.getAssociationsList(),
    proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association;
      reader.readMessage(value,proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.deserializeBinaryFromReader);
      msg.addAssociations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssociationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Association associations = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.prototype.getAssociationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association>} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.prototype.setAssociationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.prototype.addAssociations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress.prototype.clearAssociationsList = function() {
  return this.setAssociationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isAssociated: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association;
  return proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAssociated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsAssociated();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string borrower_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string borrower_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.getBorrowerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.setBorrowerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_associated = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.getIsAssociated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Association.prototype.setIsAssociated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated string borrower_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};


/**
 * optional Address address = 2;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getAddress = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.Address|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ResidenceInfo current_residence = 3;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getCurrentResidence = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setCurrentResidence = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.clearCurrentResidence = function() {
  return this.setCurrentResidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.hasCurrentResidence = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ResidenceInfo prior_residence = 4;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getPriorResidence = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.ResidenceInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setPriorResidence = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.clearPriorResidence = function() {
  return this.setPriorResidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.hasPriorResidence = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional RealEstateOwnedInfo real_estate_owned = 5;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getRealEstateOwned = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.RealEstateOwnedInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setRealEstateOwned = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.clearRealEstateOwned = function() {
  return this.setRealEstateOwned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.hasRealEstateOwned = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MailingAddress mailing_address = 6;
 * @return {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getMailingAddress = function() {
  return /** @type{?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.MailingAddress|undefined} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
*/
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setMailingAddress = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.clearMailingAddress = function() {
  return this.setMailingAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.hasMailingAddress = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool subject_property_indicator = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getSubjectPropertyIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setSubjectPropertyIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool address_to_be_determined_indicator = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.getAddressToBeDeterminedIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.CentralizedAddressLoanPageData} returns this
 */
proto.wilqo.api.mortgage.CentralizedAddressLoanPageData.prototype.setAddressToBeDeterminedIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeesComparisonDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeesComparisonDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    current: (f = msg.getCurrent()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    lastDisclosed: (f = msg.getLastDisclosed()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    difference: (f = msg.getDifference()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    appliedCure: (f = msg.getAppliedCure()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeesComparisonDetail;
  return proto.wilqo.api.mortgage.FeesComparisonDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeesComparisonDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setCurrent(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setLastDisclosed(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setDifference(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setAppliedCure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeesComparisonDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeesComparisonDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLastDisclosed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getDifference();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getAppliedCure();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.ProtoDecimal current = 1;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.getCurrent = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 1));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.setCurrent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.clearCurrent = function() {
  return this.setCurrent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.hasCurrent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal last_disclosed = 2;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.getLastDisclosed = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.setLastDisclosed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.clearLastDisclosed = function() {
  return this.setLastDisclosed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.hasLastDisclosed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal difference = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.getDifference = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.setDifference = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.clearDifference = function() {
  return this.setDifference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.hasDifference = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal applied_cure = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.getAppliedCure = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.setAppliedCure = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonDetail} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.clearAppliedCure = function() {
  return this.setAppliedCure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonDetail.prototype.hasAppliedCure = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeesComparisonFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeesComparisonFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesComparisonFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    feeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paidTo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    detail: (f = msg.getDetail()) && proto.wilqo.api.mortgage.FeesComparisonDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeesComparisonFee;
  return proto.wilqo.api.mortgage.FeesComparisonFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeesComparisonFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaidTo(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.FeesComparisonDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage.FeesComparisonDetail.deserializeBinaryFromReader);
      msg.setDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeesComparisonFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeesComparisonFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesComparisonFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaidTo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage.FeesComparisonDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fee_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.getFeeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.setFeeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fee_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.getFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.setFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string paid_to = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.getPaidTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.setPaidTo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FeesComparisonDetail detail = 4;
 * @return {?proto.wilqo.api.mortgage.FeesComparisonDetail}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.getDetail = function() {
  return /** @type{?proto.wilqo.api.mortgage.FeesComparisonDetail} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.FeesComparisonDetail, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage.FeesComparisonDetail|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.setDetail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.clearDetail = function() {
  return this.setDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonFee.prototype.hasDetail = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FeesComparisonSection.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeesComparisonSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeesComparisonSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesComparisonSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    sectionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sectionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.wilqo.api.mortgage.FeesComparisonFee.toObject, includeInstance),
    requiredCure: (f = msg.getRequiredCure()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    total: (f = msg.getTotal()) && proto.wilqo.api.mortgage.FeesComparisonDetail.toObject(includeInstance, f),
    totalDifference: (f = msg.getTotalDifference()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    totalCureApplied: (f = msg.getTotalCureApplied()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeesComparisonSection;
  return proto.wilqo.api.mortgage.FeesComparisonSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeesComparisonSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSectionName(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} */ (reader.readEnum());
      msg.setSectionType(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.FeesComparisonFee;
      reader.readMessage(value,proto.wilqo.api.mortgage.FeesComparisonFee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setRequiredCure(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage.FeesComparisonDetail;
      reader.readMessage(value,proto.wilqo.api.mortgage.FeesComparisonDetail.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalDifference(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setTotalCureApplied(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeesComparisonSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeesComparisonSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesComparisonSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.FeesComparisonFee.serializeBinaryToWriter
    );
  }
  f = message.getRequiredCure();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage.FeesComparisonDetail.serializeBinaryToWriter
    );
  }
  f = message.getTotalDifference();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getTotalCureApplied();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
};


/**
 * optional string section_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getSectionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setSectionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FeeToleranceCategoryType section_type = 2;
 * @return {!proto.wilqo.api.mortgage.FeeToleranceCategoryType}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeeToleranceCategoryType} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated FeesComparisonFee fees = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.FeesComparisonFee>}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.FeesComparisonFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.FeesComparisonFee, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.FeesComparisonFee>} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesComparisonFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.FeesComparisonFee}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.FeesComparisonFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};


/**
 * optional wilqo.shared.models.ProtoDecimal required_cure = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getRequiredCure = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setRequiredCure = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.clearRequiredCure = function() {
  return this.setRequiredCure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.hasRequiredCure = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FeesComparisonDetail total = 5;
 * @return {?proto.wilqo.api.mortgage.FeesComparisonDetail}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getTotal = function() {
  return /** @type{?proto.wilqo.api.mortgage.FeesComparisonDetail} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.FeesComparisonDetail, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage.FeesComparisonDetail|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_difference = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getTotalDifference = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setTotalDifference = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.clearTotalDifference = function() {
  return this.setTotalDifference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.hasTotalDifference = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal total_cure_applied = 7;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.getTotalCureApplied = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
*/
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.setTotalCureApplied = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.FeesComparisonSection} returns this
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.clearTotalCureApplied = function() {
  return this.setTotalCureApplied(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.FeesComparisonSection.prototype.hasTotalCureApplied = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.toObject = function(includeInstance, msg) {
  var f, obj = {
    isItEnough: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    proto.wilqo.api.mortgage.CardListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.TotalRequiredAndLenderCure;
  return proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsItEnough(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.CardListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsItEnough();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_it_enough = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.getIsItEnough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} returns this
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.setIsItEnough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated CardListItem card_list = 2;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CardListItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} returns this
*/
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.TotalRequiredAndLenderCure} returns this
 */
proto.wilqo.api.mortgage.TotalRequiredAndLenderCure.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    sectionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sectionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    proto.wilqo.api.mortgage.CardListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection;
  return proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSectionName(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSectionType} */ (reader.readEnum());
      msg.setSectionType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.CardListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string section_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.getSectionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} returns this
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.setSectionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FeesAdjustmentsCreditsSectionType section_type = 2;
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSectionType}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.getSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSectionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSectionType} value
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} returns this
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.setSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} returns this
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CardListItem card_list = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CardListItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} returns this
*/
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection} returns this
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSection.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.FeesOriginationDiscountSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    sectionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sectionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cardListList: jspb.Message.toObjectList(msg.getCardListList(),
    proto.wilqo.api.mortgage.CardListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.FeesOriginationDiscountSection;
  return proto.wilqo.api.mortgage.FeesOriginationDiscountSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSectionName(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.FeesOriginationDiscountSectionType} */ (reader.readEnum());
      msg.setSectionType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.CardListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage.CardListItem.deserializeBinaryFromReader);
      msg.addCardList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.FeesOriginationDiscountSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCardListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.CardListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string section_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.getSectionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} returns this
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.setSectionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FeesOriginationDiscountSectionType section_type = 2;
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSectionType}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.getSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage.FeesOriginationDiscountSectionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.FeesOriginationDiscountSectionType} value
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} returns this
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.setSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} returns this
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated CardListItem card_list = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.CardListItem>}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.getCardListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.CardListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.CardListItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.CardListItem>} value
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} returns this
*/
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.setCardListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.CardListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.CardListItem}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.addCardList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.CardListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.FeesOriginationDiscountSection} returns this
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSection.prototype.clearCardListList = function() {
  return this.setCardListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProcessNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProcessNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProcessNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    additionalData: (f = msg.getAdditionalData()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProcessNotification}
 */
proto.wilqo.api.mortgage.ProcessNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProcessNotification;
  return proto.wilqo.api.mortgage.ProcessNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProcessNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProcessNotification}
 */
proto.wilqo.api.mortgage.ProcessNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage.ProcessNotification.NotificationTypeEnum} */ (reader.readEnum());
      msg.setNotificationType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setAdditionalData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProcessNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProcessNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProcessNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdditionalData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProcessNotification.NotificationTypeEnum = {
  NOTIFICATION_TYPE_ENUM_UNKNOWN: 0,
  NOTIFICATION_TYPE_ENUM_UPDATE: 1,
  NOTIFICATION_TYPE_ENUM_FINISH_OK: 2,
  NOTIFICATION_TYPE_ENUM_FINISH_FAILURE: 3
};

/**
 * optional NotificationTypeEnum notification_type = 1;
 * @return {!proto.wilqo.api.mortgage.ProcessNotification.NotificationTypeEnum}
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.getNotificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProcessNotification.NotificationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProcessNotification.NotificationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage.ProcessNotification} returns this
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.setNotificationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProcessNotification} returns this
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Any additional_data = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.getAdditionalData = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProcessNotification} returns this
*/
proto.wilqo.api.mortgage.ProcessNotification.prototype.setAdditionalData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProcessNotification} returns this
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.clearAdditionalData = function() {
  return this.setAdditionalData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProcessNotification.prototype.hasAdditionalData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ProductPricingSearchParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ProductPricingSearchParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    mortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    loanPurposeType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    governmentRefinanceType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    baseLoanAmount: (f = msg.getBaseLoanAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanAmortizationPeriodCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mortgageType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    loanLevelCreditScoreValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    miDurationType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    miPremiumSourceType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    escrowIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    lockBuySideDurationDayCount: jspb.Message.getFieldWithDefault(msg, 21, 0),
    refinanceType: jspb.Message.getFieldWithDefault(msg, 22, 0),
    propertyUsageType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    stateCode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    countyName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    countyCode: jspb.Message.getFieldWithDefault(msg, 15, ""),
    financedUnitCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    propertyEstimatedValueAmount: (f = msg.getPropertyEstimatedValueAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    salesContractAmount: (f = msg.getSalesContractAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    projectLegalStructureType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    projectDesignType: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ProductPricingSearchParams;
  return proto.wilqo.api.mortgage.ProductPricingSearchParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ProductPricingSearchParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (reader.readEnum());
      msg.setLoanPurposeType(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage.GovernmentRefinanceEnum} */ (reader.readEnum());
      msg.setGovernmentRefinanceType(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setBaseLoanAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoanAmortizationPeriodCount(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage.MortgageEnum} */ (reader.readEnum());
      msg.setMortgageType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanLevelCreditScoreValue(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage.MIDurationEnum} */ (reader.readEnum());
      msg.setMiDurationType(value);
      break;
    case 10:
      var value = /** @type {!proto.wilqo.api.mortgage.MIPremiumSourceEnum} */ (reader.readEnum());
      msg.setMiPremiumSourceType(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEscrowIndicator(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLockBuySideDurationDayCount(value);
      break;
    case 22:
      var value = /** @type {!proto.wilqo.api.mortgage.RefinanceCashOutDeterminationEnum} */ (reader.readEnum());
      msg.setRefinanceType(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (reader.readEnum());
      msg.setPropertyUsageType(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountyCode(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinancedUnitCount(value);
      break;
    case 17:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setPropertyEstimatedValueAmount(value);
      break;
    case 18:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setSalesContractAmount(value);
      break;
    case 19:
      var value = /** @type {!proto.wilqo.api.mortgage.ProjectLegalStructureEnum} */ (reader.readEnum());
      msg.setProjectLegalStructureType(value);
      break;
    case 20:
      var value = /** @type {!proto.wilqo.api.mortgage.ProjectDesignEnum} */ (reader.readEnum());
      msg.setProjectDesignType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ProductPricingSearchParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ProductPricingSearchParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLoanPurposeType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGovernmentRefinanceType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getBaseLoanAmount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanAmortizationPeriodCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMortgageType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLoanLevelCreditScoreValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMiDurationType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMiPremiumSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getEscrowIndicator();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getLockBuySideDurationDayCount();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getRefinanceType();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getPropertyUsageType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getStateCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCountyName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCountyCode();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFinancedUnitCount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPropertyEstimatedValueAmount();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getSalesContractAmount();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getProjectLegalStructureType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getProjectDesignType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional bool mortgage_originator_first_time_home_buyer_eligibility_indicator = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setMortgageOriginatorFirstTimeHomeBuyerEligibilityIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional LoanPurposeEnum loan_purpose_type = 2;
 * @return {!proto.wilqo.api.mortgage.LoanPurposeEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getLoanPurposeType = function() {
  return /** @type {!proto.wilqo.api.mortgage.LoanPurposeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.LoanPurposeEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setLoanPurposeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional GovernmentRefinanceEnum government_refinance_type = 3;
 * @return {!proto.wilqo.api.mortgage.GovernmentRefinanceEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getGovernmentRefinanceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.GovernmentRefinanceEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.GovernmentRefinanceEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setGovernmentRefinanceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal base_loan_amount = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getBaseLoanAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
*/
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setBaseLoanAmount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.clearBaseLoanAmount = function() {
  return this.setBaseLoanAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.hasBaseLoanAmount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 loan_amortization_period_count = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getLoanAmortizationPeriodCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setLoanAmortizationPeriodCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional MortgageEnum mortgage_type = 6;
 * @return {!proto.wilqo.api.mortgage.MortgageEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getMortgageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.MortgageEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MortgageEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setMortgageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string loan_level_credit_score_value = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getLoanLevelCreditScoreValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setLoanLevelCreditScoreValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional MIDurationEnum mi_duration_type = 9;
 * @return {!proto.wilqo.api.mortgage.MIDurationEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getMiDurationType = function() {
  return /** @type {!proto.wilqo.api.mortgage.MIDurationEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MIDurationEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setMiDurationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional MIPremiumSourceEnum mi_premium_source_type = 10;
 * @return {!proto.wilqo.api.mortgage.MIPremiumSourceEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getMiPremiumSourceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.MIPremiumSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.MIPremiumSourceEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setMiPremiumSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional bool escrow_indicator = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getEscrowIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setEscrowIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 lock_buy_side_duration_day_count = 21;
 * @return {number}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getLockBuySideDurationDayCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setLockBuySideDurationDayCount = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional RefinanceCashOutDeterminationEnum refinance_type = 22;
 * @return {!proto.wilqo.api.mortgage.RefinanceCashOutDeterminationEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getRefinanceType = function() {
  return /** @type {!proto.wilqo.api.mortgage.RefinanceCashOutDeterminationEnum} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.RefinanceCashOutDeterminationEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setRefinanceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional PropertyUsageEnum property_usage_type = 12;
 * @return {!proto.wilqo.api.mortgage.PropertyUsageEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getPropertyUsageType = function() {
  return /** @type {!proto.wilqo.api.mortgage.PropertyUsageEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.PropertyUsageEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setPropertyUsageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional string state_code = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getStateCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setStateCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string county_name = 14;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getCountyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setCountyName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string county_code = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getCountyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setCountyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 financed_unit_count = 16;
 * @return {number}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getFinancedUnitCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setFinancedUnitCount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal property_estimated_value_amount = 17;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getPropertyEstimatedValueAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 17));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
*/
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setPropertyEstimatedValueAmount = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.clearPropertyEstimatedValueAmount = function() {
  return this.setPropertyEstimatedValueAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.hasPropertyEstimatedValueAmount = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal sales_contract_amount = 18;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getSalesContractAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 18));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
*/
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setSalesContractAmount = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.clearSalesContractAmount = function() {
  return this.setSalesContractAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.hasSalesContractAmount = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional ProjectLegalStructureEnum project_legal_structure_type = 19;
 * @return {!proto.wilqo.api.mortgage.ProjectLegalStructureEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getProjectLegalStructureType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProjectLegalStructureEnum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProjectLegalStructureEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setProjectLegalStructureType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional ProjectDesignEnum project_design_type = 20;
 * @return {!proto.wilqo.api.mortgage.ProjectDesignEnum}
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.getProjectDesignType = function() {
  return /** @type {!proto.wilqo.api.mortgage.ProjectDesignEnum} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage.ProjectDesignEnum} value
 * @return {!proto.wilqo.api.mortgage.ProductPricingSearchParams} returns this
 */
proto.wilqo.api.mortgage.ProductPricingSearchParams.prototype.setProjectDesignType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DealComments.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealComments.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealComments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealComments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealComments.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealCommentsId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    threadsList: jspb.Message.toObjectList(msg.getThreadsList(),
    proto.wilqo.api.mortgage.Thread.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealComments}
 */
proto.wilqo.api.mortgage.DealComments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealComments;
  return proto.wilqo.api.mortgage.DealComments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealComments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealComments}
 */
proto.wilqo.api.mortgage.DealComments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealCommentsId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage.Thread;
      reader.readMessage(value,proto.wilqo.api.mortgage.Thread.deserializeBinaryFromReader);
      msg.addThreads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealComments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealComments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealComments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealComments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealCommentsId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getThreadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage.Thread.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealComments.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealComments} returns this
 */
proto.wilqo.api.mortgage.DealComments.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_comments_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealComments.prototype.getDealCommentsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealComments} returns this
 */
proto.wilqo.api.mortgage.DealComments.prototype.setDealCommentsId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.DealComments.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.DealComments} returns this
*/
proto.wilqo.api.mortgage.DealComments.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.DealComments} returns this
 */
proto.wilqo.api.mortgage.DealComments.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DealComments.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Thread threads = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.Thread>}
 */
proto.wilqo.api.mortgage.DealComments.prototype.getThreadsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.Thread>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.Thread, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.Thread>} value
 * @return {!proto.wilqo.api.mortgage.DealComments} returns this
*/
proto.wilqo.api.mortgage.DealComments.prototype.setThreadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.Thread=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.Thread}
 */
proto.wilqo.api.mortgage.DealComments.prototype.addThreads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage.Thread, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DealComments} returns this
 */
proto.wilqo.api.mortgage.DealComments.prototype.clearThreadsList = function() {
  return this.setThreadsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.Thread.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.Thread.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.Thread.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.Thread} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Thread.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    author: (f = msg.getAuthor()) && proto.wilqo.api.mortgage.DealCommentAuthor.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    richContent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    threadCommentsList: jspb.Message.toObjectList(msg.getThreadCommentsList(),
    proto.wilqo.api.mortgage.ThreadComment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.Thread}
 */
proto.wilqo.api.mortgage.Thread.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.Thread;
  return proto.wilqo.api.mortgage.Thread.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.Thread} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.Thread}
 */
proto.wilqo.api.mortgage.Thread.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.DealCommentAuthor;
      reader.readMessage(value,proto.wilqo.api.mortgage.DealCommentAuthor.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichContent(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.ThreadComment;
      reader.readMessage(value,proto.wilqo.api.mortgage.ThreadComment.deserializeBinaryFromReader);
      msg.addThreadComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.Thread.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.Thread.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.Thread} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.Thread.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.DealCommentAuthor.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRichContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThreadCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage.ThreadComment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string thread_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.Thread.prototype.getThreadId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
 */
proto.wilqo.api.mortgage.Thread.prototype.setThreadId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DealCommentAuthor author = 2;
 * @return {?proto.wilqo.api.mortgage.DealCommentAuthor}
 */
proto.wilqo.api.mortgage.Thread.prototype.getAuthor = function() {
  return /** @type{?proto.wilqo.api.mortgage.DealCommentAuthor} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.DealCommentAuthor, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.DealCommentAuthor|undefined} value
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
*/
proto.wilqo.api.mortgage.Thread.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
 */
proto.wilqo.api.mortgage.Thread.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Thread.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.Thread.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
*/
proto.wilqo.api.mortgage.Thread.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
 */
proto.wilqo.api.mortgage.Thread.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Thread.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.Thread.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
*/
proto.wilqo.api.mortgage.Thread.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
 */
proto.wilqo.api.mortgage.Thread.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.Thread.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string rich_content = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.Thread.prototype.getRichContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
 */
proto.wilqo.api.mortgage.Thread.prototype.setRichContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ThreadComment thread_comments = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.ThreadComment>}
 */
proto.wilqo.api.mortgage.Thread.prototype.getThreadCommentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.ThreadComment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.ThreadComment, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.ThreadComment>} value
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
*/
proto.wilqo.api.mortgage.Thread.prototype.setThreadCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.ThreadComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.ThreadComment}
 */
proto.wilqo.api.mortgage.Thread.prototype.addThreadComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.ThreadComment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.Thread} returns this
 */
proto.wilqo.api.mortgage.Thread.prototype.clearThreadCommentsList = function() {
  return this.setThreadCommentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.ThreadComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.ThreadComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ThreadComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    threadCommentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    author: (f = msg.getAuthor()) && proto.wilqo.api.mortgage.DealCommentAuthor.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    richContent: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.ThreadComment}
 */
proto.wilqo.api.mortgage.ThreadComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.ThreadComment;
  return proto.wilqo.api.mortgage.ThreadComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.ThreadComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.ThreadComment}
 */
proto.wilqo.api.mortgage.ThreadComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThreadCommentId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage.DealCommentAuthor;
      reader.readMessage(value,proto.wilqo.api.mortgage.DealCommentAuthor.deserializeBinaryFromReader);
      msg.setAuthor(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRichContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.ThreadComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.ThreadComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.ThreadComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThreadCommentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage.DealCommentAuthor.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRichContent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string thread_comment_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.getThreadCommentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.setThreadCommentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DealCommentAuthor author = 2;
 * @return {?proto.wilqo.api.mortgage.DealCommentAuthor}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.getAuthor = function() {
  return /** @type{?proto.wilqo.api.mortgage.DealCommentAuthor} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage.DealCommentAuthor, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage.DealCommentAuthor|undefined} value
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
*/
proto.wilqo.api.mortgage.ThreadComment.prototype.setAuthor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.clearAuthor = function() {
  return this.setAuthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.hasAuthor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
*/
proto.wilqo.api.mortgage.ThreadComment.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
*/
proto.wilqo.api.mortgage.ThreadComment.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string rich_content = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.getRichContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.ThreadComment} returns this
 */
proto.wilqo.api.mortgage.ThreadComment.prototype.setRichContent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DealCommentAuthor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DealCommentAuthor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealCommentAuthor.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    avatarUri: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DealCommentAuthor}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DealCommentAuthor;
  return proto.wilqo.api.mortgage.DealCommentAuthor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DealCommentAuthor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DealCommentAuthor}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DealCommentAuthor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DealCommentAuthor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DealCommentAuthor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvatarUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealCommentAuthor} returns this
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealCommentAuthor} returns this
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string avatar_uri = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.getAvatarUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DealCommentAuthor} returns this
 */
proto.wilqo.api.mortgage.DealCommentAuthor.prototype.setAvatarUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DocumentsSubscription.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentsSubscription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentsSubscription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentsSubscription.toObject = function(includeInstance, msg) {
  var f, obj = {
    isConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentCategoriesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentsSubscription;
  return proto.wilqo.api.mortgage.DocumentsSubscription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentsSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConsumer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDocumentCategories(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentsSubscription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentsSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentsSubscription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsConsumer();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentCategoriesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional bool is_consumer = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.getIsConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription} returns this
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.setIsConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription} returns this
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription} returns this
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DocumentCategoryEnum document_categories = 4;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>}
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.getDocumentCategoriesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentCategoryEnum>} value
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription} returns this
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.setDocumentCategoriesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentCategoryEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription} returns this
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.addDocumentCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentsSubscription} returns this
 */
proto.wilqo.api.mortgage.DocumentsSubscription.prototype.clearDocumentCategoriesList = function() {
  return this.setDocumentCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.wilqo.api.mortgage.DocumentSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage}
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage;
  return proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage}
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage.DocumentSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage.DocumentSummary.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage.DocumentSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DocumentSummary documents = 1;
 * @return {!Array<!proto.wilqo.api.mortgage.DocumentSummary>}
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.DocumentSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.DocumentSummary, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.DocumentSummary>} value
 * @return {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage} returns this
*/
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.DocumentSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.DocumentSummary}
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage.DocumentSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage} returns this
 */
proto.wilqo.api.mortgage.DocumentSummarySubscriptionMessage.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.WilqoPartyCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    categoryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerCategoryName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 4, 0),
    showConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    partyTypesList: jspb.Message.toObjectList(msg.getPartyTypesList(),
    proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.toObject, includeInstance),
    consumerOrder: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.WilqoPartyCategory;
  return proto.wilqo.api.mortgage.WilqoPartyCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerCategoryName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowConsumer(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType;
      reader.readMessage(value,proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.deserializeBinaryFromReader);
      msg.addPartyTypes(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConsumerOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.WilqoPartyCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerCategoryName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getShowConsumer();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPartyTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.serializeBinaryToWriter
    );
  }
  f = message.getConsumerOrder();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    showConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    consumerOrder: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType;
  return proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowConsumer(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConsumerOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getShowConsumer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getConsumerOrder();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.WilqoPartyType type = 1;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool show_consumer = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.getShowConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.setShowConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 consumer_order = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.getConsumerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType.prototype.setConsumerOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category_name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_category_name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getConsumerCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setConsumerCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 order = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool show_consumer = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getShowConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setShowConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated PartyType party_types = 6;
 * @return {!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType>}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getPartyTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType>} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
*/
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setPartyTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.addPartyTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage.WilqoPartyCategory.PartyType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.clearPartyTypesList = function() {
  return this.setPartyTypesList([]);
};


/**
 * optional int32 consumer_order = 7;
 * @return {number}
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.getConsumerOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.WilqoPartyCategory.prototype.setConsumerOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.wilqo.api.mortgage.WilqoPartyCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory;
  return proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage.WilqoPartyCategory;
      reader.readMessage(value,proto.wilqo.api.mortgage.WilqoPartyCategory.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage.WilqoPartyCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated WilqoPartyCategory categories = 3;
 * @return {!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory>}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage.WilqoPartyCategory, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage.WilqoPartyCategory>} value
 * @return {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} returns this
*/
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage.WilqoPartyCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage.WilqoPartyCategory}
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage.WilqoPartyCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory} returns this
 */
proto.wilqo.api.mortgage.SeedDataWilqoPartyCategory.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ConditionStatusEnum = {
  CONDITION_STATUS_ENUM_UNKNOWN: 0,
  CONDITION_STATUS_ENUM_NOT_CLEARED: 1,
  CONDITION_STATUS_ENUM_READY_TO_CLEAR: 2,
  CONDITION_STATUS_ENUM_CLEARED: 3,
  CONDITION_STATUS_ENUM_REMOVED: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanPurposeEnum = {
  LOAN_PURPOSE_ENUM_UNKNOWN: 0,
  LOAN_PURPOSE_ENUM_MORTGAGE_MODIFICATION: 1,
  LOAN_PURPOSE_ENUM_OTHER: 2,
  LOAN_PURPOSE_ENUM_PURCHASE: 3,
  LOAN_PURPOSE_ENUM_REFINANCE: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.RefinanceCashOutDeterminationEnum = {
  REFINANCE_CASH_OUT_DETERMINATION_ENUM_UNKNOWN: 0,
  REFINANCE_CASH_OUT_DETERMINATION_ENUM_CASH_OUT: 1,
  REFINANCE_CASH_OUT_DETERMINATION_ENUM_LIMITED_CASH_OUT: 2,
  REFINANCE_CASH_OUT_DETERMINATION_ENUM_NO_CASH_OUT: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.GovernmentRefinanceEnum = {
  GOVERNMENT_REFINANCE_ENUM_UNKNOWN: 0,
  GOVERNMENT_REFINANCE_ENUM_CASH_OUT_VA: 1,
  GOVERNMENT_REFINANCE_ENUM_FULL_DOCUMENTATION: 2,
  GOVERNMENT_REFINANCE_ENUM_INTEREST_RATE_REDUCTION_REFINANCE_LOAN: 3,
  GOVERNMENT_REFINANCE_ENUM_OTHER: 4,
  GOVERNMENT_REFINANCE_ENUM_STREAMLINE_WITH_APPRAISAL: 5,
  GOVERNMENT_REFINANCE_ENUM_STREAMLINE_WITHOUT_APPRAISAL: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.MIDurationEnum = {
  MI_DURATION_ENUM_UNKNOWN: 0,
  MI_DURATION_ENUM_ANNUAL: 1,
  MI_DURATION_ENUM_NOT_APPLICABLE: 2,
  MI_DURATION_ENUM_OTHER: 3,
  MI_DURATION_ENUM_PERIODIC_MONTHLY: 4,
  MI_DURATION_ENUM_SINGLE_LIFE_OF_LOAN: 5,
  MI_DURATION_ENUM_SINGLE_SPECIFIC: 6,
  MI_DURATION_ENUM_SPLIT_PREMIUM: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.MIPremiumSourceEnum = {
  MI_PREMIUM_SOURCE_ENUM_UNKNOWN: 0,
  MI_PREMIUM_SOURCE_ENUM_BORROWER: 1,
  MI_PREMIUM_SOURCE_ENUM_BORROWER_AND_LENDER: 2,
  MI_PREMIUM_SOURCE_ENUM_LENDER: 3,
  MI_PREMIUM_SOURCE_ENUM_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProjectLegalStructureEnum = {
  PROJECT_LEGAL_STRUCTURE_ENUM_UNKNOWN: 0,
  PROJECT_LEGAL_STRUCTURE_ENUM_COMMON_INTEREST_APARTMENT: 1,
  PROJECT_LEGAL_STRUCTURE_ENUM_CONDOMINIUM: 2,
  PROJECT_LEGAL_STRUCTURE_ENUM_COOPERATIVE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProjectDesignEnum = {
  PROJECT_DESIGN_ENUM_UNKNOWN: 0,
  PROJECT_DESIGN_ENUM_GARDEN_PROJECT: 1,
  PROJECT_DESIGN_ENUM_HIGHRISE_PROJECT: 2,
  PROJECT_DESIGN_ENUM_MIDRISE_PROJECT: 3,
  PROJECT_DESIGN_ENUM_OTHER: 4,
  PROJECT_DESIGN_ENUM_TOWNHOUSE_ROWHOUSE: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.BuyingProcessStageEnum = {
  BUYING_PROCESS_STAGE_ENUM_UNKNOWN: 0,
  BUYING_PROCESS_STAGE_ENUM_RESEARCHING: 1,
  BUYING_PROCESS_STAGE_ENUM_TOURING: 2,
  BUYING_PROCESS_STAGE_ENUM_READY_FOR_OFFER: 3,
  BUYING_PROCESS_STAGE_ENUM_CONTRACT_SIGNED: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PropertyUsageEnum = {
  PROPERTY_USAGE_ENUM_UNKNOWN: 0,
  PROPERTY_USAGE_ENUM_INVESTMENT: 1,
  PROPERTY_USAGE_ENUM_OTHER: 2,
  PROPERTY_USAGE_ENUM_PRIMARY_RESIDENCE: 3,
  PROPERTY_USAGE_ENUM_SECOND_HOME: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PropertyTypeEnum = {
  PROPERTY_TYPE_ENUM_UNKNOWN: 0,
  PROPERTY_TYPE_ENUM_SINGLE_FAMILY: 1,
  PROPERTY_TYPE_ENUM_TOWNHOUSE: 2,
  PROPERTY_TYPE_ENUM_MORE_UNITS: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PartyTypeEnum = {
  PARTY_TYPE_ENUM_UNKNOWN: 0,
  PARTY_TYPE_ENUM_BORROWERS: 1,
  PARTY_TYPE_ENUM_INTERNAL_PARTIES: 2,
  PARTY_TYPE_ENUM_INTERNAL_PARTIES_WITHOUT_ROLE: 3,
  PARTY_TYPE_ENUM_THIRD_PARTIES: 4,
  PARTY_TYPE_ENUM_LOAN_ORIGINATOR: 5,
  PARTY_TYPE_ENUM_AUTHORIZED_THIRD_PARTY: 6,
  PARTY_TYPE_ENUM_CONSERVATOR: 7,
  PARTY_TYPE_ENUM_NON_TITLE_NON_SPOUSE: 8,
  PARTY_TYPE_ENUM_NON_TITLE_SPOUSE: 9
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PartyRoleEnum = {
  PARTY_ROLE_ENUM_UNKNOWN: 0,
  PARTY_ROLE_ENUM_APPRAISER: 1,
  PARTY_ROLE_ENUM_APPRAISER_SUPERVISOR: 2,
  PARTY_ROLE_ENUM_ASSIGN_FROM: 3,
  PARTY_ROLE_ENUM_ASSIGN_TO: 4,
  PARTY_ROLE_ENUM_ATTORNEY: 5,
  PARTY_ROLE_ENUM_ATTORNEY_IN_FACT: 6,
  PARTY_ROLE_ENUM_AUTHORIZED_REPRESENTATIVE: 7,
  PARTY_ROLE_ENUM_AUTHORIZED_THIRD_PARTY: 8,
  PARTY_ROLE_ENUM_BANKRUPTCY_FILER: 9,
  PARTY_ROLE_ENUM_BANKRUPTCY_TRUSTEE: 10,
  PARTY_ROLE_ENUM_BENEFICIAL_INTEREST_PARTY: 11,
  PARTY_ROLE_ENUM_BILL_TO_PARTY: 12,
  PARTY_ROLE_ENUM_BORROWER: 13,
  PARTY_ROLE_ENUM_BUILDER: 14,
  PARTY_ROLE_ENUM_CLIENT: 15,
  PARTY_ROLE_ENUM_CLOSING_AGENT: 16,
  PARTY_ROLE_ENUM_CONSERVATOR: 17,
  PARTY_ROLE_ENUM_CONSUMER_REPORTING_AGENCY: 18,
  PARTY_ROLE_ENUM_COOPERATIVE_COMPANY: 19,
  PARTY_ROLE_ENUM_CORRESPONDENT_LENDER: 20,
  PARTY_ROLE_ENUM_COSIGNER: 21,
  PARTY_ROLE_ENUM_CREDIT_COUNSELING_AGENT: 22,
  PARTY_ROLE_ENUM_CREDIT_ENHANCEMENT_RISK_HOLDER: 23,
  PARTY_ROLE_ENUM_CUSTODIAN_NOTE_PAY_TO: 24,
  PARTY_ROLE_ENUM_DEBT_COLLECTOR: 25,
  PARTY_ROLE_ENUM_DEFENDANT: 26,
  PARTY_ROLE_ENUM_DELIVER_RESCISSION_TO: 27,
  PARTY_ROLE_ENUM_DESIGNATED_CONTACT: 28,
  PARTY_ROLE_ENUM_DOCUMENT_CUSTODIAN: 29,
  PARTY_ROLE_ENUM_EN_OTE_CONTROLLER: 30,
  PARTY_ROLE_ENUM_EN_OTE_CONTROLLER_TRANSFEREE: 31,
  PARTY_ROLE_ENUM_EN_OTE_CUSTODIAN: 32,
  PARTY_ROLE_ENUM_EN_OTE_CUSTODIAN_TRANSFEREE: 33,
  PARTY_ROLE_ENUM_EN_OTE_DELEGATEE_FOR_TRANSFERS: 34,
  PARTY_ROLE_ENUM_EN_OTE_REGISTERING_PARTY: 35,
  PARTY_ROLE_ENUM_EN_OTE_SERVICER: 36,
  PARTY_ROLE_ENUM_EN_OTE_SERVICER_TRANSFEREE: 37,
  PARTY_ROLE_ENUM_EN_OTE_TRANSFER_INITIATOR: 38,
  PARTY_ROLE_ENUM_EXECUTOR: 39,
  PARTY_ROLE_ENUM_FHA_SPONSOR: 40,
  PARTY_ROLE_ENUM_FLOOD_CERTIFICATE_PROVIDER: 41,
  PARTY_ROLE_ENUM_FULFILLMENT_PARTY: 42,
  PARTY_ROLE_ENUM_GIFT_DONOR: 43,
  PARTY_ROLE_ENUM_GRANTEE: 44,
  PARTY_ROLE_ENUM_GRANTOR: 45,
  PARTY_ROLE_ENUM_GUARANTOR: 46,
  PARTY_ROLE_ENUM_HAZARD_INSURANCE_AGENT: 47,
  PARTY_ROLE_ENUM_HAZARD_INSURANCE_COMPANY: 48,
  PARTY_ROLE_ENUM_HOMEOWNERS_ASSOCIATION: 49,
  PARTY_ROLE_ENUM_HOUSING_COUNSELING_AGENCY: 50,
  PARTY_ROLE_ENUM_HOUSING_COUNSELING_AGENT: 51,
  PARTY_ROLE_ENUM_HUD_1_SETTLEMENT_AGENT: 52,
  PARTY_ROLE_ENUM_INTERVIEWER: 53,
  PARTY_ROLE_ENUM_INTERVIEWER_EMPLOYER: 54,
  PARTY_ROLE_ENUM_INVESTOR: 55,
  PARTY_ROLE_ENUM_IRS_TAX_FORM_THIRD_PARTY: 56,
  PARTY_ROLE_ENUM_LAW_FIRM: 57,
  PARTY_ROLE_ENUM_LENDER: 58,
  PARTY_ROLE_ENUM_LENDER_BRANCH: 59,
  PARTY_ROLE_ENUM_LIEN_HOLDER: 60,
  PARTY_ROLE_ENUM_LOAN_CLOSER: 61,
  PARTY_ROLE_ENUM_LOAN_DELIVERY_FILE_PREPARER: 62,
  PARTY_ROLE_ENUM_LOAN_FUNDER: 63,
  PARTY_ROLE_ENUM_LOAN_OFFICER: 64,
  PARTY_ROLE_ENUM_LOAN_ORIGINATION_COMPANY: 65,
  PARTY_ROLE_ENUM_LOAN_ORIGINATOR: 66,
  PARTY_ROLE_ENUM_LOAN_PROCESSOR: 67,
  PARTY_ROLE_ENUM_LOAN_SELLER: 68,
  PARTY_ROLE_ENUM_LOAN_UNDERWRITER: 69,
  PARTY_ROLE_ENUM_LOSS_PAYEE: 70,
  PARTY_ROLE_ENUM_MANAGEMENT_COMPANY: 71,
  PARTY_ROLE_ENUM_MI_COMPANY: 72,
  PARTY_ROLE_ENUM_MORTGAGE_BROKER: 73,
  PARTY_ROLE_ENUM_NON_TITLE_NON_SPOUSE_OWNERSHIP_INTEREST: 74,
  PARTY_ROLE_ENUM_NON_TITLE_SPOUSE: 75,
  PARTY_ROLE_ENUM_NOTARY: 76,
  PARTY_ROLE_ENUM_NOTE_PAY_TO: 77,
  PARTY_ROLE_ENUM_NOTE_PAY_TO_RECIPIENT: 78,
  PARTY_ROLE_ENUM_OTHER: 79,
  PARTY_ROLE_ENUM_PAYEE: 80,
  PARTY_ROLE_ENUM_PLAINTIFF: 81,
  PARTY_ROLE_ENUM_POOL_INSURER: 82,
  PARTY_ROLE_ENUM_POOL_ISSUER: 83,
  PARTY_ROLE_ENUM_POOL_ISSUER_TRANSFEREE: 84,
  PARTY_ROLE_ENUM_PREPARED_BY: 85,
  PARTY_ROLE_ENUM_PROJECT_DEVELOPER: 86,
  PARTY_ROLE_ENUM_PROJECT_MANAGEMENT_AGENT: 87,
  PARTY_ROLE_ENUM_PROPERTY_ACCESS_CONTACT: 88,
  PARTY_ROLE_ENUM_PROPERTY_JURISDICTIONAL_AUTHORITY: 89,
  PARTY_ROLE_ENUM_PROPERTY_OWNER: 90,
  PARTY_ROLE_ENUM_PROPERTY_PRESERVATION_AGENT: 91,
  PARTY_ROLE_ENUM_PROPERTY_PURCHASER: 92,
  PARTY_ROLE_ENUM_PROPERTY_SELLER: 93,
  PARTY_ROLE_ENUM_REAL_ESTATE_AGENT: 94,
  PARTY_ROLE_ENUM_RECEIVING_PARTY: 95,
  PARTY_ROLE_ENUM_REGISTRY_OPERATOR: 96,
  PARTY_ROLE_ENUM_REGULATORY_AGENCY: 97,
  PARTY_ROLE_ENUM_REQUESTING_PARTY: 98,
  PARTY_ROLE_ENUM_RESPONDING_PARTY: 99,
  PARTY_ROLE_ENUM_RESPOND_TO_PARTY: 100,
  PARTY_ROLE_ENUM_RETURN_TO: 101,
  PARTY_ROLE_ENUM_REVIEW_APPRAISER: 102,
  PARTY_ROLE_ENUM_SECURITY_ISSUER: 103,
  PARTY_ROLE_ENUM_SERVICE_BUREAU: 104,
  PARTY_ROLE_ENUM_SERVICE_PROVIDER: 105,
  PARTY_ROLE_ENUM_SERVICER: 106,
  PARTY_ROLE_ENUM_SERVICER_PAYMENT_COLLECTION: 107,
  PARTY_ROLE_ENUM_SETTLOR: 108,
  PARTY_ROLE_ENUM_SPOUSE: 109,
  PARTY_ROLE_ENUM_SUBMITTING_PARTY: 110,
  PARTY_ROLE_ENUM_SUCCESSOR_IN_INTEREST: 111,
  PARTY_ROLE_ENUM_TAXABLE_PARTY: 112,
  PARTY_ROLE_ENUM_TAX_ASSESSOR: 113,
  PARTY_ROLE_ENUM_TAX_COLLECTOR: 114,
  PARTY_ROLE_ENUM_TAXPAYER: 115,
  PARTY_ROLE_ENUM_TAX_SERVICE_PROVIDER: 116,
  PARTY_ROLE_ENUM_TAX_SERVICER: 117,
  PARTY_ROLE_ENUM_THIRD_PARTY_INVESTOR: 118,
  PARTY_ROLE_ENUM_THIRD_PARTY_ORIGINATOR: 119,
  PARTY_ROLE_ENUM_TITLE_COMPANY: 120,
  PARTY_ROLE_ENUM_TITLE_HOLDER: 121,
  PARTY_ROLE_ENUM_TITLE_UNDERWRITER: 122,
  PARTY_ROLE_ENUM_TRUST: 123,
  PARTY_ROLE_ENUM_TRUST_BENEFICIARY: 124,
  PARTY_ROLE_ENUM_TRUSTEE: 125,
  PARTY_ROLE_ENUM_UNSPECIFIED: 126,
  PARTY_ROLE_ENUM_WAREHOUSE_LENDER: 127,
  PARTY_ROLE_ENUM_WITNESS: 128
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FraudRoleEnum = {
  FRAUD_ROLE_ENUM_UNKNOWN: 0,
  FRAUD_ROLE_ENUM_APPRAISAL_COMPANY: 1,
  FRAUD_ROLE_ENUM_APPRAISAL_MANAGEMENT_COMPANY: 2,
  FRAUD_ROLE_ENUM_APPRAISAL_REVIEWER: 3,
  FRAUD_ROLE_ENUM_APPRAISAL_REVIEWER_COMPANY: 4,
  FRAUD_ROLE_ENUM_APPRAISER: 5,
  FRAUD_ROLE_ENUM_ATTORNEY: 6,
  FRAUD_ROLE_ENUM_ATTORNEY_COMPANY: 7,
  FRAUD_ROLE_ENUM_BUYER_AGENT: 8,
  FRAUD_ROLE_ENUM_BUYER_ATTORNEY: 9,
  FRAUD_ROLE_ENUM_BUYERS_REALTOR_COMPANY: 10,
  FRAUD_ROLE_ENUM_CLOSER: 11,
  FRAUD_ROLE_ENUM_CLOSING_AGENT: 12,
  FRAUD_ROLE_ENUM_CLOSING_COMPANY: 13,
  FRAUD_ROLE_ENUM_CREDIT_BUREAU_COMPANY: 14,
  FRAUD_ROLE_ENUM_ESCROW_AGENT: 15,
  FRAUD_ROLE_ENUM_ESCROW_COMPANY: 16,
  FRAUD_ROLE_ENUM_FUNDER: 17,
  FRAUD_ROLE_ENUM_HOMEOWNERS_INSURANCE_COMPANY: 18,
  FRAUD_ROLE_ENUM_HOMEOWNERS_INSURANCE_CONTACT: 19,
  FRAUD_ROLE_ENUM_LENDER: 20,
  FRAUD_ROLE_ENUM_LISTING_REALTOR: 21,
  FRAUD_ROLE_ENUM_LISTING_REAL_ESTATE_OFFICE: 22,
  FRAUD_ROLE_ENUM_LOAN_ORIGINATOR: 23,
  FRAUD_ROLE_ENUM_LOAN_OFFICER_ASSISTANT: 24,
  FRAUD_ROLE_ENUM_MORTGAGE_INSURANCE_COMPANY: 25,
  FRAUD_ROLE_ENUM_NON_OBLIGATED_BORROWER: 26,
  FRAUD_ROLE_ENUM_NOTARY: 27,
  FRAUD_ROLE_ENUM_PROCESSOR: 28,
  FRAUD_ROLE_ENUM_SELLER: 29,
  FRAUD_ROLE_ENUM_SETTLEMENT_AGENT: 30,
  FRAUD_ROLE_ENUM_SETTLEMENT_COMPANY: 31,
  FRAUD_ROLE_ENUM_TITLE_COMPANY: 32,
  FRAUD_ROLE_ENUM_UNDERWRITER: 33,
  FRAUD_ROLE_ENUM_BORROWER: 34
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.StorageTypeEnum = {
  STORAGE_TYPE_ENUM_UNKNOWN: 0,
  STORAGE_TYPE_ENUM_EXTERNAL: 1,
  STORAGE_TYPE_ENUM_SECURE_FILE_STORAGE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DocumentEnum = {
  DOCUMENT_ENUM_UNKNOWN: 0,
  DOCUMENT_ENUM_203_K_BORROWER_ACKNOWLEDGMENT: 1,
  DOCUMENT_ENUM_203_K_BORROWER_IDENTITY_OF_INTEREST_CERTIFICATION: 2,
  DOCUMENT_ENUM_203_K_CONSULTANT_IDENTITY_OF_INTEREST_STATEMENT: 3,
  DOCUMENT_ENUM_203_K_CONSULTANT_REPORT: 4,
  DOCUMENT_ENUM_203_K_COST_ESTIMATES: 5,
  DOCUMENT_ENUM_203_K_DRAW_REQUEST: 6,
  DOCUMENT_ENUM_203_K_HOMEOWNER_ACKNOWLEDGMENT: 7,
  DOCUMENT_ENUM_203_K_INITIAL_DRAW_REQUEST: 8,
  DOCUMENT_ENUM_203_K_MAXIMUM_MORTGAGE_WORKSHEET: 9,
  DOCUMENT_ENUM_203_K_REHABILITATION_AGREEMENT: 10,
  DOCUMENT_ENUM_ABSTRACT_NOTICE_AGREEMENT: 11,
  DOCUMENT_ENUM_ABSTRACT_OF_JUDGMENT: 12,
  DOCUMENT_ENUM_ACH_DEBIT_AUTHORIZATION: 13,
  DOCUMENT_ENUM_ACKNOWLEDGMENT_OF_NOTICE_OF_RIGHT_TO_CANCEL: 14,
  DOCUMENT_ENUM_AFFIDAVIT_OF_DEATH: 15,
  DOCUMENT_ENUM_AFFIDAVIT_OF_LOST_NOTE: 16,
  DOCUMENT_ENUM_AFFILIATED_BUSINESS_ARRANGEMENT_DISCLOSURE: 17,
  DOCUMENT_ENUM_AIRPORT_NOISE_POLLUTION_AGREEMENT: 18,
  DOCUMENT_ENUM_ALTERNATIVE_REQUEST_FOR_AGREEMENT_OF_SHORT_SALE: 19,
  DOCUMENT_ENUM_AMENDATORY_CLAUSE: 20,
  DOCUMENT_ENUM_AMORTIZATION_SCHEDULE: 21,
  DOCUMENT_ENUM_APPLICATION_DISCLOSURE: 22,
  DOCUMENT_ENUM_APPLICATION_DISCLOSURE_ADDITIONAL_CREDIT_AND_DEBT: 23,
  DOCUMENT_ENUM_APPRAISAL_RECERTIFICATION: 24,
  DOCUMENT_ENUM_APPRAISAL_REPORT: 25,
  DOCUMENT_ENUM_APPRAISAL_REPORT_DESKTOP: 26,
  DOCUMENT_ENUM_APPRAISAL_REPORT_EXTERIOR_ONLY: 27,
  DOCUMENT_ENUM_APPRAISAL_REPORT_INTERIOR_EXTERIOR: 28,
  DOCUMENT_ENUM_APPRAISAL_REVIEW: 29,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_EXTERIOR_ONLY_INSPECTION_WITH_VALUE_OPINION: 30,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_EXTERIOR_ONLY_WITHOUT_VALUE_OPINION: 31,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_INTERIOR_EXTERIOR_INSPECTION_WITH_VALUE_OPINION: 32,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_INTERIOR_EXTERIOR_WITHOUT_VALUE_OPINION: 33,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_NO_INSPECTION_WITH_VALUE_OPINION: 34,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_WITHOUT_VALUE_OPINION: 35,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_REQUEST: 36,
  DOCUMENT_ENUM_APPROVAL_LETTER: 37,
  DOCUMENT_ENUM_ARTICLES_OF_INCORPORATION: 38,
  DOCUMENT_ENUM_ASSIGNMENT: 39,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_DEED_OF_TRUST: 40,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_MORTGAGE: 41,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_RENTS: 42,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_TRADE: 43,
  DOCUMENT_ENUM_ASSIGNMENT_BLANKET_ASSIGNMENT: 44,
  DOCUMENT_ENUM_ASSIGNMENT_COOPERATIVE_ASSIGNMENT_OF_PROPRIETARY_LEASE: 45,
  DOCUMENT_ENUM_ASSUMPTION_AGREEMENT: 46,
  DOCUMENT_ENUM_ASSURANCE_OF_COMPLETION: 47,
  DOCUMENT_ENUM_ATTESTATION_OF_DAMAGE_TO_PROPERTY: 48,
  DOCUMENT_ENUM_ATTORNEY_IN_FACT_AFFIDAVIT: 49,
  DOCUMENT_ENUM_ATTORNEYS_OPINION_LETTER: 50,
  DOCUMENT_ENUM_AUTOMATED_UNDERWRITING_FEEDBACK: 51,
  DOCUMENT_ENUM_AUTOMATED_VALUATION_REPORT: 52,
  DOCUMENT_ENUM_AUTOMATED_VALUATION_REPORT_WITH_INSPECTION: 53,
  DOCUMENT_ENUM_AVM_FEEDBACK: 54,
  DOCUMENT_ENUM_BAILEE_LETTER: 55,
  DOCUMENT_ENUM_BALANCE_TRANSFER_AUTHORIZATION_NOTICE: 56,
  DOCUMENT_ENUM_BALLOON_REFINANCE_DISCLOSURE: 57,
  DOCUMENT_ENUM_BANK_DEPOSIT_SLIP: 58,
  DOCUMENT_ENUM_BANKRUPTCY_DISCHARGE_NOTICE: 59,
  DOCUMENT_ENUM_BANK_STATEMENT: 60,
  DOCUMENT_ENUM_BANK_STATEMENT401_K: 61,
  DOCUMENT_ENUM_BANK_STATEMENT_CHECKING_ACCOUNT: 62,
  DOCUMENT_ENUM_BANK_STATEMENT_MUTUAL_FUND_ACCOUNT: 63,
  DOCUMENT_ENUM_BANK_STATEMENT_SAVING_ACCOUNT: 64,
  DOCUMENT_ENUM_BANK_STATEMENT_STOCK_ACCOUNT: 65,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT: 66,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT401_K: 67,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT_ANNUITY: 68,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT_PENSION: 69,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT_TRUST: 70,
  DOCUMENT_ENUM_BID: 71,
  DOCUMENT_ENUM_BIRTH_CERTIFICATE: 72,
  DOCUMENT_ENUM_BOND_CERTIFICATE: 73,
  DOCUMENT_ENUM_BORROWER_ACKNOWLEDGMENT_OF_PROPERTY_CONDITION: 74,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE: 75,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_GAP_IN_EMPLOYMENT: 76,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_LETTER_OF_EXPLANATION: 77,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_LETTER_OF_INTENT: 78,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_QUALIFIED_WRITTEN_REQUEST: 79,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_TRAILING_SPOUSE: 80,
  DOCUMENT_ENUM_BORROWER_LIEN_AFFIDAVIT: 81,
  DOCUMENT_ENUM_BORROWERS_CERTIFICATION: 82,
  DOCUMENT_ENUM_BORROWERS_CONTRACT_WITH_RESPECT_TO_HOTEL_AND_TRANSIENT_USE_OF_PROPERTY: 83,
  DOCUMENT_ENUM_BREACH_NOTICE: 84,
  DOCUMENT_ENUM_BROKER_DISCLOSURE_STATEMENT: 85,
  DOCUMENT_ENUM_BROKER_PRICE_OPINION: 86,
  DOCUMENT_ENUM_BROKER_PRICE_OPINION_DESKTOP: 87,
  DOCUMENT_ENUM_BROKER_PRICE_OPINION_EXTERIOR_INSPECTION: 88,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION: 89,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_BUILDER_CERTIFICATION_OF_PLANS_AND_SPECIFICATIONS: 90,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_BUILDERS_CERTIFICATE: 91,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_PROPERTY_INSPECTION: 92,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_TERMITE_TREATMENT: 93,
  DOCUMENT_ENUM_BUILDING_PERMIT: 94,
  DOCUMENT_ENUM_BUSINESS_LICENSE: 95,
  DOCUMENT_ENUM_BUYDOWN_AGREEMENT: 96,
  DOCUMENT_ENUM_BUYING_YOUR_HOME_SETTLEMENT_COSTS_AND_HELPFUL_INFORMATION: 97,
  DOCUMENT_ENUM_CAIVRS_AUTHORIZATION: 98,
  DOCUMENT_ENUM_CANCELLATION_OF_LISTING: 99,
  DOCUMENT_ENUM_CHECK: 100,
  DOCUMENT_ENUM_CHECKLIST: 101,
  DOCUMENT_ENUM_CHILD_SUPPORT_VERIFICATION: 102,
  DOCUMENT_ENUM_CLOSE_LINE_OF_CREDIT_REQUEST: 103,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE: 104,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_ALTERNATE_FORM: 105,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_BORROWER_ONLY: 106,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_MODEL_FORM: 107,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_SELLER_ONLY: 108,
  DOCUMENT_ENUM_CLOSING_INSTRUCTIONS: 109,
  DOCUMENT_ENUM_COLLECTION_REGISTER: 110,
  DOCUMENT_ENUM_COMPARATIVE_INCOME_ANALYSIS: 111,
  DOCUMENT_ENUM_COMPLIANCE_AGREEMENT: 112,
  DOCUMENT_ENUM_COMPLIANCE_INSPECTION_REPORT: 113,
  DOCUMENT_ENUM_CONDITIONAL_COMMITMENT: 114,
  DOCUMENT_ENUM_CONDOMINIUM_OCCUPANCY_CERTIFICATE: 115,
  DOCUMENT_ENUM_CONSERVATOR_AND_GUARDIANSHIP_AGREEMENT: 116,
  DOCUMENT_ENUM_CONSTRUCTION_COST_BREAKDOWN: 117,
  DOCUMENT_ENUM_CONSUMER_HANDBOOK_ON_ARM: 118,
  DOCUMENT_ENUM_CONVERSION_OPTION_NOTICE: 119,
  DOCUMENT_ENUM_CONVEYANCE_DEED: 120,
  DOCUMENT_ENUM_CONVEYANCE_DEED_BARGAIN_AND_SALE_DEED: 121,
  DOCUMENT_ENUM_CONVEYANCE_DEED_QUIT_CLAIM_DEED: 122,
  DOCUMENT_ENUM_CONVEYANCE_DEED_WARRANTY_DEED: 123,
  DOCUMENT_ENUM_CONVEYANCE_TAX_FORM: 124,
  DOCUMENT_ENUM_COOPERATIVE_BYLAWS: 125,
  DOCUMENT_ENUM_COOPERATIVE_OPERATING_BUDGET: 126,
  DOCUMENT_ENUM_COOPERATIVE_PROPRIETARY_LEASE: 127,
  DOCUMENT_ENUM_COOPERATIVE_RECOGNITION_AGREEMENT: 128,
  DOCUMENT_ENUM_COOPERATIVE_STOCK_CERTIFICATE: 129,
  DOCUMENT_ENUM_COOPERATIVE_STOCK_POWER: 130,
  DOCUMENT_ENUM_COSIGNER_NOTICE: 131,
  DOCUMENT_ENUM_COUNCIL_OF_AMERICAN_BUILDING_OFFICIALS_CERTIFICATION: 132,
  DOCUMENT_ENUM_COUNSELING_CERTIFICATION: 133,
  DOCUMENT_ENUM_COUNSELING_CERTIFICATION_HOMEOWNERSHIP: 134,
  DOCUMENT_ENUM_COUNSELING_CERTIFICATION_HOME_RETENTION: 135,
  DOCUMENT_ENUM_COUNSELING_CHECKLIST_FOR_MILITARY_HOMEBUYERS: 136,
  DOCUMENT_ENUM_CREDIT_CARD_AUTHORIZATION: 137,
  DOCUMENT_ENUM_CREDIT_INSURANCE_AGREEMENT: 138,
  DOCUMENT_ENUM_CREDIT_REPORT: 139,
  DOCUMENT_ENUM_CREDIT_REPORTING_ADJUSTMENT: 140,
  DOCUMENT_ENUM_CUSTOMER_IDENTIFICATION_VERIFICATION: 141,
  DOCUMENT_ENUM_DEATH_CERTIFICATE: 142,
  DOCUMENT_ENUM_DEBTORS_STATEMENT_OF_RIGHTS: 143,
  DOCUMENT_ENUM_DIVORCE_DECREE: 144,
  DOCUMENT_ENUM_DOCUMENT_DELIVERY_CERTIFICATION: 145,
  DOCUMENT_ENUM_DOCUMENT_DELIVERY_CERTIFICATION_APPRAISAL_REPORT: 146,
  DOCUMENT_ENUM_DOCUMENT_SEQUENCE: 147,
  DOCUMENT_ENUM_DRIVERS_LICENSE: 148,
  DOCUMENT_ENUM_EARLY_START_LETTER: 149,
  DOCUMENT_ENUM_ELECTRONIC_FUNDS_TRANSFER: 150,
  DOCUMENT_ENUM_ELEVATION_CERTIFICATE: 151,
  DOCUMENT_ENUM_EMPLOYEE_IDENTIFICATION: 152,
  DOCUMENT_ENUM_ENERGY_EFFICIENT_MORTGAGE_WORKSHEET: 153,
  DOCUMENT_ENUM_EQUAL_CREDIT_OPPORTUNITY_ACT_DISCLOSURE: 154,
  DOCUMENT_ENUM_ESCROW_AGREEMENT: 155,
  DOCUMENT_ENUM_ESCROW_ANALYSIS_ANNUAL_DISCLOSURE_STATEMENT: 156,
  DOCUMENT_ENUM_ESCROW_FOR_COMPLETION_AGREEMENT: 157,
  DOCUMENT_ENUM_ESCROW_FOR_COMPLETION_LETTER: 158,
  DOCUMENT_ENUM_ESCROW_WAIVER: 159,
  DOCUMENT_ENUM_ESTIMATE_OF_CLOSING_COSTS_PAID_TO_THIRD_PARTY: 160,
  DOCUMENT_ENUM_ESTOPPEL_AGREEMENT: 161,
  DOCUMENT_ENUM_EVALUATION_REPORT: 162,
  DOCUMENT_ENUM_EVALUATION_REPORT_DESKTOP: 163,
  DOCUMENT_ENUM_EVALUATION_REPORT_EXTERIOR_INSPECTION: 164,
  DOCUMENT_ENUM_FACTA_CREDIT_SCORE_DISCLOSURE: 165,
  DOCUMENT_ENUM_FAIR_LENDING_NOTICE: 166,
  DOCUMENT_ENUM_FEDERAL_APPLICATION_INSURANCE_DISCLOSURE: 167,
  DOCUMENT_ENUM_FEDERAL_SALE_OF_INSURANCE_DISCLOSURE: 168,
  DOCUMENT_ENUM_FH_A__MI_PREMIUM_NETTING_AUTHORIZATION: 169,
  DOCUMENT_ENUM_FHA_FIVE_DAY_WAIVER: 170,
  DOCUMENT_ENUM_FHA_LIMITED_DENIAL_OF_PARTICIPATION_GENERAL_SERVICES_ADMINISTRATION_CHECKLIST: 171,
  DOCUMENT_ENUM_FHA_MORTGAGE_CREDIT_ANALYSIS_WORKSHEET: 172,
  DOCUMENT_ENUM_FHA_REFERRAL_CHECKLIST: 173,
  DOCUMENT_ENUM_FHA_REFINANCE_MAXIMUM_MORTGAGE_WORKSHEET: 174,
  DOCUMENT_ENUM_FHA_REFINANCE_OF_BORROWERS_IN_NEGATIVE_EQUITY_POSITIONS_BORROWER_CERTIFICATION: 175,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT: 176,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_BALANCE_SHEET: 177,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_CASH_FLOW: 178,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_FINANCIAL_POSITION: 179,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_INCOME: 180,
  DOCUMENT_ENUM_FLOOD_HAZARD_NOTICE: 181,
  DOCUMENT_ENUM_FLOOD_INSURANCE_AGREEMENT: 182,
  DOCUMENT_ENUM_FORBEARANCE_PLAN_AGREEMENT: 183,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE: 184,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_CANCELLATION: 185,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_INITIAL: 186,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_INSUFFICIENT_DOCUMENTATION: 187,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_PLACEMENT: 188,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_RENEWAL: 189,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_SECOND_AND_FINAL: 190,
  DOCUMENT_ENUM_FOR_YOUR_PROTECTION_HOME_INSPECTION: 191,
  DOCUMENT_ENUM_FRE_OWNED_STREAMLINE_REFINANCE_CHECKLIST: 192,
  DOCUMENT_ENUM_FUNDING_TRANSMITTAL: 193,
  DOCUMENT_ENUM_GENERAL_LOAN_ACKNOWLEDGMENT: 194,
  DOCUMENT_ENUM_GFE: 195,
  DOCUMENT_ENUM_GIFT_LETTER: 196,
  DOCUMENT_ENUM_GROUP_SAVINGS_AGREEMENT: 197,
  DOCUMENT_ENUM_HECM_ANTI_CHURNING_DISCLOSURE: 198,
  DOCUMENT_ENUM_HECM_CALCULATION_EVIDENCE: 199,
  DOCUMENT_ENUM_HECM_CHOICE_OF_INSURANCE_OPTIONS_NOTICE: 200,
  DOCUMENT_ENUM_HECM_CLOSING_COSTS_AND_LIENS_SCHEDULE: 201,
  DOCUMENT_ENUM_HECM_COUNSELING_WAIVER_QUALIFICATION: 202,
  DOCUMENT_ENUM_HECM_EXTENSION: 203,
  DOCUMENT_ENUM_HECM_FACE_TO_FACE_CERTIFICATION: 204,
  DOCUMENT_ENUM_HECM_FINANCIAL_ASSESSMENT_WORKSHEET: 205,
  DOCUMENT_ENUM_HECM_LOAN_SUBMISSION_SCHEDULE: 206,
  DOCUMENT_ENUM_HECM_NOTICE_TO_BORROWER: 207,
  DOCUMENT_ENUM_HECM_PAYMENT_PLAN: 208,
  DOCUMENT_ENUM_HERS_HOME_ENERGY_REPORT: 209,
  DOCUMENT_ENUM_HIGH_COST_WORKSHEET: 210,
  DOCUMENT_ENUM_HOLD_HARMLESS_AGREEMENT: 211,
  DOCUMENT_ENUM_HOME_BUYER_EDUCATION_CERTIFICATION: 212,
  DOCUMENT_ENUM_HOME_EQUITY_LINE_FREEZE_LETTER: 213,
  DOCUMENT_ENUM_HOMEOWNERS_ASSOCIATION_CERTIFICATION: 214,
  DOCUMENT_ENUM_HOUSING_COUNSELING_AGENCIES_LIST: 215,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT: 216,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT_IDENTITY_THEFT: 217,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT_NAME: 218,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT_SIGNATURE: 219,
  DOCUMENT_ENUM_IDENTITY_THEFT_DISCLOSURE: 220,
  DOCUMENT_ENUM_INCOMPLETE_APPLICATION_NOTICE: 221,
  DOCUMENT_ENUM_INDIVIDUAL_DEVELOPMENT_ACCOUNT_STATEMENT: 222,
  DOCUMENT_ENUM_INITIAL_ADJUSTABLE_RATE_MORTGAGE_DISCLOSURE: 223,
  DOCUMENT_ENUM_INITIAL_ESCROW_ACCOUNT_DISCLOSURE: 224,
  DOCUMENT_ENUM_INTEGRATED_DISCLOSURE: 225,
  DOCUMENT_ENUM_INTEREST_RATE_AND_DISCOUNT_DISCLOSURE_STATEMENT: 226,
  DOCUMENT_ENUM_INVOICE: 227,
  DOCUMENT_ENUM_INVOICE_UTILITY_BILL: 228,
  DOCUMENT_ENUM_IRS_1040: 229,
  DOCUMENT_ENUM_IRS_1041: 230,
  DOCUMENT_ENUM_IRS_1065: 231,
  DOCUMENT_ENUM_IRS_1098: 232,
  DOCUMENT_ENUM_IRS_1099: 233,
  DOCUMENT_ENUM_IRS_1099_A: 234,
  DOCUMENT_ENUM_IRS_1099_B: 235,
  DOCUMENT_ENUM_IRS_1099_C: 236,
  DOCUMENT_ENUM_IRS_1099_CAP: 237,
  DOCUMENT_ENUM_IRS_1099_DIV: 238,
  DOCUMENT_ENUM_IRS_1099_G: 239,
  DOCUMENT_ENUM_IRS_1099_H: 240,
  DOCUMENT_ENUM_IRS_1099_INT: 241,
  DOCUMENT_ENUM_IRS_1099_LTC: 242,
  DOCUMENT_ENUM_IRS_1099_MISC: 243,
  DOCUMENT_ENUM_IRS_1099_OID: 244,
  DOCUMENT_ENUM_IRS_1099_PATR: 245,
  DOCUMENT_ENUM_IRS_1099_Q: 246,
  DOCUMENT_ENUM_IRS_1099_R: 247,
  DOCUMENT_ENUM_IRS_1099_S: 248,
  DOCUMENT_ENUM_IRS_1099_SA: 249,
  DOCUMENT_ENUM_IRS_1120: 250,
  DOCUMENT_ENUM_IRS_1120_S: 251,
  DOCUMENT_ENUM_IRS_TAX_TRANSCRIPT: 252,
  DOCUMENT_ENUM_IRSW_2: 253,
  DOCUMENT_ENUM_IRSW_8: 254,
  DOCUMENT_ENUM_IRSW_9: 255,
  DOCUMENT_ENUM_ITEMIZATION_OF_AMOUNT_FINANCED: 256,
  DOCUMENT_ENUM_JUDGMENT: 257,
  DOCUMENT_ENUM_LAND_LEASEHOLD_AGREEMENT: 258,
  DOCUMENT_ENUM_LAST_WILL_AND_TESTAMENT: 259,
  DOCUMENT_ENUM_LEAD_HAZARD_INFORMATION: 260,
  DOCUMENT_ENUM_LEGAL_DESCRIPTION: 261,
  DOCUMENT_ENUM_LENDER_CORRESPONDENCE: 262,
  DOCUMENT_ENUM_LENDER_LATE_ENDORSEMENT_REQUEST_CERTIFICATION_LETTER: 263,
  DOCUMENT_ENUM_LIFE_INSURANCE_POLICY: 264,
  DOCUMENT_ENUM_LINE_ITEM_BUDGET: 265,
  DOCUMENT_ENUM_LOAN_APPLICATION: 266,
  DOCUMENT_ENUM_LOAN_APPLICATION_FNM_1009: 267,
  DOCUMENT_ENUM_LOAN_APPLICATION_URLA: 268,
  DOCUMENT_ENUM_LOAN_CLOSING_NOTICE: 269,
  DOCUMENT_ENUM_LOAN_ESTIMATE: 270,
  DOCUMENT_ENUM_LOAN_MODIFICATION_AFFIDAVIT: 271,
  DOCUMENT_ENUM_LOAN_MODIFICATION_AGREEMENT: 272,
  DOCUMENT_ENUM_LOAN_MODIFICATION_DENIAL_NOTICE: 273,
  DOCUMENT_ENUM_LOAN_MODIFICATION_REQUEST: 274,
  DOCUMENT_ENUM_LOAN_MODIFICATION_TRIAL_PAYMENT_PERIOD_AGREEMENT: 275,
  DOCUMENT_ENUM_LOAN_PAYOFF_REQUEST: 276,
  DOCUMENT_ENUM_LOAN_STATEMENT: 277,
  DOCUMENT_ENUM_LOAN_SUBMISSION_SUMMARY: 278,
  DOCUMENT_ENUM_MANUFACTURED_HOUSING_CERTIFICATE_OF_TITLE: 279,
  DOCUMENT_ENUM_MARITAL_STATUS_AFFIDAVIT: 280,
  DOCUMENT_ENUM_MARRIAGE_CERTIFICATE: 281,
  DOCUMENT_ENUM_MI_APPLICATION: 282,
  DOCUMENT_ENUM_MI_CERTIFICATE: 283,
  DOCUMENT_ENUM_MI_CONDITIONAL_COMMITMENT: 284,
  DOCUMENT_ENUM_MI_DECLINATION: 285,
  DOCUMENT_ENUM_MI_DISCLOSURE: 286,
  DOCUMENT_ENUM_MILITARY_DISCHARGE_PAPERS: 287,
  DOCUMENT_ENUM_MILITARY_IDENTIFICATION: 288,
  DOCUMENT_ENUM_MI_MODIFICATION: 289,
  DOCUMENT_ENUM_MI_RATE_QUOTE: 290,
  DOCUMENT_ENUM_MI_SUSPENSION: 291,
  DOCUMENT_ENUM_MONTHLY_SUMMARY_OF_ASSISTANCE_PAYMENTS: 292,
  DOCUMENT_ENUM_MORTGAGEES_CERTIFICATION_AND_APPLICATION_FOR_ASSISTANCE_OR_INTEREST_REDUCTION_PAYMENTS: 293,
  DOCUMENT_ENUM_NAME_AFFIDAVIT: 294,
  DOCUMENT_ENUM_NATIONAL_IDENTIFICATION: 295,
  DOCUMENT_ENUM_NATIONAL_IDENTIFICATION_CEDULA_DE_IDENTIDAD: 296,
  DOCUMENT_ENUM_NATIONAL_IDENTIFICATION_SOCIAL_SECURITY_CARD: 297,
  DOCUMENT_ENUM_NEAREST_LIVING_RELATIVE_INFORMATION: 298,
  DOCUMENT_ENUM_NEAREST_LIVING_RELATIVE_INFORMATION_ALTERNATIVE_CONTACT: 299,
  DOCUMENT_ENUM_NEAREST_LIVING_RELATIVE_INFORMATION_VA_QUESTIONNAIRE: 300,
  DOCUMENT_ENUM_NON_BORROWING_SPOUSE_CERTIFICATION_LETTER: 301,
  DOCUMENT_ENUM_NON_BORROWING_SPOUSE_CERTIFICATION_LETTER_ELIGIBLE: 302,
  DOCUMENT_ENUM_NON_BORROWING_SPOUSE_CERTIFICATION_LETTER_INELIGIBLE: 303,
  DOCUMENT_ENUM_NON_DIPLOMAT_VERIFICATION: 304,
  DOCUMENT_ENUM_NON_REFUNDABILITY_NOTICE: 305,
  DOCUMENT_ENUM_NOTE: 306,
  DOCUMENT_ENUM_NOTE_CONSOLIDATED: 307,
  DOCUMENT_ENUM_NOTE_HECM_LOAN_AGREEMENT: 308,
  DOCUMENT_ENUM_NOTE_NEW_MONEY: 309,
  DOCUMENT_ENUM_NOTE_ADDENDUM: 310,
  DOCUMENT_ENUM_NOTE_ADDENDUM_AFFORDABLE_MERIT_RATE: 311,
  DOCUMENT_ENUM_NOTE_ADDENDUM_ARBITRATION: 312,
  DOCUMENT_ENUM_NOTE_ADDENDUM_ARM: 313,
  DOCUMENT_ENUM_NOTE_ADDENDUM_BALLOON: 314,
  DOCUMENT_ENUM_NOTE_ADDENDUM_CONSTRUCTION: 315,
  DOCUMENT_ENUM_NOTE_ADDENDUM_FIXED_RATE_OPTION: 316,
  DOCUMENT_ENUM_NOTE_ADDENDUM_GEM: 317,
  DOCUMENT_ENUM_NOTE_ADDENDUM_GPM: 318,
  DOCUMENT_ENUM_NOTE_ADDENDUM_INTEREST_ONLY: 319,
  DOCUMENT_ENUM_NOTE_ADDENDUM_INTER_VIVOS_REVOCABLE_TRUST: 320,
  DOCUMENT_ENUM_NOTE_ADDENDUM_OCCUPANCY_DECLARATION: 321,
  DOCUMENT_ENUM_NOTE_ADDENDUM_PREPAYMENT: 322,
  DOCUMENT_ENUM_NOTE_ADDENDUM_RATE_IMPROVEMENT: 323,
  DOCUMENT_ENUM_NOTE_ALLONGE: 324,
  DOCUMENT_ENUM_NOTE_AND_SECURITY_INSTRUMENT_MODIFICATION: 325,
  DOCUMENT_ENUM_NOTE_MODIFICATION: 326,
  DOCUMENT_ENUM_NOTE_RIDER: 327,
  DOCUMENT_ENUM_NOTE_RIDER_ARM: 328,
  DOCUMENT_ENUM_NOTE_RIDER_BALLOON: 329,
  DOCUMENT_ENUM_NOTE_RIDER_BUYDOWN: 330,
  DOCUMENT_ENUM_NOTE_RIDER_HECM_REPAIR: 331,
  DOCUMENT_ENUM_NOTE_RIDER_OCCUPANCY: 332,
  DOCUMENT_ENUM_NOTE_RIDER_PREPAYMENT: 333,
  DOCUMENT_ENUM_NOTE_RIDER_STEP_RATE: 334,
  DOCUMENT_ENUM_NOTICE_OF_ACTION_TAKEN: 335,
  DOCUMENT_ENUM_NOTICE_OF_COMPLETION: 336,
  DOCUMENT_ENUM_NOTICE_OF_DEFAULT: 337,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL: 338,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_ADD_SECURITY_INTEREST: 339,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_CREDIT_INCREASE: 340,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_INCREASE_SECURITY: 341,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_OPEN_ACCOUNT: 342,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_COPY_OF_APPRAISAL_REPORT: 343,
  DOCUMENT_ENUM_NOTICE_TO_HOMEBUYER: 344,
  DOCUMENT_ENUM_NOTICE_TO_LENDER: 345,
  DOCUMENT_ENUM_OCCUPANCY_AGREEMENT: 346,
  DOCUMENT_ENUM_OCCUPANCY_CERTIFICATION: 347,
  DOCUMENT_ENUM_OTHER: 348,
  DOCUMENT_ENUM_PARTIAL_RELEASE_OF_SECURITY_INSTRUMENT: 349,
  DOCUMENT_ENUM_PARTNERSHIP_AGREEMENT: 350,
  DOCUMENT_ENUM_PASSPORT: 351,
  DOCUMENT_ENUM_PAYMENT_HISTORY: 352,
  DOCUMENT_ENUM_PAYMENT_LETTER: 353,
  DOCUMENT_ENUM_PAYOFF_STATEMENT: 354,
  DOCUMENT_ENUM_PAY_STUB: 355,
  DOCUMENT_ENUM_PERMANENT_RESIDENT_IDENTIFICATION: 356,
  DOCUMENT_ENUM_PERSONAL_PROPERTY_APPRAISAL_REPORT: 357,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY: 358,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY_DURABLE: 359,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY_LIMITED: 360,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY_MANUFACTURED_HOUSING: 361,
  DOCUMENT_ENUM_PREPAYMENT_CHARGE_OPTION_NOTICE: 362,
  DOCUMENT_ENUM_PREQUALIFICATION_LETTER: 363,
  DOCUMENT_ENUM_PRIMARY_MI_ANNUAL_DISCLOSURE: 364,
  DOCUMENT_ENUM_PRIVACY_DISCLOSURE: 365,
  DOCUMENT_ENUM_PRIVATE_IDENTIFICATION: 366,
  DOCUMENT_ENUM_PRODUCT_COMPARISON_DISCLOSURE: 367,
  DOCUMENT_ENUM_PROOF_OF_HUD_WAIVER_CAIVRS_CLEARANCE: 368,
  DOCUMENT_ENUM_PROOF_OF_MONETARY_INVESTMENT: 369,
  DOCUMENT_ENUM_PROOF_OF_RESIDENCE_IN_DECLARED_MAJOR_DISASTER_AREA: 370,
  DOCUMENT_ENUM_PROOF_OF_TAX_DEFERRAL_OR_EXEMPTION: 371,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT: 372,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_CARPET: 373,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_ENVIRONMENTAL: 374,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_FOOTINGS: 375,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_FRAMING: 376,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_HEATING: 377,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_INSULATION: 378,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_PEST: 379,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_PLUMBING: 380,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_ROOF: 381,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SEPTIC: 382,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SOIL: 383,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SOIL_TREATMENT: 384,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_STRUCTURAL_ENGINEERING: 385,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SUBTERRANEAN_TERMITE: 386,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_TERMITE: 387,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_WATER_HEALTH: 388,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_WELL: 389,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER: 390,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_EARTHQUAKE: 391,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_FIRE_AND_EXTENDED_COVERAGE: 392,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_FLOOD: 393,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_HAZARD: 394,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_HOMEOWNERS: 395,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_HURRICANE: 396,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_INSECT_INFESTATION: 397,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_LEASEHOLD: 398,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_MINE_SUBSIDENCE: 399,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_PERSONAL_PROPERTY: 400,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_STORM: 401,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_TORNADO: 402,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_VOLCANO: 403,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_WIND: 404,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS: 405,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_EARTHQUAKE: 406,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_FIRE_AND_EXTENDED_COVERAGE: 407,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_FLOOD: 408,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_HAZARD: 409,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_HOMEOWNERS: 410,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_HURRICANE: 411,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_INSECT_INFESTATION: 412,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_LEASEHOLD: 413,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_MINE_SUBSIDENCE: 414,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_PERSONAL_PROPERTY: 415,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_STORM: 416,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_TORNADO: 417,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_VOLCANO: 418,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_WIND: 419,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY: 420,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_EARTHQUAKE: 421,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_FIRE_AND_EXTENDED_COVERAGE: 422,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_FLOOD: 423,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_HAZARD: 424,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_HOMEOWNERS: 425,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_HURRICANE: 426,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_INSECT_INFESTATION: 427,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_LEASEHOLD: 428,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_MINE_SUBSIDENCE: 429,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_PERSONAL_PROPERTY: 430,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_STORM: 431,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_TORNADO: 432,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_VOLCANO: 433,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_WIND: 434,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_REQUIREMENT_DISCLOSURE: 435,
  DOCUMENT_ENUM_PURCHASE_AGREEMENT: 436,
  DOCUMENT_ENUM_RATE_CHANGE_NOTICE: 437,
  DOCUMENT_ENUM_RATE_LOCK_AGREEMENT: 438,
  DOCUMENT_ENUM_RATE_LOCK_AGREEMENT_BUSINESS_TO_BUSINESS: 439,
  DOCUMENT_ENUM_RATE_LOCK_AGREEMENT_BUSINESS_TO_CONSUMER: 440,
  DOCUMENT_ENUM_REAFFIRMATION_AGREEMENT: 441,
  DOCUMENT_ENUM_RECEIPT: 442,
  DOCUMENT_ENUM_RECERTIFICATION_OF_FAMILY_INCOME_AND_COMPOSITION: 443,
  DOCUMENT_ENUM_RECERTIFICATION_OF_FAMILY_INCOME_STATISTICAL_REPORT: 444,
  DOCUMENT_ENUM_RECONVEYANCE: 445,
  DOCUMENT_ENUM_REFINANCE_COST_ESTIMATE_WORKSHEET: 446,
  DOCUMENT_ENUM_REFINANCE_COST_ESTIMATE_WORKSHEET_HECM: 447,
  DOCUMENT_ENUM_RELOCATION_BENEFITS_PACKAGE: 448,
  DOCUMENT_ENUM_RELOCATION_BUYOUT_AGREEMENT: 449,
  DOCUMENT_ENUM_REMITTANCE_DELIVERY_RECEIPT: 450,
  DOCUMENT_ENUM_RENTAL_AGREEMENT: 451,
  DOCUMENT_ENUM_RENTAL_INCOME_ANALYSIS_STATEMENT: 452,
  DOCUMENT_ENUM_REPAYMENT_PLAN_AGREEMENT: 453,
  DOCUMENT_ENUM_REQUEST_FOR_AGREEMENT_OF_SHORT_SALE: 454,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN: 455,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN_IRS_4506: 456,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN_IRS_4506_T: 457,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN_IRS_4506_TEZ: 458,
  DOCUMENT_ENUM_REQUEST_FOR_NOTICE_OF_DEFAULT: 459,
  DOCUMENT_ENUM_RESIDUAL_INCOME_ANALYSIS_WORKSHEET: 460,
  DOCUMENT_ENUM_RESOLUTION_OF_CONSUMER_COMPLAINT: 461,
  DOCUMENT_ENUM_RETIREMENT_ACCOUNT_STATEMENT: 462,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE: 463,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_ACCOUNT_REVIEW_NOTICE: 464,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_CREDIT_SCORE_DISCLOSURE_EXCEPTION: 465,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_CREDIT_SCORE_DISCLOSURE_NO_RESIDENTIAL_SECURED: 466,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_CREDIT_SCORE_DISCLOSURE_NO_SCORE: 467,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_GENERAL_NOTICE: 468,
  DOCUMENT_ENUM_ROAD_MAINTENANCE_AGREEMENT: 469,
  DOCUMENT_ENUM_SATISFACTION_OF_JUDGMENT: 470,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT: 471,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT_LIEN_RELEASE: 472,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT_SATISFACTION_OF_DEED_OF_TRUST: 473,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT_SATISFACTION_OF_MORTGAGE: 474,
  DOCUMENT_ENUM_SCRA_NOTICE_DISCLOSURE: 475,
  DOCUMENT_ENUM_SECTION32_DISCLOSURE_NOTICE: 476,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT: 477,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_DEED_OF_TRUST: 478,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MORTGAGE: 479,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_ADDENDUM: 480,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_ADDENDUM_FIXED_RATE_OPTION: 481,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION: 482,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION_CONSOLIDATION_AGREEMENT: 483,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION_CONSOLIDATION_EXTENSION_MODIFICATION_AGREEMENT: 484,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION_MODIFICATION_AGREEMENT: 485,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER: 486,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_AFFORDABLE_MERIT_RATE: 487,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_ARM: 488,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_BALLOON: 489,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_BENEFICIARY: 490,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_BIWEEKLY: 491,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_CONDOMINIUM: 492,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_CONSTRUCTION: 493,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_GEM: 494,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_GPM: 495,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_HOMESTEAD_EXEMPTION: 496,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_INTEREST_ONLY: 497,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_INTER_VIVOS_REVOCABLE_TRUST: 498,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_INVESTOR: 499,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_LAND_TRUST: 500,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_LEASEHOLD: 501,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_MANUFACTURED_HOUSING: 502,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_MERS_REGISTRY: 503,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_NON_OWNER: 504,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_ONE_TO_FOUR_FAMILY: 505,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_OWNER_OCCUPANCY: 506,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_PREPAYMENT: 507,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_PUD: 508,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_RATE_IMPROVEMENT: 509,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_REHABILITATION: 510,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_RENEWAL_AND_EXTENSION: 511,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_SECOND_HOME: 512,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_SECOND_LIEN: 513,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_TAX_EXEMPT_FINANCING: 514,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_VA: 515,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_VETERANS_LAND_BOARD: 516,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_WAIVER_OF_BORROWERS_RIGHTS: 517,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_WAIVER_OF_DOWER_RIGHTS: 518,
  DOCUMENT_ENUM_SELLER_LEAD_HAZARD_DISCLOSURE_AND_ACKNOWLEDGMENT: 519,
  DOCUMENT_ENUM_SERVICING_DISCLOSURE_STATEMENT: 520,
  DOCUMENT_ENUM_SERVICING_TRANSFER_STATEMENT: 521,
  DOCUMENT_ENUM_SETTLEMENT_STATEMENT: 522,
  DOCUMENT_ENUM_SETTLEMENT_STATEMENT_HUD_1: 523,
  DOCUMENT_ENUM_SETTLEMENT_STATEMENT_HUD_1_A: 524,
  DOCUMENT_ENUM_SHORT_SALE_AGREEMENT: 525,
  DOCUMENT_ENUM_SHORT_SALE_PROCESS_CHECKLIST: 526,
  DOCUMENT_ENUM_SOCIAL_SECURITY_AWARD_LETTER: 527,
  DOCUMENT_ENUM_SOLICITATION_LETTER: 528,
  DOCUMENT_ENUM_STANDARD_FLOOD_HAZARD_DETERMINATION: 529,
  DOCUMENT_ENUM_STATE_IDENTIFICATION: 530,
  DOCUMENT_ENUM_STATEMENT_OF_BORROWER_BENEFIT: 531,
  DOCUMENT_ENUM_STOCK_CERTIFICATE: 532,
  DOCUMENT_ENUM_SUBORDINATION_AGREEMENT: 533,
  DOCUMENT_ENUM_SUBSIDY_AGREEMENT: 534,
  DOCUMENT_ENUM_SUBSTITUTION_OF_TRUSTEE: 535,
  DOCUMENT_ENUM_SURVEY: 536,
  DOCUMENT_ENUM_SURVEY_AFFIDAVIT: 537,
  DOCUMENT_ENUM_TAX_ASSESSMENT_VALUE_INDICATION: 538,
  DOCUMENT_ENUM_TAX_AUTHORIZATION: 539,
  DOCUMENT_ENUM_TAX_CERTIFICATE: 540,
  DOCUMENT_ENUM_TAX_LIEN: 541,
  DOCUMENT_ENUM_TAX_LIEN_FEDERAL: 542,
  DOCUMENT_ENUM_TAX_LIEN_LOCAL: 543,
  DOCUMENT_ENUM_TAX_LIEN_STATE: 544,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE: 545,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE_FEDERAL: 546,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE_LOCAL: 547,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE_STATE: 548,
  DOCUMENT_ENUM_TAXPAYER_IDENTIFICATION: 549,
  DOCUMENT_ENUM_TAXPAYER_IDENTIFICATION_NUMERO_DE_IDENTIFICACION_TRIBUTARIA: 550,
  DOCUMENT_ENUM_TAX_RETURN: 551,
  DOCUMENT_ENUM_TEN_YEAR_WARRANTY: 552,
  DOCUMENT_ENUM_THIRD_PARTY_EMPLOYMENT_STATEMENT: 553,
  DOCUMENT_ENUM_TIL_DISCLOSURE: 554,
  DOCUMENT_ENUM_TITLE_ABSTRACT: 555,
  DOCUMENT_ENUM_TITLE_COMMITMENT: 556,
  DOCUMENT_ENUM_TITLE_INSURANCE_ENDORSEMENT: 557,
  DOCUMENT_ENUM_TITLE_INSURANCE_POLICY: 558,
  DOCUMENT_ENUM_TRIAL_LOAN_MODIFICATION_AGREEMENT: 559,
  DOCUMENT_ENUM_TRIAL_PERIOD_PLAN_NOTICE: 560,
  DOCUMENT_ENUM_TRUST_AGREEMENT: 561,
  DOCUMENT_ENUM_UCC_1_STATEMENT: 562,
  DOCUMENT_ENUM_UCC_3_STATEMENT: 563,
  DOCUMENT_ENUM_UNDERWRITING_CONDITIONS: 564,
  DOCUMENT_ENUM_UNDERWRITING_CONDITIONS_MI_APPLICATION: 565,
  DOCUMENT_ENUM_UNDERWRITING_TRANSMITTAL: 566,
  DOCUMENT_ENUM_UNUSED_ESCROW_FUNDS_DISTRIBUTION_AUTHORIZATION: 567,
  DOCUMENT_ENUM_UTILITY_BILL: 568,
  DOCUMENT_ENUM_VA_ACKNOWLEDGMENT_OF_NO_INSPECTION: 569,
  DOCUMENT_ENUM_VA_CERTIFICATE_OF_ELIGIBILITY: 570,
  DOCUMENT_ENUM_VA_CERTIFICATE_OF_REASONABLE_VALUE: 571,
  DOCUMENT_ENUM_VA_COLLECTION_POLICY_NOTICE: 572,
  DOCUMENT_ENUM_VA_FORECLOSURE_BID_LETTER: 573,
  DOCUMENT_ENUM_VA_FUNDING_FEE_NOTICE: 574,
  DOCUMENT_ENUM_VA_INTEREST_RATE_REDUCTION_REFINANCING_LOAN_WORKSHEET: 575,
  DOCUMENT_ENUM_VA_LOAN_ANALYSIS: 576,
  DOCUMENT_ENUM_VA_LOAN_SUMMARY_SHEET: 577,
  DOCUMENT_ENUM_VALUATION_UPDATE: 578,
  DOCUMENT_ENUM_VA_RATE_REDUCTION_REFINANCE_INFORMATION_AND_AGREEMENT: 579,
  DOCUMENT_ENUM_VA_REPORT_AND_CERTIFICATION_OF_LOAN_DISBURSEMENT: 580,
  DOCUMENT_ENUM_VA_REQUEST_FOR_CERTIFICATION_OF_ELIGIBILITY_FOR_HOME_LOAN_BENEFIT: 581,
  DOCUMENT_ENUM_VA_VERIFICATION_OF_BENEFIT_RELATED_INDEBTEDNESS: 582,
  DOCUMENT_ENUM_VERIFICATION_OF_ADDRESS: 583,
  DOCUMENT_ENUM_VERIFICATION_OF_CREDIT: 584,
  DOCUMENT_ENUM_VERIFICATION_OF_DEBT: 585,
  DOCUMENT_ENUM_VERIFICATION_OF_DEPENDENT_CARE: 586,
  DOCUMENT_ENUM_VERIFICATION_OF_DEPOSIT: 587,
  DOCUMENT_ENUM_VERIFICATION_OF_EMPLOYMENT: 588,
  DOCUMENT_ENUM_VERIFICATION_OF_MERS_REGISTRY: 589,
  DOCUMENT_ENUM_VERIFICATION_OF_MORTGAGE_OR_RENT: 590,
  DOCUMENT_ENUM_VERIFICATION_OF_SECURITIES: 591,
  DOCUMENT_ENUM_VERIFICATION_OF_SSN: 592,
  DOCUMENT_ENUM_VISA: 593,
  DOCUMENT_ENUM_VOLUNTEER_ESCROW_PREPAYMENT_DESIGNATION: 594,
  DOCUMENT_ENUM_VOTER_REGISTRATION: 595,
  DOCUMENT_ENUM_WARRANTY_OF_COMPLETION_OF_CONSTRUCTION: 596,
  DOCUMENT_ENUM_WIRE_INSTRUCTIONS: 597,
  DOCUMENT_ENUM_WIRE_TRANSFER_AUTHORIZATION: 598,
  DOCUMENT_ENUM_WIRE_TRANSFER_CONFIRMATION: 599,
  DOCUMENT_ENUM_WORKSHEET: 600,
  DOCUMENT_ENUM_FRAUDGUARD: 601
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DocumentStatusEnum = {
  DOCUMENT_STATUS_ENUM_UNKNOWN: 0,
  DOCUMENT_STATUS_ENUM_NEEDED: 1,
  DOCUMENT_STATUS_ENUM_REQUESTED: 2,
  DOCUMENT_STATUS_ENUM_RECEIVED: 3,
  DOCUMENT_STATUS_ENUM_REVIEWED: 4,
  DOCUMENT_STATUS_ENUM_EXPIRED: 5,
  DOCUMENT_STATUS_ENUM_NOT_USED: 6,
  DOCUMENT_STATUS_ENUM_VALIDATED: 7,
  DOCUMENT_STATUS_ENUM_REJECTED: 8,
  DOCUMENT_STATUS_ENUM_ARCHIVED: 9
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ComplianceTestEnum = {
  COMPLIANCE_TEST_ENUM_UNKNOWN: 0,
  COMPLIANCE_TEST_ENUM_HOEPA: 1,
  COMPLIANCE_TEST_ENUM_NMLS: 2,
  COMPLIANCE_TEST_ENUM_TRID: 3,
  COMPLIANCE_TEST_ENUM_STATE_HIGH_COST: 4,
  COMPLIANCE_TEST_ENUM_FEDERAL_HPM: 5,
  COMPLIANCE_TEST_ENUM_TILA: 6,
  COMPLIANCE_TEST_ENUM_QUALIFIED_MORTGAGE: 7,
  COMPLIANCE_TEST_ENUM_TEXAS_HOME_EQUITY: 8,
  COMPLIANCE_TEST_ENUM_TEXAS50F2: 9,
  COMPLIANCE_TEST_ENUM_NEW_YORK_SUB_PRIME: 10,
  COMPLIANCE_TEST_ENUM_CALIFORNIA_HPML: 11,
  COMPLIANCE_TEST_ENUM_RESPA: 12,
  COMPLIANCE_TEST_ENUM_STATE_SPECIFIC: 13,
  COMPLIANCE_TEST_ENUM_USDA: 14,
  COMPLIANCE_TEST_ENUM_FHA: 15,
  COMPLIANCE_TEST_ENUM_VA: 16,
  COMPLIANCE_TEST_ENUM_FREDDIE_MAC: 17,
  COMPLIANCE_TEST_ENUM_FANNIE_MAE: 18,
  COMPLIANCE_TEST_ENUM_FLOOD_INSURANCE: 19,
  COMPLIANCE_TEST_ENUM_COOK_COUNTY: 20,
  COMPLIANCE_TEST_ENUM_OFAC: 21,
  COMPLIANCE_TEST_ENUM_CONNECTICUT_NON_PRIME: 22,
  COMPLIANCE_TEST_ENUM_CHICAGO: 23,
  COMPLIANCE_TEST_ENUM_ALL: 24,
  COMPLIANCE_TEST_ENUM_CLEVELAND_HEIGHTS: 25,
  COMPLIANCE_TEST_ENUM_KENTON: 26
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ComplianceTestSourceEnum = {
  COMPLIANCE_TEST_SOURCE_ENUM_UNKNOWN: 0,
  COMPLIANCE_TEST_SOURCE_ENUM_DOCUTECH_PREDCHECK: 1,
  COMPLIANCE_TEST_SOURCE_ENUM_FACTUALDATA_DRIVE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ComplianceTestSuitePurposeEnum = {
  COMPLIANCE_TEST_SUITE_PURPOSE_ENUM_UNKNOWN: 0,
  COMPLIANCE_TEST_SUITE_PURPOSE_ENUM_COMPLIANCE: 1,
  COMPLIANCE_TEST_SUITE_PURPOSE_ENUM_FRAUD: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ComplianceTestStatusEnum = {
  COMPLIANCE_TEST_STATUS_ENUM_UNKNOWN: 0,
  COMPLIANCE_TEST_STATUS_ENUM_IN_PROGRESS: 1,
  COMPLIANCE_TEST_STATUS_ENUM_PASS: 2,
  COMPLIANCE_TEST_STATUS_ENUM_FAIL: 3,
  COMPLIANCE_TEST_STATUS_ENUM_DATA_NEEDED: 4,
  COMPLIANCE_TEST_STATUS_ENUM_NOT_APPLICABLE: 5,
  COMPLIANCE_TEST_STATUS_ENUM_WARNING: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.UxDisplayStatusEnum = {
  UX_DISPLAY_STATUS_ENUM_NEW: 0,
  UX_DISPLAY_STATUS_ENUM_PROGRESS: 1,
  UX_DISPLAY_STATUS_ENUM_SUCCESS: 2,
  UX_DISPLAY_STATUS_ENUM_DANGER: 3,
  UX_DISPLAY_STATUS_ENUM_RESTING: 4,
  UX_DISPLAY_STATUS_ENUM_COMPLETE: 5,
  UX_DISPLAY_STATUS_ENUM_WARNING: 6,
  UX_DISPLAY_STATUS_ENUM_CRITICAL: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProgressItemStatusEnum = {
  PROGRESS_ITEM_STATUS_ENUM_UNKNOWN: 0,
  PROGRESS_ITEM_STATUS_ENUM_NOT_STARTED: 1,
  PROGRESS_ITEM_STATUS_ENUM_IN_PROGRESS: 3,
  PROGRESS_ITEM_STATUS_ENUM_COMPLETED: 4,
  PROGRESS_ITEM_STATUS_ENUM_NEEDS_REVIEW: 5,
  PROGRESS_ITEM_STATUS_ENUM_VALIDATED: 6,
  PROGRESS_ITEM_STATUS_ENUM_EXPIRED: 7,
  PROGRESS_ITEM_STATUS_ENUM_CANCELED: 8,
  PROGRESS_ITEM_STATUS_ENUM_NOT_APPLICABLE: 9,
  PROGRESS_ITEM_STATUS_ENUM_LO_COMPLETED: 10,
  PROGRESS_ITEM_STATUS_ENUM_READY_FOR_UW: 11,
  PROGRESS_ITEM_STATUS_ENUM_UW_APPROVED: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AmortizationEnum = {
  AMORTIZATION_ENUM_UNKNOWN: 0,
  AMORTIZATION_ENUM_ADJUSTABLE_RATE: 1,
  AMORTIZATION_ENUM_FIXED: 2,
  AMORTIZATION_ENUM_GEM: 3,
  AMORTIZATION_ENUM_GPM: 4,
  AMORTIZATION_ENUM_GRADUATED_PAYMENT_ARM: 5,
  AMORTIZATION_ENUM_OTHER: 6,
  AMORTIZATION_ENUM_RATE_IMPROVEMENT_MORTGAGE: 7,
  AMORTIZATION_ENUM_STEP: 8
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanPhaseEnum = {
  LOAN_PHASE_ENUM_UNKNOWN: 0,
  LOAN_PHASE_ENUM_PRE_QUALIFICATION: 1,
  LOAN_PHASE_ENUM_APPLICATION: 2,
  LOAN_PHASE_ENUM_CLOSED: 4,
  LOAN_PHASE_ENUM_DECISIONED: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeeEnum = {
  FEE_ENUM_UNKNOWN: 0,
  FEE_ENUM_203_K_CONSULTANT_FEE: 1,
  FEE_ENUM_203_K_DISCOUNT_ON_REPAIRS: 2,
  FEE_ENUM_203_K_INSPECTION_FEE: 3,
  FEE_ENUM_203_K_PERMITS: 4,
  FEE_ENUM_203_K_SUPPLEMENTAL_ORIGINATION_FEE: 5,
  FEE_ENUM_203_K_TITLE_UPDATE: 6,
  FEE_ENUM_APPLICATION_FEE: 7,
  FEE_ENUM_APPRAISAL_DESK_REVIEW_FEE: 8,
  FEE_ENUM_APPRAISAL_FEE: 9,
  FEE_ENUM_APPRAISAL_FIELD_REVIEW_FEE: 10,
  FEE_ENUM_APPRAISAL_MANAGEMENT_COMPANY_FEE: 11,
  FEE_ENUM_ARM_CONVERSION_FEE: 12,
  FEE_ENUM_ASBESTOS_INSPECTION_FEE: 13,
  FEE_ENUM_ASSIGNMENT_PREPARATION_FEE: 14,
  FEE_ENUM_ASSUMPTION_FEE: 15,
  FEE_ENUM_ATTORNEY_FEE: 16,
  FEE_ENUM_AUTOMATED_UNDERWRITING_FEE: 17,
  FEE_ENUM_AVM_FEE: 18,
  FEE_ENUM_BANKRUPTCY_MONITORING_FEE: 19,
  FEE_ENUM_BOND_FEE: 20,
  FEE_ENUM_BOND_REVIEW_FEE: 21,
  FEE_ENUM_CERTIFICATION_FEE: 22,
  FEE_ENUM_CHOSEN_INTEREST_RATE_CREDIT_OR_CHARGE_TOTAL: 23,
  FEE_ENUM_COMMITMENT_FEE: 24,
  FEE_ENUM_CONDOMINIUM_ASSOCIATION_DUES: 25,
  FEE_ENUM_CONDOMINIUM_ASSOCIATION_SPECIAL_ASSESSMENT: 26,
  FEE_ENUM_CONSTRUCTION_HANDLING_FEE: 27,
  FEE_ENUM_CONSTRUCTION_INSPECTION_FEE: 28,
  FEE_ENUM_COOPERATIVE_ASSOCIATION_DUES: 29,
  FEE_ENUM_COOPERATIVE_ASSOCIATION_SPECIAL_ASSESSMENT: 30,
  FEE_ENUM_COPY_OR_FAX_FEE: 31,
  FEE_ENUM_COURIER_FEE: 32,
  FEE_ENUM_CREDIT_DISABILITY_INSURANCE_PREMIUM: 33,
  FEE_ENUM_CREDIT_LIFE_INSURANCE_PREMIUM: 34,
  FEE_ENUM_CREDIT_PROPERTY_INSURANCE_PREMIUM: 35,
  FEE_ENUM_CREDIT_REPORT_FEE: 36,
  FEE_ENUM_CREDIT_UNEMPLOYMENT_INSURANCE_PREMIUM: 37,
  FEE_ENUM_DEBT_CANCELLATION_INSURANCE_PREMIUM: 38,
  FEE_ENUM_DEBT_SUSPENSION_INSURANCE_PREMIUM: 39,
  FEE_ENUM_DEED_PREPARATION_FEE: 40,
  FEE_ENUM_DISASTER_INSPECTION_FEE: 41,
  FEE_ENUM_DISCOUNT_ON_REPAIRS_FEE: 42,
  FEE_ENUM_DOCUMENTARY_STAMP_FEE: 43,
  FEE_ENUM_DOCUMENT_PREPARATION_FEE: 44,
  FEE_ENUM_DOWN_PAYMENT_PROTECTION_FEE: 45,
  FEE_ENUM_DRY_WALL_INSPECTION_FEE: 46,
  FEE_ENUM_ELECTRICAL_INSPECTION_FEE: 47,
  FEE_ENUM_ELECTRONIC_DOCUMENT_DELIVERY_FEE: 48,
  FEE_ENUM_ENVIRONMENTAL_INSPECTION_FEE: 49,
  FEE_ENUM_ESCROW_HOLDBACK_FEE: 50,
  FEE_ENUM_ESCROW_SERVICE_FEE: 51,
  FEE_ENUM_ESCROW_WAIVER_FEE: 52,
  FEE_ENUM_FILING_FEE: 53,
  FEE_ENUM_FLOOD_CERTIFICATION: 54,
  FEE_ENUM_FOUNDATION_INSPECTION_FEE: 55,
  FEE_ENUM_HEATING_COOLING_INSPECTION_FEE: 56,
  FEE_ENUM_HELOC_ANNUAL_FEE: 57,
  FEE_ENUM_HELOC_OVER_LIMIT_FEE: 58,
  FEE_ENUM_HIGH_COST_MORTGAGE_COUNSELING_FEE: 59,
  FEE_ENUM_HOME_INSPECTION_FEE: 60,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_DUES: 61,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_SERVICE_FEE: 62,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_SPECIAL_ASSESSMENT: 63,
  FEE_ENUM_HOME_WARRANTY_FEE: 64,
  FEE_ENUM_LATE_CHARGE: 65,
  FEE_ENUM_LEAD_INSPECTION_FEE: 66,
  FEE_ENUM_LENDERS_ATTORNEY_FEE: 67,
  FEE_ENUM_LOAN_DISCOUNT_POINTS: 68,
  FEE_ENUM_LOAN_LEVEL_PRICE_ADJUSTMENT: 69,
  FEE_ENUM_LOAN_ORIGINATION_FEE: 70,
  FEE_ENUM_LOAN_ORIGINATOR_COMPENSATION: 71,
  FEE_ENUM_MANUAL_UNDERWRITING_FEE: 72,
  FEE_ENUM_MANUFACTURED_HOUSING_INSPECTION_FEE: 73,
  FEE_ENUM_MANUFACTURED_HOUSING_PROCESSING_FEE: 74,
  FEE_ENUM_MERS_REGISTRATION_FEE: 75,
  FEE_ENUM_MI_INITIAL_PREMIUM: 76,
  FEE_ENUM_MI_UPFRONT_PREMIUM: 77,
  FEE_ENUM_MODIFICATION_FEE: 78,
  FEE_ENUM_MOLD_INSPECTION_FEE: 79,
  FEE_ENUM_MORTGAGE_BROKER_FEE: 80,
  FEE_ENUM_MORTGAGE_SURCHARGE_COUNTY_OR_PARISH: 81,
  FEE_ENUM_MORTGAGE_SURCHARGE_MUNICIPAL: 82,
  FEE_ENUM_MORTGAGE_SURCHARGE_STATE: 83,
  FEE_ENUM_MORTGAGE_TAX_CREDIT_SERVICE_FEE: 84,
  FEE_ENUM_MULTIPLE_LOANS_CLOSING_FEE: 85,
  FEE_ENUM_MUNICIPAL_LIEN_CERTIFICATE_FEE: 86,
  FEE_ENUM_NOTARY_FEE: 87,
  FEE_ENUM_NSF_FEE: 88,
  FEE_ENUM_OTHER: 89,
  FEE_ENUM_OUR_ORIGINATION_CHARGE_TOTAL: 90,
  FEE_ENUM_PARTIAL_LIEN_RELEASE_FEE: 91,
  FEE_ENUM_PAYOFF_REQUEST_FEE: 92,
  FEE_ENUM_PEST_INSPECTION_FEE: 93,
  FEE_ENUM_PLUMBING_INSPECTION_FEE: 94,
  FEE_ENUM_POWER_OF_ATTORNEY_PREPARATION_FEE: 95,
  FEE_ENUM_POWER_OF_ATTORNEY_RECORDING_FEE: 96,
  FEE_ENUM_PRECLOSING_VERIFICATION_CONTROL_FEE: 97,
  FEE_ENUM_PROCESSING_FEE: 98,
  FEE_ENUM_PROGRAM_GUARANTEE_FEE: 99,
  FEE_ENUM_PROPERTY_INSPECTION_WAIVER_FEE: 100,
  FEE_ENUM_RADON_INSPECTION_FEE: 101,
  FEE_ENUM_RATE_LOCK_FEE: 102,
  FEE_ENUM_REAL_ESTATE_COMMISSION_BUYERS_BROKER: 103,
  FEE_ENUM_REAL_ESTATE_COMMISSION_SELLERS_BROKER: 104,
  FEE_ENUM_RECAST_FEE: 105,
  FEE_ENUM_RECONVEYANCE_FEE: 106,
  FEE_ENUM_RECONVEYANCE_TRACKING_FEE: 107,
  FEE_ENUM_RECORDING_FEE_FOR_ASSIGNMENT: 108,
  FEE_ENUM_RECORDING_FEE_FOR_DEED: 109,
  FEE_ENUM_RECORDING_FEE_FOR_MORTGAGE: 110,
  FEE_ENUM_RECORDING_FEE_FOR_MUNICIPAL_LIEN_CERTIFICATE: 111,
  FEE_ENUM_RECORDING_FEE_FOR_OTHER_DOCUMENT: 112,
  FEE_ENUM_RECORDING_FEE_FOR_RELEASE: 113,
  FEE_ENUM_RECORDING_FEE_FOR_SUBORDINATION: 114,
  FEE_ENUM_RECORDING_FEE_TOTAL: 115,
  FEE_ENUM_RECORDING_SERVICE_FEE: 116,
  FEE_ENUM_REDRAW_FEE: 117,
  FEE_ENUM_REINSPECTION_FEE: 118,
  FEE_ENUM_RENOVATION_CONSULTANT_FEE: 119,
  FEE_ENUM_REPAIRS_FEE: 120,
  FEE_ENUM_ROOF_INSPECTION_FEE: 121,
  FEE_ENUM_SEPTIC_INSPECTION_FEE: 122,
  FEE_ENUM_SETTLEMENT_FEE: 123,
  FEE_ENUM_SIGNING_AGENT_FEE: 124,
  FEE_ENUM_SMOKE_DETECTOR_INSPECTION_FEE: 125,
  FEE_ENUM_STATE_TITLE_INSURANCE_FEE: 126,
  FEE_ENUM_STRUCTURAL_INSPECTION_FEE: 127,
  FEE_ENUM_SUBORDINATION_FEE: 128,
  FEE_ENUM_SURVEY_FEE: 129,
  FEE_ENUM_TAX_SERVICE_FEE: 130,
  FEE_ENUM_TAX_STAMP_FOR_CITY_DEED: 131,
  FEE_ENUM_TAX_STAMP_FOR_CITY_MORTGAGE: 132,
  FEE_ENUM_TAX_STAMP_FOR_COUNTY_DEED: 133,
  FEE_ENUM_TAX_STAMP_FOR_COUNTY_MORTGAGE: 134,
  FEE_ENUM_TAX_STAMP_FOR_STATE_DEED: 135,
  FEE_ENUM_TAX_STAMP_FOR_STATE_MORTGAGE: 136,
  FEE_ENUM_TAX_STATUS_RESEARCH_FEE: 137,
  FEE_ENUM_TEMPORARY_BUYDOWN_ADMINISTRATION_FEE: 138,
  FEE_ENUM_TEMPORARY_BUYDOWN_POINTS: 139,
  FEE_ENUM_TITLE_ABSTRACT_FEE: 140,
  FEE_ENUM_TITLE_BORROWER_CLOSING_PROTECTION_LETTER_FEE: 141,
  FEE_ENUM_TITLE_CERTIFICATION_FEE: 142,
  FEE_ENUM_TITLE_CLOSING_COORDINATION_FEE: 143,
  FEE_ENUM_TITLE_CLOSING_FEE: 144,
  FEE_ENUM_TITLE_CLOSING_PROTECTION_LETTER_FEE: 145,
  FEE_ENUM_TITLE_COMMITMENT_FEE: 146,
  FEE_ENUM_TITLE_DOCUMENT_PREPARATION_FEE: 147,
  FEE_ENUM_TITLE_ENDORSEMENT_FEE: 148,
  FEE_ENUM_TITLE_EXAMINATION_FEE: 149,
  FEE_ENUM_TITLE_FINAL_POLICY_SHORT_FORM_FEE: 150,
  FEE_ENUM_TITLE_INSURANCE_BINDER_FEE: 151,
  FEE_ENUM_TITLE_INSURANCE_FEE: 152,
  FEE_ENUM_TITLE_LENDERS_COVERAGE_PREMIUM: 153,
  FEE_ENUM_TITLE_NOTARY_FEE: 154,
  FEE_ENUM_TITLE_OWNERS_COVERAGE_PREMIUM: 155,
  FEE_ENUM_TITLE_SEARCH_FEE: 156,
  FEE_ENUM_TITLE_SERVICES_FEE_TOTAL: 157,
  FEE_ENUM_TITLE_SERVICES_SALES_TAX: 158,
  FEE_ENUM_TITLE_SUB_ESCROW_FEE: 159,
  FEE_ENUM_TITLE_SUBORDINATION_PROCESSING_FEE: 160,
  FEE_ENUM_TITLE_UNDERWRITING_ISSUE_RESOLUTION_FEE: 161,
  FEE_ENUM_TRANSFER_TAX_TOTAL: 162,
  FEE_ENUM_UNDERWRITING_FEE: 163,
  FEE_ENUM_USDA_RURAL_DEVELOPMENT_GUARANTEE_FEE: 164,
  FEE_ENUM_VA_FUNDING_FEE: 165,
  FEE_ENUM_VERIFICATION_OF_ASSETS_FEE: 166,
  FEE_ENUM_VERIFICATION_OF_EMPLOYMENT_FEE: 167,
  FEE_ENUM_VERIFICATION_OF_INCOME_FEE: 168,
  FEE_ENUM_VERIFICATION_OF_RESIDENCY_STATUS_FEE: 169,
  FEE_ENUM_VERIFICATION_OF_TAXPAYER_IDENTIFICATION_FEE: 170,
  FEE_ENUM_VERIFICATION_OF_TAX_RETURN_FEE: 171,
  FEE_ENUM_WATER_TESTING_FEE: 172,
  FEE_ENUM_WELL_INSPECTION_FEE: 173,
  FEE_ENUM_WIRE_TRANSFER_FEE: 174,
  FEE_ENUM_TRANSFER_MORTGAGE_TAX: 175,
  FEE_ENUM_TRANSFER_DEED_TAX: 176,
  FEE_ENUM_TRANSFER_TAX: 177,
  FEE_ENUM_203_K_ARCHITECTURAL_AND_ENGINEERING_FEE: 178,
  FEE_ENUM_AMORTIZATION_FEE: 179,
  FEE_ENUM_CLO_ACCESS_FEE: 180,
  FEE_ENUM_DOCUMENT_SIGNING_FEE: 181,
  FEE_ENUM_EARTHQUAKE_INSURANCE_PREMIUM: 182,
  FEE_ENUM_FLOOD_DETERMINATION: 183,
  FEE_ENUM_FLOOD_INSURANCE_PREMIUM: 184,
  FEE_ENUM_FLOOD_MONITORING: 185,
  FEE_ENUM_FUNDING_WIRE_OR_DISBURSEMENT_FEE: 186,
  FEE_ENUM_HAIL_INSURANCE_PREMIUM: 187,
  FEE_ENUM_HOMEOWNERS_ASSOCIATION_CERTIFICATION: 188,
  FEE_ENUM_INTANGIBLE_TAX: 189,
  FEE_ENUM_MORTGAGE_INSURANCE_PMI_SINGLE_PREMIUM: 190,
  FEE_ENUM_MORTGAGE_INSURANCE_APPLICATION_FEE: 191,
  FEE_ENUM_NEW_LOAN_ADMINISTRATION_FEE: 192,
  FEE_ENUM_RATE_LOCK_EXTENSION: 193,
  FEE_ENUM_TAX_TRANSCRIPT_FEE: 194,
  FEE_ENUM_THIRD_PARTY_SUBORDINATE_FEE: 195,
  FEE_ENUM_TIE_IN_FEE: 196,
  FEE_ENUM_TITLE_ATTORNEY_FEE: 197,
  FEE_ENUM_TITLE_COURIER_FEE: 198,
  FEE_ENUM_TITLE_SETTLMENT_FEE: 199,
  FEE_ENUM_TITLE_SURVEY_FEE: 200,
  FEE_ENUM_TITLE_WARRANTY_FEE: 201,
  FEE_ENUM_VERIFICATION_FEE: 202,
  FEE_ENUM_VERIFICATION_FEE_3RD_PARTY: 203,
  FEE_ENUM_VOLCANO_INSURANCE_PREMIUM: 204,
  FEE_ENUM_WAREHOUSING_FEE: 205,
  FEE_ENUM_WIND_AND_STORM_INSURANCE_PREMIUM: 206
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AssetBankRetirementEnum = {
  ASSET_BANK_RETIREMENT_ENUM_CHECKING: 0,
  ASSET_BANK_RETIREMENT_ENUM_SAVINGS: 1,
  ASSET_BANK_RETIREMENT_ENUM_MONEY_MARKET: 2,
  ASSET_BANK_RETIREMENT_ENUM_CERTIFICATE_OF_DEPOSIT: 3,
  ASSET_BANK_RETIREMENT_ENUM_MUTUAL_FUND: 4,
  ASSET_BANK_RETIREMENT_ENUM_STOCKS: 5,
  ASSET_BANK_RETIREMENT_ENUM_STOCK_OPTIONS: 6,
  ASSET_BANK_RETIREMENT_ENUM_BONDS: 7,
  ASSET_BANK_RETIREMENT_ENUM_RETIREMENT: 8,
  ASSET_BANK_RETIREMENT_ENUM_BRIDGE_LOAN_PROCEEDS: 9,
  ASSET_BANK_RETIREMENT_ENUM_INDIVIDUAL_RETIREMENT_ACCOUNT: 10,
  ASSET_BANK_RETIREMENT_ENUM_TRUST_ACCOUNT: 11,
  ASSET_BANK_RETIREMENT_ENUM_CASH_VALUE_OF_LIFE_INSURANCE: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OtherAssetCreditTypeEnum = {
  OTHER_ASSET_CREDIT_TYPE_ENUM_PROCEEDS_FROM_REAL_ESTATE_TO_BE_CLOSED_ON_BEFORE_CLOSING: 0,
  OTHER_ASSET_CREDIT_TYPE_ENUM_PROCEEDS_SALE_NON_REALESTATE_ASSET: 1,
  OTHER_ASSET_CREDIT_TYPE_ENUM_SECURED_BORROW_FUNDS: 2,
  OTHER_ASSET_CREDIT_TYPE_ENUM_UNSECURED_BORROW_FUNDS: 3,
  OTHER_ASSET_CREDIT_TYPE_ENUM_OTHER: 4,
  OTHER_ASSET_CREDIT_TYPE_ENUM_EARNEST_MONEY: 5,
  OTHER_ASSET_CREDIT_TYPE_ENUM_EMPLOYER_ASSISTANCE: 6,
  OTHER_ASSET_CREDIT_TYPE_ENUM_LOT_EQUITY: 7,
  OTHER_ASSET_CREDIT_TYPE_ENUM_RELOCATION_FUNDS: 8,
  OTHER_ASSET_CREDIT_TYPE_ENUM_RENT_CREDIT: 9,
  OTHER_ASSET_CREDIT_TYPE_ENUM_SWEAT_EQUITY: 10,
  OTHER_ASSET_CREDIT_TYPE_ENUM_TRADE_EQUITY: 11
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CreditCardOtherDebtLeaseEnum = {
  CREDIT_CARD_OTHER_DEBT_LEASE_ENUM_REVOLVING: 0,
  CREDIT_CARD_OTHER_DEBT_LEASE_ENUM_INSTALLMENT: 1,
  CREDIT_CARD_OTHER_DEBT_LEASE_ENUM_OPEN30DAY: 2,
  CREDIT_CARD_OTHER_DEBT_LEASE_ENUM_LEASE: 3,
  CREDIT_CARD_OTHER_DEBT_LEASE_ENUM_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OtherLiabilitiesAndExpensesTypeEnum = {
  OTHER_LIABILITIES_AND_EXPENSES_TYPE_ENUM_ALIMONY: 0,
  OTHER_LIABILITIES_AND_EXPENSES_TYPE_ENUM_CHILDSUPPORT: 1,
  OTHER_LIABILITIES_AND_EXPENSES_TYPE_ENUM_SEPARATEMAINTENANCE: 2,
  OTHER_LIABILITIES_AND_EXPENSES_TYPE_ENUM_JOBRELATEDEXPENSES: 3,
  OTHER_LIABILITIES_AND_EXPENSES_TYPE_ENUM_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AusTypeEnum = {
  AUS_TYPE_ENUM_UNKNOWN: 0,
  AUS_TYPE_ENUM_DU_AND_LP: 1,
  AUS_TYPE_ENUM_DESKTOP_UNDERWRITER: 2,
  AUS_TYPE_ENUM_LOAN_PROSPECTOR: 3,
  AUS_TYPE_ENUM_DESKTOP_ORIGINATOR: 4,
  AUS_TYPE_ENUM_DO_AND_LP: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AusProvider = {
  AUS_PROVIDER_UNKNOWN: 0,
  AUS_PROVIDER_DESKTOP_UNDERWRITER: 1,
  AUS_PROVIDER_LOAN_PROSPECTOR: 2,
  AUS_PROVIDER_DESKTOP_ORIGINATOR: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AusResultTypeEnum = {
  AUS_RESULT_TYPE_ENUM_UNKNOWN: 0,
  AUS_RESULT_TYPE_ENUM_DESKTOP_UNDERWRITER: 1,
  AUS_RESULT_TYPE_ENUM_LOAN_PROSPECTOR: 2,
  AUS_RESULT_TYPE_ENUM_DESKTOP_ORIGINATOR: 3,
  AUS_RESULT_TYPE_ENUM_ERROR: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DocumentCategoryEnum = {
  DOCUMENT_CATEGORY_ENUM_UNKNOWN: 0,
  DOCUMENT_CATEGORY_ENUM_TRID_APPLICATION: 1,
  DOCUMENT_CATEGORY_ENUM_INTENT_TO_PROCEED: 2,
  DOCUMENT_CATEGORY_ENUM_CREDIT: 3,
  DOCUMENT_CATEGORY_ENUM_AUTOMATED_UNDERWRITING: 4,
  DOCUMENT_CATEGORY_ENUM_APPRAISAL: 5,
  DOCUMENT_CATEGORY_ENUM_FEES: 6,
  DOCUMENT_CATEGORY_ENUM_DISCLOSURES: 7,
  DOCUMENT_CATEGORY_ENUM_SUBMIT_APPLICATION: 8,
  DOCUMENT_CATEGORY_ENUM_INCOME: 9,
  DOCUMENT_CATEGORY_ENUM_EMPLOYMENT: 10,
  DOCUMENT_CATEGORY_ENUM_ASSET: 11,
  DOCUMENT_CATEGORY_ENUM_TITLE: 12,
  DOCUMENT_CATEGORY_ENUM_FLOOD: 13,
  DOCUMENT_CATEGORY_ENUM_CONDITIONS: 14,
  DOCUMENT_CATEGORY_ENUM_UNDERWRITER_DECISION: 15,
  DOCUMENT_CATEGORY_ENUM_CLEAR_TO_CLOSE: 16,
  DOCUMENT_CATEGORY_ENUM_CLOSING_DOCUMENTS: 17,
  DOCUMENT_CATEGORY_ENUM_FUNDS_TO_CLOSE: 18,
  DOCUMENT_CATEGORY_ENUM_FUNDING_WIRE: 19,
  DOCUMENT_CATEGORY_ENUM_MERS_TRANSFER: 20,
  DOCUMENT_CATEGORY_ENUM_TRAILING_DOCUMENTS: 21,
  DOCUMENT_CATEGORY_ENUM_POST_CLOSING_AUDIT: 22,
  DOCUMENT_CATEGORY_ENUM_FRAUD: 23,
  DOCUMENT_CATEGORY_ENUM_COMPLIANCE: 24,
  DOCUMENT_CATEGORY_ENUM_PURCHASE_CONTRACT: 25
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanProgressEnum = {
  LOAN_PROGRESS_ENUM_NOT_STARTED: 0,
  LOAN_PROGRESS_ENUM_IN_PROGRESS: 1,
  LOAN_PROGRESS_ENUM_NEEDS_REVIEW: 2,
  LOAN_PROGRESS_ENUM_VALIDATED: 3,
  LOAN_PROGRESS_ENUM_EXPIRED: 4,
  LOAN_PROGRESS_ENUM_CANCELED: 5,
  LOAN_PROGRESS_ENUM_COMPLETED: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanFeeCategoryTypeEnum = {
  LOAN_FEE_CATEGORY_TYPE_ENUM_FEE: 0,
  LOAN_FEE_CATEGORY_TYPE_ENUM_ESCROW: 1,
  LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanFeeSpecialCaseEnum = {
  LOAN_FEE_SPECIAL_CASE_ENUM_NONE: 0,
  LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT: 1,
  LOAN_FEE_SPECIAL_CASE_ENUM_PREPAID_INTEREST: 2,
  LOAN_FEE_SPECIAL_CASE_ENUM_BUYDOWN: 3,
  LOAN_FEE_SPECIAL_CASE_ENUM_DISCOUNT_POINTS: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DisclosureRequestEnum = {
  DISCLOSURE_REQUEST_ENUM_UNKNOWN: 0,
  DISCLOSURE_REQUEST_ENUM_INITIAL_DISCLOSURES: 1,
  DISCLOSURE_REQUEST_ENUM_REDISCLOSED_LOAN_ESTIMATE: 2,
  DISCLOSURE_REQUEST_ENUM_CLOSING_DISCLOSURE: 3,
  DISCLOSURE_REQUEST_ENUM_REDISCLOSED_CLOSING_DISCLOSURE: 4,
  DISCLOSURE_REQUEST_ENUM_CLOSING_PACKAGE: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.TreasuryNPVLoanAmortizationEnum = {
  TREASURY_NPV_LOAN_AMORTIZATION_ENUM_UNKNOWN: 0,
  TREASURY_NPV_LOAN_AMORTIZATION_ENUM_ADJUSTABLE_RATE: 1,
  TREASURY_NPV_LOAN_AMORTIZATION_ENUM_FIXED_RATE: 2,
  TREASURY_NPV_LOAN_AMORTIZATION_ENUM_OTHER: 3,
  TREASURY_NPV_LOAN_AMORTIZATION_ENUM_STEP: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.RateStatus = {
  RATE_STATUS_UNSPECIFIED: 0,
  RATE_STATUS_APPROVED: 1,
  RATE_STATUS_REJECTED: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ObjectStatusEnum = {
  OBJECT_STATUS_ENUM_STATED: 0,
  OBJECT_STATUS_ENUM_COLLECTED: 1,
  OBJECT_STATUS_ENUM_VALIDATED: 2,
  OBJECT_STATUS_ENUM_VERIFIED: 3,
  OBJECT_STATUS_ENUM_REJECTED: 4,
  OBJECT_STATUS_ENUM_REPORTED: 5,
  OBJECT_STATUS_ENUM_ARCHIVED: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ObjectStatusMessageTypeEnum = {
  OBJECT_STATUS_MESSAGE_TYPE_ASSET: 0,
  OBJECT_STATUS_MESSAGE_TYPE_CURRENT_INCOME_ITEM: 1,
  OBJECT_STATUS_MESSAGE_TYPE_EMPLOYMENT: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.IntentToProceedCommunicationMethodEnum = {
  INTENT_TO_PROCEED_COMMUNICATION_METHOD_ENUM_ESIGN: 0,
  INTENT_TO_PROCEED_COMMUNICATION_METHOD_ENUM_WET_SIGN: 1,
  INTENT_TO_PROCEED_COMMUNICATION_METHOD_ENUM_VERBAL: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DisclosureDeliveryMethodEnum = {
  DISCLOSURE_DELIVERY_METHOD_ENUM_UNKNOWN: 0,
  DISCLOSURE_DELIVERY_METHOD_ENUM_COURIER_DELIVERY_SERVICE: 1,
  DISCLOSURE_DELIVERY_METHOD_ENUM_ELECTRONIC_DELIVERY: 2,
  DISCLOSURE_DELIVERY_METHOD_ENUM_IN_PERSON: 3,
  DISCLOSURE_DELIVERY_METHOD_ENUM_OTHER: 4,
  DISCLOSURE_DELIVERY_METHOD_ENUM_USPS_FIRST_CLASS_MAIL: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryStatusEnum = {
  DISCLOSURE_DOCUMENTS_DELIVERY_STATUS_ENUM_UNKNOWN: 0
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DisclosureDocumentsDeliveryMethodEnum = {
  DISCLOSURE_DOCUMENTS_DELIVERY_METHOD_ENUM_UNKNOWN: 0,
  DISCLOSURE_DOCUMENTS_DELIVERY_METHOD_ENUM_PROCESSING_CENTER: 1,
  DISCLOSURE_DOCUMENTS_DELIVERY_METHOD_ENUM_ESIGN: 2,
  DISCLOSURE_DOCUMENTS_DELIVERY_METHOD_ENUM_EMAIL: 3,
  DISCLOSURE_DOCUMENTS_DELIVERY_METHOD_ENUM_EDISCLOSE: 4,
  DISCLOSURE_DOCUMENTS_DELIVERY_METHOD_ENUM_ECLOSE: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.TridTrackingStatusEnum = {
  TRID_TRACKING_STATUS_YES: 0,
  TRID_TRACKING_STATUS_NO: 1,
  TRID_TRACKING_STATUS_NEUTRAL: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.MaritalStatusEnum = {
  MARITAL_STATUS_ENUM_UNKNOWN: 0,
  MARITAL_STATUS_ENUM_DIVORCED: 1,
  MARITAL_STATUS_ENUM_MARRIED: 2,
  MARITAL_STATUS_ENUM_NOT_PROVIDED: 3,
  MARITAL_STATUS_ENUM_OTHER: 4,
  MARITAL_STATUS_ENUM_SEPARATED: 5,
  MARITAL_STATUS_ENUM_UNMARRIED: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CitizenshipResidencyEnum = {
  CITIZENSHIP_RESIDENCY_ENUM_UNKNOWN: 0,
  CITIZENSHIP_RESIDENCY_ENUM_NON_PERMANENT_RESIDENT_ALIEN: 1,
  CITIZENSHIP_RESIDENCY_ENUM_NON_RESIDENT_ALIEN: 2,
  CITIZENSHIP_RESIDENCY_ENUM_PERMANENT_RESIDENT_ALIEN: 3,
  CITIZENSHIP_RESIDENCY_ENUM_US_CITIZEN: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DomesticRelationshipEnum = {
  DOMESTIC_RELATIONSHIP_ENUM_UNKNOWN: 0,
  DOMESTIC_RELATIONSHIP_ENUM_CIVIL_UNION: 1,
  DOMESTIC_RELATIONSHIP_ENUM_DOMESTIC_PARTNERSHIP: 2,
  DOMESTIC_RELATIONSHIP_ENUM_OTHER: 3,
  DOMESTIC_RELATIONSHIP_ENUM_REGISTERED_RECIPROCAL_BENEFICIARY_RELATIONSHIP: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.BorrowerDetailsSectionEnum = {
  BORROWER_DETAILS_SECTION_ENUM_UNKNOWN: 0,
  BORROWER_DETAILS_SECTION_ENUM_PERSONAL_INFORMATION: 1,
  BORROWER_DETAILS_SECTION_ENUM_MILITARY_SERVICE: 2,
  BORROWER_DETAILS_SECTION_ENUM_CONTACT: 3,
  BORROWER_DETAILS_SECTION_ENUM_INCOME_SUMMARY: 4,
  BORROWER_DETAILS_SECTION_ENUM_ASSETS: 5,
  BORROWER_DETAILS_SECTION_ENUM_OTHER_ASSETS_AND_CREDITS: 6,
  BORROWER_DETAILS_SECTION_ENUM_CREDIT_SUMMARY: 7,
  BORROWER_DETAILS_SECTION_ENUM_EXPENSES: 8,
  BORROWER_DETAILS_SECTION_ENUM_REO_SUMMARY: 9,
  BORROWER_DETAILS_SECTION_ENUM_APPLICATION_INFORMATION: 10
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AppraisalManualTriggerPriorityEnum = {
  MANUAL_TRIGGER_PRIORITY_ENUM_NORMAL: 0,
  MANUAL_TRIGGER_PRIORITY_ENUM_RUSH: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.JointAssetLiabilityReportingEnum = {
  JOINT_ASSET_LIABILITY_REPORTING_ENUM_UNKNOWN: 0,
  JOINT_ASSET_LIABILITY_REPORTING_ENUM_JOINTLY: 1,
  JOINT_ASSET_LIABILITY_REPORTING_ENUM_NOT_JOINTLY: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FloodProductEnum = {
  FLOOD_PRODUCT_ENUM_FLOOD_ZONE_DETERMINATION_WITH_CENSUS_TRACT: 0,
  FLOOD_PRODUCT_ENUM_FLOOD_ZONE_DETERMINATION: 1,
  FLOOD_PRODUCT_ENUM_HOME_EQUITY: 2,
  FLOOD_PRODUCT_ENUM_HOME_EQUITY_WITH_CENSUS_TRACT: 3,
  FLOOD_PRODUCT_ENUM_LIFE_OF_LOAN_WITH_CENSUS_TRACT: 4,
  FLOOD_PRODUCT_ENUM_LIFE_OF_LOAN: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CreditVendorEnum = {
  CREDIT_VENDOR_ENUM_UNKNOWN: 0,
  CREDIT_VENDOR_ENUM_FACTUAL: 1,
  CREDIT_VENDOR_ENUM_XACTUS: 2,
  CREDIT_VENDOR_ENUM_ADVANTAGE_CREDIT: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CreditReportOnEnum = {
  CREDIT_REPORT_ON_ENUM_INDIVIDUAL: 0,
  CREDIT_REPORT_ON_ENUM_JOINT: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CreditRequestTypeEnum = {
  CREDIT_REQUEST_TYPE_ENUM_NEW: 0,
  CREDIT_REQUEST_TYPE_ENUM_RETRIEVE_EXISTING: 1,
  CREDIT_REQUEST_TYPE_ENUM_UPGRADE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CreditPullTypeEnum = {
  CREDIT_PULL_TYPE_ENUM_SOFT_PULL: 0,
  CREDIT_PULL_TYPE_ENUM_HARD_PULL: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CreditBureausEnum = {
  CREDIT_BUREAUS_ENUM_EXPERIAN: 0,
  CREDIT_BUREAUS_ENUM_EQUIFAX: 1,
  CREDIT_BUREAUS_ENUM_TRANSUNION: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.IntegrationTypeLocalEnum = {
  INTEGRATION_TYPE_LOCAL_ENUM_UNKNOWN: 0,
  INTEGRATION_TYPE_LOCAL_ENUM_CREDIT: 1,
  INTEGRATION_TYPE_LOCAL_ENUM_FRAUD: 2,
  INTEGRATION_TYPE_LOCAL_ENUM_FLOOD: 3,
  INTEGRATION_TYPE_LOCAL_ENUM_DISCLOSURES: 4,
  INTEGRATION_TYPE_LOCAL_ENUM_COMPLIANCE: 5,
  INTEGRATION_TYPE_LOCAL_ENUM_FEES: 6,
  INTEGRATION_TYPE_LOCAL_ENUM_AUS: 7,
  INTEGRATION_TYPE_LOCAL_ENUM_APPRAISAL: 8,
  INTEGRATION_TYPE_LOCAL_ENUM_PRODUCT_AND_PRICING: 9,
  INTEGRATION_TYPE_LOCAL_ENUM_PREQUALIFICATION: 10,
  INTEGRATION_TYPE_LOCAL_ENUM_DOCUMENT_GENERATION: 11,
  INTEGRATION_TYPE_LOCAL_ENUM_MERS_REGISTRATION: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.IntegrationVendorEnum = {
  INTEGRATION_VENDOR_ENUM_UNKNOWN: 0,
  INTEGRATION_VENDOR_ENUM_BLEND: 1,
  INTEGRATION_VENDOR_ENUM_FACTUAL: 2,
  INTEGRATION_VENDOR_ENUM_ARCHMORTGAGEINSURANCE: 3,
  INTEGRATION_VENDOR_ENUM_DESKTOPUNDERWRITER: 4,
  INTEGRATION_VENDOR_ENUM_OPTIMAL_BLUE: 5,
  INTEGRATION_VENDOR_ENUM_POLLY: 6,
  INTEGRATION_VENDOR_ENUM_DOCUTECH: 7,
  INTEGRATION_VENDOR_ENUM_REGGORA: 8,
  INTEGRATION_VENDOR_ENUM_XACTUS: 9,
  INTEGRATION_VENDOR_ENUM_LOAN_PASS: 10,
  INTEGRATION_VENDOR_ENUM_MERS: 11,
  INTEGRATION_VENDOR_ENUM_DOC_MAGIC: 12,
  INTEGRATION_VENDOR_ENUM_ASURITY: 13
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.EmploymentStatusEnum = {
  EMPLOYMENT_STATUS_ENUM_UNKNOWN: 0,
  EMPLOYMENT_STATUS_ENUM_CURRENT: 1,
  EMPLOYMENT_STATUS_ENUM_PREVIOUS: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.EmploymentClassificationEnum = {
  EMPLOYMENT_CLASSIFICATION_ENUM_UNKNOWN: 0,
  EMPLOYMENT_CLASSIFICATION_ENUM_PRIMARY: 1,
  EMPLOYMENT_CLASSIFICATION_ENUM_SECONDARY: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeePaymentPaidByEnum = {
  FEE_PAYMENT_PAID_BY_ENUM_UNKNOWN: 0,
  FEE_PAYMENT_PAID_BY_ENUM_BORROWER: 1,
  FEE_PAYMENT_PAID_BY_ENUM_BROKER: 2,
  FEE_PAYMENT_PAID_BY_ENUM_BUYER: 3,
  FEE_PAYMENT_PAID_BY_ENUM_CORRESPONDENT: 4,
  FEE_PAYMENT_PAID_BY_ENUM_LENDER: 5,
  FEE_PAYMENT_PAID_BY_ENUM_SELLER: 6,
  FEE_PAYMENT_PAID_BY_ENUM_SERVICER: 7,
  FEE_PAYMENT_PAID_BY_ENUM_THIRDPARTY: 8
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DocumentValidPeriodInDaysEnum = {
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_UNKNOWN: 0,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_30: 1,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_60: 2,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_90: 3,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_120: 4,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_180: 5,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_240: 6,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_365: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DisplayType = {
  DISPLAY_TYPE_DEFAULT: 0,
  DISPLAY_TYPE_CURRENCY: 1,
  DISPLAY_TYPE_PERCENTAGE: 2,
  DISPLAY_TYPE_DATE: 3,
  DISPLAY_TYPE_CURRENCY_SUB_ITEM: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ComplianceConditionSeverityEnum = {
  COMPLIANCE_CONDITION_SEVERITY_ENUM_UNKNOWN: 0,
  COMPLIANCE_CONDITION_SEVERITY_ENUM_LOW: 1,
  COMPLIANCE_CONDITION_SEVERITY_ENUM_MEDIUM: 2,
  COMPLIANCE_CONDITION_SEVERITY_ENUM_HIGH: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.IntegratedDisclosureSectionEnum = {
  INTEGRATED_DISCLOSURE_SECTION_ENUM_UNKNOWN: 0,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_DUE_FROM_BORROWER_AT_CLOSING: 1,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_DUE_FROM_SELLER_AT_CLOSING: 2,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_DUE_TO_SELLER_AT_CLOSING: 3,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_INITIAL_ESCROW_PAYMENT_AT_CLOSING: 4,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_ORIGINATION_CHARGES: 5,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_OTHER: 6,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_OTHER_COSTS: 7,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_PAID_ALREADY_BY_OR_ON_BEHALF_OF_BORROWER_AT_CLOSING: 8,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_PAYOFFS_AND_PAYMENTS: 9,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_PREPAIDS: 10,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_BORROWER_DID_NOT_SHOP_FOR: 11,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_BORROWER_DID_SHOP_FOR: 12,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CANNOT_SHOP_FOR: 13,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_SERVICES_YOU_CAN_SHOP_FOR: 14,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TAXES_AND_OTHER_GOVERNMENT_FEES: 15,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TOTAL_CLOSING_COSTS: 16,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TOTAL_LOAN_COSTS: 17,
  INTEGRATED_DISCLOSURE_SECTION_ENUM_TOTAL_OTHER_COSTS: 18
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanGroupEnum = {
  LOAN_GROUP_ENUM_UNKNOWN: 0,
  LOAN_GROUP_ENUM_LOAN_PHASE: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.SampleDealProfileIdentifierEnum = {
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_UNKNOWN: 0,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_DEFAULT: 1,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_WILQO: 2,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_DEMO: 3,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_FHMLC: 4,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_XACTUS: 5,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_FACTUAL: 6,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_FLOOD: 7,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_FNMA: 8,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_FEES: 9,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_PRICING: 10,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_MORTGAGE_INSURANCE: 11,
  SAMPLE_DEAL_PROFILE_IDENTIFIER_ENUM_DOCUMENTS: 12
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeeCalculationTypeEnum = {
  FEE_CALCULATION_TYPE_ENUM_UNKNOWN: 0,
  FEE_CALCULATION_TYPE_ENUM_FIXED: 1,
  FEE_CALCULATION_TYPE_ENUM_PERCENT: 2,
  FEE_CALCULATION_TYPE_ENUM_FIXED_AND_PERCENT: 3,
  FEE_CALCULATION_TYPE_ENUM_VARIABLE: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PrepaidItemPerDiemCalculationMethodEnum = {
  PREPAID_ITEM_PER_DIEM_CALCULATION_METHOD_ENUM_UNKNOWN: 0,
  PREPAID_ITEM_PER_DIEM_CALCULATION_METHOD_ENUM_360: 1,
  PREPAID_ITEM_PER_DIEM_CALCULATION_METHOD_ENUM_365: 2,
  PREPAID_ITEM_PER_DIEM_CALCULATION_METHOD_ENUM_365_OR366: 3,
  PREPAID_ITEM_PER_DIEM_CALCULATION_METHOD_ENUM_OTHER: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeePercentBasisEnum = {
  FEE_PERCENT_BASIS_ENUM_UNKNOWN: 0,
  FEE_PERCENT_BASIS_ENUM_BASE_LOAN_AMOUNT: 1,
  FEE_PERCENT_BASIS_ENUM_NOTE_AMOUNT: 2,
  FEE_PERCENT_BASIS_ENUM_OTHER: 3,
  FEE_PERCENT_BASIS_ENUM_PROPERTY_APPRAISED_VALUE_AMOUNT: 4,
  FEE_PERCENT_BASIS_ENUM_PURCHASE_PRICE_AMOUNT: 5,
  FEE_PERCENT_BASIS_ENUM_FIXED: 6,
  FEE_PERCENT_BASIS_ENUM_VARIABLE: 7,
  FEE_PERCENT_BASIS_ENUM_ORIGINAL_LOAN_AMOUNT: 8
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.EscrowPremiumRatePercentBasisEnum = {
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_UNKNOWN: 0,
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_BORROWER_REQUESTED_LOAN_AMOUNT: 1,
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_NOTE_AMOUNT: 2,
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_OTHER: 3,
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_PROPERTY_VALUATION_AMOUNT: 4,
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_PURCHASE_PRICE_AMOUNT: 5,
  ESCROW_PREMIUM_RATE_PERCENT_BASIS_ENUM_TOTAL_LOAN_AMOUNT: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.EscrowItemEnum = {
  ESCROW_ITEM_ENUM_UNKNOWN: 0,
  ESCROW_ITEM_ENUM_BOROUGH_PROPERTY_TAX: 2,
  ESCROW_ITEM_ENUM_CITY_PROPERTY_TAX: 4,
  ESCROW_ITEM_ENUM_COUNTY_PROPERTY_TAX: 11,
  ESCROW_ITEM_ENUM_DISTRICT_PROPERTY_TAX: 12,
  ESCROW_ITEM_ENUM_HAIL_INSURANCE_PREMIUM: 16,
  ESCROW_ITEM_ENUM_MORTGAGE_INSURANCE: 21,
  ESCROW_ITEM_ENUM_PROPERTY_TAX: 25,
  ESCROW_ITEM_ENUM_SCHOOL_PROPERTY_TAX: 27,
  ESCROW_ITEM_ENUM_STATE_PROPERTY_TAX: 28,
  ESCROW_ITEM_ENUM_TOWN_PROPERTY_TAX: 29,
  ESCROW_ITEM_ENUM_TOWNSHIP_PROPERTY_TAX: 30,
  ESCROW_ITEM_ENUM_VILLAGE_PROPERTY_TAX: 31,
  ESCROW_ITEM_ENUM_WINDSTORM_INSURANCE: 33,
  ESCROW_ITEM_ENUM_CONDOMINIUM_ASSOCIATION_DUES: 5,
  ESCROW_ITEM_ENUM_CONDOMINIUM_ASSOCIATION_SPECIAL_ASSESSMENT: 6,
  ESCROW_ITEM_ENUM_CONSTRUCTION_COMPLETION_FUNDS: 7,
  ESCROW_ITEM_ENUM_COOPERATIVE_ASSOCIATION_DUES: 8,
  ESCROW_ITEM_ENUM_COOPERATIVE_ASSOCIATION_SPECIAL_ASSESSMENT: 9,
  ESCROW_ITEM_ENUM_ENERGY_EFFICIENT_IMPROVEMENT_FUNDS: 14,
  ESCROW_ITEM_ENUM_HOMEOWNERS_ASSOCIATION_DUES: 18,
  ESCROW_ITEM_ENUM_HOMEOWNERS_ASSOCIATION_SPECIAL_ASSESSMENT: 19,
  ESCROW_ITEM_ENUM_OTHER: 22,
  ESCROW_ITEM_ENUM_REHABILITATION_FUNDS: 26,
  ESCROW_ITEM_ENUM_AGGREGATE_ADJUSTMENT: 34,
  ESCROW_ITEM_ENUM_MORTGAGE_INSURANCE_PREMIUM: 35,
  ESCROW_ITEM_ENUM_ASSESSMENT_TAX: 1,
  ESCROW_ITEM_ENUM_BOROUGH_TAX: 36,
  ESCROW_ITEM_ENUM_CITY_BOND_TAX: 3,
  ESCROW_ITEM_ENUM_CITY_TAX: 37,
  ESCROW_ITEM_ENUM_COUNTY_BOND_TAX: 10,
  ESCROW_ITEM_ENUM_COUNTY_TAX: 38,
  ESCROW_ITEM_ENUM_DISTRICT_TAX: 39,
  ESCROW_ITEM_ENUM_PARISH_TAX: 23,
  ESCROW_ITEM_ENUM_SCHOOL_TAX: 40,
  ESCROW_ITEM_ENUM_STATE_TAX: 41,
  ESCROW_ITEM_ENUM_TOWN_TAX: 42,
  ESCROW_ITEM_ENUM_TOWNSHIP_TAX: 43,
  ESCROW_ITEM_ENUM_VILLAGE_TAX: 44,
  ESCROW_ITEM_ENUM_HAZARD_INSURANCE: 17,
  ESCROW_ITEM_ENUM_FIRE_AND_EXTENDED_COVERAGE_INSURANCE: 45,
  ESCROW_ITEM_ENUM_HOMEOWNERS_INSURANCE: 20,
  ESCROW_ITEM_ENUM_FLOOD_INSURANCE: 15,
  ESCROW_ITEM_ENUM_EARTHQUAKE_INSURANCE: 13,
  ESCROW_ITEM_ENUM_HAIL_INSURANCE: 46,
  ESCROW_ITEM_ENUM_STORM_INSURANCE: 47,
  ESCROW_ITEM_ENUM_TORNADO_INSURANCE: 48,
  ESCROW_ITEM_ENUM_HURRICANE_INSURANCE: 49,
  ESCROW_ITEM_ENUM_PEST_INSURANCE: 24,
  ESCROW_ITEM_ENUM_VOLCANO_INSURANCE: 32,
  ESCROW_ITEM_ENUM_WIND_INSURANCE: 50
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PrepaidItemEnum = {
  PREPAID_ITEM_ENUM_UNKNOWN: 0,
  PREPAID_ITEM_ENUM_BOROUGH_PROPERTY_TAX: 1,
  PREPAID_ITEM_ENUM_CITY_PROPERTY_TAX: 2,
  PREPAID_ITEM_ENUM_COUNTY_PROPERTY_TAX: 7,
  PREPAID_ITEM_ENUM_DISTRICT_PROPERTY_TAX: 8,
  PREPAID_ITEM_ENUM_EARTHQUAKE_INSURANCE_PREMIUM: 9,
  PREPAID_ITEM_ENUM_FLOOD_INSURANCE_PREMIUM: 10,
  PREPAID_ITEM_ENUM_HAIL_INSURANCE_PREMIUM: 11,
  PREPAID_ITEM_ENUM_HAZARD_INSURANCE_PREMIUM: 12,
  PREPAID_ITEM_ENUM_HOMEOWNERS_INSURANCE_PREMIUM: 15,
  PREPAID_ITEM_ENUM_STATE_PROPERTY_TAX: 19,
  PREPAID_ITEM_ENUM_TOWN_PROPERTY_TAX: 20,
  PREPAID_ITEM_ENUM_VOLCANO_INSURANCE_PREMIUM: 23,
  PREPAID_ITEM_ENUM_WIND_AND_STORM_INSURANCE_PREMIUM: 24,
  PREPAID_ITEM_ENUM_CONDOMINIUM_ASSOCIATION_DUES: 3,
  PREPAID_ITEM_ENUM_CONDOMINIUM_ASSOCIATION_SPECIAL_ASSESSMENT: 4,
  PREPAID_ITEM_ENUM_COOPERATIVE_ASSOCIATION_DUES: 5,
  PREPAID_ITEM_ENUM_COOPERATIVE_ASSOCIATION_SPECIAL_ASSESSMENT: 6,
  PREPAID_ITEM_ENUM_HOMEOWNERS_ASSOCIATION_DUES: 13,
  PREPAID_ITEM_ENUM_HOMEOWNERS_ASSOCIATION_SPECIAL_ASSESSMENT: 14,
  PREPAID_ITEM_ENUM_MORTGAGE_INSURANCE_PREMIUM: 16,
  PREPAID_ITEM_ENUM_OTHER: 17,
  PREPAID_ITEM_ENUM_PREPAID_INTEREST: 18,
  PREPAID_ITEM_ENUM_USDA_RURAL_DEVELOPMENT_GUARANTEE_FEE: 21,
  PREPAID_ITEM_ENUM_VA_FUNDING_FEE: 22,
  PREPAID_ITEM_ENUM_FHA_UP_FRONT_PREMIUM: 25,
  PREPAID_ITEM_ENUM_ASSESSMENT_TAX: 26,
  PREPAID_ITEM_ENUM_BOROUGH_TAX: 27,
  PREPAID_ITEM_ENUM_CITY_BOND_TAX: 28,
  PREPAID_ITEM_ENUM_CITY_TAX: 29,
  PREPAID_ITEM_ENUM_COUNTY_BOND_TAX: 30,
  PREPAID_ITEM_ENUM_COUNTY_TAX: 31,
  PREPAID_ITEM_ENUM_DISTRICT_TAX: 32,
  PREPAID_ITEM_ENUM_PARISH_TAX: 33,
  PREPAID_ITEM_ENUM_SCHOOL_TAX: 34,
  PREPAID_ITEM_ENUM_STATE_TAX: 35,
  PREPAID_ITEM_ENUM_TOWN_TAX: 36,
  PREPAID_ITEM_ENUM_TOWNSHIP_TAX: 37,
  PREPAID_ITEM_ENUM_VILLAGE_TAX: 38,
  PREPAID_ITEM_ENUM_HAZARD_INSURANCE: 39,
  PREPAID_ITEM_ENUM_FIRE_AND_EXTENDED_COVERAGE_INSURANCE: 40,
  PREPAID_ITEM_ENUM_HOMEOWNERS_INSURANCE: 41,
  PREPAID_ITEM_ENUM_FLOOD_INSURANCE: 42,
  PREPAID_ITEM_ENUM_EARTHQUAKE_INSURANCE: 43,
  PREPAID_ITEM_ENUM_HAIL_INSURANCE: 44,
  PREPAID_ITEM_ENUM_STORM_INSURANCE: 45,
  PREPAID_ITEM_ENUM_TORNADO_INSURANCE: 46,
  PREPAID_ITEM_ENUM_HURRICANE_INSURANCE: 47,
  PREPAID_ITEM_ENUM_PEST_INSURANCE: 48,
  PREPAID_ITEM_ENUM_VOLCANO_INSURANCE: 49,
  PREPAID_ITEM_ENUM_WIND_INSURANCE: 50
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ConditionTemplateCategoryEnum = {
  CONDITION_TEMPLATE_CATEGORY_ENUM_UNKNOWN: 0,
  CONDITION_TEMPLATE_CATEGORY_ENUM_ASSETS: 1,
  CONDITION_TEMPLATE_CATEGORY_ENUM_PROPERTY: 2,
  CONDITION_TEMPLATE_CATEGORY_ENUM_INCOME: 3,
  CONDITION_TEMPLATE_CATEGORY_ENUM_CREDIT: 4,
  CONDITION_TEMPLATE_CATEGORY_ENUM_PROGRAM: 5,
  CONDITION_TEMPLATE_CATEGORY_ENUM_LEGAL: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ConditionSatisfactionResponsiblePartyEnum = {
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_UNKNOWN: 0,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_ATTORNEY: 1,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_BROKER: 2,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_CLOSER: 3,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_CLOSING_AGENT: 4,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_ESCROW_COMPANY: 5,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_LOAN_OFFICER: 6,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_ORIGINATOR: 7,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_OTHER: 8,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_PROCESSOR: 9,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_SETTLEMENT_AGENT: 10,
  CONDITION_SATISFACTION_RESPONSIBLE_PARTY_ENUM_TITLE_COMPANY: 11
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ConditionSatisfactionTimeframeEnum = {
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_UNKNOWN: 0,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_OTHER: 1,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_APPROVAL: 2,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_DOCUMENTS: 3,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_FUNDING: 4,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_PRIOR_TO_SIGNING: 5,
  CONDITION_SATISFACTION_TIMEFRAME_ENUM_UNDERWRITER_TO_REVIEW: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeeSourceEnum = {
  FEE_SOURCE_ENUM_UNKNOWN: 0,
  FEE_SOURCE_ENUM_LODESTAR: 1,
  FEE_SOURCE_ENUM_MANUAL: 2,
  FEE_SOURCE_ENUM_TRIGGERED: 3,
  FEE_SOURCE_ENUM_INTEGRATION: 4,
  FEE_SOURCE_ENUM_CALCULATED: 5,
  FEE_SOURCE_ENUM_MANUAL_OVERRIDE: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PartySourceTypeEnum = {
  PARTY_SOURCE_TYPE_ENUM_UNKNOWN: 0,
  PARTY_SOURCE_TYPE_ENUM_USER: 1,
  PARTY_SOURCE_TYPE_ENUM_INTEGRATION: 2,
  PARTY_SOURCE_TYPE_ENUM_AUTOMATED_PROCESS: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProductPriceTargetEnum = {
  PRODUCT_PRICE_TARGET_ENUM_UNKNOWN: 0,
  PRODUCT_PRICE_TARGET_ENUM_ALL_PRODUCTS: 1,
  PRODUCT_PRICE_TARGET_ENUM_SINGLE_PRODUCT: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProductChannel = {
  PRODUCT_CHANNEL_UNSPECIFIED: 0,
  PRODUCT_CHANNEL_CORRESPONDENT: 1,
  PRODUCT_CHANNEL_WHOLESALE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.CommunityLendingProductType = {
  COMMUNITY_LENDING_PRODUCT_TYPE_UNSPECIFIED: 0,
  COMMUNITY_LENDING_PRODUCT_TYPE_NONE: 1,
  COMMUNITY_LENDING_PRODUCT_TYPE_FHLMC_HOME_ONE: 2,
  COMMUNITY_LENDING_PRODUCT_TYPE_FHLMC_HOME_POSSIBLE: 3,
  COMMUNITY_LENDING_PRODUCT_TYPE_FNMA_HIGH_LTV_REFINANCE: 4,
  COMMUNITY_LENDING_PRODUCT_TYPE_FNMA_HOME_READY: 5,
  COMMUNITY_LENDING_PRODUCT_TYPE_FNMA_HOME_STYLE: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanTermType = {
  LOAN_TERM_TYPE_UNSPECIFIED: 0,
  LOAN_TERM_TYPE_PRESET: 1,
  LOAN_TERM_TYPE_RANGE: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.MortgageEnum = {
  MORTGAGE_ENUM_UNKNOWN: 0,
  MORTGAGE_ENUM_CONVENTIONAL: 1,
  MORTGAGE_ENUM_FHA: 2,
  MORTGAGE_ENUM_LOCAL_AGENCY: 3,
  MORTGAGE_ENUM_OTHER: 4,
  MORTGAGE_ENUM_PUBLIC_AND_INDIAN_HOUSING: 5,
  MORTGAGE_ENUM_STATE_AGENCY: 6,
  MORTGAGE_ENUM_USDA_RURAL_DEVELOPMENT: 7,
  MORTGAGE_ENUM_VA: 8
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.BooleanTypeEnum = {
  BOOLEAN_TYPE_ENUM_UNKNOWN: 0,
  BOOLEAN_TYPE_ENUM_YES: 1,
  BOOLEAN_TYPE_ENUM_NO: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LienPriorityEnum = {
  LIEN_PRIORITY_ENUM_UNKNOWN: 0,
  LIEN_PRIORITY_ENUM_FIRST_LIEN: 1,
  LIEN_PRIORITY_ENUM_FOURTH_LIEN: 2,
  LIEN_PRIORITY_ENUM_OTHER: 3,
  LIEN_PRIORITY_ENUM_SECOND_LIEN: 4,
  LIEN_PRIORITY_ENUM_THIRD_LIEN: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.IndexEnum = {
  INDEX_ENUM_UNKNOWN: 0,
  INDEX_ENUM_BANK_PRIME_LOAN: 1,
  INDEX_ENUM_CERTIFICATE_OF_DEPOSIT_INDEX: 2,
  INDEX_ENUM_CONSTANT_MATURITY_TREASURY: 3,
  INDEX_ENUM_COST_OF_SAVINGS_INDEX: 4,
  INDEX_ENUM_ELEVENTH_DISTRICT_COST_OF_FUNDS_INDEX: 5,
  INDEX_ENUM_LIBOR: 6,
  INDEX_ENUM_OTHER: 7,
  INDEX_ENUM_TREASURY_BILL: 8,
  INDEX_ENUM_TWELVE_MONTH_TREASURY_AVERAGE: 9
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeeToleranceCategoryType = {
  FEE_TOLERANCE_CATEGORY_TYPE_UNKNOWN: 0,
  FEE_TOLERANCE_CATEGORY_TYPE_CAN_CHANGE: 1,
  FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE: 2,
  FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_INCREASE: 3,
  FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.NotificationSourceEnum = {
  NOTIFICATION_SOURCE_ENUM_UNKNOWN: 0,
  NOTIFICATION_SOURCE_ENUM_NEW_LOAN: 1,
  NOTIFICATION_SOURCE_ENUM_NEW_BORROWER: 2,
  NOTIFICATION_SOURCE_ENUM_DOCUMENT_ADDED_BY_BORROWER: 3,
  NOTIFICATION_SOURCE_ENUM_APPLICATION_SUBMITTED: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DesktopOriginatorSubmissionType = {
  DESKTOP_ORIGINATOR_SUBMISSION_TYPE_UNKNOWN: 0,
  DESKTOP_ORIGINATOR_SUBMISSION_TYPE_PRELIM: 1,
  DESKTOP_ORIGINATOR_SUBMISSION_TYPE_INTERIM: 2,
  DESKTOP_ORIGINATOR_SUBMISSION_TYPE_FINAL: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeesAdjustmentsCreditsSectionType = {
  FEES_ADJUSTMENTS_CREDITS_SECTION_TYPE_UNKNOWN: 0,
  FEES_ADJUSTMENTS_CREDITS_SECTION_TYPE_LENDER_CREDITS: 1,
  FEES_ADJUSTMENTS_CREDITS_SECTION_TYPE_INTERESTED_PARTY_CREDITS: 2,
  FEES_ADJUSTMENTS_CREDITS_SECTION_TYPE_PRORATIONS: 3,
  FEES_ADJUSTMENTS_CREDITS_SECTION_TYPE_EARNEST_MONEY_DEPOSIT: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.FeesOriginationDiscountSectionType = {
  FEES_ORIGINATION_DISCOUNT_SECTION_TYPE_UNKNOWN: 0,
  FEES_ORIGINATION_DISCOUNT_SECTION_TYPE_ORIGINATION_AND_DISCOUNT_POINTS: 1,
  FEES_ORIGINATION_DISCOUNT_SECTION_TYPE_OTHER_DISCOUNT_POINT_RELATED_FIELDS: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.BorrowerResidencyEnum = {
  BORROWER_RESIDENCY_ENUM_UNKNOWN: 0,
  BORROWER_RESIDENCY_ENUM_CURRENT: 1,
  BORROWER_RESIDENCY_ENUM_PRIOR: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.BorrowerResidencyBasisEnum = {
  BORROWER_RESIDENCY_BASIS_ENUM_UNKNOWN: 0,
  BORROWER_RESIDENCY_BASIS_ENUM_LIVING_RENT_FREE: 1,
  BORROWER_RESIDENCY_BASIS_ENUM_OWN: 2,
  BORROWER_RESIDENCY_BASIS_ENUM_RENT: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OwnedPropertyDispositionStatusEnum = {
  OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_UNKNOWN: 0,
  OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_PENDING_SALE: 1,
  OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_RETAIN: 2,
  OWNED_PROPERTY_DISPOSITION_STATUS_ENUM_SOLD: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PropertyCurrentUsageEnum = {
  PROPERTY_CURRENT_USAGE_ENUM_UNKNOWN: 0,
  PROPERTY_CURRENT_USAGE_ENUM_INVESTMENT: 1,
  PROPERTY_CURRENT_USAGE_ENUM_OTHER: 2,
  PROPERTY_CURRENT_USAGE_ENUM_PRIMARY_RESIDENCE: 3,
  PROPERTY_CURRENT_USAGE_ENUM_SECOND_HOME: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.PropertyDispositionStatusEnum = {
  PROPERTY_DISPOSITION_STATUS_ENUM_UNKNOWN: 0,
  PROPERTY_DISPOSITION_STATUS_ENUM_OTHER: 1,
  PROPERTY_DISPOSITION_STATUS_ENUM_RETAIN: 2,
  PROPERTY_DISPOSITION_STATUS_ENUM_SELL: 3,
  PROPERTY_DISPOSITION_STATUS_ENUM_UNDECIDED: 4,
  PROPERTY_DISPOSITION_STATUS_ENUM_VACATE: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProductPriceVendorEnum = {
  PRODUCT_PRICE_VENDOR_ENUM_UNKNOWN: 0,
  PRODUCT_PRICE_VENDOR_ENUM_LOAN_PASS: 1,
  PRODUCT_PRICE_VENDOR_ENUM_POLLY: 2,
  PRODUCT_PRICE_VENDOR_ENUM_OPTIMAL_BLUE: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.ProductPriceProductStatusEnum = {
  PRODUCT_PRICE_PRODUCT_STATUS_ENUM_UNKNOWN: 0,
  PRODUCT_PRICE_PRODUCT_STATUS_ENUM_ELIGIBLE: 1,
  PRODUCT_PRICE_PRODUCT_STATUS_ENUM_NEEDS_MORE_DATA: 2,
  PRODUCT_PRICE_PRODUCT_STATUS_ENUM_INELIGIBLE: 3,
  PRODUCT_PRICE_PRODUCT_STATUS_ENUM_ERROR: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AddressUsage = {
  ADDRESS_USAGE_UNKNOWN: 0,
  ADDRESS_USAGE_CURRENT: 1,
  ADDRESS_USAGE_PREVIOUS: 2,
  ADDRESS_USAGE_MAILING: 3,
  ADDRESS_USAGE_OWNED_PROPERTY: 4,
  ADDRESS_USAGE_SUBJECT_PROPERTY: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.AddressFormatEnum = {
  ADDRESS_FORMAT_ENUM_UNKNOWN: 0,
  ADDRESS_FORMAT_ENUM_HIGHWAY_CONTRACT: 1,
  ADDRESS_FORMAT_ENUM_INTERNATIONAL: 2,
  ADDRESS_FORMAT_ENUM_MILITARY: 3,
  ADDRESS_FORMAT_ENUM_OTHER: 4,
  ADDRESS_FORMAT_ENUM_POST_OFFICE_BOX: 5,
  ADDRESS_FORMAT_ENUM_RURAL_ROUTE: 6,
  ADDRESS_FORMAT_ENUM_US_STANDARD: 7
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.DocumentSourceType = {
  DOCUMENT_SOURCE_TYPE_UNKNOWN: 0,
  DOCUMENT_SOURCE_TYPE_MANUAL: 1,
  DOCUMENT_SOURCE_TYPE_INTEGRATION: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.LoanProductAdvisorLoanProcessingStageEnum = {
  LOAN_PRODUCT_ADVISOR_LOAN_PROCESSING_STAGE_ENUM_UNKNOWN: 0,
  LOAN_PRODUCT_ADVISOR_LOAN_PROCESSING_STAGE_ENUM_APPLICATION: 1,
  LOAN_PRODUCT_ADVISOR_LOAN_PROCESSING_STAGE_ENUM_FINAL_DISPOSITION: 2,
  LOAN_PRODUCT_ADVISOR_LOAN_PROCESSING_STAGE_ENUM_POST_CLOSING_QUALITY_CONTROL: 3,
  LOAN_PRODUCT_ADVISOR_LOAN_PROCESSING_STAGE_ENUM_PREQUALIFICATION: 4,
  LOAN_PRODUCT_ADVISOR_LOAN_PROCESSING_STAGE_ENUM_UNDERWRITING: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OwnedPropertyRentalIncomeDocumentationSource = {
  OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_UNKNOWN: 0,
  OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_TAX_RETURN: 1,
  OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_LEASE_AGREEMENT: 2,
  OWNED_PROPERTY_RENTAL_INCOME_DOCUMENTATION_SOURCE_MARKET_RENT: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OwnedPropertyLienInstallmentItemization = {
  OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_UNKNOWN: 0,
  OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_NON_ITEMIZED: 1,
  OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_ITEMIZED: 2,
  OWNED_PROPERTY_LIEN_INSTALLMENT_ITEMIZATION_ASSOCIATED_LIABILITIES: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OwnedPropertyMaintenanceExpenseItemization = {
  OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_UNKNOWN: 0,
  OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_NON_ITEMIZED: 1,
  OWNED_PROPERTY_MAINTENANCE_EXPENSE_ITEMIZATION_ITEMIZED: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.MortgageNotificationMethod = {
  MORTGAGE_NOTIFICATION_METHOD_UNKNOWN: 0,
  MORTGAGE_NOTIFICATION_METHOD_SMS: 1,
  MORTGAGE_NOTIFICATION_METHOD_EMAIL: 2,
  MORTGAGE_NOTIFICATION_METHOD_MICROSOFT_TEAMS: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage.OwnedPropertyHasRentalIncome = {
  OWNED_PROPERTY_HAS_RENTAL_INCOME_UNKNOWN: 0,
  OWNED_PROPERTY_HAS_RENTAL_INCOME_YES: 1,
  OWNED_PROPERTY_HAS_RENTAL_INCOME_NO: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage);
