import type {
  LoanEscrowFee,
  LoanEscrowFeeCategory,
  LoanPrepaidFee,
  LoanPrepaidFeeCategory,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { cleanAndConvertCurrency } from '@/Utils/Helpers/numberFormatter';
import { ConvertNumberToProtoDecimal } from '@/Utils/Helpers/protoDecimalConversion';

export function getOriginalFeesList(
  copyloanFees: Array<LoanEscrowFeeCategory.AsObject> | Array<LoanPrepaidFeeCategory.AsObject>,
  fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject,
) {
  return copyloanFees.map((category) => {
    const feeIndex = category.feesList.findIndex((f) => f.feeId === fee.feeId);
    if (feeIndex !== -1) {
      const updatedFeesList = [...category.feesList];
      return updatedFeesList[feeIndex];
    }
    return category;
  });
}

export function calculateOverrides(
  controlName: string,
  fee: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject,
  copyloanEscrowFees: Array<LoanEscrowFeeCategory.AsObject> | Array<LoanPrepaidFeeCategory.AsObject>,
  value?: string | undefined,
) {
  if (controlName === 'manualOverrideIndicator') {
    fee.manualOverrideIndicator = value === 'true';
    if (value === 'true') {
      fee.total = undefined;
      fee.borrowerPaid = undefined;
      fee.lenderPaid = undefined;
      fee.sellerPaid = undefined;
      fee.timeframe = 0;
      fee.manualOverrideTotalAmount = undefined;
      fee.manualOverrideTimeframe = 0;
    } else if (value === 'false') {
      // revert changes from original Fee using original copy data
      const originalValues = getOriginalFeesList(copyloanEscrowFees, fee);
      const selectedFee = originalValues[0] as LoanEscrowFee.AsObject;
      fee.manualOverrideTotalAmount = undefined;
      fee.manualOverrideTimeframe = 0;
      fee.total = selectedFee.calculatedTotalAmount;
      fee.amount = selectedFee.amount;
      fee.timeframe = Number(selectedFee.calculatedTimeframe);
    }
  }

  if (controlName === 'manualOverrideTotalAmount') {
    if (value) {
      fee.manualOverrideTotalAmount = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
      fee.total = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
      fee.borrowerPaid = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
      fee.timeframe = 0;
    } else {
      fee.manualOverrideTotalAmount = undefined;
    }
  }

  if (controlName === 'manualOverrideTimeframe') {
    if (value) {
      fee.total = ConvertNumberToProtoDecimal(Number(cleanAndConvertCurrency(fee.amount)) * Number(value)).toObject();
      fee.borrowerPaid = ConvertNumberToProtoDecimal(Number(cleanAndConvertCurrency(fee.amount)) * Number(value)).toObject();
      fee.manualOverrideTimeframe = Number(value);
      fee.timeframe = Number(value);
    } else {
      fee.manualOverrideTimeframe = 0;
      fee.manualOverrideTotalAmount = undefined;
    }
  }

  if (controlName === 'clear') {
    fee.total = undefined;
    fee.borrowerPaid = undefined;
    fee.lenderPaid = undefined;
    fee.sellerPaid = undefined;
    fee.timeframe = 0;
    fee.manualOverrideTotalAmount = undefined;
    fee.manualOverrideTimeframe = 0;
  }

  return fee;
}
