import { useQuery } from '@tanstack/react-query';

import { GetLoanFeeTemplatesQueryRequest, GetLoanFeeTemplatesQueryResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Queries_pb';
import { PaginationConfig } from '@/API/Models/Wilqo.Shared.Models/PaginationModels_pb';
import type { TableSort } from '@/Components/Features/table';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

export const LOAN_FEE_TEMPLATES_MESSAGE_NAME = 'wilqo.api.mortgage_config.GetLoanFeeTemplatesQueryRequest';

interface ILoanFeeTemplates {
  showArchived: boolean;
  page: number;
  pageSize: number;
  sort?: TableSort;
  search: string;
}

const useLoanFeeTemplates = (props: ILoanFeeTemplates) => {
  const { page, pageSize, search = '', showArchived, sort } = props;
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery([LOAN_FEE_TEMPLATES_MESSAGE_NAME, bpdId, showArchived, page, pageSize, sort, search], async () => {
    const request = new GetLoanFeeTemplatesQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setShowArchived(showArchived);
    if (search) request.setSearchTerm(search);
    const pagination = new PaginationConfig();
    pagination.setCurrentPage(page);
    pagination.setPageLength(pageSize);

    if (sort) {
      pagination.setSortAscending(sort.isAscending);
      pagination.setSortField(sort?.columnId);
    }
    request.setPageConfig(pagination);
    return sendMessage<GetLoanFeeTemplatesQueryResponse.AsObject>(
      {
        msg: request,
        msgName: LOAN_FEE_TEMPLATES_MESSAGE_NAME,
      },
      GetLoanFeeTemplatesQueryResponse,
    );
  }, { enabled: Boolean(bpdId) });
};
export { useLoanFeeTemplates };
