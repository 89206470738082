import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetLenderConditionsByBPIdQueryRequest, GetLenderConditionsByBPIdQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetLenderConditionsByBPIdQueryRequest';

export const useLenderConditions = (dealId?: string, archived = false) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, dealId, archived], async () => {
    const request = new GetLenderConditionsByBPIdQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setDealId(dealId || '');
    request.setIncludeArchived(archived);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetLenderConditionsByBPIdQueryResponse();
    GetLenderConditionsByBPIdQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(
      MESSAGE_NAME,
      responseObj.error,
      responseObj.lenderConditionsList,
    );
  });
};
