import { useMutation } from '@tanstack/react-query';

import type { LoanPage } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb';
import type { CardListItemWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CardListItem_pb';
import type { EditableTableWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/EditableTable_pb';
import type { FormWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb';
import type { FusionWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Fusion_pb';
import type { MosaicWidgetContainerSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { DealWriteCommandResponse, FullPageSaveCommandRequest, FullPageSaveItem, SaveFieldItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useWilqoMessage } from '@/API/Queries/useWilqoMessage';
import { type DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { IUseWidgetContextValues } from '@/Components/Widgets/content/WidgetContext';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

interface UpdateObject {
  dealId: string;
  dealVersion: number;
  page: LoanPage.AsObject;
  pageScopeToken: string;
  values: DynamicDataElementValues;
  hiddenFieldIds?: string[];
  hiddenWidgetIds?: string[];
  createNew: boolean;
}

// editable table form ids will follow this structure editable-scopeToken-fieldId
const getEditableTable = (id: string, widget: EditableTableWidgetSettings.AsObject, values: DynamicDataElementValues) => {
  const widgetKey = Object.keys(values).find((key) => {
    const [, , widgetId] = key.split('@');
    if (widgetId === id) {
      const value = values[key].dynamic;
      return Boolean(value);
    }
    return false;
  });
  if (widgetKey) {
    const item = new FullPageSaveItem();
    item.setWidgetId(id);
    const itemsList = Object.keys(values).reduce((prev: Array<SaveFieldItem>, key) => {
      const [isEditable, , widgetId, , fieldId] = key.split('@');
      if (isEditable === 'editable' && widgetId === id && values[key].dynamic) {
        const item = new SaveFieldItem();
        item.setFieldId(fieldId);
        item.setValue(values[key].dynamic);
        return [...prev, item];
      }
      return prev;
    }, []);
    const currentScopeToken = widgetKey?.split('@')[3];
    item.setWidgetScopeToken(currentScopeToken || '');
    item.setItemsList(itemsList);
    return item;
  }
  return null;
};

const getFusionWidget = (pageScopeToken: string, id: string, values: DynamicDataElementValues, widget?: FusionWidgetSettings.AsObject) => {
  const fieldIdsList = widget?.switchOptionsList.map((f) => f.id) ?? [];
  if (widget?.switchField?.virtualFieldIdentifier?.value) fieldIdsList.push(widget?.switchField?.virtualFieldIdentifier?.value);

  const item = new FullPageSaveItem();
  item.setWidgetId(id);
  item.setWidgetScopeToken(pageScopeToken);
  const fields = Object.keys(values)
    .filter((fieldKey) => fieldIdsList.some((fieldId) => fieldKey === fieldId))
    .reduce((acc: Array<SaveFieldItem>, fieldId) => {
      const fieldValue = values[fieldId]?.dynamic;
      if (!fieldValue) return acc;
      const field = new SaveFieldItem();
      field.setFieldId(fieldId);
      field.setValue(values[fieldId].dynamic);
      return [...acc, field];
    }, []);
  item.setItemsList(fields);
  return item;
};
const getFormWidget = (pageScopeToken: string, id: string, values: DynamicDataElementValues, widget?: FormWidgetSettings.AsObject) => {
  const item = new FullPageSaveItem();
  item.setWidgetId(id);
  item.setWidgetScopeToken(pageScopeToken);
  const fields = Object.keys(values)
    .filter((fieldId) => widget?.fieldsList
      .some((field) => field.id === fieldId)).reduce((acc: Array<SaveFieldItem>, fieldId) => {
        const fieldValue = values[fieldId]?.dynamic;
        if (!fieldValue) return acc;
        const field = new SaveFieldItem();
        field.setFieldId(fieldId);
        field.setValue(values[fieldId].dynamic);
        return [...acc, field];
      }, []);
  item.setItemsList(fields);
  return item;
};
const getCardListWidget = (pageScopeToken: string, id: string, values: DynamicDataElementValues, widget?: CardListItemWidgetSettings.AsObject) => {
  const item = new FullPageSaveItem();
  item.setWidgetId(id);
  item.setWidgetScopeToken(widget?.scopeToken || pageScopeToken);
  const fields = Object.keys(values)
    .filter((fieldId) => widget?.fieldsList
      .some((field) => field.id === fieldId)).reduce((acc: Array<SaveFieldItem>, fieldId) => {
        const fieldValue = values[fieldId]?.dynamic;
        if (!fieldValue) return acc;
        const field = new SaveFieldItem();
        field.setFieldId(fieldId);
        field.setValue(values[fieldId].dynamic);
        return [...acc, field];
      }, []);
  item.setItemsList(fields);
  return item;
};

const getMosaicWidget = (pageScopeToken: string, id: string, values: DynamicDataElementValues, widget: MosaicWidgetContainerSettings.AsObject) => widget.cardListItemWidgetsList.reduce((prev: Array<FullPageSaveItem>, cardListItemWidget) => {
  const pageItem = new FullPageSaveItem();
  pageItem.setWidgetId(cardListItemWidget.id || widget.id);
  pageItem.setWidgetScopeToken(pageScopeToken);
  const fields = cardListItemWidget.fieldsList.reduce((prev: Array<SaveFieldItem>, field) => {
    const fieldItem = new SaveFieldItem();
    const value = values[field.id]?.dynamic || values[field.panelElement?.id || '']?.dynamic;
    if (value && value.getDataType() !== DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
      fieldItem.setFieldId(field.id);
      fieldItem.setValue(value);
      return [...prev, fieldItem];
    }
    return prev;
  }, []);
  if (fields.length === 0) return prev;
  pageItem.setItemsList(fields);
  return [...prev, pageItem];
}, []);

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest';

const getCreateNew = ({ page: { widgetsList }, values }: UpdateObject) => {
  const currentWidget = widgetsList[0];
  if (!currentWidget?.editableTable) return false;
  return Object.keys(values).some((key) => {
    const currentKeyWidgetId = key.split('@')[2];
    if (currentKeyWidgetId === currentWidget.id && key.includes('new')) {
      return Boolean(values[key].dynamic);
    }
    return false;
  });
};

const buildItemslistToSave = (updateObject: UpdateObject) => {
  const filteredValues = updateObject.values;
  return updateObject.page.widgetsList.reduce((items: Array<FullPageSaveItem>, w) => {
    if (updateObject.hiddenWidgetIds?.includes(w.id)) return items;
    let item;
    if (w.cardListItem) item = getCardListWidget(updateObject.pageScopeToken, w.id, filteredValues, w.cardListItem);
    if (w.editableTable) item = getEditableTable(w.id, w.editableTable, filteredValues);
    if (w.form) item = getFormWidget(updateObject.pageScopeToken, w.id, filteredValues, w.form);
    if (w.fusionWidget) item = getFusionWidget(updateObject.pageScopeToken, w.id, filteredValues, w.fusionWidget);
    if (w.mosaicWidgetContainer) {
      const mosaicItems = getMosaicWidget(updateObject.pageScopeToken, w.id, filteredValues, w.mosaicWidgetContainer);
      return [...items, ...mosaicItems];
    }
    if (item) {
      return [...items, item];
    }
    return items;
  }, []);
};

const useSendUpdate = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation(async (updateObject: UpdateObject) => {
    const request = new FullPageSaveCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setDealVersion(updateObject.dealVersion);
    request.setBusinessProcessDomainId(bpdId);
    request.setPageId(updateObject.page.id || '');
    request.setItemsList(buildItemslistToSave(updateObject));
    const isCreateNew = updateObject.createNew || getCreateNew(updateObject);
    request.setCreateNew(isCreateNew);
    return sendMessage<DealWriteCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      DealWriteCommandResponse,
    );
  });
};

export const useDynamicPageSave = (widgetValues?: IUseWidgetContextValues) => {
  const { mutateAsync: sendUpdate } = useSendUpdate();

  return useMutation<DealWriteCommandResponse.AsObject | undefined, Error, UpdateObject, any>(async (updateObject: UpdateObject) => {
    let result: DealWriteCommandResponse.AsObject | undefined;

    const savingWidgetIds = buildItemslistToSave(updateObject)
      .reduce(
        (acc, currentItem) => (currentItem.getItemsList().length ? [...acc, currentItem.getWidgetId()] : acc),
        [] as Array<string>,
      );
    widgetValues?.setSavingWidgetIds(savingWidgetIds);

    const onSuccess = () => {
      widgetValues?.setSavingWidgetIds([]);
      widgetValues?.setEditingWidgetIds([]);
    };

    if (updateObject.page.widgetsList.some((w) => Boolean(w.editableTable))) {
      // eslint-disable-next-line no-restricted-syntax
      for (const widget of updateObject.page.widgetsList) {
        if (widget.form || widget.mosaicWidgetContainer || widget.editableTable || widget.cardListItem) {
          // eslint-disable-next-line no-await-in-loop
          const response = await sendUpdate(
            {
              ...updateObject,
              createNew: updateObject.createNew,
              dealVersion: result?.dealVersion || updateObject.dealVersion,
              page: {
                ...updateObject.page,
                widgetsList: [widget],
              },
            },
            {
              onSuccess() {
                // if last widget call onSuccess
                if (widget.id === updateObject.page.widgetsList.at(-1)?.id) onSuccess();
              },
            },
          );
          result = response;
        }
      }
      return result;
    }
    return sendUpdate(updateObject, { onSuccess });
  });
};
