import type { ReactNode } from 'react';

import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';

interface IPermissionCheckProps {
  permissionKey: string;
  children: ReactNode;
}

const PermissionCheck = (props: IPermissionCheckProps) => {
  const { children, permissionKey } = props;
  const { feePermissions } = useFeesContext();

  const hasPermission = (permissionKey: string): boolean => {
    if (!feePermissions) return false;
    const value: {
      [key: string]: boolean;
    } = feePermissions;
    return value[permissionKey];
  };

  return hasPermission(permissionKey) ? <div>{children}</div> : <div />;
};

export default PermissionCheck;
