import type { AutoCompleteProps as ComponentProps } from './AutoComplete.component';
import Component from './AutoComplete.component';
import type { IAutoCompleteProps as DDEProps } from './AutoComplete.dde';
import DDE from './AutoComplete.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
}

export type AutoCompleteProps = ExtendedComponentProps | ExtendedDDEProps;

const AutoComplete = (props: AutoCompleteProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { AutoComplete };
