import { useQuery } from '@tanstack/react-query';

import { GetIntegrationHistoryQueryRequest, GetIntegrationHistoryQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetIntegrationHistoryQueryRequest';

const useLoanintegrationHistory = (dealId: string) => {
  const sendMessage = useWilqoMessage();

  return useQuery([MESSAGE_NAME, dealId], async () => {
    const request = new GetIntegrationHistoryQueryRequest();
    request.setDealId(dealId);

    const response = await sendMessage<GetIntegrationHistoryQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetIntegrationHistoryQueryResponse,
    );
    return response?.integrationHistoryList;
  }, { enabled: Boolean(dealId) });
};
export { MESSAGE_NAME as INTEGRATION_HISTORY_MESSAGE_NAME, useLoanintegrationHistory };
