import type { ComponentProps } from './RadioGroup.component';
import Component from './RadioGroup.component';
import type { IRadioGroupProps as DDEProps } from './RadioGroup.dde';
import DDE from './RadioGroup.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
}

type RadioGroupProps = ExtendedComponentProps | ExtendedDDEProps;

const RadioGroup = (props: RadioGroupProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { RadioGroup };
