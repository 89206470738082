import { Dialog } from './Dialog';
import { DialogContent } from './DialogContent';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { DialogLayout } from './DialogLayout';
import { DialogSidesheet } from './DialogSideSheet';

export const DialogRoot = {
  Content: DialogContent,
  Footer: DialogFooter,
  Header: DialogHeader,
  Layout: DialogLayout,
  Root: Dialog,
  Sheet: DialogSidesheet,
};

export {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogLayout,
  DialogSidesheet,
};
