interface IconProps {
  className?: string;
}

const ConnectedBoxes = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* First box */}
    <rect fill="transparent" height="8" stroke="#666666" width="6" x="2" y="3" />
    <rect fill="transparent" height="6" stroke="#666666" width="4" x="3" y="4" />

    {/* Second box */}
    <rect fill="transparent" height="8" stroke="#666666" width="6" x="17" y="3" />
    <rect fill="transparent" height="6" stroke="#666666" width="4" x="18" y="4" />

    {/* Third box */}
    <rect fill="transparent" height="8" stroke="#666666" width="6" x="17" y="15" />
    <rect fill="transparent" height="6" stroke="#666666" width="4" x="18" y="16" />

    {/* top line */}
    <rect fill="transparent" height="1" stroke="#666666" width="7" x="9" y="6" />

    {/* connecting line */}
    <rect fill="transparent" height="9" stroke="#666666" width="1" x="12" y="8" />

    {/* bottom line */}
    <rect fill="transparent" height="1" stroke="#666666" width="4" x="12" y="18" />
  </svg>
);

export default ConnectedBoxes;
