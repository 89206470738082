// source: Wilqo.Shared.Models/RenderableValue.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.wilqo.shared.models.DateFormatTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayRenderableValue', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeAssociationSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFormatTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeBoolSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeCurrencySettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeDateSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeDecimalSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeEnumerationSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeGenerateSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypePeriodSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectAdminSource', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectCommonSource', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectSettings.SourceCase', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectSourceStatic', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeSelectStrategy', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeStringMaskType', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DisplayTypeStringSettings', null, global);
goog.exportSymbol('proto.wilqo.shared.models.PeriodFormatType', null, global);
goog.exportSymbol('proto.wilqo.shared.models.RenderableValue', null, global);
goog.exportSymbol('proto.wilqo.shared.models.RenderableValue.OverrideSettingsCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.RenderableValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.shared.models.RenderableValue.oneofGroups_);
};
goog.inherits(proto.wilqo.shared.models.RenderableValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.RenderableValue.displayName = 'proto.wilqo.shared.models.RenderableValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeBoolSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeBoolSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeBoolSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeCurrencySettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeCurrencySettings.displayName = 'proto.wilqo.shared.models.DisplayTypeCurrencySettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeDateSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeDateSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeDateSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeDateSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeDecimalSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeDecimalSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeDecimalSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DisplayTypeEnumerationSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeEnumerationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeEnumerationSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeEnumerationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypePeriodSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypePeriodSettings.displayName = 'proto.wilqo.shared.models.DisplayTypePeriodSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeStringSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeStringSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeStringSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeStringSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeAssociationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeAssociationSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeAssociationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.displayName = 'proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.displayName = 'proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeSelectSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeSelectSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeSelectSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeGenerateSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeGenerateSettings.displayName = 'proto.wilqo.shared.models.DisplayTypeGenerateSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.displayName = 'proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayTypeSelectSourceStatic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.displayName = 'proto.wilqo.shared.models.DisplayTypeSelectSourceStatic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DisplayRenderableValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DisplayRenderableValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DisplayRenderableValue.displayName = 'proto.wilqo.shared.models.DisplayRenderableValue';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.shared.models.RenderableValue.oneofGroups_ = [[3,4,5,6,7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.wilqo.shared.models.RenderableValue.OverrideSettingsCase = {
  OVERRIDE_SETTINGS_NOT_SET: 0,
  CURRENCY: 3,
  ENUMERATION: 4,
  DECIMAL: 5,
  DATE: 6,
  BOOLEAN: 7,
  RELATED: 8,
  SELECT: 9,
  GENERATE: 10,
  STRING: 11,
  PERIOD: 12
};

/**
 * @return {proto.wilqo.shared.models.RenderableValue.OverrideSettingsCase}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getOverrideSettingsCase = function() {
  return /** @type {proto.wilqo.shared.models.RenderableValue.OverrideSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.RenderableValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.RenderableValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.RenderableValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.RenderableValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    displayType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: (f = msg.getCurrency()) && proto.wilqo.shared.models.DisplayTypeCurrencySettings.toObject(includeInstance, f),
    enumeration: (f = msg.getEnumeration()) && proto.wilqo.shared.models.DisplayTypeEnumerationSettings.toObject(includeInstance, f),
    decimal: (f = msg.getDecimal()) && proto.wilqo.shared.models.DisplayTypeDecimalSettings.toObject(includeInstance, f),
    date: (f = msg.getDate()) && proto.wilqo.shared.models.DisplayTypeDateSettings.toObject(includeInstance, f),
    pb_boolean: (f = msg.getBoolean()) && proto.wilqo.shared.models.DisplayTypeBoolSettings.toObject(includeInstance, f),
    related: (f = msg.getRelated()) && proto.wilqo.shared.models.DisplayTypeAssociationSettings.toObject(includeInstance, f),
    select: (f = msg.getSelect()) && proto.wilqo.shared.models.DisplayTypeSelectSettings.toObject(includeInstance, f),
    generate: (f = msg.getGenerate()) && proto.wilqo.shared.models.DisplayTypeGenerateSettings.toObject(includeInstance, f),
    string: (f = msg.getString()) && proto.wilqo.shared.models.DisplayTypeStringSettings.toObject(includeInstance, f),
    period: (f = msg.getPeriod()) && proto.wilqo.shared.models.DisplayTypePeriodSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.shared.models.RenderableValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.RenderableValue;
  return proto.wilqo.shared.models.RenderableValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.RenderableValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.shared.models.RenderableValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.DisplayTypeEnum} */ (reader.readEnum());
      msg.setDisplayType(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.DisplayTypeCurrencySettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeCurrencySettings.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 4:
      var value = new proto.wilqo.shared.models.DisplayTypeEnumerationSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeEnumerationSettings.deserializeBinaryFromReader);
      msg.setEnumeration(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.DisplayTypeDecimalSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeDecimalSettings.deserializeBinaryFromReader);
      msg.setDecimal(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.DisplayTypeDateSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeDateSettings.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 7:
      var value = new proto.wilqo.shared.models.DisplayTypeBoolSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeBoolSettings.deserializeBinaryFromReader);
      msg.setBoolean(value);
      break;
    case 8:
      var value = new proto.wilqo.shared.models.DisplayTypeAssociationSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeAssociationSettings.deserializeBinaryFromReader);
      msg.setRelated(value);
      break;
    case 9:
      var value = new proto.wilqo.shared.models.DisplayTypeSelectSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeSelectSettings.deserializeBinaryFromReader);
      msg.setSelect(value);
      break;
    case 10:
      var value = new proto.wilqo.shared.models.DisplayTypeGenerateSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeGenerateSettings.deserializeBinaryFromReader);
      msg.setGenerate(value);
      break;
    case 11:
      var value = new proto.wilqo.shared.models.DisplayTypeStringSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeStringSettings.deserializeBinaryFromReader);
      msg.setString(value);
      break;
    case 12:
      var value = new proto.wilqo.shared.models.DisplayTypePeriodSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypePeriodSettings.deserializeBinaryFromReader);
      msg.setPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.RenderableValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.RenderableValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.RenderableValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.RenderableValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getDisplayType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.shared.models.DisplayTypeCurrencySettings.serializeBinaryToWriter
    );
  }
  f = message.getEnumeration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.shared.models.DisplayTypeEnumerationSettings.serializeBinaryToWriter
    );
  }
  f = message.getDecimal();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.shared.models.DisplayTypeDecimalSettings.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.shared.models.DisplayTypeDateSettings.serializeBinaryToWriter
    );
  }
  f = message.getBoolean();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wilqo.shared.models.DisplayTypeBoolSettings.serializeBinaryToWriter
    );
  }
  f = message.getRelated();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.shared.models.DisplayTypeAssociationSettings.serializeBinaryToWriter
    );
  }
  f = message.getSelect();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.shared.models.DisplayTypeSelectSettings.serializeBinaryToWriter
    );
  }
  f = message.getGenerate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.wilqo.shared.models.DisplayTypeGenerateSettings.serializeBinaryToWriter
    );
  }
  f = message.getString();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.wilqo.shared.models.DisplayTypeStringSettings.serializeBinaryToWriter
    );
  }
  f = message.getPeriod();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.wilqo.shared.models.DisplayTypePeriodSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional DynamicDataElement value = 1;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DisplayTypeEnum display_type = 2;
 * @return {!proto.wilqo.shared.models.DisplayTypeEnum}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getDisplayType = function() {
  return /** @type {!proto.wilqo.shared.models.DisplayTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeEnum} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.setDisplayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional DisplayTypeCurrencySettings currency = 3;
 * @return {?proto.wilqo.shared.models.DisplayTypeCurrencySettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getCurrency = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeCurrencySettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeCurrencySettings, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeCurrencySettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setCurrency = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DisplayTypeEnumerationSettings enumeration = 4;
 * @return {?proto.wilqo.shared.models.DisplayTypeEnumerationSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getEnumeration = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeEnumerationSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeEnumerationSettings, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeEnumerationSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setEnumeration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearEnumeration = function() {
  return this.setEnumeration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasEnumeration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DisplayTypeDecimalSettings decimal = 5;
 * @return {?proto.wilqo.shared.models.DisplayTypeDecimalSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getDecimal = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeDecimalSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeDecimalSettings, 5));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeDecimalSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setDecimal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearDecimal = function() {
  return this.setDecimal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasDecimal = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DisplayTypeDateSettings date = 6;
 * @return {?proto.wilqo.shared.models.DisplayTypeDateSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getDate = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeDateSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeDateSettings, 6));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeDateSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setDate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DisplayTypeBoolSettings boolean = 7;
 * @return {?proto.wilqo.shared.models.DisplayTypeBoolSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getBoolean = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeBoolSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeBoolSettings, 7));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeBoolSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setBoolean = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearBoolean = function() {
  return this.setBoolean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasBoolean = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DisplayTypeAssociationSettings related = 8;
 * @return {?proto.wilqo.shared.models.DisplayTypeAssociationSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getRelated = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeAssociationSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeAssociationSettings, 8));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeAssociationSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setRelated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearRelated = function() {
  return this.setRelated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasRelated = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DisplayTypeSelectSettings select = 9;
 * @return {?proto.wilqo.shared.models.DisplayTypeSelectSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getSelect = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeSelectSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeSelectSettings, 9));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeSelectSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setSelect = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearSelect = function() {
  return this.setSelect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasSelect = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional DisplayTypeGenerateSettings generate = 10;
 * @return {?proto.wilqo.shared.models.DisplayTypeGenerateSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getGenerate = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeGenerateSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeGenerateSettings, 10));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeGenerateSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setGenerate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearGenerate = function() {
  return this.setGenerate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasGenerate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DisplayTypeStringSettings string = 11;
 * @return {?proto.wilqo.shared.models.DisplayTypeStringSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getString = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeStringSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeStringSettings, 11));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeStringSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setString = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearString = function() {
  return this.setString(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasString = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DisplayTypePeriodSettings period = 12;
 * @return {?proto.wilqo.shared.models.DisplayTypePeriodSettings}
 */
proto.wilqo.shared.models.RenderableValue.prototype.getPeriod = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypePeriodSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypePeriodSettings, 12));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypePeriodSettings|undefined} value
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
*/
proto.wilqo.shared.models.RenderableValue.prototype.setPeriod = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.wilqo.shared.models.RenderableValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.RenderableValue} returns this
 */
proto.wilqo.shared.models.RenderableValue.prototype.clearPeriod = function() {
  return this.setPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.RenderableValue.prototype.hasPeriod = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeBoolSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeBoolSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    trueLabel: jspb.Message.getFieldWithDefault(msg, 1, ""),
    falseLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nullable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    fieldTypeOverride: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeBoolSettings}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeBoolSettings;
  return proto.wilqo.shared.models.DisplayTypeBoolSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeBoolSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeBoolSettings}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrueLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFalseLabel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNullable(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldTypeOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeBoolSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeBoolSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrueLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFalseLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNullable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFieldTypeOverride();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string true_label = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.getTrueLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeBoolSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.setTrueLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string false_label = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.getFalseLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeBoolSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.setFalseLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool nullable = 3;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.getNullable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeBoolSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.setNullable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string field_type_override = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.getFieldTypeOverride = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeBoolSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeBoolSettings.prototype.setFieldTypeOverride = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeCurrencySettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    decimalPlaces: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minValue: (f = msg.getMinValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxValue: (f = msg.getMaxValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeCurrencySettings;
  return proto.wilqo.shared.models.DisplayTypeCurrencySettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDecimalPlaces(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinValue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeCurrencySettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecimalPlaces();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 decimal_places = 1;
 * @return {number}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.getDecimalPlaces = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.setDecimalPlaces = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value min_value = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.getMinValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.setMinValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.clearMinValue = function() {
  return this.setMinValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.hasMinValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value max_value = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.getMaxValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.setMaxValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeCurrencySettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.clearMaxValue = function() {
  return this.setMaxValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeCurrencySettings.prototype.hasMaxValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeDateSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeDateSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    formatWithTimestamp: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    formatType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeDateSettings}
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeDateSettings;
  return proto.wilqo.shared.models.DisplayTypeDateSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeDateSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeDateSettings}
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFormatWithTimestamp(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.DateFormatTypeEnum} */ (reader.readEnum());
      msg.setFormatType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeDateSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeDateSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormatWithTimestamp();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFormatType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool format_with_timestamp = 1;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.prototype.getFormatWithTimestamp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDateSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.prototype.setFormatWithTimestamp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional DateFormatTypeEnum format_type = 2;
 * @return {!proto.wilqo.shared.models.DateFormatTypeEnum}
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.prototype.getFormatType = function() {
  return /** @type {!proto.wilqo.shared.models.DateFormatTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DateFormatTypeEnum} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDateSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDateSettings.prototype.setFormatType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeDecimalSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    decimalPlaces: jspb.Message.getFieldWithDefault(msg, 1, 0),
    formatAsPercent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    minValue: (f = msg.getMinValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxValue: (f = msg.getMaxValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    emptyAsZero: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeDecimalSettings;
  return proto.wilqo.shared.models.DisplayTypeDecimalSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDecimalPlaces(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFormatAsPercent(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinValue(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmptyAsZero(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeDecimalSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDecimalPlaces();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFormatAsPercent();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMinValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getEmptyAsZero();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 decimal_places = 1;
 * @return {number}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.getDecimalPlaces = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.setDecimalPlaces = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool format_as_percent = 2;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.getFormatAsPercent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.setFormatAsPercent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.Int32Value min_value = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.getMinValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.setMinValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.clearMinValue = function() {
  return this.setMinValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.hasMinValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value max_value = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.getMaxValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.setMaxValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.clearMaxValue = function() {
  return this.setMaxValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.hasMaxValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool empty_as_zero = 5;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.getEmptyAsZero = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeDecimalSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeDecimalSettings.prototype.setEmptyAsZero = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeEnumerationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    uppercase: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    unknownIsDefault: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    optionsOverrideList: jspb.Message.toObjectList(msg.getOptionsOverrideList(),
    proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.toObject, includeInstance),
    allowSelectUnknown: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    fieldTypeOverride: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeEnumerationSettings;
  return proto.wilqo.shared.models.DisplayTypeEnumerationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUppercase(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnknownIsDefault(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.deserializeBinaryFromReader);
      msg.addOptionsOverride(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSelectUnknown(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldTypeOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeEnumerationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUppercase();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUnknownIsDefault();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getOptionsOverrideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.serializeBinaryToWriter
    );
  }
  f = message.getAllowSelectUnknown();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFieldTypeOverride();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exclude: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings;
  return proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayText(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExclude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDisplayText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExclude();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional DynamicDataElement value = 1;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_text = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.getDisplayText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.setDisplayText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool exclude = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.getExclude = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings.prototype.setExclude = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool uppercase = 1;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.getUppercase = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.setUppercase = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool unknown_is_default = 2;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.getUnknownIsDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.setUnknownIsDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated OptionSettings options_override = 3;
 * @return {!Array<!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings>}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.getOptionsOverrideList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings>} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.setOptionsOverrideList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.addOptionsOverride = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.DisplayTypeEnumerationSettings.OptionSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.clearOptionsOverrideList = function() {
  return this.setOptionsOverrideList([]);
};


/**
 * optional bool allow_select_unknown = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.getAllowSelectUnknown = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.setAllowSelectUnknown = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string field_type_override = 5;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.getFieldTypeOverride = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeEnumerationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeEnumerationSettings.prototype.setFieldTypeOverride = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypePeriodSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypePeriodSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    format: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minValue: (f = msg.getMinValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxValue: (f = msg.getMaxValue()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypePeriodSettings;
  return proto.wilqo.shared.models.DisplayTypePeriodSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypePeriodSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.PeriodFormatType} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinValue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypePeriodSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypePeriodSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMinValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional PeriodFormatType format = 1;
 * @return {!proto.wilqo.shared.models.PeriodFormatType}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.getFormat = function() {
  return /** @type {!proto.wilqo.shared.models.PeriodFormatType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.PeriodFormatType} value
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value min_value = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.getMinValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.setMinValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.clearMinValue = function() {
  return this.setMinValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.hasMinValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value max_value = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.getMaxValue = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.setMaxValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypePeriodSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.clearMaxValue = function() {
  return this.setMaxValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypePeriodSettings.prototype.hasMaxValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeStringSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeStringSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    maskType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minLength: (f = msg.getMinLength()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxLength: (f = msg.getMaxLength()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    regex: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeStringSettings;
  return proto.wilqo.shared.models.DisplayTypeStringSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeStringSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.DisplayTypeStringMaskType} */ (reader.readEnum());
      msg.setMaskType(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinLength(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxLength(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeStringSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeStringSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaskType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMinLength();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxLength();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getRegex();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional DisplayTypeStringMaskType mask_type = 1;
 * @return {!proto.wilqo.shared.models.DisplayTypeStringMaskType}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.getMaskType = function() {
  return /** @type {!proto.wilqo.shared.models.DisplayTypeStringMaskType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeStringMaskType} value
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.setMaskType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value min_length = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.getMinLength = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.setMinLength = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.clearMinLength = function() {
  return this.setMinLength(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.hasMinLength = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value max_length = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.getMaxLength = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.setMaxLength = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.clearMaxLength = function() {
  return this.setMaxLength(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.hasMaxLength = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string regex = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.getRegex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeStringSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeStringSettings.prototype.setRegex = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeAssociationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    iteratorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemTextVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemValueVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    selectionType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    itemAddDetails: (f = msg.getItemAddDetails()) && proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeAssociationSettings;
  return proto.wilqo.shared.models.DisplayTypeAssociationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIteratorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemTextVirtualFieldId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemValueVirtualFieldId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFormatTypeEnum} */ (reader.readEnum());
      msg.setSelectionType(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.deserializeBinaryFromReader);
      msg.setItemAddDetails(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeAssociationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIteratorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemTextVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemValueVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSelectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getItemAddDetails();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFormatTypeEnum = {
  ASSOCIATION_FORMAT_TYPE_UNKNOWN: 0,
  ASSOCIATION_FORMAT_TYPE_SINGLE: 1,
  ASSOCIATION_FORMAT_TYPE_MULTIPLE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails;
  return proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string page_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allowedValuesList: jspb.Message.toObjectList(msg.getAllowedValuesList(),
    Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig;
  return proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.addAllowedValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllowedValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DynamicDataElement allowed_values = 2;
 * @return {!Array<!proto.wilqo.shared.models.DynamicDataElement>}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.getAllowedValuesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DynamicDataElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DynamicDataElement>} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} returns this
*/
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.setAllowedValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.addAllowedValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DynamicDataElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig.prototype.clearAllowedValuesList = function() {
  return this.setAllowedValuesList([]);
};


/**
 * optional string iterator_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.getIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.setIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string item_text_virtual_field_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.getItemTextVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.setItemTextVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_value_virtual_field_id = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.getItemValueVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.setItemValueVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AssociationFormatTypeEnum selection_type = 4;
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFormatTypeEnum}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.getSelectionType = function() {
  return /** @type {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFormatTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFormatTypeEnum} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.setSelectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional AssociationItemAddDetails item_add_details = 5;
 * @return {?proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.getItemAddDetails = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails, 5));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationItemAddDetails|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.setItemAddDetails = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.clearItemAddDetails = function() {
  return this.setItemAddDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.hasItemAddDetails = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AssociationFilterConfig filter = 6;
 * @return {?proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.getFilter = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig, 6));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeAssociationSettings.AssociationFilterConfig|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeAssociationSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeAssociationSettings.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_ = [[1000,1001,1002]];

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.SourceCase = {
  SOURCE_NOT_SET: 0,
  STATIC: 1000,
  ADMIN: 1001,
  COMMON: 1002
};

/**
 * @return {proto.wilqo.shared.models.DisplayTypeSelectSettings.SourceCase}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.getSourceCase = function() {
  return /** @type {proto.wilqo.shared.models.DisplayTypeSelectSettings.SourceCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeSelectSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    strategy: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pb_static: (f = msg.getStatic()) && proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.toObject(includeInstance, f),
    admin: jspb.Message.getFieldWithDefault(msg, 1001, 0),
    common: jspb.Message.getFieldWithDefault(msg, 1002, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeSelectSettings;
  return proto.wilqo.shared.models.DisplayTypeSelectSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.DisplayTypeSelectStrategy} */ (reader.readEnum());
      msg.setStrategy(value);
      break;
    case 1000:
      var value = new proto.wilqo.shared.models.DisplayTypeSelectSourceStatic;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.deserializeBinaryFromReader);
      msg.setStatic(value);
      break;
    case 1001:
      var value = /** @type {!proto.wilqo.shared.models.DisplayTypeSelectAdminSource} */ (reader.readEnum());
      msg.setAdmin(value);
      break;
    case 1002:
      var value = /** @type {!proto.wilqo.shared.models.DisplayTypeSelectCommonSource} */ (reader.readEnum());
      msg.setCommon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeSelectSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStatic();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.wilqo.shared.models.DisplayTypeSelectAdminSource} */ (jspb.Message.getField(message, 1001));
  if (f != null) {
    writer.writeEnum(
      1001,
      f
    );
  }
  f = /** @type {!proto.wilqo.shared.models.DisplayTypeSelectCommonSource} */ (jspb.Message.getField(message, 1002));
  if (f != null) {
    writer.writeEnum(
      1002,
      f
    );
  }
};


/**
 * optional DisplayTypeSelectStrategy strategy = 1;
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectStrategy}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.getStrategy = function() {
  return /** @type {!proto.wilqo.shared.models.DisplayTypeSelectStrategy} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectStrategy} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.setStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional DisplayTypeSelectSourceStatic static = 1000;
 * @return {?proto.wilqo.shared.models.DisplayTypeSelectSourceStatic}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.getStatic = function() {
  return /** @type{?proto.wilqo.shared.models.DisplayTypeSelectSourceStatic} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DisplayTypeSelectSourceStatic, 1000));
};


/**
 * @param {?proto.wilqo.shared.models.DisplayTypeSelectSourceStatic|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
*/
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.setStatic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1000, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.clearStatic = function() {
  return this.setStatic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.hasStatic = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * optional DisplayTypeSelectAdminSource admin = 1001;
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectAdminSource}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.getAdmin = function() {
  return /** @type {!proto.wilqo.shared.models.DisplayTypeSelectAdminSource} */ (jspb.Message.getFieldWithDefault(this, 1001, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectAdminSource} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.setAdmin = function(value) {
  return jspb.Message.setOneofField(this, 1001, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.clearAdmin = function() {
  return jspb.Message.setOneofField(this, 1001, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.hasAdmin = function() {
  return jspb.Message.getField(this, 1001) != null;
};


/**
 * optional DisplayTypeSelectCommonSource common = 1002;
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectCommonSource}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.getCommon = function() {
  return /** @type {!proto.wilqo.shared.models.DisplayTypeSelectCommonSource} */ (jspb.Message.getFieldWithDefault(this, 1002, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectCommonSource} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.setCommon = function(value) {
  return jspb.Message.setOneofField(this, 1002, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.clearCommon = function() {
  return jspb.Message.setOneofField(this, 1002, proto.wilqo.shared.models.DisplayTypeSelectSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeSelectSettings.prototype.hasCommon = function() {
  return jspb.Message.getField(this, 1002) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeGenerateSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeGenerateSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeGenerateSettings}
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeGenerateSettings;
  return proto.wilqo.shared.models.DisplayTypeGenerateSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeGenerateSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeGenerateSettings}
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeGenerateSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeGenerateSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool hidden = 1;
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DisplayTypeGenerateSettings} returns this
 */
proto.wilqo.shared.models.DisplayTypeGenerateSettings.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption;
  return proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayTypeSelectSourceStatic;
  return proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption;
      reader.readMessage(value,proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DisplayTypeSelectSourceStaticOption options = 1;
 * @return {!Array<!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption>}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption>} value
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic} returns this
*/
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption}
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.DisplayTypeSelectSourceStaticOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DisplayTypeSelectSourceStatic} returns this
 */
proto.wilqo.shared.models.DisplayTypeSelectSourceStatic.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DisplayRenderableValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DisplayRenderableValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayRenderableValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && proto.wilqo.shared.models.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue}
 */
proto.wilqo.shared.models.DisplayRenderableValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DisplayRenderableValue;
  return proto.wilqo.shared.models.DisplayRenderableValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DisplayRenderableValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue}
 */
proto.wilqo.shared.models.DisplayRenderableValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.RenderableValue;
      reader.readMessage(value,proto.wilqo.shared.models.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DisplayRenderableValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DisplayRenderableValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DisplayRenderableValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.shared.models.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string caption = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue} returns this
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string help_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue} returns this
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RenderableValue value = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue} returns this
*/
proto.wilqo.shared.models.DisplayRenderableValue.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DisplayRenderableValue} returns this
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DisplayRenderableValue.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeEnum = {
  DISPLAY_TYPE_ENUM_UNKNOWN: 0,
  DISPLAY_TYPE_ENUM_CURRENCY: 1,
  DISPLAY_TYPE_ENUM_DECIMAL: 2,
  DISPLAY_TYPE_ENUM_ENUMERATION: 3,
  DISPLAY_TYPE_ENUM_DATE: 4,
  DISPLAY_TYPE_ENUM_STRING: 5,
  DISPLAY_TYPE_ENUM_BOOL: 6,
  DISPLAY_TYPE_ENUM_ASSOCIATION: 8,
  DISPLAY_TYPE_ENUM_SELECT: 9,
  DISPLAY_TYPE_ENUM_GENERATE: 10,
  DISPLAY_TYPE_ENUM_PERIOD: 11
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DateFormatTypeEnum = {
  DATE_FORMAT_TYPE_DATE: 0,
  DATE_FORMAT_TYPE_TIME: 1,
  DATE_FORMAT_TYPE_DATETIME: 2
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.PeriodFormatType = {
  PERIOD_FORMAT_TYPE_UNKNOWN: 0,
  PERIOD_FORMAT_TYPE_SECONDS: 1,
  PERIOD_FORMAT_TYPE_MINUTES: 2,
  PERIOD_FORMAT_TYPE_HOURS: 3,
  PERIOD_FORMAT_TYPE_DAYS: 4,
  PERIOD_FORMAT_TYPE_MONTHS: 5,
  PERIOD_FORMAT_TYPE_YEARS: 6
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeSelectStrategy = {
  DISPLAY_TYPE_SELECT_STRATEGY_DROP_DOWN: 0,
  DISPLAY_TYPE_SELECT_STRATEGY_AUTO_COMPLETE: 1,
  DISPLAY_TYPE_SELECT_STRATEGY_SUGGESTION: 2,
  DISPLAY_TYPE_SELECT_STRATEGY_DROP_DOWN_WITH_LICENSE_CHECK: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeSelectAdminSource = {
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_ORGANIZATIONS: 0,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_USERS: 1,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_ACTIVITY_TEMPLATES: 2,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_SKILLSETS: 3,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_SKILLSET_GROUPS: 4,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_LENDER_CONDITIONS: 5,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_LOAN_FEES: 6,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_DOCUMENT_CATEGORIES: 7,
  DISPLAY_TYPE_SELECT_ADMIN_SOURCE_PARTY_CATEGORIES: 8
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeSelectCommonSource = {
  DISPLAY_TYPE_SELECT_COMMON_SOURCE_UNITED_STATES: 0,
  DISPLAY_TYPE_SELECT_COMMON_SOURCE_UNITED_STATES_BY_NAME: 1,
  DISPLAY_TYPE_SELECT_COMMON_SOURCE_UNITED_STATES_BY_CODE: 2,
  DISPLAY_TYPE_SELECT_COMMON_SOURCE_LANGUAGE_PREFERENCES: 3
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DisplayTypeStringMaskType = {
  DISPLAY_TYPE_STRING_MASK_TYPE_UNKNOWN: 0,
  DISPLAY_TYPE_STRING_MASK_TYPE_CURRENCY: 1,
  DISPLAY_TYPE_STRING_MASK_TYPE_DATE: 2,
  DISPLAY_TYPE_STRING_MASK_TYPE_SSN: 3,
  DISPLAY_TYPE_STRING_MASK_TYPE_SSN9: 4,
  DISPLAY_TYPE_STRING_MASK_TYPE_PHONE: 5
};

goog.object.extend(exports, proto.wilqo.shared.models);
