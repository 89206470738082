import { createContext, useCallback, useContext, useState } from 'react';

import type { ChecklistItem } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';

interface IChecklistProgressProviderProps {
  children: React.ReactNode;
}

export interface IChecklistProgressItem {
  checklistItem: ChecklistItem.AsObject;
  value: any;
}

interface ChecklistProgressParams {
  checklistProgress: Array<IChecklistProgressItem>;
  addProgress: (item: Array<IChecklistProgressItem> | IChecklistProgressItem) => void;
}

const initialState: ChecklistProgressParams = {
  addProgress: () => { },
  checklistProgress: [],
};

const ChecklistProgressContext = createContext(initialState);
ChecklistProgressContext.displayName = 'ChecklistProgressContext';

const findProgress = (
  currentProgress: Array<IChecklistProgressItem>,
  newItem: IChecklistProgressItem,
) => currentProgress.find((currentItem) => newItem.checklistItem.id === currentItem.checklistItem.id);

const ChecklistProgressProvider = (props: IChecklistProgressProviderProps) => {
  const { children } = props;

  const [checklistProgress, setProgress] = useState<Array<IChecklistProgressItem>>([]);

  const handleArrayUpdate = useCallback((items: Array<IChecklistProgressItem>) => {
    // get list of items that needed to be updated
    const updatedList = checklistProgress.map((currentItem) => {
      const updatedItem = findProgress(items, currentItem);
      if (updatedItem) return updatedItem;
      return currentItem;
    });
    // get list of new progress items
    const newItems = items
      .filter((newItem) => !checklistProgress
        .some((currentItem) => currentItem.checklistItem.id === newItem.checklistItem.id));

    // merge the list of updated items with the new ones
    const newProgress = [...updatedList, ...newItems];
    setProgress(newProgress);
  }, [checklistProgress]);

  const handleSingleUpdate = useCallback((item: IChecklistProgressItem) => {
    let newProgress = [];
    // if new item was already in the list
    if (findProgress(checklistProgress, item)) {
      // updates the current progress with the new item
      newProgress = checklistProgress.map((currentItem) => {
        if (currentItem.checklistItem.id === item.checklistItem.id) return item;
        return currentItem;
      });
    } else {
      newProgress = [...checklistProgress, item];
    }
    setProgress(newProgress);
  }, [checklistProgress]);

  const addProgress = useCallback((item: Array<IChecklistProgressItem> | IChecklistProgressItem) => {
    if (Array.isArray(item)) {
      handleArrayUpdate(item);
    } else {
      handleSingleUpdate(item);
    }
  }, [handleArrayUpdate, handleSingleUpdate]);

  return (
    <ChecklistProgressContext.Provider value={{ addProgress, checklistProgress }}>
      {children}
    </ChecklistProgressContext.Provider>
  );
};

const useChecklistProgress = () => useContext(ChecklistProgressContext);

export { ChecklistProgressProvider, useChecklistProgress };
