import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetPanelSequenceActivityQueryRequest, GetPanelSequenceActivityQueryResponse } from '@/API/Models/Wilqo_API_Activity_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetPanelSequenceActivityQueryRequest';

const useActivityPanelSequence = (activityId: string) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, activityId], async () => {
    const request = new GetPanelSequenceActivityQueryRequest();
    request.setActivityId(activityId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetPanelSequenceActivityQueryResponse();
    GetPanelSequenceActivityQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.activity);
  });
};
export { useActivityPanelSequence };
