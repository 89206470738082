import { useQuery } from '@tanstack/react-query';

import type { ProcessType } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { GetDealProcessesQueryRequest, GetDealProcessesQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetDealProcessesQueryRequest';

interface Params {
  dealId: string;
  processTypesList: ProcessType[];
}

export const useGetProcessesForDeal = (params: Params) => {
  const sendMessage = useWilqoMessage();
  const institutionId = useInstitutionId();

  return useQuery(
    [MESSAGE_NAME, params.dealId, params.processTypesList],
    async () => {
      const request = new GetDealProcessesQueryRequest();
      request.setDealId(params.dealId);
      request.setInstitutionId(institutionId);
      request.setProcessTypesList(params.processTypesList);
      return sendMessage<GetDealProcessesQueryResponse.AsObject>(
        {
          msg: request,
          msgName: MESSAGE_NAME,
        },
        GetDealProcessesQueryResponse,
      );
    },
    { enabled: Boolean(params.dealId) && Boolean(institutionId) },
  );
};
