import { useState } from 'react';

import { StepStatus } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { useProcessInformation } from '@/API/Queries/mortgageIntegrations/processStatus/useProcessInformation';
import { Button } from '@/Components/Atoms/Button';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';

import { ProcessStepInformation } from './ProcessStepInformation';

export interface ProcessInformationTableProps {
  processId: string;
}

export const ProcessInformationTable = (props: ProcessInformationTableProps) => {
  const [enabled, setEnabled] = useState(false);
  const { data, error, isLoading } = useProcessInformation({ enabled, processId: props.processId });

  const showTable = () => {
    setEnabled(true);
  };

  const hideTable = () => {
    setEnabled(false);
  };

  if (error) {
    return <div>An error ocurred retrieving data</div>;
  }

  if (enabled) {
    if (isLoading) {
      return <Skeleton height="50px" rows={3} variant="rect" />;
    }

    return (
      <div>
        <div>
          <Button
            className="m-0 mobile:mt-4"
            label="Hide"
            onClick={hideTable}
            type="button"
            variant="tertiary"
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Input name</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {
              data?.stepsList.map(
                (row) => (
                  <tr key={`${data?.instanceId}-${row.name}`}>
                    <td>{row.name}</td>
                    <td>{ConvertEnum(StepStatus, row.status)}</td>
                    <td>{row.inputName}</td>
                    <td><ProcessStepInformation processId={data?.instanceId} stepName={row.name} /></td>
                  </tr>
                ),
              )
            }
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <Button
      className="m-0 mobile:mt-4"
      label="View"
      onClick={showTable}
      type="button"
      variant="tertiary"
    />
  );
};
