import { IRole } from "@/Routes/Auth/Roles";

export const hasRole = (role: IRole, roleMask: string): boolean => {
  const userRoleMask = new Uint8Array(Buffer.from(roleMask, 'base64'));
  const inputRoleMask = role.mask;
  for (let i = 0; i < inputRoleMask.length; i += 1) {
    if (userRoleMask.length <= i && inputRoleMask[i] !== 0) {
      return false;
    }

    // eslint-disable-next-line no-bitwise
    if ((inputRoleMask[i] & userRoleMask[i]) !== inputRoleMask[i]) {
      return false;
    }
  }

  return true;
};

export const hasRoles = (roles: IRole[], roleMask: string): boolean => {
  for (let i = 0; i < roles.length; i += 1) {
    if (!hasRole(roles[i], roleMask)) {
      return false;
    }
  }

  return true;
};
