import clsx from 'clsx';
import { useMemo } from 'react';

export type ProgressBarVariant = 'dark' | 'light';

export interface ProgressBarProps {
  stepsNumber: number;
  currentStep: number;
  variant?: ProgressBarVariant;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { currentStep, stepsNumber, variant = 'light' } = props;

  const steps = useMemo(() => Array.from(Array(stepsNumber).keys()), [stepsNumber]);

  return (
    <div
      className={
        clsx(
          'flex flex-row h-2 w-full rounded-xl',
          {
            'bg-on-primary-stroke': variant === 'dark',
            'bg-on-surface-states-resting': variant !== 'dark',
          },
        )
      }
    >
      {
        steps.map((step: number) => {
          const hasProgressed = step < currentStep;
          const isLast = currentStep - 1 === step;

          return (
            <div
              key={step}
              className={
                clsx(
                  'flex-1 first:rounded-bl-xl first:rounded-tl-xl',
                  {
                    'bg-on-primary-solid': hasProgressed && variant !== 'light',
                    'bg-primary': hasProgressed && variant === 'light',
                    'rounded-br-xl rounded-tr-xl': isLast,
                  },
                )
              }
            />
          );
        })
      }
    </div>
  );
};

export { ProgressBar };
