import { useLocation } from 'react-router';

import { useHandoffStatus } from '@/API/Queries/questionnaire/useHandoffStatus';
import { Loader } from '@/Components/Atoms/Loader';
import { MessageRenderer } from '@/Components/Atoms/MessageRenderer';
import { LayoutWithColumns } from '@/Components/Layouts/WithColumn';
import { useLayout } from '@/Utils/Hooks/useLayout/useLayout';

interface ILocationState {
  runId: string;
  trackId: string;
}

const HandoffComponent = () => {
  const { state } = useLocation();
  const { runId, trackId } = state as ILocationState;

  useHandoffStatus(runId, trackId);
  const withColumnsProps = useLayout();

  const renderContent = () => (
    <>
      <MessageRenderer />
      <Loader className="h-fit" />
    </>
  );

  return (
    <LayoutWithColumns
      {...withColumnsProps}
      content={renderContent()}
      header={{
        appBar: null,
        renderDrawer: false,
      }}
      justifyContent="center"
      layoutsList={[12]}
      renderFooter
    />
  );
};

const Handoff = HandoffComponent;

export { Handoff };
