import { withErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { SubscriptionProvider } from '@/API/Queries/useSubscription';
import AppAuthProvider from '@/Routes/Auth/Auth0Context';
import { SharedProvider } from '@/Utils/Hooks/useShared/useShared';

import { WebsocketProvider } from './API/Queries/useWebSocket';
import { ErrorMessage } from './Components/Atoms/ErrorMessage';
import { NavigationProvider } from './Routes/NavigationContext';
import { NewRoutes } from './Routes/newNavigationRoutes';
import { AppRoutes } from './Routes/routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 10,
    },
  },
});

const App = () => {
  const newRoutes = window.location.href.includes('newRoute');
  return (
    <BrowserRouter basename={newRoutes ? '/newRoutes' : '/mortgage'}>
      <NavigationProvider>
        <QueryClientProvider client={queryClient}>
          <AppAuthProvider>
            <SubscriptionProvider>
              <WebsocketProvider>
                <SharedProvider>
                  <HelmetProvider>
                    <Helmet>
                      <title>Mortgage</title>
                      <meta content={`Wilqo@${process.env.VERSION}`} name="version" />
                    </Helmet>
                    <div className="flex h-full">
                      <div className="w-full">
                        <Suspense fallback={<div />}>
                          {newRoutes ? <NewRoutes /> : <AppRoutes />}
                        </Suspense>
                      </div>
                    </div>
                  </HelmetProvider>
                </SharedProvider>
              </WebsocketProvider>
            </SubscriptionProvider>
          </AppAuthProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </NavigationProvider>
    </BrowserRouter>
  );
};

const FallbackErrorMessage = () => (
  <ErrorMessage
    buttonLabel="Try again"
    onClick={() => window.location.reload()}
    subtitle="Something went wrong"
    title="Sorry!"
  />
);

const AppWithErrorBoundary = withErrorBoundary(App, { fallback: FallbackErrorMessage });
export { AppWithErrorBoundary };
