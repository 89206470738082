import { twMerge } from 'tailwind-merge';

import { Surface } from '../Surface';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  selected?: boolean;
}

export const ListItemRoot = ({ className, onClick, ...props }: Props) => (
  <Surface
    className={twMerge('px-2 py-3 flex flex-row', className)}
    {...props}
    onClick={(event) => {
      if (onClick) {
        event.stopPropagation();
        onClick(event);
      }
    }}
  />
);
