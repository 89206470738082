import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const DialogFooter = (
  { borderTop = false, dense = false, span = false, ...rest }: React.HTMLAttributes<HTMLDivElement> &
  { borderTop?: boolean; dense?: boolean; span?: boolean },
) => {
  const footerStyle = clsx('flex gap-2 items-center', {
    'border-t border-t-on-surface-stroke': borderTop,
    'justify-between': span,
    'justify-end': !span,
    'p-2': dense,
    'py-[18px] px-4': !dense,
  });

  return (
    <footer {...rest} className={twMerge(footerStyle, 'col-span-2 row-start-3 bg-surface', rest.className)}>
      {rest.children}
    </footer>
  );
};
