import { useWindow } from '@/Utils/Helpers/useWindow';

interface IUseAdaptiveProps {
  Mobile?: () => JSX.Element;
  Desktop: () => JSX.Element;
  Tablet?: () => JSX.Element;
}

const useAdaptiveDesign = (props: IUseAdaptiveProps) => {
  const { Desktop, Mobile, Tablet } = props;
  const { windowType } = useWindow();

  const MobileComp = Mobile || Tablet || Desktop;
  const TabletComp = Tablet || Mobile || Desktop;

  if (windowType === 'desktop') return Desktop;
  if (windowType === 'tablet') return TabletComp;
  if (windowType === 'mobile') return MobileComp;

  return Desktop;
};

export { useAdaptiveDesign };
