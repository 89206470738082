// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_BrandAdminModels_pb = require('../../../Wilqo.Shared.Models/BrandAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_BrandAdminModels_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ActionListItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DynamicDialogFlowType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageAction', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.AdditionalSettingsCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageActionDisplayVariant', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageActionSubType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.repeatedFields_, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.LoanPageAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.displayName = 'proto.wilqo.api.mortgage_dynamic_data.LoanPageAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ActionListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ActionListItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ActionListItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.repeatedFields_ = [7];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.oneofGroups_ = [[11]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.AdditionalSettingsCase = {
  ADDITIONAL_SETTINGS_NOT_SET: 0,
  DYNAMIC_LOAN_PAGE_FLOW_SETTINGS: 11
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.AdditionalSettingsCase}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getAdditionalSettingsCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.AdditionalSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    variant: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    actionsListList: jspb.Message.toObjectList(msg.getActionsListList(),
    proto.wilqo.api.mortgage_dynamic_data.ActionListItem.toObject, includeInstance),
    targetId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dialogPageId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    additionalArgsMap: (f = msg.getAdditionalArgsMap()) ? f.toObject(includeInstance, undefined) : [],
    dynamicLoanPageFlowSettings: (f = msg.getDynamicLoanPageFlowSettings()) && proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.LoanPageAction;
  return proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionDisplayVariant} */ (reader.readEnum());
      msg.setVariant(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.models.ActionTypeEnum} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ActionListItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ActionListItem.deserializeBinaryFromReader);
      msg.addActionsList(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDialogPageId(value);
      break;
    case 10:
      var value = msg.getAdditionalArgsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 11:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.deserializeBinaryFromReader);
      msg.setDynamicLoanPageFlowSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActionsListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ActionListItem.serializeBinaryToWriter
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDialogPageId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAdditionalArgsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDynamicLoanPageFlowSettings();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional LoanPageActionType type = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional LoanPageActionDisplayVariant variant = 2;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionDisplayVariant}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getVariant = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionDisplayVariant} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionDisplayVariant} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ActionTypeEnum action_type = 6;
 * @return {!proto.wilqo.shared.models.ActionTypeEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getActionType = function() {
  return /** @type {!proto.wilqo.shared.models.ActionTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.models.ActionTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated ActionListItem actions_list = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ActionListItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getActionsListList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ActionListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ActionListItem, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ActionListItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setActionsListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.addActionsList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.ActionListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.clearActionsListList = function() {
  return this.setActionsListList([]);
};


/**
 * optional string target_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string dialog_page_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getDialogPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setDialogPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * map<string, string> additional_args = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getAdditionalArgsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.clearAdditionalArgsMap = function() {
  this.getAdditionalArgsMap().clear();
  return this;
};


/**
 * optional DynamicLoanFlowDialogSettings dynamic_loan_page_flow_settings = 11;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.getDynamicLoanPageFlowSettings = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings, 11));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.setDynamicLoanPageFlowSettings = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.clearDynamicLoanPageFlowSettings = function() {
  return this.setDynamicLoanPageFlowSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageAction.prototype.hasDynamicLoanPageFlowSettings = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    flowPageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings;
  return proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.DynamicDialogFlowType} */ (reader.readEnum());
      msg.setFlowType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFlowPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional DynamicDialogFlowType flow_type = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicDialogFlowType}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.getFlowType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.DynamicDialogFlowType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicDialogFlowType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.setFlowType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string flow_page_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.getFlowPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.setFlowPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicLoanFlowDialogSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ActionListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    actionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actionSubType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    label: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    additionalArgsMap: (f = msg.getAdditionalArgsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ActionListItem;
  return proto.wilqo.api.mortgage_dynamic_data.ActionListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} */ (reader.readEnum());
      msg.setActionType(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionSubType} */ (reader.readEnum());
      msg.setActionSubType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = msg.getAdditionalArgsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ActionListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActionSubType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdditionalArgsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LoanPageActionType action_type = 2;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.getActionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.setActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional LoanPageActionSubType action_sub_type = 3;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionSubType}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.getActionSubType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionSubType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionSubType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.setActionSubType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * map<string, string> additional_args = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.getAdditionalArgsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ActionListItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ActionListItem.prototype.clearAdditionalArgsMap = function() {
  this.getAdditionalArgsMap().clear();
  return this;
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicDialogFlowType = {
  DYNAMIC_DIALOG_FLOW_TYPE_UNKNOWN: 0,
  DYNAMIC_DIALOG_FLOW_TYPE_URLA: 1,
  DYNAMIC_DIALOG_FLOW_TYPE_PREQUALIFICATION: 2,
  DYNAMIC_DIALOG_FLOW_TYPE_INCOME_CHECKLIST: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType = {
  LOAN_PAGE_ACTION_TYPE_UNKNOWN: 0,
  LOAN_PAGE_ACTION_TYPE_LAUNCH_URLA: 2,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_APPRAISAL: 3,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_AUS: 4,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_CREDIT: 5,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_DISCLOSURES: 6,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_FEES: 7,
  LOAN_PAGE_ACTION_TYPE_MANAGE_FEES: 8,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_FLOOD: 9,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_PRICING: 10,
  LOAN_PAGE_ACTION_TYPE_INVITE_LINK: 11,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_MERS: 12,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_CONDITIONS: 13,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_CONDITIONS_ADD_ACTIVITY: 14,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_COMPLIANCE: 15,
  LOAN_PAGE_ACTION_TYPE_DOWNLOAD_MISMO_XML: 16,
  LOAN_PAGE_ACTION_TYPE_CONDITIONS_VIEW_ACTIVITIES: 17,
  LOAN_PAGE_ACTION_TYPE_PREQUALIFICATION: 18,
  LOAN_PAGE_ACTION_TYPE_PARTY_LENDER_EDIT: 19,
  LOAN_PAGE_ACTION_TYPE_THIRD_PARTY_DELETE: 20,
  LOAN_PAGE_ACTION_TYPE_PARTY_ADD: 21,
  LOAN_PAGE_ACTION_TYPE_UW_PRINT_DOCUMENTS: 22,
  LOAN_PAGE_ACTION_TYPE_EXPANDER: 23,
  LOAN_PAGE_ACTION_TYPE_DYNAMIC_DIALOG: 24,
  LOAN_PAGE_ACTION_TYPE_DOCUMENT_GENERATION: 25,
  LOAN_PAGE_ACTION_TYPE_DOCUMENT_GENERATION_DIALOG: 26,
  LOAN_PAGE_ACTION_TYPE_UNDERWRITING_REQUEST_DIALOG: 27,
  LOAN_PAGE_ACTION_TYPE_EDIT_MODE: 28,
  LOAN_PAGE_ACTION_TYPE_LAUNCH_DISCLOSURES_PROGRESS_ITEM: 29,
  LOAN_PAGE_ACTION_TYPE_LAUNCH_NOTICE_OF_INCOMPLETE_APPLICATION_UW_PROGRESS_ITEM: 30,
  LOAN_PAGE_ACTION_TYPE_DYNAMIC_DIALOG_FLOW: 31,
  LOAN_PAGE_ACTION_TYPE_CENTRALIZED_ADDRESS: 32,
  LOAN_PAGE_ACTION_TYPE_EXPRESS_BORROWER_ADD: 33,
  LOAN_PAGE_ACTION_TYPE_ADD_DOCUMENT: 34,
  LOAN_PAGE_ACTION_TYPE_UPDATE_DOCUMENT: 35,
  LOAN_PAGE_ACTION_TYPE_ADD_AND_EDIT_ACTIVITY: 36,
  LOAN_PAGE_ACTION_TYPE_ADD_DOCUMENT_ACTIVITY: 37,
  LOAN_PAGE_ACTION_TYPE_INTEGRATION_MORTGAGE_INSURANCE: 38
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageActionDisplayVariant = {
  LOAN_PAGE_ACTION_DISPLAY_VARIANT_PRIMARY: 0,
  LOAN_PAGE_ACTION_DISPLAY_VARIANT_SECONDARY: 1,
  LOAN_PAGE_ACTION_DISPLAY_VARIANT_TERTIARY: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageActionSubType = {
  LOAN_PAGE_ACTION_SUB_TYPE_CREDIT_NEW: 0,
  LOAN_PAGE_ACTION_SUB_TYPE_CREDIT_RETRIEVE_EXISTING: 1,
  LOAN_PAGE_ACTION_SUB_TYPE_CREDIT_UPGRADE: 2,
  LOAN_PAGE_ACTION_SUB_TYPE_DOWNLOAD_MISMO_XML: 5,
  LOAN_PAGE_ACTION_SUB_TYPE_DOWNLOAD_DOCUMENTS: 6,
  LOAN_PAGE_ACTION_SUB_TYPE_DOWNLOAD_MISMO_XML_AND_DOCUMENTS: 7
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
