import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetLeadSourcesConfigQueryRequest, GetLeadSourcesConfigQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const DOMAIN_LEAD_SOURCES = 'wilqo.api.users.GetLeadSourcesConfigQueryRequest';

const useLeadSources = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([DOMAIN_LEAD_SOURCES], async () => {
    const request = new GetLeadSourcesConfigQueryRequest();
    request.setBusinessProcessDomainId(bpdId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: DOMAIN_LEAD_SOURCES,
    });
    const response = new GetLeadSourcesConfigQueryResponse();
    GetLeadSourcesConfigQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(DOMAIN_LEAD_SOURCES, responseObj.error, responseObj.leadSourcesList);
  }, { enabled: Boolean(bpdId) });
};

export { useLeadSources };
