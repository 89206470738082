import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpsertBorrowerIntentToProceedCommandRequest, UpsertBorrowerIntentToProceedCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { DisclosureDeliveryMethodEnum, IntentToProceedCommunicationMethodEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { BorrowerIntentToProceed } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { getTimestampFromDate } from '@/Utils/Helpers/getDateFromTimestamp';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.UpsertBorrowerIntentToProceedCommandRequest';

interface UpsertBorrowerProps {
  dealId: string;
  partyId: string;

  borrowerIntentToProceed: {
    intentToProceedMethod: IntentToProceedCommunicationMethodEnum;
    loanEstimatedReceivedMethod?: DisclosureDeliveryMethodEnum;
    loanEstimatedReceivedDate?: string;
    intentToProceedDate?: string;
  };
}

const useUpsertBorrowerIntentToProceed = () => {
  const { commandResponse } = useBackend();
  const authContext = useAuth();
  const bpid = authContext.secureAuthValues.authorizedBusinessProcessDomainIds[0];

  const UpdateLoanProgressStatusCommand = async (updateObject: UpsertBorrowerProps) => {
    const { borrowerIntentToProceed, dealId, partyId } = updateObject;
    const request = new UpsertBorrowerIntentToProceedCommandRequest();
    request.setBusinessProcessDomainId(bpid);
    request.setPartyId(partyId);
    request.setDealId(dealId);

    const {
      intentToProceedDate,
      intentToProceedMethod,
      loanEstimatedReceivedDate,
      loanEstimatedReceivedMethod,
    } = borrowerIntentToProceed;

    const borrower = new BorrowerIntentToProceed();
    borrower.setIntentToProceedMethod(intentToProceedMethod);

    if (loanEstimatedReceivedMethod) {
      borrower.setLoanEstimatedReceivedMethod(loanEstimatedReceivedMethod);
    }

    if (intentToProceedDate) {
      borrower.setIntentToProceedDate(getTimestampFromDate(intentToProceedDate));
    }

    if (loanEstimatedReceivedDate) {
      borrower.setLoanEstimatedReceivedDate(getTimestampFromDate(loanEstimatedReceivedDate));
    }

    request.setBorrowerIntentToProceed(borrower);

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpsertBorrowerIntentToProceedCommandResponse();
    UpsertBorrowerIntentToProceedCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpdateLoanProgressStatusCommand);
};

export { useUpsertBorrowerIntentToProceed };
