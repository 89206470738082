import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useOnClickOutside } from '@/Utils/Helpers/useOnClickOutside';

import { MenuOption } from './MenuOption';

interface IPosition {
  left: number;
  top: number;
}

export interface IMenuOption {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
  icon?: string;
  animation?: string;
  hidden?: boolean;
  key?: string;
}

export interface MenuProps {
  options: Array<IMenuOption> | React.ReactNode | ((close: any) => React.ReactNode);
  triggerItem: React.ReactNode | ((props?: any) => JSX.Element);
  alignTop?: boolean;
  className?: string;
  closeAfterSelecting?: boolean;
  triggerProps?: {
    [key: string]: any;
  };
}

export interface MenuRef { open: boolean }

const CLOSED_POSITION = { left: 99999, top: 99999 };

const Menu = forwardRef<MenuRef, MenuProps>((props, ref) => {
  const { className, closeAfterSelecting = false, options, triggerItem, triggerProps } = props;

  const [open, setOpen] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState<IPosition>(CLOSED_POSITION);
  const triggerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(optionsRef, () => setOpen(false));
  useImperativeHandle(ref, () => ({ open }));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, option: IMenuOption) => {
    event.stopPropagation();
    option.onClick(event);
    if (closeAfterSelecting) setOpen(false);
  };

  const renderTrigger = () => {
    if (typeof triggerItem === 'function') return triggerItem(triggerProps);
    return triggerItem;
  };

  const renderOptions = () => {
    if (Array.isArray(options)) {
      return options.filter((option) => !option.hidden).map((option) => (
        <MenuOption
          key={`option-${option.key}-${option.label}`}
          animation={option.animation}
          label={option.label}
          leadingIcon={option.icon}
          onClick={(e: any) => {
            handleClick(e, option);
          }}
        />
      ));
    }

    if (typeof options === 'function') return options(() => setOpen(false));

    return options;
  };

  // Handle moving the options off the viewport when closing
  useEffect(() => {
    if (open) return;

    if (optionsPosition !== CLOSED_POSITION) {
      setOptionsPosition(CLOSED_POSITION);
    }
  }, [open, optionsPosition]);

  // Prevent options from popping up off screen when opening
  useEffect(() => {
    const setPosition = () => {
      if (!open) return;

      const triggerBounds = triggerRef.current?.getBoundingClientRect();
      if (!triggerBounds) return;

      const optionsBounds = optionsRef.current?.getBoundingClientRect();
      if (!optionsBounds) return;

      const windowWidth = Math.min(document.documentElement.clientWidth, window.outerHeight);
      const windowHeight = Math.min(document.documentElement.clientHeight, window.innerHeight);
      const optionsRight = optionsBounds.width + triggerBounds.left;
      const optionsBottom = optionsBounds.height + triggerBounds.bottom;
      const isOutOfBoundsHorizontal = optionsRight > windowWidth;
      const isOutOfBoundsVertical = optionsBottom > windowHeight;

      const newPosition: IPosition = {
        left: isOutOfBoundsHorizontal
          ? triggerBounds.right - optionsBounds.width
          : triggerBounds.left,
        top: isOutOfBoundsVertical
          ? triggerBounds.bottom - optionsBounds.height
          : triggerBounds.top + triggerBounds.height + 8,
      };

      setOptionsPosition(newPosition);
    };

    window.addEventListener('scroll', setPosition, true);
    setPosition();
    return () => {
      window.removeEventListener('scroll', setPosition);
    };
  }, [open, optionsRef, triggerRef]);

  const handleTriggerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <div className={className}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        ref={triggerRef}
        onClick={handleTriggerClick}
        role="button"
        tabIndex={0}
      >
        {renderTrigger()}
      </div>
      <div
        ref={optionsRef}
        className={clsx('fixed bg-surface shadow-four min-w-min z-[99999] pointer-events-auto overflow-auto max-h-64',
          {
            block: open,
            hidden: !open,
          })}
        style={optionsPosition}
      >
        {renderOptions()}
      </div>
    </div>
  );
});

export { Menu };
