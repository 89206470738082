interface IconProps {
  className?: string;
}

const Rule = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.4502 11.0351L12.9102 7.49509L14.3202 6.08509L16.4402 8.20509L20.6802 3.96509L22.0902 5.37509L16.4502 11.0351ZM10.9102 7.03509H1.91016V9.03509H10.9102V7.03509ZM20.9102 13.4451L19.5002 12.0351L16.9102 14.6251L14.3202 12.0351L12.9102 13.4451L15.5002 16.0351L12.9102 18.6251L14.3202 20.0351L16.9102 17.4451L19.5002 20.0351L20.9102 18.6251L18.3202 16.0351L20.9102 13.4451ZM10.9102 15.0351H1.91016V17.0351H10.9102V15.0351Z" />
  </svg>

);

export default Rule;
