import { useMemo } from 'react';

import type { FormError } from './DynamicFormContext';
import { useDynamicForm } from './DynamicFormContext';

interface UseDynamicFormValueReturn<T, P> {
  error?: FormError;
  value?: T;
  dynamic: P;
  plainValue?: string;
}

const useDynamicFormValue = <T = any, P = any>(id: string): UseDynamicFormValueReturn<T, P> => {
  const { errors, values } = useDynamicForm();

  const error = useMemo(() => (errors ? errors[id] : undefined), [errors, id]);
  const value = useMemo(() => (values[id] ? values[id].value : undefined), [values, id]);
  const dynamic = useMemo(() => (values[id] ? values[id].dynamic : undefined), [values, id]);
  const plainValue = useMemo(() => (values[id] ? values[id].plainValue : undefined), [values, id]);

  return { dynamic, error, plainValue, value };
};

export { useDynamicFormValue };
