import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { IntegrationHistory as IntegrationHistoryModel, ProgressItem } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { ProgressItemStatusEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useLoanintegrationHistory } from '@/API/Queries/mortgage/useLoanIntegrationHistory';
import { useLoanProgressSummary } from '@/API/Queries/mortgage/useLoanProgressSummary';
import { useUpdateLoanProgressStatus } from '@/API/Queries/mortgage/useUpdateLoanProgressStatus';
import { useIntegrationReport } from '@/API/Queries/mortgageIntegrations/useIntegrationReport';
import { useIntegrationRunRequest } from '@/API/Queries/mortgageIntegrations/useIntegrationRunRequest';
import { useIntegrationRunResponse } from '@/API/Queries/mortgageIntegrations/useIntegrationRunResponse';
import { useGetFile } from '@/API/Queries/other/useGetFile';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Dialog } from '@/Components/Atoms/Dialog';
import { Select } from '@/Components/Atoms/Select';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { ConvertEnum, getEnumPanelElementOptionList } from '@/Utils/Helpers/enumHelpers';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

type IntegrationType = 'report' | 'request' | 'response';

const LoanProgressStatusOptions = getEnumPanelElementOptionList(ProgressItemStatusEnum, true);

interface IntegrationHistoryProps {
  progressId?: string;
  hideBreadcrumbs?: boolean;
  sideSheetName?: string;
  options?: PanelElementOption.AsObject[];
}

interface AliasProps {
  [prop: string]: string;
}

const aliases: AliasProps = {
  asurity: 'disclosures',
  aus: 'automated underwriting',
};

const IntegrationHistory = (props: IntegrationHistoryProps) => {
  const { hideBreadcrumbs = false, options = LoanProgressStatusOptions, progressId: propsProgressId, sideSheetName } = props;
  const { progressId: routeProgressId = '' } = useParams();
  const progressId = propsProgressId || routeProgressId;
  const { data: loan } = useLoan();

  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationType>();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedTrackId, setSelectedTrackId] = useState<string>('');
  const [progress, setProgress] = useState<ProgressItem.AsObject>();
  const [selectedStatus, setSelectedStatus] = useState<PanelElementOption.AsObject>();
  const [showTable, setShowTable] = useState<boolean>(true);
  const [sideSheet, setSideSheet] = useState<string>('');
  const { mutate: getDocument } = useGetFile();
  const { showSnackBar } = useShared();

  const { data: integrationRunRequest } = useIntegrationRunRequest(
    selectedIntegration === 'request' ? selectedTrackId : '',
  );
  const { data: integrationRunResponse } = useIntegrationRunResponse(
    selectedIntegration === 'response' ? selectedTrackId : '',
  );
  const { data: integrationReport } = useIntegrationReport(
    selectedIntegration === 'report' ? selectedTrackId : '',
  );

  const { isLoading: isUpdating, mutate: updateLoanStatus } = useUpdateLoanProgressStatus();
  const { data: progressSummary, refetch: reloadProgress } = useLoanProgressSummary(loan?.dealId || '');
  const { data: integrationHistoryList = [], isLoading: isLoadingReports } = useLoanintegrationHistory(loan?.dealId || '');

  useEffect(() => {
    const progress = progressSummary?.progressItemsList.find((progressItem) => progressItem.identifier === progressId);

    if (sideSheetName) {
      setShowTable(true);
      setSideSheet(sideSheetName);
    }
    if (progress) {
      setProgress(progress);
      setShowTable(() => ['credit', 'appraisal', 'disclosures', 'fees', 'compliance', 'flood', 'automated underwriting', 'product & pricing', 'mers', 'closing documents'].includes(progress.name.toLowerCase().trim()));
    }
  }, [progressId, progressSummary?.progressItemsList, sideSheetName]);

  useEffect(() => {
    if (integrationRunRequest?.requestObject) {
      const wdw = window.open('', '_blank');
      if (wdw) {
        const content = wdw.document.createElement('pre');
        content.innerHTML = integrationRunRequest.requestObject;
        wdw.document.body.appendChild(content);
        wdw.focus();
        setSelectedTrackId('');
      }
    }
  }, [integrationRunRequest?.requestObject]);

  useEffect(() => {
    if (integrationRunResponse?.responseObject) {
      const wdw = window.open('', '_blank');
      if (wdw) {
        const content = wdw.document.createElement('pre');
        content.innerHTML = integrationRunResponse.responseObject;
        wdw.document.body.appendChild(content);
        wdw.focus();
        setSelectedTrackId('');
      }
    }
  }, [integrationRunResponse?.responseObject]);

  useEffect(() => {
    if (integrationReport?.documentUrl) {
      getDocument(integrationReport?.documentUrl, {
        onSuccess: (url) => {
          window.open(url, '_blank')?.focus();
          setSelectedTrackId('');
        },
      });
    }
  }, [getDocument, integrationReport?.documentUrl]);

  const handleConfirmStatusChange = () => {
    updateLoanStatus({
      dealId: loan?.dealId || '',
      identifier: progress?.identifier || '',
      statusType: !props.options ? Number(selectedStatus?.id) : 0,
      statusValue: props.options ? selectedStatus?.value : undefined,
    }, {
      onError: () => {
        showSnackBar({ message: 'Something went wrong', open: true });
        setSelectedStatus(undefined);
      },
      onSuccess: () => {
        showSnackBar({ message: 'Status updated', open: true });
        setSelectedStatus(undefined);
        reloadProgress();
      },
    });
  };

  const data = useMemo(() => integrationHistoryList.filter(
    (integrationHistory) => {
      const integrationName = (integrationHistory.integrationName || '').toLowerCase().trim();
      const integrationAlias = aliases[integrationName as keyof AliasProps] || integrationName;
      const isValid = integrationAlias === (progress?.name || sideSheet || '').toLowerCase().trim();
      if (isValid) {
        return integrationName.includes(searchText.toLowerCase().trim());
      }

      return false;
    },
  ).map((integrationHistory) => ({
    ...integrationHistory,
    createdDate: new Date(
      (integrationHistory.createdDate?.seconds || 0) * 1000 + Math.round((integrationHistory.createdDate?.nanos || 0) / 1e6),
    ).toLocaleString(),
    status: {
      label: integrationHistory.status,
      type: integrationHistory.badge?.status,
    },
  })), [integrationHistoryList, progress, searchText, sideSheet]);

  const columns = useMemo((): Array<Column<IntegrationHistoryModel.AsObject>> => [
    {
      header: 'Integration Name',
      id: 'integration-name',
      key: {
        text: 'integrationName',
        type: 'text',
      },
    },
    {
      header: 'Status',
      id: 'status',
      key: {
        badge: 'status',
        type: 'badge',
      },
    },
    {
      header: 'Date & Time',
      id: 'createdDate',
      key: {
        text: 'createdDate',
        type: 'text',
      },
    },
    {
      header: 'Request',
      id: 'request',
      key: {
        onClick: (item) => {
          setSelectedTrackId(item.trackId);
          setSelectedIntegration('request');
        },
        type: 'item',
        value: 'View Data',
      },
    },
    {
      header: 'Response',
      id: 'response',
      key: {
        onClick: (item) => {
          setSelectedTrackId(item.trackId);
          setSelectedIntegration('response');
        },
        type: 'item',
        value: 'View Data',
      },
    },
    {
      header: 'Report',
      id: 'report',
      key: {
        onClick: (item) => {
          setSelectedTrackId(item.trackId);
          setSelectedIntegration('report');
        },
        type: 'item',
        value: 'View Report',
      },
    },
  ], []);

  const renderTable = () => {
    if (data.length === 0) {
      return (
        <ContingencyMessage
          icon="NoItems"
          subtitle={`No integrations found for ${progress?.name || sideSheet}`}
          title="No results, yet"
          variant="image"
        />
      );
    }
    return (
      <Table
        cardProps={{
          headerProps: {
            searchProps: {
              onChange: setSearchText,
              value: searchText,
            },
            title: 'Integration History',
          },
        }}
        columns={progress?.name === 'MERS' ? columns.slice(0, -1) : columns}
        data={data}
        isLoading={isLoadingReports}
        width="stretch"
      />
    );
  };

  return (
    <div
      className={clsx({
        'py-8 px-0': hideBreadcrumbs,
      })}
    >
      <div className="flex justify-start items-center gap-x-4 mb-6">
        {!hideBreadcrumbs && (
          <Typography className="capitalize" variant="display5">
            {`${progress?.name || sideSheet} Summary`}
          </Typography>
        )}
        <Select
          className="w-[200px]"
          displayOnly
          fullWidth={false}
          onSelect={setSelectedStatus}
          options={options}
          placeholder=""
          titleKey="headerText"
          value={progress?.statusType ? ConvertEnum(ProgressItemStatusEnum, progress?.statusType) : ''}
          variant="outlined"
        />
      </div>
      {showTable && renderTable()}
      {selectedStatus && (
        <Dialog
          actions={[
            { isLoading: isUpdating, label: 'Update Status', onClick: handleConfirmStatusChange, type: 'button' },
          ]}
          dismissButtonLabel="Cancel"
          onClickDismiss={() => setSelectedStatus(undefined)}
          size="default"
          title={`Update ${progress?.name || sideSheet} status`}
        >
          <Typography className="text-on-surface-active" variant="body2">
            {`Are you sure you want to update the status of the ${progress?.name || sideSheet}?`}
          </Typography>
        </Dialog>
      )}
    </div>
  );
};

export { IntegrationHistory };
