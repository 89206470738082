import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { RunLodestarCommandRequest, RunLodestarCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../../useBackend';
import { endpointResponseMiddleware } from '../../util';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.RunLodestarCommandRequest';

export interface RunFeesProps {
  agentId: string;
  dealId: string;
}

const useRunFees = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation(async (manualFeesProps: RunFeesProps) => {
    const {
      agentId,
      dealId,
    } = manualFeesProps;
    const request = new RunLodestarCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setDealId(dealId);
    request.setInstitutionId(institutionId);
    request.setAgentId(agentId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new RunLodestarCommandResponse();
    RunLodestarCommandResponse.deserializeBinaryFromReader(
      response,
      new BinaryReader(responseMsg.getValue()),
    );
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj);
  });
};

export { useRunFees };
