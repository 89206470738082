import { Disclosure } from '@headlessui/react';

import type { IButtonProps } from './Button';
import { Button } from './Button';
import { Icon } from './Icon';

interface AccordionButtonProps {
  title: string;
  open: boolean;
  actions?: Array<IButtonProps>;
  children?: React.ReactNode;
}

const AccordionButton = (props: AccordionButtonProps) => {
  const { title, open, actions = [], children } = props;

  const renderButtons = () => (
    <>
      {actions.map((button) => (
        <Button
          key={button.label}
          {...button}
          onClick={(e) => {
            e.stopPropagation();
            if (button.onClick) button.onClick(e);
          }}
        />
      ))}
    </>
  );

  return (
    <Disclosure.Button as="div" className="flex w-full items-center flex-row justify-between pt-3 px-0">
      <div className="flex flex-row gap-x-4 items-center">
        <span className="text-on-surface-active font-semibold">{title}</span>
        {children && renderButtons()}
      </div>
      <div className="flex flex-row gap-x-4">
        {children || renderButtons()}
        <Icon icon={open ? 'ExpandLess' : 'ExpandMore'} />
      </div>
    </Disclosure.Button>
  );
};

export { AccordionButton };
