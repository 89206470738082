// source: Wilqo.Shared.Mortgage.Integration/Compliance/Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.wilqo.shared.mortgage_integration.ComplianceCheckType', null, global);
/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.ComplianceCheckType = {
  COMPLIANCE_CHECK_TYPE_UNSPECIFIED: 0,
  COMPLIANCE_CHECK_TYPE_HOEPA: 1,
  COMPLIANCE_CHECK_TYPE_NMLS: 2,
  COMPLIANCE_CHECK_TYPE_TRID: 3,
  COMPLIANCE_CHECK_TYPE_STATE_HIGH_COST: 4,
  COMPLIANCE_CHECK_TYPE_FEDERAL_HPM: 5,
  COMPLIANCE_CHECK_TYPE_TILA: 6,
  COMPLIANCE_CHECK_TYPE_QUALIFIED_MORTGAGE: 7,
  COMPLIANCE_CHECK_TYPE_TEXAS_HOME_EQUITY: 8,
  COMPLIANCE_CHECK_TYPE_TEXAS50F2: 9,
  COMPLIANCE_CHECK_TYPE_NEW_YORK_SUB_PRIME: 10,
  COMPLIANCE_CHECK_TYPE_CALIFORNIA_HPML: 11,
  COMPLIANCE_CHECK_TYPE_RESPA: 12,
  COMPLIANCE_CHECK_TYPE_STATE_SPECIFIC: 13,
  COMPLIANCE_CHECK_TYPE_USDA: 14,
  COMPLIANCE_CHECK_TYPE_FHA: 15,
  COMPLIANCE_CHECK_TYPE_VA: 16,
  COMPLIANCE_CHECK_TYPE_FREDDIE_MAC: 17,
  COMPLIANCE_CHECK_TYPE_FANNIE_MAE: 18,
  COMPLIANCE_CHECK_TYPE_FLOOD_INSURANCE: 19,
  COMPLIANCE_CHECK_TYPE_COOK_COUNTY: 20,
  COMPLIANCE_CHECK_TYPE_OFAC: 21,
  COMPLIANCE_CHECK_TYPE_CONNECTICUT_NON_PRIME: 22,
  COMPLIANCE_CHECK_TYPE_CHICAGO: 23,
  COMPLIANCE_CHECK_TYPE_ALL: 24,
  COMPLIANCE_CHECK_TYPE_CLEVELAND_HEIGHTS: 25,
  COMPLIANCE_CHECK_TYPE_KENTON: 26
};

goog.object.extend(exports, proto.wilqo.shared.mortgage_integration);
