export const useGetOrganizations = (institutionId: string): { id: string; name: string; businessProcessDomainId: string }[] => {
  // eslint-disable-next-line no-console
  console.log('institutionId', institutionId);

  return [
    {
      businessProcessDomainId: 'WILQO-BPD',
      id: 'WILQO-ORG',
      name: 'Base Bank',
    },
    {
      businessProcessDomainId: 'MAVERICK-BPD',
      id: 'MAVERICK-ORG',
      name: 'Maverick',
    },
  ];
};

export const useGetCredentialTypes = (organization: string) => {
  switch (organization) {
    case 'WILQO-ORG':
      return [
        {
          id: 1,
          name: 'XactusCredit',
        },
        {
          id: 2,
          name: 'Asurity',
        },
        {
          id: 3,
          name: 'FactualFlood',
        },
      ];
    case 'MAVERICK-ORG':
      return [
        {
          id: 1,
          name: 'XactusCredit',
        },
      ];
    default:
      return [];
  }
};
