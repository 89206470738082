/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { PanelElementRequirement, PanelElementValidation } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';

import type { FormError } from './DynamicFormContext';
import { useDynamicForm } from './DynamicFormContext';
import { useDynamicFormValue } from './useDynamicFormValue';
import { checkValidators } from './validate';

export interface ExtendedConverter<T = any> {
  fromDynamic: (dynamic: DynamicDataElement) => T;
  toDynamic: (value?: T) => DynamicDataElement;
}

interface Options<T> {
  order?: number;
  initialValue?: T;
  requirement?: PanelElementRequirement.AsObject;
  validators?: Array<PanelElementValidation.AsObject>;
  onBlur?: () => void;
  onFocus?: () => void;
  panelElementType?: string;
}

interface FieldProps {
  ref: any;
  errorMessage?: string;
  focused: boolean;
  hidden: boolean;
  disabled: boolean;
}
export type HandleChangeType<T> = (event: T, plainValue: string, path?: string, submit?: boolean, resetOnTracker?: boolean) => void;
type DynamicFormValueReturn<T> = [value: T | undefined, handleChange: HandleChangeType<T>, ref: FieldProps];

const isValueEmpty = (value: any, panelElementType?: string) => {
  if (!value) return true;
  if (panelElementType === 'number') {
    const numberValue = value?.replace(/\D+/g, '');
    return numberValue.length === 0;
  }
  if (panelElementType === 'multi-select' || panelElementType === 'chip-list') {
    return value.length === 0;
  }
  return false;
};

const useDynamicFormRegister = <T = any>(id: string, converter?: ExtendedConverter<T>, options?: Options<T>): DynamicFormValueReturn<T> => {
  const { disabledFieldIds, hiddenFieldIds, register, setErrors } = useDynamicForm();

  const { error, value } = useDynamicFormValue(id);

  const { onChange, unregister } = register<T>(id, converter);
  const initialized = useRef(false);

  const ref = useRef<any>(null);
  const [pristine, setPristine] = useState(true);
  const [focused, setFocused] = useState(false);

  const validate = useCallback((value: any) => {
    let error: FormError = { errorMessage: undefined, type: '' };
    if (isValueEmpty(value, options?.panelElementType) && options?.requirement?.required) {
      error = { errorMessage: options.requirement.errorMessage || 'Required', type: 'required' };
    }
    if (value && options?.validators) {
      error = checkValidators(value, options.validators) || error;
    }
    setErrors(id, error);
  }, [id, options, setErrors]);

  useEffect(() => {
    validate(value);
  }, [value]);

  useEffect(() => {
    if (options?.validators) {
      validate(value);
    }
  }, [options?.validators, value]);

  const errorMessage = useMemo(() => {
    if (!pristine && !focused && error) {
      return error.errorMessage;
    }
    return '';
  }, [pristine, focused, error]);

  const hidden = useMemo(() => hiddenFieldIds.some((hiddenFieldId) => hiddenFieldId === id), [hiddenFieldIds]);
  const disabled = useMemo(() => disabledFieldIds.includes(id), [disabledFieldIds]);

  const handleBlur = () => {
    setFocused(false);
  };
  const handleFocus = () => {
    setFocused(true);
    setPristine(false);
  };

  useEffect(() => {
    if (options?.initialValue) {
      validate(options?.initialValue);
    }
    if (ref?.current?.addEventListener) {
      ref.current?.addEventListener('blur', handleBlur);
      ref.current?.addEventListener('focus', handleFocus);
    }

    return () => {
      if (ref?.current?.removeEventListener) {
        ref.current?.removeEventListener('blur', handleBlur);
        ref.current?.removeEventListener('focus', handleFocus);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback((value: T, path?: string, plainValue = '', submit = false, resetOnTracker = false, isInitial = false) => {
    setPristine(false);
    initialized.current = true;
    onChange(value, path || '', plainValue, submit, resetOnTracker, isInitial);
  }, [onChange]);

  useEffect(() => {
    if (!initialized.current && value === undefined && options?.initialValue !== undefined) {
      handleChange(options.initialValue, '', '', false, false, true);
      initialized.current = true;
    }
  }, [options?.initialValue, handleChange, value]);

  useEffect(() => () => {
    unregister();
    setFocused(false);
    setPristine(true);
  }, []);

  return [value, handleChange, { disabled, errorMessage, focused, hidden, ref }];
};

export { useDynamicFormRegister };
