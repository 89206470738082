import React from 'react';

import { NavigationDrawer } from '@/Components/Atoms/NavigationDrawer';
import type { NavigationRailProps } from '@/Components/Atoms/NavigationRail';
import { NavigationRail } from '@/Components/Atoms/NavigationRail';

interface NewLayoutProps {
  children: React.ReactNode;
}

const NewLayoutRail = (props: NavigationRailProps) => (
  <NavigationRail data-layout-rail>
    {props.children}
  </NavigationRail>
);

const LayoutNavDrawer = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <NavigationDrawer data-layout-drawer>
    {props.children}
  </NavigationDrawer>
);

const LayoutContent = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div className="flex-1" data-layout-content {...props} />
);

const DesktopLayout = (props: NewLayoutProps) => (
  <div
    className="
      h-full w-full flex flex-row
    "
  >
    {props.children}
  </div>
);

export const Layout = {
  Content: LayoutContent,
  Drawer: LayoutNavDrawer,
  Rail: NewLayoutRail,
  Root: DesktopLayout,
};
