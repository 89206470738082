import { useCallback } from 'react';

import { useIsOfficerLicensed } from '@/API/Queries/user/useIsOfficerLicensed';

import type { GeocodeError, IGeoCodeData } from '@/Utils/Helpers/geoCodeConversion';
import { convertToGeoCode } from '@/Utils/Helpers/geoCodeConversion';

interface SuccessfulCallbackResponse {
  validState: boolean;
  geocode: IGeoCodeData;
  error?: GeocodeError;
}
type CallbackResponse = GeocodeError | SuccessfulCallbackResponse;
type Callback = (response: CallbackResponse) => void;
const useGoogleGeocode = () => {
  const { mutate: checkLicense } = useIsOfficerLicensed();

  const getGeocode = useCallback(async (address: string, validateState: boolean, callback: Callback) => {
    convertToGeoCode(address, async (geocodeResponse) => {
      if (!geocodeResponse.error && geocodeResponse.geocode) {
        if (validateState) {
          checkLicense(geocodeResponse.geocode.state, { onSuccess: (response) => {
            if (response && geocodeResponse.geocode) {
              callback({
                geocode: geocodeResponse.geocode,
                validState: response.value,
              });
            }
          } });
        } else {
          callback({
            geocode: geocodeResponse.geocode,
            validState: true,
          });
        }
      }
      if (geocodeResponse.error) {
        callback(geocodeResponse.error);
      }
    });
  }, [checkLicense]);

  return {
    getGeocode,
  };
};

export { useGoogleGeocode };
