import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

import type { CellDataModel, TableColumnSettings, TableFormWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm_pb';
import { ActivityStatus } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { useUpdateActivityStatus } from '@/API/Queries/activities/useUpdateActivityStatus';
import { DYNAMIC_PAGE_MESSAGE_NAME } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { Dialog } from '@/Components/Atoms/Dialog';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column, IHoverActions, TableCellProps } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useSearchParams } from '@/Routes/NavigationContext';
import { EditLoanActivitiesModal } from '@/Routes/Pages/loan/PurposeBuilt/EditLoanActivitiesModal';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export const ActivityTableCollection = (props: TableFormWidgetSettings.AsObject) => {
  const {
    columnsList,
    rowDataList,
  } = props;

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const { loanId: dealId } = useDynamicLoanInfo();
  const { isLoading: isDeleting, mutate: updateActivityStatus } = useUpdateActivityStatus();
  const { showSnackBar } = useShared();
  const { pageId, scopeToken } = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  const [openEdit, setOpenEdit] = useState<{ entityId: string; entityVersion: number }>();
  const [deleteActivityId, setDeleteActivityId] = useState<string | undefined>();

  const handleClickCell = useCallback((row: any) => {
    const entityIdColumnId = columnsList.find((c) => c.title === 'EntityId')?.columnId || '';
    const entityVersionColumnId = columnsList.find((c) => c.title === 'EntityVersion')?.columnId || '';
    const entityId = renderValue(rowDataList[row.index].rowDataMap.find(([cId]) => cId === entityIdColumnId)?.[1].firstLineList[0]);
    const entityVersion = Number(renderValue(rowDataList[row.index].rowDataMap.find(([cId]) => cId === entityVersionColumnId)?.[1].secondLineList[0]));
    setOpenEdit({ entityId, entityVersion });
  }, [columnsList, rowDataList]);

  const fromCellDataModelToTableCell = useCallback((columnId: string, dataModel: CellDataModel.AsObject): TableCellProps<any> => {
    const { badgeValue, badgeVariant, detailPageId, firstLineList, secondLineList } = dataModel;
    if ((badgeValue && badgeVariant) && firstLineList.length === 0) {
      return {
        badge: `${columnId}-badge`,
        type: 'badge',
      };
    }
    return {
      caption: secondLineList.length > 0 ? `${columnId}-cellLineTwo` : '',
      onClick: detailPageId ? (row) => handleClickCell(row) : undefined,
      text: `${columnId}-cellLineOne`,
      type: 'text',
    };
  }, [handleClickCell]);

  const widgetColumns = useMemo(() => (rowDataList.length > 0 ? columnsList.filter((column) => !column.hidden).reduce((acc: Array<Column<any>>, column: TableColumnSettings.AsObject) => {
    const cellMap = rowDataList[0].rowDataMap.find((e) => e[0] === column.columnId);
    if (!column.title) return acc;
    const tableColumn: Column<any> = {
      header: column.title,
      id: column.columnId,
      key: cellMap ? fromCellDataModelToTableCell(column.columnId, cellMap[1]) : { text: '', type: 'text' },
    };
    return [...acc, tableColumn];
  }, []) : []), [columnsList, rowDataList, fromCellDataModelToTableCell]);

  const data = useMemo(() => rowDataList.map((data) => ({
    ...data.rowDataMap.reduce((acc, [columnId, cellConfig]) => {
      if (!cellConfig.kebab) {
        return {
          ...acc,
          [`${columnId}-badge`]: cellConfig.badgeValue ? {
            label: renderValue(cellConfig.badgeValue),
            variant: cellConfig.badgeVariant,
          } : undefined,
          [`${columnId}-cellLineOne`]: cellConfig.firstLineList.length > 0 ? renderValue(cellConfig.firstLineList[0]) : undefined,
          [`${columnId}-cellLineTwo`]: cellConfig.secondLineList.length > 0 ? renderValue(cellConfig.secondLineList[0]) : undefined,
          [`${columnId}-pageId`]: cellConfig.detailPageId,
          [`${columnId}-pageScopeToken`]: cellConfig.detailPageScopeToken,
        };
      }
      return acc;
    }, {}),
  })), [rowDataList]);

  const handleDeleteActivity = useCallback(() => {
    if (deleteActivityId) {
      updateActivityStatus({ activityId: deleteActivityId, status: ActivityStatus.CANCELLED }, {
        onError: () => showSnackBar({ message: 'Something went wrong', open: true }),
        onSettled: () => setDeleteActivityId(undefined),
        onSuccess: () => {
          showSnackBar({ message: 'Activity Deleted', open: true });
          queryClient.invalidateQueries([DYNAMIC_PAGE_MESSAGE_NAME, dealId, pageId, scopeToken]);
        },
      });
    }
  }, [updateActivityStatus, showSnackBar, dealId, pageId, scopeToken, queryClient, deleteActivityId]);

  const handleDeleteClick = useCallback((row: number) => {
    const entityIdColumnId = columnsList.find((c) => c.title === 'EntityId')?.columnId || '';
    const entityId = renderValue(rowDataList[row].rowDataMap.find(([cId]) => cId === entityIdColumnId)?.[1].firstLineList[0]);
    setDeleteActivityId(entityId);
  }, [columnsList, rowDataList]);

  const renderHoverActions = (row: any) => {
    if (!row?.id || !rowDataList[row?.id]) {
      return [];
    }

    const kebabColumnId = columnsList.find((column) => !column.title)?.columnId;
    const cellConfig: CellDataModel.AsObject | undefined = rowDataList[row.id].rowDataMap.find(([rowColumnId]) => rowColumnId === kebabColumnId)?.[1];
    const kebab: Array<IHoverActions<any>> = [];

    if (cellConfig?.kebab?.editEnabled) {
      kebab.push({
        label: 'Edit',
        onClick: () => handleClickCell(row),
      });
    }

    if (cellConfig?.kebab?.deleteEnabled) {
      kebab.push({
        label: 'Delete',
        onClick: () => handleDeleteClick(row.index),
      });
    }

    return kebab;
  };

  const handleCloseAction = () => {
    setOpenEdit(undefined);
    queryClient.invalidateQueries([DYNAMIC_PAGE_MESSAGE_NAME, dealId, pageId, scopeToken]);
  };

  return (
    <>
      <Table
        cardProps={{ }}
        columns={widgetColumns}
        data={data}
        hoverActions={renderHoverActions}
        rowActionType="dropdown"
        width="stretch"
      />
      {openEdit && (
        <EditLoanActivitiesModal
          closeModal={handleCloseAction}
          entityId={openEdit.entityId}
          entityVersion={openEdit.entityVersion}
        />
      )}
      {
        deleteActivityId && (
          <Dialog
            actions={[
              { label: 'Cancel', onClick: () => setDeleteActivityId(undefined), variant: 'tertiary' },
              { danger: true, isLoading: isDeleting, label: 'Delete', onClick: handleDeleteActivity },
            ]}
            onClickDismiss={() => setDeleteActivityId(undefined)}
            title="Delete confirmation"
          >
            <Typography variant="body2">Are you sure you want to delete the activity</Typography>
          </Dialog>
        )
      }
    </>
  );
};
