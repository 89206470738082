import { IndividualEscrowPayment, IndividualFeePayment, IndividualPrepaidPayment, LoanEscrowFee, LoanEscrowFeeCategory, LoanFee, LoanFeeCategory, LoanPrepaidFee, LoanPrepaidFeeCategory } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toProtoDecimalFromObject, toTimestampFromObject } from './helper';
import { toPartyFeePayeeContactInfoCardDataFromObject, toPartySummaryFromObject } from './partyHelper';

const setFeePaidBy = (obj: IndividualEscrowPayment.AsObject | IndividualFeePayment.AsObject | IndividualPrepaidPayment.AsObject,
  individualPayment: IndividualEscrowPayment | IndividualFeePayment | IndividualPrepaidPayment): void => {
  if (obj.paidByBorrowerFinancedAmount) individualPayment.setPaidByBorrowerFinancedAmount(toProtoDecimalFromObject(obj.paidByBorrowerFinancedAmount));
  if (obj.paidByBorrowerOutsideOfClosingAmount) individualPayment.setPaidByBorrowerOutsideOfClosingAmount(toProtoDecimalFromObject(obj.paidByBorrowerOutsideOfClosingAmount));
  if (obj.paidByBorrowerTotalAmount) individualPayment.setPaidByBorrowerTotalAmount(toProtoDecimalFromObject(obj.paidByBorrowerTotalAmount));
  if (obj.paidByLenderAtClosingTotalAmount) individualPayment.setPaidByLenderAtClosingTotalAmount(toProtoDecimalFromObject(obj.paidByLenderAtClosingTotalAmount));
  if (obj.paidByLenderOutsideOfClosingAmount) individualPayment.setPaidByLenderOutsideOfClosingAmount(toProtoDecimalFromObject(obj.paidByLenderOutsideOfClosingAmount));
  if (obj.paidByLenderTotalAmount) individualPayment.setPaidByLenderTotalAmount(toProtoDecimalFromObject(obj.paidByLenderTotalAmount));
  if (obj.paidBySellerAtClosingTotalAmount) individualPayment.setPaidBySellerAtClosingTotalAmount(toProtoDecimalFromObject(obj.paidBySellerAtClosingTotalAmount));
  if (obj.paidBySellerCreditAmount) individualPayment.setPaidBySellerCreditAmount(toProtoDecimalFromObject(obj.paidBySellerCreditAmount));
  if (obj.paidByLenderCreditAmount) individualPayment.setPaidByLenderCreditAmount(toProtoDecimalFromObject(obj.paidByLenderCreditAmount));
  if (obj.paidBySellerOutsideOfClosingAmount) individualPayment.setPaidBySellerOutsideOfClosingAmount(toProtoDecimalFromObject(obj.paidBySellerOutsideOfClosingAmount));
  if (obj.paidBySellerTotalAmount) individualPayment.setPaidBySellerTotalAmount(toProtoDecimalFromObject(obj.paidBySellerTotalAmount));
};

const setFeePaidTo = (obj: IndividualEscrowPayment.AsObject | IndividualFeePayment.AsObject | IndividualPrepaidPayment.AsObject,
  individualPayment: IndividualEscrowPayment | IndividualFeePayment | IndividualPrepaidPayment): void => {
  if (obj.payeeContactInfo) individualPayment.setPayeeContactInfo(toPartyFeePayeeContactInfoCardDataFromObject(obj.payeeContactInfo));
  if (obj.payeePartySummary) individualPayment.setPayeePartySummary(toPartySummaryFromObject(obj.payeePartySummary));
  if (obj.paymentDate) individualPayment.setPaymentDate(toTimestampFromObject(obj.paymentDate));
};

const toIndividualFeePaymentFromObject = (obj: IndividualFeePayment.AsObject): IndividualFeePayment => {
  const individualFeePayment = new IndividualFeePayment();

  setFeePaidBy(obj, individualFeePayment);
  if (obj.paidByOtherAtClosingTotalAmount) individualFeePayment.setPaidByOtherAtClosingTotalAmount(toProtoDecimalFromObject(obj.paidByOtherAtClosingTotalAmount));
  if (obj.paidByOtherOutsideOfClosingAmount) individualFeePayment.setPaidByOtherOutsideOfClosingAmount(toProtoDecimalFromObject(obj.paidByOtherOutsideOfClosingAmount));
  if (obj.paidByOtherTotalAmount) individualFeePayment.setPaidByOtherTotalAmount(toProtoDecimalFromObject(obj.paidByOtherTotalAmount));

  setFeePaidTo(obj, individualFeePayment);

  if (obj.borrowerChosenProviderIndicator) individualFeePayment.setBorrowerChosenProviderIndicator(obj.borrowerChosenProviderIndicator);
  if (obj.feeCalculationType) individualFeePayment.setFeeCalculationType(obj.feeCalculationType);
  if (obj.feePaymentPaidByOtherType) individualFeePayment.setFeePaymentPaidByOtherType(obj.feePaymentPaidByOtherType);
  if (obj.feePercentBasisType) individualFeePayment.setFeePercentBasisType(obj.feePercentBasisType);
  if (obj.feeSource) individualFeePayment.setFeeSource(obj.feeSource);
  if (obj.feeSpecifiedFixedAmount) individualFeePayment.setFeeSpecifiedFixedAmount(toProtoDecimalFromObject(obj.feeSpecifiedFixedAmount));
  if (obj.feeToleranceCategoryType) individualFeePayment.setFeeToleranceCategoryType(obj.feeToleranceCategoryType);
  if (obj.feeTotalPercent) individualFeePayment.setFeeTotalPercent(toProtoDecimalFromObject(obj.feeTotalPercent));
  if (obj.feeType) individualFeePayment.setFeeType(obj.feeType);
  if (obj.integratedDisclosureLineNumberValue) individualFeePayment.setIntegratedDisclosureLineNumberValue(obj.integratedDisclosureLineNumberValue);
  if (obj.integratedDisclosureSectionType) individualFeePayment.setIntegratedDisclosureSectionType(obj.integratedDisclosureSectionType);
  if (obj.regulationZPointsAndFeesIndicator) individualFeePayment.setRegulationZPointsAndFeesIndicator(obj.regulationZPointsAndFeesIndicator);
  if (obj.providerSelectedFromLenderListIndicator) individualFeePayment.setProviderSelectedFromLenderListIndicator(obj.providerSelectedFromLenderListIndicator);

  return individualFeePayment;
};

const toLoanFeeFromObject = (obj: LoanFee.AsObject): LoanFee => {
  const loanFee = new LoanFee();
  loanFee.setFeeId(obj.feeId);
  loanFee.setName(obj.name);
  if (obj.basedOn) loanFee.setBasedOn(obj.basedOn);
  if (obj.isApr) loanFee.setIsApr(obj.isApr);
  if (obj.paidTo) loanFee.setPaidTo(obj.paidTo);
  if (obj.source) loanFee.setSource(obj.source);
  if (obj.sourceMessage) loanFee.setSourceMessage(obj.sourceMessage);
  if (obj.borrowerPaid) loanFee.setBorrowerPaid(toProtoDecimalFromObject(obj.borrowerPaid));
  if (obj.lenderPaid) loanFee.setLenderPaid(toProtoDecimalFromObject(obj.lenderPaid));
  if (obj.otherPaid) loanFee.setOtherPaid(toProtoDecimalFromObject(obj.otherPaid));
  if (obj.sellerPaid) loanFee.setSellerPaid(toProtoDecimalFromObject(obj.sellerPaid));
  if (obj.total) loanFee.setTotal(toProtoDecimalFromObject(obj.total));
  if (obj.feePayment) loanFee.setFeePayment(toIndividualFeePaymentFromObject(obj.feePayment));

  return loanFee;
};

const toLoanFeeCategoryFromObject = (obj: LoanFeeCategory.AsObject): LoanFeeCategory => {
  const loanFeeCategory = new LoanFeeCategory();
  if (obj.category) loanFeeCategory.setCategory(obj.category);
  if (obj.sumTotal) loanFeeCategory.setSumTotal(toProtoDecimalFromObject(obj.sumTotal));
  if (obj.feesList) {
    obj.feesList.forEach((fee) => {
      loanFeeCategory.addFees(toLoanFeeFromObject(fee));
    });
  }

  return loanFeeCategory;
};

const toLoanEscrowPrepaidFeeFromObject = (obj: LoanEscrowFee.AsObject | LoanPrepaidFee.AsObject,
  loanEscrowPrepaidFee: LoanEscrowFee | LoanPrepaidFee): void => {
  if (obj.amount) loanEscrowPrepaidFee.setAmount(toProtoDecimalFromObject(obj.amount));
  if (obj.borrowerPaid) loanEscrowPrepaidFee.setBorrowerPaid(toProtoDecimalFromObject(obj.borrowerPaid));
  if (obj.feeId) loanEscrowPrepaidFee.setFeeId(obj.feeId);
  if (obj.isApr) loanEscrowPrepaidFee.setIsApr(obj.isApr);
  if (obj.lenderPaid) loanEscrowPrepaidFee.setLenderPaid(toProtoDecimalFromObject(obj.lenderPaid));
  if (obj.name) loanEscrowPrepaidFee.setName(obj.name);
  if (obj.paidTo) loanEscrowPrepaidFee.setPaidTo(obj.paidTo);
  if (obj.sellerPaid) loanEscrowPrepaidFee.setSellerPaid(toProtoDecimalFromObject(obj.sellerPaid));
  if (obj.sourceMessage) loanEscrowPrepaidFee.setSourceMessage(obj.sourceMessage);
  if (obj.specialCase) loanEscrowPrepaidFee.setSpecialCase(obj.specialCase);
  if (obj.timeframe) loanEscrowPrepaidFee.setTimeframe(obj.timeframe);
  if (obj.total) loanEscrowPrepaidFee.setTotal(toProtoDecimalFromObject(obj.total));

  // overrides
  if (obj.manualOverrideIndicator) loanEscrowPrepaidFee.setManualOverrideIndicator(obj.manualOverrideIndicator);
  if (obj.manualOverrideTimeframe) loanEscrowPrepaidFee.setManualOverrideTimeframe(Number(obj.manualOverrideTimeframe));
  if (obj.manualOverrideTotalAmount) loanEscrowPrepaidFee.setManualOverrideTotalAmount(toProtoDecimalFromObject(obj.manualOverrideTotalAmount));
};

const toIndividualEscrowPaymentFromObject = (obj: IndividualEscrowPayment.AsObject): IndividualEscrowPayment => {
  const individualEscrowPayment = new IndividualEscrowPayment();

  setFeePaidBy(obj, individualEscrowPayment);

  setFeePaidTo(obj, individualEscrowPayment);

  if (obj.borrowerChosenProviderIndicator) individualEscrowPayment.setBorrowerChosenProviderIndicator(obj.borrowerChosenProviderIndicator);
  if (obj.escrowCalculationType) individualEscrowPayment.setEscrowCalculationType(obj.escrowCalculationType);
  if (obj.escrowSource) individualEscrowPayment.setEscrowSource(obj.escrowSource);
  if (obj.escrowTotalPercent) individualEscrowPayment.setEscrowTotalPercent(toProtoDecimalFromObject(obj.escrowTotalPercent));
  if (obj.escrowType) individualEscrowPayment.setEscrowType(obj.escrowType);
  if (obj.integratedDisclosureLineNumberValue) individualEscrowPayment.setIntegratedDisclosureLineNumberValue(obj.integratedDisclosureLineNumberValue);
  if (obj.integratedDisclosureSectionType) individualEscrowPayment.setIntegratedDisclosureSectionType(obj.integratedDisclosureSectionType);
  if (obj.regulationZPointsAndFeesIndicator) individualEscrowPayment.setRegulationZPointsAndFeesIndicator(obj.regulationZPointsAndFeesIndicator);
  if (obj.providerSelectedFromLenderListIndicator) individualEscrowPayment.setProviderSelectedFromLenderListIndicator(obj.providerSelectedFromLenderListIndicator);

  return individualEscrowPayment;
};

const toLoanEscrowFeeFromObject = (obj: LoanEscrowFee.AsObject): LoanEscrowFee => {
  const loanEscrowFee = new LoanEscrowFee();
  if (obj.escrowPayment) loanEscrowFee.setEscrowPayment(toIndividualEscrowPaymentFromObject(obj.escrowPayment));
  toLoanEscrowPrepaidFeeFromObject(obj, loanEscrowFee);

  return loanEscrowFee;
};

const toLoanEscrowFeeCategoryFromObject = (obj: LoanEscrowFeeCategory.AsObject): LoanEscrowFeeCategory => {
  const loanEscrowFeeCategory = new LoanEscrowFeeCategory();
  if (obj.category) loanEscrowFeeCategory.setCategory(obj.category);
  if (obj.sumTotal) loanEscrowFeeCategory.setSumTotal(toProtoDecimalFromObject(obj.sumTotal));
  if (obj.feesList) {
    obj.feesList.forEach((fee) => {
      loanEscrowFeeCategory.addFees(toLoanEscrowFeeFromObject(fee));
    });
  }

  return loanEscrowFeeCategory;
};

const toIndividualPrepaidPaymentFromObject = (obj: IndividualPrepaidPayment.AsObject): IndividualPrepaidPayment => {
  const individualPrepaidPayment = new IndividualPrepaidPayment();

  setFeePaidBy(obj, individualPrepaidPayment);

  setFeePaidTo(obj, individualPrepaidPayment);

  if (obj.borrowerChosenProviderIndicator) individualPrepaidPayment.setBorrowerChosenProviderIndicator(obj.borrowerChosenProviderIndicator);
  if (obj.integratedDisclosureLineNumberValue) individualPrepaidPayment.setIntegratedDisclosureLineNumberValue(obj.integratedDisclosureLineNumberValue);
  if (obj.integratedDisclosureSectionType) individualPrepaidPayment.setIntegratedDisclosureSectionType(obj.integratedDisclosureSectionType);
  if (obj.prepaidCalculationType) individualPrepaidPayment.setPrepaidCalculationType(obj.prepaidCalculationType);
  if (obj.prepaidSource) individualPrepaidPayment.setPrepaidSource(obj.prepaidSource);
  if (obj.prepaidTotalAmount) individualPrepaidPayment.setPrepaidTotalAmount(toProtoDecimalFromObject(obj.prepaidTotalAmount));
  if (obj.prepaidType) individualPrepaidPayment.setPrepaidType(obj.prepaidType);
  if (obj.regulationZPointsAndFeesIndicator) individualPrepaidPayment.setRegulationZPointsAndFeesIndicator(obj.regulationZPointsAndFeesIndicator);
  if (obj.providerSelectedFromLenderListIndicator) individualPrepaidPayment.setProviderSelectedFromLenderListIndicator(obj.providerSelectedFromLenderListIndicator);

  return individualPrepaidPayment;
};

const toLoanPrepaidFeeFromObject = (obj: LoanPrepaidFee.AsObject): LoanPrepaidFee => {
  const loanPrepaidFee = new LoanPrepaidFee();
  if (obj.prepaidPayment) loanPrepaidFee.setPrepaidPayment(toIndividualPrepaidPaymentFromObject(obj.prepaidPayment));
  toLoanEscrowPrepaidFeeFromObject(obj, loanPrepaidFee);

  return loanPrepaidFee;
};

const toLoanPrepaidFeeCategoryFromObject = (obj: LoanPrepaidFeeCategory.AsObject): LoanPrepaidFeeCategory => {
  const loanPrepaidFeeCategory = new LoanPrepaidFeeCategory();
  if (obj.category) loanPrepaidFeeCategory.setCategory(obj.category);
  if (obj.sumTotal) loanPrepaidFeeCategory.setSumTotal(toProtoDecimalFromObject(obj.sumTotal));
  if (obj.feesList) {
    obj.feesList.forEach((fee) => {
      loanPrepaidFeeCategory.addFees(toLoanPrepaidFeeFromObject(fee));
    });
  }

  return loanPrepaidFeeCategory;
};

export const toLoanFeeCategoriesFromObjectArray = (objArray: LoanFeeCategory.AsObject[]): LoanFeeCategory[] => {
  const loanFeeCategories = new Array<LoanFeeCategory>();
  objArray.forEach((category) => {
    loanFeeCategories.push(toLoanFeeCategoryFromObject(category));
  });

  return loanFeeCategories;
};

export const toLoanEscrowFeeCategoriesFromObjectArray = (objArray: LoanEscrowFeeCategory.AsObject[]): LoanEscrowFeeCategory[] => {
  const loanEscrowFeeCategories = new Array<LoanEscrowFeeCategory>();
  objArray.forEach((category) => {
    loanEscrowFeeCategories.push(toLoanEscrowFeeCategoryFromObject(category));
  });

  return loanEscrowFeeCategories;
};

export const toLoanPrepaidFeeCategoriesFromObjectArray = (objArray: LoanPrepaidFeeCategory.AsObject[]): LoanPrepaidFeeCategory[] => {
  const loanPrepaidFeeCategories = new Array<LoanPrepaidFeeCategory>();
  objArray.forEach((category) => {
    loanPrepaidFeeCategories.push(toLoanPrepaidFeeCategoryFromObject(category));
  });

  return loanPrepaidFeeCategories;
};
