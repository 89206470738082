import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useCreateBusinessProcessTemplate } from '@/API/Queries/businessProcess/useCreateBusinessProcessTemplate';
import { useProgressItemTemplates } from '@/API/Queries/mortgageConfig/useProgressItemTemplates';
import AutoComplete from '@/Components/Atoms/AutoComplete/AutoComplete.dde';
import { Button } from '@/Components/Atoms/Button';
import { TextInput } from '@/Components/Atoms/Input/Input.dde';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanelElement, getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

interface Props {
  open: boolean;
  close: () => void;
}

export const BusinessProcessTemplateCreate = (props: Props) => {
  const { close, open } = props;

  const navigate = useNavigate();
  const { isLoading, mutate: createBPTemplate } = useCreateBusinessProcessTemplate();
  const { data: progressItems } = useProgressItemTemplates();
  const progressOptions = useMemo(() => progressItems?.map((progressItem) => getPanelElementOption({
    headerText: progressItem.name,
    id: progressItem.identifier,
  })), [progressItems]);

  const handleSubmit = (values: DynamicDataElementValues) => {
    createBPTemplate({
      internalName: values.internalName.value,
      progressItemId: values.progressItem.value?.id || '',
      title: values.externalName.value,
    }, {
      onSuccess: (response) => navigate(`/admin/businessProcessTemplate/${response.businessProcessTemplateId}/details`),
    });
  };

  return (
    <Dialog
      onOpenChange={close}
      open={open}
    >
      <DialogLayout asChild>
        <DynamicFormProvider onSubmit={handleSubmit}>
          <DialogHeader title="Create new Business Process Template" />

          <DialogContent className="p-4 sm:p-12">
            <div className="w-[508px] mx-auto flex flex-col gap-4">
              <TextInput panelElement={getPanelElement({ headerText: 'Internal Name', id: 'internalName', order: 1, requirement: { errorMessage: 'Required Field', required: true } })} />
              <TextInput panelElement={getPanelElement({ headerText: 'External Name', id: 'externalName', order: 2, requirement: { errorMessage: 'Required Field', required: true } })} />
              <AutoComplete panelElement={getPanelElement({ headerText: 'Progress Item', id: 'progressItem', optionsList: progressOptions, order: 3 })} />
            </div>
          </DialogContent>

          <DialogFooter borderTop>
            <Button
              isLoading={isLoading}
              label="submit"
              type="submit"
            />
          </DialogFooter>
        </DynamicFormProvider>
      </DialogLayout>
    </Dialog>
  );
};
