import type { ReactNode } from 'react';

import type { IButtonProps } from '@/Components/Atoms/Button';
import type { Panel, PanelElement, PanelElementMask, PanelElementOption, PanelElementRequirement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { FieldAttachment } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb';
import { LoanPage, LoanPageSaveStrategy, LoanPageType, LoanPageWidgetExpanderState } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb';
import { FieldConfig } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { LoanPageAction, LoanPageActionDisplayVariant, LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { ActionTypeEnum } from '@/API/Models/Wilqo.Shared.Models/BrandAdminModels_pb';

export const getPanelElementRequiment = (errorMessage = 'Required'): PanelElementRequirement.AsObject => ({ errorMessage, required: true });

export interface PanelElementExtended extends PanelElement.AsObject {
  variant?: any;
  enrichedHeaderText?: ReactNode | string;
  enrichedSummaryText?: ReactNode | string;
  actions?: Array<IButtonProps>;
  orientation?: number;
  attach?: FieldAttachment.AsObject;
  trigger?: 'chip' | 'text-field';
}

export const getPanelElement = (panelElement: Partial<PanelElementExtended>): PanelElementExtended => {
  const {
    disabled = false,
    externalLink = { value: '' },
    fieldLibraryIdentifier = { value: '' },
    enrichedHeaderText,
    headerText = '',
    id = '',
    mask = { allowEmptyFormatting: false, customFormat: '', customMask: '', type: '' },
    modalConfirmationText = '',
    optionsList = [],
    order = 1,
    path = '',
    privateInput = false,
    requirement = { errorMessage: '', required: false },
    summaryText = '',
    thumbnail = '',
    type = '',
    validationEvent = { value: '' },
    validatorsList = [],
    variant,
    orientation,
    attach,
    value
  } = panelElement;
  const pe: PanelElementExtended = {
    disabled,
    enrichedHeaderText,
    externalLink,
    fieldLibraryIdentifier,
    headerText,
    id,
    mask,
    modalConfirmationText,
    optionsList,
    order,
    orientation,
    path,
    privateInput,
    requirement,
    summaryText,
    thumbnail,
    type,
    validationEvent,
    validatorsList,
    variant,
    attach,
    value
  };
  return pe;
};

export const getPanelElementOption = (option: Partial<PanelElementOption.AsObject>): PanelElementOption.AsObject => {
  const {
    disabled = false,
    headerText = '',
    id = '',
    modalConfirmationText = '',
    order = 1,
    path = '',
    summaryText = '',
    type = '',
    value,
  } = option;

  const optionElement: PanelElementOption.AsObject = {
    disabled,
    headerText,
    id,
    modalConfirmationText,
    order,
    path,
    summaryText,
    type,
    value,
  };

  return optionElement;
};

export const getPanelElementMask = (mask: Partial<PanelElementMask.AsObject>): PanelElementMask.AsObject => ({
  allowEmptyFormatting: mask.allowEmptyFormatting || false,
  customFormat: mask.customFormat || '',
  customMask: mask.customMask || '',
  type: mask.type || '',
});

export const getPanel = (panel: Partial<Panel.AsObject>): Panel.AsObject => ({
  coverImage: '',
  footerText: panel.footerText ?? '',
  headerText: '',
  hyperlinksList: [],
  id: '',
  panelElementsList: [],
  showNext: false,
  showNextModalConfirmationText: '',
  summaryText: '',
  type: { value: '' },
  ...panel,
});

export const getPageConfig = (page: Partial<LoanPage.AsObject>): LoanPage.AsObject => ({
  businessProcessDomain: '',
  conditionalAsyncSubmitConfigsList: [],
  defaultWidgetExpandedState: LoanPageWidgetExpanderState.LOAN_PAGE_WIDGET_EXPANDER_STATE_COLLAPSED,
  id: '',
  panelElementConditionalOverridesList: page.panelElementConditionalOverridesList ?? [],
  loanPageType: LoanPageType.LOAN_PAGE_CONFIG_TYPE_HOME,
  showFieldValidationsList: [],
  widgetsList: [],
  saveStrategy: LoanPageSaveStrategy.LOAN_PAGE_SAVE_STRATEGY_UNKNOWN,
  bannerList: [],
  ...page,
})

export const getFieldConfig = (field: Partial<FieldConfig>): FieldConfig => ({
  helpText: '',
  hidden: false,
  id: '',
  leadingText: '',
  ...field,
})

export const getPageAction = (pageAction: Partial<LoanPageAction.AsObject>): LoanPageAction.AsObject => ({
  type: LoanPageActionType.LOAN_PAGE_ACTION_TYPE_UNKNOWN,
  variant: LoanPageActionDisplayVariant.LOAN_PAGE_ACTION_DISPLAY_VARIANT_PRIMARY,
  id: '',
  label: '',
  name: '',
  actionType: ActionTypeEnum.ACTION_TYPE_ENUM_UNKNOWN,
  actionsListList: [],
  targetId: '',
  dialogPageId: '',
  additionalArgsMap: [],
  ...pageAction,
})