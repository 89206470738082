import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useMatch } from 'react-router';

import { useNavigate } from '@/Routes/NavigationContext';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { Icon } from './Icon';
import { Tooltip } from './Tooltip';
import { Typography } from './Typography';

export interface TabData {
  icon?: string;
  matchPattern?: string;
  label?: string;
  linkTo?: string;
  onClick?: () => void;
  active?: boolean;
  tooltip?: string;
}

export interface ITabsProps {
  tabsData: Array<TabData>;
  justify?: 'flex-start' | 'space-between';
  sticky?: boolean;
  stretch?: boolean;
  className?: string;
  noPadding?: boolean;
}

export interface TabItemProps extends TabData {
  stretch: boolean;
}

const TabItem = (props: TabItemProps) => {
  const { active: overideMatch, icon, label, linkTo = '', matchPattern, onClick, stretch, tooltip } = props;
  const match = useMatch({ caseSensitive: false, path: matchPattern || linkTo });
  const navigate = useNavigate();

  const tabRef = useRef<HTMLDivElement>(null);

  const active = overideMatch || Boolean(match);

  const handleClick = () => {
    if (onClick) onClick();
    else navigate(linkTo, { replace: true });
  };

  useEffect(() => {
    if (active && tabRef.current) {
      const parent = tabRef.current.parentElement!;
      const tabPosition = (tabRef.current?.offsetLeft - parent.offsetLeft) - (parent.getBoundingClientRect().right / 2) + (tabRef.current?.offsetWidth / 2);
      tabRef.current?.parentElement?.scrollTo({ behavior: 'smooth', left: tabPosition });
    }
  }, [active]);

  return (
    <div
      ref={tabRef}
      className={
        clsx(
          'uppercase cursor-pointer h-full text-sm flex-1 text-center',
          'max-h-12 p-4 pb-[14px] border-b-2',
          'hover:bg-on-surface-states-hover',
          'focus:bg-on-surface-states-focus',
          'active:bg-on-surface-states-pressed',
          'disabled:bg-on-surface-states-disabled',
          {
            'border-primary-on-surface': active,
            'border-transparent': !active,
            'flex-1': stretch,
          },
        )
      }
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      {icon && tooltip && (
        <Tooltip className="tooltip " message={tooltip}>
          <Icon
            icon={icon}
            selected={active}
          />
        </Tooltip>
      )}
      {icon && !tooltip && (
        <Icon
          icon={icon}
          selected={Boolean(match)}
        />
      )}
      {label && (
        <Typography
          className={
            clsx(
              'whitespace-pre',
              {
                'pl-2': Boolean(icon),
                'text-on-surface-inactive': !active,
                'text-primary-on-surface': active,
              },
            )
          }
          variant="body2Bold"
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

const Tabs = (props: ITabsProps) => {
  const { className, noPadding, sticky = false, stretch = false, tabsData } = props;
  const { windowType } = useWindow();
  const smallScreen = windowType === 'mobile' || windowType === 'tablet';

  return (
    <div
      className={clsx(
        className, 'flex items-stretch bg-surface border-b-[1px] border-on-surface-stroke w-full overflow-auto flex-row',
        {
          'px-6': !noPadding,
          'sticky top-16': sticky,
        },
      )}
      style={{ scrollbarWidth: smallScreen ? 'auto' : 'none' }}
    >
      <div className="flex items-center justify-start w-full">
        {tabsData.map((tab) => (
          <TabItem key={tab.label} {...tab} stretch={stretch} />
        ))}
      </div>
    </div>
  );
};

export { TabItem, Tabs };
