/* eslint-disable sonarjs/no-nested-template-literals */
import type { CardProps, CardVariant } from '@/Components/Atoms/Card/Card';
import type { CardHeaderProps } from '@/Components/Atoms/Card/CardHeader';
import type { ContextualCardHeaderProps } from '@/Components/Atoms/Card/ContextualCardHeader';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { DesktopTable } from './desktop/DesktopTable';
import type { TableCellProps } from './desktop/TableCell';
import type { IHoverActions as HoverActions } from './desktop/TableHoverActions';
import { MobileTable } from './mobile/MobileTable';

export type { TableCellProps };

export interface Column<T extends Record<any, any>> {
  header: string;
  key: TableCellProps<T>;
  id: string;
  hidden?: boolean;
  mobile?: string;
}

export type ActionType = 'button' | 'dropdown';

type LabelFunction<T> = (original: T) => string;
export type ActionLabel<T> = LabelFunction<T> | string;

export interface IHoverActions<T> extends Omit<HoverActions<T>, 'label'> {
  label: ActionLabel<T>;
}

interface CardTableHeaderProps extends Omit<CardHeaderProps, 'close' | 'contextualHeaderProps' | 'disableAccordion' | 'open' | 'variant'> {
  contextualHeaderProps?: Omit<ContextualCardHeaderProps, 'onClickClose'>;
}

interface TableCardProps extends Omit<CardProps, 'children' | 'headerProps' | 'variant'> {
  headerProps?: CardTableHeaderProps;
  variant?: CardVariant;
}

export type TableHoverAct<T> = Array<IHoverActions<T>> | ((row: T, index: number) => Array<IHoverActions<T>>);
export interface TableSort {
  columnId: string;
  isAscending: boolean;
}

export interface TableProps<T extends Record<string, unknown>> {
  columns: Array<any>;
  data: Array<T>;
  cardProps: TableCardProps;
  hoverActions?: TableHoverAct<T>;
  setRowSelection?: (list: Array<T>) => void;
  onClickRow?: (item: any) => void;
  isLoading?: boolean;
  loadingRows?: number;
  rowActionType?: ActionType;
  width?: 'content' | 'stretch';
  expandable?: boolean;
  noFooter?: boolean;
  preSelectedItems?: Array<any>;
  handleNewPage?: (newPage: number) => void;
  handleNewPageSize?: (newPageSize: number) => void;
  hasOwnPagination?: boolean;
  totalItems?: number;
  numberOfPages?: number;
  currentPage?: number;
  defaultPageSize?: number;
  sort?: TableSort;
  setSort?: (sort: TableSort) => void;
}

export const Table = <T extends Record<string, unknown>>(props: TableProps<T>) => {
  const { windowType } = useWindow();

  if (windowType === 'mobile') return <MobileTable {...props} />;
  return <DesktopTable {...props} />;
};
