// source: Wilqo.Shared.Models/ActivityAdminModels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb = require('../Wilqo.Shared.Models/Wilqo_Shared_Models_UIComponents_Common_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Wilqo_Shared_Models_UIComponents_Common_pb);
goog.exportSymbol('proto.wilqo.shared.models.DataFieldGroup', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DataFieldTemplate', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DataFieldTemplateSearchTerm', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerAsyncValidation', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerAsyncValidationItem', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerAsyncValidationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanel', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelElement', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelElementMask', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelElementOption', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelElementRequirement', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelElementResponse', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelElementValidation', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelHyperlinkElement', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelSequence', null, global);
goog.exportSymbol('proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton', null, global);
goog.exportSymbol('proto.wilqo.shared.models.TableButton', null, global);
goog.exportSymbol('proto.wilqo.shared.models.TableColumn', null, global);
goog.exportSymbol('proto.wilqo.shared.models.TableIcon', null, global);
goog.exportSymbol('proto.wilqo.shared.models.TableParameters', null, global);
goog.exportSymbol('proto.wilqo.shared.models.TypeSpecificParameters', null, global);
goog.exportSymbol('proto.wilqo.shared.models.ValidationEventEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelSequence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DesignerPanelSequence.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelSequence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelSequence.displayName = 'proto.wilqo.shared.models.DesignerPanelSequence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DesignerPanel.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanel.displayName = 'proto.wilqo.shared.models.DesignerPanel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelHyperlinkElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelHyperlinkElement.displayName = 'proto.wilqo.shared.models.DesignerPanelHyperlinkElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DesignerPanelElement.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelElement.displayName = 'proto.wilqo.shared.models.DesignerPanelElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelElementRequirement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelElementRequirement.displayName = 'proto.wilqo.shared.models.DesignerPanelElementRequirement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelElementValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelElementValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelElementValidation.displayName = 'proto.wilqo.shared.models.DesignerPanelElementValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelElementOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelElementOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelElementOption.displayName = 'proto.wilqo.shared.models.DesignerPanelElementOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelElementMask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelElementMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelElementMask.displayName = 'proto.wilqo.shared.models.DesignerPanelElementMask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.displayName = 'proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerAsyncValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DesignerAsyncValidation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerAsyncValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerAsyncValidation.displayName = 'proto.wilqo.shared.models.DesignerAsyncValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerAsyncValidationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerAsyncValidationItem.displayName = 'proto.wilqo.shared.models.DesignerAsyncValidationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DataFieldGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DataFieldGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DataFieldGroup.displayName = 'proto.wilqo.shared.models.DataFieldGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DataFieldTemplateSearchTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DataFieldTemplateSearchTerm.displayName = 'proto.wilqo.shared.models.DataFieldTemplateSearchTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DataFieldTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.DataFieldTemplate.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.DataFieldTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DataFieldTemplate.displayName = 'proto.wilqo.shared.models.DataFieldTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.TypeSpecificParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.TypeSpecificParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.TypeSpecificParameters.displayName = 'proto.wilqo.shared.models.TypeSpecificParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.TableParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.models.TableParameters.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.models.TableParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.TableParameters.displayName = 'proto.wilqo.shared.models.TableParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.TableIcon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.TableIcon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.TableIcon.displayName = 'proto.wilqo.shared.models.TableIcon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.TableColumn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.TableColumn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.TableColumn.displayName = 'proto.wilqo.shared.models.TableColumn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.TableButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.TableButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.TableButton.displayName = 'proto.wilqo.shared.models.TableButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.DesignerPanelElementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.DesignerPanelElementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.DesignerPanelElementResponse.displayName = 'proto.wilqo.shared.models.DesignerPanelElementResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DesignerPanelSequence.repeatedFields_ = [5,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelSequence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelSequence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelSequence.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastModified: (f = msg.getLastModified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    proto.wilqo.shared.models.DesignerPanel.toObject, includeInstance),
    panelSequenceVersion: jspb.Message.getFieldWithDefault(msg, 6, 0),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    logicalScopeIdentifier: jspb.Message.getFieldWithDefault(msg, 8, ""),
    navigationButtonsList: jspb.Message.toObjectList(msg.getNavigationButtonsList(),
    proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.toObject, includeInstance),
    asyncValidationsList: jspb.Message.toObjectList(msg.getAsyncValidationsList(),
    proto.wilqo.shared.models.DesignerAsyncValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence}
 */
proto.wilqo.shared.models.DesignerPanelSequence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelSequence;
  return proto.wilqo.shared.models.DesignerPanelSequence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelSequence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence}
 */
proto.wilqo.shared.models.DesignerPanelSequence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.DesignerPanel;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPanelSequenceVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalScopeIdentifier(value);
      break;
    case 9:
      var value = new proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.deserializeBinaryFromReader);
      msg.addNavigationButtons(value);
      break;
    case 10:
      var value = new proto.wilqo.shared.models.DesignerAsyncValidation;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerAsyncValidation.deserializeBinaryFromReader);
      msg.addAsyncValidations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelSequence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelSequence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelSequence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.shared.models.DesignerPanel.serializeBinaryToWriter
    );
  }
  f = message.getPanelSequenceVersion();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLogicalScopeIdentifier();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNavigationButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.serializeBinaryToWriter
    );
  }
  f = message.getAsyncValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.wilqo.shared.models.DesignerAsyncValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_modified = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getLastModified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
*/
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated DesignerPanel panels = 5;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanel>}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerPanel, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanel>} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
*/
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanel}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.DesignerPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};


/**
 * optional uint32 panel_sequence_version = 6;
 * @return {number}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getPanelSequenceVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setPanelSequenceVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string logical_scope_identifier = 8;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getLogicalScopeIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setLogicalScopeIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated DesignerPanelSequenceNavigationButton navigation_buttons = 9;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton>}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getNavigationButtonsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton, 9));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton>} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
*/
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setNavigationButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.addNavigationButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.clearNavigationButtonsList = function() {
  return this.setNavigationButtonsList([]);
};


/**
 * repeated DesignerAsyncValidation async_validations = 10;
 * @return {!Array<!proto.wilqo.shared.models.DesignerAsyncValidation>}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.getAsyncValidationsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerAsyncValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerAsyncValidation, 10));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerAsyncValidation>} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
*/
proto.wilqo.shared.models.DesignerPanelSequence.prototype.setAsyncValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidation}
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.addAsyncValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.shared.models.DesignerAsyncValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequence} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequence.prototype.clearAsyncValidationsList = function() {
  return this.setAsyncValidationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DesignerPanel.repeatedFields_ = [5,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    headerText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    showNext: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    proto.wilqo.shared.models.DesignerPanelElement.toObject, includeInstance),
    showNextModalConfirmationText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: (f = msg.getType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    coverImage: jspb.Message.getFieldWithDefault(msg, 8, ""),
    footerText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    hyperlinksList: jspb.Message.toObjectList(msg.getHyperlinksList(),
    proto.wilqo.shared.models.DesignerPanelHyperlinkElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanel}
 */
proto.wilqo.shared.models.DesignerPanel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanel;
  return proto.wilqo.shared.models.DesignerPanel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanel}
 */
proto.wilqo.shared.models.DesignerPanel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowNext(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.DesignerPanelElement;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelElement.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShowNextModalConfirmationText(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImage(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooterText(value);
      break;
    case 10:
      var value = new proto.wilqo.shared.models.DesignerPanelHyperlinkElement;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelHyperlinkElement.deserializeBinaryFromReader);
      msg.addHyperlinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShowNext();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.shared.models.DesignerPanelElement.serializeBinaryToWriter
    );
  }
  f = message.getShowNextModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCoverImage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFooterText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHyperlinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.wilqo.shared.models.DesignerPanelHyperlinkElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string header_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string summary_text = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool show_next = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getShowNext = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setShowNext = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated DesignerPanelElement panel_elements = 5;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanelElement>}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanelElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerPanelElement, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanelElement>} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
*/
proto.wilqo.shared.models.DesignerPanel.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanelElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanelElement}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.DesignerPanelElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};


/**
 * optional string show_next_modal_confirmation_text = 6;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getShowNextModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setShowNextModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue type = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
*/
proto.wilqo.shared.models.DesignerPanel.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.hasType = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string cover_image = 8;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getCoverImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setCoverImage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string footer_text = 9;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getFooterText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.setFooterText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated DesignerPanelHyperlinkElement hyperlinks = 10;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanelHyperlinkElement>}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.getHyperlinksList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanelHyperlinkElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerPanelHyperlinkElement, 10));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanelHyperlinkElement>} value
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
*/
proto.wilqo.shared.models.DesignerPanel.prototype.setHyperlinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement}
 */
proto.wilqo.shared.models.DesignerPanel.prototype.addHyperlinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.shared.models.DesignerPanelHyperlinkElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanel} returns this
 */
proto.wilqo.shared.models.DesignerPanel.prototype.clearHyperlinksList = function() {
  return this.setHyperlinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelHyperlinkElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelHyperlinkElement;
  return proto.wilqo.shared.models.DesignerPanelHyperlinkElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.models.HyperlinkTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelHyperlinkElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keyword = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional HyperlinkTypeEnum type = 6;
 * @return {!proto.wilqo.shared.models.HyperlinkTypeEnum}
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.models.HyperlinkTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.models.HyperlinkTypeEnum} value
 * @return {!proto.wilqo.shared.models.DesignerPanelHyperlinkElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelHyperlinkElement.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DesignerPanelElement.repeatedFields_ = [14,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    path: jspb.Message.getFieldWithDefault(msg, 7, ""),
    order: jspb.Message.getFieldWithDefault(msg, 9, 0),
    commandType: jspb.Message.getFieldWithDefault(msg, 19, ""),
    fieldNumber: jspb.Message.getFieldWithDefault(msg, 20, 0),
    externalLink: (f = msg.getExternalLink()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    modalConfirmationText: jspb.Message.getFieldWithDefault(msg, 12, ""),
    requirement: (f = msg.getRequirement()) && proto.wilqo.shared.models.DesignerPanelElementRequirement.toObject(includeInstance, f),
    validatorsList: jspb.Message.toObjectList(msg.getValidatorsList(),
    proto.wilqo.shared.models.DesignerPanelElementValidation.toObject, includeInstance),
    privateInput: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.wilqo.shared.models.DesignerPanelElementOption.toObject, includeInstance),
    mask: (f = msg.getMask()) && proto.wilqo.shared.models.DesignerPanelElementMask.toObject(includeInstance, f),
    validationEvent: (f = msg.getValidationEvent()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 22, ""),
    typeParameters: (f = msg.getTypeParameters()) && proto.wilqo.shared.models.TypeSpecificParameters.toObject(includeInstance, f),
    fieldLibraryIdentifier: (f = msg.getFieldLibraryIdentifier()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    virtualFieldIdentifier: (f = msg.getVirtualFieldIdentifier()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement}
 */
proto.wilqo.shared.models.DesignerPanelElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelElement;
  return proto.wilqo.shared.models.DesignerPanelElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement}
 */
proto.wilqo.shared.models.DesignerPanelElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommandType(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldNumber(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalLink(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setModalConfirmationText(value);
      break;
    case 13:
      var value = new proto.wilqo.shared.models.DesignerPanelElementRequirement;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelElementRequirement.deserializeBinaryFromReader);
      msg.setRequirement(value);
      break;
    case 14:
      var value = new proto.wilqo.shared.models.DesignerPanelElementValidation;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelElementValidation.deserializeBinaryFromReader);
      msg.addValidators(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivateInput(value);
      break;
    case 16:
      var value = new proto.wilqo.shared.models.DesignerPanelElementOption;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelElementOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 18:
      var value = new proto.wilqo.shared.models.DesignerPanelElementMask;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelElementMask.deserializeBinaryFromReader);
      msg.setMask(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValidationEvent(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 23:
      var value = new proto.wilqo.shared.models.TypeSpecificParameters;
      reader.readMessage(value,proto.wilqo.shared.models.TypeSpecificParameters.deserializeBinaryFromReader);
      msg.setTypeParameters(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFieldLibraryIdentifier(value);
      break;
    case 25:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVirtualFieldIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getCommandType();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFieldNumber();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getExternalLink();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRequirement();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.wilqo.shared.models.DesignerPanelElementRequirement.serializeBinaryToWriter
    );
  }
  f = message.getValidatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.wilqo.shared.models.DesignerPanelElementValidation.serializeBinaryToWriter
    );
  }
  f = message.getPrivateInput();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.wilqo.shared.models.DesignerPanelElementOption.serializeBinaryToWriter
    );
  }
  f = message.getMask();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.wilqo.shared.models.DesignerPanelElementMask.serializeBinaryToWriter
    );
  }
  f = message.getValidationEvent();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTypeParameters();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.wilqo.shared.models.TypeSpecificParameters.serializeBinaryToWriter
    );
  }
  f = message.getFieldLibraryIdentifier();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVirtualFieldIdentifier();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string header_text = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string summary_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string path = 7;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 order = 9;
 * @return {number}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string command_type = 19;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getCommandType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setCommandType = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional uint32 field_number = 20;
 * @return {number}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getFieldNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setFieldNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional google.protobuf.StringValue external_link = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getExternalLink = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setExternalLink = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearExternalLink = function() {
  return this.setExternalLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasExternalLink = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool disabled = 11;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string modal_confirmation_text = 12;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional DesignerPanelElementRequirement requirement = 13;
 * @return {?proto.wilqo.shared.models.DesignerPanelElementRequirement}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getRequirement = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelElementRequirement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DesignerPanelElementRequirement, 13));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelElementRequirement|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setRequirement = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearRequirement = function() {
  return this.setRequirement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasRequirement = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated DesignerPanelElementValidation validators = 14;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanelElementValidation>}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getValidatorsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanelElementValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerPanelElementValidation, 14));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanelElementValidation>} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setValidatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanelElementValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanelElementValidation}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.addValidators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.shared.models.DesignerPanelElementValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearValidatorsList = function() {
  return this.setValidatorsList([]);
};


/**
 * optional bool private_input = 15;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getPrivateInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setPrivateInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated DesignerPanelElementOption options = 16;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanelElementOption>}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanelElementOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerPanelElementOption, 16));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanelElementOption>} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanelElementOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.wilqo.shared.models.DesignerPanelElementOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional DesignerPanelElementMask mask = 18;
 * @return {?proto.wilqo.shared.models.DesignerPanelElementMask}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getMask = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelElementMask} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DesignerPanelElementMask, 18));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelElementMask|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setMask = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearMask = function() {
  return this.setMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasMask = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue validation_event = 21;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getValidationEvent = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 21));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setValidationEvent = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearValidationEvent = function() {
  return this.setValidationEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasValidationEvent = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string thumbnail = 22;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional TypeSpecificParameters type_parameters = 23;
 * @return {?proto.wilqo.shared.models.TypeSpecificParameters}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getTypeParameters = function() {
  return /** @type{?proto.wilqo.shared.models.TypeSpecificParameters} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.TypeSpecificParameters, 23));
};


/**
 * @param {?proto.wilqo.shared.models.TypeSpecificParameters|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setTypeParameters = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearTypeParameters = function() {
  return this.setTypeParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasTypeParameters = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.StringValue field_library_identifier = 24;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getFieldLibraryIdentifier = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 24));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setFieldLibraryIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearFieldLibraryIdentifier = function() {
  return this.setFieldLibraryIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasFieldLibraryIdentifier = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.StringValue virtual_field_identifier = 25;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.getVirtualFieldIdentifier = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 25));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
*/
proto.wilqo.shared.models.DesignerPanelElement.prototype.setVirtualFieldIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.clearVirtualFieldIdentifier = function() {
  return this.setVirtualFieldIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElement.prototype.hasVirtualFieldIdentifier = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelElementRequirement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelElementRequirement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.toObject = function(includeInstance, msg) {
  var f, obj = {
    required: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementRequirement}
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelElementRequirement;
  return proto.wilqo.shared.models.DesignerPanelElementRequirement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementRequirement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementRequirement}
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelElementRequirement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementRequirement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementRequirement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementRequirement} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementRequirement.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelElementValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelElementValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementValidation}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelElementValidation;
  return proto.wilqo.shared.models.DesignerPanelElementValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementValidation}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelElementValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementValidation} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementValidation} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string error_message = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementValidation} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementValidation.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelElementOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelElementOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    summaryText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    order: jspb.Message.getFieldWithDefault(msg, 9, 0),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    modalConfirmationText: jspb.Message.getFieldWithDefault(msg, 12, ""),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    path: jspb.Message.getFieldWithDefault(msg, 14, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 15, ""),
    externalLink: jspb.Message.getFieldWithDefault(msg, 16, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelElementOption;
  return proto.wilqo.shared.models.DesignerPanelElementOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeaderText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummaryText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setModalConfirmationText(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalLink(value);
      break;
    case 17:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelElementOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSummaryText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getModalConfirmationText();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getExternalLink();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string header_text = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string summary_text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 order = 9;
 * @return {number}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool disabled = 11;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string modal_confirmation_text = 12;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getModalConfirmationText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setModalConfirmationText = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool visible = 13;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string path = 14;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string thumbnail = 15;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string external_link = 16;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getExternalLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setExternalLink = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional DynamicDataElement value = 17;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 17));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
*/
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementOption} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElementOption.prototype.hasValue = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelElementMask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelElementMask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementMask.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customFormat: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customMask: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allowEmptyFormatting: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementMask}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelElementMask;
  return proto.wilqo.shared.models.DesignerPanelElementMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementMask}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomFormat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMask(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowEmptyFormatting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelElementMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementMask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomFormat();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomMask();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllowEmptyFormatting();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementMask} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string custom_format = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.getCustomFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementMask} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.setCustomFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string custom_mask = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.getCustomMask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementMask} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.setCustomMask = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool allow_empty_formatting = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.getAllowEmptyFormatting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementMask} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementMask.prototype.setAllowEmptyFormatting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton;
  return proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton} returns this
 */
proto.wilqo.shared.models.DesignerPanelSequenceNavigationButton.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DesignerAsyncValidation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerAsyncValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerAsyncValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.shared.models.DesignerAsyncValidationItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidation}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerAsyncValidation;
  return proto.wilqo.shared.models.DesignerAsyncValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidation}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.DesignerAsyncValidationTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.DesignerAsyncValidationItem;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerAsyncValidationItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerAsyncValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerAsyncValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.shared.models.DesignerAsyncValidationItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional DesignerAsyncValidationTypeEnum type = 1;
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidationTypeEnum}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.getType = function() {
  return /** @type {!proto.wilqo.shared.models.DesignerAsyncValidationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidationTypeEnum} value
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidation} returns this
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated DesignerAsyncValidationItem items = 2;
 * @return {!Array<!proto.wilqo.shared.models.DesignerAsyncValidationItem>}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerAsyncValidationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DesignerAsyncValidationItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerAsyncValidationItem>} value
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidation} returns this
*/
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidationItem}
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DesignerAsyncValidationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidation} returns this
 */
proto.wilqo.shared.models.DesignerAsyncValidation.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerAsyncValidationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidationItem}
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerAsyncValidationItem;
  return proto.wilqo.shared.models.DesignerAsyncValidationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidationItem}
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerAsyncValidationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerAsyncValidationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidationItem} returns this
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool required = 2;
 * @return {boolean}
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DesignerAsyncValidationItem} returns this
 */
proto.wilqo.shared.models.DesignerAsyncValidationItem.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DataFieldGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DataFieldGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DataFieldGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DataFieldGroup}
 */
proto.wilqo.shared.models.DataFieldGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DataFieldGroup;
  return proto.wilqo.shared.models.DataFieldGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DataFieldGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DataFieldGroup}
 */
proto.wilqo.shared.models.DataFieldGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DataFieldGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DataFieldGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DataFieldGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldGroup} returns this
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldGroup} returns this
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldGroup} returns this
 */
proto.wilqo.shared.models.DataFieldGroup.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DataFieldTemplateSearchTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dayRank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    defaultRank: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DataFieldTemplateSearchTerm;
  return proto.wilqo.shared.models.DataFieldTemplateSearchTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDayRank(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDefaultRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DataFieldTemplateSearchTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDayRank();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDefaultRank();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} returns this
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 rank = 2;
 * @return {number}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} returns this
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 day_rank = 3;
 * @return {number}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.getDayRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} returns this
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.setDayRank = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 default_rank = 4;
 * @return {number}
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.getDefaultRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm} returns this
 */
proto.wilqo.shared.models.DataFieldTemplateSearchTerm.prototype.setDefaultRank = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.DataFieldTemplate.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DataFieldTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DataFieldTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DataFieldTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 12, ""),
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelElementTemplate: (f = msg.getPanelElementTemplate()) && proto.wilqo.shared.models.DesignerPanelElement.toObject(includeInstance, f),
    searchTermList: jspb.Message.toObjectList(msg.getSearchTermList(),
    proto.wilqo.shared.models.DataFieldTemplateSearchTerm.toObject, includeInstance),
    dataType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    dynamicListType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    complexTypeName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    group: (f = msg.getGroup()) && proto.wilqo.shared.models.DataFieldGroup.toObject(includeInstance, f),
    allowUseInTrigger: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowUseInActivityContentEditor: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    allowUseAsTextTemplateVariable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    messageTypeName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    fieldLibraryPath: jspb.Message.getFieldWithDefault(msg, 11, ""),
    clrTypeName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    fieldLibraryId: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.shared.models.DataFieldTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DataFieldTemplate;
  return proto.wilqo.shared.models.DataFieldTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DataFieldTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DataFieldTemplate}
 */
proto.wilqo.shared.models.DataFieldTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.models.DesignerPanelElement;
      reader.readMessage(value,proto.wilqo.shared.models.DesignerPanelElement.deserializeBinaryFromReader);
      msg.setPanelElementTemplate(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.DataFieldTemplateSearchTerm;
      reader.readMessage(value,proto.wilqo.shared.models.DataFieldTemplateSearchTerm.deserializeBinaryFromReader);
      msg.addSearchTerm(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.shared.models.DynamicListTypeEnum} */ (reader.readEnum());
      msg.setDynamicListType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplexTypeName(value);
      break;
    case 5:
      var value = new proto.wilqo.shared.models.DataFieldGroup;
      reader.readMessage(value,proto.wilqo.shared.models.DataFieldGroup.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowUseInTrigger(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowUseInActivityContentEditor(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowUseAsTextTemplateVariable(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageTypeName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryPath(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setClrTypeName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DataFieldTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DataFieldTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DataFieldTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelElementTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.shared.models.DesignerPanelElement.serializeBinaryToWriter
    );
  }
  f = message.getSearchTermList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.DataFieldTemplateSearchTerm.serializeBinaryToWriter
    );
  }
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDynamicListType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getComplexTypeName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.shared.models.DataFieldGroup.serializeBinaryToWriter
    );
  }
  f = message.getAllowUseInTrigger();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowUseInActivityContentEditor();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAllowUseAsTextTemplateVariable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMessageTypeName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFieldLibraryPath();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getClrTypeName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFieldLibraryId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string id = 12;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DesignerPanelElement panel_element_template = 2;
 * @return {?proto.wilqo.shared.models.DesignerPanelElement}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getPanelElementTemplate = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelElement} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DesignerPanelElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelElement|undefined} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
*/
proto.wilqo.shared.models.DataFieldTemplate.prototype.setPanelElementTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.clearPanelElementTemplate = function() {
  return this.setPanelElementTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.hasPanelElementTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated DataFieldTemplateSearchTerm search_term = 3;
 * @return {!Array<!proto.wilqo.shared.models.DataFieldTemplateSearchTerm>}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getSearchTermList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DataFieldTemplateSearchTerm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.DataFieldTemplateSearchTerm, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DataFieldTemplateSearchTerm>} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
*/
proto.wilqo.shared.models.DataFieldTemplate.prototype.setSearchTermList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DataFieldTemplateSearchTerm}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.addSearchTerm = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.DataFieldTemplateSearchTerm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.clearSearchTermList = function() {
  return this.setSearchTermList([]);
};


/**
 * optional DataTypeEnum data_type = 4;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional DynamicListTypeEnum dynamic_list_type = 14;
 * @return {!proto.wilqo.shared.models.DynamicListTypeEnum}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getDynamicListType = function() {
  return /** @type {!proto.wilqo.shared.models.DynamicListTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DynamicListTypeEnum} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setDynamicListType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string complex_type_name = 9;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getComplexTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setComplexTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional DataFieldGroup group = 5;
 * @return {?proto.wilqo.shared.models.DataFieldGroup}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getGroup = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldGroup} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.DataFieldGroup, 5));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldGroup|undefined} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
*/
proto.wilqo.shared.models.DataFieldTemplate.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool allow_use_in_trigger = 6;
 * @return {boolean}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getAllowUseInTrigger = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setAllowUseInTrigger = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allow_use_in_activity_content_editor = 7;
 * @return {boolean}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getAllowUseInActivityContentEditor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setAllowUseInActivityContentEditor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool allow_use_as_text_template_variable = 8;
 * @return {boolean}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getAllowUseAsTextTemplateVariable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setAllowUseAsTextTemplateVariable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string message_type_name = 10;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getMessageTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setMessageTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string field_library_path = 11;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getFieldLibraryPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setFieldLibraryPath = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string clr_type_name = 13;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getClrTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setClrTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string field_library_id = 15;
 * @return {string}
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.getFieldLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DataFieldTemplate} returns this
 */
proto.wilqo.shared.models.DataFieldTemplate.prototype.setFieldLibraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.TypeSpecificParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.TypeSpecificParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.TypeSpecificParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TypeSpecificParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    table: (f = msg.getTable()) && proto.wilqo.shared.models.TableParameters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.TypeSpecificParameters}
 */
proto.wilqo.shared.models.TypeSpecificParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.TypeSpecificParameters;
  return proto.wilqo.shared.models.TypeSpecificParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.TypeSpecificParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.TypeSpecificParameters}
 */
proto.wilqo.shared.models.TypeSpecificParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.shared.models.TableParameters;
      reader.readMessage(value,proto.wilqo.shared.models.TableParameters.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.TypeSpecificParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.TypeSpecificParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.TypeSpecificParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TypeSpecificParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.shared.models.TableParameters.serializeBinaryToWriter
    );
  }
};


/**
 * optional TableParameters table = 1;
 * @return {?proto.wilqo.shared.models.TableParameters}
 */
proto.wilqo.shared.models.TypeSpecificParameters.prototype.getTable = function() {
  return /** @type{?proto.wilqo.shared.models.TableParameters} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.models.TableParameters, 1));
};


/**
 * @param {?proto.wilqo.shared.models.TableParameters|undefined} value
 * @return {!proto.wilqo.shared.models.TypeSpecificParameters} returns this
*/
proto.wilqo.shared.models.TypeSpecificParameters.prototype.setTable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.models.TypeSpecificParameters} returns this
 */
proto.wilqo.shared.models.TypeSpecificParameters.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.models.TypeSpecificParameters.prototype.hasTable = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.models.TableParameters.repeatedFields_ = [3,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.TableParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.TableParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.TableParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    tableName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tableTitleItemcount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tableIconsList: jspb.Message.toObjectList(msg.getTableIconsList(),
    proto.wilqo.shared.models.TableIcon.toObject, includeInstance),
    checkBoxColumn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    freezeFirstColumn: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    tableColumnsList: jspb.Message.toObjectList(msg.getTableColumnsList(),
    proto.wilqo.shared.models.TableColumn.toObject, includeInstance),
    tableButtonsList: jspb.Message.toObjectList(msg.getTableButtonsList(),
    proto.wilqo.shared.models.TableButton.toObject, includeInstance),
    scoreCalculation: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.TableParameters}
 */
proto.wilqo.shared.models.TableParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.TableParameters;
  return proto.wilqo.shared.models.TableParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.TableParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.TableParameters}
 */
proto.wilqo.shared.models.TableParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTableTitleItemcount(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.models.TableIcon;
      reader.readMessage(value,proto.wilqo.shared.models.TableIcon.deserializeBinaryFromReader);
      msg.addTableIcons(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckBoxColumn(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFreezeFirstColumn(value);
      break;
    case 6:
      var value = new proto.wilqo.shared.models.TableColumn;
      reader.readMessage(value,proto.wilqo.shared.models.TableColumn.deserializeBinaryFromReader);
      msg.addTableColumns(value);
      break;
    case 7:
      var value = new proto.wilqo.shared.models.TableButton;
      reader.readMessage(value,proto.wilqo.shared.models.TableButton.deserializeBinaryFromReader);
      msg.addTableButtons(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setScoreCalculation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.TableParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.TableParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.TableParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTableName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTableTitleItemcount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTableIconsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.models.TableIcon.serializeBinaryToWriter
    );
  }
  f = message.getCheckBoxColumn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFreezeFirstColumn();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTableColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.shared.models.TableColumn.serializeBinaryToWriter
    );
  }
  f = message.getTableButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.shared.models.TableButton.serializeBinaryToWriter
    );
  }
  f = message.getScoreCalculation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string table_name = 1;
 * @return {string}
 */
proto.wilqo.shared.models.TableParameters.prototype.getTableName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.setTableName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string table_title_itemCount = 2;
 * @return {string}
 */
proto.wilqo.shared.models.TableParameters.prototype.getTableTitleItemcount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.setTableTitleItemcount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TableIcon table_icons = 3;
 * @return {!Array<!proto.wilqo.shared.models.TableIcon>}
 */
proto.wilqo.shared.models.TableParameters.prototype.getTableIconsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.TableIcon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.TableIcon, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.TableIcon>} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
*/
proto.wilqo.shared.models.TableParameters.prototype.setTableIconsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.TableIcon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.TableIcon}
 */
proto.wilqo.shared.models.TableParameters.prototype.addTableIcons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.TableIcon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.clearTableIconsList = function() {
  return this.setTableIconsList([]);
};


/**
 * optional bool check_box_column = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.TableParameters.prototype.getCheckBoxColumn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.setCheckBoxColumn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool freeze_first_column = 5;
 * @return {boolean}
 */
proto.wilqo.shared.models.TableParameters.prototype.getFreezeFirstColumn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.setFreezeFirstColumn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated TableColumn table_columns = 6;
 * @return {!Array<!proto.wilqo.shared.models.TableColumn>}
 */
proto.wilqo.shared.models.TableParameters.prototype.getTableColumnsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.TableColumn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.TableColumn, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.TableColumn>} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
*/
proto.wilqo.shared.models.TableParameters.prototype.setTableColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.models.TableColumn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.TableColumn}
 */
proto.wilqo.shared.models.TableParameters.prototype.addTableColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.models.TableColumn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.clearTableColumnsList = function() {
  return this.setTableColumnsList([]);
};


/**
 * repeated TableButton table_buttons = 7;
 * @return {!Array<!proto.wilqo.shared.models.TableButton>}
 */
proto.wilqo.shared.models.TableParameters.prototype.getTableButtonsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.TableButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.models.TableButton, 7));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.TableButton>} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
*/
proto.wilqo.shared.models.TableParameters.prototype.setTableButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.shared.models.TableButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.TableButton}
 */
proto.wilqo.shared.models.TableParameters.prototype.addTableButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.shared.models.TableButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.clearTableButtonsList = function() {
  return this.setTableButtonsList([]);
};


/**
 * optional string score_calculation = 8;
 * @return {string}
 */
proto.wilqo.shared.models.TableParameters.prototype.getScoreCalculation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableParameters} returns this
 */
proto.wilqo.shared.models.TableParameters.prototype.setScoreCalculation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.TableIcon.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.TableIcon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.TableIcon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableIcon.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.TableIcon}
 */
proto.wilqo.shared.models.TableIcon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.TableIcon;
  return proto.wilqo.shared.models.TableIcon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.TableIcon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.TableIcon}
 */
proto.wilqo.shared.models.TableIcon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.TableIcon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.TableIcon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.TableIcon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableIcon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.TableIcon.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableIcon} returns this
 */
proto.wilqo.shared.models.TableIcon.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.TableIcon.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableIcon} returns this
 */
proto.wilqo.shared.models.TableIcon.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.wilqo.shared.models.TableIcon.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableIcon} returns this
 */
proto.wilqo.shared.models.TableIcon.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.TableColumn.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.TableColumn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.TableColumn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableColumn.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataField: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.TableColumn}
 */
proto.wilqo.shared.models.TableColumn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.TableColumn;
  return proto.wilqo.shared.models.TableColumn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.TableColumn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.TableColumn}
 */
proto.wilqo.shared.models.TableColumn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColumnId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.TableColumn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.TableColumn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.TableColumn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableColumn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 column_id = 1;
 * @return {number}
 */
proto.wilqo.shared.models.TableColumn.prototype.getColumnId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.TableColumn} returns this
 */
proto.wilqo.shared.models.TableColumn.prototype.setColumnId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.TableColumn.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableColumn} returns this
 */
proto.wilqo.shared.models.TableColumn.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string data_field = 3;
 * @return {string}
 */
proto.wilqo.shared.models.TableColumn.prototype.getDataField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableColumn} returns this
 */
proto.wilqo.shared.models.TableColumn.prototype.setDataField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.TableButton.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.TableButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.TableButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.TableButton}
 */
proto.wilqo.shared.models.TableButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.TableButton;
  return proto.wilqo.shared.models.TableButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.TableButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.TableButton}
 */
proto.wilqo.shared.models.TableButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.TableButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.TableButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.TableButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.TableButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.TableButton.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableButton} returns this
 */
proto.wilqo.shared.models.TableButton.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.shared.models.TableButton.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableButton} returns this
 */
proto.wilqo.shared.models.TableButton.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.shared.models.TableButton.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.TableButton} returns this
 */
proto.wilqo.shared.models.TableButton.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.DesignerPanelElementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.DesignerPanelElementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementResponse}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.DesignerPanelElementResponse;
  return proto.wilqo.shared.models.DesignerPanelElementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.DesignerPanelElementResponse}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.DesignerPanelElementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.DesignerPanelElementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementResponse} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementResponse} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.DesignerPanelElementResponse} returns this
 */
proto.wilqo.shared.models.DesignerPanelElementResponse.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.ValidationEventEnum = {
  VALIDATION_EVENT_ENUM_ON_CHANGE: 0,
  VALIDATION_EVENT_ENUM_ON_BLUR: 1
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.DesignerAsyncValidationTypeEnum = {
  DESIGNER_ASYNC_VALIDATION_TYPE_ENUM_UNKNOWN: 0,
  DESIGNER_ASYNC_VALIDATION_TYPE_ENUM_LOAN_DUPLICATE_CHECK: 1,
  DESIGNER_ASYNC_VALIDATION_TYPE_ENUM_LENDER_LICENSE_CHECK: 2
};

goog.object.extend(exports, proto.wilqo.shared.models);
