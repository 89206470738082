import { useQuery } from '@tanstack/react-query';

import { GetConditionTemplateTriggersQueryRequest, GetConditionTemplateTriggersQueryResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

export const MESSAGE_NAME = 'wilqo.api.mortgage_config.GetConditionTemplateTriggersQueryRequest';

const useLenderConditionTrigger = (conditionId: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery(
    [MESSAGE_NAME, bpdId, conditionId],
    async () => {
      const request = new GetConditionTemplateTriggersQueryRequest();
      request.setBusinessProcessDomainId(bpdId);
      request.setConditionId(conditionId);
      const response = await sendMessage<GetConditionTemplateTriggersQueryResponse.AsObject>(
        {
          msg: request,
          msgName: MESSAGE_NAME,
        },
        GetConditionTemplateTriggersQueryResponse,
      );
      return response.triggerRules;
    },
    {
      cacheTime: 0,
    },
  );
};

export { useLenderConditionTrigger };
