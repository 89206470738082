interface IconProps {
  className?: string;
}

const Number7 = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z" fillRule="evenodd" />
    <path d="M14.9355 7.46875V8.37695L11.6367 16H9.85547L13.1543 8.78711H8.90625V7.46875H14.9355Z" />
  </svg>
);

export default Number7;
