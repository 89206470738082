import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { SaveFieldItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DealWriteCommandResponse, SaveSubmitFormCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';

import { useBackend } from '../useBackend';

type Dto = {
  bpdId: string;
  dealId: string;
  dealVersion: number;
  scopeToken: string;
  itemsList: SaveFieldItem[];
  pageId: string;
  widgetId: string;
};

export const SAVE_SUBMIT_WIDGET_MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.SaveSubmitFormCommandRequest';

export const useSaveSubmitForm = () => {
  const { commandResponse } = useBackend();

  return useMutation({
    mutationFn: async (parameters: Dto) => {
      const request = new SaveSubmitFormCommandRequest();
      request.setBusinessProcessDomainId(parameters.bpdId);
      request.setDealId(parameters.dealId);
      request.setDealVersion(parameters.dealVersion);
      request.setItemsList(parameters.itemsList);
      request.setScopeToken(parameters.scopeToken);
      request.setPageId(parameters.pageId);
      request.setWidgetId(parameters.widgetId);

      const message = {
        msg: request,
        msgName: SAVE_SUBMIT_WIDGET_MESSAGE_NAME,
      };

      const responseMsg = await commandResponse(message);
      const response = new DealWriteCommandResponse();
      DealWriteCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
      /// NOTE: Not using middleware here because api can return error and good data at the same time
      return response.toObject();
    },
    mutationKey: [SAVE_SUBMIT_WIDGET_MESSAGE_NAME] });
};
