import { useQuery } from '@tanstack/react-query';

import { GetUserByIdQueryRequest, GetUserByIdQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.users.GetUserByIdQueryRequest';

export const useUser = (id: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  return useQuery([MESSAGE_NAME, id], async () => {
    const request = new GetUserByIdQueryRequest();
    request.setUserId(id);
    const response = await sendMessage<GetUserByIdQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetUserByIdQueryResponse,
    );
    return response.user;
  }, { enabled: Boolean(id) && Boolean(bpdId) });
};
