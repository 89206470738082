import clsx from 'clsx';
import { useMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Icon } from '../Icon';
import type { TabData } from '../Tab';
import { Tooltip } from '../Tooltip';

export interface ITabsProps {
  className?: string;
  tabsData: Array<TabData>;
  justify?: 'flex-start' | 'space-between';
  sticky?: boolean;
}

const TabItem = (props: TabData) => {
  const { active: overrideMatch, icon, label, linkTo, matchPattern, onClick, tooltip } = props;

  const match = useMatch(matchPattern || linkTo || '');

  const active = Boolean(overrideMatch) || Boolean(match && linkTo);

  const labelCname = clsx(
    'font-semibold text-on-surface-inactive',
    {
      'pl-2': Boolean(icon),
    },
  );

  const tabItemCname = clsx(
    'items-center cursor-pointer flex h-full justify-center',
    'relative max-h-12 py-4 px-2',
    'hover:bg-on-surface-states-hover',
    'focus:bg-on-surface-states-focus',
    'disabled:bg-on-surface-states-disabled',
    'h-[fit-content] hover:bg-transparent',
  );

  const renderTab = () => (
    <div
      className={tabItemCname}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      {icon && tooltip && (
        <Tooltip className="tooltip" message={tooltip}>
          <Icon
            className="focus:ring-1"
            icon={icon}
            onClick={() => {}}
            selected={active}
            variant="interactive"
          />
        </Tooltip>
      )}
      {icon && !tooltip && (
        <Icon
          icon={icon}
          selected={Boolean(match)}
          variant="interactive"
        />
      )}
      {label && <span className={labelCname}>{label}</span>}
      {active && (
        <div className="absolute w-[2px] h-full right-0 overflow-hidden">
          <div className="absolute bg-primary-on-surface w-[4px] h-full rounded-l-lg right-[-2px]" />
        </div>
      )}
    </div>
  );

  if (linkTo) {
    return (
      <NavLink
        end
        replace
        to={linkTo}
      >
        {renderTab()}
      </NavLink>
    );
  }

  return renderTab();
};

export const SidesheetRail = (props: ITabsProps) => {
  const { className = '', justify = 'flex-start', sticky = false, tabsData } = props;

  /// TODO: Not this is hiding the Documents POC Sidesheet for 9/5/2024 code freeze
  const newTabsData = tabsData.filter((x) => x.tooltip !== 'Documents POC');

  return (
    <div className={clsx(
      'flex items-center bg-surface',
      'border-transparent flex-col w-[fit-content]',
      {
        'justify-between': justify === 'space-between',
        'justify-start': justify === 'flex-start',
        'sticky top-16': sticky,
      },
      className,
    )}
    >
      {newTabsData.map((tab) => (
        <TabItem key={`tabsData-${tab.tooltip}-${tab.icon}`} {...tab} />
      ))}
    </div>
  );
};
