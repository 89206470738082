import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateLenderConditionTemplateTriggersCommandRequest, UpdateLenderConditionTemplateTriggersCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import type { RuleAggregatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';
import { convertTriggerSummaryToInstance } from './helpers';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest';

interface UpdateObject {
  conditionId: string;
  trigger: RuleAggregatorSummary.AsObject;
}

const useUpdateLenderConditionTriggers = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const updateCommand = async (updateObject: UpdateObject) => {
    const { conditionId, trigger } = updateObject;
    const request = new UpdateLenderConditionTemplateTriggersCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setConditionId(conditionId);
    const triggerRules = convertTriggerSummaryToInstance(trigger);
    request.setTriggerRules(triggerRules);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpdateLenderConditionTemplateTriggersCommandResponse();
    UpdateLenderConditionTemplateTriggersCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, { id: conditionId });
  };

  return useMutation(updateCommand);
};

export { useUpdateLenderConditionTriggers };
