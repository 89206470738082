import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetLoanFraudPageQueryRequest, GetLoanFraudPageQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const FRAUD_PAGE_MESSAGE = 'wilqo.api.mortgage.GetLoanFraudPageQueryRequest';

const useLoanFraudPage = (dealId: string) => {
  const { commandResponse } = useBackend();

  return useQuery([FRAUD_PAGE_MESSAGE, dealId], async () => {
    const request = new GetLoanFraudPageQueryRequest();
    request.setDealId(dealId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: FRAUD_PAGE_MESSAGE,
    });
    const response = new GetLoanFraudPageQueryResponse();
    GetLoanFraudPageQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(FRAUD_PAGE_MESSAGE, responseObj.error, responseObj);
  }, {
    enabled: Boolean(dealId),
  });
};
export { useLoanFraudPage };
