import { useParams } from 'react-router';

import type { RuleAggregatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { useBusinessProcessTemplateTrigger } from '@/API/Queries/businessProcess/useBusinessProcessTemplateTrigger';
import { useUpdateBusinessProcessTemplateTrigger } from '@/API/Queries/businessProcess/useUpdateBusinessProcessTemplateTrigger';
import { Trigger } from '@/Components/Features/trigger/Trigger';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export const BusinessProcessTemplateTriggers = () => {
  const { id = '' } = useParams();
  const { showSnackBar } = useShared();

  const { data: trigger, isLoading } = useBusinessProcessTemplateTrigger(id);
  const { mutate: updateTrigger } = useUpdateBusinessProcessTemplateTrigger();

  const handleTriggerUpdate = (trigger: RuleAggregatorSummary.AsObject) => {
    updateTrigger(
      { id, trigger },
      {
        onError: (error: any) => showSnackBar({ message: error.message || 'Something went wrong', open: true }),
      },
    );
  };

  return (
    <div className="h-full">
      <Trigger
        isLoading={isLoading}
        onUpdateTrigger={handleTriggerUpdate}
        title="Business Process Template Trigger"
        trigger={trigger}
      />
    </div>
  );
};
