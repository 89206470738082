import { useMutation } from '@tanstack/react-query';

import { RunComplianceCommandRequest, RunComplianceCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Commands_pb';
import type { ComplianceCheckType } from '@/API/Models/Wilqo.Shared.Mortgage.Integration/Compliance/Models_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessageWrapper } from '../../useWilqoMessageWrapper';

interface RunComplianceCheckProps {
  dealId: string;
  complianceCheckTypesList: ComplianceCheckType[];
}

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.RunComplianceCommandRequest';

const useRunComplianceChecks = () => {
  const institutionId = useInstitutionId();
  const sendMessage = useWilqoMessageWrapper();

  return useMutation(async (props: RunComplianceCheckProps) => {
    const request = new RunComplianceCommandRequest();
    request.setInstitutionId(institutionId);
    request.setDealId(props.dealId);
    request.setComplianceCheckTypesList(props.complianceCheckTypesList);
    return sendMessage<RunComplianceCommandResponse.AsObject>({ msg: request, msgName: MESSAGE_NAME }, RunComplianceCommandResponse);
  });
};

export { useRunComplianceChecks };
