// source: Wilqo_API_Questionnaire_Entities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Models_ActivityAdminModels_pb = require('./Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_CustomWrappers_pb = require('./Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('./Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_API_Brand_Models_pb = require('./Wilqo_API_Brand_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Brand_Models_pb);
goog.exportSymbol('proto.wilqo.api.questionnaire.AsyncValidation', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.AsyncValidationItem', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.AsyncValidationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.DynamicDataItem', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.DynamicDataItemField', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.IntegrationCallStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PanelSequenceNavigationButton', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PanelSequenceRoute', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PanelSequenceRoute.RouteTypes', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PricingSearchProduct', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.Questionnaire', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireHeader', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireInstance', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireIntegration', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireLanguageEnum', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnairePanelResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnairePanelSequence', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireResponses', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireTenancy', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireValidationMessage', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.QuestionnaireValidationMessageTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.RoutingWeight', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.RoutingWeight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.RoutingWeight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.RoutingWeight.displayName = 'proto.wilqo.api.questionnaire.RoutingWeight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.PanelSequenceRoute.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PanelSequenceRoute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PanelSequenceRoute.displayName = 'proto.wilqo.api.questionnaire.PanelSequenceRoute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnaireTenancy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnaireTenancy.displayName = 'proto.wilqo.api.questionnaire.QuestionnaireTenancy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.Questionnaire = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.Questionnaire.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.Questionnaire, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.Questionnaire.displayName = 'proto.wilqo.api.questionnaire.Questionnaire';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.QuestionnaireInstance.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnaireInstance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnaireInstance.displayName = 'proto.wilqo.api.questionnaire.QuestionnaireInstance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.QuestionnaireIntegration.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnaireIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnaireIntegration.displayName = 'proto.wilqo.api.questionnaire.QuestionnaireIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.QuestionnairePanelSequence.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnairePanelSequence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnairePanelSequence.displayName = 'proto.wilqo.api.questionnaire.QuestionnairePanelSequence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnaireHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnaireHeader.displayName = 'proto.wilqo.api.questionnaire.QuestionnaireHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.AsyncValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.AsyncValidation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.AsyncValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.AsyncValidation.displayName = 'proto.wilqo.api.questionnaire.AsyncValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.AsyncValidationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.AsyncValidationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.AsyncValidationItem.displayName = 'proto.wilqo.api.questionnaire.AsyncValidationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PanelSequenceNavigationButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.displayName = 'proto.wilqo.api.questionnaire.PanelSequenceNavigationButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.QuestionnairePanelResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnairePanelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnairePanelResponse.displayName = 'proto.wilqo.api.questionnaire.QuestionnairePanelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.QuestionnaireResponses.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnaireResponses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnaireResponses.displayName = 'proto.wilqo.api.questionnaire.QuestionnaireResponses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PricingSearchProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PricingSearchProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PricingSearchProduct.displayName = 'proto.wilqo.api.questionnaire.PricingSearchProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.displayName = 'proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.QuestionnaireValidationMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.displayName = 'proto.wilqo.api.questionnaire.QuestionnaireValidationMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.DynamicDataItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.DynamicDataItem.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.DynamicDataItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.DynamicDataItem.displayName = 'proto.wilqo.api.questionnaire.DynamicDataItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.DynamicDataItemField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.DynamicDataItemField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.DynamicDataItemField.displayName = 'proto.wilqo.api.questionnaire.DynamicDataItemField';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.RoutingWeight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.RoutingWeight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.RoutingWeight.toObject = function(includeInstance, msg) {
  var f, obj = {
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    panelSequenceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    panelSequenceVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
    querystringParamsMap: (f = msg.getQuerystringParamsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight}
 */
proto.wilqo.api.questionnaire.RoutingWeight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.RoutingWeight;
  return proto.wilqo.api.questionnaire.RoutingWeight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.RoutingWeight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight}
 */
proto.wilqo.api.questionnaire.RoutingWeight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeight(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPanelSequenceVersion(value);
      break;
    case 7:
      var value = msg.getQuerystringParamsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.RoutingWeight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.RoutingWeight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.RoutingWeight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPanelSequenceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPanelSequenceVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getQuerystringParamsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional double weight = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight} returns this
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string panel_sequence_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.getPanelSequenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight} returns this
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.setPanelSequenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 panel_sequence_version = 5;
 * @return {number}
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.getPanelSequenceVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight} returns this
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.setPanelSequenceVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<string, string> querystring_params = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.getQuerystringParamsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight} returns this
 */
proto.wilqo.api.questionnaire.RoutingWeight.prototype.clearQuerystringParamsMap = function() {
  this.getQuerystringParamsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PanelSequenceRoute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceRoute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.toObject = function(includeInstance, msg) {
  var f, obj = {
    routeType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    relativeUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    weightsList: jspb.Message.toObjectList(msg.getWeightsList(),
    proto.wilqo.api.questionnaire.RoutingWeight.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PanelSequenceRoute;
  return proto.wilqo.api.questionnaire.PanelSequenceRoute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceRoute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.questionnaire.PanelSequenceRoute.RouteTypes} */ (reader.readEnum());
      msg.setRouteType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelativeUrl(value);
      break;
    case 5:
      var value = new proto.wilqo.api.questionnaire.RoutingWeight;
      reader.readMessage(value,proto.wilqo.api.questionnaire.RoutingWeight.deserializeBinaryFromReader);
      msg.addWeights(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PanelSequenceRoute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceRoute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRouteType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRelativeUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWeightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.questionnaire.RoutingWeight.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.RouteTypes = {
  DEFAULT: 0,
  MOBILE: 1
};

/**
 * optional RouteTypes route_type = 1;
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute.RouteTypes}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.getRouteType = function() {
  return /** @type {!proto.wilqo.api.questionnaire.PanelSequenceRoute.RouteTypes} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceRoute.RouteTypes} value
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute} returns this
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.setRouteType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string relative_url = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.getRelativeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute} returns this
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.setRelativeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated RoutingWeight weights = 5;
 * @return {!Array<!proto.wilqo.api.questionnaire.RoutingWeight>}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.getWeightsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.RoutingWeight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.RoutingWeight, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.RoutingWeight>} value
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute} returns this
*/
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.setWeightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.RoutingWeight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.RoutingWeight}
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.addWeights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.questionnaire.RoutingWeight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute} returns this
 */
proto.wilqo.api.questionnaire.PanelSequenceRoute.prototype.clearWeightsList = function() {
  return this.setWeightsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnaireTenancy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireTenancy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireTenancy}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnaireTenancy;
  return proto.wilqo.api.questionnaire.QuestionnaireTenancy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireTenancy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireTenancy}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnaireTenancy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireTenancy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireTenancy} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireTenancy} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireTenancy} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireTenancy.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.Questionnaire.repeatedFields_ = [3,8,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.Questionnaire.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.Questionnaire} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.Questionnaire.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseurlsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    questionnaireInstancesList: jspb.Message.toObjectList(msg.getQuestionnaireInstancesList(),
    proto.wilqo.api.questionnaire.QuestionnaireInstance.toObject, includeInstance),
    routesList: jspb.Message.toObjectList(msg.getRoutesList(),
    proto.wilqo.api.questionnaire.PanelSequenceRoute.toObject, includeInstance),
    tenancy: (f = msg.getTenancy()) && proto.wilqo.api.questionnaire.QuestionnaireTenancy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.Questionnaire}
 */
proto.wilqo.api.questionnaire.Questionnaire.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.Questionnaire;
  return proto.wilqo.api.questionnaire.Questionnaire.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.Questionnaire} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.Questionnaire}
 */
proto.wilqo.api.questionnaire.Questionnaire.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBaseurls(value);
      break;
    case 8:
      var value = new proto.wilqo.api.questionnaire.QuestionnaireInstance;
      reader.readMessage(value,proto.wilqo.api.questionnaire.QuestionnaireInstance.deserializeBinaryFromReader);
      msg.addQuestionnaireInstances(value);
      break;
    case 7:
      var value = new proto.wilqo.api.questionnaire.PanelSequenceRoute;
      reader.readMessage(value,proto.wilqo.api.questionnaire.PanelSequenceRoute.deserializeBinaryFromReader);
      msg.addRoutes(value);
      break;
    case 9:
      var value = new proto.wilqo.api.questionnaire.QuestionnaireTenancy;
      reader.readMessage(value,proto.wilqo.api.questionnaire.QuestionnaireTenancy.deserializeBinaryFromReader);
      msg.setTenancy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.Questionnaire.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.Questionnaire} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.Questionnaire.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseurlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getQuestionnaireInstancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.wilqo.api.questionnaire.QuestionnaireInstance.serializeBinaryToWriter
    );
  }
  f = message.getRoutesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.questionnaire.PanelSequenceRoute.serializeBinaryToWriter
    );
  }
  f = message.getTenancy();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.api.questionnaire.QuestionnaireTenancy.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string baseUrls = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.getBaseurlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.setBaseurlsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.addBaseurls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.clearBaseurlsList = function() {
  return this.setBaseurlsList([]);
};


/**
 * repeated QuestionnaireInstance questionnaire_instances = 8;
 * @return {!Array<!proto.wilqo.api.questionnaire.QuestionnaireInstance>}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.getQuestionnaireInstancesList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.QuestionnaireInstance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.QuestionnaireInstance, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.QuestionnaireInstance>} value
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
*/
proto.wilqo.api.questionnaire.Questionnaire.prototype.setQuestionnaireInstancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireInstance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.addQuestionnaireInstances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.api.questionnaire.QuestionnaireInstance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.clearQuestionnaireInstancesList = function() {
  return this.setQuestionnaireInstancesList([]);
};


/**
 * repeated PanelSequenceRoute routes = 7;
 * @return {!Array<!proto.wilqo.api.questionnaire.PanelSequenceRoute>}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.getRoutesList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.PanelSequenceRoute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.PanelSequenceRoute, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.PanelSequenceRoute>} value
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
*/
proto.wilqo.api.questionnaire.Questionnaire.prototype.setRoutesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceRoute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceRoute}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.addRoutes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.questionnaire.PanelSequenceRoute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.clearRoutesList = function() {
  return this.setRoutesList([]);
};


/**
 * optional QuestionnaireTenancy tenancy = 9;
 * @return {?proto.wilqo.api.questionnaire.QuestionnaireTenancy}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.getTenancy = function() {
  return /** @type{?proto.wilqo.api.questionnaire.QuestionnaireTenancy} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.questionnaire.QuestionnaireTenancy, 9));
};


/**
 * @param {?proto.wilqo.api.questionnaire.QuestionnaireTenancy|undefined} value
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
*/
proto.wilqo.api.questionnaire.Questionnaire.prototype.setTenancy = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.Questionnaire} returns this
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.clearTenancy = function() {
  return this.setTenancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.Questionnaire.prototype.hasTenancy = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnaireInstance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireInstance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelSequenceDesign: (f = msg.getPanelSequenceDesign()) && Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.toObject(includeInstance, f),
    integrationsList: jspb.Message.toObjectList(msg.getIntegrationsList(),
    proto.wilqo.api.questionnaire.QuestionnaireIntegration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnaireInstance;
  return proto.wilqo.api.questionnaire.QuestionnaireInstance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireInstance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.deserializeBinaryFromReader);
      msg.setPanelSequenceDesign(value);
      break;
    case 2:
      var value = new proto.wilqo.api.questionnaire.QuestionnaireIntegration;
      reader.readMessage(value,proto.wilqo.api.questionnaire.QuestionnaireIntegration.deserializeBinaryFromReader);
      msg.addIntegrations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnaireInstance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireInstance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelSequenceDesign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getIntegrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.questionnaire.QuestionnaireIntegration.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.DesignerPanelSequence panel_sequence_design = 1;
 * @return {?proto.wilqo.shared.models.DesignerPanelSequence}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.getPanelSequenceDesign = function() {
  return /** @type{?proto.wilqo.shared.models.DesignerPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanelSequence, 1));
};


/**
 * @param {?proto.wilqo.shared.models.DesignerPanelSequence|undefined} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance} returns this
*/
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.setPanelSequenceDesign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.clearPanelSequenceDesign = function() {
  return this.setPanelSequenceDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.hasPanelSequenceDesign = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated QuestionnaireIntegration integrations = 2;
 * @return {!Array<!proto.wilqo.api.questionnaire.QuestionnaireIntegration>}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.getIntegrationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.QuestionnaireIntegration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.QuestionnaireIntegration, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.QuestionnaireIntegration>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance} returns this
*/
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.setIntegrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireIntegration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration}
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.addIntegrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.questionnaire.QuestionnaireIntegration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireInstance} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireInstance.prototype.clearIntegrationsList = function() {
  return this.setIntegrationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnaireIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, 0),
    integrationIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    endMessageTitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endMessageSubtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endExternalLink: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnaireIntegration;
  return proto.wilqo.api.questionnaire.QuestionnaireIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.questionnaire.QuestionnaireLanguageEnum} */ (reader.readEnum());
      msg.setLanguage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addIntegrationIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndMessageTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndMessageSubtitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndExternalLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnaireIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIntegrationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getEndMessageTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndMessageSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndExternalLink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional QuestionnaireLanguageEnum language = 1;
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireLanguageEnum}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.getLanguage = function() {
  return /** @type {!proto.wilqo.api.questionnaire.QuestionnaireLanguageEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireLanguageEnum} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string integration_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.getIntegrationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.setIntegrationIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.addIntegrationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.clearIntegrationIdsList = function() {
  return this.setIntegrationIdsList([]);
};


/**
 * optional string end_message_title = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.getEndMessageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.setEndMessageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_message_subtitle = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.getEndMessageSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.setEndMessageSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end_external_link = 5;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.getEndExternalLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireIntegration} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireIntegration.prototype.setEndExternalLink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.repeatedFields_ = [4,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnairePanelSequence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject, includeInstance),
    modifiedHeader: (f = msg.getModifiedHeader()) && proto.wilqo.api.questionnaire.QuestionnaireHeader.toObject(includeInstance, f),
    modifiedFooter: (f = msg.getModifiedFooter()) && Wilqo_API_Brand_Models_pb.Footer.toObject(includeInstance, f),
    navigationButtonsList: jspb.Message.toObjectList(msg.getNavigationButtonsList(),
    proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.toObject, includeInstance),
    asyncValidationsList: jspb.Message.toObjectList(msg.getAsyncValidationsList(),
    proto.wilqo.api.questionnaire.AsyncValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnairePanelSequence;
  return proto.wilqo.api.questionnaire.QuestionnairePanelSequence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    case 5:
      var value = new proto.wilqo.api.questionnaire.QuestionnaireHeader;
      reader.readMessage(value,proto.wilqo.api.questionnaire.QuestionnaireHeader.deserializeBinaryFromReader);
      msg.setModifiedHeader(value);
      break;
    case 8:
      var value = new Wilqo_API_Brand_Models_pb.Footer;
      reader.readMessage(value,Wilqo_API_Brand_Models_pb.Footer.deserializeBinaryFromReader);
      msg.setModifiedFooter(value);
      break;
    case 6:
      var value = new proto.wilqo.api.questionnaire.PanelSequenceNavigationButton;
      reader.readMessage(value,proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.deserializeBinaryFromReader);
      msg.addNavigationButtons(value);
      break;
    case 7:
      var value = new proto.wilqo.api.questionnaire.AsyncValidation;
      reader.readMessage(value,proto.wilqo.api.questionnaire.AsyncValidation.deserializeBinaryFromReader);
      msg.addAsyncValidations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnairePanelSequence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
  f = message.getModifiedHeader();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.questionnaire.QuestionnaireHeader.serializeBinaryToWriter
    );
  }
  f = message.getModifiedFooter();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_API_Brand_Models_pb.Footer.serializeBinaryToWriter
    );
  }
  f = message.getNavigationButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.serializeBinaryToWriter
    );
  }
  f = message.getAsyncValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.questionnaire.AsyncValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 entity_version = 3;
 * @return {number}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated wilqo.shared.models.Panel panels = 4;
 * @return {!Array<!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Panel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Panel>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
*/
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.Panel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.Panel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};


/**
 * optional QuestionnaireHeader modified_header = 5;
 * @return {?proto.wilqo.api.questionnaire.QuestionnaireHeader}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getModifiedHeader = function() {
  return /** @type{?proto.wilqo.api.questionnaire.QuestionnaireHeader} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.questionnaire.QuestionnaireHeader, 5));
};


/**
 * @param {?proto.wilqo.api.questionnaire.QuestionnaireHeader|undefined} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
*/
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setModifiedHeader = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.clearModifiedHeader = function() {
  return this.setModifiedHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.hasModifiedHeader = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.api.brand.Footer modified_footer = 8;
 * @return {?proto.wilqo.api.brand.Footer}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getModifiedFooter = function() {
  return /** @type{?proto.wilqo.api.brand.Footer} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Brand_Models_pb.Footer, 8));
};


/**
 * @param {?proto.wilqo.api.brand.Footer|undefined} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
*/
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setModifiedFooter = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.clearModifiedFooter = function() {
  return this.setModifiedFooter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.hasModifiedFooter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated PanelSequenceNavigationButton navigation_buttons = 6;
 * @return {!Array<!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton>}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getNavigationButtonsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.PanelSequenceNavigationButton, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
*/
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setNavigationButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.addNavigationButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.questionnaire.PanelSequenceNavigationButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.clearNavigationButtonsList = function() {
  return this.setNavigationButtonsList([]);
};


/**
 * repeated AsyncValidation async_validations = 7;
 * @return {!Array<!proto.wilqo.api.questionnaire.AsyncValidation>}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.getAsyncValidationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.AsyncValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.AsyncValidation, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.AsyncValidation>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
*/
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.setAsyncValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.AsyncValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.AsyncValidation}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.addAsyncValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.questionnaire.AsyncValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelSequence} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelSequence.prototype.clearAsyncValidationsList = function() {
  return this.setAsyncValidationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnaireHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkTo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    external: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireHeader}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnaireHeader;
  return proto.wilqo.api.questionnaire.QuestionnaireHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireHeader}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkTo(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnaireHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternal();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string image_url = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireHeader} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link_to = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.getLinkTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireHeader} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.setLinkTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool external = 3;
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.getExternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireHeader} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireHeader.prototype.setExternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.AsyncValidation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.AsyncValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.AsyncValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.AsyncValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.questionnaire.AsyncValidationItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.AsyncValidation}
 */
proto.wilqo.api.questionnaire.AsyncValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.AsyncValidation;
  return proto.wilqo.api.questionnaire.AsyncValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.AsyncValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.AsyncValidation}
 */
proto.wilqo.api.questionnaire.AsyncValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.questionnaire.AsyncValidationTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.wilqo.api.questionnaire.AsyncValidationItem;
      reader.readMessage(value,proto.wilqo.api.questionnaire.AsyncValidationItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.AsyncValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.AsyncValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.AsyncValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.questionnaire.AsyncValidationItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional AsyncValidationTypeEnum type = 1;
 * @return {!proto.wilqo.api.questionnaire.AsyncValidationTypeEnum}
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.questionnaire.AsyncValidationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.questionnaire.AsyncValidationTypeEnum} value
 * @return {!proto.wilqo.api.questionnaire.AsyncValidation} returns this
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated AsyncValidationItem items = 2;
 * @return {!Array<!proto.wilqo.api.questionnaire.AsyncValidationItem>}
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.AsyncValidationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.AsyncValidationItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.AsyncValidationItem>} value
 * @return {!proto.wilqo.api.questionnaire.AsyncValidation} returns this
*/
proto.wilqo.api.questionnaire.AsyncValidation.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.AsyncValidationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.AsyncValidationItem}
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.questionnaire.AsyncValidationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.AsyncValidation} returns this
 */
proto.wilqo.api.questionnaire.AsyncValidation.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.AsyncValidationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.AsyncValidationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.AsyncValidationItem}
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.AsyncValidationItem;
  return proto.wilqo.api.questionnaire.AsyncValidationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.AsyncValidationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.AsyncValidationItem}
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.AsyncValidationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.AsyncValidationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.AsyncValidationItem} returns this
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool required = 2;
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.questionnaire.AsyncValidationItem} returns this
 */
proto.wilqo.api.questionnaire.AsyncValidationItem.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PanelSequenceNavigationButton;
  return proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton} returns this
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton} returns this
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PanelSequenceNavigationButton} returns this
 */
proto.wilqo.api.questionnaire.PanelSequenceNavigationButton.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnairePanelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnairePanelResponse;
  return proto.wilqo.api.questionnaire.QuestionnairePanelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnairePanelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string panel_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated wilqo.shared.models.PanelElementResponse responses = 2;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse} returns this
*/
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse} returns this
 */
proto.wilqo.api.questionnaire.QuestionnairePanelResponse.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnaireResponses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireResponses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.wilqo.api.questionnaire.QuestionnairePanelResponse.toObject, includeInstance),
    urlAttributes: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanOfficerId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inviteUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnaireResponses;
  return proto.wilqo.api.questionnaire.QuestionnaireResponses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireResponses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.questionnaire.QuestionnairePanelResponse;
      reader.readMessage(value,proto.wilqo.api.questionnaire.QuestionnairePanelResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlAttributes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOfficerId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnaireResponses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireResponses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.questionnaire.QuestionnairePanelResponse.serializeBinaryToWriter
    );
  }
  f = message.getUrlAttributes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanOfficerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInviteUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated QuestionnairePanelResponse responses = 2;
 * @return {!Array<!proto.wilqo.api.questionnaire.QuestionnairePanelResponse>}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.QuestionnairePanelResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.QuestionnairePanelResponse, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.QuestionnairePanelResponse>} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses} returns this
*/
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.QuestionnairePanelResponse}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.questionnaire.QuestionnairePanelResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};


/**
 * optional string url_attributes = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.getUrlAttributes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.setUrlAttributes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loan_officer_id = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.getLoanOfficerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.setLoanOfficerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string invite_url = 5;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.getInviteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireResponses} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireResponses.prototype.setInviteUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PricingSearchProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PricingSearchProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    apr: (f = msg.getApr()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    rate: (f = msg.getRate()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    monthlyPayment: (f = msg.getMonthlyPayment()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    loanTermYears: jspb.Message.getFieldWithDefault(msg, 6, 0),
    amortizationType: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PricingSearchProduct;
  return proto.wilqo.api.questionnaire.PricingSearchProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PricingSearchProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setApr(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setRate(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setMonthlyPayment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLoanTermYears(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmortizationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PricingSearchProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PricingSearchProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApr();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getRate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyPayment();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getLoanTermYears();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAmortizationType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string search_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal apr = 3;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getApr = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
*/
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setApr = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.clearApr = function() {
  return this.setApr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.hasApr = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal rate = 4;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getRate = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 4));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
*/
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setRate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.clearRate = function() {
  return this.setRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.hasRate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.ProtoDecimal monthly_payment = 5;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getMonthlyPayment = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 5));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
*/
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setMonthlyPayment = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.clearMonthlyPayment = function() {
  return this.setMonthlyPayment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.hasMonthlyPayment = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 loan_term_years = 6;
 * @return {number}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getLoanTermYears = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setLoanTermYears = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string amortization_type = 7;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.getAmortizationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PricingSearchProduct} returns this
 */
proto.wilqo.api.questionnaire.PricingSearchProduct.prototype.setAmortizationType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    brandIdentity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    creationDate: (f = msg.getCreationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateDate: (f = msg.getUpdateDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary;
  return proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandIdentity(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBrandIdentity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreationDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string brand_identity = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.getBrandIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.setBrandIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp creation_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.getCreationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
*/
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.setCreationDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.clearCreationDate = function() {
  return this.setCreationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.hasCreationDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp update_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.getUpdateDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
*/
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.setUpdateDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary} returns this
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.clearUpdateDate = function() {
  return this.setUpdateDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary.prototype.hasUpdateDate = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelElementId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    questionnaireValidationMessageType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.QuestionnaireValidationMessage;
  return proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessageTypeEnum} */ (reader.readEnum());
      msg.setQuestionnaireValidationMessageType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuestionnaireValidationMessageType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string panel_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_element_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional QuestionnaireValidationMessageTypeEnum questionnaire_validation_message_type = 4;
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessageTypeEnum}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.getQuestionnaireValidationMessageType = function() {
  return /** @type {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessageTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessageTypeEnum} value
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage} returns this
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessage.prototype.setQuestionnaireValidationMessageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.DynamicDataItem.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.DynamicDataItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.DynamicDataItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.questionnaire.DynamicDataItemField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItem}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.DynamicDataItem;
  return proto.wilqo.api.questionnaire.DynamicDataItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItem}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = new proto.wilqo.api.questionnaire.DynamicDataItemField;
      reader.readMessage(value,proto.wilqo.api.questionnaire.DynamicDataItemField.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.DynamicDataItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.DynamicDataItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.questionnaire.DynamicDataItemField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItem} returns this
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DynamicDataItemField items = 2;
 * @return {!Array<!proto.wilqo.api.questionnaire.DynamicDataItemField>}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.DynamicDataItemField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.questionnaire.DynamicDataItemField, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.DynamicDataItemField>} value
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItem} returns this
*/
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItemField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItemField}
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.questionnaire.DynamicDataItemField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItem} returns this
 */
proto.wilqo.api.questionnaire.DynamicDataItem.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.DynamicDataItemField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItemField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItemField}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.DynamicDataItemField;
  return proto.wilqo.api.questionnaire.DynamicDataItemField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItemField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItemField}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.DynamicDataItemField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItemField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItemField} returns this
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItemField} returns this
*/
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItemField} returns this
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.DynamicDataItemField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.api.questionnaire.QuestionnaireLanguageEnum = {
  QUESTIONNAIRE_LANGUAGE_ENUM_UNKNOWN: 0,
  QUESTIONNAIRE_LANGUAGE_ENUM_ENGLISH: 1,
  QUESTIONNAIRE_LANGUAGE_ENUM_SPANISH: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.questionnaire.AsyncValidationTypeEnum = {
  ASYNC_VALIDATION_TYPE_ENUM_UNKNOWN: 0,
  ASYNC_VALIDATION_TYPE_ENUM_LOAN_DUPLICATE_CHECK: 1,
  ASYNC_VALIDATION_TYPE_ENUM_LENDER_LICENSE_CHECK: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.questionnaire.IntegrationCallStatusEnum = {
  INTEGRATION_CALL_STATUS_ENUM_STATUS_NOT_SET: 0,
  INTEGRATION_CALL_STATUS_ENUM_RECEIVED: 1,
  INTEGRATION_CALL_STATUS_ENUM_IN_PROGRESS: 2,
  INTEGRATION_CALL_STATUS_ENUM_OK: 3,
  INTEGRATION_CALL_STATUS_ENUM_ERROR: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.questionnaire.QuestionnaireValidationMessageTypeEnum = {
  QUESTIONNAIRE_VALIDATION_MESSAGE_TYPE_ENUM_WARNING: 0,
  QUESTIONNAIRE_VALIDATION_MESSAGE_TYPE_ENUM_ERROR: 1
};

goog.object.extend(exports, proto.wilqo.api.questionnaire);
