import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetAdminPanelSequenceDesignQueryRequest, GetAdminPanelSequenceDesignQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetAdminPanelSequenceDesignQueryRequest';

const usePanelSequenceDesign = (id: string) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, id], async () => {
    const request = new GetAdminPanelSequenceDesignQueryRequest();
    request.setActivityDesignId(id);
    request.setBusinessProcessDomainId(bpdId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetAdminPanelSequenceDesignQueryResponse();
    GetAdminPanelSequenceDesignQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(
      MESSAGE_NAME,
      responseObj.error,
      {
        activityDesign: responseObj.activityDesign,
        runtime: responseObj.runtime,
      },
    );
  }, { enabled: Boolean(id) && Boolean(bpdId) });
};
export { usePanelSequenceDesign };
