import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { UpdateFeesAdjustmentsCreditsCommandRequest, UpdateFeesAdjustmentsCreditsCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { FeesAdjustmentsCreditsSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { toFeesAdjustmentsCreditsSectionFromObject } from '../helpers/feesCreditsHelper';
import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface UseUpdateFeesAdjustmentsCreditsDTO {
  dealId: string;
  feesAdjustmentsCreditsSection: FeesAdjustmentsCreditsSection.AsObject;
}

const MESSAGE_NAME = 'wilqo.api.mortgage.UpdateFeesAdjustmentsCreditsCommandRequest';

const useUpdateFeesAdjustmentsCredits = () => {
  const { commandResponse } = useBackend();

  const UpdateFeesAdjustmentsCredits = async (updateObject: UseUpdateFeesAdjustmentsCreditsDTO) => {
    const request = new UpdateFeesAdjustmentsCreditsCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setSection(toFeesAdjustmentsCreditsSectionFromObject(updateObject.feesAdjustmentsCreditsSection));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpdateFeesAdjustmentsCreditsCommandResponse();
    UpdateFeesAdjustmentsCreditsCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(UpdateFeesAdjustmentsCredits);
};

export { useUpdateFeesAdjustmentsCredits };
