import { Button } from '../Button';
import { DropdownMenu } from '../DropdownMenu';
import { DynamicIcon } from '../DynamicIcon';
import type { CardFilterItem } from './CardFilters';
import type { CardButton } from './CardHeader';

export interface ContextualCardHeaderProps {
  buttons: CardButton[];
  onClickClose: () => void;
  caption: string;
  filters?: CardFilterItem[];
}

export const ContextualCardHeader = (props: ContextualCardHeaderProps) => {
  const { buttons, caption, onClickClose } = props;

  const [firstButton, secondButton, ...restButtons] = buttons;

  const handleActionClick = (action: CardButton) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (action.onClick) action.onClick(onClickClose);
  };

  const handleClickClearButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClickClose();
  };

  const handleClickMenuItem = (button: CardButton) => {
    if (button.onClick) button.onClick(onClickClose);
  };

  const renderRestButtons = () => {
    if (restButtons.length === 1) {
      const button = restButtons[0];
      return <Button {...button} onClick={handleActionClick(button)} variant="tertiary" />;
    }
    if (restButtons.length > 1) {
      return (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button label="More" rightIcon="ArrowDropDown" variant="tertiary" />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content align="start" size="large">
            {
              restButtons.map((button) => (button.children ? (
                <DropdownMenu.SubItem label={button.label}>
                  {
                    button.children.map((btn) => (
                      <DropdownMenu.Item label={btn.label} onClick={() => handleClickMenuItem(btn)} />
                    ))
                  }
                </DropdownMenu.SubItem>
              ) : (
                <DropdownMenu.Item label={button.label} onClick={() => handleClickMenuItem(button)} />
              )
              ))
            }
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      );
    }
    return null;
  };

  return (
    <div
      className="flex items-center flex-row h-16 py-3 px-4 bg-on-surface-states-selected overflow-visible [&_.item]:mr-2"
    >
      <button
        onClick={handleClickClearButton}
        type="button"
      >
        <DynamicIcon className="ml-auto mr-0 text-2xl text-on-surface-inactive" icon="Clear" />
      </button>
      <span className="item text-on-surface-active ml-6">{caption}</span>
      {firstButton && (
        <Button
          className={secondButton ? 'item' : ''}
          {...firstButton}
          onClick={handleActionClick(firstButton)}
          variant="tertiary"
        />
      )}
      {secondButton && (
        <Button
          className={restButtons.length > 0 ? 'item' : ''}
          {...secondButton}
          onClick={handleActionClick(secondButton)}
          variant="tertiary"
        />
      )}
      {renderRestButtons()}
    </div>
  );
};
