import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useMemo } from 'react';

import { GetLoanPageDataQueryRequest, GetLoanPageDataQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPageQueries_pb';

import { useBackend } from '../useBackend';

export const DYNAMIC_PAGE_MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.GetLoanPageDataQueryRequest';

type Options = UseQueryOptions<GetLoanPageDataQueryResponse.AsObject, any, GetLoanPageDataQueryResponse.AsObject, any>;

// eslint-disable-next-line import/no-mutable-exports
export let dynamicLoanPageQueryKey: QueryKey;

const useDynamicLoanPage = (dealId: string | undefined, pageId: string | undefined, scopeToken: string | undefined, tabId = '', withoutData = false, options?: Options) => {
  const { commandResponse } = useBackend();

  const queryOptions = useMemo(() => {
    const hasProps = Boolean(dealId?.trim()) && Boolean(pageId?.trim()) && Boolean(scopeToken?.trim());
    if (options) {
      return { ...options, enabled: hasProps && options.enabled };
    }
    return { enabled: hasProps };
  }, [options, pageId, scopeToken, dealId]);

  dynamicLoanPageQueryKey = [DYNAMIC_PAGE_MESSAGE_NAME, dealId, pageId, scopeToken];

  return useQuery(dynamicLoanPageQueryKey, async () => {
    const request = new GetLoanPageDataQueryRequest();
    request.setDealId(dealId || '');
    request.setPageId(pageId || '');
    request.setScopeToken(scopeToken || '');
    request.setTabId(tabId);
    request.setWithoutData(withoutData);
    const msg = {
      msg: request,
      msgName: DYNAMIC_PAGE_MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new GetLoanPageDataQueryResponse();
    GetLoanPageDataQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    /// NOTE: Not using middleware here because api can return error and good data at the same time
    return response.toObject();
  }, queryOptions);
};

export { useDynamicLoanPage };
