import clsx from 'clsx';
import { useEffect, useState } from 'react';

import type {
  IndividualEscrowPayment,
  LoanEscrowFee,
  LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { LoanFeeSpecialCaseEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { Column } from '@/API/Queries/mortgage/useLoanFees';
import { Card } from '@/Components/Atoms/Card/Card';
import { Chip } from '@/Components/Atoms/Chip';
import { Icon } from '@/Components/Atoms/Icon';
import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import {
  ColumnEnum,
  emptyValue,
  INDIVIDUAL_FEES_COLUMNS,
  mapPaymentChanges,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import type { TableContent } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import {
  createTableContent,
  PAYMENT_PORTIONS_OF_FEE,
  validatePaymentValues,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import { convertProtoToCurrency } from '@/Utils/Helpers/numberFormatter';

interface IFeePaidBy {
  fee: LoanEscrowFee.AsObject;
  escrowPayment?: IndividualEscrowPayment.AsObject;
  total?: number;
  categoryType: LoanFeeCategoryTypeEnum;
  addError: (error: string) => void;
  removeError: (error: string) => void;
  isInErrorState: boolean;
  errorState: string[];
}

export const FeePaidBy = (props: IFeePaidBy) => {
  const { addError, categoryType, errorState, escrowPayment, fee, removeError, total } = props;
  const [flag, setFlag] = useState<{ value: number }>({ value: 0 });
  const { handleBlurInput } = useFeesContext();
  const [dataTable, setDataTable] = useState<Array<TableContent>>();

  useEffect(() => {
    if (!fee || !escrowPayment || !total) return;
    setDataTable(createTableContent(escrowPayment));
  }, [fee, escrowPayment, total, flag]);

  const removeOtherChip = (payer: string) => !(payer === 'Other' && categoryType === 1);

  const handleSendUpdatedValues = (updatedValue: string, column: string, editedRowData: TableContent) => {
    const EditFeeColumn = `${editedRowData.payer.charAt(0).toLowerCase()}${editedRowData.payer.slice(1)}Paid`;
    setFlag((prevObj) => ({ ...prevObj, value: prevObj.value + 1 }));

    if (LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT === fee.specialCase) {
      updatedValue = updatedValue.startsWith('-') ? updatedValue : `-${updatedValue}`;
    }

    if (PAYMENT_PORTIONS_OF_FEE.includes(column)) {
      mapPaymentChanges(updatedValue, column, editedRowData, fee, categoryType);
    } else {
      handleBlurInput(updatedValue, { key: EditFeeColumn }, fee, undefined, undefined, categoryType);
    }
    validatePaymentValues(editedRowData.payer, escrowPayment, addError, removeError);
  };

  const renderChip = ({ payer }: TableContent) => (
    <td
      key={payer}
      className="text-left px-3 py-4 border-r border-r-on-surface-stroke min-w-[250px]"
    >
      <Chip
        label={payer}
        selected
      />
    </td>
  );

  const renderDefaultInput = (rowData: TableContent, column: Column, value: number) => {
    const errorKey = column.id.charAt(0).toUpperCase() + column.id.slice(1);
    const isInErrorState = errorState.some((error) => error.includes(errorKey));

    return (
      <td
        key={column.id}
        className={clsx('px-3 py-4 border', {
          '!border-danger': isInErrorState,
          'border-r-0': !isInErrorState,
        })}
        id={column.id}
      >
        <Input
          disabled={((column.id !== ColumnEnum.CREDIT_AMOUNT && rowData.payer === 'Seller')
            || (column.id === ColumnEnum.CREDIT_AMOUNT && rowData.payer === 'Borrower'))}
          id={rowData.payer}
          mask={MaskType.CURRENCY}
          onBlur={(e) => handleSendUpdatedValues(e.target.value, column.id, rowData)}
          value={String(value || emptyValue())}
        />
      </td>
    );
  };

  const renderTooltip = (column: Column, value: number | string) => {
    const negativePaidAtClosingError = Number(value) < 0;

    return (
      <td
        key={column.id}
        className="px-3 min-w-[200px] border-l"
      >
        <div className="flex">
          <div>
            {
              negativePaidAtClosingError && fee.specialCase !== LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT && (
                <div>
                  <Tooltip message="Paid at closing cannot be negative">
                    <Icon className="!text-danger" icon="Error" />
                  </Tooltip>
                </div>
              )
            }
          </div>
          <div className="flex-grow">
            <Typography className="text-on-surface-active text-center" variant="body2">
              {convertProtoToCurrency(value)}
            </Typography>
          </div>
        </div>
      </td>
    );
  };

  return (
    <Card
      defaultOpen
      headerProps={{
        title: 'Fee paid by',
      }}
      variant="border"
    >
      <div>
        <table className="w-full p-2">
          <thead className="border-b border-b-on-surface-stroke">
            <tr>
              {INDIVIDUAL_FEES_COLUMNS.map((column, index) => (
                <th
                  key={column.id}
                  className={clsx('px-3 py-3 text-center border-r border-r-on-surface-stroke', {
                    '!border-r-0': index === INDIVIDUAL_FEES_COLUMNS.length - 1,
                    '!text-left w-[300px]': index === 0,
                  })}
                  scope="col"
                >
                  <Typography className="uppercase text-on-surface-inactive" variant="overline">
                    {column.header}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="w-full">
            {
              dataTable?.map((rowData) => removeOtherChip(rowData.payer) && (
                <tr
                  key={rowData.payer}
                  className="border-b border-b-on-surface-stroke"
                >
                  {INDIVIDUAL_FEES_COLUMNS.map((column, index) => {
                    const isLastColumn = index === INDIVIDUAL_FEES_COLUMNS.length - 1;
                    const value = rowData[column.id as keyof typeof rowData] as number;

                    if ([index === 0].includes(true)) {
                      return renderChip(rowData);
                    }
                    if (isLastColumn) {
                      return renderTooltip(column, value);
                    }
                    return renderDefaultInput(rowData, column, value);
                  })}
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Card>
  );
};
