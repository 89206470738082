import clsx from 'clsx';
import { get } from 'lodash';

import type { LoanFee, LoanFeeCategory } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { FeeCalculationTypeEnum,
  FeePercentBasisEnum,
  FeeSourceEnum,
  UxDisplayStatusEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { Badge } from '@/Components/Atoms/Badge';
import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Icon } from '@/Components/Atoms/Icon';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { ColumnEnum, FEES_COLUMNS } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { convertProtoToCurrency, convertProtoToPercentage, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import { ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';

const FeeDetails = () => {
  const { loanFees } = useFeesContext();

  const generateSourceIcon = (fee: LoanFee.AsObject) => {
    switch (fee.source) {
      case FeeSourceEnum.FEE_SOURCE_ENUM_UNKNOWN:
        return '--';
      case FeeSourceEnum.FEE_SOURCE_ENUM_MANUAL:
        return (
          <div className="relative">
            <Tooltip message={fee.sourceMessage}>
              <Icon icon="Person" />
            </Tooltip>
          </div>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_INTEGRATION:
        return (
          <Tooltip message={fee.sourceMessage}>
            <Icon icon="CloudSync" />
          </Tooltip>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_TRIGGERED:
        return (
          <Tooltip message={fee.sourceMessage}>
            <Icon icon="Bolt" />
          </Tooltip>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_CALCULATED:
        return (
          <Tooltip message={fee.sourceMessage}>
            <Icon icon="Calculate" />
          </Tooltip>
        );
      case FeeSourceEnum.FEE_SOURCE_ENUM_MANUAL_OVERRIDE:
        return (
          <Tooltip message={fee.sourceMessage}>
            <Icon icon="PersonEdit" />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <div className="grid gap-y-6">
      {
        loanFees.map((loanfee: LoanFeeCategory.AsObject) => (
          <Card
            key={loanfee.category}
            headerProps={{
              caption: 'Total',
              captionStrong: convertProtoToCurrency(loanfee.sumTotal),
              title: loanfee.category,
            }}
            variant="border"
          >
            {loanfee.feesList.length === 0 ? (
              <div className="py-4">
                <ContingencyMessage
                  icon="NoItems"
                  subtitle="No details found"
                  title="No results, yet"
                  variant="image"
                />
              </div>
            ) : (
              <div className="relative overflow-x-auto">
                <table className="w-full table-fixed">
                  <thead className="border-b border-b-on-surface-stroke">
                    <tr>
                      {FEES_COLUMNS.map((column) => (
                        <th
                          key={column.id}
                          className={
                            clsx(
                              'text-[10px] tracking-[1.5px] font-normal text-on-surface-inactive px-4 py-3 uppercase border-r border-r-on-surface-stroke',
                              {
                                'text-left w-[255px]': column.id === 'name',
                                'text-right': column.id !== 'name',
                                'w-[150px]': column.id === 'basis',
                              },
                            )
                          }
                          id={column.id}
                          scope="col"
                        >
                          {column.header}
                        </th>
                      ))}
                      <th className="text-[10px] tracking-[1.5px] font-normal text-on-surface-inactive px-2 py-3 uppercase text-right" scope="col">DATA SOURCE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loanfee.feesList.map((fee: LoanFee.AsObject) => (
                      <tr
                        key={`table-body-${fee.feeId}`}
                        className="transition duration-150 ease-in-out hover:bg-on-surface-states-hover"
                      >
                        {FEES_COLUMNS.map((column, index) => {
                          const isSellerPaidColumn = column.id === ColumnEnum.SELLER_PAID;
                          const isLenderPaidColumn = column.id === ColumnEnum.LENDER_PAID;
                          const isBorrowerPaidColumn = column.id === ColumnEnum.BORROWER_PAID;
                          const emptyValue = numberFormatter.format(0);

                          let value = get(fee, column.id);
                          if (typeof value === 'object') {
                            value = convertProtoToCurrency(value);
                          }
                          if (!value) {
                            value = emptyValue;
                          }
                          if (index === 0) {
                            return (
                              <td
                                key={`table-row-cell-${column.id}`}
                                className="pl-4 pr-10 py-4 text-left border-r border-r-on-surface-stroke align-top"
                                id={`table-row-cell-${fee.name}`}
                              >
                                <div className="flex align-center justify-left">
                                  <div className="grid gap-1 mr-3">
                                    <p className="text-sm font-normal text-on-surface-active break-words">{fee.name}</p>
                                    <span className="text-xs font-normal text-on-surface-inactive">
                                      {`to ${fee.feePayment?.payeePartySummary?.fullName || fee.paidTo}`}
                                    </span>
                                  </div>
                                  {
                                    fee.isApr && (
                                      <Badge
                                        label="apr"
                                        variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS}
                                      />
                                    )
                                  }
                                </div>
                              </td>
                            );
                          }

                          if (column.id === ColumnEnum.BASIS) {
                            const feeCalculationType = fee.feePayment?.feeCalculationType;

                            if (feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED_AND_PERCENT) {
                              return (
                                <td
                                  key={`table-row-cell-${column.id}`}
                                  className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
                                  id={`table-row-cell-${fee.name}`}
                                >
                                  <div className="flex flex-col items-end">
                                    <span className="text-sm text-on-surface-active font-normal">
                                      {`${convertProtoToCurrency(fee.feePayment?.feeSpecifiedFixedAmount)} + ${convertProtoToPercentage(fee.feePayment?.feeTotalPercent)}`}
                                    </span>
                                    <span className="text-xs font-normal text-on-surface-inactive">
                                      {ConvertEnum(FeePercentBasisEnum, fee.feePayment?.feePercentBasisType || 0)}
                                    </span>
                                  </div>
                                </td>
                              );
                            }

                            if (feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_PERCENT) {
                              return (
                                <td
                                  key={`table-row-cell-${column.id}`}
                                  className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
                                  id={`table-row-cell-${fee.name}`}
                                >
                                  <div className="flex flex-col items-end">
                                    <span className="text-sm text-on-surface-active font-normal">
                                      {convertProtoToPercentage(fee.feePayment?.feeTotalPercent)}
                                    </span>
                                    <span className="text-xs font-normal text-on-surface-inactive">
                                      {ConvertEnum(FeePercentBasisEnum, fee.feePayment?.feePercentBasisType || 0)}
                                    </span>
                                  </div>
                                </td>
                              );
                            }

                            if (feeCalculationType === FeeCalculationTypeEnum.FEE_CALCULATION_TYPE_ENUM_FIXED) {
                              return (
                                <td
                                  key={`table-row-cell-${column.id}`}
                                  className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
                                  id={`table-row-cell-${fee.name}`}
                                >
                                  <div className="flex flex-col items-end">
                                    <span className="text-sm text-on-surface-active font-normal">
                                      {convertProtoToCurrency(fee.feePayment?.feeSpecifiedFixedAmount)}
                                    </span>
                                  </div>
                                </td>
                              );
                            }

                            // Default for FEE_CALCULATION_TYPE_ENUM_UNKNOWN && FEE_CALCULATION_TYPE_ENUM_VARIABLE
                            return (
                              <td
                                key={`table-row-cell-${column.id}`}
                                className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
                                id={`table-row-cell-${fee.name}`}
                              >
                                <div className="flex flex-col items-end">
                                  <span className="text-sm text-on-surface-active font-normal">
                                    --
                                  </span>
                                </div>
                              </td>
                            );
                          }

                          const pocAmount = fee.feePayment?.paidByBorrowerOutsideOfClosingAmount ? ConvertProtoDecimalAsObjectToNumber(fee.feePayment?.paidByBorrowerOutsideOfClosingAmount) : undefined;

                          if (isBorrowerPaidColumn && pocAmount) {
                            return (
                              <td
                                key={`table-row-cell-${column.id}`}
                                className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
                                id={`table-row-cell-${fee.name}`}
                              >
                                <div className="flex flex-col items-end">
                                  <span className="text-sm text-on-surface-active font-normal">
                                    {value}
                                  </span>
                                  <Typography className="text-on-surface-inactive" variant="caption">
                                    {`${convertProtoToCurrency(pocAmount)} (POC)`}
                                  </Typography>
                                </div>
                              </td>
                            );
                          }

                          const sellerCredit = fee.feePayment?.paidBySellerCreditAmount ? ConvertProtoDecimalAsObjectToNumber(fee.feePayment.paidBySellerCreditAmount) : undefined;
                          if (isSellerPaidColumn && sellerCredit) {
                            return (
                              <td
                                key={`table-row-cell-${column.id}`}
                                className={
                                  clsx(
                                    'border-r border-r-on-surface-stroke text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
                                    {
                                      'opacity-40': column.hidden,
                                      'text-right': true,
                                    },
                                  )
                                }
                                id={`table-row-cell-${fee.name}`}
                              >
                                <div>
                                  <span>
                                    {value}
                                  </span>
                                  <Typography className="text-on-surface-inactive" variant="caption">
                                    {`${convertProtoToCurrency(sellerCredit)} (credit)`}
                                  </Typography>
                                </div>
                              </td>
                            );
                          }

                          const lenderCredit = fee.feePayment?.paidByLenderCreditAmount ? ConvertProtoDecimalAsObjectToNumber(fee.feePayment.paidByLenderCreditAmount) : undefined;
                          if (isLenderPaidColumn && lenderCredit) {
                            return (
                              <td
                                key={`table-row-cell-${column.id}`}
                                className={
                                  clsx(
                                    'border-r border-r-on-surface-stroke text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
                                    {
                                      'opacity-40': column.hidden,
                                      'text-right': true,
                                    },
                                  )
                                }
                                id={`table-row-cell-${fee.name}`}
                              >
                                <div>
                                  <span>
                                    {value}
                                  </span>
                                  <Typography className="text-on-surface-inactive" variant="caption">
                                    {`${convertProtoToCurrency(lenderCredit)} (credit)`}
                                  </Typography>
                                </div>
                              </td>
                            );
                          }

                          if (fee.total && column.id === ColumnEnum.TOTAL) {
                            return (
                              <td
                                key={`table-row-cell-${column.id}`}
                                className="px-6 py-4 text-right border-r border-r-on-surface-stroke w-[150px] align-top"
                                id={`table-row-cell-${fee.name}`}
                              >
                                <div className="flex items-center justify-end">
                                  <span className="text-sm text-on-surface-active font-normal">
                                    {fee.total && convertProtoToCurrency(get(fee, 'total'))}
                                  </span>
                                </div>
                              </td>
                            );
                          }
                          return (
                            <td
                              key={`table-row-cell-${column.id}`}
                              className={
                                clsx(
                                  'border-r border-r-on-surface-stroke text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
                                  {
                                    'opacity-40': column.hidden,
                                    'text-right': index !== 0,
                                  },
                                )
                              }
                              id={`table-row-cell-${fee.name}`}
                            >
                              {value}
                            </td>
                          );
                        })}
                        <td className="px-3 w-[120px] align-middle">
                          <div className="flex items-center justify-center">
                            {generateSourceIcon(fee)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Card>
        ))
      }
    </div>
  );
};

export default FeeDetails;
