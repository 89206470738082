// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/SummaryCard.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    badge: (f = msg.getBadge()) && Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.toObject, includeInstance),
    action: (f = msg.getAction()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction.deserializeBinaryFromReader);
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.serializeBinaryToWriter
    );
  }
  f = message.getAction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.toObject, includeInstance),
    delimiter: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine;
  return proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDelimiter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.serializeBinaryToWriter
    );
  }
  f = message.getDelimiter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated SummaryField fields = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField>}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional string delimiter = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.getDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine.prototype.setDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField;
  return proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.RenderableValue value = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryField.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Badge badge = 1;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.Badge}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.Badge} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.Badge|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated SummaryLine lines = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine>}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.SummaryLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};


/**
 * optional ButtonAction action = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ButtonAction}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.getAction = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ButtonAction} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb.ButtonAction, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ButtonAction|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.setAction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.clearAction = function() {
  return this.setAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings.prototype.hasAction = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
