import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetLoanCompliancePageQueryRequest, GetLoanCompliancePageQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const COMPLIANCE_PAGE_MESSAGE = 'wilqo.api.mortgage.GetLoanCompliancePageQueryRequest';

const useLoanCompliancePage = (dealId: string) => {
  const { commandResponse } = useBackend();

  return useQuery([COMPLIANCE_PAGE_MESSAGE, dealId], async () => {
    const request = new GetLoanCompliancePageQueryRequest();
    request.setDealId(dealId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: COMPLIANCE_PAGE_MESSAGE,
    });
    const response = new GetLoanCompliancePageQueryResponse();
    GetLoanCompliancePageQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(COMPLIANCE_PAGE_MESSAGE, responseObj.error, responseObj);
  }, {
    enabled: Boolean(dealId),
  });
};
export { useLoanCompliancePage };
