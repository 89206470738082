import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { InitializeSandboxCommandRequest, InitializeSandboxCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface IUseInitializeSandbox {
  dealId: string;
  loanId: string;
}

export const MESSAGE_NAME = 'wilqo.api.mortgage.InitializeSandboxCommandRequest';

const useInitializeSandbox = () => {
  const { commandResponse } = useBackend();

  const initializeSandbox = async (updateObject: IUseInitializeSandbox) => {
    const request = new InitializeSandboxCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setLoanId(updateObject.loanId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new InitializeSandboxCommandResponse();
    InitializeSandboxCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj);
  };

  return useMutation(initializeSandbox);
};

export { useInitializeSandbox };
