import { useCallback, useMemo, useState } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DocType, DocumentEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { AutoComplete } from '@/Components/Atoms/AutoComplete';
import { Button } from '@/Components/Atoms/Button';
import { Checkbox } from '@/Components/Atoms/Checkbox';
import { Input } from '@/Components/Atoms/Input';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

interface DocumentCategoryCreateTypeProps {
  onClose?: (docType?: DocType.AsObject, category?: PanelElementOption.AsObject, assign?: boolean) => void;
  category?: PanelElementOption.AsObject;
}

const DocumentCategoryCreateType = ({
  onClose = () => null,
  category,
}: DocumentCategoryCreateTypeProps) => {
  const documentOptions = useMemo(() => {
    const enumProperties = Object.keys(DocumentEnum);
    enumProperties.shift();
    return enumProperties.map<PanelElementOption.AsObject>((val) => getPanelElementOption({
      headerText: ConvertEnum(DocumentEnum, DocumentEnum[val as keyof typeof DocumentEnum]) || '',
      id: String(DocumentEnum[val as keyof typeof DocumentEnum]) || '',
    }));
  }, []);

  const [externalType, setExternalType] = useState<PanelElementOption.AsObject | undefined>();
  const [documentTypeName, setDocumentTypeName] = useState('');
  const [consumerFacing, setConsumerFacing] = useState(false);

  const handleSubmit = useCallback((assign?: boolean) => {
    const newDocType = new DocType();
    newDocType.setDocType(Number(externalType?.id));
    newDocType.setDocumentTypeName(documentTypeName);
    newDocType.setIsConsumerFacing(consumerFacing);
    onClose(newDocType.toObject(), category, assign);
  }, [externalType?.id, documentTypeName, consumerFacing, onClose, category]);

  return (
    <DialogLayout>
      <DialogHeader
        icon="ArrowBack"
        iconAction={onClose}
        title="Create Category Type"
      />

      <DialogContent>
        <div className="flex flex-col mx-auto gap-y-4 w-full h-full p-4 sm:p-12 max-w-lg">
          <Input
            displayOnly
            label="Document Type Name"
            onChange={setDocumentTypeName}
            placeholder="Document Type Name"
            required
            value={documentTypeName}
          />
          <AutoComplete
            displayOnly
            onSelect={(option) => setExternalType(option)}
            options={documentOptions}
            placeholder="Mismo Document type"
            required
            value={externalType?.headerText || ''}
          />
          <Checkbox
            displayOnly
            label="Consumer facing"
            onClick={setConsumerFacing}
            value={consumerFacing}
          />
        </div>
      </DialogContent>

      <DialogFooter borderTop>
        <Button
          disabled={!externalType?.id || !documentTypeName}
          label="Create & Assign"
          onClick={() => handleSubmit(true)}
          variant="primary"
        />
      </DialogFooter>
    </DialogLayout>
  );
};

export { DocumentCategoryCreateType };
