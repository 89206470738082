import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetURLAEligibleLoansQueryRequest, GetURLAEligibleLoansQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetURLAEligibleLoansQueryRequest';

const useGetURLAEligibleLoans = (isEnabled?: boolean) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME], async () => {
    const request = new GetURLAEligibleLoansQueryRequest();
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new GetURLAEligibleLoansQueryResponse();
    GetURLAEligibleLoansQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    return response.toObject();
  }, {
    enabled: isEnabled,
  });
};

export { useGetURLAEligibleLoans };
