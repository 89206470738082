import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { IsLoanOfficerLicensedInStatesQueryRequest, IsLoanOfficerLicensedInStatesQueryResponse } from '@/API/Models/Wilqo_API_Users_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.IsLoanOfficerLicensedInStatesQueryRequest';

const useIsOfficerLicensed = () => {
  const { commandResponse } = useBackend();

  const IsOfficerLicenseCommand = async (state: string) => {
    const request = new IsLoanOfficerLicensedInStatesQueryRequest();
    request.setState(state);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new IsLoanOfficerLicensedInStatesQueryResponse();
    IsLoanOfficerLicensedInStatesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.licensed);
  };

  return useMutation(IsOfficerLicenseCommand);
};

export { useIsOfficerLicensed };
