import 'json-diff-kit/dist/viewer.css';

import type { DiffResult } from 'json-diff-kit';
import { Differ, Viewer } from 'json-diff-kit';
import { useMemo } from 'react';

interface JsonDiffProps {
  left: string | undefined;
  right: string | undefined;
  showUnchanged: boolean;
}

export const JsonDiff = (props: JsonDiffProps) => {
  const { left = '{}', right = '{}', showUnchanged } = props;
  const diff = useMemo(() => {
    const allDiffs = new Differ().diff(JSON.parse(left), JSON.parse(right));

    if (showUnchanged) return allDiffs;

    // Zip the befores and afters, then reduce them, skipping instances where before and after are both "equal".
    // An even better version of this would include some lines before and after, as well as potentially preserve hierarcy
    //   info, but that would involve quite a bit more work.
    return allDiffs[0].map((diff, index): [DiffResult, DiffResult] => [diff, allDiffs[1][index]]).reduce((acc, diff) => {
      if (diff[0].type === 'equal' && diff[1].type === 'equal') return acc;
      acc[0].push(diff[0]);
      acc[1].push(diff[1]);
      return acc;
    }, [[], []] as [DiffResult[], DiffResult[]]);
  }, [left, right, showUnchanged]);

  return (
    <Viewer
      diff={diff}
      highlightInlineDiff
      inlineDiffOptions={{ mode: 'word', wordSeparator: ' ' }}
      lineNumbers
    />
  );
};
