import clsx from 'clsx';
import { forwardRef } from 'react';

export interface SurfaceProps extends React.HTMLAttributes<HTMLDivElement> {
  selected?: boolean;
}

const Surface = forwardRef((props: SurfaceProps, ref: any) => {
  const { children, className, selected, ...divProps } = props;

  return (
    <div
      ref={ref}
      className={clsx(className, {
        'cursor-pointer': props.onClick,
        'user-drag:bg-on-surface-states-dragged bg-surface hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed': !selected,
        'user-drag:bg-primary-on-surface-states-dragged bg-primary-on-surface-states-activated hover:bg-on-surface-states-hover active:bg-on-surface-states-presssed': selected,
      })}
      {...divProps}
    >
      {children}
    </div>
  );
});

export { Surface };
