import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { PanelElementResponse } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { PutValidationChecklistActivityProgressCommandRequest, PutValidationChecklistActivityProgressCommandResponse } from '@/API/Models/Wilqo_API_Activity_Commands_pb';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest';

interface UpdateObject {
  activityId: string;
  checklistItemId: string;
  elements: Array<PanelElementResponse.AsObject>;
}

const usePutActivityChecklistProgress = () => {
  const { commandResponse } = useBackend();

  const PutActivityChecklistProgressCommand = async (updateObject: UpdateObject) => {
    const { activityId, checklistItemId, elements } = updateObject;
    const request = new PutValidationChecklistActivityProgressCommandRequest();
    request.setActivityId(activityId);
    request.setChecklistItemId(checklistItemId);

    elements.map((el) => {
      const element = new PanelElementResponse();
      element.setId(el.id);
      if (el.value) {
        element.setValue(DynamicDataElementMap(el.value));
      }
      return element;
    }).forEach((x) => request.addPanelElements(x));

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PutValidationChecklistActivityProgressCommandResponse();
    PutValidationChecklistActivityProgressCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(PutActivityChecklistProgressCommand);
};

export { usePutActivityChecklistProgress };
