import { Typography } from '@/Components/Atoms/Typography';

interface HeaderProps {
  title: string;
}

export const Header = (props: HeaderProps) => (
  <div>
    <Typography variant="display5">{props.title}</Typography>
  </div>
);

export const Content = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div className="flex-1" {...props} />
);

export const Layout = (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
  <div className="flex flex-col h-full w-full" {...props} />
);

export const MobileLayout = {
  Content,
  Header,
  Root: Layout,
};
