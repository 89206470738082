import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetAdminActivityTemplatesQueryRequest, GetAdminActivityTemplatesQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetAdminActivityTemplatesQueryRequest';

const useBPDActivitiesTemplates = (page = 0, itemsPerPage = 25, searchTerm = '', enabled = true, archived = false) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, page, itemsPerPage, searchTerm, archived], async () => {
    const request = new GetAdminActivityTemplatesQueryRequest();
    request.setPage(page);
    request.setItemsPerPage(itemsPerPage);
    request.setSearchTerm(searchTerm);
    request.setIncludeArchived(archived);
    request.setBusinessProcessDomainId(bpdId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetAdminActivityTemplatesQueryResponse();
    GetAdminActivityTemplatesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(
      MESSAGE_NAME,
      responseObj.error,
      {
        list: responseObj.itemsList,
        totalItems: responseObj.totalItems,
        totalPages: responseObj.totalPages,
      },
    );
  }, { enabled: enabled && Boolean(bpdId) });
};
export { useBPDActivitiesTemplates };
