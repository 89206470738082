import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { cn } from '@/Utils/Helpers/uiBuilders';

import { Icon } from '../Icon';
import { useMenuContext } from './MenuContext';
import { MenuItemComponent } from './MenuItem';

interface DropdownMenuContentProps extends DropdownMenu.MenuContentProps {
  size?: 'fit' | 'large' | 'trigger';
}

export const MenuContent = ({ size = 'fit', ...props }: DropdownMenuContentProps) => {
  const { openChildren, setOpenChildren } = useMenuContext();

  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className={cn(
          'shadow-two bg-surface z-[100] py-2 min-w-4',
          {
            'min-w-[112px]': size === 'fit',
            'w-72': size === 'large',
            'w-[var(--radix-dropdown-menu-trigger-width)]': size === 'trigger',
          },
        )}
        onFocusOutside={(e) => e.preventDefault()}
        {...props}
      >
        {openChildren && (
          <MenuItemComponent
            icon={<Icon icon="ArrowBack" />}
            label={openChildren.title}
            onClick={() => {
              setOpenChildren(undefined);
            }}
          />
        )}
        { openChildren?.children || props.children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
};
