import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetChangeHistoryByIdQueryRequest, GetChangeHistoryByIdQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.mortgage.GetChangeHistoryByIdQueryRequest';

export const useLoanChangeHistoryItem = (dealId: string | null | undefined, eventVersion: number | undefined) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, dealId, eventVersion], async () => {
    if (!dealId || !eventVersion) {
      return null;
    }

    const request = new GetChangeHistoryByIdQueryRequest();
    request.setDealId(dealId);
    request.setVersion(eventVersion);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });

    const response = new GetChangeHistoryByIdQueryResponse();
    GetChangeHistoryByIdQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.item);
  });
};
