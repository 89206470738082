import { IntegrationTypeLocalEnum } from '@/API/Models/Wilqo.API.Mortgage.Integration/Models_pb';
import { ComplianceCheckType } from '@/API/Models/Wilqo.Shared.Mortgage.Integration/Compliance/Models_pb';
import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useRunComplianceChecks } from '@/API/Queries/mortgageIntegrations/compliance/useRunComplianceChecks';
import { Dialog } from '@/Components/Atoms/RadixDialog';
import { RequiredFields } from '@/Components/Features/DynamicLoanPage/RequiredFields';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface ComplianceModalProps {
  onClose: () => void;
  openModal: boolean;
}

const ComplianceModal = ({ onClose: handleCloseModal, openModal }: ComplianceModalProps) => {
  const { showSnackBar } = useShared();

  const { data: loan } = useLoan();
  const { isLoading: isRunning, mutate: runComplianceChecks } = useRunComplianceChecks();

  const handleOnIsValid = () => {
    runComplianceChecks({
      complianceCheckTypesList: [
        ComplianceCheckType.COMPLIANCE_CHECK_TYPE_ALL,
      ],
      dealId: loan?.dealId || '',
    }, {
      onError(error: any) {
        showSnackBar({ message: error?.message || 'Something went wrong', open: true });
      },
      onSuccess() {
        showSnackBar({ message: 'Compliance checks updated successfully', open: true });
        handleCloseModal();
      },
    });
  };

  return (
    <Dialog onOpenChange={handleCloseModal} open={openModal}>
      <RequiredFields
        dealId={loan?.dealId || ''}
        integrationType={IntegrationTypeLocalEnum.INTEGRATION_TYPE_LOCAL_ENUM_COMPLIANCE}
        isRunningIntegration={isRunning}
        onIsValid={handleOnIsValid}
        packageName="wilqo.api.mortgage_integration.RequiredFieldsValidationAdditionalInformationCompliance"
      />
    </Dialog>
  );
};

export { ComplianceModal };
