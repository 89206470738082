import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type { DesignerPanel } from '@/API/Models/Wilqo.Shared.Models/ActivityAdminModels_pb';
import type { Panel } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { ProgressType } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import type { SkillSetSummary, User } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { usePanelSequenceDesign } from '@/API/Queries/activities/usePanelSequenceDesign';
import { useSkillsetAssignmentList } from '@/API/Queries/activities/useSkillsetAssignmentList';
import { useUsersBySkill } from '@/API/Queries/user/useUsersBySkill';

type SidesheetType = 'assignments' | 'history' | 'none' | 'panel-element';

interface IDesignerPanel {
  entityId: string;
  design: Array<DesignerPanel.AsObject>;
  runtime: Array<Panel.AsObject>;
  allowConcurrence: boolean;
  allowMultipleInstances: boolean;
  title: string;
  internalName: string;
  panelSequenceDesignId: string;
  progressType?: ProgressType.AsObject;
  skillId?: string;
}

interface ActivityConfiguratorContextState {
  selectedPanelElement: number | null;
  isLoadingDesign: boolean;
  selectedPanel: number;
  designerPanel: IDesignerPanel;
  updateDesignerPanel: (newProps: Partial<IDesignerPanel>) => void;
  sidesheetType: SidesheetType;
  setSidesheetType: (type: SidesheetType) => void;
  setSelectedPanel: (panel: number) => void;
  setSelectedPanelElement: (panel: number | null) => void;
  assignedSkillsets: Array<SkillSetSummary.AsObject>;
  setAssignedSkillsets: (skills: Array<SkillSetSummary.AsObject>) => void;
  availableSkillsets: Array<SkillSetSummary.AsObject>;
  setAvailableSkillsets: (skills: Array<SkillSetSummary.AsObject>) => void;
  users: Array<User.AsObject>;
  setUsers: (users: Array<User.AsObject>) => void;
}

const INITIAL_STATE: ActivityConfiguratorContextState = {
  assignedSkillsets: [],
  availableSkillsets: [],
  designerPanel: {
    allowConcurrence: false,
    allowMultipleInstances: false,
    design: [],
    entityId: '',
    internalName: '',
    panelSequenceDesignId: '',
    runtime: [],
    skillId: '',
    title: '',
  },
  isLoadingDesign: false,
  selectedPanel: 0,
  selectedPanelElement: null,
  setAssignedSkillsets: () => { },
  setAvailableSkillsets: () => { },
  setSelectedPanel: () => { },
  setSelectedPanelElement: () => { },
  setSidesheetType: () => { },
  setUsers: () => { },
  sidesheetType: 'none',
  updateDesignerPanel: () => null,
  users: [],
};

const ActivityConfiguratorContext = createContext(INITIAL_STATE);
ActivityConfiguratorContext.displayName = 'ActivityConfiguratorContext';

interface ActivityConfiguratorProviderProps {
  children: React.ReactNode;
}

const ActivityConfiguratorProvider = (props: ActivityConfiguratorProviderProps) => {
  const { children } = props;
  const { activityId = '' } = useParams();

  const [designerPanel, setDesignPanel] = useState<IDesignerPanel>(INITIAL_STATE.designerPanel);
  const [sidesheetType, setSidesheetType] = useState<SidesheetType>('none');
  const [selectedPanel, setSelectedPanel] = useState(INITIAL_STATE.selectedPanel);
  const [selectedPanelElement, setSelectedPanelElement] = useState<number | null>(INITIAL_STATE.selectedPanelElement);

  const [assignedSkillsets, setAssignedSkillsets] = useState<Array<SkillSetSummary.AsObject>>([]);
  const [availableSkillsets, setAvailableSkillsets] = useState<Array<SkillSetSummary.AsObject>>([]);
  const [users, setUsers] = useState<Array<User.AsObject>>([]);

  const { data: design, isLoading: isLoadingDesign } = usePanelSequenceDesign(activityId);

  useEffect(() => {
    if (design) {
      setDesignPanel({
        allowConcurrence: design.activityDesign?.businessProcessExtraConfig?.allowConcurrence || false,
        allowMultipleInstances: design.activityDesign?.businessProcessExtraConfig?.allowMultipleInstances || false,
        design: design.activityDesign?.panelSequenceDesign?.panelsList || [],
        entityId: design.activityDesign?.entityId || '',
        internalName: design.activityDesign?.internalName || '',
        panelSequenceDesignId: design.activityDesign?.panelSequenceDesign?.id || '',
        progressType: design.activityDesign?.progressType,
        runtime: design.runtime?.panelsList || [],
        skillId: '',
        title: design.activityDesign?.title || '',
      });
    }
  }, [design]);

  const {
    data,
  } = useSkillsetAssignmentList(activityId);

  const { data: initialUsers = [] } = useUsersBySkill('');

  useEffect(() => {
    setUsers(initialUsers);
    setAssignedSkillsets(data?.assignedSkillsetsList || []);
    setAvailableSkillsets(data?.availableSkillsetsList || []);
  }, [data, initialUsers]);

  const updateDesignerPanel = (newProps: Partial<IDesignerPanel>) => {
    setDesignPanel({
      ...designerPanel,
      ...newProps,
    });
  };

  return (
    <ActivityConfiguratorContext.Provider
      value={{
        assignedSkillsets,
        availableSkillsets,
        designerPanel,
        isLoadingDesign,
        selectedPanel,
        selectedPanelElement,
        setAssignedSkillsets,
        setAvailableSkillsets,
        setSelectedPanel,
        setSelectedPanelElement,
        setSidesheetType,
        setUsers,
        sidesheetType,
        updateDesignerPanel,
        users,
      }}
    >
      {children}
    </ActivityConfiguratorContext.Provider>
  );
};

const useActivityConfiguratorContext = () => useContext(ActivityConfiguratorContext);
export { ActivityConfiguratorProvider, useActivityConfiguratorContext };
