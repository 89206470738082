import { useQuery } from '@tanstack/react-query';

import { GetConditionTemplateQueryRequest, GetConditionTemplateQueryResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.GetConditionTemplateQueryRequest';

const useLenderCondition = (conditionId?: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery(
    [MESSAGE_NAME, bpdId, conditionId],
    async () => {
      const request = new GetConditionTemplateQueryRequest();
      request.setBusinessProcessDomainId(bpdId);
      request.setConditionId(conditionId || '');
      const response = await sendMessage<GetConditionTemplateQueryResponse.AsObject>(
        {
          msg: request,
          msgName: MESSAGE_NAME,
        },
        GetConditionTemplateQueryResponse,
      );
      return response.condition;
    },
    {
      cacheTime: 0,
      enabled: Boolean(conditionId),
    },
  );
};

export { useLenderCondition };
