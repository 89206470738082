import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { ActivitySummary } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { ActivityStatus } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { useLoanActivitiesSubscription } from '@/API/Queries/activities/useActivitiesSubscription';
import { useCurrentUser } from '@/API/Queries/user/useCurrentUser';
import { Button } from '@/Components/Atoms/Button';
import { ChipGroup } from '@/Components/Atoms/ChipGroup/ChipGroup';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Tabs } from '@/Components/Atoms/Tab';
import { Typography } from '@/Components/Atoms/Typography';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { AddActivityLoan } from '@/Components/Widgets/dialogs/AddActivity/AddActivityLoan';
import { useNavigate } from '@/Routes/NavigationContext';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';

import { DynamicActivity } from '../activities/dynamicActivity/DynamicActivity';
import { EditLoanActivitiesModal } from './EditLoanActivitiesModal';

const OPEN_ACTIVITIES_ENUMS: Array<ActivityStatus> = [ActivityStatus.UNKNOWN, ActivityStatus.CREATED, ActivityStatus.VIEWED];
const COMPLETED_ACTIVITIES_ENUMS: Array<ActivityStatus> = [ActivityStatus.CANCELLED, ActivityStatus.COMPLETE, ActivityStatus.ARCHIVED];
type TabOption = 'complete' | 'open';
enum FilterOption {
  USER,
  BORROWER,
  OTHER,
}

interface ActivityTableData {
  name: string;
  created: string;
  id: string;
}

const LoanActivities = () => {
  const { loanId = '' } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<TabOption>('open');
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(FilterOption.USER);
  const [selectedActivity, setSelectedActivity] = useState<ActivitySummary.AsObject>();
  const [dynamicActivity, setDynamicActivity] = useState<string | undefined>();

  const { activities, error, isLoading: isLoadingActivities, refetch } = useLoanActivitiesSubscription(loanId);
  const { data: user, isLoading: isLoadingUser } = useCurrentUser();

  const isLoading = isLoadingUser || isLoadingActivities;

  const userSkillsets = useMemo(() => user?.skillsetGroupList
    .reduce((acc: string[], skillsetGroup) => [
      ...acc,
      ...skillsetGroup.skillSetsIdsList,
    ], []), [user]);

  const activitiesColumns = useMemo((): Array<Column<ActivityTableData>> => [
    {
      header: 'Activity Name',
      id: 'name',
      key: {
        text: 'name',
        type: 'text',
      },
    },
    {
      header: 'Date Created',
      id: 'date',
      key: {
        text: 'created',
        type: 'text',
      },
    },
  ], []);

  const userHasSkillsetForActivity = useCallback((activity: ActivitySummary.AsObject) => activity.skillsetsIdsList
    .some((activitySkillsetId) => userSkillsets
      ?.some((userSkillsetId) => userSkillsetId === activitySkillsetId)),
  [userSkillsets]);

  const activitiesTableData = useMemo(() => {
    let filteredActivities = activities;

    if (selectedTab === 'complete') {
      filteredActivities = activities.filter((activity) => COMPLETED_ACTIVITIES_ENUMS.some((item) => activity.status === item));
    } else {
      filteredActivities = activities.filter((activity) => OPEN_ACTIVITIES_ENUMS.some((item) => activity.status === item));
    }

    switch (selectedFilter) {
      case FilterOption.USER:
        filteredActivities = filteredActivities.filter((activity) => userHasSkillsetForActivity(activity));
        break;
      case FilterOption.BORROWER:
        filteredActivities = filteredActivities.filter((activity) => activity.filters?.roleIsConsumer);
        break;
      case FilterOption.OTHER:
        filteredActivities = filteredActivities.filter((activity) => activity.filters?.roleIsLender && !userHasSkillsetForActivity(activity));
        break;
      default:
        break;
    }

    return filteredActivities.map((activity) => ({
      created: getDateFromTimestamp(activity.createdTimestamp)?.toDateString() || '',
      id: activity.entityId,
      name: activity.title,
    }));
  }, [activities, selectedTab, selectedFilter, userHasSkillsetForActivity]);

  const navigateToActivity = (tableItem: ActivityTableData) => {
    const activity = activities.find((activity) => activity.entityId === tableItem.id);
    if (activity && !activity.filters?.statusIsComplete) {
      const { activityType, entityId, readonly, title } = activity;
      if (readonly) return;
      window.history.pushState(entityId, '', null);
      switch (activityType) {
        case 'checklist':
          navigate(`/activity/checklist/${entityId}/checklist/incomplete`);
          break;
        case 'validation-checklist':
          navigate(`/activity/validation/${entityId}/checklist/incomplete`);
          break;
        case 'mortgage-dynamic-data':
          setDynamicActivity(entityId);
          break;
        default:
          navigate(`/activity/${activityType}/${entityId}`, { state: title });
          break;
      }
    }
  };

  const handleClickEdit = (activity: ActivityTableData) => {
    const currentActivity = activities.filter((a) => a.entityId === activity.id)[0];
    setSelectedActivity(currentActivity);
    setIsEditOpen(true);
  };

  const renderActivityList = () => (
    <div className="flex flex-col">
      <div className="px-12 pt-2">
        <ChipGroup
          chips={[
            { id: '0', label: 'Your activities', selected: selectedFilter === FilterOption.USER },
            { id: '1', label: 'Borrower activities', selected: selectedFilter === FilterOption.BORROWER },
            { id: '2', label: 'Other activities', selected: selectedFilter === FilterOption.OTHER },
          ]}
          onSelectItem={(item) => setSelectedFilter(Number(item.id))}
          selected={[selectedFilter]}
        />
      </div>
      <Tabs
        stretch
        tabsData={[
          {
            active: selectedTab === 'open',
            label: 'Open',
            onClick: () => setSelectedTab('open'),
          },
          {
            active: selectedTab !== 'open',
            label: 'Complete',
            onClick: () => setSelectedTab('complete'),
          },
        ]}
      />
      <div className="px-12">
        {
          !isLoading && error && (
            <ContingencyMessage
              action={{
                label: 'Reload',
                onClick: refetch,
              }}
              icon="Error"
              subtitle="Sorry, we could not load the activities"
              title="Something went wrong"
            />
          )
        }
        {
          !error && !isLoading && activitiesTableData.length === 0 ? (
            <ContingencyMessage subtitle={error || `You have no ${selectedTab} activities at this time.`} title="No results" />
          ) : (
            <Table
              cardProps={{ headerProps: { title: 'Activities' } }}
              columns={activitiesColumns}
              data={activitiesTableData}
              hoverActions={[
                {
                  label: 'Edit',
                  onClick: handleClickEdit,
                },
              ]}
              isLoading={isLoading}
              onClickRow={navigateToActivity}
              width="stretch"
            />
          )
        }
      </div>
    </div>
  );

  return (
    <div>
      <div className="p-12 w-full flex flex-col md:flex-row items-start md:items-center justify-between">
        <Typography variant="display4">Activities</Typography>
        <Button label="Add Activity" onClick={() => setIsAddActivityOpen(true)} />
      </div>
      <div>
        {renderActivityList()}
      </div>
      {isEditOpen && selectedActivity && (
        <EditLoanActivitiesModal
          closeModal={() => {
            setIsEditOpen(false);
            refetch();
          }}
          entityId={selectedActivity.entityId}
          entityVersion={selectedActivity.entityVersion}
        />
      )}
      <AddActivityLoan
        onClose={() => setIsAddActivityOpen(false)}
        open={isAddActivityOpen}
      />
      <DynamicActivity
        id={dynamicActivity || ''}
        onClose={() => setDynamicActivity(undefined)}
        open={Boolean(dynamicActivity)}
      />
    </div>
  );
};

export { LoanActivities };
