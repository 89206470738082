import currency from 'currency.js';

import { ConvertProtoDecimalToInstance, ConvertProtoDecimalToNumber } from './protoDecimalConversion';

enum ValueType {
  DEFAULT = 0,
  CURRENCY = 1,
  PERCENTAGE = 2,
  DATE = 3,
}

const numberFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

const convertProtoToCurrency = (value: any) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return numberFormatter.format(Number(value));
  }

  return numberFormatter.format(
    ConvertProtoDecimalToNumber(
      ConvertProtoDecimalToInstance(value),
    ),
  );
};

const convertProtoToPercentage = (value: any) => {
  if (typeof value === 'string') {
    return `${value}%`;
  }

  const result = ConvertProtoDecimalToNumber(
    ConvertProtoDecimalToInstance(value),
  );
  return `${result}%`;
};

const convertProtoToString = (value: any) => {
  const result = ConvertProtoDecimalToNumber(
    ConvertProtoDecimalToInstance(value),
  );
  return result.toString();
};

const convertByType = (value: string | undefined, type: number | undefined) => {
  switch (type) {
    case ValueType.DEFAULT:
      return value;
    case ValueType.CURRENCY:
      return convertProtoToCurrency(value || 0);
    case ValueType.PERCENTAGE:
      return convertProtoToPercentage(value || 0);
    case ValueType.DATE:
      return value;
    default:
      return value;
  }
};

const cleanAndConvertCurrency = (value: any) => currency(convertProtoToCurrency(value || 0).replace(/[^0-9.-]+/g, ''));

export {
  cleanAndConvertCurrency,
  convertByType,
  convertProtoToCurrency,
  convertProtoToPercentage,
  convertProtoToString,
  numberFormatter,
};
