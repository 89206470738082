import { useMutation } from '@tanstack/react-query';

import { ArchiveBusinessProcessTemplatesCommandRequest, ArchiveBusinessProcessTemplatesCommandResponse } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.business_process.ArchiveBusinessProcessTemplatesCommandRequest';

export const useArchiveBPTemplate = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation(async (ids: string[]) => {
    const request = new ArchiveBusinessProcessTemplatesCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setBusinessProcessTemplateIdsList(ids);
    request.setInstitutionId(institutionId);
    return sendMessage<ArchiveBusinessProcessTemplatesCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      ArchiveBusinessProcessTemplatesCommandResponse,
    );
  });
};
