import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

export type InlineTextVariant = 'font-four font-semibold text-2xl leading-9' | 'text-on-surface-active text-sm';

export interface InlineInputTextEditorProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  textVariant: InlineTextVariant;
}

const InlineInputTextEditor = (props: InlineInputTextEditorProps) => {
  const { textVariant, ...inputProps } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [textHeight, setTextHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTextHeight(containerRef.current?.clientHeight || 0);
  }, [isEditing]);

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (inputProps.onBlur) inputProps.onBlur(event);
    setIsEditing(false);
  };

  const handleOnClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [isEditing]);

  return (
    <div
      ref={containerRef}
      className="flex"
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      role="button"
      tabIndex={0}
    >
      { isEditing ? (
        <textarea
          className={`${textVariant} border-0 border-b-[2px] border-b-primary
           h-[${textHeight}px] outline-none py-[12px] px-2 w-full`}
          {...inputProps}
          ref={inputRef}
          onBlur={handleBlur}
        />
      ) : (
        <span
          className={clsx(textVariant, { 'text-on-surface-inactive': !inputProps.value })}
        >
          {inputProps.value || inputProps.placeholder}
        </span>
      )}
    </div>
  );
};

export { InlineInputTextEditor };
