import { useMemo } from 'react';

import { useDynamicForm } from './DynamicFormContext';

export const useFieldOverride = (id: string) => {
  const { overrideFields } = useDynamicForm();
  return useMemo(() => overrideFields[id], [id, overrideFields]);
};

export const useAsyncFieldOverride = (id: string) => {
  const { asyncOverrideFields } = useDynamicForm();
  return useMemo(() => asyncOverrideFields[id], [id, asyncOverrideFields]);
};
