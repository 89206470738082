import { LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { Button } from '@/Components/Atoms/Button';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';

import { useWidgetContext } from '../content/WidgetContext';

export const EditWidgetsAction = () => {
  const { closeAction, currentAction, toggleAction } = useDynamicContext();
  const { savingWidgetIds, toggleEditAllWidgets } = useWidgetContext();
  const { imperativeSubmit } = useDynamicForm();

  const handleClickEdit = () => {
    toggleAction(LoanPageActionType.LOAN_PAGE_ACTION_TYPE_EDIT_MODE);
    toggleEditAllWidgets(true);
  };

  const handleCancelEditMode = () => {
    closeAction();
    toggleEditAllWidgets(false);
  };

  const handleImperativeSubmit = (event?: any) => {
    imperativeSubmit(handleCancelEditMode, undefined, event.currentTarget.id);
  };

  return (
    currentAction?.type === LoanPageActionType.LOAN_PAGE_ACTION_TYPE_EDIT_MODE ? (
      <div className="flex flex-row gap-2">
        <Button
          id="globalSave"
          isLoading={savingWidgetIds.length > 0}
          label="save"
          onClick={handleImperativeSubmit}
          type="button"
          variant="primary"
        />
        <Button
          label="cancel"
          onClick={handleCancelEditMode}
          type="button"
          variant="secondary"
        />
      </div>
    ) : (
      <Button
        label="Edit Party"
        onClick={handleClickEdit}
        variant="primary"
      />
    )
  );
};
