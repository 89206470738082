import { useMemo, useState } from 'react';

import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useNavigate } from '@/Routes/NavigationContext';

import { CreateUser } from '../createUser/createUser';

interface TableItemData {
  id: string;
  skillsetGroupList: string;
  name: string;
}

const UserList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleUpdateClick = (item: TableItemData, route: string) => {
    navigate(`/admin/user/${item.id}/${route}`);
  };

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', 'displayName'],
    ['Skillset Groups', { text: 'skillsetGroupList.displayName', type: 'text' }],
    ['Party Role', { text: 'partyRoleTypesList.name', type: 'text' }],
  ], []);

  return (
    <>
      <TableBuilder
        allowSearch
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create New', onClick: () => setModalOpen(true) }],
            title: 'All Users',
          },
        }}
        columns={columns}
        dataType={TableBuilderDataTypeEnum.USERS}
        filterConfig={{ key: 'loanPartyRoleTypesList', noItemsLabel: 'Party Roles' }}
        hoverActions={[
          { label: 'Update skillset groups', onClick: (item) => handleUpdateClick(item, 'skillsetGroups') },
        ]}
        onClickRow={(item) => handleUpdateClick(item, 'overview')}
        rowActionType="button"
      />
      {modalOpen && <CreateUser closeModal={() => setModalOpen(false)} />}
    </>
  );
};

export { UserList };
