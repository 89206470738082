// source: Wilqo_API_Activity_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_Configuration_pb = require('./Wilqo.Shared.Models/Configuration_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Configuration_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Validations_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb);
var Wilqo_API_Activity_Models_pb = require('./Wilqo_API_Activity_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Models_pb);
goog.exportSymbol('proto.wilqo.api.activity.AddAditionalFieldsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AddAditionalFieldsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CompleteChecklistCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CompleteChecklistCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ExternalActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitChecklistActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitChecklistActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitExternalActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitializeMongoDbCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InitializeMongoDbCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PutActivityStatusCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PutActivityStatusCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubmitChecklistCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubmitChecklistCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubscribeActivitiesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.SubscriptionSettingsCase', null, global);
goog.exportSymbol('proto.wilqo.api.activity.SubscribeActivitiesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpdateActivityStatusCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpdateActivityStatusCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PutActivityStatusCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PutActivityStatusCommandRequest.displayName = 'proto.wilqo.api.activity.PutActivityStatusCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PutActivityStatusCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PutActivityStatusCommandResponse.displayName = 'proto.wilqo.api.activity.PutActivityStatusCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.displayName = 'proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.displayName = 'proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.displayName = 'proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.displayName = 'proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.InitActivityCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.InitActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitActivityCommandRequest.displayName = 'proto.wilqo.api.activity.InitActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitActivityCommandResponse.displayName = 'proto.wilqo.api.activity.InitActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.displayName = 'proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.displayName = 'proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.InitChecklistActivityCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.InitChecklistActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitChecklistActivityCommandRequest.displayName = 'proto.wilqo.api.activity.InitChecklistActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitChecklistActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitChecklistActivityCommandResponse.displayName = 'proto.wilqo.api.activity.InitChecklistActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.displayName = 'proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.displayName = 'proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.displayName = 'proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.displayName = 'proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.SubmitChecklistCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SubmitChecklistCommandRequest.displayName = 'proto.wilqo.api.activity.SubmitChecklistCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.SubmitChecklistCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SubmitChecklistCommandResponse.displayName = 'proto.wilqo.api.activity.SubmitChecklistCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.displayName = 'proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.displayName = 'proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CompleteChecklistCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CompleteChecklistCommandRequest.displayName = 'proto.wilqo.api.activity.CompleteChecklistCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CompleteChecklistCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CompleteChecklistCommandResponse.displayName = 'proto.wilqo.api.activity.CompleteChecklistCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.AddAditionalFieldsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.displayName = 'proto.wilqo.api.activity.AddAditionalFieldsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.AddAditionalFieldsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.displayName = 'proto.wilqo.api.activity.AddAditionalFieldsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.displayName = 'proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.displayName = 'proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.displayName = 'proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.displayName = 'proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpdateActivityStatusCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.displayName = 'proto.wilqo.api.activity.UpdateActivityStatusCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpdateActivityStatusCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.displayName = 'proto.wilqo.api.activity.UpdateActivityStatusCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.displayName = 'proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.displayName = 'proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.InitExternalActivityCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.InitExternalActivityCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitExternalActivityCommandRequest.displayName = 'proto.wilqo.api.activity.InitExternalActivityCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ExternalActivityCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExternalActivityCommandResponse.displayName = 'proto.wilqo.api.activity.ExternalActivityCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitializeMongoDbCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitializeMongoDbCommandRequest.displayName = 'proto.wilqo.api.activity.InitializeMongoDbCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InitializeMongoDbCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InitializeMongoDbCommandResponse.displayName = 'proto.wilqo.api.activity.InitializeMongoDbCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.oneofGroups_);
};
goog.inherits(proto.wilqo.api.activity.SubscribeActivitiesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.displayName = 'proto.wilqo.api.activity.SubscribeActivitiesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.SubscribeActivitiesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.displayName = 'proto.wilqo.api.activity.SubscribeActivitiesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.displayName = 'proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.displayName = 'proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.displayName = 'proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.displayName = 'proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PutActivityStatusCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activityVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orgId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PutActivityStatusCommandRequest;
  return proto.wilqo.api.activity.PutActivityStatusCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PutActivityStatusCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getActivityVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getOrgId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActivityStatus status = 2;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 activity_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.getActivityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.setActivityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string org_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.getOrgId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandRequest.prototype.setOrgId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PutActivityStatusCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandResponse}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PutActivityStatusCommandResponse;
  return proto.wilqo.api.activity.PutActivityStatusCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandResponse}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PutActivityStatusCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} returns this
*/
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivitySummary activity = 3;
 * @return {?proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} returns this
*/
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PutActivityStatusCommandResponse} returns this
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PutActivityStatusCommandResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    panelSequenceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest;
  return proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceId(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPanelSequenceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} returns this
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string panel_sequence_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.getPanelSequenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} returns this
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.setPanelSequenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated wilqo.shared.models.PanelElementResponse panel_elements = 5;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 5));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} returns this
*/
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} returns this
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};


/**
 * optional string business_process_domain_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest} returns this
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse;
  return proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse} returns this
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse} returns this
*/
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse} returns this
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PutPanelSequenceResponseCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checklistItemId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest;
  return proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistItemId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChecklistItemId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} returns this
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} returns this
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string checklist_item_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.getChecklistItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} returns this
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.setChecklistItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.PanelElementResponse panel_elements = 3;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} returns this
*/
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest} returns this
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandRequest.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityValidationChecklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse;
  return proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivityValidationChecklist;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityValidationChecklist.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivityValidationChecklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} returns this
*/
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} returns this
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityValidationChecklist activity = 2;
 * @return {?proto.wilqo.api.activity.ActivityValidationChecklist}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityValidationChecklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityValidationChecklist, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityValidationChecklist|undefined} value
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} returns this
*/
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse} returns this
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PutValidationChecklistActivityProgressCommandResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.InitActivityCommandRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityTemplateVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    variablesMap: (f = msg.getVariablesMap()) ? f.toObject(includeInstance, proto.wilqo.api.activity.TemplateVariable.toObject) : [],
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    scopeReferencesList: jspb.Message.toObjectList(msg.getScopeReferencesList(),
    Wilqo_API_Activity_Models_pb.ScopeReference.toObject, includeInstance),
    source: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitActivityCommandRequest;
  return proto.wilqo.api.activity.InitActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityTemplateVersion(value);
      break;
    case 4:
      var value = msg.getVariablesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.api.activity.TemplateVariable.deserializeBinaryFromReader, "", new proto.wilqo.api.activity.TemplateVariable());
         });
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 10:
      var value = new Wilqo_API_Activity_Models_pb.ScopeReference;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ScopeReference.deserializeBinaryFromReader);
      msg.addScopeReferences(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVariablesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.api.activity.TemplateVariable.serializeBinaryToWriter);
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScopeReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_API_Activity_Models_pb.ScopeReference.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 activity_template_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getActivityTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setActivityTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, TemplateVariable> variables = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.api.activity.TemplateVariable>}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getVariablesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.api.activity.TemplateVariable>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.wilqo.api.activity.TemplateVariable));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.clearVariablesMap = function() {
  this.getVariablesMap().clear();
  return this;
};


/**
 * optional string business_process_domain_id = 9;
 * @return {string}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_process_id = 8;
 * @return {string}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ScopeReference scope_references = 10;
 * @return {!Array<!proto.wilqo.api.activity.ScopeReference>}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getScopeReferencesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ScopeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ScopeReference, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ScopeReference>} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setScopeReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.addScopeReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.activity.ScopeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.clearScopeReferencesList = function() {
  return this.setScopeReferencesList([]);
};


/**
 * optional string source = 11;
 * @return {string}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.setSource = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.activity.InitActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.clearSource = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitActivityCommandRequest.prototype.hasSource = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitActivityCommandResponse}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitActivityCommandResponse;
  return proto.wilqo.api.activity.InitActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitActivityCommandResponse}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivitySummary activity = 3;
 * @return {?proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.InitActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitActivityCommandResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityTemplateVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    variablesMap: (f = msg.getVariablesMap()) ? f.toObject(includeInstance, proto.wilqo.api.activity.TemplateVariable.toObject) : [],
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    scopeReferencesList: jspb.Message.toObjectList(msg.getScopeReferencesList(),
    Wilqo_API_Activity_Models_pb.ScopeReference.toObject, includeInstance),
    expirationInformationsList: jspb.Message.toObjectList(msg.getExpirationInformationsList(),
    Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.toObject, includeInstance),
    source: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest;
  return proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityTemplateVersion(value);
      break;
    case 4:
      var value = msg.getVariablesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.api.activity.TemplateVariable.deserializeBinaryFromReader, "", new proto.wilqo.api.activity.TemplateVariable());
         });
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 10:
      var value = new Wilqo_API_Activity_Models_pb.ScopeReference;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ScopeReference.deserializeBinaryFromReader);
      msg.addScopeReferences(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationInformation;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.deserializeBinaryFromReader);
      msg.addExpirationInformations(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVariablesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.api.activity.TemplateVariable.serializeBinaryToWriter);
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScopeReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_API_Activity_Models_pb.ScopeReference.serializeBinaryToWriter
    );
  }
  f = message.getExpirationInformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 activity_template_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getActivityTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setActivityTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, TemplateVariable> variables = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.api.activity.TemplateVariable>}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getVariablesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.api.activity.TemplateVariable>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.wilqo.api.activity.TemplateVariable));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.clearVariablesMap = function() {
  this.getVariablesMap().clear();
  return this;
};


/**
 * optional string business_process_domain_id = 9;
 * @return {string}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_process_id = 8;
 * @return {string}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ScopeReference scope_references = 10;
 * @return {!Array<!proto.wilqo.api.activity.ScopeReference>}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getScopeReferencesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ScopeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ScopeReference, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ScopeReference>} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setScopeReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.addScopeReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.activity.ScopeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.clearScopeReferencesList = function() {
  return this.setScopeReferencesList([]);
};


/**
 * repeated wilqo.shared.models.ExpirationInformation expiration_informations = 11;
 * @return {!Array<!proto.wilqo.shared.models.ExpirationInformation>}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getExpirationInformationsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ExpirationInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationInformation, 11));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ExpirationInformation>} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setExpirationInformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.shared.models.ExpirationInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.addExpirationInformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.shared.models.ExpirationInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.clearExpirationInformationsList = function() {
  return this.setExpirationInformationsList([]);
};


/**
 * optional string source = 12;
 * @return {string}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.setSource = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.clearSource = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandRequest.prototype.hasSource = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityPanelSequence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse;
  return proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivityPanelSequence;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityPanelSequence.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivityPanelSequence.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityPanelSequence activity = 3;
 * @return {?proto.wilqo.api.activity.ActivityPanelSequence}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityPanelSequence, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityPanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitPanelSequenceActivityCommandResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitChecklistActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityTemplateVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    variablesMap: (f = msg.getVariablesMap()) ? f.toObject(includeInstance, proto.wilqo.api.activity.TemplateVariable.toObject) : [],
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    scopeReferencesList: jspb.Message.toObjectList(msg.getScopeReferencesList(),
    Wilqo_API_Activity_Models_pb.ScopeReference.toObject, includeInstance),
    expirationInformationsList: jspb.Message.toObjectList(msg.getExpirationInformationsList(),
    Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.toObject, includeInstance),
    source: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitChecklistActivityCommandRequest;
  return proto.wilqo.api.activity.InitChecklistActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityTemplateVersion(value);
      break;
    case 5:
      var value = msg.getVariablesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.api.activity.TemplateVariable.deserializeBinaryFromReader, "", new proto.wilqo.api.activity.TemplateVariable());
         });
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 10:
      var value = new Wilqo_API_Activity_Models_pb.ScopeReference;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ScopeReference.deserializeBinaryFromReader);
      msg.addScopeReferences(value);
      break;
    case 11:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationInformation;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.deserializeBinaryFromReader);
      msg.addExpirationInformations(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitChecklistActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVariablesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.api.activity.TemplateVariable.serializeBinaryToWriter);
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScopeReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_API_Activity_Models_pb.ScopeReference.serializeBinaryToWriter
    );
  }
  f = message.getExpirationInformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_template_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 activity_template_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getActivityTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setActivityTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, TemplateVariable> variables = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.api.activity.TemplateVariable>}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getVariablesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.api.activity.TemplateVariable>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.wilqo.api.activity.TemplateVariable));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.clearVariablesMap = function() {
  this.getVariablesMap().clear();
  return this;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string business_process_id = 8;
 * @return {string}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ScopeReference scope_references = 10;
 * @return {!Array<!proto.wilqo.api.activity.ScopeReference>}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getScopeReferencesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ScopeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ScopeReference, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ScopeReference>} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setScopeReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.addScopeReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.activity.ScopeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.clearScopeReferencesList = function() {
  return this.setScopeReferencesList([]);
};


/**
 * repeated wilqo.shared.models.ExpirationInformation expiration_informations = 11;
 * @return {!Array<!proto.wilqo.shared.models.ExpirationInformation>}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getExpirationInformationsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ExpirationInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationInformation, 11));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ExpirationInformation>} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setExpirationInformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.wilqo.shared.models.ExpirationInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.addExpirationInformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.wilqo.shared.models.ExpirationInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.clearExpirationInformationsList = function() {
  return this.setExpirationInformationsList([]);
};


/**
 * optional string source = 12;
 * @return {string}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.setSource = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.clearSource = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandRequest.prototype.hasSource = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitChecklistActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    checklist: (f = msg.getChecklist()) && Wilqo_API_Activity_Models_pb.ActivityChecklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitChecklistActivityCommandResponse;
  return proto.wilqo.api.activity.InitChecklistActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivityChecklist;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityChecklist.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitChecklistActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivityChecklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivityChecklist checklist = 3;
 * @return {?proto.wilqo.api.activity.ActivityChecklist}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.getChecklist = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityChecklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityChecklist, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityChecklist|undefined} value
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitChecklistActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitChecklistActivityCommandResponse.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.repeatedFields_ = [10,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activitySkillsetId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    validationConfigsList: jspb.Message.toObjectList(msg.getValidationConfigsList(),
    Wilqo_API_Activity_Models_pb.ValidationConfig.toObject, includeInstance),
    validationResultsMap: (f = msg.getValidationResultsMap()) ? f.toObject(includeInstance, proto.wilqo.shared.rulesengine.ValidationResult.toObject) : [],
    additionalActivityTagsMap: (f = msg.getAdditionalActivityTagsMap()) ? f.toObject(includeInstance, undefined) : [],
    additionalActivityFieldsList: jspb.Message.toObjectList(msg.getAdditionalActivityFieldsList(),
    Wilqo_API_Activity_Models_pb.ActivitySummaryField.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest;
  return proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivitySkillsetId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 10:
      var value = new Wilqo_API_Activity_Models_pb.ValidationConfig;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ValidationConfig.deserializeBinaryFromReader);
      msg.addValidationConfigs(value);
      break;
    case 11:
      var value = msg.getValidationResultsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.shared.rulesengine.ValidationResult.deserializeBinaryFromReader, "", new proto.wilqo.shared.rulesengine.ValidationResult());
         });
      break;
    case 12:
      var value = msg.getAdditionalActivityTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 13:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummaryField;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummaryField.deserializeBinaryFromReader);
      msg.addAdditionalActivityFields(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivitySkillsetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValidationConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_API_Activity_Models_pb.ValidationConfig.serializeBinaryToWriter
    );
  }
  f = message.getValidationResultsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.shared.rulesengine.ValidationResult.serializeBinaryToWriter);
  }
  f = message.getAdditionalActivityTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(12, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getAdditionalActivityFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummaryField.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activity_skillset_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getActivitySkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setActivitySkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_template_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated ValidationConfig validation_configs = 10;
 * @return {!Array<!proto.wilqo.api.activity.ValidationConfig>}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getValidationConfigsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ValidationConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ValidationConfig, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ValidationConfig>} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setValidationConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.activity.ValidationConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ValidationConfig}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.addValidationConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.activity.ValidationConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.clearValidationConfigsList = function() {
  return this.setValidationConfigsList([]);
};


/**
 * map<string, wilqo.shared.rulesengine.ValidationResult> validation_results = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.shared.rulesengine.ValidationResult>}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getValidationResultsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.shared.rulesengine.ValidationResult>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      proto.wilqo.shared.rulesengine.ValidationResult));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.clearValidationResultsMap = function() {
  this.getValidationResultsMap().clear();
  return this;
};


/**
 * map<string, string> additional_activity_tags = 12;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getAdditionalActivityTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 12, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.clearAdditionalActivityTagsMap = function() {
  this.getAdditionalActivityTagsMap().clear();
  return this;
};


/**
 * repeated ActivitySummaryField additional_activity_fields = 13;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummaryField>}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getAdditionalActivityFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummaryField, 13));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummaryField>} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setAdditionalActivityFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.addAdditionalActivityFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.wilqo.api.activity.ActivitySummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.clearAdditionalActivityFieldsList = function() {
  return this.setAdditionalActivityFieldsList([]);
};


/**
 * optional string business_process_domain_id = 14;
 * @return {string}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivitySummary.toObject(includeInstance, f),
    validationChecklist: (f = msg.getValidationChecklist()) && Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse;
  return proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist.deserializeBinaryFromReader);
      msg.setValidationChecklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
  f = message.getValidationChecklist();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivitySummary activity = 2;
 * @return {?proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.ValidationChecklist validation_checklist = 3;
 * @return {?proto.wilqo.shared.models.ValidationChecklist}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.getValidationChecklist = function() {
  return /** @type{?proto.wilqo.shared.models.ValidationChecklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist, 3));
};


/**
 * @param {?proto.wilqo.shared.models.ValidationChecklist|undefined} value
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.setValidationChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.clearValidationChecklist = function() {
  return this.setValidationChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitValidationChecklistActivityCommandResponse.prototype.hasValidationChecklist = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checklistId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest;
  return proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChecklistId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string checklist_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.getChecklistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.setChecklistId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string item_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandRequest.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    checklist: (f = msg.getChecklist()) && Wilqo_Shared_Models_ActivityModels_pb.Checklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse;
  return proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Checklist;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Checklist.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Checklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} returns this
*/
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} returns this
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.Checklist checklist = 3;
 * @return {?proto.wilqo.shared.models.Checklist}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.getChecklist = function() {
  return /** @type{?proto.wilqo.shared.models.Checklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Checklist, 3));
};


/**
 * @param {?proto.wilqo.shared.models.Checklist|undefined} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} returns this
*/
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse} returns this
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CompleteChecklistItemStatusCommandResponse.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SubmitChecklistCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checklistId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checklistVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandRequest}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SubmitChecklistCommandRequest;
  return proto.wilqo.api.activity.SubmitChecklistCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandRequest}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChecklistVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SubmitChecklistCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getChecklistId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChecklistVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string checklist_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.getChecklistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.setChecklistId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 checklist_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.getChecklistVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitChecklistCommandRequest.prototype.setChecklistVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SubmitChecklistCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    checklist: (f = msg.getChecklist()) && Wilqo_Shared_Models_ActivityModels_pb.Checklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandResponse}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SubmitChecklistCommandResponse;
  return proto.wilqo.api.activity.SubmitChecklistCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandResponse}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Checklist;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Checklist.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SubmitChecklistCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Checklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} returns this
*/
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} returns this
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.Checklist checklist = 3;
 * @return {?proto.wilqo.shared.models.Checklist}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.getChecklist = function() {
  return /** @type{?proto.wilqo.shared.models.Checklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Checklist, 3));
};


/**
 * @param {?proto.wilqo.shared.models.Checklist|undefined} value
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} returns this
*/
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubmitChecklistCommandResponse} returns this
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubmitChecklistCommandResponse.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    checklistVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activityVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest;
  return proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChecklistVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChecklistVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getActivityVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 checklist_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.getChecklistVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.setChecklistVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 activity_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.getActivityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.setActivityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse;
  return proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse} returns this
*/
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse} returns this
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubmitValidationChecklistCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CompleteChecklistCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    checklistId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    checklistVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandRequest}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CompleteChecklistCommandRequest;
  return proto.wilqo.api.activity.CompleteChecklistCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandRequest}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChecklistId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChecklistVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CompleteChecklistCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getChecklistId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChecklistVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string checklist_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.getChecklistId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.setChecklistId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 checklist_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.getChecklistVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.setChecklistVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandRequest} returns this
 */
proto.wilqo.api.activity.CompleteChecklistCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CompleteChecklistCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    checklist: (f = msg.getChecklist()) && Wilqo_Shared_Models_ActivityModels_pb.Checklist.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandResponse}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CompleteChecklistCommandResponse;
  return proto.wilqo.api.activity.CompleteChecklistCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandResponse}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Checklist;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Checklist.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CompleteChecklistCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Checklist.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} returns this
*/
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} returns this
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.Checklist checklist = 3;
 * @return {?proto.wilqo.shared.models.Checklist}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.getChecklist = function() {
  return /** @type{?proto.wilqo.shared.models.Checklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Checklist, 3));
};


/**
 * @param {?proto.wilqo.shared.models.Checklist|undefined} value
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} returns this
*/
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CompleteChecklistCommandResponse} returns this
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CompleteChecklistCommandResponse.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    additionalFieldsList: jspb.Message.toObjectList(msg.getAdditionalFieldsList(),
    Wilqo_API_Activity_Models_pb.ActivitySummaryField.toObject, includeInstance),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AddAditionalFieldsCommandRequest;
  return proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummaryField;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummaryField.deserializeBinaryFromReader);
      msg.addAdditionalFields(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdditionalFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummaryField.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 activity_version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.getActivityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} returns this
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.setActivityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} returns this
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ActivitySummaryField additional_fields = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummaryField>}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.getAdditionalFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummaryField, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummaryField>} value
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} returns this
*/
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.setAdditionalFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.addAdditionalFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivitySummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} returns this
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.clearAdditionalFieldsList = function() {
  return this.setAdditionalFieldsList([]);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandRequest} returns this
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AddAditionalFieldsCommandResponse;
  return proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse} returns this
*/
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AddAditionalFieldsCommandResponse} returns this
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AddAditionalFieldsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stashPanelResponsesList: jspb.Message.toObjectList(msg.getStashPanelResponsesList(),
    Wilqo_API_Activity_Models_pb.StashPanelResponseSummary.toObject, includeInstance),
    activityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest;
  return proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.StashPanelResponseSummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.StashPanelResponseSummary.deserializeBinaryFromReader);
      msg.addStashPanelResponses(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStashPanelResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.StashPanelResponseSummary.serializeBinaryToWriter
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated StashPanelResponseSummary stash_panel_responses = 2;
 * @return {!Array<!proto.wilqo.api.activity.StashPanelResponseSummary>}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getStashPanelResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.StashPanelResponseSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.StashPanelResponseSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.StashPanelResponseSummary>} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
*/
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setStashPanelResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.StashPanelResponseSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.addStashPanelResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.StashPanelResponseSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.clearStashPanelResponsesList = function() {
  return this.setStashPanelResponsesList([]);
};


/**
 * optional string activity_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string template_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string business_process_domain_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse;
  return proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse} returns this
*/
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse} returns this
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpsertDocumentDataCollectionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    activityInstanceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activityTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activityInternalName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    panelSequenceId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    panelHeaderText: jspb.Message.getFieldWithDefault(msg, 8, ""),
    panelSummaryText: jspb.Message.getFieldWithDefault(msg, 9, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest;
  return proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityInstanceId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityInternalName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSequenceId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelHeaderText(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelSummaryText(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getActivityInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getActivityTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivityInternalName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPanelSequenceId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPanelHeaderText();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPanelSummaryText();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string activity_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getActivityInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setActivityInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 activity_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getActivityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setActivityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string activity_title = 4;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getActivityTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setActivityTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activity_internal_name = 5;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getActivityInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setActivityInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string panel_sequence_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getPanelSequenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setPanelSequenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string panel_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string panel_header_text = 8;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getPanelHeaderText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setPanelHeaderText = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string panel_summary_text = 9;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getPanelSummaryText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setPanelSummaryText = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string business_process_domain_id = 10;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse;
  return proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse} returns this
*/
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse} returns this
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpdateActivityPanelInstanceDetailsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpdateActivityStatusCommandRequest;
  return proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activity_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ActivityStatus status = 4;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandRequest} returns this
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpdateActivityStatusCommandResponse;
  return proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} returns this
*/
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} returns this
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ActivitySummary activity = 2;
 * @return {?proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} returns this
*/
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpdateActivityStatusCommandResponse} returns this
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpdateActivityStatusCommandResponse.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessesIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest;
  return proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addBusinessProcessesIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} returns this
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} returns this
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string business_processes_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.getBusinessProcessesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} returns this
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.setBusinessProcessesIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} returns this
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.addBusinessProcessesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest} returns this
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandRequest.prototype.clearBusinessProcessesIdsList = function() {
  return this.setBusinessProcessesIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse;
  return proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse} returns this
*/
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse} returns this
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.CancelActivitiesByBusinessProcessesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.repeatedFields_ = [3,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitExternalActivityCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    internalName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    activityType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    additionalFieldsList: jspb.Message.toObjectList(msg.getAdditionalFieldsList(),
    Wilqo_API_Activity_Models_pb.ActivitySummaryField.toObject, includeInstance),
    skillSetsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    state: msg.getState_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitExternalActivityCommandRequest;
  return proto.wilqo.api.activity.InitExternalActivityCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityType(value);
      break;
    case 9:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummaryField;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummaryField.deserializeBinaryFromReader);
      msg.addAdditionalFields(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSets(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitExternalActivityCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getActivityType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdditionalFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummaryField.serializeBinaryToWriter
    );
  }
  f = message.getSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getState_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      12,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string organization_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string internal_name = 4;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string applies_to_entity_id = 6;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string help_text = 7;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string activity_type = 8;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getActivityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated ActivitySummaryField additional_fields = 9;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummaryField>}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getAdditionalFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummaryField, 9));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummaryField>} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
*/
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setAdditionalFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.addAdditionalFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.wilqo.api.activity.ActivitySummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.clearAdditionalFieldsList = function() {
  return this.setAdditionalFieldsList([]);
};


/**
 * repeated string skill_sets = 10;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getSkillSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setSkillSetsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.addSkillSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.clearSkillSetsList = function() {
  return this.setSkillSetsList([]);
};


/**
 * optional bytes state = 12;
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes state = 12;
 * This is a type-conversion wrapper around `getState()`
 * @return {string}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getState_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getState()));
};


/**
 * optional bytes state = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getState()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.getState_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getState()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.activity.InitExternalActivityCommandRequest} returns this
 */
proto.wilqo.api.activity.InitExternalActivityCommandRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3BytesField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExternalActivityCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExternalActivityCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    item: (f = msg.getItem()) && Wilqo_API_Activity_Models_pb.ExternalActivity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExternalActivityCommandResponse}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExternalActivityCommandResponse;
  return proto.wilqo.api.activity.ExternalActivityCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExternalActivityCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExternalActivityCommandResponse}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ExternalActivity;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ExternalActivity.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExternalActivityCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExternalActivityCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ExternalActivity.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.ExternalActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExternalActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExternalActivity item = 2;
 * @return {?proto.wilqo.api.activity.ExternalActivity}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.getItem = function() {
  return /** @type{?proto.wilqo.api.activity.ExternalActivity} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ExternalActivity, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ExternalActivity|undefined} value
 * @return {!proto.wilqo.api.activity.ExternalActivityCommandResponse} returns this
*/
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExternalActivityCommandResponse} returns this
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivityCommandResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitializeMongoDbCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitializeMongoDbCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandRequest}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitializeMongoDbCommandRequest;
  return proto.wilqo.api.activity.InitializeMongoDbCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitializeMongoDbCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandRequest}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitializeMongoDbCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitializeMongoDbCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandRequest} returns this
 */
proto.wilqo.api.activity.InitializeMongoDbCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InitializeMongoDbCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InitializeMongoDbCommandResponse;
  return proto.wilqo.api.activity.InitializeMongoDbCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InitializeMongoDbCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse} returns this
*/
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.InitializeMongoDbCommandResponse} returns this
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.InitializeMongoDbCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.SubscriptionSettingsCase = {
  SUBSCRIPTION_SETTINGS_NOT_SET: 0,
  SKILL_SET: 2,
  APPLIES_TO: 3
};

/**
 * @return {proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.SubscriptionSettingsCase}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.getSubscriptionSettingsCase = function() {
  return /** @type {proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.SubscriptionSettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    skillSet: (f = msg.getSkillSet()) && Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs.toObject(includeInstance, f),
    appliesTo: (f = msg.getAppliesTo()) && Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs.toObject(includeInstance, f),
    paging: (f = msg.getPaging()) && Wilqo_API_Activity_Models_pb.PagingArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SubscribeActivitiesCommandRequest;
  return proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs.deserializeBinaryFromReader);
      msg.setSkillSet(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs.deserializeBinaryFromReader);
      msg.setAppliesTo(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.PagingArgs;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.PagingArgs.deserializeBinaryFromReader);
      msg.setPaging(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs.serializeBinaryToWriter
    );
  }
  f = message.getAppliesTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs.serializeBinaryToWriter
    );
  }
  f = message.getPaging();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.PagingArgs.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActivitiesBySkillSetArgs skill_set = 2;
 * @return {?proto.wilqo.api.activity.ActivitiesBySkillSetArgs}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.getSkillSet = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitiesBySkillSetArgs} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitiesBySkillSetArgs, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitiesBySkillSetArgs|undefined} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
*/
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.setSkillSet = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.clearSkillSet = function() {
  return this.setSkillSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.hasSkillSet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ActivitiesByEntityArgs applies_to = 3;
 * @return {?proto.wilqo.api.activity.ActivitiesByEntityArgs}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.getAppliesTo = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitiesByEntityArgs} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitiesByEntityArgs, 3));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitiesByEntityArgs|undefined} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
*/
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.setAppliesTo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.clearAppliesTo = function() {
  return this.setAppliesTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.hasAppliesTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PagingArgs paging = 4;
 * @return {?proto.wilqo.api.activity.PagingArgs}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.getPaging = function() {
  return /** @type{?proto.wilqo.api.activity.PagingArgs} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.PagingArgs, 4));
};


/**
 * @param {?proto.wilqo.api.activity.PagingArgs|undefined} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
*/
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.setPaging = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.clearPaging = function() {
  return this.setPaging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandRequest.prototype.hasPaging = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagingInfo: (f = msg.getPagingInfo()) && Wilqo_API_Activity_Models_pb.PagingInfo.toObject(includeInstance, f),
    currentActivitiesList: jspb.Message.toObjectList(msg.getCurrentActivitiesList(),
    Wilqo_API_Activity_Models_pb.ActivitySummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.SubscribeActivitiesCommandResponse;
  return proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.PagingInfo;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.PagingInfo.deserializeBinaryFromReader);
      msg.setPagingInfo(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.ActivitySummary;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivitySummary.deserializeBinaryFromReader);
      msg.addCurrentActivities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagingInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.PagingInfo.serializeBinaryToWriter
    );
  }
  f = message.getCurrentActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
*/
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PagingInfo paging_info = 3;
 * @return {?proto.wilqo.api.activity.PagingInfo}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.getPagingInfo = function() {
  return /** @type{?proto.wilqo.api.activity.PagingInfo} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.PagingInfo, 3));
};


/**
 * @param {?proto.wilqo.api.activity.PagingInfo|undefined} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
*/
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.setPagingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.clearPagingInfo = function() {
  return this.setPagingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.hasPagingInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ActivitySummary current_activities = 4;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummary>}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.getCurrentActivitiesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ActivitySummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummary>} value
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
*/
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.setCurrentActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.addCurrentActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.activity.ActivitySummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.SubscribeActivitiesCommandResponse} returns this
 */
proto.wilqo.api.activity.SubscribeActivitiesCommandResponse.prototype.clearCurrentActivitiesList = function() {
  return this.setCurrentActivitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest;
  return proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest} returns this
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandRequest.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse;
  return proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse} returns this
*/
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse} returns this
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UnsubscribeActivitiesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activity: (f = msg.getActivity()) && Wilqo_API_Activity_Models_pb.ActivityPanelSequence.toObject(includeInstance, f),
    businessProcessTemplate: (f = msg.getBusinessProcessTemplate()) && Wilqo_API_Activity_Models_pb.BusinessProcessTemplateForActivity.toObject(includeInstance, f),
    progressItemDetails: (f = msg.getProgressItemDetails()) && Wilqo_API_Activity_Models_pb.ProgressType.toObject(includeInstance, f),
    skillsetIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest;
  return proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = new Wilqo_API_Activity_Models_pb.ActivityPanelSequence;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ActivityPanelSequence.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    case 3:
      var value = new Wilqo_API_Activity_Models_pb.BusinessProcessTemplateForActivity;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.BusinessProcessTemplateForActivity.deserializeBinaryFromReader);
      msg.setBusinessProcessTemplate(value);
      break;
    case 4:
      var value = new Wilqo_API_Activity_Models_pb.ProgressType;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ProgressType.deserializeBinaryFromReader);
      msg.setProgressItemDetails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Activity_Models_pb.ActivityPanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessTemplate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Activity_Models_pb.BusinessProcessTemplateForActivity.serializeBinaryToWriter
    );
  }
  f = message.getProgressItemDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Activity_Models_pb.ProgressType.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActivityPanelSequence activity = 2;
 * @return {?proto.wilqo.api.activity.ActivityPanelSequence}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivityPanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ActivityPanelSequence, 2));
};


/**
 * @param {?proto.wilqo.api.activity.ActivityPanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
*/
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BusinessProcessTemplateForActivity business_process_template = 3;
 * @return {?proto.wilqo.api.activity.BusinessProcessTemplateForActivity}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.getBusinessProcessTemplate = function() {
  return /** @type{?proto.wilqo.api.activity.BusinessProcessTemplateForActivity} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.BusinessProcessTemplateForActivity, 3));
};


/**
 * @param {?proto.wilqo.api.activity.BusinessProcessTemplateForActivity|undefined} value
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
*/
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.setBusinessProcessTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.clearBusinessProcessTemplate = function() {
  return this.setBusinessProcessTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.hasBusinessProcessTemplate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProgressType progress_item_details = 4;
 * @return {?proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.getProgressItemDetails = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressType} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ProgressType, 4));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressType|undefined} value
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
*/
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.setProgressItemDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.clearProgressItemDetails = function() {
  return this.setProgressItemDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.hasProgressItemDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated string skillset_ids = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.getSkillsetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.setSkillsetIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.addSkillsetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandRequest.prototype.clearSkillsetIdsList = function() {
  return this.setSkillsetIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse;
  return proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse} returns this
*/
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse} returns this
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UpsertActivityTemplateWithoutAdminCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.wilqo.api.activity);
