// source: Wilqo.Shared.Mortgage.Integration/Flood/models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.wilqo.shared.mortgage_integration.FloodProduct', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.FloodVendor', null, global);
goog.exportSymbol('proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.displayName = 'proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    product: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest;
  return proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.FloodProduct} */ (reader.readEnum());
      msg.setProduct(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.FloodVendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProduct();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FloodProduct product = 4;
 * @return {!proto.wilqo.shared.mortgage_integration.FloodProduct}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.getProduct = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.FloodProduct} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.FloodProduct} value
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.setProduct = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional FloodVendor vendor = 5;
 * @return {!proto.wilqo.shared.mortgage_integration.FloodVendor}
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.FloodVendor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.FloodVendor} value
 * @return {!proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.shared.mortgage_integration.RunFloodCommandRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.FloodProduct = {
  FLOOD_PRODUCT_UNKNOWN: 0,
  FLOOD_PRODUCT_FLOOD_ZONE_DETERMINATION_WITH_CENSUS_TRACT: 1,
  FLOOD_PRODUCT_FLOOD_ZONE_DETERMINATION: 2,
  FLOOD_PRODUCT_HOME_EQUITY: 3,
  FLOOD_PRODUCT_HOME_EQUITY_WITH_CENSUS_TRACT: 4,
  FLOOD_PRODUCT_LIFE_OF_LOAN_WITH_CENSUS_TRACT: 5,
  FLOOD_PRODUCT_LIFE_OF_LOAN: 6,
  FLOOD_PRODUCT_LIFE_OF_LOAN_WITH_HDMA: 7,
  FLOOD_PRODUCT_FLOOD_ZONE_DETERMINATION_WITH_HDMA: 8
};

/**
 * @enum {number}
 */
proto.wilqo.shared.mortgage_integration.FloodVendor = {
  FLOOD_VENDOR_UNKNOWN: 0,
  FLOOD_VENDOR_FACTUAL: 1,
  FLOOD_VENDOR_ADVANTAGE_FLOOD: 2
};

goog.object.extend(exports, proto.wilqo.shared.mortgage_integration);
