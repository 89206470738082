import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useParams } from 'react-router';

import { GetLoanFeeHeaderQueryRequest, GetLoanFeeHeaderQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetLoanFeeHeaderQueryRequest';

const useLoanFeesPageHeader = () => {
  const { commandResponse } = useBackend();
  const { loanId = '' } = useParams();

  return useQuery([MESSAGE_NAME], async () => {
    const request = new GetLoanFeeHeaderQueryRequest();
    request.setLoanId('');
    request.setDealId(loanId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetLoanFeeHeaderQueryResponse();
    GetLoanFeeHeaderQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj);
  });
};

export { useLoanFeesPageHeader };
