import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import type { UpsertLenderConditionTemplateCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import type { LenderCondition } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useArchiveCondition } from '@/API/Queries/mortgageConfig/useArchiveCondition';
import { useLenderConditions } from '@/API/Queries/mortgageConfig/useLenderConditions';
import { useUpsertLenderCondition } from '@/API/Queries/mortgageConfig/useUpsertLenderCondition';
import { Button } from '@/Components/Atoms/Button';
import { Dialog, DialogContent, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useNavigate } from '@/Routes/NavigationContext';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { ConditionTemplate } from './ConditionTemplate';

export const Conditions = () => {
  const navigate = useNavigate();
  const { showSnackBar } = useShared();

  // state
  const [openModal, setOpenModal] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<LenderCondition.AsObject[]>([]);
  const [showArchived, setShowArchived] = useState(false);

  // queries and mutations
  const { data: conditions = [], isLoading, refetch } = useLenderConditions('', showArchived);
  const { isLoading: isSaving, mutate: upsertCondition } = useUpsertLenderCondition();
  const { mutate: archiveCondition } = useArchiveCondition();

  // event handlers
  const onClickEdit = (condition: LenderCondition.AsObject) => {
    navigate(`/admin/lenderCondition/${condition.entityId}/configurator`);
  };

  const handleSubmitSuccess = (response: UpsertLenderConditionTemplateCommandResponse.AsObject) => {
    setOpenModal(false);
    navigate(`/admin/lenderCondition/${response.entityId}/configurator`);
    refetch();
  };

  const handleSubmitError = (error: Error) => {
    showSnackBar({ message: error.message, open: true });
  };

  const handleSubmit = (values: DynamicDataElementValues) => {
    upsertCondition({
      category: Number(values.category.value.id),
      description: values.description.value,
      internalName: values.internalName.value,
      partyType: Number(values.satisfactionResponsibleParty.value.id),
      timeframeType: Number(values.satisfactionTimeframe.value.id),
      title: values.title.value,
    }, {
      onError: handleSubmitError,
      onSuccess: handleSubmitSuccess,
    });
  };

  const handleArchive = (selectedItems = selectedTemplates) => {
    archiveCondition(
      {
        archive: !showArchived,
        ids: selectedItems.map((item) => item.entityId),
      },
      {
        onError: (error) => showSnackBar({ message: error.message || 'Something went wrong', open: true }),
        onSuccess: () => {
          refetch();
          setSelectedTemplates([]);
          showSnackBar({ message: 'Conditions updated', open: true });
        },
      },
    );
  };

  // memos
  const columns = useMemo((): Array<Column<LenderCondition.AsObject>> => [
    {
      header: 'Condition Title',
      id: 'name',
      key: {
        text: 'title',
        type: 'text',
      },
    },
    {
      header: 'Condition Description',
      id: 'description',
      key: {
        text: 'description',
        type: 'text',
      },
    },
    {
      header: 'Code',
      id: 'code',
      key: {
        text: 'code',
        type: 'text',
      },
    },
    {
      header: 'Type',
      id: 'type',
      key: {
        text: 'type',
        type: 'text',
      },
    },
    {
      header: 'Last Published Date',
      id: 'lastPublishedDate',
      key: {
        text: (condition) => dayjs(getDateFromTimestamp(condition.lastPublishedDate)?.toString()).format('MM/DD/YYYY HH:mm:ss') || '',
        type: 'text',
      },
    },
    {
      header: 'Associated Activities',
      id: 'associatedActivities',
      key: {
        text: 'associatedActivities',
        type: 'text',
      },
    },
  ], []);

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create new', onClick: () => setOpenModal(true) }],
            contextualHeaderProps: {
              buttons: [{ label: showArchived ? 'Unarchive' : 'Archive', onClick: () => handleArchive() }],
              caption: `${selectedTemplates.length} conditions selected`,
            },
            filtersProps: {
              items: [{ onClick: () => setShowArchived(!showArchived), selected: showArchived, value: 'Archived' }],
              variant: 'flex',
            },
            showContextual: selectedTemplates.length > 0,
            title: 'All Lender Conditions',
          },
        }}
        columns={columns}
        data={conditions}
        hoverActions={selectedTemplates.length > 0 ? [] : [{ label: showArchived ? 'Unarchive' : 'Archive', onClick: (item) => handleArchive([item]) }]}
        isLoading={isLoading}
        onClickRow={onClickEdit}
        rowActionType="dropdown"
        setRowSelection={setSelectedTemplates}
        totalItems={conditions.length}
      />
      <Dialog onOpenChange={() => setOpenModal(false)} open={openModal}>
        <DialogLayout asChild>
          <DynamicFormProvider onSubmit={handleSubmit}>
            {
            ({ hasError }) => (
              <>
                <DialogHeader
                  actions={<Button disabled={hasError} isLoading={isSaving} label="Save" type="submit" />}
                  title="Create new Condition"
                />
                <DialogContent className="p-12 flex gap-10 flex-col">
                  <ConditionTemplate />
                </DialogContent>
              </>
            )
          }
          </DynamicFormProvider>
        </DialogLayout>
      </Dialog>
    </>

  );
};
