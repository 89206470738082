// source: Wilqo.API.Mortgage.Integration/Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb = require('../Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb);
var Wilqo_API_Mortgage_Integration_Models_pb = require('../Wilqo.API.Mortgage.Integration/Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_Integration_Models_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Mortgage_Integration_Documents_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/Documents/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_Documents_Models_pb);
var Wilqo_Shared_Mortgage_Integration_Flood_models_pb = require('../Wilqo.Shared.Mortgage.Integration/Flood/models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_Flood_models_pb);
var Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/Pricing/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb);
var Wilqo_Shared_Mortgage_Integration_Compliance_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/Compliance/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_Compliance_Models_pb);
var Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb = require('../Wilqo.Shared.Mortgage.Integration/MortgageInsurance/Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.Vendor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunCreditCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditPullType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Vendor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunCreditCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Vendor', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunFloodCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunFloodCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunFraudCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunFraudCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunCreditCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunCreditCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunCreditCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunCreditCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunFraudCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunFraudCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunFraudCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunFraudCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.displayName = 'proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunFloodCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunFloodCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunFloodCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunFloodCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.displayName = 'proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.displayName = 'proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.repeatedFields_ = [8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    creditPullType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    creditRequestType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creditReportReferenceNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    bureausList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    consentCutOffTime: (f = msg.getConsentCutOffTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunCreditCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Vendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditPullType} */ (reader.readEnum());
      msg.setCreditPullType(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType} */ (reader.readEnum());
      msg.setCreditRequestType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditReportReferenceNumber(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBureaus(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConsentCutOffTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCreditPullType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreditRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreditReportReferenceNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBureausList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getConsentCutOffTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Vendor = {
  VENDOR_UNKNOWN: 0,
  VENDOR_FACTUAL: 1,
  VENDOR_XACTUS: 2,
  VENDOR_MERIDIAN: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditPullType = {
  CREDIT_PULL_TYPE_UNKNOWN: 0,
  CREDIT_PULL_TYPE_SOFT: 1,
  CREDIT_PULL_TYPE_HARD: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType = {
  CREDIT_REQUEST_TYPE_UNKNOWN: 0,
  CREDIT_REQUEST_TYPE_NEW: 1,
  CREDIT_REQUEST_TYPE_REISSUE: 2,
  CREDIT_REQUEST_TYPE_UPGRADE: 3,
  CREDIT_REQUEST_TYPE_RETRIEVE: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau = {
  BUREAU_UNKNOWN: 0,
  BUREAU_EXPERIAN: 1,
  BUREAU_TRANSUNION: 2,
  BUREAU_EQUIFAX: 3
};

/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Vendor vendor = 4;
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Vendor}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Vendor} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Vendor} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional CreditPullType credit_pull_type = 5;
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditPullType}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getCreditPullType = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditPullType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditPullType} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setCreditPullType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional CreditRequestType credit_request_type = 6;
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getCreditRequestType = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.CreditRequestType} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setCreditRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string credit_report_reference_number = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getCreditReportReferenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setCreditReportReferenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated Bureau bureaus = 8;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau>}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getBureausList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setBureausList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.Bureau} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.addBureaus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.clearBureausList = function() {
  return this.setBureausList([]);
};


/**
 * repeated string borrower_ids = 9;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};


/**
 * optional google.protobuf.Timestamp consent_cut_off_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.getConsentCutOffTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
*/
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.setConsentCutOffTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.clearConsentCutOffTime = function() {
  return this.setConsentCutOffTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandRequest.prototype.hasConsentCutOffTime = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    additionalValidationPagesList: jspb.Message.toObjectList(msg.getAdditionalValidationPagesList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunCreditCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.addAdditionalValidationPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdditionalValidationPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.api.mortgage_dynamic_data.LoanPage additional_validation_pages = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.getAdditionalValidationPagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPage>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.setAdditionalValidationPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.addAdditionalValidationPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunCreditCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunCreditCommandResponse.prototype.clearAdditionalValidationPagesList = function() {
  return this.setAdditionalValidationPagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanOriginatorMersOrganizationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    investorMersOrganizationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    servicerMersOrganizationId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    warehouseLineMersOrganizationId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subservicerMersOrganizationId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOriginatorMersOrganizationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestorMersOrganizationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setServicerMersOrganizationId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWarehouseLineMersOrganizationId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubservicerMersOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanOriginatorMersOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInvestorMersOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServicerMersOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWarehouseLineMersOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubservicerMersOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loan_originator_mers_organization_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getLoanOriginatorMersOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setLoanOriginatorMersOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string investor_mers_organization_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getInvestorMersOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setInvestorMersOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string servicer_mers_organization_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getServicerMersOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setServicerMersOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string warehouse_line_mers_organization_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getWarehouseLineMersOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setWarehouseLineMersOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string subservicer_mers_organization_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.getSubservicerMersOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandRequest.prototype.setSubservicerMersOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunMersRegistrationCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunFraudCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunFraudCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFraudCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunFraudCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    complianceCheckTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ComplianceCheckType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addComplianceCheckTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getComplianceCheckTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated wilqo.shared.mortgage_integration.ComplianceCheckType compliance_check_types = 4;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.ComplianceCheckType>}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.getComplianceCheckTypesList = function() {
  return /** @type {!Array<!proto.wilqo.shared.mortgage_integration.ComplianceCheckType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.ComplianceCheckType>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.setComplianceCheckTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.ComplianceCheckType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.addComplianceCheckTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandRequest.prototype.clearComplianceCheckTypesList = function() {
  return this.setComplianceCheckTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunComplianceCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    processId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDocumentTypes(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProcessId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType = {
  DOCUMENT_TYPE_NOT_SPECIFIED: 0,
  DOCUMENT_TYPE_DOC_1008: 1,
  DOCUMENT_TYPE_DOC_92900A: 2,
  DOCUMENT_TYPE_DOC_92900LT: 3,
  DOCUMENT_TYPE_DOC_1005: 4,
  DOCUMENT_TYPE_FORM_90: 5,
  DOCUMENT_TYPE_DOC_VA266393: 6,
  DOCUMENT_TYPE_GET_QUALIFIED: 7
};

/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DocumentType document_types = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType>}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getDocumentTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setDocumentTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.DocumentType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.addDocumentTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.clearDocumentTypesList = function() {
  return this.setDocumentTypesList([]);
};


/**
 * optional string subscription_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string client_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string process_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.getProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandRequest.prototype.setProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentGenerationCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest;
  return proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse;
  return proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.InitializeManagedSettingsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dealId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    packageTypeName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    planCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.toObject, includeInstance),
    processNotificationId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    requestType: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Vendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageTypeName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanCode(value);
      break;
    case 10:
      var value = new proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document;
      reader.readMessage(value,proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessNotificationId(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.DocMagicRequestType} */ (reader.readEnum());
      msg.setRequestType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPackageTypeName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPlanCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.serializeBinaryToWriter
    );
  }
  f = message.getProcessNotificationId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRequestType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Vendor = {
  VENDOR_UNKNOWN: 0,
  VENDOR_DOCUTECH: 1,
  VENDOR_ASURITY: 2,
  VENDOR_DOC_MAGIC: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document;
  return proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSequence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSequence();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sequence = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.prototype.getSequence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document.prototype.setSequence = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Vendor vendor = 6;
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Vendor}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Vendor} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Vendor} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string deal_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string package_type_name = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getPackageTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setPackageTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string plan_code = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getPlanCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setPlanCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated Document documents = 10;
 * @return {!Array<!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document>}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
*/
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional string process_notification_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getProcessNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setProcessNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional DocMagicRequestType request_type = 12;
 * @return {!proto.wilqo.api.mortgage_integration.DocMagicRequestType}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.getRequestType = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.DocMagicRequestType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.DocMagicRequestType} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocumentsCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productSearchOptions: (f = msg.getProductSearchOptions()) && Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductSearchOptions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductSearchOptions;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductSearchOptions.deserializeBinaryFromReader);
      msg.setProductSearchOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductSearchOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductSearchOptions.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.mortgage_integration.ProductSearchOptions product_search_options = 4;
 * @return {?proto.wilqo.shared.mortgage_integration.ProductSearchOptions}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.getProductSearchOptions = function() {
  return /** @type{?proto.wilqo.shared.mortgage_integration.ProductSearchOptions} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Mortgage_Integration_Pricing_Models_pb.ProductSearchOptions, 4));
};


/**
 * @param {?proto.wilqo.shared.mortgage_integration.ProductSearchOptions|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} returns this
*/
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.setProductSearchOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.clearProductSearchOptions = function() {
  return this.setProductSearchOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandRequest.prototype.hasProductSearchOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductSearchCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processNotificationId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessNotificationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessNotificationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string process_notification_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.getProcessNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandRequest.prototype.setProcessNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    plansList: jspb.Message.toObjectList(msg.getPlansList(),
    Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.DocMagicPlan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.DocMagicPlan;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.DocMagicPlan.deserializeBinaryFromReader);
      msg.addPlans(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPlansList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.DocMagicPlan.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.mortgage_integration.DocMagicPlan plans = 2;
 * @return {!Array<!proto.wilqo.shared.mortgage_integration.DocMagicPlan>}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.getPlansList = function() {
  return /** @type{!Array<!proto.wilqo.shared.mortgage_integration.DocMagicPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Mortgage_Integration_Documents_Models_pb.DocMagicPlan, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.mortgage_integration.DocMagicPlan>} value
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.setPlansList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.DocMagicPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.mortgage_integration.DocMagicPlan}
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.addPlans = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.mortgage_integration.DocMagicPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunDocMagicPlanCodeListCommandResponse.prototype.clearPlansList = function() {
  return this.setPlansList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processNotificationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    searchProcessInstanceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    searchId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessNotificationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchProcessInstanceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessNotificationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSearchProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string process_notification_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getProcessNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setProcessNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string search_process_instance_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getSearchProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setSearchProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string search_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string rate_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandRequest.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunApplySelectedProductCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processNotificationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    searchProcessInstanceId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    searchId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rateId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessNotificationId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchProcessInstanceId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessNotificationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSearchProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSearchId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string process_notification_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getProcessNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setProcessNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string search_process_instance_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getSearchProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setSearchProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string search_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getSearchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setSearchId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string product_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string rate_id = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandRequest.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunProductRateLockCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    product: jspb.Message.getFieldWithDefault(msg, 4, 0),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunFloodCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.FloodProduct} */ (reader.readEnum());
      msg.setProduct(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.shared.mortgage_integration.FloodVendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProduct();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.mortgage_integration.FloodProduct product = 4;
 * @return {!proto.wilqo.shared.mortgage_integration.FloodProduct}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.getProduct = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.FloodProduct} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.FloodProduct} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.setProduct = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional wilqo.shared.mortgage_integration.FloodVendor vendor = 5;
 * @return {!proto.wilqo.shared.mortgage_integration.FloodVendor}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.shared.mortgage_integration.FloodVendor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.shared.mortgage_integration.FloodVendor} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunFloodCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunFloodCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunFloodCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    payload: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest;
  return proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayload(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.Vendor} */ (reader.readEnum());
      msg.setVendor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPayload();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.Vendor = {
  VENDOR_UNKNOWN: 0,
  VENDOR_ASURITY_PROPEL: 1,
  VENDOR_POLLY: 2
};

/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string payload = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.setPayload = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Vendor vendor = 5;
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.Vendor}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.getVendor = function() {
  return /** @type {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.Vendor} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.Vendor} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandRequest.prototype.setVendor = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse;
  return proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.ProcessWebhookEventCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    agentId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string agent_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.getAgentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.setAgentId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.clearAgentId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandRequest.prototype.hasAgentId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunLodestarCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    searchOptions: (f = msg.getSearchOptions()) && Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceSearchOptions.toObject(includeInstance, f),
    processNotificationId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest;
  return proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceSearchOptions;
      reader.readMessage(value,Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceSearchOptions.deserializeBinaryFromReader);
      msg.setSearchOptions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessNotificationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSearchOptions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceSearchOptions.serializeBinaryToWriter
    );
  }
  f = message.getProcessNotificationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deal_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions search_options = 4;
 * @return {?proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.getSearchOptions = function() {
  return /** @type{?proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Mortgage_Integration_MortgageInsurance_Models_pb.MortgageInsuranceSearchOptions, 4));
};


/**
 * @param {?proto.wilqo.shared.mortgage_integration.MortgageInsuranceSearchOptions|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} returns this
*/
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.setSearchOptions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.clearSearchOptions = function() {
  return this.setSearchOptions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.hasSearchOptions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string process_notification_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.getProcessNotificationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandRequest.prototype.setProcessNotificationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    processInstanceId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse;
  return proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessInstanceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProcessInstanceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse} returns this
*/
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string process_instance_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.getProcessInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse} returns this
 */
proto.wilqo.api.mortgage_integration.RunMortgageInsuranceQuotesCommandResponse.prototype.setProcessInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_integration);
