import { useCallback, useEffect, useMemo, useState } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DocType, DocumentCategoryEnum, DocumentEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useDocumentCategory } from '@/API/Queries/mortgage/useDocumentCategory';
import { useUpdateDocumentCategory } from '@/API/Queries/mortgage/useUpdateDocumentCategory';
import { Button } from '@/Components/Atoms/Button';
import { Checkbox } from '@/Components/Atoms/Checkbox';
import { Input } from '@/Components/Atoms/Input';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Select } from '@/Components/Atoms/Select';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface DocumentCategoryEditTypeProps {
  categoryId: string;
  typeId?: string;
  refetch: () => void;
  onClose?: () => void;
}

const DocumentCategoryEditType = ({
  categoryId = '',
  typeId = '',
  refetch = () => null,
  onClose = () => null,
}: DocumentCategoryEditTypeProps) => {
  const { showSnackBar } = useShared();

  const { data: documentCategory } = useDocumentCategory(categoryId);
  const { isLoading, mutate: updateDocumentCategory } = useUpdateDocumentCategory();
  const documentOptions = useMemo(() => {
    const enumProperties = Object.keys(DocumentEnum);
    enumProperties.shift();
    return enumProperties.map<PanelElementOption.AsObject>((val) => getPanelElementOption({
      headerText: ConvertEnum(DocumentEnum, DocumentEnum[val as keyof typeof DocumentEnum]) || '',
      id: String(DocumentEnum[val as keyof typeof DocumentEnum]) || '',
    }));
  }, []);

  const categoryOptions = useMemo(() => {
    const enumProperties = Object.keys(DocumentCategoryEnum);
    enumProperties.shift();
    return enumProperties.map<PanelElementOption.AsObject>((val) => getPanelElementOption({
      headerText: ConvertEnum(DocumentCategoryEnum, DocumentCategoryEnum[val as keyof typeof DocumentCategoryEnum]) || '',
      id: String(DocumentCategoryEnum[val as keyof typeof DocumentCategoryEnum]) || '',
    }));
  }, []);

  const [externalType, setExternalType] = useState<PanelElementOption.AsObject | undefined>();
  const [category, setCategory] = useState<PanelElementOption.AsObject | undefined>();
  const [documentTypeName, setDocumentTypeName] = useState('');
  const [consumerFacing, setConsumerFacing] = useState(false);
  const [docTypeId, setDocTypeId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setExternalType(documentOptions.find((option) => option.id === typeId.split('-')[0]));
    setCategory(categoryOptions.find((option) => option.id === String(documentCategory?.categoryType)));
    const type = documentCategory?.docTypesList.find((type) => `${type.docType}-${type.documentTypeName}` === typeId);
    setDocumentTypeName(type?.documentTypeName || '');
    setDocTypeId(type?.id);
    if (typeof type?.isConsumerFacing === 'boolean') setConsumerFacing(type.isConsumerFacing);
  }, [documentCategory?.docTypesList, documentOptions, categoryOptions, typeId, documentCategory?.categoryType]);

  const handleSave = useCallback((assign?: boolean) => {
    const previousDocTypes = documentCategory?.docTypesList.map((type) => {
      const newType = new DocType();
      newType.setDocType(type.docType);
      newType.setDocumentTypeName(type?.documentTypeName);
      newType.setIsConsumerFacing(type?.isConsumerFacing);

      if (type?.id != null) {
        newType.setId(type.id);
      }

      return newType;
    }).filter((type) => `${type.getDocType()}-${type.getDocumentTypeName()}` !== typeId) || [];

    const newDocType = new DocType();
    newDocType.setDocType(Number(externalType?.id));
    newDocType.setDocumentTypeName(documentTypeName);
    newDocType.setIsConsumerFacing(consumerFacing);

    if (docTypeId != null) {
      newDocType.setId(docTypeId);
    }

    const docTypes = [...previousDocTypes, newDocType];
    const types = new Set([...documentCategory?.typesList || []]);

    if (assign) {
      types.add(Number(externalType?.id));
    }

    updateDocumentCategory({
      category: category?.id ? Number(category?.id) : undefined,
      docTypes,
      id: categoryId || '',
      name: documentCategory?.name,
      types: Array.from(types),
    }, {
      onSuccess: () => {
        showSnackBar({ message: 'Document Type Name updated', open: true });
        refetch();
        onClose();
      },
    });
  }, [
    documentCategory?.docTypesList,
    documentCategory?.typesList,
    documentCategory?.name,
    externalType?.id,
    documentTypeName,
    consumerFacing,
    updateDocumentCategory,
    category?.id,
    categoryId,
    typeId,
    showSnackBar,
    refetch,
    onClose,
    docTypeId,
  ]);

  return (
    <DialogLayout>
      <DialogHeader
        icon="ArrowBack"
        iconAction={onClose}
        title={`${typeId ? 'Update' : 'Create'} Category`}
      />

      <DialogContent>
        <div className="flex flex-col gap-y-4 w-full h-full overflow-auto p-4 sm:p-12 max-w-lg mx-auto">
          <Input
            displayOnly
            label="Document Type Name"
            onChange={setDocumentTypeName}
            placeholder="Document Type Name"
            required
            value={documentTypeName}
          />
          <Select
            displayOnly
            onSelect={(option) => setExternalType(option)}
            options={documentOptions}
            placeholder="Mismo Document Type"
            required
            titleKey="headerText"
            value={externalType?.headerText || ''}
          />
          <Checkbox
            displayOnly
            label="Consumer facing"
            onClick={setConsumerFacing}
            value={consumerFacing}
          />
        </div>
      </DialogContent>

      <DialogFooter borderTop>
        <Button
          disabled={!externalType?.id || !documentTypeName}
          isLoading={isLoading}
          label={`${typeId ? 'Update' : 'Create'} & Assign`}
          onClick={() => handleSave(true)}
          variant="primary"
        />
      </DialogFooter>
    </DialogLayout>
  );
};

export { DocumentCategoryEditType };
