import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';
import { UpsertFeeCommandRequest, UpsertFeeCommandResponse } from '@/API/Models/Wilqo_API_Mortgage_Commands_pb';
import type { LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export interface UseUpsertFeeDTO {
  loanId: string;
  dealId: string;
  feeId?: string;
  feeName?: string;
  borrowerPaid?: ProtoDecimal;
  sellerPaid?: ProtoDecimal;
  lenderPaid?: ProtoDecimal;
  otherPaid?: ProtoDecimal;
  total?: ProtoDecimal;
  categoryType: LoanFeeCategoryTypeEnum;
}

const MESSAGE_NAME = 'wilqo.api.mortgage.UpsertFeeCommandRequest';

const useUpsertFee = () => {
  const { commandResponse } = useBackend();

  const upsertFee = async (updateObject: UseUpsertFeeDTO) => {
    const request = new UpsertFeeCommandRequest();
    request.setLoanId(updateObject.loanId);
    request.setDealId(updateObject.dealId);
    request.setFeeId(updateObject.feeId || '');
    request.setFeeName(updateObject.feeName || '');
    request.setCategoryType(updateObject.categoryType);
    if (updateObject.borrowerPaid) request.setFeePaymentPaidByBorrowerTotalAmount(updateObject.borrowerPaid);
    if (updateObject.sellerPaid) request.setFeePaymentPaidBySellerTotalAmount(updateObject.sellerPaid);
    if (updateObject.lenderPaid) request.setFeePaymentPaidByLenderTotalAmount(updateObject.lenderPaid);
    if (updateObject.otherPaid) request.setFeePaymentPaidByOtherTotalAmount(updateObject.otherPaid);
    if (updateObject.total) request.setFeePaymentTotal(updateObject.total);

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpsertFeeCommandResponse();
    UpsertFeeCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.loanFee);
  };

  return useMutation(upsertFee);
};

export { useUpsertFee };
