import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { Organization } from '@/API/Models/Wilqo_API_Users_InstitutionModels_pb';
import { useUser } from '@/API/Queries/user/useUser';
import { useUserOrgAssociation } from '@/API/Queries/user/useUserOrgAssociation';
import { Badge } from '@/Components/Atoms/Badge';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useLookup } from '@/Utils/Hooks/useLookup/useLookup';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const UserOrgs = () => {
  const { id: userId = '' } = useParams();
  const { data: user } = useUser(userId);
  const { showSnackBar } = useShared();

  const { mutate } = useUserOrgAssociation();
  const [selectedOrgs, setSelectedOrgs] = useState<Organization.AsObject[]>([]);
  const { exists: isAssigned } = useLookup(user?.organizationIdsList);

  const bulkAssign = useCallback((assign: boolean, orgs: Organization.AsObject[]) => {
    const onSuccess = () => {
      const assigned = assign ? 'assigned' : 'unassigned';
      const message = `New organizations were ${assigned}.`;
      const snack = { message, open: true };
      showSnackBar(snack);
    };

    const newOrgIds = assign
      ? user?.organizationIdsList.concat(orgs.map((org) => org.id))
      : user?.organizationIdsList.filter((orgId) => !orgs.some((selectedOrg) => selectedOrg.id === orgId));

    mutate({ orgIds: newOrgIds || [], userId }, { onSuccess });
  }, [userId, user, showSnackBar, mutate]);

  const handleUnassignClick = useCallback(() => bulkAssign(false, selectedOrgs), [bulkAssign, selectedOrgs]);
  const handleAssignClick = useCallback(() => bulkAssign(true, selectedOrgs), [bulkAssign, selectedOrgs]);
  const handleHoverClick = useCallback((item: Organization.AsObject) => {
    bulkAssign(!isAssigned(item.id), [item]);
  }, [bulkAssign, isAssigned]);

  const renderBadge = useCallback((org: Organization.AsObject) => {
    if (isAssigned(org.id)) {
      return (
        <Badge label="Assigned" variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE} />
      );
    }
    return null;
  }, [isAssigned]);

  const columns = useMemo((): TableBuilderColumn => [
    ['Organization name', 'displayName'],
    ['Address', 'fullAddress'],
    ['Assignment', { type: 'any', value: renderBadge }],
  ], [renderBadge]);

  return (
    <TableBuilder
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [
              { label: 'Unassign', onClick: handleUnassignClick },
              { label: 'Assign', onClick: handleAssignClick },
            ],
            caption: `${selectedOrgs.length} org(s) selected`,
          },
          showContextual: selectedOrgs.length > 0,
          title: 'Organizations',
        },
      }}
      columns={columns}
      dataType={TableBuilderDataTypeEnum.ORGANIZATIONS}
      hoverActions={[{
        label: (org) => (isAssigned(org.id) ? 'Unassign' : 'Assign'),
        onClick: handleHoverClick,
      }]}
      orderBy="Assignment"
      setRowSelection={setSelectedOrgs}
    />
  );
};

export { UserOrgs };
