/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';

import type { FeeParametrizedRuleAggregatorSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Admin_pb';
import type { FeeTemplateRuleGroupParameter } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb';
import type { DataFieldTemplateSummary } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb';
import type { RuleGroupSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import type { Operator } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb';
import { toDeprecatedProgress } from '@/Utils/Helpers/progress';

interface State {
  addNewGroup: () => void;
  addNewRule: (groupId: string) => void;
  removeGroup: (groupId: string) => void;
  removeRule: (groupId: string, ruleId: string, addDefaultAutomatically: boolean) => void;
  toggleGroupCondition: (groupId: string) => void;
  toggleTriggerCondition: () => void;
  currentTrigger?: FeeParametrizedRuleAggregatorSummary.AsObject;
  updateRuleField: (groupId: string, ruleId: string, field: any) => void;
  updateRuleOperator: (groupId: string, ruleId: string, operator: Operator.AsObject) => void;
  updateRuleComparable: (groupId: string, ruleId: string, value: any) => void;
  updateRuleGroupFees: (groupId: string, fees: FeeTemplateRuleGroupParameter.AsObject) => void;
}

const initialState: State = {
  addNewGroup: () => null,
  addNewRule: () => null,
  currentTrigger: undefined,
  removeGroup: () => null,
  removeRule: () => null,
  toggleGroupCondition: () => null,
  toggleTriggerCondition: () => null,
  updateRuleComparable: () => null,
  updateRuleField: () => null,
  updateRuleGroupFees: () => null,
  updateRuleOperator: () => null,
};

const TriggerContext = createContext(initialState);
TriggerContext.displayName = 'TriggerContext';

interface TriggerContextProviderProps {
  children: React.ReactNode;
  trigger?: FeeParametrizedRuleAggregatorSummary.AsObject;
  updateTrigger: (trigger: FeeParametrizedRuleAggregatorSummary.AsObject) => void;
}

const getEmptyTrigger = (): FeeParametrizedRuleAggregatorSummary.AsObject => ({
  applyAndBetweenGroups: false,
  groupsList: [
    {
      applyAndBetweenRules: false,
      id: v4(),
      readOnly: false,
      rulesList: [
        // @ts-ignore
        { comparableValue: undefined, fieldLibraryOptionId: '', id: v4() },
      ],
    },
  ],
});

const TriggerProvider = (props: TriggerContextProviderProps) => {
  const { children, trigger, updateTrigger } = props;

  const [currentTrigger, setCurrentTrigger] = useState(trigger);

  const triggerLoaded = useRef(false);
  useEffect(() => {
    if (trigger && !triggerLoaded.current) {
      triggerLoaded.current = true;
      setCurrentTrigger({
        ...trigger,
        groupsList: trigger.groupsList.map((group) => ({
          ...group,
          feeParameters: group.feeParameters,
          id: group.id || v4(),
          rulesList: group.rulesList.length > 0
            ? group.rulesList.map((rule) => ({ ...rule, id: rule.id || v4() }))
            : [{ comparableValue: '', fieldLibraryOptionId: '', id: v4() }],
        })),
      });
    }
  }, [trigger]);

  const handleUpdateTrigger = (trigger: FeeParametrizedRuleAggregatorSummary.AsObject) => {
    updateTrigger(trigger);
    setCurrentTrigger(trigger);
  };

  const addNewGroup = () => {
    if (currentTrigger) {
      const newGroup: RuleGroupSummary.AsObject = {
        applyAndBetweenRules: false,
        id: v4(),
        readOnly: false,
        rulesList: [{ comparableValue: '', fieldLibraryOptionId: '', id: v4() }],
      };
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.concat(newGroup),
      });
    } else {
      handleUpdateTrigger(getEmptyTrigger());
    }
  };

  const removeGroup = (groupId: string) => {
    if (currentTrigger && currentTrigger.groupsList.length >= 1) {
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.filter((e) => e.id !== groupId),
      });
    }
  };

  const addNewRule = (groupId: string) => {
    if (currentTrigger) {
      const newRuleId = v4();
      const newTrigger = {
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              rulesList: [
                ...group.rulesList,
                { comparableValue: undefined, fieldLibraryOptionId: '', id: newRuleId },
              ],
            };
          }
          return group;
        }),
      };
      // @ts-ignore
      handleUpdateTrigger(newTrigger);
    }
  };

  const removeRule = (groupId: string, ruleId: string, addDefaultAutomatically: boolean) => {
    if (!currentTrigger) return;

    const ruleGroupIndex = currentTrigger.groupsList.findIndex((group) => group.id === groupId);
    if (ruleGroupIndex === -1) return;

    const ruleGroup = currentTrigger.groupsList[ruleGroupIndex];
    const isSingleRule = ruleGroup.rulesList.length <= 1;
    const newTrigger = { ...currentTrigger };

    if (isSingleRule) {
      const defaultRule = { comparableValue: '', fieldLibraryOptionId: '', id: v4() };
      newTrigger.groupsList[ruleGroupIndex].rulesList = [addDefaultAutomatically ? { ...defaultRule } : defaultRule];
    } else {
      newTrigger.groupsList[ruleGroupIndex].rulesList = ruleGroup.rulesList.filter((rule) => rule.id !== ruleId);
    }

    handleUpdateTrigger(newTrigger);
  };

  const updateRuleGroupFees = (groupId: string, fees: FeeTemplateRuleGroupParameter.AsObject) => {
    if (currentTrigger) {
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              feeParameters: fees,
            };
          }
          return group;
        }),
      });
    }
  };

  const toggleGroupCondition = (updateId: string) => {
    if (currentTrigger) {
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.map((group) => {
          if (updateId === group.id) {
            return { ...group, applyAndBetweenRules: !group.applyAndBetweenRules };
          }
          return group;
        }),
      });
    }
  };

  const toggleTriggerCondition = () => {
    if (currentTrigger) {
      handleUpdateTrigger({
        ...currentTrigger,
        applyAndBetweenGroups: !currentTrigger.applyAndBetweenGroups,
      });
    }
  };

  const updateRuleField = (groupId: string, ruleId: string, dataField: DataFieldTemplateSummary.AsObject) => {
    if (currentTrigger) {
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              rulesList: group.rulesList.map((rule) => {
                if (rule.id === ruleId) {
                  return {
                    ...rule,
                    dataField,
                  };
                }
                return rule;
              }),
            };
          }
          return group;
        }),
      });
    }
  };

  const updateRuleOperator = (groupId: string, ruleId: string, operator: Operator.AsObject) => {
    if (currentTrigger) {
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              rulesList: group.rulesList.map((rule) => {
                if (rule.id === ruleId) {
                  return {
                    ...rule,
                    operator,
                  };
                }
                return rule;
              }),
            };
          }
          return group;
        }),
      });
    }
  };

  const updateRuleComparable = (groupId: string, ruleId: string, value: any) => {
    if (currentTrigger) {
      const comparableValue = toDeprecatedProgress(value);
      handleUpdateTrigger({
        ...currentTrigger,
        groupsList: currentTrigger.groupsList.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              rulesList: group.rulesList.map((rule) => {
                if (rule.id === ruleId) {
                  return {
                    ...rule,
                    comparableValue,
                  };
                }
                return rule;
              }),
            };
          }
          return group;
        }),
      });
    }
  };

  return (
    <TriggerContext.Provider value={{
      addNewGroup,
      addNewRule,
      currentTrigger,
      removeGroup,
      removeRule,
      toggleGroupCondition,
      toggleTriggerCondition,
      updateRuleComparable,
      updateRuleField,
      updateRuleGroupFees,
      updateRuleOperator,
    }}
    >
      {children}
    </TriggerContext.Provider>
  );
};

const useTriggerContext = () => useContext(TriggerContext);
export { TriggerProvider, useTriggerContext };
