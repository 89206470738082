import { FeeParametrizedRuleAggregatorSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Admin_pb';
import { FeeParametrizedRuleGroupSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import { FeeTemplateRuleGroupParameter } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb';
import { DataFieldTemplateSummary } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb';
import { OperatorSummary, RuleAggregatorSummary, RuleGroupSummary, RuleSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { ConvertProtoDecimalToInstance } from '@/Utils/Helpers/protoDecimalConversion';

export const convertTriggerSummaryToInstance = (summary: RuleAggregatorSummary.AsObject): RuleAggregatorSummary => {
  const aggregator = new RuleAggregatorSummary();
  aggregator.setApplyAndBetweenGroups(summary.applyAndBetweenGroups);
  aggregator.setGroupsList(summary.groupsList.map((g: RuleGroupSummary.AsObject) => {
    const ruleGroup = new RuleGroupSummary();
    ruleGroup.setApplyAndBetweenRules(g.applyAndBetweenRules);
    ruleGroup.setRulesList(g.rulesList.map((r: RuleSummary.AsObject) => {
      const rule = new RuleSummary();
      rule.setId(r.id);

      if (r.dataField) {
        const dataFieldTemplate = new DataFieldTemplateSummary();
        dataFieldTemplate.setId(r.dataField.id);
        dataFieldTemplate.setName(r.dataField.name);

        dataFieldTemplate.setDataType(r.dataField.dataType);
        rule.setDataField(dataFieldTemplate);
      }
      if (r.operator) {
        const operator = new OperatorSummary();
        operator.setId(r.operator.id);
        operator.setDisplayName(r.operator.displayName);
        operator.setComparableValueRequired(r.operator.comparableValueRequired);
        rule.setOperator(operator);
      }
      rule.setComparableValue(r.comparableValue);
      rule.setFieldLibraryOptionId(r.fieldLibraryOptionId);
      return rule;
    }));
    return ruleGroup;
  }));
  return aggregator;
};

export const convertParametrizedTriggerSummaryToInstance = (summary: FeeParametrizedRuleAggregatorSummary.AsObject): FeeParametrizedRuleAggregatorSummary => {
  const aggregator = new FeeParametrizedRuleAggregatorSummary();
  aggregator.setApplyAndBetweenGroups(summary.applyAndBetweenGroups);
  aggregator.setGroupsList(summary.groupsList.map((g: FeeParametrizedRuleGroupSummary.AsObject) => {
    const ruleGroup = new FeeParametrizedRuleGroupSummary();
    const feeParameters = new FeeTemplateRuleGroupParameter();
    if (g.feeParameters?.ruleGroupSummaryId) feeParameters.setRuleGroupSummaryId(g.feeParameters.ruleGroupSummaryId);
    if (g.feeParameters?.feeCalculationType) feeParameters.setFeeCalculationType(g.feeParameters.feeCalculationType);
    if (g.feeParameters?.feePercentBasisType) feeParameters.setFeePercentBasisType(g.feeParameters.feePercentBasisType);
    if (g.feeParameters?.feeSpecifiedFixedAmount) feeParameters.setFeeSpecifiedFixedAmount(ConvertProtoDecimalToInstance(g.feeParameters.feeSpecifiedFixedAmount));
    if (g.feeParameters?.feeTotalPercent) feeParameters.setFeeTotalPercent(ConvertProtoDecimalToInstance(g.feeParameters.feeTotalPercent));
    ruleGroup.setFeeParameters(feeParameters);
    ruleGroup.setApplyAndBetweenRules(g.applyAndBetweenRules);
    ruleGroup.setRulesList(g.rulesList.map((r: RuleSummary.AsObject) => {
      const rule = new RuleSummary();
      rule.setId(r.id);

      if (r.dataField) {
        const dataFieldTemplate = new DataFieldTemplateSummary();
        dataFieldTemplate.setId(r.dataField.id);
        dataFieldTemplate.setName(r.dataField.name);

        dataFieldTemplate.setDataType(r.dataField.dataType);
        rule.setDataField(dataFieldTemplate);
      }
      if (r.operator) {
        const operator = new OperatorSummary();
        operator.setId(r.operator.id);
        operator.setDisplayName(r.operator.displayName);
        operator.setComparableValueRequired(r.operator.comparableValueRequired);
        rule.setOperator(operator);
      }
      rule.setComparableValue(r.comparableValue);
      rule.setFieldLibraryOptionId(r.fieldLibraryOptionId);
      return rule;
    }));
    return ruleGroup;
  }));
  return aggregator;
};
