import { DynamicIcon } from '@/Components/Atoms/DynamicIcon';
import { Icon } from '@/Components/Atoms/Icon';
import { Menu } from '@/Components/Atoms/Menu';

export interface ITableFooterProps {
  pageSize: number;
  pageIndex: number;
  setPageSize: (pageSize: number) => void;
  gotoPage: (page: number) => void;
  numberOfPages: number;
  totalCount: number;
}

export const TableFooter = (props: ITableFooterProps) => {
  const {
    gotoPage,
    numberOfPages,
    pageIndex,
    pageSize,
    setPageSize,
    totalCount,
  } = props;

  return (
    <div className="w-full flex items-center justify-between min-h-[56px] py-0 px-3 bg-surface border-t border-on-surface-stroke">
      <div className="flex items-center">
        <span className="text-xs mr-[2px] text-on-surface-inactive">Rows per page:</span>
        <Menu
          alignTop
          closeAfterSelecting
          options={
            [10, 20, 50, 75, 100].map((pageSize) => ({
              label: String(pageSize),
              onClick: () => setPageSize(pageSize),
            }))
          }
          triggerItem={(
            <div className=" flex items-center flex-row">
              <span className="text-xs">
                {pageSize}
              </span>
              <Icon icon="ArrowDropDown" />
            </div>
          )}
        />
        <span className="text-xs ml-6">
          {`${pageIndex === 0 ? 1 : pageIndex * pageSize + 1}-${Math.min(...[pageSize * (pageIndex + 1), totalCount])} of ${totalCount}`}
        </span>
      </div>
      { numberOfPages > 1 && (
        <div className="flex items-center">
          <span className="text-xs mr-[2px]">Page:</span>
          <Menu
            alignTop
            closeAfterSelecting
            options={[...Array(numberOfPages)].map((_, pageIndex: number) => (
              {
                label: String(pageIndex + 1),
                onClick: () => gotoPage(pageIndex),
              }
            ))}
            triggerItem={(
              <div className=" flex items-center flex-row">
                <span className="text-xs">
                  {pageIndex + 1}
                </span>
                <DynamicIcon icon="ArrowDropDown" />
              </div>
            )}
          />
          <span className="text-xs mr-3 ml-2 text-on-surface-inactive">{`of ${numberOfPages}`}</span>
          <Icon
            className="mr-4"
            disabled={pageIndex === 0}
            icon="ChevronLeft"
            onClick={() => gotoPage(pageIndex - 1)}
            variant="interactive"
          />
          <Icon
            disabled={pageIndex === numberOfPages - 1}
            icon="ChevronRight"
            onClick={() => gotoPage(pageIndex + 1)}
            variant="interactive"
          />
        </div>
      )}
    </div>
  );
};
