import { useParams } from 'react-router';

import { useSkillsetGroup } from '@/API/Queries/user/useSkillsetGroup';
import { useUpdateSkillsetGroup } from '@/API/Queries/user/useUpdateSkillsetGroup';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

const SkillsetGroupOverview = () => {
  const { id = '' } = useParams();

  const { data: skillsetGroup, isLoading, refetch } = useSkillsetGroup(id);
  const { isLoading: isSaving, mutate: updateSkillsetGroup } = useUpdateSkillsetGroup();
  const { showSnackBar } = useShared();

  const handleSubmitSuccess = (close: () => void) => {
    close();
    showSnackBar({ message: 'SkillsetGroup updated successfully', open: true });
    refetch();
  };

  const handleSubmitError = (error: any) => showSnackBar({ message: error?.message || 'Something went wrong', open: true });

  const handleSubmit = (values: DynamicDataElementValues, close: () => void) => {
    updateSkillsetGroup({
      displayName: values.displayName.value,
      longDescription: values.description.value,
      skillsetGroupId: id,
      skillsetIds: skillsetGroup?.skillSetsIdsList || [],
      userIds: skillsetGroup?.usersIdsList || [],
    }, {
      onError: handleSubmitError,
      onSuccess: () => handleSubmitSuccess(close),
    });
  };

  return (
    <CardListItem
      headerProps={{ title: 'Skillset Group Details' }}
      isEditable
      isLoading={isLoading}
      isSaving={isSaving}
      listItems={[
        {
          caption: skillsetGroup?.displayName,
          label: 'Skillset Group Name',
          panelElement: getPanelElement({ headerText: 'Skillset Group Name', id: 'displayName', type: 'text' }),
          value: { value: skillsetGroup?.displayName },
        },
        {
          caption: skillsetGroup?.longDescription,
          label: 'Description',
          panelElement: getPanelElement({ headerText: 'Description', id: 'description', type: 'text' }),
          value: { value: skillsetGroup?.longDescription },
        },
      ]}
      onSubmit={handleSubmit}
    />
  );
};

export { SkillsetGroupOverview };
