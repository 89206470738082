import { useEffect, useState } from 'react';

export enum WindowTypeEnum {
  desktop = 'desktop',
  mobile = 'mobile',
  tablet = 'tablet',
}

export type WindowType = keyof typeof WindowTypeEnum;

export const calculateScreenSize = (size: number): WindowType => {
  if (size <= 599) return 'mobile';
  if (size >= 600 && size < 840) return 'tablet';
  return 'desktop';
};

export const useWindow = () => {
  const [windowType, setWindowType] = useState<WindowType>(calculateScreenSize(window.innerWidth));

  useEffect(() => {
    const resizeUpdate = () => {
      const newScreenType = calculateScreenSize(window.innerWidth);
      if (windowType !== newScreenType) {
        setWindowType(calculateScreenSize(window.innerWidth));
      }
    };

    let timeout: any;
    const handleResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(resizeUpdate, 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowType]);

  return {
    isDesktop: windowType === WindowTypeEnum.desktop,
    isMobile: windowType === WindowTypeEnum.mobile,
    isTablet: windowType === WindowTypeEnum.tablet,
    windowType,
  };
};
