import { useMutation } from '@tanstack/react-query';

import { ArchiveBusinessProcessTemplatesCommandResponse } from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands_pb';
import type { ArchiveAdminActivityTemplatesCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { ArchiveAdminActivityTemplatesCommandRequest } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId, useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.activity.ArchiveAdminActivityTemplatesCommandRequest';

interface Params {
  activities: Array<string>;
  archived: boolean;
}

export const useArchiveActivityTemplate = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();
  const institutionId = useInstitutionId();

  return useMutation<ArchiveAdminActivityTemplatesCommandResponse.AsObject, Error, any, any>(async (params: Params) => {
    const request = new ArchiveAdminActivityTemplatesCommandRequest();
    request.setActivityTemplateIdsList(params.activities);
    request.setArchived(params.archived);
    request.setBusinessProcessDomainId(bpdId);
    request.setInstitutionId(institutionId);
    return sendMessage<ArchiveAdminActivityTemplatesCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      ArchiveBusinessProcessTemplatesCommandResponse,
    );
  });
};
