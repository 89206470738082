import clsx from 'clsx';
import { useRef, useState } from 'react';

import { useOnClickOutside } from '@/Utils/Helpers/useOnClickOutside';

import { Chip } from './Chip';
import { MenuOption } from './MenuOption';
import type { TextFieldVariant } from './TextField';
import { TextField } from './TextField';

export interface SearchDropdownOption {
  name: string;
  id: string;
}

export interface SearchDropdownGroup {
  id: string;
  name: string;
  options: Array<SearchDropdownOption>;
}

export interface SearchDropdownProps {
  onSelectItem: (item?: SearchDropdownOption, groupId?: string) => void;
  onChangeInputValue: (inputValue: string) => void;
  optionsGroup: Array<SearchDropdownGroup>;
  selectedItem?: string;
  className?: string;
  variant?: TextFieldVariant;
}

const SearchDropdown = (props: SearchDropdownProps) => {
  const { className, onChangeInputValue, onSelectItem, optionsGroup, selectedItem, variant = 'default' } = props;

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<SearchDropdownGroup | null>(null);
  const [searchText, setSearchText] = useState('');
  const searchFieldRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchFieldRef, () => setIsOptionsOpen(false));

  const handleFieldClick = (item: any) => {
    onSelectItem(item, selectedGroup?.id);
    setIsOptionsOpen(false);
  };

  const handleListItemClick = (item: any) => {
    setSelectedGroup(item);
  };

  const handleOnCloseClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onSelectItem(undefined);
    setSearchText('');
    setIsOptionsOpen(false);
  };

  const handleInputChange = (value: string) => {
    onChangeInputValue(value);
    setSearchText(value);
  };

  const renderHeader = () => {
    if (selectedGroup) {
      return (
        <div className="border-b border-b-on-surface-stroke">
          <MenuOption
            label={selectedGroup.name}
            leadingIcon="ArrowBack"
            onClick={() => setSelectedGroup(null)}
          />
        </div>
      );
    }
    return null;
  };

  const renderInput = () => (
    <div
      onClick={() => setIsOptionsOpen(true)}
      onKeyDown={() => setIsOptionsOpen(true)}
      role="button"
      tabIndex={0}
    >
      <TextField
        onChange={(event) => handleInputChange(event.target.value)}
        placeholder=""
        trailingIcon={{ icon: isOptionsOpen ? 'ArrowDropUp' : 'ArrowDropDown' }}
        value={searchText}
        variant={variant}
      >
        { selectedItem && (
        <Chip
          label={selectedItem}
          onClickClose={handleOnCloseClick}
          selected
        />
        )}
      </TextField>
    </div>
  );

  const getItemIcon = () => {
    if (!selectedGroup) return 'ChevronRight';
    return undefined;
  };

  const renderList = () => {
    if (selectedGroup) {
      return selectedGroup.options.map((option) => (
        <MenuOption
          key={option.id}
          label={option.name}
          onClick={() => handleFieldClick(option)}
          trailingIcon={getItemIcon()}
        />
      ));
    }
    return optionsGroup.map((group) => (
      <MenuOption
        key={group.id}
        label={group.name}
        onClick={() => handleListItemClick(group)}
        trailingIcon={getItemIcon()}
      />
    ));
  };

  return (
    <div
      ref={searchFieldRef}
      className={
        clsx(
          'relative w-full',
          className,
        )
      }
    >
      {renderInput()}
      {isOptionsOpen && (
        <div
          className="flex flex-col absolute top-[100%] w-full z-10 shadow-one max-h-[250px] bg-surface-background"
        >
          {renderHeader()}
          <div className="flex-1 overflow-auto [scrollbar-width:none]">
            {renderList()}
          </div>
        </div>
      )}
    </div>
  );
};

export { SearchDropdown };
