import { v4 as uuid } from 'uuid';

import type { RuleAggregatorSummary, RuleGroupSummary, RuleSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';
import { fromDeprecatedProgress, toDeprecatedProgress } from '@/Utils/Helpers/progress';

import type { DynamicDataElementValues, FormProgress } from '../dynamicForm/DynamicFormContext';

export const getTriggerFormProgress = (trigger: RuleAggregatorSummary.AsObject): FormProgress => {
  const applyAndBetweenGroups = { value: trigger.applyAndBetweenGroups };
  const groupValues = trigger.groupsList.reduce((acc, group) => {
    const groupCondition = { value: group.applyAndBetweenRules };
    let rules = {};
    const groupKey = `group-${group.id}`;
    if (group.rulesList.length > 0) {
      rules = group.rulesList.reduce((acc, rule) => ({
        ...acc,
        [`${groupKey}-rule-${rule.id}-field`]: { value: rule.dataField },
        [`${groupKey}-rule-${rule.id}-operator`]: {
          value: getPanelElementOption({ headerText: rule.operator?.displayName, id: String(rule.operator?.id) }),
        },
        [`${groupKey}-rule-${rule.id}-value`]: { value: fromDeprecatedProgress(rule.comparableValue, rule.operator?.panelElementTypeOverride || rule.dataField?.panelElementType || '') },
      }), {});
    } else {
      const emptyRuleKey = `${groupKey}-rule-${uuid()}`;
      rules = {
        [`${emptyRuleKey}-field`]: { value: {} },
        [`${emptyRuleKey}-operator`]: { value: {} },
        [`${emptyRuleKey}-value`]: { value: {} },
      };
    }
    return {
      ...rules,
      [`${groupKey}-condition`]: groupCondition,
    };
  }, {});

  return {
    values: {
      ...groupValues,
      applyAndBetweenGroups,
    },
  };
};

export const getRulePanelElementType = (fieldPanelElementType: string, operatorOveride?: string) => {
  let type = fieldPanelElementType;
  if (fieldPanelElementType === 'radioGroup') {
    type = 'select';
  }
  if (operatorOveride) {
    type = operatorOveride;
  }
  return type;
};

export const getFormValuesToRuleAggregator = (formValues: DynamicDataElementValues, trigger: RuleAggregatorSummary.AsObject): RuleAggregatorSummary.AsObject => {
  const validGroups = trigger.groupsList.filter((group) => formValues[`group-${group.id}-condition`]);
  const groupIds = validGroups.map((group) => group.id);
  const groups = groupIds.map((groupId): RuleGroupSummary.AsObject => {
    const groupKey = `group-${groupId}`;
    const group = trigger.groupsList.find((e) => e.id === groupId);
    const completeRules = group?.rulesList.filter((rule) => formValues[`${groupKey}-rule-${rule.id}-field`]?.value) || [];
    const rulesList = completeRules.map((rule): RuleSummary.AsObject => {
      const ruleKey = `${groupKey}-rule-${rule.id}`;
      return {
        comparableValue: toDeprecatedProgress(formValues[`${ruleKey}-value`]?.value) || '',
        dataField: formValues[`${ruleKey}-field`]?.value || undefined,
        fieldLibraryOptionId: rule.fieldLibraryOptionId,
        id: rule.id,
        operator: formValues[`${ruleKey}-operator`] ? {
          comparableValueRequired: false,
          displayName: formValues[`${ruleKey}-operator`]?.value?.headerText,
          id: formValues[`${ruleKey}-operator`]?.value?.id,
          panelElementTypeOverride: '',
        } : undefined,
      };
    }) || [];
    return {
      applyAndBetweenRules: formValues[`${groupKey}-condition`].value,
      id: groupId,
      readOnly: false,
      rulesList,
    };
  });
  return {
    applyAndBetweenGroups: formValues.applyAndBetweenGroups.value,
    groupsList: groups,
  };
};
