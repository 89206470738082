import clsx from 'clsx';
import type { ReactNode } from 'react';

interface AlertProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  children?: ReactNode;
  fashion?: 'danger' | 'success';
}

export const Alert = (props: AlertProps) => {
  const { children, fashion = 'primary', prefix, suffix } = props;
  const background = `bg-status-${fashion}`;
  const text = `text-on-status-${fashion}`;

  return (
    <div className={clsx('p-4', 'flex', 'w-full', background, text)}>
      <div>{prefix}</div>
      <div className="flex-grow">{children}</div>
      <div>{suffix}</div>
    </div>
  );
};
