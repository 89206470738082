import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { AdminLoanPartyRoleTypesEnum, User } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useUpdateUserPartyRoleType } from '@/API/Queries/user/useUpdateUserPartyRoles';
import { useUser } from '@/API/Queries/user/useUser';
import type { IBadgeProps } from '@/Components/Atoms/Badge';
import { Badge } from '@/Components/Atoms/Badge';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

type TableData = {
  badge?: IBadgeProps;
  id: AdminLoanPartyRoleTypesEnum;
  name: string;
};

const isAssigned = (user: User.AsObject, role: AdminLoanPartyRoleTypesEnum) => user?.partyRoleTypesList.some((userRole) => userRole.id === role);

const UserPartyRoles = () => {
  const { id = '' } = useParams();
  const { data: user, refetch } = useUser(id);
  const { mutate: updateUserPartyRole } = useUpdateUserPartyRoleType();
  const { showSnackBar } = useShared();
  const [selectedItems, setSelectedItems] = useState<Array<TableData>>([]);

  const handleUpdateSuccess = () => {
    refetch();
    showSnackBar({ message: `${user?.displayName} party role successfully updated`, open: true });
  };

  const handleAssigments = (loanRoleTypes: Array<AdminLoanPartyRoleTypesEnum>) => {
    updateUserPartyRole(
      {
        loanRoleTypes,
        userId: id,
      },
      {
        onError: () => showSnackBar({ message: 'Something went wrong, try again later', open: true }),
        onSuccess: handleUpdateSuccess,
      },
    );
  };

  const handleAssignmentClick = (item: TableData) => {
    if (user) {
      let loanRoleTypes: Array<AdminLoanPartyRoleTypesEnum> = [];
      if (Array.isArray(item)) {
        loanRoleTypes = item;
      } else if (isAssigned(user, item.id)) loanRoleTypes = user.partyRoleTypesList.filter((userRole) => userRole.id !== item.id).map((e) => e.id);
      else loanRoleTypes = user.partyRoleTypesList.map((e) => e.id).concat(item.id);
      handleAssigments(loanRoleTypes);
    }
  };

  const handleBulkAssignment = (assign: boolean) => {
    let newRoles: Array<AdminLoanPartyRoleTypesEnum> = [];
    const currentRoles = user?.partyRoleTypesList.map((e) => e.id) || [];
    const selectedRoles = selectedItems.map((e) => e.id);
    if (assign) {
      newRoles = [...currentRoles, ...selectedRoles];
    } else {
      newRoles = currentRoles.filter((e) => !selectedRoles.some((i) => i === e));
    }
    handleAssigments(newRoles);
  };

  const handleSetSelection = useCallback((newList: any) => {
    setSelectedItems(newList);
  }, []);

  const renderAssignmentBadge = useCallback((currentData: TableData) => {
    if (user && isAssigned(user, currentData.id)) {
      return <Badge label="Assigned" variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_SUCCESS} />;
    }
    return null;
  }, [user]);

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', 'name'],
    ['Assignment', { type: 'any', value: renderAssignmentBadge }],
  ], [renderAssignmentBadge]);

  return (
    <TableBuilder
      allowSearch
      cardProps={{
        headerProps: {
          contextualHeaderProps: {
            buttons: [
              { label: 'Assign', onClick: () => handleBulkAssignment(true) },
              { label: 'Unassign', onClick: () => handleBulkAssignment(false) },
            ],
            caption: `${selectedItems.length} roles selected`,
          },
          showContextual: selectedItems.length > 0,
          title: 'All Party Roles',
        },
      }}
      columns={columns}
      dataType={TableBuilderDataTypeEnum.PARTY_ROLES}
      hoverActions={[
        {
          label: (item: TableData) => (user && isAssigned(user, item.id) ? 'Unassign' : 'Assign'),
          onClick: handleAssignmentClick,
        },
      ]}
      orderBy="Assignment"
      setRowSelection={handleSetSelection}
    />
  );
};

export { UserPartyRoles };
