import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import type {
  LoanPrepaidFee,
  LoanPrepaidFeeCategory,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { LoanFeeCategoryTypeEnum, PrepaidItemEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { Icon } from '@/Components/Atoms/Icon';
import { Input } from '@/Components/Atoms/Input';
import { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Tooltip } from '@/Components/Atoms/Tooltip';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import {
  ColumnEnum,
  emptyValue,
  ESCROWS_PREPAIDS_COLUMNS_EDIT,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import { calculateOverrides } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/overridesFeesHelper';
import FeeOverrideControls from '@/Routes/Pages/loan/PurposeBuilt/Fees/Widgets/FeeOverrideControls';
import { pluralizeDays, pluralizeMonths } from '@/Utils/Helpers/dateHelpers';
import { convertProtoToCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import { ConvertProtoDecimalAsObjectToNumber } from '@/Utils/Helpers/protoDecimalConversion';

export interface FeeEditTotalsProps {
  onEditFee: (
    fee: LoanPrepaidFee.AsObject,
    categoryType: LoanFeeCategoryTypeEnum,
  ) => void;
}

const Prepaids = (props: FeeEditTotalsProps) => {
  const { onEditFee } = props;
  const [copyLoanPrepaidFees, setCopyLoanPrepaidFees] = useState<Array<LoanPrepaidFeeCategory.AsObject>>([]);

  const {
    handleBlurInput,
    hasError,
    loanPrepaidFees,
    overrideErrors,
    totalMet,
  } = useFeesContext();

  useEffect(() => {
    setCopyLoanPrepaidFees(cloneDeep(loanPrepaidFees));
  }, [loanPrepaidFees]);

  const handleEditIndividualFees = (
    fee: LoanPrepaidFee.AsObject,
    categoryType: LoanFeeCategoryTypeEnum,
  ) => {
    onEditFee(fee, categoryType);
  };

  return (
    <div>
      {loanPrepaidFees ? loanPrepaidFees.map((loanFeeCategory: LoanPrepaidFeeCategory.AsObject) => {
        const getCurrentItemCategory = (value: string | undefined, column: string, fee: LoanPrepaidFee.AsObject) => {
          handleBlurInput(value, { key: column }, fee, loanFeeCategory, undefined, undefined);
        };

        return (
          <Card
            key={loanFeeCategory.category}
            defaultOpen
            headerProps={{
              caption: 'Total',
              captionStrong: loanFeeCategory.sumTotal ? numberFormatter.format(ConvertProtoDecimalAsObjectToNumber(loanFeeCategory.sumTotal)) : '',
              title: loanFeeCategory.category,
            }}
            variant="border"
          >
            <table className="w-full">
              <thead className="border-b border-b-on-surface-stroke">
                <tr>
                  {ESCROWS_PREPAIDS_COLUMNS_EDIT.map((column, index) => (
                    <th
                      key={`${column.id}`}
                      className={clsx(
                        'px-3 py-3 text-center border-r border-r-on-surface-stroke',
                        {
                          '!border-r-0': index === ESCROWS_PREPAIDS_COLUMNS_EDIT.length - 1,
                          '!text-left w-[450px]': index === 0,
                        },
                      )}
                      scope="col"
                    >
                      <Typography className="uppercase text-on-surface-inactive" variant="overline">
                        {column.header}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loanFeeCategory.feesList.map((fee) => (
                  <tr
                    key={`table-row-${fee.feeId}`}
                    className="transition duration-150 ease-in-out hover:bg-on-surface-states-hover"
                    id={`table-row-${fee.feeId}`}
                  >
                    {ESCROWS_PREPAIDS_COLUMNS_EDIT?.map((column) => {
                      const disabled = (fee.feeId !== totalMet.feeId && totalMet.exceeded) || column.hidden;
                      const paidBySellerCreditAmount = ConvertProtoDecimalAsObjectToNumber(fee.prepaidPayment?.paidBySellerCreditAmount);
                      const paidByLenderCreditAmount = ConvertProtoDecimalAsObjectToNumber(fee.prepaidPayment?.paidByLenderCreditAmount);
                      const sellerCreditAmount = paidBySellerCreditAmount > 0;
                      const lenderCreditAmount = paidByLenderCreditAmount > 0;
                      const value = fee[column.id as keyof Omit<LoanPrepaidFee.AsObject, 'feeId' | 'feeName' | 'isapr' | 'paidto'>];

                      if (column.id === ColumnEnum.NAME) {
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className={clsx(
                              'px-6 py-4 w-[450px] align-top',
                              {
                                'border border-danger-on-surface': fee.feeId in overrideErrors,
                                'border-r border-r-on-surface-stroke': !(fee.feeId in overrideErrors),
                              },
                            )}
                          >
                            <FeeOverrideControls
                              key={fee.feeId}
                              categoryType={LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID}
                              fee={fee}
                              handleClear={() => {
                                calculateOverrides(
                                  'clear',
                                  fee,
                                  copyLoanPrepaidFees,
                                  undefined,
                                );
                              }}
                              handleIndicatorOverride={(value, controlName, fee) => {
                                const overrideFee = calculateOverrides(
                                  controlName,
                                  fee,
                                  copyLoanPrepaidFees,
                                  String(value),
                                );

                                handleBlurInput(String(value), { key: controlName }, overrideFee, loanFeeCategory, undefined, undefined);
                              }}
                              handleTimeframeOverride={(value, controlName, fee) => {
                                const overrideFee = calculateOverrides(
                                  controlName,
                                  fee,
                                  copyLoanPrepaidFees,
                                  String(value),
                                );

                                handleBlurInput(String(value), { key: controlName }, overrideFee, loanFeeCategory, undefined, undefined);
                              }}
                              handleTotalOverride={(value, controlName, fee) => {
                                const overrideFee = calculateOverrides(
                                  controlName,
                                  fee,
                                  copyLoanPrepaidFees,
                                  String(value),
                                );

                                handleBlurInput(String(value), { key: controlName }, overrideFee, loanFeeCategory, undefined, undefined);
                              }}
                              id={fee.feeId}
                              onEditFee={(fee, categoryType) => handleEditIndividualFees(fee, categoryType)}
                              viewOnly={false}
                            />
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.AMOUNT) {
                        const useDays = fee.prepaidPayment?.prepaidType === PrepaidItemEnum.PREPAID_ITEM_ENUM_PREPAID_INTEREST;

                        const renderAmount = () => {
                          if (fee.manualOverrideIndicator && (!fee.manualOverrideTimeframe && !fee.manualOverrideTotalAmount)) {
                            return String(convertProtoToCurrency(fee.amount));
                          }

                          if (fee.manualOverrideIndicator && !fee.manualOverrideTimeframe) {
                            return fee.manualOverrideTotalAmount ? convertProtoToCurrency(fee.manualOverrideTotalAmount) : '--';
                          }
                          return convertProtoToCurrency(fee.amount || 0);
                        };

                        const renderTimeFrame = () => {
                          if (fee.manualOverrideIndicator && fee.manualOverrideTotalAmount) {
                            return '--';
                          }
                          if (fee.manualOverrideIndicator && !fee.manualOverrideTotalAmount) {
                            if (useDays) {
                              return fee.manualOverrideTimeframe ? pluralizeDays(fee.manualOverrideTimeframe || 0) : '--';
                            }
                            return fee.manualOverrideTimeframe ? pluralizeMonths(fee.manualOverrideTimeframe || 0) : '--';
                          }

                          return useDays ? pluralizeDays(fee.timeframe || 0) : pluralizeMonths(fee.timeframe || 0);
                        };

                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className={clsx('text-sm text-on-surface-active font-normal px-6 py-4 whitespace-nowrap w-[145px] align-top',
                              { 'border-r border-r-on-surface-stroke': true, 'text-right': true })}
                          >
                            <span className="mt-2 block">{renderAmount()}</span>
                            <Typography
                              className="text-on-surface-inactive"
                              variant="caption"
                            >
                              {renderTimeFrame()}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.BORROWER_PAID) {
                        const pocAmount = fee.prepaidPayment?.paidByBorrowerOutsideOfClosingAmount
                          ? ConvertProtoDecimalAsObjectToNumber(fee.prepaidPayment?.paidByBorrowerOutsideOfClosingAmount) : 0;

                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-4 border-r border-r-on-surface-stroke relative align-top"
                          >
                            <Input
                              disabled
                              displayOnly
                              id={`${column.id}-${fee.feeId}`}
                              mask={MaskType.CURRENCY}
                              name={column.id}
                              onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                              value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                            />
                            <Typography
                              className={clsx('text-on-surface-inactive', { invisible: !pocAmount })}
                              variant="caption"
                            >
                              {`${convertProtoToCurrency(pocAmount)} (POC)`}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.LENDER_PAID) {
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-4 border-r border-r-on-surface-stroke relative align-top"
                          >
                            <Input
                              disabled={disabled}
                              displayOnly
                              id={`${column.id}-${fee.feeId}`}
                              mask={MaskType.CURRENCY}
                              name={column.id}
                              onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                              value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                            />
                            <Typography
                              className={clsx('text-on-surface-inactive', { invisible: !lenderCreditAmount })}
                              variant="caption"
                            >
                              {`${convertProtoToCurrency(paidByLenderCreditAmount)} (credit)`}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.SELLER_PAID) {
                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-3 py-4 border-r border-r-on-surface-stroke relative align-top"
                          >
                            <Input
                              disabled={disabled}
                              displayOnly
                              id={`${column.id}-${fee.feeId}`}
                              mask={MaskType.CURRENCY}
                              name={column.id}
                              onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                              value={String(value ? convertProtoToCurrency(value) : emptyValue())}
                            />
                            <Typography
                              className={clsx('text-on-surface-inactive', { invisible: !sellerCreditAmount })}
                              variant="caption"
                            >
                              {`${convertProtoToCurrency(paidBySellerCreditAmount)} (credit)`}
                            </Typography>
                          </td>
                        );
                      }

                      if (column.id === ColumnEnum.TOTAL) {
                        const feeInErrorArray = hasError(fee.feeId, LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID);

                        const renderTotal = () => {
                          if (fee.manualOverrideIndicator) {
                            if (!fee.manualOverrideTimeframe && !fee.manualOverrideTotalAmount) {
                              return String(convertProtoToCurrency(fee.manualOverrideTotalAmount));
                            }
                            return String(convertProtoToCurrency(fee.total));
                          }
                          return String(convertProtoToCurrency(fee.calculatedTotalAmount));
                        };

                        return (
                          <td
                            key={`${column.id}-${fee.feeId}`}
                            className="px-6 py-4 min-w-[180px] relative align-top"
                          >
                            <div className="flex items-center justify-end gap-3">
                              {
                              ((totalMet.feeId === fee.feeId && totalMet.exceeded && totalMet.category === LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID) || feeInErrorArray) && (
                                <div>
                                  <Tooltip message={totalMet.message || 'Amount entered exceeds total'}>
                                    <Icon className="!text-danger" icon="Error" />
                                  </Tooltip>
                                </div>
                              )
                            }
                              <div className="flex-grow">
                                <Input
                                  disabled
                                  displayOnly
                                  id={`${column.id}-${fee.feeId}`}
                                  mask={MaskType.CURRENCY}
                                  name={column.id}
                                  onBlur={(e) => getCurrentItemCategory(e.target.value, column.id, fee)}
                                  value={renderTotal()}
                                />
                              </div>
                            </div>
                          </td>
                        );
                      }
                      return null;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        );
      }) : (
        <ContingencyMessage
          icon="NoItems"
          subtitle="No fees found"
          title="No results, yet"
          variant="image"
        />
      )}
    </div>
  );
};

export default Prepaids;
