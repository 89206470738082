// source: Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_DynamicModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcess', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessLog', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessStatus', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessSummary', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessTemplateDesign', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary', null, global);
goog.exportSymbol('proto.wilqo.api.business_process.ExecutedBusinessProcess', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.BusinessProcessTemplateDesign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.BusinessProcessTemplateDesign.displayName = 'proto.wilqo.api.business_process.BusinessProcessTemplateDesign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.displayName = 'proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.BusinessProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.BusinessProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.BusinessProcess.displayName = 'proto.wilqo.api.business_process.BusinessProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.BusinessProcessSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.BusinessProcessSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.BusinessProcessSummary.displayName = 'proto.wilqo.api.business_process.BusinessProcessSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.displayName = 'proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.business_process.ExecutedBusinessProcess.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.business_process.ExecutedBusinessProcess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.ExecutedBusinessProcess.displayName = 'proto.wilqo.api.business_process.ExecutedBusinessProcess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.business_process.BusinessProcessLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.business_process.BusinessProcessLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.business_process.BusinessProcessLog.displayName = 'proto.wilqo.api.business_process.BusinessProcessLog';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.BusinessProcessTemplateDesign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastModified: (f = msg.getLastModified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    creationDate: (f = msg.getCreationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastPublishDate: (f = msg.getLastPublishDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    internalName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    loanProgressItemIdentifier: jspb.Message.getFieldWithDefault(msg, 12, ""),
    completeProgressItemOnBPCompletion: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    allowMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    allowConcurrentInstances: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    lastPublishedBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    lastPublishedByDisplayName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    dynamicTrigger: (f = msg.getDynamicTrigger()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.BusinessProcessTemplateDesign;
  return proto.wilqo.api.business_process.BusinessProcessTemplateDesign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    case 8:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationDate(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPublishDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProgressItemIdentifier(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteProgressItemOnBPCompletion(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstances(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowConcurrentInstances(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPublishedBy(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPublishedByDisplayName(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 19:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.deserializeBinaryFromReader);
      msg.setDynamicTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.BusinessProcessTemplateDesign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getCreationDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastPublishDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLoanProgressItemIdentifier();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCompleteProgressItemOnBPCompletion();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getAllowMultipleInstances();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAllowConcurrentInstances();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getLastPublishedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLastPublishedByDisplayName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getDynamicTrigger();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_modified = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getLastModified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BusinessProcessTypeEnum type = 6;
 * @return {!proto.wilqo.api.business_process.BusinessProcessTypeEnum}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregator trigger_rules = 7;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregator}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator, 7));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregator|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Any settings = 8;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getSettings = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 8));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp creation_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getCreationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setCreationDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.clearCreationDate = function() {
  return this.setCreationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.hasCreationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp last_publish_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getLastPublishDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setLastPublishDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.clearLastPublishDate = function() {
  return this.setLastPublishDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.hasLastPublishDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string internal_name = 11;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string loan_progress_item_identifier = 12;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getLoanProgressItemIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setLoanProgressItemIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool complete_progress_item_on_b_p_completion = 13;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getCompleteProgressItemOnBPCompletion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setCompleteProgressItemOnBPCompletion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool allow_multiple_instances = 14;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getAllowMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setAllowMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool allow_concurrent_instances = 15;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getAllowConcurrentInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setAllowConcurrentInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string last_published_by = 16;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getLastPublishedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setLastPublishedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string last_published_by_display_name = 17;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getLastPublishedByDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setLastPublishedByDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool archived = 18;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional wilqo.shared.rulesengine.DynamicRuleAggregator dynamic_trigger = 19;
 * @return {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.getDynamicTrigger = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DynamicRuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator, 19));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.setDynamicTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesign} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.clearDynamicTrigger = function() {
  return this.setDynamicTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesign.prototype.hasDynamicTrigger = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastModified: (f = msg.getLastModified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    creationDate: (f = msg.getCreationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastPublishDate: (f = msg.getLastPublishDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    internalName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    loanProgressItemIdentifier: jspb.Message.getFieldWithDefault(msg, 10, ""),
    allowMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    allowConcurrentInstances: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    skillSetsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary;
  return proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationDate(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPublishDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProgressItemIdentifier(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstances(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowConcurrentInstances(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCreationDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastPublishDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLoanProgressItemIdentifier();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAllowMultipleInstances();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getAllowConcurrentInstances();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_modified = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getLastModified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BusinessProcessTypeEnum type = 6;
 * @return {!proto.wilqo.api.business_process.BusinessProcessTypeEnum}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp creation_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getCreationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setCreationDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.clearCreationDate = function() {
  return this.setCreationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.hasCreationDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp last_publish_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getLastPublishDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setLastPublishDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.clearLastPublishDate = function() {
  return this.setLastPublishDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.hasLastPublishDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string internal_name = 9;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string loan_progress_item_identifier = 10;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getLoanProgressItemIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setLoanProgressItemIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool allow_multiple_instances = 11;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getAllowMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setAllowMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool allow_concurrent_instances = 12;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getAllowConcurrentInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setAllowConcurrentInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool archived = 13;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * repeated string skill_sets = 14;
 * @return {!Array<string>}
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.getSkillSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.setSkillSetsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.addSkillSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessTemplateDesignSummary.prototype.clearSkillSetsList = function() {
  return this.setSkillSetsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.BusinessProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.BusinessProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    templateId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    templateVersion: jspb.Message.getFieldWithDefault(msg, 5, 0),
    started: (f = msg.getStarted()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    scope: jspb.Message.getFieldWithDefault(msg, 7, ""),
    settings: (f = msg.getSettings()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.BusinessProcess}
 */
proto.wilqo.api.business_process.BusinessProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.BusinessProcess;
  return proto.wilqo.api.business_process.BusinessProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.BusinessProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.BusinessProcess}
 */
proto.wilqo.api.business_process.BusinessProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTemplateVersion(value);
      break;
    case 6:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setStarted(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 8:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.BusinessProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.BusinessProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStarted();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 version = 3;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string template_id = 4;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 template_version = 5;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Duration started = 6;
 * @return {?proto.google.protobuf.Duration}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getStarted = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 6));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
*/
proto.wilqo.api.business_process.BusinessProcess.prototype.setStarted = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.clearStarted = function() {
  return this.setStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.hasStarted = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string scope = 7;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Any settings = 8;
 * @return {?proto.google.protobuf.Any}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getSettings = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 8));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
*/
proto.wilqo.api.business_process.BusinessProcess.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional BusinessProcessTypeEnum type = 9;
 * @return {!proto.wilqo.api.business_process.BusinessProcessTypeEnum}
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @return {!proto.wilqo.api.business_process.BusinessProcess} returns this
 */
proto.wilqo.api.business_process.BusinessProcess.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.BusinessProcessSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.BusinessProcessSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    templateVersion: jspb.Message.getFieldWithDefault(msg, 4, 0),
    scope: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    started: (f = msg.getStarted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    duration: (f = msg.getDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.BusinessProcessSummary;
  return proto.wilqo.api.business_process.BusinessProcessSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.BusinessProcessSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTemplateVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScope(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarted(value);
      break;
    case 8:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.BusinessProcessSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.BusinessProcessSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTemplateVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getScope();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStarted();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_id = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 template_version = 4;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getTemplateVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setTemplateVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string scope = 5;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getScope = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setScope = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional BusinessProcessTypeEnum type = 6;
 * @return {!proto.wilqo.api.business_process.BusinessProcessTypeEnum}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessTypeEnum} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp started = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getStarted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setStarted = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.clearStarted = function() {
  return this.setStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.hasStarted = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Duration duration = 8;
 * @return {?proto.google.protobuf.Duration}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.getDuration = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 8));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessSummary.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creationDate: (f = msg.getCreationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastPublishedDate: (f = msg.getLastPublishedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validationsCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary;
  return proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreationDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastPublishedDate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidationsCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreationDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastPublishedDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidationsCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp creation_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.getCreationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.setCreationDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.clearCreationDate = function() {
  return this.setCreationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.hasCreationDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp last_published_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.getLastPublishedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} returns this
*/
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.setLastPublishedDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.clearLastPublishedDate = function() {
  return this.setLastPublishedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.hasLastPublishedDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 validations_count = 4;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.getValidationsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary} returns this
 */
proto.wilqo.api.business_process.BusinessProcessValidationChecklistSummary.prototype.setValidationsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.ExecutedBusinessProcess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.ExecutedBusinessProcess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdIn: (f = msg.getCreatedIn()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : [],
    logList: jspb.Message.toObjectList(msg.getLogList(),
    proto.wilqo.api.business_process.BusinessProcessLog.toObject, includeInstance),
    manuallyTriggered: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    manuallyTriggeredBy: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.ExecutedBusinessProcess;
  return proto.wilqo.api.business_process.ExecutedBusinessProcess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.ExecutedBusinessProcess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedIn(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 10:
      var value = new proto.wilqo.api.business_process.BusinessProcessLog;
      reader.readMessage(value,proto.wilqo.api.business_process.BusinessProcessLog.deserializeBinaryFromReader);
      msg.addLog(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManuallyTriggered(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setManuallyTriggeredBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.ExecutedBusinessProcess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.ExecutedBusinessProcess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedIn();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLogList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.wilqo.api.business_process.BusinessProcessLog.serializeBinaryToWriter
    );
  }
  f = message.getManuallyTriggered();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getManuallyTriggeredBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 9;
 * @return {string}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string business_process_id = 1;
 * @return {string}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_id = 3;
 * @return {string}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_in = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getCreatedIn = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
*/
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setCreatedIn = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.clearCreatedIn = function() {
  return this.setCreatedIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.hasCreatedIn = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BusinessProcessStatusEnum status = 6;
 * @return {!proto.wilqo.api.business_process.BusinessProcessStatusEnum}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessStatusEnum} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * map<string, string> tags = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
  return this;
};


/**
 * repeated BusinessProcessLog log = 10;
 * @return {!Array<!proto.wilqo.api.business_process.BusinessProcessLog>}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getLogList = function() {
  return /** @type{!Array<!proto.wilqo.api.business_process.BusinessProcessLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.business_process.BusinessProcessLog, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.business_process.BusinessProcessLog>} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
*/
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setLogList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.addLog = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.business_process.BusinessProcessLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.clearLogList = function() {
  return this.setLogList([]);
};


/**
 * optional bool manually_triggered = 11;
 * @return {boolean}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getManuallyTriggered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setManuallyTriggered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string manually_triggered_by = 12;
 * @return {string}
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.getManuallyTriggeredBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.ExecutedBusinessProcess} returns this
 */
proto.wilqo.api.business_process.ExecutedBusinessProcess.prototype.setManuallyTriggeredBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.business_process.BusinessProcessLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.business_process.BusinessProcessLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    sequence: jspb.Message.getFieldWithDefault(msg, 1, 0),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    errorOcurred: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    log: jspb.Message.getFieldWithDefault(msg, 5, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog}
 */
proto.wilqo.api.business_process.BusinessProcessLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.business_process.BusinessProcessLog;
  return proto.wilqo.api.business_process.BusinessProcessLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.business_process.BusinessProcessLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog}
 */
proto.wilqo.api.business_process.BusinessProcessLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSequence(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.business_process.BusinessProcessStatusEnum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setErrorOcurred(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.business_process.BusinessProcessLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.business_process.BusinessProcessLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.business_process.BusinessProcessLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getErrorOcurred();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 sequence = 1;
 * @return {number}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional BusinessProcessStatusEnum status = 2;
 * @return {!proto.wilqo.api.business_process.BusinessProcessStatusEnum}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.business_process.BusinessProcessStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.business_process.BusinessProcessStatusEnum} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
*/
proto.wilqo.api.business_process.BusinessProcessLog.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool error_ocurred = 4;
 * @return {boolean}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.getErrorOcurred = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.setErrorOcurred = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string log = 5;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string summary = 6;
 * @return {string}
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.business_process.BusinessProcessLog} returns this
 */
proto.wilqo.api.business_process.BusinessProcessLog.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.business_process.BusinessProcessTypeEnum = {
  BUSINESS_PROCESS_TYPE_ENUM_UNDEFINED: 0,
  BUSINESS_PROCESS_TYPE_ENUM_ACTIVITY: 1,
  BUSINESS_PROCESS_TYPE_ENUM_APPRAISAL_VALIDATION: 2,
  BUSINESS_PROCESS_TYPE_ENUM_LOAN_ID: 4,
  BUSINESS_PROCESS_TYPE_ENUM_AUS: 6,
  BUSINESS_PROCESS_TYPE_ENUM_APPRAISAL_ORDER: 7,
  BUSINESS_PROCESS_TYPE_ENUM_DEMO_AUS: 8,
  BUSINESS_PROCESS_TYPE_ENUM_PROGRESS_ITEM_PROCESS: 11,
  BUSINESS_PROCESS_TYPE_ENUM_SET_FIELD_VALUES: 13,
  BUSINESS_PROCESS_TYPE_ENUM_APPLY_FEE_TEMPLATES: 14,
  BUSINESS_PROCESS_TYPE_ENUM_DOCUMENT_DATA_COLLECTION: 15,
  BUSINESS_PROCESS_TYPE_ENUM_LODESTAR_FEES: 16,
  BUSINESS_PROCESS_TYPE_ENUM_FLOOD_DATA: 17,
  BUSINESS_PROCESS_TYPE_ENUM_APPLY_LENDER_CONDITION: 18,
  BUSINESS_PROCESS_TYPE_ENUM_ACTIVITY_PER_ASSET_DEMO: 21,
  BUSINESS_PROCESS_TYPE_ENUM_MANUAL_DOCUTECH_DOCUMENTLIST: 23,
  BUSINESS_PROCESS_TYPE_ENUM_MANUAL_AUTOMATED_UNDERWRITTING: 24,
  BUSINESS_PROCESS_TYPE_ENUM_LOAN_LEVEL_INTENT_TO_PROCEED: 27,
  BUSINESS_PROCESS_TYPE_ENUM_MANUAL_GET_LENDERS: 29,
  BUSINESS_PROCESS_TYPE_ENUM_MANUAL_GET_AGENTS: 33,
  BUSINESS_PROCESS_TYPE_ENUM_SET_FIELD_VALUES_DYNAMIC: 34,
  BUSINESS_PROCESS_TYPE_ENUM_CREATE_LOAN_EVENT: 35
};

/**
 * @enum {number}
 */
proto.wilqo.api.business_process.BusinessProcessStatus = {
  BUSINESSPROCESSSTATUS_EMPTY: 0,
  BUSINESSPROCESSSTATUS_AWAITINGACTIVATION: 2,
  BUSINESSPROCESSSTATUS_ACTIVE: 3,
  BUSINESSPROCESSSTATUS_COMPLETE: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.business_process.BusinessProcessStatusEnum = {
  BUSINESS_PROCESS_STATUS_ENUM_UNKNOWN: 0,
  BUSINESS_PROCESS_STATUS_ENUM_NEW: 1,
  BUSINESS_PROCESS_STATUS_ENUM_ACTIVE: 2,
  BUSINESS_PROCESS_STATUS_ENUM_PAUSED: 3,
  BUSINESS_PROCESS_STATUS_ENUM_CANCELED: 4,
  BUSINESS_PROCESS_STATUS_ENUM_ERROR: 5,
  BUSINESS_PROCESS_STATUS_ENUM_COMPLETED: 6
};

goog.object.extend(exports, proto.wilqo.api.business_process);
