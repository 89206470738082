import { FieldAttachment } from "@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb";
import { toString } from "./dynamicDataElementHelper";
import { DynamicDataElement } from "@/API/Models/Wilqo.Shared.Models/DynamicData_pb";
import { snakeCase } from "lodash";

export function getAttachId(attach: FieldAttachment.AsObject) {
  if (attach.sourceItem) {
    const dynamic = new DynamicDataElement();
    dynamic.setDataType(attach.sourceItem?.dataType);
    dynamic.setValue(attach.sourceItem?.value);
    const value = toString(dynamic);
    return `${attach.sourceId}-${snakeCase(value)}-${attach.position}`;
  }

  return `${attach.sourceId}-${attach.position}`;
}