import { useQuery } from '@tanstack/react-query';

import { GetProcessInformationQueryRequest, GetProcessInformationQueryResponse } from '@/API/Models/Wilqo.API.Mortgage.Integration/Queries_pb';
import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage_integration.GetProcessInformationQueryRequest';

export interface ProcessInformationProps {
  processId: string;
  enabled: boolean;
}

const useProcessInformation = (props: ProcessInformationProps) => {
  const sendMessage = useWilqoMessage();
  const institutionId = useInstitutionId();

  return useQuery(
    [MESSAGE_NAME, props.processId],
    async () => {
      const request = new GetProcessInformationQueryRequest();
      request.setInstitutionId(institutionId);
      request.setInstanceId(props.processId);
      return sendMessage<GetProcessInformationQueryResponse.AsObject>(
        {
          msg: request,
          msgName: MESSAGE_NAME,
        }, GetProcessInformationQueryResponse,
      );
    },
    { enabled: props.enabled },
  );
};

export { useProcessInformation };
