import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetDealSummaryConfigurationListByBPDQueryRequest, GetDealSummaryConfigurationListByBPDQueryResponse } from '@/API/Models/Wilqo_API_Brand_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.brand.GetDealSummaryConfigurationListByBPDQueryRequest';

const useDealSummaryConfigurations = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId], async () => {
    const request = new GetDealSummaryConfigurationListByBPDQueryRequest();
    request.setBusinessProcessDomainId(bpdId);

    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetDealSummaryConfigurationListByBPDQueryResponse();
    GetDealSummaryConfigurationListByBPDQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.configurationsList);
  });
};
export { useDealSummaryConfigurations };
