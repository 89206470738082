import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { forwardRef, useMemo, useRef } from 'react';

import { HelperText } from '../HelperText';
import type { TextFieldVariant } from '../TextField';
import { TextField } from '../TextField';

export interface DateMonthYearProps {
  onChange?: (value?: any) => void;
  value?: string;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  variant?: TextFieldVariant;
  errorMessage?: string;
  min?: number;
  max?: number;
}

const DateMonthYear = forwardRef<any, DateMonthYearProps>((props: DateMonthYearProps, ref) => {
  const {
    className,
    disabled = false,
    errorMessage,
    fullWidth = true,
    max = 2070,
    min = 1970,
    onChange = () => null,
    value,
    variant = 'default',
  } = props;

  const divRef = useRef<HTMLDivElement>(null);
  const [month, year] = (value || '').split('/');

  const months = useMemo(
    () => [...Array(12)].map((_, index) => `${index + 1}`.padStart(2, '0')), [],
  );

  const years = useMemo(
    () => [...Array(max - min)].map((_, index) => `${min + index}`), [max, min],
  );

  const onChangeMonth = (month: string) => onChange(`${month}/${year}`);
  const onChangeYear = (year: string) => onChange(`${month}/${year}`);

  return (
    <div ref={divRef} className="relative">
      <div className="flex gap-x-4 w-full">
        <Listbox disabled={disabled} value={month}>
          <Listbox.Button
            as="button"
            className="relative"
            onClick={() => {
              setTimeout(() => {
                divRef?.current?.scrollIntoView({ behavior: 'smooth' });
              }, 50);
            }}
          >
            <TextField
              ref={ref}
              autoComplete="off"
              disabled={disabled}
              error={Boolean(errorMessage)}
              placeholder="MM"
              trailingIcon={{ icon: 'ArrowDropDown' }}
              value={month}
              variant={variant}
            />
          </Listbox.Button>
          <Listbox.Options className={clsx(
            'absolute mb-4 bg-surface rounded shadow-two overflow-y-auto max-h-[300px] overflow-auto min-w-min w-full z-20',
            className,
            {
              'w-fit': !fullWidth,
              'w-full': fullWidth,
            },
          )}
          >
            {
                months?.map((month) => (
                  <Listbox.Option
                    key={month}
                    className="text-sm cursor-pointer hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed text-on-surface-active items-center flex py-[14px] px-4 w-full whitespace-nowrap"
                    onClick={() => onChangeMonth(month)}
                    value={month}
                  >
                    {month}
                  </Listbox.Option>
                ))
              }
          </Listbox.Options>
        </Listbox>

        <Listbox disabled={disabled} value={year}>
          <Listbox.Button
            as="button"
            className="relative"
            onClick={() => {
              setTimeout(() => {
                divRef?.current?.scrollIntoView({ behavior: 'smooth' });
              }, 50);
            }}
          >
            <TextField
              ref={ref}
              autoComplete="off"
              disabled={disabled}
              error={Boolean(errorMessage)}
              placeholder="YYYY"
              trailingIcon={{ icon: 'ArrowDropDown' }}
              value={year}
              variant={variant}
            />
          </Listbox.Button>
          <Listbox.Options className={clsx(
            'absolute mb-4 bg-surface rounded shadow-two overflow-y-auto max-h-[300px] overflow-auto min-w-min w-full z-20',
            className,
            {
              'w-fit': !fullWidth,
              'w-full': fullWidth,
            },
          )}
          >
            {
                years.map((year) => (
                  <Listbox.Option
                    key={year}
                    className="text-sm cursor-pointer hover:bg-on-surface-states-hover active:bg-on-surface-states-pressed text-on-surface-active items-center flex py-[14px] px-4 w-full whitespace-nowrap"
                    onClick={() => onChangeYear(year)}
                    value={year}
                  >
                    {year}
                  </Listbox.Option>
                ))
              }
          </Listbox.Options>
        </Listbox>
      </div>
      {errorMessage && <HelperText message={errorMessage} />}
    </div>
  );
});

export default DateMonthYear;
