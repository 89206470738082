import type { UseQueryResult } from '@tanstack/react-query';
import { filter } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useBPDActivitiesTemplates } from '@/API/Queries/activities/useBPDActivitiesTemplates';
import { useGetAllPartyRoles } from '@/API/Queries/mortgage/useGetAllPartyRoles';
import { useInstitutionUsers } from '@/API/Queries/user/useInstitutionUsers';
import { useOrganizations } from '@/API/Queries/user/useOrganizations';
import { useSkillsets } from '@/API/Queries/user/useSkillsets';
import type { CardFilterItem } from '@/Components/Atoms/Card/CardFilters';
import type { Column, IHoverActions, TableCellProps, TableProps } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';

export enum TableBuilderDataTypeEnum {
  USERS,
  ACTIVITIES,
  ORGANIZATIONS,
  SKILLSETS,
  PARTY_ROLES,
  BUSINESS_PROCESSES,
}

export type TableBuilderColumn = [string, TableCellProps<any> | string][];

export interface WidgetHoverAction extends IHoverActions<any> {
  onClick: (item: any, refetch?: () => void) => void;
}

export interface QueryTableProps extends Omit<TableProps<any>, 'columns' | 'currentPage' | 'data' | 'handleNewPage' | 'handleNewPageSize' | 'hoverActions' | 'isLoading' | 'numberOfPages' | 'onClickColumnHeader' | 'sortedByColumnId' | 'totalItems' > {
  columns: TableBuilderColumn;
  dataType: TableBuilderDataTypeEnum;
  orderBy?: string;
  allowSearch?: boolean;
  hoverActions?: Array<WidgetHoverAction>;
  filterConfig?: {
    key: string;
    noItemsLabel: string;
  };
  match?: (item: any) => boolean;
}

const useData = (dataType: TableBuilderDataTypeEnum, search: string, currentPage = 0, numberPerPage = 10, filters: any): UseQueryResult<any> => {
  const usersData = useInstitutionUsers(search, filters, dataType === TableBuilderDataTypeEnum.USERS);
  const activitiesData = useBPDActivitiesTemplates(currentPage, numberPerPage, search, dataType === TableBuilderDataTypeEnum.ACTIVITIES);
  const organizationsData = useOrganizations(dataType === TableBuilderDataTypeEnum.ORGANIZATIONS);
  const skillsetsData = useSkillsets(search, dataType === TableBuilderDataTypeEnum.SKILLSETS);
  const partyRolesData = useGetAllPartyRoles(search);

  switch (dataType) {
    case TableBuilderDataTypeEnum.ACTIVITIES:
      return activitiesData;
    case TableBuilderDataTypeEnum.USERS:
      return usersData;
    case TableBuilderDataTypeEnum.ORGANIZATIONS:
      return organizationsData;
    case TableBuilderDataTypeEnum.SKILLSETS:
      return skillsetsData;
    case TableBuilderDataTypeEnum.PARTY_ROLES:
      return partyRolesData;
    default:
      return usersData;
  }
};

const BACKEND_DRIVEN_PAGINATION_TYPE: TableBuilderDataTypeEnum[] = [
  TableBuilderDataTypeEnum.ACTIVITIES,
  TableBuilderDataTypeEnum.BUSINESS_PROCESSES,
];

export const TableBuilder = (props: QueryTableProps) => {
  const {
    allowSearch,
    cardProps: { headerProps, ...cardProps },
    columns,
    dataType,
    filterConfig,
    hoverActions = [],
    orderBy,
    match,
    ...tableProps
  } = props;

  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState <Array<CardFilterItem>>([]);
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const { data, isLoading, refetch } = useData(dataType, search, currentPage, itemsPerPage, selectedFilter?.id);

  const tableData = useMemo(() => {
    const filtered = filter(data?.list, match);

    if (!orderBy) {
      return filtered;
    }

    const orderByColumnConfig = columns.find((column) => column[0] === orderBy);

    return filtered.reduce((acc: any[], current: any) => {
      const columnKey = orderByColumnConfig?.[1];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = typeof columnKey === 'string' ? current[columnKey] : columnKey?.value(current);
      if (value) {
        return [current, ...acc];
      }
      return [...acc, current];
    }, []);
  }, [data, columns, orderBy, match]);

  const tableColumns = useMemo(() => columns.map(([header, value]): Column<any> => {
    const key: TableCellProps<any> = typeof value === 'string' ? { text: value, type: 'text' } : value;
    return {
      header,
      id: header || uuid(),
      key,
    };
  }), [columns]);

  const tableHoverActions = useMemo(() => hoverActions.map((action) => ({
    ...action,
    onClick: (item: any) => action.onClick(item, refetch),
  })), [hoverActions, refetch]);

  // getting filters based on a key
  useEffect(() => {
    if (filterConfig && data && data.list && filters.length === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const filterItems = data.list.reduce((acc: Array<CardFilterItem>, current: any) => {
        const currentItems = current[filterConfig.key];
        const newList = [...acc];
        currentItems.forEach((item: any) => {
          if (!acc.some((e) => item.name === e.value)) {
            const newFilterItem: CardFilterItem = {
              id: item.id,
              onClick: () => setSelectedFilter(item),
              selected: item.id === selectedFilter?.id,
              value: item.name,
            };
            newList.push(newFilterItem);
          }
        });
        return newList;
      }, []);
      setFilters(filterItems);
    }
  }, [data, filterConfig, selectedFilter, filters]);

  // updating the selected filter
  useEffect(() => {
    setFilters((currentFilters) => currentFilters.map((filter) => ({ ...filter, selected: filter.value === selectedFilter?.name })));
  }, [selectedFilter]);

  const onRemoveFilter = () => {
    setSelectedFilter(undefined);
  };

  return (
    <Table
      cardProps={{
        ...cardProps,
        headerProps: !headerProps ? undefined : {
          ...headerProps,
          contextualHeaderProps: headerProps.contextualHeaderProps ? {
            ...headerProps.contextualHeaderProps,
            buttons: headerProps.contextualHeaderProps?.buttons.map((button) => ({
              ...button,
              onClick: (close: any) => {
                if (button.onClick) button.onClick(close, refetch);
              },
            })) || [],
          } : undefined,
          filtersProps: filterConfig ? {
            items: filters,
            noItemsLabel: filterConfig.noItemsLabel,
            onRemoveFilter,
            variant: 'dropdown',
          } : undefined,
          searchProps: allowSearch ? { onChange: setSearch, value: search } : undefined,
        },
      }}
      columns={tableColumns}
      currentPage={currentPage}
      data={tableData}
      handleNewPage={setCurrentPage}
      handleNewPageSize={setItemsPerPage}
      hasOwnPagination={BACKEND_DRIVEN_PAGINATION_TYPE.includes(dataType)}
      hoverActions={tableHoverActions}
      isLoading={isLoading}
      numberOfPages={data?.totalPages}
      totalItems={data?.totalItems}
      {...tableProps}
    />
  );
};
