import { useMemo, useState } from 'react';

import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';
import { useNavigate } from '@/Routes/NavigationContext';

import { CreateSkillset } from './CreateSkillset';

interface TableItemData {
  description: string;
  id: string;
  name: string;
}

const SkillsetListComponent = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const handleRowClick = (skillset: TableItemData) => {
    navigate(`/admin/skillset/${skillset.id}/overview`);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', { text: 'displayName', type: 'text' }],
    ['Description', { text: 'longDescription', type: 'text' }],
  ], []);

  return (
    <>
      <TableBuilder
        allowSearch
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create new', onClick: () => setOpenModal(true) }],
            title: 'All Skillsets',
          },
        }}
        columns={columns}
        dataType={TableBuilderDataTypeEnum.SKILLSETS}
        onClickRow={(item) => handleRowClick(item)}
        rowActionType="button"
      />
      { openModal && <CreateSkillset closeModal={closeModal} />}
    </>
  );
};

const SkillsetList = SkillsetListComponent;

export { SkillsetList };
