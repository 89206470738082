import { createContext, useCallback, useContext, useState } from 'react';

import type { ValidationChecklistItem } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';

interface IValidationProgressProviderProps {
  children: React.ReactNode;
}

export interface IValidationProgressItem {
  checklistItem: ValidationChecklistItem.AsObject;
  value: any;
}

interface ValidationProgressParams {
  checklistProgress: Array<IValidationProgressItem>;
  addProgress: (item: Array<IValidationProgressItem> | IValidationProgressItem) => void;
}

const initialState: ValidationProgressParams = {
  addProgress: () => { },
  checklistProgress: [],
};

const ValidationProgressContext = createContext(initialState);
ValidationProgressContext.displayName = 'ValidationProgressContext';

const findProgress = (
  currentProgress: Array<IValidationProgressItem>,
  newItem: IValidationProgressItem,
) => currentProgress.find((currentItem) => newItem.checklistItem.id === currentItem.checklistItem.id);

const ValidationProgressProvider = (props: IValidationProgressProviderProps) => {
  const { children } = props;

  const [checklistProgress, setProgress] = useState<Array<IValidationProgressItem>>([]);

  const handleArrayUpdate = useCallback((items: Array<IValidationProgressItem>) => {
    // get list of items that needed to be updated
    const updatedList = checklistProgress.map((currentItem) => {
      const updatedItem = findProgress(items, currentItem);
      if (updatedItem) return updatedItem;
      return currentItem;
    });
    // get list of new progress items
    const newItems = items
      .filter((newItem) => !checklistProgress
        .some((currentItem) => currentItem.checklistItem.id === newItem.checklistItem.id));

    // merge the list of updated items with the new ones
    const newProgress = [...updatedList, ...newItems];
    setProgress(newProgress);
  }, [checklistProgress]);

  const handleSingleUpdate = useCallback((item: IValidationProgressItem) => {
    let newProgress = [];
    // if new item was already in the list
    if (findProgress(checklistProgress, item)) {
      // updates the current progress with the new item
      newProgress = checklistProgress.map((currentItem) => {
        if (currentItem.checklistItem.id === item.checklistItem.id) return item;
        return currentItem;
      });
    } else {
      newProgress = [...checklistProgress, item];
    }
    setProgress(newProgress);
  }, [checklistProgress]);

  const addProgress = useCallback((item: Array<IValidationProgressItem> | IValidationProgressItem) => {
    if (Array.isArray(item)) {
      handleArrayUpdate(item);
    } else {
      handleSingleUpdate(item);
    }
  }, [handleArrayUpdate, handleSingleUpdate]);

  return (
    <ValidationProgressContext.Provider value={{ addProgress, checklistProgress }}>
      {children}
    </ValidationProgressContext.Provider>
  );
};

const useValidationProgress = () => useContext(ValidationProgressContext);

export { useValidationProgress, ValidationProgressProvider };
