import { createRef, forwardRef, useImperativeHandle, useRef } from 'react';

import { Icon } from '@/Components/Atoms/Icon';
import { Menu } from '@/Components/Atoms/Menu';
import { Skeleton } from '@/Components/Atoms/Skeleton';

import { useActivityConfiguratorContext } from '../../activityConfiguratorContext';
import { PanelDropArea } from '../panelDropArea/panelDropArea';

interface MainDropAreaProps {
  setReorderPanelsModal: (bool: boolean) => void;
  handleDeleteModal: (type: string, panelIndex?: number, panelElementIndex?: number) => void;
  handleAddPanel: () => void;
  onClickPanelElement: (panelIndex: number, panelElementIndex: number) => void;
}

export interface MainDropAreaRef {
  scrollPanelIntoView: (panelIndex: number) => void;
}

const MainDropArea = forwardRef<MainDropAreaRef, MainDropAreaProps>((props, ref) => {
  const {
    handleAddPanel,
    handleDeleteModal,
    onClickPanelElement,
    setReorderPanelsModal,
  } = props;
  const {
    designerPanel: { runtime },
    isLoadingDesign,
    setSelectedPanel,
  } = useActivityConfiguratorContext();

  const panelRef: any = useRef([]);
  panelRef.current = [...Array(runtime.length).keys()].map(
    (_, i) => panelRef.current[i] ?? createRef(),
  );

  const scrollPanelIntoView = (panelIndex: number) => {
    if (!panelRef) return;
    panelRef.current[panelIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useImperativeHandle(ref, () => ({
    scrollPanelIntoView,
  }));

  const handleClickControls = (panelIndex: number) => {
    setSelectedPanel(panelIndex);
    scrollPanelIntoView(panelIndex);
  };

  if (isLoadingDesign) {
    return (
      <Skeleton height="220px" margin="16px auto" rows={3} variant="rect" width="90%" />
    );
  }

  return (
    <div className="block flex-auto overflow-x-hidden overflow-y-auto p-6 max-h-[calc(100vh-122px)]">
      {
        runtime.length === 0
        && (
          <div className="grid text-center mt-6">
            <Icon
              className="text-on-surface-inactive mx-[auto] flex items-center justify-center"
              icon="Add"
              onClick={handleAddPanel}
              size="lg"
              variant="fixed-background"
            />
            <p className="mt-2 text-on-surface-active">Create Page</p>
          </div>
        )
      }
      {runtime.map((panel, panelIndex: number) => (
        <div key={panel.id} ref={panelRef.current[panelIndex]} className="max-w-[810px] overflow-x-hidden my-3 mx-[auto]">
          <div className="bg-surface">
            <div className="flex items-center h-12 justify-end bg-surface-variant">
              <span className="font-semibold mr-[auto] text-on-surface-solid">{`Page ${panelIndex + 1}`}</span>
              <Icon
                className="text-on-surface-disabled ml-4"
                icon="ExpandLess"
                onClick={() => (
                  panelIndex > 0 ? handleClickControls(panelIndex - 1) : null)}
                variant="interactive"
              />
              <Icon
                className="text-on-surface-disabled ml-4"
                icon="ExpandMore"
                onClick={() => (runtime.length - 1 !== panelIndex ? handleClickControls(panelIndex + 1) : null)}
                variant="interactive"
              />
              <Icon className="text-on-surface-inactive ml-4" icon="Add" onClick={handleAddPanel} />
              <Icon
                className="text-on-surface-disabled ml-4"
                icon="Delete"
                onClick={() => handleDeleteModal('panel', panelIndex)}
                variant="interactive"
              />
              <Menu
                options={[{ label: 'Reorder', onClick: () => setReorderPanelsModal(true) }]}
                triggerItem={<Icon className="!text-on-surface-inactive" icon="MoreVert" variant="interactive" />}
              />
            </div>
            <PanelDropArea
              handleDeleteModal={(panelElementIndex) => handleDeleteModal('panelElement', panelIndex, panelElementIndex)}
              onClickPanelElement={onClickPanelElement}
              panel={panel}
              panelIndex={panelIndex}
            />
          </div>
        </div>
      ))}
    </div>
  );
});

export { MainDropArea };
