import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetAdminPanelSequenceDesignTriggerRulesQueryRequest, GetAdminPanelSequenceDesignTriggerRulesQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const MESSAGE_NAME = 'wilqo.api.activity.GetAdminPanelSequenceDesignTriggerRulesQueryRequest';

const useActivityTriggers = (id: string) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, bpdId, id], async () => {
    const request = new GetAdminPanelSequenceDesignTriggerRulesQueryRequest();
    request.setAdminPanelSequenceDesignId(id);
    request.setBusinessProcessDomainId(bpdId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetAdminPanelSequenceDesignTriggerRulesQueryResponse();
    GetAdminPanelSequenceDesignTriggerRulesQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.triggerRules);
  }, { enabled: Boolean(id) && Boolean(bpdId) });
};
export { useActivityTriggers };
