import React, { useCallback, useMemo, useState } from 'react';

import { useInstitutionId } from '@/Routes/Auth/AppAuthContext';
import { getPanelElementOption } from '@/Utils/Helpers/getPanelElement';

import { CredentialForm } from './credentialForm';
import { useGetCredentialTypes, useGetOrganizations } from './helpers';
import { UpdateCredentials } from './UpdateCredential';

export interface CredentialFormData {
  selectedOrganization: string;
  selectedCredentialType: string;
}

const CredentialManager = () => {
  const [formData, setFormData] = useState<CredentialFormData>({
    selectedCredentialType: '',
    selectedOrganization: '',
  });
  const institutionId = useInstitutionId();
  const organizations = useGetOrganizations(institutionId);
  const credentialsPerOrganization = useGetCredentialTypes(formData.selectedOrganization);

  const cardOptions = useMemo(() => [
    {
      field: 'selectedOrganization',
      options: organizations?.map((org) => getPanelElementOption({ headerText: org.name, id: org.id })) ?? [],
      subtitle: 'Please select an organization',
      title: 'Organization',
    },
    {
      field: 'selectedCredentialType',
      options: credentialsPerOrganization?.map((credentialType) => getPanelElementOption({ headerText: credentialType.name, id: credentialType.id.toString() })) ?? [],
      subtitle: 'Please select a credential type',
      title: 'Credential Type',
    },
  ], [organizations, credentialsPerOrganization]);

  const handleChange = useCallback((field: keyof CredentialFormData) => (value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value.id,
    }));
  }, []);

  return (
    <div className="flex sm:flex-row flex-col gap-2 w-full h-[350px]">
      <div className="flex-1">
        <CredentialForm cardOptions={cardOptions} handleChange={handleChange} />
      </div>
      <div className="flex-1">
        {formData.selectedOrganization !== '' && formData.selectedCredentialType !== '' && (
          <UpdateCredentials
            credentialSet={[]}
            selectedCredentialType={credentialsPerOrganization.find(
              (credential) => String(credential.id) === formData.selectedCredentialType,
            )?.name || ''}
          />
        )}
      </div>
    </div>
  );
};

export { CredentialManager };
