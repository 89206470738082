import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useState } from 'react';

import { DYNAMIC_PAGE_MESSAGE_NAME } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

export type WidgetContext = 'dialog' | 'page' | 'togglable' | 'widget';
export enum ExpandStatusTypeEnum {
  DEFAULT,
  OPEN_ALL,
  COLLAPSE_ALL,
}

interface InitialState {
  editingWidgetIds: Array<string>;
  savingWidgetIds: Array<string>;
  disabledActions: boolean;
  context: WidgetContext;
  pageId?: string;
  scopeToken?: string;
  tabId?: string;
  hasSavedIds?: Array<string>;
  expandStatus?: ExpandStatusTypeEnum;
  reloadMainPage: VoidFunction;
  setExpandStatus: (status: ExpandStatusTypeEnum) => void;
  setHasSavedIds?: (ids: Array<string>) => void;
  setEditingWidgetIds: (newItems: Array<string>) => void;
  addEditingWidget: (widgetId: string[] | string) => void;
  removeEditingWidget: (widgetId: string[] | string) => void;
  setSavingWidgetIds: (newItems: Array<string>) => void;
  addSavingWidget: (widgetId: string) => void;
  removeSavingWidget: (widgetId: string) => void;
  toggleEditAllWidgets: (value?: boolean) => void;
  editAllWidgets: boolean;
}

const initialState: InitialState = {
  addEditingWidget: () => { },
  addSavingWidget: () => { },
  context: 'page',
  disabledActions: false,
  editAllWidgets: false,
  editingWidgetIds: [],
  expandStatus: ExpandStatusTypeEnum.DEFAULT,
  reloadMainPage: () => { },
  removeEditingWidget: () => { },
  removeSavingWidget: () => { },
  savingWidgetIds: [],
  setEditingWidgetIds: () => { },
  setExpandStatus: () => { },
  setSavingWidgetIds: () => { },
  toggleEditAllWidgets: () => { },
};

const Context = createContext(initialState);

export interface Props {
  children: React.ReactNode;
  context: WidgetContext;
  pageId: string;
  scopeToken?: string;
  tabId?: string;
  editingWidgetIds?: Array<string>;
  expandStatus?: ExpandStatusTypeEnum;
  setExpandStatus?: (status: ExpandStatusTypeEnum) => void;
  setEditingWidgetIds?: (ids: string[] | ((prev: string[]) => string[])) => void;
  savingWidgetIds?: Array<string>;
  setSavingWidgetIds?: (ids: string[]) => void;
  hasSavedIds?: Array<string>;
  setHasSavedIds?: (b: Array<string>) => void;
  setEditAllWidgets?: (v: boolean) => void;
  editAllWidgets?: boolean;
}

export const WidgetProvider = (props: Props) => {
  const {
    editingWidgetIds = [],
    expandStatus = ExpandStatusTypeEnum.DEFAULT,
    savingWidgetIds = [],
    setEditingWidgetIds = () => null,
    setSavingWidgetIds = () => null,
    setExpandStatus = () => null,
    setEditAllWidgets = () => null,
    editAllWidgets = false,
  } = props;

  const queryClient = useQueryClient();
  const { loanId } = useDynamicLoanInfo();

  const toggleEditAllWidgets = useCallback((value?: boolean) => {
    setEditAllWidgets(value ?? !editAllWidgets);
  }, [editAllWidgets, setEditAllWidgets]);

  const addEditingWidget = useCallback((widgetId: string[] | string) => {
    setEditingWidgetIds((currentIds) => {
      if (Array.isArray(widgetId)) {
        return [...currentIds, ...widgetId];
      }
      if (!currentIds.includes(widgetId)) {
        return currentIds.concat(widgetId);
      }
      return currentIds;
    });
  }, [setEditingWidgetIds]);

  const removeEditingWidget = useCallback((widgetId: string[] | string) => {
    if (Array.isArray(widgetId)) {
      setEditingWidgetIds((prevEditingIds: string[]) => prevEditingIds.filter((currentId) => !widgetId.includes(currentId)));
    } else {
      setEditingWidgetIds((prevEditingIds: string[]) => prevEditingIds.filter((currentId) => currentId !== widgetId));
    }
  }, [setEditingWidgetIds]);

  const addSavingWidget = (widgetId: string[] | string) => {
    if (Array.isArray(widgetId)) {
      setSavingWidgetIds([...savingWidgetIds, ...widgetId]);
    } else {
      setSavingWidgetIds(savingWidgetIds.concat(widgetId));
    }
  };

  const removeSavingWidget = (widgetId: string) => setSavingWidgetIds(savingWidgetIds.filter((currentId) => currentId !== widgetId));

  const reloadMainPage = () => {
    queryClient.invalidateQueries([DYNAMIC_PAGE_MESSAGE_NAME, loanId, props.pageId, props.scopeToken]);
  };

  return (
    <Context.Provider
      value={{
        addEditingWidget,
        addSavingWidget,
        context: props.context,
        disabledActions: false,
        editAllWidgets,
        editingWidgetIds,
        expandStatus,
        hasSavedIds: props.hasSavedIds,
        pageId: props.pageId,
        reloadMainPage,
        removeEditingWidget,
        removeSavingWidget,
        savingWidgetIds,
        scopeToken: props.scopeToken,
        setEditingWidgetIds,
        setExpandStatus,
        setHasSavedIds: props.setHasSavedIds,
        setSavingWidgetIds,
        tabId: props.tabId,
        toggleEditAllWidgets,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useWidgetContext = () => useContext(Context);

export const useWidgetContextValues = () => {
  const [editAllWidgets, setEditAllWidgets] = useState<boolean>(false);
  const [editingWidgetIds, setEditingWidgetIds] = useState<Array<string>>([]);
  const [savingWidgetIds, setSavingWidgetIds] = useState<Array<string>>([]);
  const [hasSavedIds, setHasSavedIds] = useState<Array<string>>([]);
  const [expandStatus, setExpandStatus] = useState<ExpandStatusTypeEnum>(ExpandStatusTypeEnum.DEFAULT);

  return {
    editAllWidgets,
    editingWidgetIds,
    expandStatus,
    hasSavedIds,
    savingWidgetIds,
    setEditAllWidgets,
    setEditingWidgetIds,
    setExpandStatus,
    setHasSavedIds,
    setSavingWidgetIds,
  };
};

export type IUseWidgetContextValues = ReturnType<typeof useWidgetContextValues>;
