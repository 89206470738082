import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useWindow } from '@/Utils/Helpers/useWindow';

import { AnimatedBackdrop } from './AnimatedBackdrop';
import type { NavDrawerItemType } from './NavDrawerItem';
import { NavDrawerItem } from './NavDrawerItem';
import { Skeleton } from './Skeleton';

export interface NavDrawerProps {
  className?: string;
  items: Array<NavDrawerItemType>;
  open?: boolean;
  toggleDrawer?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  fixed?: boolean;
  footer?: ReactNode;
}

const NavDrawer = (props: NavDrawerProps) => {
  const {
    className = '',
    disabled = false,
    fixed,
    footer,
    isLoading,
    items: navigationList,
    open = true,
    toggleDrawer,
  } = props;

  const { windowType } = useWindow();
  useEffect(() => {
    if (toggleDrawer && ((windowType !== 'desktop' && open) || (windowType === 'desktop' && !open))) {
      toggleDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowType]);

  return (
    <>
      { (fixed && open) && (
        <div
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          role="button"
          tabIndex={0}
        >
          <AnimatedBackdrop className="right-0 top-0 w-full" />
        </div>
      )}
      <div
        className={clsx(
          'relative flex flex-col justify-between overflow-auto h-full  bg-surface ease-in-out duration-300',
          {
            '!fixed top-0 p-0 z-50 !h-[100vh]': fixed,
            'left-0 w-[256px] min-w-[256px]': open,
            'w-0 left-[-256px]': !open,
          },
        )}
        data-testid="drawer"
      >
        {isLoading && <Skeleton height="40px" rows={10} variant="rect" />}
        <div className="w-full h-full">
          <div
            className={clsx(
              'flex flex-col overflow-hidden min-h-full',
              className,
            )}
          >
            {navigationList && navigationList.map((navigationItem) => (
              <NavDrawerItem
                key={navigationItem.label}
                disabled={disabled}
                navigation={navigationItem}
                toggleDrawer={toggleDrawer}
              />
            ))}
          </div>
        </div>
        {footer}
      </div>
    </>
  );
};

export { NavDrawer };
