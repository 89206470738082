import clsx from 'clsx';
import { debounce } from 'lodash';
import { useRef, useState } from 'react';

import { DynamicIcon } from './DynamicIcon';
import { Icon } from './Icon';

export interface AnimatedIconInputProps {
  value: string;
  onChange: (value: string) => void;
  width?: string;
  className?: string;
  isLoading?: boolean;
}

export const AnimatedIconInput = (props: AnimatedIconInputProps) => {
  const { className = '', isLoading = false, onChange } = props;

  const [showInput, setShowInput] = useState(false);
  const [active, setActive] = useState(false);

  const [search, setSearch] = useState('');
  const debounceSetSearch = useRef(debounce(onChange, 500));

  const handleSearch = (value: string) => {
    debounceSetSearch.current(value);
    setSearch(value);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpen = () => {
    setShowInput(true);
    setActive(true);
    inputRef?.current?.focus();
    setSearch('');
    onChange('');
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    onChange('');
    setShowInput(false);
    setActive(false);
    event.stopPropagation();
  };

  const handleInputBlur = () => {
    setActive(false);
  };

  const renderTrailingIcon = () => {
    if (!showInput) return null;
    if (isLoading) {
      return (
        <DynamicIcon className="animate-spin" icon="Loop" />
      );
    }
    return (
      <Icon
        icon="Clear"
        onClick={handleClose}
        variant="interactive"
      />
    );
  };

  return (
    <div className="relative w-10 h-10">
      <div
        className={
          clsx(
            'absolute right-0 h-10 overflow-hidden z-20 bg-surface ease-in-out duration-150',
            className,
            {
              'border-b-2 border-b-on-surface-stroke': !active && showInput,
              'border-b-2 border-b-primary-on-surface': active,
              'w-[288px] pt-1 pr-2 pb-0.5 pl-0': showInput,
              'w-[40px]': !showInput,
            },
          )
        }
      >
        <div className="flex w-full flex-row flex-nowrap gap-x-2 items-center absolute left-0">
          <Icon
            icon="Search"
            onClick={handleOpen}
            variant={showInput ? 'default' : 'interactive'}
          />
          {showInput && (
            <input
              ref={inputRef}
              autoFocus
              className="text-body1Size leading-body1Height tracking-body1Spacing placeholder-on-surface-inactive outline-none bg-transparent select-all w-full"
              name="search"
              onBlur={handleInputBlur}
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
          )}
          {renderTrailingIcon()}
        </div>
      </div>
    </div>
  );
};
