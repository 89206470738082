// source: Wilqo.API.Mortgage.DocGen/Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentCategoryEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentInfo', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentMergeSourceEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentStatusEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_docgen.SavedFile', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_docgen.SavedFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_docgen.SavedFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_docgen.SavedFile.displayName = 'proto.wilqo.api.mortgage_docgen.SavedFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.displayName = 'proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_docgen.DocumentInfo.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_docgen.DocumentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_docgen.DocumentInfo.displayName = 'proto.wilqo.api.mortgage_docgen.DocumentInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_docgen.SavedFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_docgen.SavedFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_docgen.SavedFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mimeType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_docgen.SavedFile}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_docgen.SavedFile;
  return proto.wilqo.api.mortgage_docgen.SavedFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_docgen.SavedFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_docgen.SavedFile}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimeType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentType} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_docgen.SavedFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_docgen.SavedFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_docgen.SavedFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMimeType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.SavedFile} returns this
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mime_type = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.getMimeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.SavedFile} returns this
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.setMimeType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.SavedFile} returns this
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DocumentType document_type = 4;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentType}
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentType} value
 * @return {!proto.wilqo.api.mortgage_docgen.SavedFile} returns this
 */
proto.wilqo.api.mortgage_docgen.SavedFile.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentUrlsToBeMergedList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    dealId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mergedDocumentInfo: (f = msg.getMergedDocumentInfo()) && proto.wilqo.api.mortgage_docgen.DocumentInfo.toObject(includeInstance, f),
    existingDocumentIdsToBeMergedList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    sourceType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage;
  return proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentUrlsToBeMerged(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage_docgen.DocumentInfo;
      reader.readMessage(value,proto.wilqo.api.mortgage_docgen.DocumentInfo.deserializeBinaryFromReader);
      msg.setMergedDocumentInfo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addExistingDocumentIdsToBeMerged(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentMergeSourceEnum} */ (reader.readEnum());
      msg.setSourceType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentUrlsToBeMergedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMergedDocumentInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.mortgage_docgen.DocumentInfo.serializeBinaryToWriter
    );
  }
  f = message.getExistingDocumentIdsToBeMergedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getSourceType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string document_urls_to_be_merged = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getDocumentUrlsToBeMergedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setDocumentUrlsToBeMergedList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.addDocumentUrlsToBeMerged = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.clearDocumentUrlsToBeMergedList = function() {
  return this.setDocumentUrlsToBeMergedList([]);
};


/**
 * optional string deal_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional DocumentInfo merged_document_info = 6;
 * @return {?proto.wilqo.api.mortgage_docgen.DocumentInfo}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getMergedDocumentInfo = function() {
  return /** @type{?proto.wilqo.api.mortgage_docgen.DocumentInfo} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_docgen.DocumentInfo, 6));
};


/**
 * @param {?proto.wilqo.api.mortgage_docgen.DocumentInfo|undefined} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
*/
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setMergedDocumentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.clearMergedDocumentInfo = function() {
  return this.setMergedDocumentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.hasMergedDocumentInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string existing_document_ids_to_be_merged = 7;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getExistingDocumentIdsToBeMergedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setExistingDocumentIdsToBeMergedList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.addExistingDocumentIdsToBeMerged = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.clearExistingDocumentIdsToBeMergedList = function() {
  return this.setExistingDocumentIdsToBeMergedList([]);
};


/**
 * optional DocumentMergeSourceEnum source_type = 8;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentMergeSourceEnum}
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.getSourceType = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentMergeSourceEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentMergeSourceEnum} value
 * @return {!proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage} returns this
 */
proto.wilqo.api.mortgage_docgen.MergeDocumentsRequestMessage.prototype.setSourceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_docgen.DocumentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    documentType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    progressItemTemplateIdentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documentStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expirationDate: (f = msg.getExpirationDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    effectiveDate: (f = msg.getEffectiveDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    documentCategory: jspb.Message.getFieldWithDefault(msg, 7, 0),
    validPeriod: jspb.Message.getFieldWithDefault(msg, 8, 0),
    documentCategoryId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    visibleToConsumerIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    documentTypeId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    borrowerIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_docgen.DocumentInfo;
  return proto.wilqo.api.mortgage_docgen.DocumentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentName(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentEnum} */ (reader.readEnum());
      msg.setDocumentType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressItemTemplateIdentifier(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentStatusEnum} */ (reader.readEnum());
      msg.setDocumentStatus(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentCategoryEnum} */ (reader.readEnum());
      msg.setDocumentCategory(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum} */ (reader.readEnum());
      msg.setValidPeriod(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentCategoryId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleToConsumerIndicator(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTypeId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addBorrowerIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_docgen.DocumentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDocumentType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProgressItemTemplateIdentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDocumentCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDocumentCategoryId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getVisibleToConsumerIndicator();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDocumentTypeId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBorrowerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional string document_name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getDocumentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setDocumentName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DocumentEnum document_type = 2;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentEnum}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getDocumentType = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentEnum} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setDocumentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string progress_item_template_identifier = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getProgressItemTemplateIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setProgressItemTemplateIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DocumentStatusEnum document_status = 4;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentStatusEnum}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getDocumentStatus = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentStatusEnum} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setDocumentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp expiration_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
*/
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp effective_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getEffectiveDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
*/
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DocumentCategoryEnum document_category = 7;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentCategoryEnum}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getDocumentCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setDocumentCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional DocumentValidPeriodInDaysEnum valid_period = 8;
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getValidPeriod = function() {
  return /** @type {!proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setValidPeriod = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.clearValidPeriod = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.hasValidPeriod = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string document_category_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getDocumentCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setDocumentCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool visible_to_consumer_indicator = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getVisibleToConsumerIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setVisibleToConsumerIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string document_type_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getDocumentTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setDocumentTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string borrower_ids = 12;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.getBorrowerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.setBorrowerIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.addBorrowerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_docgen.DocumentInfo} returns this
 */
proto.wilqo.api.mortgage_docgen.DocumentInfo.prototype.clearBorrowerIdsList = function() {
  return this.setBorrowerIdsList([]);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_docgen.DocumentType = {
  DOCUMENT_TYPE_NOT_SPECIFIED: 0,
  DOCUMENT_TYPE_DOC_1008: 1,
  DOCUMENT_TYPE_DOC_92900A: 2,
  DOCUMENT_TYPE_DOC_92900LT: 3,
  DOCUMENT_TYPE_DOC_1005: 4,
  DOCUMENT_TYPE_FORM_90: 5,
  DOCUMENT_TYPE_DOC_VA266393: 6,
  DOCUMENT_TYPE_GET_QUALIFIED: 7,
  DOCUMENT_TYPE_FORM_TITLE_ORDER: 8,
  DOCUMENT_TYPE_DOC_FEE_WORKSHEET: 9,
  DOCUMENT_TYPE_DOC_928005B: 10,
  DOCUMENT_TYPE_FORM_VA260286: 11,
  DOCUMENT_TYPE_DOC_NET_FUNDING_WORKSHEET: 12,
  DOCUMENT_TYPE_DOC_NOI: 13
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_docgen.DocumentMergeSourceEnum = {
  DOCUMENT_MERGE_SOURCE_ENUM_UNKNOWN: 0,
  DOCUMENT_MERGE_SOURCE_ENUM_MERGE_EXISITING_DOCUMENTS: 1,
  DOCUMENT_MERGE_SOURCE_ENUM_MERGE_AND_UPLOAD_NEW_DOCUMENT: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_docgen.DocumentEnum = {
  DOCUMENT_ENUM_UNKNOWN: 0,
  DOCUMENT_ENUM_203_K_BORROWER_ACKNOWLEDGMENT: 1,
  DOCUMENT_ENUM_203_K_BORROWER_IDENTITY_OF_INTEREST_CERTIFICATION: 2,
  DOCUMENT_ENUM_203_K_CONSULTANT_IDENTITY_OF_INTEREST_STATEMENT: 3,
  DOCUMENT_ENUM_203_K_CONSULTANT_REPORT: 4,
  DOCUMENT_ENUM_203_K_COST_ESTIMATES: 5,
  DOCUMENT_ENUM_203_K_DRAW_REQUEST: 6,
  DOCUMENT_ENUM_203_K_HOMEOWNER_ACKNOWLEDGMENT: 7,
  DOCUMENT_ENUM_203_K_INITIAL_DRAW_REQUEST: 8,
  DOCUMENT_ENUM_203_K_MAXIMUM_MORTGAGE_WORKSHEET: 9,
  DOCUMENT_ENUM_203_K_REHABILITATION_AGREEMENT: 10,
  DOCUMENT_ENUM_ABSTRACT_NOTICE_AGREEMENT: 11,
  DOCUMENT_ENUM_ABSTRACT_OF_JUDGMENT: 12,
  DOCUMENT_ENUM_ACH_DEBIT_AUTHORIZATION: 13,
  DOCUMENT_ENUM_ACKNOWLEDGMENT_OF_NOTICE_OF_RIGHT_TO_CANCEL: 14,
  DOCUMENT_ENUM_AFFIDAVIT_OF_DEATH: 15,
  DOCUMENT_ENUM_AFFIDAVIT_OF_LOST_NOTE: 16,
  DOCUMENT_ENUM_AFFILIATED_BUSINESS_ARRANGEMENT_DISCLOSURE: 17,
  DOCUMENT_ENUM_AIRPORT_NOISE_POLLUTION_AGREEMENT: 18,
  DOCUMENT_ENUM_ALTERNATIVE_REQUEST_FOR_AGREEMENT_OF_SHORT_SALE: 19,
  DOCUMENT_ENUM_AMENDATORY_CLAUSE: 20,
  DOCUMENT_ENUM_AMORTIZATION_SCHEDULE: 21,
  DOCUMENT_ENUM_APPLICATION_DISCLOSURE: 22,
  DOCUMENT_ENUM_APPLICATION_DISCLOSURE_ADDITIONAL_CREDIT_AND_DEBT: 23,
  DOCUMENT_ENUM_APPRAISAL_RECERTIFICATION: 24,
  DOCUMENT_ENUM_APPRAISAL_REPORT: 25,
  DOCUMENT_ENUM_APPRAISAL_REPORT_DESKTOP: 26,
  DOCUMENT_ENUM_APPRAISAL_REPORT_EXTERIOR_ONLY: 27,
  DOCUMENT_ENUM_APPRAISAL_REPORT_INTERIOR_EXTERIOR: 28,
  DOCUMENT_ENUM_APPRAISAL_REVIEW: 29,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_EXTERIOR_ONLY_INSPECTION_WITH_VALUE_OPINION: 30,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_EXTERIOR_ONLY_WITHOUT_VALUE_OPINION: 31,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_INTERIOR_EXTERIOR_INSPECTION_WITH_VALUE_OPINION: 32,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_INTERIOR_EXTERIOR_WITHOUT_VALUE_OPINION: 33,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_NO_INSPECTION_WITH_VALUE_OPINION: 34,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_WITHOUT_VALUE_OPINION: 35,
  DOCUMENT_ENUM_APPRAISAL_REVIEW_REQUEST: 36,
  DOCUMENT_ENUM_APPROVAL_LETTER: 37,
  DOCUMENT_ENUM_ARTICLES_OF_INCORPORATION: 38,
  DOCUMENT_ENUM_ASSIGNMENT: 39,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_DEED_OF_TRUST: 40,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_MORTGAGE: 41,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_RENTS: 42,
  DOCUMENT_ENUM_ASSIGNMENT_ASSIGNMENT_OF_TRADE: 43,
  DOCUMENT_ENUM_ASSIGNMENT_BLANKET_ASSIGNMENT: 44,
  DOCUMENT_ENUM_ASSIGNMENT_COOPERATIVE_ASSIGNMENT_OF_PROPRIETARY_LEASE: 45,
  DOCUMENT_ENUM_ASSUMPTION_AGREEMENT: 46,
  DOCUMENT_ENUM_ASSURANCE_OF_COMPLETION: 47,
  DOCUMENT_ENUM_ATTESTATION_OF_DAMAGE_TO_PROPERTY: 48,
  DOCUMENT_ENUM_ATTORNEY_IN_FACT_AFFIDAVIT: 49,
  DOCUMENT_ENUM_ATTORNEYS_OPINION_LETTER: 50,
  DOCUMENT_ENUM_AUTOMATED_UNDERWRITING_FEEDBACK: 51,
  DOCUMENT_ENUM_AUTOMATED_VALUATION_REPORT: 52,
  DOCUMENT_ENUM_AUTOMATED_VALUATION_REPORT_WITH_INSPECTION: 53,
  DOCUMENT_ENUM_AVM_FEEDBACK: 54,
  DOCUMENT_ENUM_BAILEE_LETTER: 55,
  DOCUMENT_ENUM_BALANCE_TRANSFER_AUTHORIZATION_NOTICE: 56,
  DOCUMENT_ENUM_BALLOON_REFINANCE_DISCLOSURE: 57,
  DOCUMENT_ENUM_BANK_DEPOSIT_SLIP: 58,
  DOCUMENT_ENUM_BANKRUPTCY_DISCHARGE_NOTICE: 59,
  DOCUMENT_ENUM_BANK_STATEMENT: 60,
  DOCUMENT_ENUM_BANK_STATEMENT401_K: 61,
  DOCUMENT_ENUM_BANK_STATEMENT_CHECKING_ACCOUNT: 62,
  DOCUMENT_ENUM_BANK_STATEMENT_MUTUAL_FUND_ACCOUNT: 63,
  DOCUMENT_ENUM_BANK_STATEMENT_SAVING_ACCOUNT: 64,
  DOCUMENT_ENUM_BANK_STATEMENT_STOCK_ACCOUNT: 65,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT: 66,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT401_K: 67,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT_ANNUITY: 68,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT_PENSION: 69,
  DOCUMENT_ENUM_BENEFIT_PLAN_DISTRIBUTION_STATEMENT_TRUST: 70,
  DOCUMENT_ENUM_BID: 71,
  DOCUMENT_ENUM_BIRTH_CERTIFICATE: 72,
  DOCUMENT_ENUM_BOND_CERTIFICATE: 73,
  DOCUMENT_ENUM_BORROWER_ACKNOWLEDGMENT_OF_PROPERTY_CONDITION: 74,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE: 75,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_GAP_IN_EMPLOYMENT: 76,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_LETTER_OF_EXPLANATION: 77,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_LETTER_OF_INTENT: 78,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_QUALIFIED_WRITTEN_REQUEST: 79,
  DOCUMENT_ENUM_BORROWER_CORRESPONDENCE_TRAILING_SPOUSE: 80,
  DOCUMENT_ENUM_BORROWER_LIEN_AFFIDAVIT: 81,
  DOCUMENT_ENUM_BORROWERS_CERTIFICATION: 82,
  DOCUMENT_ENUM_BORROWERS_CONTRACT_WITH_RESPECT_TO_HOTEL_AND_TRANSIENT_USE_OF_PROPERTY: 83,
  DOCUMENT_ENUM_BREACH_NOTICE: 84,
  DOCUMENT_ENUM_BROKER_DISCLOSURE_STATEMENT: 85,
  DOCUMENT_ENUM_BROKER_PRICE_OPINION: 86,
  DOCUMENT_ENUM_BROKER_PRICE_OPINION_DESKTOP: 87,
  DOCUMENT_ENUM_BROKER_PRICE_OPINION_EXTERIOR_INSPECTION: 88,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION: 89,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_BUILDER_CERTIFICATION_OF_PLANS_AND_SPECIFICATIONS: 90,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_BUILDERS_CERTIFICATE: 91,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_PROPERTY_INSPECTION: 92,
  DOCUMENT_ENUM_BUILDERS_CERTIFICATION_TERMITE_TREATMENT: 93,
  DOCUMENT_ENUM_BUILDING_PERMIT: 94,
  DOCUMENT_ENUM_BUSINESS_LICENSE: 95,
  DOCUMENT_ENUM_BUYDOWN_AGREEMENT: 96,
  DOCUMENT_ENUM_BUYING_YOUR_HOME_SETTLEMENT_COSTS_AND_HELPFUL_INFORMATION: 97,
  DOCUMENT_ENUM_CAIVRS_AUTHORIZATION: 98,
  DOCUMENT_ENUM_CANCELLATION_OF_LISTING: 99,
  DOCUMENT_ENUM_CHECK: 100,
  DOCUMENT_ENUM_CHECKLIST: 101,
  DOCUMENT_ENUM_CHILD_SUPPORT_VERIFICATION: 102,
  DOCUMENT_ENUM_CLOSE_LINE_OF_CREDIT_REQUEST: 103,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE: 104,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_ALTERNATE_FORM: 105,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_BORROWER_ONLY: 106,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_MODEL_FORM: 107,
  DOCUMENT_ENUM_CLOSING_DISCLOSURE_SELLER_ONLY: 108,
  DOCUMENT_ENUM_CLOSING_INSTRUCTIONS: 109,
  DOCUMENT_ENUM_COLLECTION_REGISTER: 110,
  DOCUMENT_ENUM_COMPARATIVE_INCOME_ANALYSIS: 111,
  DOCUMENT_ENUM_COMPLIANCE_AGREEMENT: 112,
  DOCUMENT_ENUM_COMPLIANCE_INSPECTION_REPORT: 113,
  DOCUMENT_ENUM_CONDITIONAL_COMMITMENT: 114,
  DOCUMENT_ENUM_CONDOMINIUM_OCCUPANCY_CERTIFICATE: 115,
  DOCUMENT_ENUM_CONSERVATOR_AND_GUARDIANSHIP_AGREEMENT: 116,
  DOCUMENT_ENUM_CONSTRUCTION_COST_BREAKDOWN: 117,
  DOCUMENT_ENUM_CONSUMER_HANDBOOK_ON_ARM: 118,
  DOCUMENT_ENUM_CONVERSION_OPTION_NOTICE: 119,
  DOCUMENT_ENUM_CONVEYANCE_DEED: 120,
  DOCUMENT_ENUM_CONVEYANCE_DEED_BARGAIN_AND_SALE_DEED: 121,
  DOCUMENT_ENUM_CONVEYANCE_DEED_QUIT_CLAIM_DEED: 122,
  DOCUMENT_ENUM_CONVEYANCE_DEED_WARRANTY_DEED: 123,
  DOCUMENT_ENUM_CONVEYANCE_TAX_FORM: 124,
  DOCUMENT_ENUM_COOPERATIVE_BYLAWS: 125,
  DOCUMENT_ENUM_COOPERATIVE_OPERATING_BUDGET: 126,
  DOCUMENT_ENUM_COOPERATIVE_PROPRIETARY_LEASE: 127,
  DOCUMENT_ENUM_COOPERATIVE_RECOGNITION_AGREEMENT: 128,
  DOCUMENT_ENUM_COOPERATIVE_STOCK_CERTIFICATE: 129,
  DOCUMENT_ENUM_COOPERATIVE_STOCK_POWER: 130,
  DOCUMENT_ENUM_COSIGNER_NOTICE: 131,
  DOCUMENT_ENUM_COUNCIL_OF_AMERICAN_BUILDING_OFFICIALS_CERTIFICATION: 132,
  DOCUMENT_ENUM_COUNSELING_CERTIFICATION: 133,
  DOCUMENT_ENUM_COUNSELING_CERTIFICATION_HOMEOWNERSHIP: 134,
  DOCUMENT_ENUM_COUNSELING_CERTIFICATION_HOME_RETENTION: 135,
  DOCUMENT_ENUM_COUNSELING_CHECKLIST_FOR_MILITARY_HOMEBUYERS: 136,
  DOCUMENT_ENUM_CREDIT_CARD_AUTHORIZATION: 137,
  DOCUMENT_ENUM_CREDIT_INSURANCE_AGREEMENT: 138,
  DOCUMENT_ENUM_CREDIT_REPORT: 139,
  DOCUMENT_ENUM_CREDIT_REPORTING_ADJUSTMENT: 140,
  DOCUMENT_ENUM_CUSTOMER_IDENTIFICATION_VERIFICATION: 141,
  DOCUMENT_ENUM_DEATH_CERTIFICATE: 142,
  DOCUMENT_ENUM_DEBTORS_STATEMENT_OF_RIGHTS: 143,
  DOCUMENT_ENUM_DIVORCE_DECREE: 144,
  DOCUMENT_ENUM_DOCUMENT_DELIVERY_CERTIFICATION: 145,
  DOCUMENT_ENUM_DOCUMENT_DELIVERY_CERTIFICATION_APPRAISAL_REPORT: 146,
  DOCUMENT_ENUM_DOCUMENT_SEQUENCE: 147,
  DOCUMENT_ENUM_DRIVERS_LICENSE: 148,
  DOCUMENT_ENUM_EARLY_START_LETTER: 149,
  DOCUMENT_ENUM_ELECTRONIC_FUNDS_TRANSFER: 150,
  DOCUMENT_ENUM_ELEVATION_CERTIFICATE: 151,
  DOCUMENT_ENUM_EMPLOYEE_IDENTIFICATION: 152,
  DOCUMENT_ENUM_ENERGY_EFFICIENT_MORTGAGE_WORKSHEET: 153,
  DOCUMENT_ENUM_EQUAL_CREDIT_OPPORTUNITY_ACT_DISCLOSURE: 154,
  DOCUMENT_ENUM_ESCROW_AGREEMENT: 155,
  DOCUMENT_ENUM_ESCROW_ANALYSIS_ANNUAL_DISCLOSURE_STATEMENT: 156,
  DOCUMENT_ENUM_ESCROW_FOR_COMPLETION_AGREEMENT: 157,
  DOCUMENT_ENUM_ESCROW_FOR_COMPLETION_LETTER: 158,
  DOCUMENT_ENUM_ESCROW_WAIVER: 159,
  DOCUMENT_ENUM_ESTIMATE_OF_CLOSING_COSTS_PAID_TO_THIRD_PARTY: 160,
  DOCUMENT_ENUM_ESTOPPEL_AGREEMENT: 161,
  DOCUMENT_ENUM_EVALUATION_REPORT: 162,
  DOCUMENT_ENUM_EVALUATION_REPORT_DESKTOP: 163,
  DOCUMENT_ENUM_EVALUATION_REPORT_EXTERIOR_INSPECTION: 164,
  DOCUMENT_ENUM_FACTA_CREDIT_SCORE_DISCLOSURE: 165,
  DOCUMENT_ENUM_FAIR_LENDING_NOTICE: 166,
  DOCUMENT_ENUM_FEDERAL_APPLICATION_INSURANCE_DISCLOSURE: 167,
  DOCUMENT_ENUM_FEDERAL_SALE_OF_INSURANCE_DISCLOSURE: 168,
  DOCUMENT_ENUM_FH_A__MI_PREMIUM_NETTING_AUTHORIZATION: 169,
  DOCUMENT_ENUM_FHA_FIVE_DAY_WAIVER: 170,
  DOCUMENT_ENUM_FHA_LIMITED_DENIAL_OF_PARTICIPATION_GENERAL_SERVICES_ADMINISTRATION_CHECKLIST: 171,
  DOCUMENT_ENUM_FHA_MORTGAGE_CREDIT_ANALYSIS_WORKSHEET: 172,
  DOCUMENT_ENUM_FHA_REFERRAL_CHECKLIST: 173,
  DOCUMENT_ENUM_FHA_REFINANCE_MAXIMUM_MORTGAGE_WORKSHEET: 174,
  DOCUMENT_ENUM_FHA_REFINANCE_OF_BORROWERS_IN_NEGATIVE_EQUITY_POSITIONS_BORROWER_CERTIFICATION: 175,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT: 176,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_BALANCE_SHEET: 177,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_CASH_FLOW: 178,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_FINANCIAL_POSITION: 179,
  DOCUMENT_ENUM_FINANCIAL_STATEMENT_INCOME: 180,
  DOCUMENT_ENUM_FLOOD_HAZARD_NOTICE: 181,
  DOCUMENT_ENUM_FLOOD_INSURANCE_AGREEMENT: 182,
  DOCUMENT_ENUM_FORBEARANCE_PLAN_AGREEMENT: 183,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE: 184,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_CANCELLATION: 185,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_INITIAL: 186,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_INSUFFICIENT_DOCUMENTATION: 187,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_PLACEMENT: 188,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_RENEWAL: 189,
  DOCUMENT_ENUM_FORCE_PLACED_INSURANCE_NOTICE_SECOND_AND_FINAL: 190,
  DOCUMENT_ENUM_FOR_YOUR_PROTECTION_HOME_INSPECTION: 191,
  DOCUMENT_ENUM_FRE_OWNED_STREAMLINE_REFINANCE_CHECKLIST: 192,
  DOCUMENT_ENUM_FUNDING_TRANSMITTAL: 193,
  DOCUMENT_ENUM_GENERAL_LOAN_ACKNOWLEDGMENT: 194,
  DOCUMENT_ENUM_GFE: 195,
  DOCUMENT_ENUM_GIFT_LETTER: 196,
  DOCUMENT_ENUM_GROUP_SAVINGS_AGREEMENT: 197,
  DOCUMENT_ENUM_HECM_ANTI_CHURNING_DISCLOSURE: 198,
  DOCUMENT_ENUM_HECM_CALCULATION_EVIDENCE: 199,
  DOCUMENT_ENUM_HECM_CHOICE_OF_INSURANCE_OPTIONS_NOTICE: 200,
  DOCUMENT_ENUM_HECM_CLOSING_COSTS_AND_LIENS_SCHEDULE: 201,
  DOCUMENT_ENUM_HECM_COUNSELING_WAIVER_QUALIFICATION: 202,
  DOCUMENT_ENUM_HECM_EXTENSION: 203,
  DOCUMENT_ENUM_HECM_FACE_TO_FACE_CERTIFICATION: 204,
  DOCUMENT_ENUM_HECM_FINANCIAL_ASSESSMENT_WORKSHEET: 205,
  DOCUMENT_ENUM_HECM_LOAN_SUBMISSION_SCHEDULE: 206,
  DOCUMENT_ENUM_HECM_NOTICE_TO_BORROWER: 207,
  DOCUMENT_ENUM_HECM_PAYMENT_PLAN: 208,
  DOCUMENT_ENUM_HERS_HOME_ENERGY_REPORT: 209,
  DOCUMENT_ENUM_HIGH_COST_WORKSHEET: 210,
  DOCUMENT_ENUM_HOLD_HARMLESS_AGREEMENT: 211,
  DOCUMENT_ENUM_HOME_BUYER_EDUCATION_CERTIFICATION: 212,
  DOCUMENT_ENUM_HOME_EQUITY_LINE_FREEZE_LETTER: 213,
  DOCUMENT_ENUM_HOMEOWNERS_ASSOCIATION_CERTIFICATION: 214,
  DOCUMENT_ENUM_HOUSING_COUNSELING_AGENCIES_LIST: 215,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT: 216,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT_IDENTITY_THEFT: 217,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT_NAME: 218,
  DOCUMENT_ENUM_IDENTITY_AFFIDAVIT_SIGNATURE: 219,
  DOCUMENT_ENUM_IDENTITY_THEFT_DISCLOSURE: 220,
  DOCUMENT_ENUM_INCOMPLETE_APPLICATION_NOTICE: 221,
  DOCUMENT_ENUM_INDIVIDUAL_DEVELOPMENT_ACCOUNT_STATEMENT: 222,
  DOCUMENT_ENUM_INITIAL_ADJUSTABLE_RATE_MORTGAGE_DISCLOSURE: 223,
  DOCUMENT_ENUM_INITIAL_ESCROW_ACCOUNT_DISCLOSURE: 224,
  DOCUMENT_ENUM_INTEGRATED_DISCLOSURE: 225,
  DOCUMENT_ENUM_INTEREST_RATE_AND_DISCOUNT_DISCLOSURE_STATEMENT: 226,
  DOCUMENT_ENUM_INVOICE: 227,
  DOCUMENT_ENUM_INVOICE_UTILITY_BILL: 228,
  DOCUMENT_ENUM_IRS_1040: 229,
  DOCUMENT_ENUM_IRS_1041: 230,
  DOCUMENT_ENUM_IRS_1065: 231,
  DOCUMENT_ENUM_IRS_1098: 232,
  DOCUMENT_ENUM_IRS_1099: 233,
  DOCUMENT_ENUM_IRS_1099_A: 234,
  DOCUMENT_ENUM_IRS_1099_B: 235,
  DOCUMENT_ENUM_IRS_1099_C: 236,
  DOCUMENT_ENUM_IRS_1099_CAP: 237,
  DOCUMENT_ENUM_IRS_1099_DIV: 238,
  DOCUMENT_ENUM_IRS_1099_G: 239,
  DOCUMENT_ENUM_IRS_1099_H: 240,
  DOCUMENT_ENUM_IRS_1099_INT: 241,
  DOCUMENT_ENUM_IRS_1099_LTC: 242,
  DOCUMENT_ENUM_IRS_1099_MISC: 243,
  DOCUMENT_ENUM_IRS_1099_OID: 244,
  DOCUMENT_ENUM_IRS_1099_PATR: 245,
  DOCUMENT_ENUM_IRS_1099_Q: 246,
  DOCUMENT_ENUM_IRS_1099_R: 247,
  DOCUMENT_ENUM_IRS_1099_S: 248,
  DOCUMENT_ENUM_IRS_1099_SA: 249,
  DOCUMENT_ENUM_IRS_1120: 250,
  DOCUMENT_ENUM_IRS_1120_S: 251,
  DOCUMENT_ENUM_IRS_TAX_TRANSCRIPT: 252,
  DOCUMENT_ENUM_IRSW_2: 253,
  DOCUMENT_ENUM_IRSW_8: 254,
  DOCUMENT_ENUM_IRSW_9: 255,
  DOCUMENT_ENUM_ITEMIZATION_OF_AMOUNT_FINANCED: 256,
  DOCUMENT_ENUM_JUDGMENT: 257,
  DOCUMENT_ENUM_LAND_LEASEHOLD_AGREEMENT: 258,
  DOCUMENT_ENUM_LAST_WILL_AND_TESTAMENT: 259,
  DOCUMENT_ENUM_LEAD_HAZARD_INFORMATION: 260,
  DOCUMENT_ENUM_LEGAL_DESCRIPTION: 261,
  DOCUMENT_ENUM_LENDER_CORRESPONDENCE: 262,
  DOCUMENT_ENUM_LENDER_LATE_ENDORSEMENT_REQUEST_CERTIFICATION_LETTER: 263,
  DOCUMENT_ENUM_LIFE_INSURANCE_POLICY: 264,
  DOCUMENT_ENUM_LINE_ITEM_BUDGET: 265,
  DOCUMENT_ENUM_LOAN_APPLICATION: 266,
  DOCUMENT_ENUM_LOAN_APPLICATION_FNM_1009: 267,
  DOCUMENT_ENUM_LOAN_APPLICATION_URLA: 268,
  DOCUMENT_ENUM_LOAN_CLOSING_NOTICE: 269,
  DOCUMENT_ENUM_LOAN_ESTIMATE: 270,
  DOCUMENT_ENUM_LOAN_MODIFICATION_AFFIDAVIT: 271,
  DOCUMENT_ENUM_LOAN_MODIFICATION_AGREEMENT: 272,
  DOCUMENT_ENUM_LOAN_MODIFICATION_DENIAL_NOTICE: 273,
  DOCUMENT_ENUM_LOAN_MODIFICATION_REQUEST: 274,
  DOCUMENT_ENUM_LOAN_MODIFICATION_TRIAL_PAYMENT_PERIOD_AGREEMENT: 275,
  DOCUMENT_ENUM_LOAN_PAYOFF_REQUEST: 276,
  DOCUMENT_ENUM_LOAN_STATEMENT: 277,
  DOCUMENT_ENUM_LOAN_SUBMISSION_SUMMARY: 278,
  DOCUMENT_ENUM_MANUFACTURED_HOUSING_CERTIFICATE_OF_TITLE: 279,
  DOCUMENT_ENUM_MARITAL_STATUS_AFFIDAVIT: 280,
  DOCUMENT_ENUM_MARRIAGE_CERTIFICATE: 281,
  DOCUMENT_ENUM_MI_APPLICATION: 282,
  DOCUMENT_ENUM_MI_CERTIFICATE: 283,
  DOCUMENT_ENUM_MI_CONDITIONAL_COMMITMENT: 284,
  DOCUMENT_ENUM_MI_DECLINATION: 285,
  DOCUMENT_ENUM_MI_DISCLOSURE: 286,
  DOCUMENT_ENUM_MILITARY_DISCHARGE_PAPERS: 287,
  DOCUMENT_ENUM_MILITARY_IDENTIFICATION: 288,
  DOCUMENT_ENUM_MI_MODIFICATION: 289,
  DOCUMENT_ENUM_MI_RATE_QUOTE: 290,
  DOCUMENT_ENUM_MI_SUSPENSION: 291,
  DOCUMENT_ENUM_MONTHLY_SUMMARY_OF_ASSISTANCE_PAYMENTS: 292,
  DOCUMENT_ENUM_MORTGAGEES_CERTIFICATION_AND_APPLICATION_FOR_ASSISTANCE_OR_INTEREST_REDUCTION_PAYMENTS: 293,
  DOCUMENT_ENUM_NAME_AFFIDAVIT: 294,
  DOCUMENT_ENUM_NATIONAL_IDENTIFICATION: 295,
  DOCUMENT_ENUM_NATIONAL_IDENTIFICATION_CEDULA_DE_IDENTIDAD: 296,
  DOCUMENT_ENUM_NATIONAL_IDENTIFICATION_SOCIAL_SECURITY_CARD: 297,
  DOCUMENT_ENUM_NEAREST_LIVING_RELATIVE_INFORMATION: 298,
  DOCUMENT_ENUM_NEAREST_LIVING_RELATIVE_INFORMATION_ALTERNATIVE_CONTACT: 299,
  DOCUMENT_ENUM_NEAREST_LIVING_RELATIVE_INFORMATION_VA_QUESTIONNAIRE: 300,
  DOCUMENT_ENUM_NON_BORROWING_SPOUSE_CERTIFICATION_LETTER: 301,
  DOCUMENT_ENUM_NON_BORROWING_SPOUSE_CERTIFICATION_LETTER_ELIGIBLE: 302,
  DOCUMENT_ENUM_NON_BORROWING_SPOUSE_CERTIFICATION_LETTER_INELIGIBLE: 303,
  DOCUMENT_ENUM_NON_DIPLOMAT_VERIFICATION: 304,
  DOCUMENT_ENUM_NON_REFUNDABILITY_NOTICE: 305,
  DOCUMENT_ENUM_NOTE: 306,
  DOCUMENT_ENUM_NOTE_CONSOLIDATED: 307,
  DOCUMENT_ENUM_NOTE_HECM_LOAN_AGREEMENT: 308,
  DOCUMENT_ENUM_NOTE_NEW_MONEY: 309,
  DOCUMENT_ENUM_NOTE_ADDENDUM: 310,
  DOCUMENT_ENUM_NOTE_ADDENDUM_AFFORDABLE_MERIT_RATE: 311,
  DOCUMENT_ENUM_NOTE_ADDENDUM_ARBITRATION: 312,
  DOCUMENT_ENUM_NOTE_ADDENDUM_ARM: 313,
  DOCUMENT_ENUM_NOTE_ADDENDUM_BALLOON: 314,
  DOCUMENT_ENUM_NOTE_ADDENDUM_CONSTRUCTION: 315,
  DOCUMENT_ENUM_NOTE_ADDENDUM_FIXED_RATE_OPTION: 316,
  DOCUMENT_ENUM_NOTE_ADDENDUM_GEM: 317,
  DOCUMENT_ENUM_NOTE_ADDENDUM_GPM: 318,
  DOCUMENT_ENUM_NOTE_ADDENDUM_INTEREST_ONLY: 319,
  DOCUMENT_ENUM_NOTE_ADDENDUM_INTER_VIVOS_REVOCABLE_TRUST: 320,
  DOCUMENT_ENUM_NOTE_ADDENDUM_OCCUPANCY_DECLARATION: 321,
  DOCUMENT_ENUM_NOTE_ADDENDUM_PREPAYMENT: 322,
  DOCUMENT_ENUM_NOTE_ADDENDUM_RATE_IMPROVEMENT: 323,
  DOCUMENT_ENUM_NOTE_ALLONGE: 324,
  DOCUMENT_ENUM_NOTE_AND_SECURITY_INSTRUMENT_MODIFICATION: 325,
  DOCUMENT_ENUM_NOTE_MODIFICATION: 326,
  DOCUMENT_ENUM_NOTE_RIDER: 327,
  DOCUMENT_ENUM_NOTE_RIDER_ARM: 328,
  DOCUMENT_ENUM_NOTE_RIDER_BALLOON: 329,
  DOCUMENT_ENUM_NOTE_RIDER_BUYDOWN: 330,
  DOCUMENT_ENUM_NOTE_RIDER_HECM_REPAIR: 331,
  DOCUMENT_ENUM_NOTE_RIDER_OCCUPANCY: 332,
  DOCUMENT_ENUM_NOTE_RIDER_PREPAYMENT: 333,
  DOCUMENT_ENUM_NOTE_RIDER_STEP_RATE: 334,
  DOCUMENT_ENUM_NOTICE_OF_ACTION_TAKEN: 335,
  DOCUMENT_ENUM_NOTICE_OF_COMPLETION: 336,
  DOCUMENT_ENUM_NOTICE_OF_DEFAULT: 337,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL: 338,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_ADD_SECURITY_INTEREST: 339,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_CREDIT_INCREASE: 340,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_INCREASE_SECURITY: 341,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_CANCEL_OPEN_ACCOUNT: 342,
  DOCUMENT_ENUM_NOTICE_OF_RIGHT_TO_COPY_OF_APPRAISAL_REPORT: 343,
  DOCUMENT_ENUM_NOTICE_TO_HOMEBUYER: 344,
  DOCUMENT_ENUM_NOTICE_TO_LENDER: 345,
  DOCUMENT_ENUM_OCCUPANCY_AGREEMENT: 346,
  DOCUMENT_ENUM_OCCUPANCY_CERTIFICATION: 347,
  DOCUMENT_ENUM_OTHER: 348,
  DOCUMENT_ENUM_PARTIAL_RELEASE_OF_SECURITY_INSTRUMENT: 349,
  DOCUMENT_ENUM_PARTNERSHIP_AGREEMENT: 350,
  DOCUMENT_ENUM_PASSPORT: 351,
  DOCUMENT_ENUM_PAYMENT_HISTORY: 352,
  DOCUMENT_ENUM_PAYMENT_LETTER: 353,
  DOCUMENT_ENUM_PAYOFF_STATEMENT: 354,
  DOCUMENT_ENUM_PAY_STUB: 355,
  DOCUMENT_ENUM_PERMANENT_RESIDENT_IDENTIFICATION: 356,
  DOCUMENT_ENUM_PERSONAL_PROPERTY_APPRAISAL_REPORT: 357,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY: 358,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY_DURABLE: 359,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY_LIMITED: 360,
  DOCUMENT_ENUM_POWER_OF_ATTORNEY_MANUFACTURED_HOUSING: 361,
  DOCUMENT_ENUM_PREPAYMENT_CHARGE_OPTION_NOTICE: 362,
  DOCUMENT_ENUM_PREQUALIFICATION_LETTER: 363,
  DOCUMENT_ENUM_PRIMARY_MI_ANNUAL_DISCLOSURE: 364,
  DOCUMENT_ENUM_PRIVACY_DISCLOSURE: 365,
  DOCUMENT_ENUM_PRIVATE_IDENTIFICATION: 366,
  DOCUMENT_ENUM_PRODUCT_COMPARISON_DISCLOSURE: 367,
  DOCUMENT_ENUM_PROOF_OF_HUD_WAIVER_CAIVRS_CLEARANCE: 368,
  DOCUMENT_ENUM_PROOF_OF_MONETARY_INVESTMENT: 369,
  DOCUMENT_ENUM_PROOF_OF_RESIDENCE_IN_DECLARED_MAJOR_DISASTER_AREA: 370,
  DOCUMENT_ENUM_PROOF_OF_TAX_DEFERRAL_OR_EXEMPTION: 371,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT: 372,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_CARPET: 373,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_ENVIRONMENTAL: 374,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_FOOTINGS: 375,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_FRAMING: 376,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_HEATING: 377,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_INSULATION: 378,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_PEST: 379,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_PLUMBING: 380,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_ROOF: 381,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SEPTIC: 382,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SOIL: 383,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SOIL_TREATMENT: 384,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_STRUCTURAL_ENGINEERING: 385,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_SUBTERRANEAN_TERMITE: 386,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_TERMITE: 387,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_WATER_HEALTH: 388,
  DOCUMENT_ENUM_PROPERTY_INSPECTION_REPORT_WELL: 389,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER: 390,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_EARTHQUAKE: 391,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_FIRE_AND_EXTENDED_COVERAGE: 392,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_FLOOD: 393,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_HAZARD: 394,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_HOMEOWNERS: 395,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_HURRICANE: 396,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_INSECT_INFESTATION: 397,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_LEASEHOLD: 398,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_MINE_SUBSIDENCE: 399,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_PERSONAL_PROPERTY: 400,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_STORM: 401,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_TORNADO: 402,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_VOLCANO: 403,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_BINDER_WIND: 404,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS: 405,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_EARTHQUAKE: 406,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_FIRE_AND_EXTENDED_COVERAGE: 407,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_FLOOD: 408,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_HAZARD: 409,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_HOMEOWNERS: 410,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_HURRICANE: 411,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_INSECT_INFESTATION: 412,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_LEASEHOLD: 413,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_MINE_SUBSIDENCE: 414,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_PERSONAL_PROPERTY: 415,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_STORM: 416,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_TORNADO: 417,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_VOLCANO: 418,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_DECLARATIONS_WIND: 419,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY: 420,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_EARTHQUAKE: 421,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_FIRE_AND_EXTENDED_COVERAGE: 422,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_FLOOD: 423,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_HAZARD: 424,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_HOMEOWNERS: 425,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_HURRICANE: 426,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_INSECT_INFESTATION: 427,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_LEASEHOLD: 428,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_MINE_SUBSIDENCE: 429,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_PERSONAL_PROPERTY: 430,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_STORM: 431,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_TORNADO: 432,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_VOLCANO: 433,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_POLICY_WIND: 434,
  DOCUMENT_ENUM_PROPERTY_INSURANCE_REQUIREMENT_DISCLOSURE: 435,
  DOCUMENT_ENUM_PURCHASE_AGREEMENT: 436,
  DOCUMENT_ENUM_RATE_CHANGE_NOTICE: 437,
  DOCUMENT_ENUM_RATE_LOCK_AGREEMENT: 438,
  DOCUMENT_ENUM_RATE_LOCK_AGREEMENT_BUSINESS_TO_BUSINESS: 439,
  DOCUMENT_ENUM_RATE_LOCK_AGREEMENT_BUSINESS_TO_CONSUMER: 440,
  DOCUMENT_ENUM_REAFFIRMATION_AGREEMENT: 441,
  DOCUMENT_ENUM_RECEIPT: 442,
  DOCUMENT_ENUM_RECERTIFICATION_OF_FAMILY_INCOME_AND_COMPOSITION: 443,
  DOCUMENT_ENUM_RECERTIFICATION_OF_FAMILY_INCOME_STATISTICAL_REPORT: 444,
  DOCUMENT_ENUM_RECONVEYANCE: 445,
  DOCUMENT_ENUM_REFINANCE_COST_ESTIMATE_WORKSHEET: 446,
  DOCUMENT_ENUM_REFINANCE_COST_ESTIMATE_WORKSHEET_HECM: 447,
  DOCUMENT_ENUM_RELOCATION_BENEFITS_PACKAGE: 448,
  DOCUMENT_ENUM_RELOCATION_BUYOUT_AGREEMENT: 449,
  DOCUMENT_ENUM_REMITTANCE_DELIVERY_RECEIPT: 450,
  DOCUMENT_ENUM_RENTAL_AGREEMENT: 451,
  DOCUMENT_ENUM_RENTAL_INCOME_ANALYSIS_STATEMENT: 452,
  DOCUMENT_ENUM_REPAYMENT_PLAN_AGREEMENT: 453,
  DOCUMENT_ENUM_REQUEST_FOR_AGREEMENT_OF_SHORT_SALE: 454,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN: 455,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN_IRS_4506: 456,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN_IRS_4506_T: 457,
  DOCUMENT_ENUM_REQUEST_FOR_COPY_OF_TAX_RETURN_IRS_4506_TEZ: 458,
  DOCUMENT_ENUM_REQUEST_FOR_NOTICE_OF_DEFAULT: 459,
  DOCUMENT_ENUM_RESIDUAL_INCOME_ANALYSIS_WORKSHEET: 460,
  DOCUMENT_ENUM_RESOLUTION_OF_CONSUMER_COMPLAINT: 461,
  DOCUMENT_ENUM_RETIREMENT_ACCOUNT_STATEMENT: 462,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE: 463,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_ACCOUNT_REVIEW_NOTICE: 464,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_CREDIT_SCORE_DISCLOSURE_EXCEPTION: 465,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_CREDIT_SCORE_DISCLOSURE_NO_RESIDENTIAL_SECURED: 466,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_CREDIT_SCORE_DISCLOSURE_NO_SCORE: 467,
  DOCUMENT_ENUM_RISK_BASED_PRICING_NOTICE_GENERAL_NOTICE: 468,
  DOCUMENT_ENUM_ROAD_MAINTENANCE_AGREEMENT: 469,
  DOCUMENT_ENUM_SATISFACTION_OF_JUDGMENT: 470,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT: 471,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT_LIEN_RELEASE: 472,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT_SATISFACTION_OF_DEED_OF_TRUST: 473,
  DOCUMENT_ENUM_SATISFACTION_OF_SECURITY_INSTRUMENT_SATISFACTION_OF_MORTGAGE: 474,
  DOCUMENT_ENUM_SCRA_NOTICE_DISCLOSURE: 475,
  DOCUMENT_ENUM_SECTION32_DISCLOSURE_NOTICE: 476,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT: 477,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_DEED_OF_TRUST: 478,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MORTGAGE: 479,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_ADDENDUM: 480,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_ADDENDUM_FIXED_RATE_OPTION: 481,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION: 482,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION_CONSOLIDATION_AGREEMENT: 483,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION_CONSOLIDATION_EXTENSION_MODIFICATION_AGREEMENT: 484,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_MODIFICATION_MODIFICATION_AGREEMENT: 485,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER: 486,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_AFFORDABLE_MERIT_RATE: 487,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_ARM: 488,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_BALLOON: 489,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_BENEFICIARY: 490,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_BIWEEKLY: 491,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_CONDOMINIUM: 492,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_CONSTRUCTION: 493,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_GEM: 494,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_GPM: 495,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_HOMESTEAD_EXEMPTION: 496,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_INTEREST_ONLY: 497,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_INTER_VIVOS_REVOCABLE_TRUST: 498,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_INVESTOR: 499,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_LAND_TRUST: 500,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_LEASEHOLD: 501,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_MANUFACTURED_HOUSING: 502,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_MERS_REGISTRY: 503,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_NON_OWNER: 504,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_ONE_TO_FOUR_FAMILY: 505,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_OWNER_OCCUPANCY: 506,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_PREPAYMENT: 507,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_PUD: 508,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_RATE_IMPROVEMENT: 509,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_REHABILITATION: 510,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_RENEWAL_AND_EXTENSION: 511,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_SECOND_HOME: 512,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_SECOND_LIEN: 513,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_TAX_EXEMPT_FINANCING: 514,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_VA: 515,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_VETERANS_LAND_BOARD: 516,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_WAIVER_OF_BORROWERS_RIGHTS: 517,
  DOCUMENT_ENUM_SECURITY_INSTRUMENT_RIDER_WAIVER_OF_DOWER_RIGHTS: 518,
  DOCUMENT_ENUM_SELLER_LEAD_HAZARD_DISCLOSURE_AND_ACKNOWLEDGMENT: 519,
  DOCUMENT_ENUM_SERVICING_DISCLOSURE_STATEMENT: 520,
  DOCUMENT_ENUM_SERVICING_TRANSFER_STATEMENT: 521,
  DOCUMENT_ENUM_SETTLEMENT_STATEMENT: 522,
  DOCUMENT_ENUM_SETTLEMENT_STATEMENT_HUD_1: 523,
  DOCUMENT_ENUM_SETTLEMENT_STATEMENT_HUD_1_A: 524,
  DOCUMENT_ENUM_SHORT_SALE_AGREEMENT: 525,
  DOCUMENT_ENUM_SHORT_SALE_PROCESS_CHECKLIST: 526,
  DOCUMENT_ENUM_SOCIAL_SECURITY_AWARD_LETTER: 527,
  DOCUMENT_ENUM_SOLICITATION_LETTER: 528,
  DOCUMENT_ENUM_STANDARD_FLOOD_HAZARD_DETERMINATION: 529,
  DOCUMENT_ENUM_STATE_IDENTIFICATION: 530,
  DOCUMENT_ENUM_STATEMENT_OF_BORROWER_BENEFIT: 531,
  DOCUMENT_ENUM_STOCK_CERTIFICATE: 532,
  DOCUMENT_ENUM_SUBORDINATION_AGREEMENT: 533,
  DOCUMENT_ENUM_SUBSIDY_AGREEMENT: 534,
  DOCUMENT_ENUM_SUBSTITUTION_OF_TRUSTEE: 535,
  DOCUMENT_ENUM_SURVEY: 536,
  DOCUMENT_ENUM_SURVEY_AFFIDAVIT: 537,
  DOCUMENT_ENUM_TAX_ASSESSMENT_VALUE_INDICATION: 538,
  DOCUMENT_ENUM_TAX_AUTHORIZATION: 539,
  DOCUMENT_ENUM_TAX_CERTIFICATE: 540,
  DOCUMENT_ENUM_TAX_LIEN: 541,
  DOCUMENT_ENUM_TAX_LIEN_FEDERAL: 542,
  DOCUMENT_ENUM_TAX_LIEN_LOCAL: 543,
  DOCUMENT_ENUM_TAX_LIEN_STATE: 544,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE: 545,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE_FEDERAL: 546,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE_LOCAL: 547,
  DOCUMENT_ENUM_TAX_LIEN_RELEASE_STATE: 548,
  DOCUMENT_ENUM_TAXPAYER_IDENTIFICATION: 549,
  DOCUMENT_ENUM_TAXPAYER_IDENTIFICATION_NUMERO_DE_IDENTIFICACION_TRIBUTARIA: 550,
  DOCUMENT_ENUM_TAX_RETURN: 551,
  DOCUMENT_ENUM_TEN_YEAR_WARRANTY: 552,
  DOCUMENT_ENUM_THIRD_PARTY_EMPLOYMENT_STATEMENT: 553,
  DOCUMENT_ENUM_TIL_DISCLOSURE: 554,
  DOCUMENT_ENUM_TITLE_ABSTRACT: 555,
  DOCUMENT_ENUM_TITLE_COMMITMENT: 556,
  DOCUMENT_ENUM_TITLE_INSURANCE_ENDORSEMENT: 557,
  DOCUMENT_ENUM_TITLE_INSURANCE_POLICY: 558,
  DOCUMENT_ENUM_TRIAL_LOAN_MODIFICATION_AGREEMENT: 559,
  DOCUMENT_ENUM_TRIAL_PERIOD_PLAN_NOTICE: 560,
  DOCUMENT_ENUM_TRUST_AGREEMENT: 561,
  DOCUMENT_ENUM_UCC_1_STATEMENT: 562,
  DOCUMENT_ENUM_UCC_3_STATEMENT: 563,
  DOCUMENT_ENUM_UNDERWRITING_CONDITIONS: 564,
  DOCUMENT_ENUM_UNDERWRITING_CONDITIONS_MI_APPLICATION: 565,
  DOCUMENT_ENUM_UNDERWRITING_TRANSMITTAL: 566,
  DOCUMENT_ENUM_UNUSED_ESCROW_FUNDS_DISTRIBUTION_AUTHORIZATION: 567,
  DOCUMENT_ENUM_UTILITY_BILL: 568,
  DOCUMENT_ENUM_VA_ACKNOWLEDGMENT_OF_NO_INSPECTION: 569,
  DOCUMENT_ENUM_VA_CERTIFICATE_OF_ELIGIBILITY: 570,
  DOCUMENT_ENUM_VA_CERTIFICATE_OF_REASONABLE_VALUE: 571,
  DOCUMENT_ENUM_VA_COLLECTION_POLICY_NOTICE: 572,
  DOCUMENT_ENUM_VA_FORECLOSURE_BID_LETTER: 573,
  DOCUMENT_ENUM_VA_FUNDING_FEE_NOTICE: 574,
  DOCUMENT_ENUM_VA_INTEREST_RATE_REDUCTION_REFINANCING_LOAN_WORKSHEET: 575,
  DOCUMENT_ENUM_VA_LOAN_ANALYSIS: 576,
  DOCUMENT_ENUM_VA_LOAN_SUMMARY_SHEET: 577,
  DOCUMENT_ENUM_VALUATION_UPDATE: 578,
  DOCUMENT_ENUM_VA_RATE_REDUCTION_REFINANCE_INFORMATION_AND_AGREEMENT: 579,
  DOCUMENT_ENUM_VA_REPORT_AND_CERTIFICATION_OF_LOAN_DISBURSEMENT: 580,
  DOCUMENT_ENUM_VA_REQUEST_FOR_CERTIFICATION_OF_ELIGIBILITY_FOR_HOME_LOAN_BENEFIT: 581,
  DOCUMENT_ENUM_VA_VERIFICATION_OF_BENEFIT_RELATED_INDEBTEDNESS: 582,
  DOCUMENT_ENUM_VERIFICATION_OF_ADDRESS: 583,
  DOCUMENT_ENUM_VERIFICATION_OF_CREDIT: 584,
  DOCUMENT_ENUM_VERIFICATION_OF_DEBT: 585,
  DOCUMENT_ENUM_VERIFICATION_OF_DEPENDENT_CARE: 586,
  DOCUMENT_ENUM_VERIFICATION_OF_DEPOSIT: 587,
  DOCUMENT_ENUM_VERIFICATION_OF_EMPLOYMENT: 588,
  DOCUMENT_ENUM_VERIFICATION_OF_MERS_REGISTRY: 589,
  DOCUMENT_ENUM_VERIFICATION_OF_MORTGAGE_OR_RENT: 590,
  DOCUMENT_ENUM_VERIFICATION_OF_SECURITIES: 591,
  DOCUMENT_ENUM_VERIFICATION_OF_SSN: 592,
  DOCUMENT_ENUM_VISA: 593,
  DOCUMENT_ENUM_VOLUNTEER_ESCROW_PREPAYMENT_DESIGNATION: 594,
  DOCUMENT_ENUM_VOTER_REGISTRATION: 595,
  DOCUMENT_ENUM_WARRANTY_OF_COMPLETION_OF_CONSTRUCTION: 596,
  DOCUMENT_ENUM_WIRE_INSTRUCTIONS: 597,
  DOCUMENT_ENUM_WIRE_TRANSFER_AUTHORIZATION: 598,
  DOCUMENT_ENUM_WIRE_TRANSFER_CONFIRMATION: 599,
  DOCUMENT_ENUM_WORKSHEET: 600,
  DOCUMENT_ENUM_FRAUDGUARD: 601
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_docgen.DocumentStatusEnum = {
  DOCUMENT_STATUS_ENUM_UNKNOWN: 0,
  DOCUMENT_STATUS_ENUM_NEEDED: 1,
  DOCUMENT_STATUS_ENUM_REQUESTED: 2,
  DOCUMENT_STATUS_ENUM_RECEIVED: 3,
  DOCUMENT_STATUS_ENUM_REVIEWED: 4,
  DOCUMENT_STATUS_ENUM_EXPIRED: 5,
  DOCUMENT_STATUS_ENUM_NOT_USED: 6,
  DOCUMENT_STATUS_ENUM_VALIDATED: 7,
  DOCUMENT_STATUS_ENUM_REJECTED: 8,
  DOCUMENT_STATUS_ENUM_ARCHIVED: 9
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_docgen.DocumentCategoryEnum = {
  DOCUMENT_CATEGORY_ENUM_UNKNOWN: 0,
  DOCUMENT_CATEGORY_ENUM_TRID_APPLICATION: 1,
  DOCUMENT_CATEGORY_ENUM_INTENT_TO_PROCEED: 2,
  DOCUMENT_CATEGORY_ENUM_CREDIT: 3,
  DOCUMENT_CATEGORY_ENUM_AUTOMATED_UNDERWRITING: 4,
  DOCUMENT_CATEGORY_ENUM_APPRAISAL: 5,
  DOCUMENT_CATEGORY_ENUM_FEES: 6,
  DOCUMENT_CATEGORY_ENUM_DISCLOSURES: 7,
  DOCUMENT_CATEGORY_ENUM_SUBMIT_APPLICATION: 8,
  DOCUMENT_CATEGORY_ENUM_INCOME: 9,
  DOCUMENT_CATEGORY_ENUM_EMPLOYMENT: 10,
  DOCUMENT_CATEGORY_ENUM_ASSET: 11,
  DOCUMENT_CATEGORY_ENUM_TITLE: 12,
  DOCUMENT_CATEGORY_ENUM_FLOOD: 13,
  DOCUMENT_CATEGORY_ENUM_CONDITIONS: 14,
  DOCUMENT_CATEGORY_ENUM_UNDERWRITER_DECISION: 15,
  DOCUMENT_CATEGORY_ENUM_CLEAR_TO_CLOSE: 16,
  DOCUMENT_CATEGORY_ENUM_CLOSING_DOCUMENTS: 17,
  DOCUMENT_CATEGORY_ENUM_FUNDS_TO_CLOSE: 18,
  DOCUMENT_CATEGORY_ENUM_FUNDING_WIRE: 19,
  DOCUMENT_CATEGORY_ENUM_MERS_TRANSFER: 20,
  DOCUMENT_CATEGORY_ENUM_TRAILING_DOCUMENTS: 21,
  DOCUMENT_CATEGORY_ENUM_POST_CLOSING_AUDIT: 22,
  DOCUMENT_CATEGORY_ENUM_FRAUD: 23,
  DOCUMENT_CATEGORY_ENUM_COMPLIANCE: 24,
  DOCUMENT_CATEGORY_ENUM_PURCHASE_CONTRACT: 25
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_docgen.DocumentValidPeriodInDaysEnum = {
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_UNKNOWN: 0,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_30: 1,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_60: 2,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_90: 3,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_120: 4,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_180: 5,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_240: 6,
  DOCUMENT_VALID_PERIOD_IN_DAYS_ENUM_365: 7
};

goog.object.extend(exports, proto.wilqo.api.mortgage_docgen);
