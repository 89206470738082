import { forwardRef, useCallback, useState } from 'react';

import { useGooglePredictions } from '@/API/Queries/external/useGooglePredictions';
import type { IGeoCodeData } from '@/Utils/Helpers/geoCodeConversion';
import { useGoogleGeocode } from '@/Utils/Hooks/useGoogleGeocode/useGoogleGeocode';

import AutoComplete from '../AutoComplete/AutoComplete.component';
import { UnlicensedStateDialog } from '../StateSelect/UnlicensedStateDialog';
import { ManualAddressDialog } from './ManualAddressDialog';

enum AddressAutoCompleteTypeEnum {
  ADDRESS = 0,
  ZIP,
}

export interface SelectedValue {
  geocode: IGeoCodeData;
  text: string;
}

export interface ComponentProps {
  placeholder: string;
  onSelect: (item: SelectedValue | null) => void;
  onValueUpdate?: (v: string) => void;
  value: any;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  type?: AddressAutoCompleteTypeEnum;
  validateState?: boolean;
  filterInternational?: boolean;
  disableManual?: boolean;
}

const AddressAutoComplete = forwardRef((props: ComponentProps, ref) => {
  const {
    disabled,
    onSelect,
    placeholder,
    validateState = false,
    required,
    type = AddressAutoCompleteTypeEnum.ADDRESS,
    value,
    filterInternational = true,
    disableManual = false,
  } = props;

  const [validateStateModalOpen, setValidateStateModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [manualAddressDialogOpen, setManualAddressDialogOpen] = useState(false);

  const { getGeocode } = useGoogleGeocode();
  const { data: results = [], isLoading } = useGooglePredictions(inputValue, undefined, filterInternational);

  const handleAddressText = useCallback((selection?: any) => {
    if (selection) {
      const { description: address } = selection;
      let addressToGeocode = address;
      if (type === AddressAutoCompleteTypeEnum.ZIP) {
        addressToGeocode = address.replace(/\D/g, '');
      }
      getGeocode(addressToGeocode, validateState, (response) => {
        if (typeof response !== 'string') {
          if (!response.validState) {
            setValidateStateModalOpen(true);
          } else {
            onSelect({ geocode: response.geocode, text: address });
          }
        }
      });
    } else {
      onSelect(null);
    }
  }, [getGeocode, onSelect, type, validateState]);

  const handleManualSubmit = (value: SelectedValue) => {
    onSelect(value);
    setManualAddressDialogOpen(false);
  };

  const handleInputUpdate = (value: string) => {
    setInputValue(value);
    if (props.onValueUpdate) props.onValueUpdate(value);
  };

  return (
    <>
      <AutoComplete
        ref={ref}
        controlFilter={false}
        disabled={disabled}
        disableText={false}
        isLoading={isLoading && Boolean(inputValue)}
        noItemsClick={disableManual ? undefined : () => setManualAddressDialogOpen(true)}
        noItemsMessage={disableManual ? undefined : 'Create New Address'}
        onChangeInputValue={handleInputUpdate}
        onSelect={handleAddressText}
        optionKey="description"
        options={results}
        placeholder={placeholder}
        required={required}
        value={value}
      />
      <ManualAddressDialog
        close={() => setManualAddressDialogOpen(false)}
        handleSubmit={handleManualSubmit}
        open={manualAddressDialogOpen}
      />
      <UnlicensedStateDialog
        onClickDismiss={() => setValidateStateModalOpen(false)}
        open={validateStateModalOpen}
      />
    </>
  );
});

export default AddressAutoComplete;
