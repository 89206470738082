import { BinaryReader } from 'google-protobuf';
import { useCallback, useEffect, useRef, useState } from 'react';

import { SubscribeActivitiesCommandRequest, SubscribeActivitiesCommandResponse, UnsubscribeActivitiesCommandRequest } from '@/API/Models/Wilqo_API_Activity_Commands_pb';
import type { ActivitySummary } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { ActivitiesByEntityArgs, ActivitiesBySkillSetArgs, ActivitiesSubscriptionMessage } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import type { WilqoMessage } from '../useBackend';
import { useSubscriptionContext } from '../useSubscription';
import { useWebSocket } from '../useWebSocket';

const useActivitiesSubscription = (loanId = '', includeCompleted = false) => {
  const { commandResponse, isConnected } = useWebSocket();
  const { subscribe: subscribeContext } = useSubscriptionContext();
  const bpdId = useBPDId();

  const subscriptionId = useRef<string>('');
  const subscribed = useRef<boolean>(false);
  const [activities, setActivities] = useState<Array<ActivitySummary.AsObject>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const updateActivitiesList = (newActivities: ActivitySummary.AsObject[]) => {
    setActivities((activities) => {
      if (newActivities?.length > 0) {
        let updatedActivitiesList = [...activities];
        newActivities.forEach((activity) => {
          const index = activities.findIndex((e) => e.entityId === activity?.entityId);
          if (index === -1) {
            // TODO: This is adding a new activity to the list, but for the cases we are using a paginated table, how to proceed?
            // Add only if there is less activities in the list than the page size?
            updatedActivitiesList = [...updatedActivitiesList, activity];
          } else {
            updatedActivitiesList[index] = activity;
          }
        });
        return updatedActivitiesList;
      }
      return activities;
    });
  };

  const handleNewMessage = useCallback((protoMessage: any) => {
    setIsLoading(false);
    const message = ActivitiesSubscriptionMessage.deserializeBinary(protoMessage.getValue_asU8()).toObject();
    if (message.activity) {
      updateActivitiesList([message.activity]);
    }
  }, []);

  const unsubscribe = useCallback(async () => {
    const deleteRequest = new UnsubscribeActivitiesCommandRequest();
    deleteRequest.setBusinessProcessDomainId(bpdId);
    deleteRequest.setSubscriptionId(subscriptionId.current);
    if (loanId) {
      deleteRequest.setEntityId(loanId);
    }

    const unsubscribeMessage: WilqoMessage = {
      msg: deleteRequest,
      msgName: 'wilqo.api.activity.UnsubscribeActivitiesCommandRequest',
    };
    const response = await commandResponse(unsubscribeMessage);
    setIsLoading(false);
    return response;
  }, [loanId, bpdId, commandResponse]);

  const subscribe = useCallback(async () => {
    const request = new SubscribeActivitiesCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    if (loanId) {
      const appliesTo = new ActivitiesByEntityArgs();
      appliesTo.setEntityId(loanId);
      /*
        This is always true because when subscribing
        to all of an entity's activities, we need to
        display the activities organized into tabs by
        skill sets and status.
      */
      appliesTo.setIncludeAll(true);
      request.setAppliesTo(appliesTo);
    } else {
      const bySkillSets = new ActivitiesBySkillSetArgs();
      bySkillSets.setIncludeCompleted(includeCompleted);
      request.setSkillSet(bySkillSets);
    }

    const subscriptionMessage: WilqoMessage = {
      msg: request,
      msgName: 'wilqo.api.activity.SubscribeActivitiesCommandRequest',
    };
    const message = await commandResponse(subscriptionMessage);
    const response = new SubscribeActivitiesCommandResponse();
    SubscribeActivitiesCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(message.getValue()));
    const subscribeResponse = response.toObject();
    setIsLoading(false);
    if (subscribeResponse.error) {
      setError(subscribeResponse.error.errorMessage);
    } else {
      // Calling the same code as handleNewMessage() to keep activity list working while we update FE
      updateActivitiesList(subscribeResponse.currentActivitiesList);
      subscriptionId.current = subscribeResponse.subscriptionId;
      subscribeContext(
        subscribeResponse.subscriptionId,
        handleNewMessage,
        subscribe,
      );
    }
  }, [loanId, includeCompleted, bpdId, subscribeContext, handleNewMessage, commandResponse]);

  const refetch = useCallback(() => {
    setError(undefined);
    setIsLoading(true);
    subscribe();
  }, [subscribe]);

  useEffect(() => {
    if (!subscribed.current && isConnected) {
      subscribed.current = true;
      subscribe();
    }

    return () => {
      unsubscribe();
    };
  }, [subscribe, unsubscribe, isConnected]);

  return {
    activities,
    error,
    isLoading,
    refetch,
  };
};

const useLoanActivitiesSubscription = (loanId: string) => useActivitiesSubscription(loanId, false);
const useUserActivitiesSubscription = () => useActivitiesSubscription('', true);

export {
  useLoanActivitiesSubscription,
  useUserActivitiesSubscription,
};
