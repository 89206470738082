import { useEffect } from 'react';

import { Loader } from '@/Components/Atoms/Loader';
import { Logo } from '@/Components/Atoms/Logo';
import { Tile } from '@/Components/Atoms/Tile';
import { Typography } from '@/Components/Atoms/Typography';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { useNavigate } from '@/Routes/NavigationContext';

const Login = () => {
  const navigate = useNavigate();
  const { authenticated, login } = useAuth();

  useEffect(() => {
    if (authenticated) {
      navigate('/home');
    }
  }, [authenticated, navigate]);

  const handleLoginClick = () => {
    let returnTo = '';
    if (!window.location.pathname.includes('login')) {
      returnTo = window.location.pathname + window.location.search;
    }
    login(`${window.location.protocol}//${window.location.host}/mortgage/login`, { returnTo });
  };

  return (
    <div className="flex flex-row h-full w-full">
      <div className="bg-secondary-on-surface-states-resting justify-center items-center flex-1 hidden tablet:flex !text-secondary">
        <Logo className="!text-secondary mx-8" size="lg" variant="transparent" />
      </div>
      <div className="flex-1">
        <div className="p-8 h-full">
          {authenticated ? (
            <Loader />
          ) : (
            <div className="flex justify-center h-full flex-col max-w-[508px] mx-auto">
              <Logo className="tablet:hidden" />
              <Typography className="tablet:pb-16 py-16" variant="display4">
                Log in to your account
              </Typography>
              <Tile
                displayOnly
                label="LOG IN"
                onClick={handleLoginClick}
                thumbnail=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Login as default };
