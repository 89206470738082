import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import type { ProtoDecimal } from '@/API/Models/Wilqo.Shared.Models/CustomWrappers_pb';
import type {
  CardListItem,
  FeesComparisonDetail,
  FeesComparisonFee,
  FeesComparisonSection } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { FeeToleranceCategoryType } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { GetFeesComparisonsQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useGetFeesComparison } from '@/API/Queries/mortgage/useGetFeesComparison';
import { useUpdateFeesComparison } from '@/API/Queries/mortgage/useUpdateFeesComparison';
import { Button } from '@/Components/Atoms/Button';
import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Typography } from '@/Components/Atoms/Typography';
import { useFeesContext } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import { cleanAndConvertCurrency, convertProtoToCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import {
  ConvertNumberToProtoDecimal,
  ConvertProtoDecimalAsObjectToNumber,
} from '@/Utils/Helpers/protoDecimalConversion';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface IColumns {
  name: string;
  id: string;
  index?: number;
}

interface TableContentProps {
  feeList: FeesComparisonFee.AsObject[];
  columns: Array<IColumns>;
  sectionType: number | undefined;
  total: FeesComparisonDetail.AsObject | undefined;
  editMode: boolean;
  showInputs: boolean;
}

const defaultColumns: Array<IColumns> = [
  { id: 'feeName', name: 'FEE NAME' },
  { id: 'paidTo', name: 'PAID TO' },
  { id: 'current', name: 'CURRENT' },
  { id: 'lastDisclosed', name: 'LAST DISCLOSED' },
  { id: 'difference', name: 'DIFFERENCE' },
  { id: 'appliedCure', name: 'APPLIED CURE' },
];

const CAPTION_NAME = ['Total Change', 'Required Cure', 'Additional Cure Required?'];

interface ITotalRequiredAndLenderCure {
  isItEnough: boolean;
  cardListList: Array<CardListItem.AsObject>;
}

const ComparisonDetails = (): JSX.Element => {
  const { data, isLoading } = useGetFeesComparison();
  const { loanId = '' } = useParams();
  const { mutate } = useUpdateFeesComparison();
  const { showSnackBar } = useShared();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState<FeesComparisonSection.AsObject>();
  const [editTotalRequired, setEditTotalRequired] = useState<boolean>(false);
  const [sectionIndex, setSectionIndex] = useState<number>();
  const [feesData, setFeesData] = useState<GetFeesComparisonsQueryResponse.AsObject>();
  const [totalAndLender, setTotalAndLender] = useState<ITotalRequiredAndLenderCure>();
  const { feePermissions } = useFeesContext();

  const handleDialog = () => {
    setEditTotalRequired(false);
    setCurrentSection(undefined);
    setIsDialogOpen(!isDialogOpen);
    setSectionIndex(undefined);
  };

  const calculateTotalRequiredCure = (sections: FeesComparisonSection.AsObject[] | undefined) => {
    if (sections && totalAndLender) {
      const total = sections.slice(0, 3).reduce((acc, curr) => acc + Number(ConvertProtoDecimalAsObjectToNumber(curr.requiredCure)), 0);
      const newState = totalAndLender;
      newState.cardListList[0].value = numberFormatter.format(total);
      setTotalAndLender({
        ...newState,
      });
    }
  };

  useEffect(() => {
    if (!data) return;
    setFeesData(data);
    setTotalAndLender(data.totalRequiredAndLenderCure);
  }, [data]);

  useEffect(() => {
    if (feesData?.feesComparisonSectionsList) {
      calculateTotalRequiredCure(feesData.feesComparisonSectionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesData?.feesComparisonSectionsList]);

  const handleCurrentSection = (section: FeesComparisonSection.AsObject, index: number) => {
    handleDialog();
    setCurrentSection(cloneDeep(section));
    setSectionIndex(index);
  };

  // returns columns based on fee tolerance category type
  const columnsBasedOnFeeTolerance = (sectionType: FeeToleranceCategoryType | number | undefined) => {
    const tenPercentSectionColumns: Array<IColumns> = [
      { id: 'feeName', name: 'LE SECTION/CD SECTION: NAME' },
      { id: 'paidTo', name: 'PAID TO' },
      { id: 'current', name: 'CURRENT' },
      { id: 'lastDisclosed', name: 'LAST DISCLOSED' },
      { id: 'difference', name: 'DIFFERENCE' }];

    const cantIncreaseSectionColumns: Array<IColumns> = [
      { id: 'feeName', name: 'FEE NAME' },
      { id: 'paidTo', name: 'PAID TO' },
      { id: 'current', name: 'CURRENT' },
      { id: 'lastDisclosed', name: 'LAST DISCLOSED' },
      { id: 'difference', name: 'DIFFERENCE' }];

    switch (sectionType) {
      case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT:
        return tenPercentSectionColumns;
      case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CAN_CHANGE:
        return cantIncreaseSectionColumns;
      default:
        return defaultColumns;
    }
  };

  const evaluateSectionSign = (sectionType: number) => {
    if (sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_INCREASE) {
      return 'positive';
    }
    if (sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE) {
      return 'negative';
    }
    if (sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CAN_CHANGE) {
      return 'all';
    }
    if (sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT) {
      return 'all';
    }
    return 'positive';
  };

  const getTotal = (
    section: FeesComparisonSection.AsObject,
    filterType: 'all' | 'negative' | 'positive',
  ): number => {
    const total = section.feesList.reduce((acc, fee) => {
      const amount = fee.detail?.difference ? ConvertProtoDecimalAsObjectToNumber(fee.detail.difference) : 0;
      switch (filterType) {
        case 'all':
          return acc + amount;
        case 'positive':
          return acc + (amount > 0 ? amount : 0);
        case 'negative':
          return acc + (amount < 0 ? amount : 0);
        default:
          return acc;
      }
    }, 0);

    const absTotal = Math.abs(total);
    section.requiredCure = ConvertNumberToProtoDecimal(
      section.sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE ? absTotal : total,
    ).toObject();

    return section.sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE ? absTotal : total;
  };

  const addCureCaptions = (captions: Array<{ caption: string; captionStrong: string; edit: boolean }>, section: FeesComparisonSection.AsObject, edit: boolean) => {
    const hasFirstLoanEstimateSentDate = data?.hasFirstLoanEstimateSentDate;
    const requiredCure = numberFormatter.format(getTotal(section, evaluateSectionSign(section.sectionType))) || '--';
    const appliedCure = numberFormatter.format(ConvertProtoDecimalAsObjectToNumber(section.totalCureApplied)) || '--';

    captions.push(
      {
        caption: 'Required Cure',
        captionStrong: hasFirstLoanEstimateSentDate ? requiredCure : '--',
        edit: false,

      },
      {
        caption: 'Applied Cure',
        captionStrong: hasFirstLoanEstimateSentDate ? appliedCure : '--',
        edit,

      },
    );
  };

  const calculateTenPercentTotalDiff = (section: FeesComparisonDetail.AsObject | undefined): number => {
    if (section && ConvertProtoDecimalAsObjectToNumber(section.lastDisclosed) > 0) {
      const result = ((ConvertProtoDecimalAsObjectToNumber(section.difference) / ConvertProtoDecimalAsObjectToNumber(section.lastDisclosed)) * 100).toFixed(2);
      return parseFloat(result) > 0 ? parseFloat(result) : 0;
    }
    return 0;
  };

  const calculateSpecialRequiredCure = (section: FeesComparisonDetail.AsObject | undefined, thresholdPercent: number): number => {
    if (section) {
      const calcObj = {
        difference: ConvertProtoDecimalAsObjectToNumber(section.difference),
        lastDisclosed: ConvertProtoDecimalAsObjectToNumber(section.lastDisclosed),
      };
      const thresholdDecimal = thresholdPercent / 100;
      const totalDifferencePercent = calcObj.difference / calcObj.lastDisclosed;
      const result = calcObj.lastDisclosed * (totalDifferencePercent - thresholdDecimal);
      return result > 0 ? result : 0;
    }
    return 0;
  };

  const addSpecialCureCaptions = (
    captions: Array<{ caption: string; captionStrong: string; edit: boolean; value?: number; onBlurHandler?: (value: string) => void }>,
    section: FeesComparisonSection.AsObject,
    edit: boolean,
  ) => {
    if (!section) return;
    const hasFirstLoanEstimateSentDate = data?.hasFirstLoanEstimateSentDate;
    const totalDifference = calculateTenPercentTotalDiff(section.total as FeesComparisonDetail.AsObject);
    const requiredCure = calculateSpecialRequiredCure(section.total as FeesComparisonDetail.AsObject, 10);
    section.requiredCure = ConvertNumberToProtoDecimal(requiredCure).toObject();
    captions.push(
      {
        caption: 'Total Difference',
        captionStrong: hasFirstLoanEstimateSentDate ? `${String(totalDifference)}%` : '--',
        edit: false,
      },
      {
        caption: 'Required Cure',
        captionStrong: hasFirstLoanEstimateSentDate ? numberFormatter.format(requiredCure) : '--',
        edit: false,
      },
      {
        caption: 'Applied Cure',
        captionStrong: hasFirstLoanEstimateSentDate ? numberFormatter.format(ConvertProtoDecimalAsObjectToNumber(section.totalCureApplied)) : '--',
        edit,
        onBlurHandler: (value: string) => {
          const cleanedValue = value.replace(/[^0-9.-]+/g, '');
          section.totalCureApplied = ConvertNumberToProtoDecimal(Number(cleanedValue)).toObject();
        },
        value: ConvertProtoDecimalAsObjectToNumber(section?.totalCureApplied),
      },
    );
  };

  const handleCaptions = (section: FeesComparisonSection.AsObject | undefined, edit: boolean): Array<{ caption: string; captionStrong: string; edit: boolean }> => {
    const captions: Array<{ caption: string; captionStrong: string; edit: boolean }> = [];
    const hasFirstLoanEstimateSentDate = data?.hasFirstLoanEstimateSentDate;

    if (section) {
      switch (section.sectionType) {
        case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_INCREASE:
        case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE:
          addCureCaptions(captions, section, edit);
          break;
        case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT:
          addSpecialCureCaptions(captions, section, edit);
          break;
        case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CAN_CHANGE:
          captions.push({
            caption: 'Total Change',
            captionStrong: hasFirstLoanEstimateSentDate ? numberFormatter.format(getTotal(section, evaluateSectionSign(section.sectionType))) : '--',
            edit,
          });
          break;
        default:
          break;
      }
    }
    return captions;
  };

  const onBlurHandler = (value: string, fee: FeesComparisonFee.AsObject, feeIndex: number, columnIndex: number) => {
    const newValue = Number(value.replace(/[^0-9.-]+/g, ''));
    const updatedFeesList = currentSection?.feesList;

    if (updatedFeesList) {
      const isTotalCureAppliedColumn = defaultColumns[columnIndex].id === 'appliedCure';
      updatedFeesList[feeIndex] = {
        ...fee,
        detail: {
          ...fee.detail,
          appliedCure: isTotalCureAppliedColumn ? ConvertNumberToProtoDecimal(newValue).toObject() : fee.detail?.appliedCure,
          difference: !isTotalCureAppliedColumn ? ConvertNumberToProtoDecimal(
            Number(ConvertProtoDecimalAsObjectToNumber(fee.detail?.current)) - newValue,
          ).toObject() : fee.detail?.difference,
          lastDisclosed: isTotalCureAppliedColumn ? fee.detail?.lastDisclosed : ConvertNumberToProtoDecimal(newValue).toObject(),
        },
      };
      let totalCurrent = 0;
      let totalLastDisclosed = 0;
      let totalDifference = 0;
      let totalCureApplied = 0;

      updatedFeesList.forEach((f) => {
        totalCurrent += Number(ConvertProtoDecimalAsObjectToNumber(f.detail?.current));
        totalLastDisclosed += Number(ConvertProtoDecimalAsObjectToNumber(f.detail?.lastDisclosed));
        totalDifference += Number(ConvertProtoDecimalAsObjectToNumber(f.detail?.difference));
        if (currentSection?.sectionType !== FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT) {
          totalCureApplied += Number(ConvertProtoDecimalAsObjectToNumber(f.detail?.appliedCure));
        }
      });

      setCurrentSection((prevSection: any) => ({
        ...prevSection,
        feesList: updatedFeesList,
        total: {
          current: ConvertNumberToProtoDecimal(totalCurrent).toObject(),
          difference: ConvertNumberToProtoDecimal(totalDifference).toObject(),
          lastDisclosed: ConvertNumberToProtoDecimal(totalLastDisclosed).toObject(),
        },
        ...currentSection?.sectionType !== FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT && {
          totalCureApplied: ConvertNumberToProtoDecimal(totalCureApplied).toObject(),
        },
      }));
    }
  };

  const updateFeesSection = () => {
    if (loanId && (currentSection || editTotalRequired)) {
      mutate(
        {
          dealId: loanId,
          feesComparisonSection: currentSection,
          totalLenderCure: ConvertNumberToProtoDecimal(Number(totalAndLender?.cardListList[1].value.replace(/[^0-9.-]+/g, ''))).toObject(),
        },
      );
    }
    // update fees section and add it back to the fees data
    if (sectionIndex !== undefined) {
      const feesComparisonSectionsList = feesData?.feesComparisonSectionsList;

      if (feesComparisonSectionsList) {
        const updatedSectionsList = feesComparisonSectionsList.map((section) => {
          if (section.sectionName === currentSection?.sectionName) {
            return currentSection;
          }
          return section;
        });
        if (updatedSectionsList) {
          setFeesData({ ...feesData, feesComparisonSectionsList: updatedSectionsList });
        }
      }
    }
    setIsDialogOpen(!isDialogOpen);
    showSnackBar({ message: 'Fees updated successfully', open: true });
  };

  function isNotProtoDecimal(columnId: string) {
    return columnId === 'feeName' || columnId === 'paidTo';
  }
  const dynamicRowContent = (isProto: boolean, content: ProtoDecimal.AsObject, sectionType: number | undefined, index: number) => {
    if (sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE && index === 4 && ConvertProtoDecimalAsObjectToNumber(content) >= 0) {
      return '--';
    }
    if (sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_INCREASE && index === 4 && ConvertProtoDecimalAsObjectToNumber(content) <= 0) {
      return '--';
    }
    if (isProto) return String(convertProtoToCurrency(content));
    return '';
  };

  const showRedText = (sectionType: number | undefined, isLastColumn: boolean, value: number): boolean => {
    if (!isLastColumn) {
      return false;
    }
    switch (sectionType) {
      case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_INCREASE:
        return value > 0;
      case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CANNOT_DECREASE:
        return value < 0;
      case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT:
      case FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_CAN_CHANGE:
        return Math.abs(value) > 0;
      default:
        return false;
    }
  };

  const editLastDisclosedPermission = feePermissions?.compareEditLastDisclosed;
  const applyToleranceCurePermission = feePermissions?.compareApplyToleranceCure;

  const TableRow = (
    indexColumn: number | undefined,
    fee: FeesComparisonFee.AsObject | undefined,
    id: string,
    editMode: boolean,
    feeIndex: number,
    sectionType: number | undefined,
    isLastColumn: boolean,
    isFirstTwoColumn: boolean,
    showInputs: boolean,
  ) => {
    if (!fee?.detail || indexColumn === undefined) return null;
    const value = fee.detail[id as keyof FeesComparisonDetail.AsObject] as ProtoDecimal.AsObject;
    const currentFee = fee[id as keyof FeesComparisonFee.AsObject] as string;

    if (
      !showInputs
      && (defaultColumns[indexColumn].id === 'lastDisclosed'
      || defaultColumns[indexColumn].id === 'difference'
    || defaultColumns[indexColumn].id === 'appliedCure')
    ) {
      return (
        <td
          key={`${defaultColumns[indexColumn].id}-${fee.feeId}`}
          className={clsx(
            'pr-4 pl-4 py-4 text-left',
            {
              'border-r border-r-on-surface-stroke': indexColumn === 0,
            },
          )}
        >
          <div className={clsx(
            'flex align-center', {
              'justify-end': !isFirstTwoColumn,
            },
          )}
          >
            <Typography variant="body1">
              --
            </Typography>
          </div>
        </td>
      );
    }

    return (
      <td
        key={`${defaultColumns[indexColumn].id}-${fee.feeId}`}
        className={clsx(
          'pr-4 pl-4 py-4 text-left',
          {
            'border-r border-r-on-surface-stroke': indexColumn === 0,
          },
        )}
      >
        <div className={clsx(
          'flex align-center', {
            'justify-end': !isFirstTwoColumn,
          },
        )}
        >
          {((defaultColumns[indexColumn].id === 'lastDisclosed' && editLastDisclosedPermission)
            || (defaultColumns[indexColumn].id === 'appliedCure' && applyToleranceCurePermission)) && editMode
            ? (
              <Input
                disabled={false}
                id={`${defaultColumns[indexColumn].id}-${fee.feeId}`}
                mask={MaskType.CURRENCY}
                onBlur={(e) => onBlurHandler(e.target.value, fee, feeIndex, indexColumn)}
                value={convertProtoToCurrency(value)}
              />
            ) : (
              <Typography
                className={clsx({
                  '!text-danger': !isNotProtoDecimal(defaultColumns[indexColumn].id) && showRedText(sectionType, defaultColumns[indexColumn].id === 'difference', Number(cleanAndConvertCurrency(value))),
                }, 'text-status-on-status')}
                variant={!isNotProtoDecimal(defaultColumns[indexColumn].id) && Math.abs(Number(cleanAndConvertCurrency(value))) > 0 && defaultColumns[indexColumn].id === 'difference' ? 'body1Bold' : 'body1'}
              >
                {isNotProtoDecimal(defaultColumns[indexColumn].id) && currentFee}
                {dynamicRowContent(!isNotProtoDecimal(defaultColumns[indexColumn].id), value, sectionType, indexColumn)}
              </Typography>
            )}
        </div>
      </td>
    );
  };

  const specialRow = (total: FeesComparisonDetail.AsObject | undefined, indexColumn: number | undefined) => {
    let value;
    if (total && indexColumn) {
      switch (indexColumn) {
        case 4:
          value = total.difference;
          break;
        case 2:
          value = total.current;
          break;
        case 3:
          value = total.lastDisclosed;
          break;
        default:
          break;
      }
    }

    value = value !== undefined ? convertProtoToCurrency(value) : '';

    return (
      <td
        key={indexColumn !== undefined ? `${defaultColumns[indexColumn].id}` : undefined}
        className={clsx(
          'pr-4 pl-4 py-4 text-left',
          {
            'border-r border-r-on-surface-stroke': indexColumn !== undefined && defaultColumns[indexColumn].id === 'feeName',
          },
        )}
      >
        <div className={clsx(
          'flex align-center', {
            'justify-end': indexColumn !== 0 && indexColumn !== 1,
          },
        )}
        >
          <Typography
            className={clsx({ '!text-danger': indexColumn === 4 && Number(cleanAndConvertCurrency(value)) > 0 }, '')}
            variant="body1Bold"
          >
            {indexColumn === 0 ? 'Total' : value}
          </Typography>
        </div>
      </td>
    );
  };

  const TableBody = (
    props: TableContentProps,
  ) => (
    <tbody>
      {props.feeList.map((fee: FeesComparisonFee.AsObject | undefined, index: number) => (
        <tr key={`${fee?.feeId}`}>
          {props.columns.map(({ id }: { id: string }, indexColumn: number | undefined) => TableRow(indexColumn, fee, id, props.editMode, index, props.sectionType, indexColumn === props.columns.length - 2, indexColumn === 0 || indexColumn === 1, props.showInputs))}
        </tr>
      ))}
      {props.sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT
        && <tr>{props.columns.map((_, index) => specialRow(props.total, index))}</tr>}
    </tbody>
  );

  const TableHead = (columns: Array<IColumns>) => (
    <thead className="border-b border-b-on-surface-stroke">
      <tr>
        {columns.map((column, index: number) => (
          <th
            key={column.id}
            className={clsx(
              'text-[10px] tracking-[1.5px] font-normal text-on-surface-inactive px-4 py-3 uppercase  text-right ',
              {
                '!border-r-0': index === columns.length - 1,
                '!text-left': defaultColumns[index].id === 'feeName' || defaultColumns[index].id === 'paidTo',
                'border-r border-r-on-surface-stroke': defaultColumns[index].id === 'feeName',
              },
            )}
            scope="col"
          >
            {column.name}
          </th>
        ))}
      </tr>
    </thead>
  );

  const TableContent = (
    props: TableContentProps,
  ) => (
    props.feeList.length !== 0
      ? (
        <table className="w-full">
          {TableHead(props.columns)}
          {TableBody(props)}
        </table>
      )
      : (
        <ContingencyMessage
          subtitle="Sorry, we couldn't find any results for these types of fees"
          title="No results"
          variant="image"
        />
      )
  );

  const handleTotalLenderCureCalc = (value: string) => {
    if (totalAndLender) {
      const newState = totalAndLender;
      newState.cardListList[1].value = String(value);
      setTotalAndLender({
        ...newState,
      });
    }
  };

  const TotalRequiredAndLenderCure = (isEditMode: boolean, totalandlendercure: ITotalRequiredAndLenderCure | undefined) => (
    <div className="flex pr-4 pl-4 py-4">
      {totalandlendercure?.cardListList.map((item, index: number) => (
        <div key={item.caption} className="flex flex-col min-w-[350px]">
          <Typography className="text-status-on-status" variant="body1">
            {item.caption}
          </Typography>
          {isEditMode && index !== 0 ? (
            <div className="mr-7">
              <Input
                disabled={false}
                mask={MaskType.CURRENCY}
                onBlur={(e) => handleTotalLenderCureCalc(e.target.value)}
                value={item.value}
              />
            </div>
          ) : (
            <Typography className="mt-2" variant="body2">
              {data?.hasFirstLoanEstimateSentDate ? item.value : '--'}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );

  const sectionList = () => (
    <>
      {feesData?.feesComparisonSectionsList.map((section, index) => (
        <Card
          key={section.sectionName}
          headerProps={{
            dropdownAction: () => handleCurrentSection(feesData?.feesComparisonSectionsList[index], index),
            multipleCaptions: handleCaptions(section, false),
            showDangerStyle: Math.abs(Number(cleanAndConvertCurrency(section?.requiredCure))) > 0,
            showTitleAction: data?.hasFirstLoanEstimateSentDate && section.feesList.length > 0 && (applyToleranceCurePermission || editLastDisclosedPermission),
            title: section.sectionName,
          }}
          variant="border"
        >
          {TableContent(
            {
              columns: columnsBasedOnFeeTolerance(section.sectionType),
              editMode: false,
              feeList: section.feesList,
              sectionType: section.sectionType,
              showInputs: feesData?.hasFirstLoanEstimateSentDate || false,
              total: section.total,
            },
          )}
        </Card>
      ))}
      <Card
        key="total required and lender cure"
        headerProps={{
          caption: CAPTION_NAME[2],
          captionStrong: totalAndLender?.isItEnough ? 'YES' : 'NO',
          dropdownAction: () => {
            setIsDialogOpen((prev) => !prev);
            setEditTotalRequired(true);
          },
          headerActionStyle: 'default',
          showDangerStyle: false,
          showTitleAction: false,
          title: 'Total Required & Lender Cure',
        }}
        variant="border"
      >
        {TotalRequiredAndLenderCure(false, totalAndLender)}
      </Card>
    </>
  );

  return (
    <div className="grid gap-y-6">
      {!isLoading && sectionList()}
      <Dialog open={isDialogOpen}>
        <DialogLayout>
          <DialogHeader icon="Clear" iconAction={handleDialog} />
          <DialogContent>
            <div className="flex-1 flex flex-col gap-10 p-12 overflow-y-auto">
              <Card
                key={currentSection?.sectionName}
                defaultOpen
                headerProps={{
                  multipleCaptions: handleCaptions(currentSection, currentSection?.sectionType === FeeToleranceCategoryType.FEE_TOLERANCE_CATEGORY_TYPE_TOTAL_CANNOT_INCREASE_MORE_THAN_TEN_PERCENT),
                  showDangerStyle: Math.abs(Number(cleanAndConvertCurrency(currentSection?.requiredCure))) > 0,
                  title: currentSection?.sectionName ?? 'Total Required & Lender Cure',
                }}
                variant="border"
              >
                {editTotalRequired
                  ? TotalRequiredAndLenderCure(false, totalAndLender)
                  : TableContent(
                    {
                      columns: columnsBasedOnFeeTolerance(currentSection?.sectionType),
                      editMode: true,
                      feeList: currentSection?.feesList ?? [],
                      sectionType: currentSection?.sectionType,
                      showInputs: data?.hasFirstLoanEstimateSentDate || false,
                      total: currentSection?.total,
                    },
                  )}
              </Card>
            </div>
          </DialogContent>
          <DialogFooter borderTop>
            <Button
              isLoading={isLoading}
              label="Save"
              onClick={updateFeesSection}
              variant="primary"
            />
          </DialogFooter>
        </DialogLayout>
      </Dialog>
    </div>
  );
};

export default ComparisonDetails;
