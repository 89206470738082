import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { BusinessProcessExtraConfig } from '@/API/Models/Wilqo_API_Activity_Admin_pb';
import { CreateActivityCommandRequest, CreateActivityCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { ActivityType, ProgressType } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.CreateActivityCommandRequest';

interface UpdateObject {
  internalName: string;
  externalName: string;
  progressType?: ProgressType.AsObject;
  allowConcurrence: boolean;
  allowMultipleInstances: boolean;
}

const useCreateActivityTemplate = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const createActivityCommand = async (updateObject: UpdateObject) => {
    const { allowConcurrence, allowMultipleInstances, externalName, internalName, progressType } = updateObject;
    const request = new CreateActivityCommandRequest();
    request.setInternalName(internalName);
    request.setExternalName(externalName);
    request.setActivityType(ActivityType.PANEL_SEQUENCE);
    const bpConfig = new BusinessProcessExtraConfig();
    bpConfig.setAllowConcurrence(allowConcurrence);
    bpConfig.setAllowMultipleInstances(allowMultipleInstances);
    request.setBusinessProcessConfig(bpConfig);
    if (progressType) {
      const requestProgressType = new ProgressType();
      requestProgressType.setName(progressType.name);
      requestProgressType.setConsumerDescription(progressType.consumerDescription);
      requestProgressType.setLenderDescription(progressType.lenderDescription);
      requestProgressType.setScreenOrder(progressType.screenOrder);
      requestProgressType.setIdentifier(progressType.identifier);
      request.setProgressType(requestProgressType);
    }
    request.setBusinessProcessDomainId(bpdId);

    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new CreateActivityCommandResponse();
    CreateActivityCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, { id: obj.adminPanelSequenceDesignId });
  };

  return useMutation(createActivityCommand);
};

export { useCreateActivityTemplate };
