import clsx from 'clsx';
import { useState } from 'react';

import { AnimatedContainer } from './AnimatedContainer';
import { Logo } from './Logo';

export interface ICoverImageProps {
  coverImage: string;
  logoRedirect?: string;
  logoExternal?: boolean;
}

const CoverImage = (props: ICoverImageProps) => {
  const {
    coverImage,
    logoExternal = false,
    logoRedirect = '/',
  } = props;

  const [coverType, setCoverType] = useState < 'image' | 'logo' | 'none'>('none');

  const handleOnError = () => {
    setCoverType('logo');
  };

  if (coverType === 'logo') {
    return (
      <div className="h-full bg-secondary-on-surface-states-resting justify-center items-center hidden tablet:flex !text-secondary">
        <Logo className="!text-secondary" size="lg" variant="transparent" />
      </div>
    );
  }

  const isContain = coverImage.includes('contain');

  return (
    <AnimatedContainer>
      <div className="items-center flex bg-surface w-full min-h-[72px] py-0 px-6 sticky top-0">
        <Logo external={logoExternal} logoRedirect={logoRedirect} />
      </div>
      <div className="relative flex justify-center overflow-hidden w-full h-full">
        <img
          alt="cover"
          className={clsx('absolute h-full w-full object-center', {
            'object-contain': isContain,
            'object-cover aspect-square': !isContain,
          })}
          id="image"
          onError={handleOnError}
          src={coverImage}
        />
      </div>
    </AnimatedContainer>
  );
};

export { CoverImage };
