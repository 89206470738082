// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Header.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb = require('../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb);
var Wilqo_Shared_Models_UIComponents_InfoCard_pb = require('../../Wilqo.Shared.Models/UIComponents/InfoCard_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_InfoCard_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.DynamicTitle', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.displayName = 'proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.DynamicTitle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.displayName = 'proto.wilqo.api.mortgage_dynamic_data.DynamicTitle';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    infoCardsList: jspb.Message.toObjectList(msg.getInfoCardsList(),
    Wilqo_Shared_Models_UIComponents_InfoCard_pb.InfoCard.toObject, includeInstance),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.toObject, includeInstance),
    navigationChipTitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dynamicTitle: (f = msg.getDynamicTitle()) && proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader;
  return proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_UIComponents_InfoCard_pb.InfoCard;
      reader.readMessage(value,Wilqo_Shared_Models_UIComponents_InfoCard_pb.InfoCard.deserializeBinaryFromReader);
      msg.addInfoCards(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNavigationChipTitle(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.DynamicTitle;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.deserializeBinaryFromReader);
      msg.setDynamicTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInfoCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_UIComponents_InfoCard_pb.InfoCard.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.serializeBinaryToWriter
    );
  }
  f = message.getNavigationChipTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDynamicTitle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string help_text = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated wilqo.shared.models.InfoCard info_cards = 2;
 * @return {!Array<!proto.wilqo.shared.models.InfoCard>}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.getInfoCardsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.InfoCard>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_UIComponents_InfoCard_pb.InfoCard, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.InfoCard>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.setInfoCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.InfoCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.InfoCard}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.addInfoCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.InfoCard, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.clearInfoCardsList = function() {
  return this.setInfoCardsList([]);
};


/**
 * repeated LoanPageAction actions = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional string navigation_chip_title = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.getNavigationChipTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.setNavigationChipTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional DynamicTitle dynamic_title = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.DynamicTitle}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.getDynamicTitle = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.DynamicTitle, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.DynamicTitle|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.setDynamicTitle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.clearDynamicTitle = function() {
  return this.setDynamicTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageHeader.prototype.hasDynamicTitle = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance),
    itemsDelimiter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    staticTitleAndItemsDelimiter: jspb.Message.getFieldWithDefault(msg, 3, ""),
    prefixStaticTitle: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    defaultUserName: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.DynamicTitle;
  return proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemsDelimiter(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStaticTitleAndItemsDelimiter(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrefixStaticTitle(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultUserName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getItemsDelimiter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStaticTitleAndItemsDelimiter();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPrefixStaticTitle();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDefaultUserName();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * repeated wilqo.shared.models.RenderableValue items = 1;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional string items_delimiter = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.getItemsDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.setItemsDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string static_title_and_items_delimiter = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.getStaticTitleAndItemsDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.setStaticTitleAndItemsDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool prefix_static_title = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.getPrefixStaticTitle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.setPrefixStaticTitle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool default_user_name = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.getDefaultUserName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.DynamicTitle} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.DynamicTitle.prototype.setDefaultUserName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
