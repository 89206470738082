import { debounce, isEqual } from 'lodash';
import { createContext, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import useEvent from 'react-use-event-hook';

import type { FieldAttachment, FormCircuitComponentField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb';
import type { FieldGroupingSettings, HeaderAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import {
  type DynamicFieldBehavior,
  type ShowFieldValidation,
  ShowFieldValidationActionType,
} from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { LoanPage } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb';
import type { FormField } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { PanelElement, PanelElementValidation } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import type { AsyncValidation } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { useAsyncValidation } from '@/API/Queries/activities/useAsyncValidation';
import { watchUpdateField } from '@/Components/Features/dynamicForm/watchAndUpdateFields';
import type { SaveConfig } from '@/Components/Widgets/content/useSaveWidget';
import type { BorrowerChip } from '@/Components/Widgets/popovers/ExpressAddBorrower';
import { ddeAreEqual, DynamicDataElementMap, toList } from '@/Utils/Helpers/dynamicDataElementHelper';

import type { FieldType } from './conditionalValidation';
import { isConditionValid } from './conditionalValidation';
import type { ChildrenField } from './useAsyncOptions';
import { useAsyncOptions } from './useAsyncOptions';
import { useConditionalFieldOverride } from './useConditionalFieldOverride';
import { useConditionalFields } from './useConditionalFields';
import type { ExtendedConverter } from './useDynamicFormRegister';

export type FieldConfig = Omit<FormCircuitComponentField.AsObject, 'attach' | 'required' | 'staticValue'>
& Omit<FormField.AsObject, 'consolidatedItemScopeToken' | 'hasChild' | 'hyperLinksList' | 'readOnly'> & {
  attach?: FieldAttachment.AsObject;
  hasChild?: boolean;
  required?: boolean;
  staticValue?: string;
  consolidatedItemScopeToken?: string;
  readOnly?: boolean;
};

export interface DynamicDataElementValue {
  dynamic?: DynamicDataElement | any;
  plainValue?: string;
  value?: any;
  fieldConfig?: FieldConfig;
}

export type ConditionalAsyncFieldOverride = Record<string, PanelElement.AsObject>;

export type ConditionalFieldOverride = Record<string, FieldOverrides | undefined>;
export interface FieldOverrides {
  optionsFilter?: DynamicDataElement.AsObject[];
  validators?: PanelElementValidation.AsObject[];
  value?: DynamicDataElement.AsObject;
  disabled?: boolean;
}

export type DynamicDataElementValues = Record<string, DynamicDataElementValue>;

export interface FormError {
  errorMessage?: string;
  type: string;
}

export interface AsyncError {
  type: number;
  data?: any;
}

interface RegisterReturn<T> {
  onChange: (value: T, path: string, plainValue: any, submit: boolean, resetOnTracker?: boolean, isInitital?: boolean) => void;
  unregister: () => void;
}

export interface FormProgress {
  values: DynamicDataElementValues;
  path?: string;
}

export interface ImperativeDynamicForm {
  imperativeSubmit: (callback?: any, saveConfigStr?: string) => void;
  valuesHasChanged: boolean;
}

export type ImperativeSubmitType = (callback?: any, saveConfigStr?: string, submitterId?: string) => void;

export interface DynamicFormContextState {
  onChange: (v: [id: string, value: DynamicDataElementValue, path?: string]) => void;
  values: DynamicDataElementValues;
  valuesHasChanged: boolean;
  hasError: boolean;
  fieldHasChanged: (fieldId: string) => boolean;
  setErrors: (id: string, error: FormError) => void;
  errors?: Record<string, FormError>;
  register: <T>(id: string, c?: ExtendedConverter<T>) => RegisterReturn<T>;
  asyncErrors?: AsyncError[];
  imperativeSubmit: ImperativeSubmitType;
  resetProgress: (values: FormProgress, overridePrevious?: boolean) => void;
  hiddenFieldIds: Array<string>;
  hiddenWidgetIds: Array<string>;
  disabledFieldIds: Array<string>;
  setConditionalFields: (newCondition: ShowFieldValidation.AsObject[]) => void;
  overrideFields: ConditionalFieldOverride;
  asyncOverrideFields: ConditionalAsyncFieldOverride;
  conditionalAsyncSubmitConfig?: DynamicFieldBehavior.AsObject[];
  hiddenHeaderActions?: HeaderAction.AsObject[];
  conditionalFields: ShowFieldValidation.AsObject[];
  isConditionalTargetSaved: (sourceId: string, type: FieldType) => boolean;
  setFieldGrouping: React.Dispatch<React.SetStateAction<FieldGroupingSettings.AsObject | undefined>>;
  fieldGrouping: FieldGroupingSettings.AsObject | undefined;
  clearForm: (preserveDefaultValues: boolean) => void;
}

const INITIAL_STATE: DynamicFormContextState = {
  asyncOverrideFields: {},
  clearForm: () => { },
  conditionalAsyncSubmitConfig: [],
  conditionalFields: [],
  disabledFieldIds: [],
  errors: undefined,
  fieldGrouping: undefined,
  fieldHasChanged: () => false,
  hasError: false,
  hiddenFieldIds: [],
  hiddenWidgetIds: [],
  imperativeSubmit: () => { },
  isConditionalTargetSaved: () => false,
  onChange: () => { },
  overrideFields: {},
  register: () => ({ onChange: () => null, unregister: () => null }),
  resetProgress: () => { },
  setConditionalFields: () => { },
  setErrors: () => { },
  setFieldGrouping: () => { },
  values: {},
  valuesHasChanged: false,
};

const DynamicFormContext = createContext(INITIAL_STATE);
DynamicFormContext.displayName = 'DynamicFormContext';

type FunctionalChildren = (params: DynamicFormContextState) => React.ReactNode;

export interface AdditionalFormInfo {
  initialValue: DynamicDataElementValues;
}

type DynamicFormProps = {
  children: FunctionalChildren | React.ReactNode;
  progress?: FormProgress;
  className?: string;
  submitOnChange?: boolean;
  asyncValidations?: AsyncValidation.AsObject[];
  shouldUnregister?: boolean;
  borrowerContext?: BorrowerChip;
  page?: LoanPage.AsObject;
  scopeToken?: string;
  dealId?: string;
  fieldGrouping?: FieldGroupingSettings.AsObject;
};

export interface SubmitValues {
  path: string;
  callback?: VoidFunction;
  event?: any;
  additionalFormInfo?: AdditionalFormInfo;
  hiddenFieldIds?: string[];
  hiddenWidgetIds?: string[];
}

type DynamicFormProviderProps = DynamicFormProps & ({
  asDiv?: boolean;
  onSubmit?: (value: DynamicDataElementValues, extra: SubmitValues) => void;
});

const DynamicFormProvider = forwardRef((props: DynamicFormProviderProps, ref: any) => {
  const {
    asDiv = false,
    asyncValidations,
    children,
    className,
    onSubmit = () => null,
    progress,
    shouldUnregister = false,
    submitOnChange = false,
    borrowerContext,
    page,
    fieldGrouping: fieldGroupingConfig,
  } = props as DynamicFormProviderProps & { asDiv?: boolean };

  const [formState, setFormState] = useState<DynamicDataElementValues>({});
  const [formStateTracker, setFormStateTracker] = useState<DynamicDataElementValues>({});
  const [errors, setErrors] = useState<Record<string, FormError>>({});
  const [asyncErrors, setAsyncErrors] = useState<AsyncError[]>([]);
  const [conditionalFields, setConditionalFields] = useState<Array<ShowFieldValidation.AsObject>>(page?.showFieldValidationsList || []);
  const [asyncOverrideFields, setAsyncOverrideFields] = useState<ConditionalAsyncFieldOverride>({});
  const [configOverideFields, setConfigOverrideFields] = useState<ConditionalFieldOverride>({});
  const [fieldGrouping, setFieldGrouping] = useState<FieldGroupingSettings.AsObject | undefined>(fieldGroupingConfig);

  const registeredConverters = useRef<Record<string, ExtendedConverter>>({});
  const pathRef = useRef('');

  const handleSetAsyncError = useCallback((asyncError: AsyncError) => setAsyncErrors(
    [...asyncErrors, asyncError],
  ), [asyncErrors]);

  const handleClearAsyncError = useCallback((type: number) => setAsyncErrors(
    asyncErrors.filter((error) => error.type !== type),
  ), [asyncErrors]);
  useAsyncValidation(formState, errors, handleSetAsyncError, handleClearAsyncError, asyncValidations);
  const findAsyncOptions = useAsyncOptions(page?.id || '', props.scopeToken || '', props.dealId);
  const findConfigOptions = useConditionalFieldOverride(props.page?.panelElementConditionalOverridesList);

  const getValues = useCallback((formState: DynamicDataElementValues, id: string, item?: DynamicDataElementValue, converter?: ExtendedConverter) => {
    let dynamic: DynamicDataElement | undefined;
    let value: any;
    let plainValue: string | undefined;
    let fieldConfig: FieldConfig | undefined;
    if (item) {
      dynamic = item.dynamic;
      value = item.value;
      plainValue = item.plainValue;
      fieldConfig = item.fieldConfig;
    } else if (formState[id]) {
      dynamic = formState[id].dynamic;
      value = formState[id].value;
      plainValue = formState[id].plainValue;
      fieldConfig = formState[id].fieldConfig;
    }
    if (!dynamic || !value) {
      const itemConverter = converter || registeredConverters.current[id];
      if (itemConverter) {
        if (value && !dynamic && itemConverter.toDynamic) {
          dynamic = itemConverter.toDynamic(value);
        }

        if (dynamic && !value && itemConverter.fromDynamic) {
          value = itemConverter.fromDynamic(dynamic);
        }
      }
      return { dynamic, fieldConfig, plainValue, value };
    }
    return { ...item };
  }, []);

  const handleUpdateState = useCallback(async (newState: DynamicDataElementValues, overrideFormState?: boolean, overrideTracker?: boolean) => {
    const updatedIds = Object.keys(newState);
    let childrenValues: DynamicDataElementValues = {};
    setFormState((prev) => {
      if (page?.fieldFilters) {
        // if field is getting updated
        const { dependencyInfoList, parentFieldsList } = page?.fieldFilters;
        const updatedParentIds = parentFieldsList
          .filter((parent) => updatedIds.includes(parent.fieldId))
          .map((parent) => parent.fieldId);
        const childrenFields: ChildrenField[] = dependencyInfoList.reduce((childrenList, child) => {
          const hasUpdatedParents = child.parentFieldIdsList.some((parentId) => updatedParentIds.includes(parentId));
          if (hasUpdatedParents) {
            return [...childrenList, {
              ...child,
              parentFieldIdsList: child.parentFieldIdsList.map((parentId) => ({
                fieldId: parentId,
                fieldValue: (updatedIds.includes(parentId) ? newState : prev)[parentId]?.dynamic?.toObject(),
              })),
            }];
          }
          return childrenList;
        }, [] as any);

        // reset all children values
        childrenValues = childrenFields.reduce((pre: DynamicDataElementValues, current) => (
          {
            ...pre,
            [current.childFieldId]: {
              dynamic: undefined,
              fieldConfig: newState[current.childFieldId]?.fieldConfig ?? prev[current.childFieldId]?.fieldConfig,
              plainValue: undefined,
              value: undefined,
            },
          }
        ), {});

        if (childrenFields.length) {
          const promises = childrenFields.map((child) => findAsyncOptions(child.childFieldId, child.widgetId, child.parentFieldIdsList));

          Promise.all(promises).then((response) => {
            const newOverride = response.reduce((prev: ConditionalAsyncFieldOverride, current) => {
              if (current) {
                const withValues = {
                  ...prev,
                  [current.fieldId]: {
                    ...current.panelElement,
                    value: current.defaultFieldValue,
                  } as PanelElement.AsObject,
                };
                return {
                  ...prev,
                  ...withValues,
                };
              }
              return prev;
            }, {});
            setAsyncOverrideFields((prev) => ({ ...prev, ...newOverride }));
          });
        }
      }

      return overrideFormState ? { ...newState, ...childrenValues } : { ...prev, ...newState, ...childrenValues };
    });

    if (overrideTracker) setFormStateTracker((prev) => ({ ...prev, ...newState }));
    if (page?.panelElementConditionalOverridesList.some((item) => updatedIds.includes(item.sourcePanelElement?.panelElementId || ''))) {
      const fields = findConfigOptions(newState);
      setConfigOverrideFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?.fieldFilters, page?.panelElementConditionalOverridesList, findAsyncOptions, getValues]);

  const defaultBorrowerContextValue = useCallback((values: DynamicDataElementValues) => {
    if (!borrowerContext) return values;

    values = Object.keys(values).reduce((progress, fieldId) => {
      const panelElement = values[fieldId].fieldConfig?.panelElement;
      const selectedBorrowerChip = panelElement?.optionsList
        .find((option) => option.headerText === borrowerContext.label);
      // ? if on URLA and some field is a chip-list of borrowers
      // set the default value to be same as the borrower chip
      // selected on URLA header
      if (panelElement?.type === 'chip-list' && selectedBorrowerChip) {
        const peValue = selectedBorrowerChip.value;
        const dde = toList(peValue ? [DynamicDataElementMap(peValue)] : []);
        return {
          ...progress,
          [fieldId]: { dynamic: dde, fieldConfig: values[fieldId].fieldConfig },
        };
      }

      return { ...progress, [fieldId]: values[fieldId] };
    }, {} as DynamicDataElementValues);

    return values;
  }, [borrowerContext]);

  const clearForm = useCallback((preserveDefaultValues?: boolean) => {
    if (preserveDefaultValues) {
      setFormState(formStateTracker);
    } else {
      setFormState((currentValues) => Object.keys(currentValues).reduce((prev: DynamicDataElementValues, key: string) => {
        const converters = registeredConverters.current[key];
        let newValue: DynamicDataElementValue = { dynamic: undefined, fieldConfig: currentValues[key].fieldConfig, value: undefined };
        if (converters) {
          const nullDDE = DynamicDataElementMap({ dataType: DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL, value: '' });
          newValue = { ...newValue, dynamic: converters.toDynamic(undefined), value: converters.fromDynamic(nullDDE) };
        }

        return { ...prev, [key]: newValue };
      }, {}));
    }
  }, [formStateTracker]);

  const resetProgress = useEvent((progress: FormProgress, overridePrevious = false) => {
    const values = defaultBorrowerContextValue(progress.values);

    if (progress.path) pathRef.current = progress.path;
    if (!overridePrevious) {
      handleUpdateState(Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: getValues(formState, key, values[key]) }), formState), overridePrevious, true);
    } else {
      handleUpdateState(Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: getValues({}, key, values[key]) }), {}), overridePrevious, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const hasUpdateProgress = useRef(false);
  useEffect(() => {
    if (progress && progress.values && !hasUpdateProgress.current) {
      resetProgress(progress);
      hasUpdateProgress.current = true;
    }
  }, [progress, resetProgress, formState]);

  const valuesHasChanged = useMemo(() => {
    let hasChanged = false;

    Object.keys(formState).forEach((fieldKey) => {
      if (!formStateTracker[fieldKey]) {
        hasChanged = false;
      } else {
        const differentFromInitValue = !isEqual(formState[fieldKey]?.value, formStateTracker[fieldKey]?.value);
        const valueIsNotEmptyArray = !(Array.isArray(formState[fieldKey]?.value) && formState[fieldKey]?.value.length === 0);
        const hasNoConsentConfigToSent = !page?.conditionalAsyncSubmitConfigsList?.some((config) => config.toggleConsent
          && (Object.values(config.toggleConsent).includes(fieldKey) || config.panelElementId === fieldKey));

        if (differentFromInitValue && valueIsNotEmptyArray && hasNoConsentConfigToSent) {
          hasChanged = true;
        }
      }
    });

    return hasChanged;
  }, [formState, formStateTracker, page?.conditionalAsyncSubmitConfigsList]);

  const fieldHasChanged = useCallback(
    (fieldId: string) => {
      if (formStateTracker[fieldId]?.dynamic !== undefined && formState[fieldId]?.dynamic !== undefined) {
        return !ddeAreEqual(formStateTracker[fieldId]?.dynamic, formState[fieldId]?.dynamic);
      }
      return formStateTracker[fieldId]?.dynamic !== formState[fieldId]?.dynamic;
    },
    [formState, formStateTracker],
  );

  const hasError = useMemo(() => {
    const errorKeys = Object.keys(errors);
    const hasValidationError = errorKeys.some((errorKey) => Boolean(errors[errorKey].errorMessage));
    const hasAsyncError = asyncErrors.length > 0;
    return hasValidationError || hasAsyncError;
  }, [errors, asyncErrors]);

  const hasErrorByIds = useCallback((ids: string[]) => {
    const hasValidationError = ids.some((errorKey) => Boolean(errors[errorKey]?.errorMessage));
    const hasAsyncError = asyncErrors.length > 0;
    return hasValidationError || hasAsyncError;
  }, [errors, asyncErrors]);

  const { disabledFieldIds, hiddenFieldIds, hiddenHeaderActionIds, hiddenWidgetIds } = useConditionalFields(conditionalFields, formState);

  const isConditionalTargetSaved = useCallback((sourceId: string, type: FieldType) => {
    const foundConditions = conditionalFields.filter((condition) => {
      switch (type) {
        case 'widgetElement':
          return condition.targetElementsList.some((t) => t.widgetsIdsList.includes(sourceId));
        case 'panelElement':
          return condition.targetElementsList.some((t) => t.panelElementsIdsList.includes(sourceId));
        case 'headerAction':
          return condition.targetElementsList.some((t) => t.headerActionsList.some((h) => h.headerActionId === sourceId));
        default:
          return [];
      }
    });

    if (foundConditions.length === 0) return false;

    return foundConditions.some((condition) => isConditionValid(condition, formStateTracker));
  }, [conditionalFields, formStateTracker]);

  const hiddenHeaderActions = useMemo(
    () => conditionalFields
      .filter((condition) => condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN)
      .flatMap((condition) => condition.targetElementsList.flatMap((element) => element.headerActionsList))
      .filter((action) => hiddenHeaderActionIds.includes(action.headerActionId)),
    [conditionalFields, hiddenHeaderActionIds],
  );

  const handleSubmit = useCallback((event?: React.FormEvent<HTMLFormElement>, state = formState, callback?: any, additionalFormInfo?: AdditionalFormInfo) => {
    event?.stopPropagation();
    event?.preventDefault();
    let dynamicPageConfig;
    let valuesChangedInAllowedList;
    let hasErrorOnForm = hasError;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event?.nativeEvent?.submitter?.dataset.additionalinfo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dynamicPageConfig = event?.nativeEvent.submitter.dataset.additionalinfo;
      const saveConfig: SaveConfig = JSON.parse(dynamicPageConfig);
      if (saveConfig.type !== 'editableTable') {
        const fieldsList = (JSON.parse(dynamicPageConfig)?.widgets as LoanPageWidget.AsObject[])?.flatMap((widget) => widget.cardListItem?.fieldsList || widget.form?.fieldsList);
        const fieldIds = fieldsList.map((field) => field?.id ?? '');
        // remove unecessary items that will not be saved
        // eg. if you save a widget separetly it will remove other widget values
        state = Object.keys(state).reduce((submitState: DynamicDataElementValues, fieldId) => {
          if (fieldIds.includes(fieldId) || !ddeAreEqual(state[fieldId].dynamic, formStateTracker[fieldId].dynamic)) {
            return { ...submitState, [fieldId]: state[fieldId] };
          }
          return submitState;
        }, {});
        hasErrorOnForm = hasErrorByIds(fieldIds);
      }
    }
    if (!hasErrorOnForm) {
      const submitState = Object.keys(state).reduce((submitState: DynamicDataElementValues, fieldId) => {
        const isHidden = hiddenFieldIds.includes(fieldId);
        const shouldFilter = page?.showFieldValidationsList.some((validation) => validation.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN_IGNORE_ON_SAVE && validation.targetElementsList.some((target) => target.panelElementsIdsList.includes(fieldId)));
        // if value is present on conditionalAsyncSubmitConfig it will be saved
        // using another endpoint - (so it will be removed from here)
        const removeFromSubmitState = page?.conditionalAsyncSubmitConfigsList?.some((config) => config.toggleConsent
          && (Object.values(config.toggleConsent).includes(fieldId) || config.panelElementId === fieldId));
        if (removeFromSubmitState || (isHidden && shouldFilter)) {
          return submitState;
        }

        // Compare if values have changed and apply value from changeTracking field
        const differentFromInitValue = !isEqual(formState[fieldId]?.value, formStateTracker[fieldId]?.value);
        if (differentFromInitValue) {
          const changedFieldId = formStateTracker[fieldId]?.fieldConfig?.panelElement?.virtualFieldIdentifier?.value;
          valuesChangedInAllowedList = !!watchUpdateField(page?.widgetsList, changedFieldId);
        }
        // any other value return current value
        return { ...submitState, [fieldId]: state[fieldId] };
      }, {});

      if (valuesChangedInAllowedList) {
        const fieldId = page?.widgetsList[0].changeTracking?.actionsList[0].setFieldValue?.fieldId || '';
        const value = page?.widgetsList[0].changeTracking?.actionsList[0].setFieldValue?.value;
        if (value) {
          submitState[fieldId] = getValues(submitState, fieldId, { dynamic: DynamicDataElementMap(value) });
        }
      }

      onSubmit(
        submitState,
        {
          additionalFormInfo,
          callback,
          event,
          hiddenFieldIds,
          hiddenWidgetIds,
          path: dynamicPageConfig || pathRef.current,
        },
      );
    }
  }, [
    formState,
    hasError,
    hasErrorByIds,
    formStateTracker,
    onSubmit,
    hiddenFieldIds,
    page?.showFieldValidationsList,
    page?.conditionalAsyncSubmitConfigsList,
    page?.widgetsList,
    getValues,
    hiddenWidgetIds,
  ]);

  const imperativeSubmit = useCallback((callback: any, saveConfigStr?: string, submitterId?: string) => {
    handleSubmit(
      { nativeEvent: { submitter: { dataset: { additionalinfo: saveConfigStr }, id: submitterId } }, preventDefault: () => { }, stopPropagation: () => { } } as any,
      undefined,
      callback,
      { initialValue: formStateTracker },
    );
  }, [formStateTracker, handleSubmit]);

  useImperativeHandle<any, ImperativeDynamicForm>(ref, () => ({
    imperativeSubmit,
    valuesHasChanged,
  }), [imperativeSubmit, valuesHasChanged]);

  const debouncedSubmit = useRef(debounce(handleSubmit, 500));

  useEffect(() => {
    debouncedSubmit.current = debounce(handleSubmit, 500);
  }, [handleSubmit]);

  const register = useCallback((id: string, converter?: ExtendedConverter) => {
    if (converter && !registeredConverters.current[id]) {
      registeredConverters.current = { ...registeredConverters.current, [id]: converter };
      // if there's any value already, convert all values
      const currentFieldValue = formState[id];
      if (currentFieldValue && ((!currentFieldValue.dynamic && currentFieldValue.value)
        || (!currentFieldValue.value && currentFieldValue.dynamic))
      ) {
        const fieldValue = getValues(formState, id, formState[id], converter);
        handleUpdateState({ [id]: fieldValue });
        setFormStateTracker((formStateTracker) => ({ ...formStateTracker, [id]: fieldValue }));
      }
    }

    const onChange = (value: any, plainValue: string, path: string, submit = false, resetOnTracker = false, isInitial = false) => {
      if ((isInitial && formState[id]?.dynamic === undefined) || !isInitial) {
        let dynamic: DynamicDataElement | undefined;
        const fieldConfig = formState[id]?.fieldConfig;
        if (converter) {
          dynamic = converter.toDynamic(value);
        }
        pathRef.current = path;
        handleUpdateState({ [id]: { dynamic, fieldConfig, plainValue, value } });
        if (resetOnTracker) {
          setFormStateTracker((formStateTracker) => ({ ...formStateTracker, [id]: { dynamic, fieldConfig, plainValue, value } }));
        }
        if (submit || submitOnChange) {
          const newState = { ...formState, [id]: { dynamic, fieldConfig, plainValue, value } };
          debouncedSubmit.current(undefined, newState);
        }
      }
    };

    const unregister = () => {
      if (shouldUnregister) {
        setFormState((formState) => {
          const newFormState = { ...formState };
          delete newFormState[id];
          if (submitOnChange) {
            debouncedSubmit.current(undefined, newFormState);
          }
          return newFormState;
        });
        const newConverter = { ...registeredConverters.current };
        delete newConverter[id];
        registeredConverters.current = newConverter;
      }
      setErrors((errors) => {
        const newErrorState = { ...errors };
        delete newErrorState[id];
        return newErrorState;
      });
    };

    return { onChange, unregister };
  }, [formState, submitOnChange, shouldUnregister, getValues, handleUpdateState]);

  const handleFormOnChange = useCallback((v: [id: string, value: DynamicDataElementValue, path?: string]) => {
    const [id, value, path] = v;
    if (path) pathRef.current = path;
    handleUpdateState({ [id]: value });
  }, [handleUpdateState]);

  const handleSetError = useCallback((id: string, error: FormError) => {
    setErrors((errors) => ({ ...errors, [id]: error }));
  }, []);

  const handleSetConditional = useCallback((newConditions: ShowFieldValidation.AsObject[]) => {
    setConditionalFields((state) => state.concat(newConditions));
  }, []);

  useEffect(() => () => {
    setFormState({});
    setFormStateTracker({});
    setErrors({});
    pathRef.current = '';
    registeredConverters.current = {};
    hasUpdateProgress.current = false;
  }, []);

  useEffect(() => {
    if (page?.showFieldValidationsList && page?.showFieldValidationsList.length > 0) {
      setConditionalFields(page?.showFieldValidationsList);
    }
  }, [page?.showFieldValidationsList]);

  const value: DynamicFormContextState = {
    asyncErrors,
    asyncOverrideFields,
    clearForm,
    conditionalAsyncSubmitConfig: page?.conditionalAsyncSubmitConfigsList,
    conditionalFields,
    disabledFieldIds,
    errors,
    fieldGrouping,
    fieldHasChanged,
    hasError,
    hiddenFieldIds,
    hiddenHeaderActions,
    hiddenWidgetIds,
    imperativeSubmit,
    isConditionalTargetSaved,
    onChange: handleFormOnChange,
    overrideFields: configOverideFields,
    register,
    resetProgress,
    setConditionalFields: handleSetConditional,
    setErrors: handleSetError,
    setFieldGrouping,
    values: formState,
    valuesHasChanged,
  };

  return (
    <DynamicFormContext.Provider value={value}>
      {!asDiv ? (
        <form ref={ref} autoComplete="off" className={className} id="form-1" onSubmit={handleSubmit}>
          {typeof children === 'function' ? children(value) : children}
          <button hidden id="submit-form" type="submit">submit</button>
        </form>
      ) : (
        <div ref={ref} className={className} id="div-form-1">
          {typeof children === 'function' ? children(value) : children}
        </div>
      )}
    </DynamicFormContext.Provider>
  );
});

const useDynamicForm = () => useContext(DynamicFormContext);
export { DynamicFormProvider, useDynamicForm };
