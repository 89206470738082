import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { AddPanelElementCommandRequest, PanelSequenceDesignCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.AddPanelElementCommandRequest';

interface UpdateObject {
  panelId: string;
  index: number;
  onNewPage?: boolean;
  panelElementTemplateId: string;
}

const useAddDesignerPanelElement = () => {
  const { commandResponse } = useBackend();
  const {
    designerPanel: { entityId, panelSequenceDesignId },
  } = useActivityConfiguratorContext();
  const bpdId = useBPDId();

  const AddDesignerPanelElementCommand = async (updateObject: UpdateObject) => {
    const { index, onNewPage = false, panelElementTemplateId, panelId } = updateObject;
    const request = new AddPanelElementCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setPanelSequenceDesignId(panelSequenceDesignId);
    request.setActivityDesignId(entityId);
    request.setPanelId(panelId);
    request.setAddIndex(index);
    request.setOnNewPage(onNewPage);
    request.setElementTemplateReference(panelElementTemplateId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new PanelSequenceDesignCommandResponse();
    PanelSequenceDesignCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(AddDesignerPanelElementCommand);
};

export { useAddDesignerPanelElement };
