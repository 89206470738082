// source: Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_CustomWrappers_pb = require('../Wilqo.Shared.Models/CustomWrappers_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_CustomWrappers_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_WilqoPartyType_pb = require('../Wilqo.Shared.Models/WilqoPartyType_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_WilqoPartyType_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb);
var Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb = require('../Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Admin_pb.js');
goog.object.extend(proto, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb);
var Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb = require('../Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb.js');
goog.object.extend(proto, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb);
var Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb = require('../Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb.js');
goog.object.extend(proto, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.InitializeDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.InitializeDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.RebuildDataCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.RebuildDataCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.InitializeDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.InitializeDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.InitializeDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.InitializeDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.RebuildDataCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.RebuildDataCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.RebuildDataCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.RebuildDataCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.displayName = 'proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.displayName = 'proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    includeSeedDataJson: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeProgressItemTemplates: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    includeArchivedLoanFeeTemplates: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.InitializeDataCommandRequest;
  return proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeSeedDataJson(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeProgressItemTemplates(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeArchivedLoanFeeTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIncludeSeedDataJson();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeProgressItemTemplates();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIncludeArchivedLoanFeeTemplates();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool include_seed_data_json = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.getIncludeSeedDataJson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.setIncludeSeedDataJson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_progress_item_templates = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.getIncludeProgressItemTemplates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.setIncludeProgressItemTemplates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool include_archived_loan_fee_templates = 5;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.getIncludeArchivedLoanFeeTemplates = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandRequest.prototype.setIncludeArchivedLoanFeeTemplates = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.InitializeDataCommandResponse;
  return proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.InitializeDataCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.InitializeDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.RebuildDataCommandRequest;
  return proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDataTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType = {
  DATA_TYPE_UNKNOWN: 0,
  DATA_TYPE_LOAN_FEE_TEMPLATES: 1
};

/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DataType data_types = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType>}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.getDataTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType>} value
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.setDataTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.DataType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.addDataTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandRequest.prototype.clearDataTypesList = function() {
  return this.setDataTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.RebuildDataCommandResponse;
  return proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.RebuildDataCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.RebuildDataCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.repeatedFields_ = [31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    feeEstimatedTotalAmount: (f = msg.getFeeEstimatedTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaidToType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    feeProcessType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    feeSpecifiedHud1LineNumberValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    feeSpecifiedLineNumberValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gfeAggregationType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    integratedDisclosureLineNumberValue: jspb.Message.getFieldWithDefault(msg, 15, ""),
    integratedDisclosureSectionType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    integratedDisclosureSubsectionType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    optionalCostIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    regulationZPointsAndFeesIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    requiredProviderOfServiceIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    sectionClassificationType: jspb.Message.getFieldWithDefault(msg, 21, 0),
    defaultFeeIncludedInAprIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    defaultFeePaidToType: jspb.Message.getFieldWithDefault(msg, 25, 0),
    defaultFeePaidToPartyType: jspb.Message.getFieldWithDefault(msg, 26, 0),
    defaultFeePaymentPaidByTypeThirdPartyName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    defaultFeePaymentPaidByType: jspb.Message.getFieldWithDefault(msg, 28, 0),
    defaultFeePaymentTimingType: jspb.Message.getFieldWithDefault(msg, 29, 0),
    hud1SectionClassificationType: jspb.Message.getFieldWithDefault(msg, 30, 0),
    feeParametersList: jspb.Message.toObjectList(msg.getFeeParametersList(),
    Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.toObject, includeInstance),
    defaultFeePaidToPartyRoleMapId: jspb.Message.getFieldWithDefault(msg, 32, ""),
    defaultFeeToleranceCategoryType: jspb.Message.getFieldWithDefault(msg, 34, 0),
    loanFeeTemplateId: jspb.Message.getFieldWithDefault(msg, 35, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest;
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeEstimatedTotalAmount(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeePaidToEnum} */ (reader.readEnum());
      msg.setFeePaidToType(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeProcessEnum} */ (reader.readEnum());
      msg.setFeeProcessType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSpecifiedHud1LineNumberValue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSpecifiedLineNumberValue(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (reader.readEnum());
      msg.setGfeAggregationType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegratedDisclosureLineNumberValue(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSectionType(value);
      break;
    case 17:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSubsectionType(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptionalCostIndicator(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegulationZPointsAndFeesIndicator(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredProviderOfServiceIndicator(value);
      break;
    case 21:
      var value = /** @type {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} */ (reader.readEnum());
      msg.setSectionClassificationType(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultFeeIncludedInAprIndicator(value);
      break;
    case 25:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaidToType(value);
      break;
    case 26:
      var value = /** @type {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaidToPartyType(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFeePaymentPaidByTypeThirdPartyName(value);
      break;
    case 28:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaymentPaidByType(value);
      break;
    case 29:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaymentTimingType(value);
      break;
    case 30:
      var value = /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (reader.readEnum());
      msg.setHud1SectionClassificationType(value);
      break;
    case 31:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.deserializeBinaryFromReader);
      msg.addFeeParameters(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFeePaidToPartyRoleMapId(value);
      break;
    case 34:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} */ (reader.readEnum());
      msg.setDefaultFeeToleranceCategoryType(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanFeeTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFeeEstimatedTotalAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaidToType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getFeeProcessType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getFeeSpecifiedHud1LineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFeeSpecifiedLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getIntegratedDisclosureLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIntegratedDisclosureSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getOptionalCostIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getRegulationZPointsAndFeesIndicator();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getRequiredProviderOfServiceIndicator();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getSectionClassificationType();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getDefaultFeeIncludedInAprIndicator();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getDefaultFeePaidToType();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getDefaultFeePaidToPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getDefaultFeePaymentPaidByTypeThirdPartyName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getDefaultFeePaymentPaidByType();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getDefaultFeePaymentTimingType();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getFeeParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.serializeBinaryToWriter
    );
  }
  f = message.getDefaultFeePaidToPartyRoleMapId();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getDefaultFeeToleranceCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 35));
  if (f != null) {
    writer.writeString(
      35,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FeeEnum type = 4;
 * @return {!proto.wilqo.api.mortgage_config.FeeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_estimated_total_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getFeeEstimatedTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setFeeEstimatedTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.clearFeeEstimatedTotalAmount = function() {
  return this.setFeeEstimatedTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.hasFeeEstimatedTotalAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FeePaidToEnum fee_paid_to_type = 7;
 * @return {!proto.wilqo.api.mortgage_config.FeePaidToEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getFeePaidToType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeePaidToEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeePaidToEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setFeePaidToType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional FeeProcessEnum fee_process_type = 9;
 * @return {!proto.wilqo.api.mortgage_config.FeeProcessEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getFeeProcessType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeProcessEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeProcessEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setFeeProcessType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string fee_specified_hud_1_line_number_value = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getFeeSpecifiedHud1LineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setFeeSpecifiedHud1LineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fee_specified_line_number_value = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getFeeSpecifiedLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setFeeSpecifiedLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional GFEAggregationEnum gfe_aggregation_type = 14;
 * @return {!proto.wilqo.api.mortgage_config.GFEAggregationEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getGfeAggregationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setGfeAggregationType = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.clearGfeAggregationType = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.hasGfeAggregationType = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string integrated_disclosure_line_number_value = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getIntegratedDisclosureLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setIntegratedDisclosureLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional IntegratedDisclosureSectionEnum integrated_disclosure_section_type = 16;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getIntegratedDisclosureSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setIntegratedDisclosureSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional IntegratedDisclosureSubsectionEnum integrated_disclosure_subsection_type = 17;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getIntegratedDisclosureSubsectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setIntegratedDisclosureSubsectionType = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.clearIntegratedDisclosureSubsectionType = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.hasIntegratedDisclosureSubsectionType = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool optional_cost_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getOptionalCostIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setOptionalCostIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool regulation_z_points_and_fees_indicator = 19;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getRegulationZPointsAndFeesIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setRegulationZPointsAndFeesIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool required_provider_of_service_indicator = 20;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getRequiredProviderOfServiceIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setRequiredProviderOfServiceIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional SectionClassificationEnum section_classification_type = 21;
 * @return {!proto.wilqo.api.mortgage_config.SectionClassificationEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getSectionClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setSectionClassificationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional bool default_fee_included_in_apr_indicator = 23;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeeIncludedInAprIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeeIncludedInAprIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional DefaultFeePaidToTypeEnum default_fee_paid_to_type = 25;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeePaidToType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeePaidToType = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional PartyRoleTypeEnum default_fee_paid_to_party_type = 26;
 * @return {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeePaidToPartyType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeePaidToPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional string default_fee_payment_paid_by_type_third_party_name = 27;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeePaymentPaidByTypeThirdPartyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeePaymentPaidByTypeThirdPartyName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional DefaultFeePaymentPaidByTypeEnum default_fee_payment_paid_by_type = 28;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeePaymentPaidByType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeePaymentPaidByType = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional DefaultFeePaymentTimingTypeEnum default_fee_payment_timing_type = 29;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeePaymentTimingType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeePaymentTimingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional Hud1SectionClassificationTypeEnum hud_1_section_classification_type = 30;
 * @return {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getHud1SectionClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setHud1SectionClassificationType = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.clearHud1SectionClassificationType = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.hasHud1SectionClassificationType = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated FeeTemplateRuleGroupParameter fee_parameters = 31;
 * @return {!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getFeeParametersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter, 31));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setFeeParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.addFeeParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.clearFeeParametersList = function() {
  return this.setFeeParametersList([]);
};


/**
 * optional string default_fee_paid_to_party_role_map_id = 32;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeePaidToPartyRoleMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeePaidToPartyRoleMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional FeeToleranceCategoryType default_fee_tolerance_category_type = 34;
 * @return {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getDefaultFeeToleranceCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setDefaultFeeToleranceCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional string loan_fee_template_id = 35;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.getLoanFeeTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.setLoanFeeTemplateId = function(value) {
  return jspb.Message.setField(this, 35, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.clearLoanFeeTemplateId = function() {
  return jspb.Message.setField(this, 35, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest.prototype.hasLoanFeeTemplateId = function() {
  return jspb.Message.getField(this, 35) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse;
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.repeatedFields_ = [31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    feeEstimatedTotalAmount: (f = msg.getFeeEstimatedTotalAmount()) && Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.toObject(includeInstance, f),
    feePaidToType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    feeProcessType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    feeSpecifiedHud1LineNumberValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    feeSpecifiedLineNumberValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gfeAggregationType: jspb.Message.getFieldWithDefault(msg, 14, 0),
    integratedDisclosureLineNumberValue: jspb.Message.getFieldWithDefault(msg, 15, ""),
    integratedDisclosureSectionType: jspb.Message.getFieldWithDefault(msg, 16, 0),
    integratedDisclosureSubsectionType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    optionalCostIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    regulationZPointsAndFeesIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    requiredProviderOfServiceIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    sectionClassificationType: jspb.Message.getFieldWithDefault(msg, 21, 0),
    defaultFeeIncludedInAprIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    defaultFeePaidToType: jspb.Message.getFieldWithDefault(msg, 25, 0),
    defaultFeePaidToPartyType: jspb.Message.getFieldWithDefault(msg, 26, 0),
    defaultFeePaymentPaidByTypeThirdPartyName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    defaultFeePaymentPaidByType: jspb.Message.getFieldWithDefault(msg, 28, 0),
    defaultFeePaymentTimingType: jspb.Message.getFieldWithDefault(msg, 29, 0),
    hud1SectionClassificationType: jspb.Message.getFieldWithDefault(msg, 30, 0),
    feeParametersList: jspb.Message.toObjectList(msg.getFeeParametersList(),
    Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.toObject, includeInstance),
    defaultFeePaidToPartyRoleMapId: jspb.Message.getFieldWithDefault(msg, 32, ""),
    defaultFeeToleranceCategoryType: jspb.Message.getFieldWithDefault(msg, 34, 0),
    defaultFeePaidToWilqoPartyType: jspb.Message.getFieldWithDefault(msg, 35, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest;
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal;
      reader.readMessage(value,Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.deserializeBinaryFromReader);
      msg.setFeeEstimatedTotalAmount(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeePaidToEnum} */ (reader.readEnum());
      msg.setFeePaidToType(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeProcessEnum} */ (reader.readEnum());
      msg.setFeeProcessType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSpecifiedHud1LineNumberValue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSpecifiedLineNumberValue(value);
      break;
    case 14:
      var value = /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (reader.readEnum());
      msg.setGfeAggregationType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegratedDisclosureLineNumberValue(value);
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSectionType(value);
      break;
    case 17:
      var value = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (reader.readEnum());
      msg.setIntegratedDisclosureSubsectionType(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptionalCostIndicator(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRegulationZPointsAndFeesIndicator(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiredProviderOfServiceIndicator(value);
      break;
    case 21:
      var value = /** @type {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} */ (reader.readEnum());
      msg.setSectionClassificationType(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultFeeIncludedInAprIndicator(value);
      break;
    case 25:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaidToType(value);
      break;
    case 26:
      var value = /** @type {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaidToPartyType(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFeePaymentPaidByTypeThirdPartyName(value);
      break;
    case 28:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaymentPaidByType(value);
      break;
    case 29:
      var value = /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} */ (reader.readEnum());
      msg.setDefaultFeePaymentTimingType(value);
      break;
    case 30:
      var value = /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (reader.readEnum());
      msg.setHud1SectionClassificationType(value);
      break;
    case 31:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.deserializeBinaryFromReader);
      msg.addFeeParameters(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultFeePaidToPartyRoleMapId(value);
      break;
    case 34:
      var value = /** @type {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} */ (reader.readEnum());
      msg.setDefaultFeeToleranceCategoryType(value);
      break;
    case 35:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (reader.readEnum());
      msg.setDefaultFeePaidToWilqoPartyType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFeeEstimatedTotalAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal.serializeBinaryToWriter
    );
  }
  f = message.getFeePaidToType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getFeeProcessType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getFeeSpecifiedHud1LineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFeeSpecifiedLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getIntegratedDisclosureLineNumberValue();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIntegratedDisclosureSectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getOptionalCostIndicator();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getRegulationZPointsAndFeesIndicator();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getRequiredProviderOfServiceIndicator();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getSectionClassificationType();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getDefaultFeeIncludedInAprIndicator();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getDefaultFeePaidToType();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getDefaultFeePaidToPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      26,
      f
    );
  }
  f = message.getDefaultFeePaymentPaidByTypeThirdPartyName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getDefaultFeePaymentPaidByType();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getDefaultFeePaymentTimingType();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getFeeParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter.serializeBinaryToWriter
    );
  }
  f = message.getDefaultFeePaidToPartyRoleMapId();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getDefaultFeeToleranceCategoryType();
  if (f !== 0.0) {
    writer.writeEnum(
      34,
      f
    );
  }
  f = message.getDefaultFeePaidToWilqoPartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FeeEnum type = 4;
 * @return {!proto.wilqo.api.mortgage_config.FeeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.models.ProtoDecimal fee_estimated_total_amount = 6;
 * @return {?proto.wilqo.shared.models.ProtoDecimal}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getFeeEstimatedTotalAmount = function() {
  return /** @type{?proto.wilqo.shared.models.ProtoDecimal} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_CustomWrappers_pb.ProtoDecimal, 6));
};


/**
 * @param {?proto.wilqo.shared.models.ProtoDecimal|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setFeeEstimatedTotalAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.clearFeeEstimatedTotalAmount = function() {
  return this.setFeeEstimatedTotalAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.hasFeeEstimatedTotalAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FeePaidToEnum fee_paid_to_type = 7;
 * @return {!proto.wilqo.api.mortgage_config.FeePaidToEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getFeePaidToType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeePaidToEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeePaidToEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setFeePaidToType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional FeeProcessEnum fee_process_type = 9;
 * @return {!proto.wilqo.api.mortgage_config.FeeProcessEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getFeeProcessType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeProcessEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeProcessEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setFeeProcessType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string fee_specified_hud_1_line_number_value = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getFeeSpecifiedHud1LineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setFeeSpecifiedHud1LineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fee_specified_line_number_value = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getFeeSpecifiedLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setFeeSpecifiedLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional GFEAggregationEnum gfe_aggregation_type = 14;
 * @return {!proto.wilqo.api.mortgage_config.GFEAggregationEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getGfeAggregationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.GFEAggregationEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setGfeAggregationType = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.clearGfeAggregationType = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.hasGfeAggregationType = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string integrated_disclosure_line_number_value = 15;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getIntegratedDisclosureLineNumberValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setIntegratedDisclosureLineNumberValue = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional IntegratedDisclosureSectionEnum integrated_disclosure_section_type = 16;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getIntegratedDisclosureSectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setIntegratedDisclosureSectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional IntegratedDisclosureSubsectionEnum integrated_disclosure_subsection_type = 17;
 * @return {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getIntegratedDisclosureSubsectionType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.IntegratedDisclosureSubsectionEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setIntegratedDisclosureSubsectionType = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.clearIntegratedDisclosureSubsectionType = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.hasIntegratedDisclosureSubsectionType = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool optional_cost_indicator = 18;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getOptionalCostIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setOptionalCostIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool regulation_z_points_and_fees_indicator = 19;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getRegulationZPointsAndFeesIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setRegulationZPointsAndFeesIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool required_provider_of_service_indicator = 20;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getRequiredProviderOfServiceIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setRequiredProviderOfServiceIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional SectionClassificationEnum section_classification_type = 21;
 * @return {!proto.wilqo.api.mortgage_config.SectionClassificationEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getSectionClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.SectionClassificationEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setSectionClassificationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional bool default_fee_included_in_apr_indicator = 23;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeeIncludedInAprIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeeIncludedInAprIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional DefaultFeePaidToTypeEnum default_fee_paid_to_type = 25;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaidToType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaidToTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaidToType = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional PartyRoleTypeEnum default_fee_paid_to_party_type = 26;
 * @return {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaidToPartyType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.PartyRoleTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaidToPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 26, value);
};


/**
 * optional string default_fee_payment_paid_by_type_third_party_name = 27;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaymentPaidByTypeThirdPartyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaymentPaidByTypeThirdPartyName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional DefaultFeePaymentPaidByTypeEnum default_fee_payment_paid_by_type = 28;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaymentPaidByType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentPaidByTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaymentPaidByType = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional DefaultFeePaymentTimingTypeEnum default_fee_payment_timing_type = 29;
 * @return {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaymentTimingType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.DefaultFeePaymentTimingTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaymentTimingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional Hud1SectionClassificationTypeEnum hud_1_section_classification_type = 30;
 * @return {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getHud1SectionClassificationType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.Hud1SectionClassificationTypeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setHud1SectionClassificationType = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.clearHud1SectionClassificationType = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.hasHud1SectionClassificationType = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated FeeTemplateRuleGroupParameter fee_parameters = 31;
 * @return {!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getFeeParametersList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Reflections_pb.FeeTemplateRuleGroupParameter, 31));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter>} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setFeeParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.addFeeParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.wilqo.api.mortgage_config.FeeTemplateRuleGroupParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.clearFeeParametersList = function() {
  return this.setFeeParametersList([]);
};


/**
 * optional string default_fee_paid_to_party_role_map_id = 32;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaidToPartyRoleMapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaidToPartyRoleMapId = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional FeeToleranceCategoryType default_fee_tolerance_category_type = 34;
 * @return {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeeToleranceCategoryType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeToleranceCategoryType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeeToleranceCategoryType = function(value) {
  return jspb.Message.setProto3EnumField(this, 34, value);
};


/**
 * optional wilqo.shared.models.WilqoPartyType default_fee_paid_to_wilqo_party_type = 35;
 * @return {!proto.wilqo.shared.models.WilqoPartyType}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.getDefaultFeePaidToWilqoPartyType = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyType} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandRequest.prototype.setDefaultFeePaidToWilqoPartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse;
  return proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanFeeTemplateUpdateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminLoanFeeTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest;
  return proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminLoanFeeTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminLoanFeeTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string admin_loan_fee_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.getAdminLoanFeeTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.setAdminLoanFeeTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional FeeParametrizedRuleAggregatorSummary trigger_rules = 3;
 * @return {?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandRequest.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse;
  return proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FeeParametrizedRuleAggregatorSummary trigger_rules = 2;
 * @return {?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.FeeParametrizedRuleAggregatorSummary, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpdateAdminLoanFeeTemplateTriggersCommandResponse.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminLoanFeeTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest;
  return proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminLoanFeeTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminLoanFeeTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string admin_loan_fee_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.getAdminLoanFeeTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.setAdminLoanFeeTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    template: (f = msg.getTemplate()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse;
  return proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdminLoanFeeTemplate template = 2;
 * @return {?proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.getTemplate = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.PublishAdminLoanFeeTemplateCommandResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lenderConditionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest;
  return proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderConditionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLenderConditionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string lender_condition_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.getLenderConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.setLenderConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string institution_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse;
  return proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.PublishLenderConditionCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, 0),
    conditionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    conditionSatisfactionResponsiblePartyType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    conditionSatisfactionTimeframeType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    internalName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest;
  return proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum} */ (reader.readEnum());
      msg.setConditionSatisfactionResponsiblePartyType(value);
      break;
    case 7:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum} */ (reader.readEnum());
      msg.setConditionSatisfactionTimeframeType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConditionSatisfactionResponsiblePartyType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getConditionSatisfactionTimeframeType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ConditionTemplateCategoryEnum category = 4;
 * @return {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getCategory = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ConditionTemplateCategoryEnum} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string condition_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ConditionSatisfactionResponsiblePartyEnum condition_satisfaction_responsible_party_type = 6;
 * @return {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getConditionSatisfactionResponsiblePartyType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ConditionSatisfactionResponsiblePartyEnum} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setConditionSatisfactionResponsiblePartyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional ConditionSatisfactionTimeframeEnum condition_satisfaction_timeframe_type = 7;
 * @return {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getConditionSatisfactionTimeframeType = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ConditionSatisfactionTimeframeEnum} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setConditionSatisfactionTimeframeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string internal_name = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandRequest.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse;
  return proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLenderConditionTemplateCommandResponse.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    conditionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest;
  return proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConditionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string condition_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.getConditionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.setConditionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregatorSummary trigger_rules = 3;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_AdminModels_pb.RuleAggregatorSummary, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandRequest.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse;
  return proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpdateLenderConditionTemplateTriggersCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageConfig: (f = msg.getMessageConfig()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LicenseValidationMessageConfig.toObject(includeInstance, f),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest;
  return proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 2:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LicenseValidationMessageConfig;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LicenseValidationMessageConfig.deserializeBinaryFromReader);
      msg.setMessageConfig(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LicenseValidationMessageConfig.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string organization_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LicenseValidationMessageConfig message_config = 2;
 * @return {?proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.getMessageConfig = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LicenseValidationMessageConfig, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.LicenseValidationMessageConfig|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.setMessageConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.clearMessageConfig = function() {
  return this.setMessageConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.hasMessageConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse;
  return proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpsertLicenseValidationMessageConfigCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest;
  return proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.PanelElementResponse panel_elements = 3;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} returns this
*/
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandRequest.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    template: (f = msg.getTemplate()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse;
  return proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdminLoanFeeTemplate template = 2;
 * @return {?proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.getTemplate = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Admin_pb.AdminLoanFeeTemplate, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.UpsertAdminLoanFeeTemplatePageCommandResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loanFeeTemplateIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest;
  return proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addLoanFeeTemplateIds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoanFeeTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string loan_fee_template_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.getLoanFeeTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.setLoanFeeTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.addLoanFeeTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.clearLoanFeeTemplateIdsList = function() {
  return this.setLoanFeeTemplateIdsList([]);
};


/**
 * optional bool archived = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandRequest.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse;
  return proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLoanFeeTemplatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lenderConditionTemplateIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest;
  return proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addLenderConditionTemplateIds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLenderConditionTemplateIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string lender_condition_template_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.getLenderConditionTemplateIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.setLenderConditionTemplateIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.addLenderConditionTemplateIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.clearLenderConditionTemplateIdsList = function() {
  return this.setLenderConditionTemplateIdsList([]);
};


/**
 * optional bool archived = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandRequest.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse;
  return proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse} returns this
*/
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse} returns this
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.ArchiveAdminLenderConditionTemplatesCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.wilqo.api.mortgage_config);
