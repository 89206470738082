import type { PanelElement } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';

import type { Props as ComponentProps } from './CheckboxSelect.component';
import Component from './CheckboxSelect.component';
import DDE from './CheckboxSelect.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends PanelElement.AsObject {
  displayOnly: false;
}

export type CheckboxSelectProps = ExtendedComponentProps | ExtendedDDEProps;

const CheckboxSelect = (props: CheckboxSelectProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as PanelElement.AsObject} />;
};

export { CheckboxSelect };
