import { useMutation } from '@tanstack/react-query';

import type { DynamicDataActivityCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/DynamicDataActivity/Wilqo_API_Mortgage_DynamicData_DynamicDataActivity_Commands_pb';
import { SubmitDynamicDataActivityCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/DynamicDataActivity/Wilqo_API_Mortgage_DynamicData_DynamicDataActivity_Commands_pb';
import type { FullPageSaveItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DealWriteCommandResponse } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

interface Params {
  activityId: string;
  pageSaveItems: FullPageSaveItem[];
}

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.SubmitDynamicDataActivityCommandRequest';

export const useSubmitDynamicActivity = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation<DynamicDataActivityCommandResponse.AsObject, Error, Params>(async (params: Params) => {
    const request = new SubmitDynamicDataActivityCommandRequest();
    request.setActivityId(params.activityId);
    request.setBusinessProcessDomainId(bpdId);
    request.setItemsList(params.pageSaveItems);
    return sendMessage<DealWriteCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      DealWriteCommandResponse,
    );
  });
};
