import { useEffect } from 'react';
import { useParams } from 'react-router';

import type { RuleAggregatorSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { useActivityTriggers } from '@/API/Queries/activities/useActivityTriggers';
import { useUpdateActivityTriggers } from '@/API/Queries/activities/useUpdateActicityTriggers';
import { Trigger } from '@/Components/Features/trigger/Trigger';

import { useActivityConfiguratorContext } from './activityConfiguratorContext';

const ActivityTrigger = () => {
  const { setSidesheetType } = useActivityConfiguratorContext();
  const { activityId = '' } = useParams();

  const { data, isLoading, refetch } = useActivityTriggers(activityId);
  const { mutate } = useUpdateActivityTriggers();

  useEffect(() => () => {
    refetch();
  }, [refetch]);

  const handleUpdateTrigger = (trigger: RuleAggregatorSummary.AsObject) => {
    mutate({
      designId: activityId,
      rules: trigger,
    });
  };

  useEffect(() => {
    setSidesheetType('none');
  }, [setSidesheetType]);

  return (
    <Trigger
      isLoading={isLoading}
      onUpdateTrigger={handleUpdateTrigger}
      title="Trigger this activity when the following conditions are met"
      trigger={data}
    />
  );
};

export { ActivityTrigger };
