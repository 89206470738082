import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type { LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import {
  type CardListItem,
  type LoanEscrowFee,
  type LoanEscrowFeeCategory,
  type LoanFee,
  type LoanFeeCategory,
  type LoanPrepaidFee,
  type LoanPrepaidFeeCategory,
  LoanFeeSpecialCaseEnum,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { FeePermissions } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useLoanFees } from '@/API/Queries/mortgage/useLoanFees';
import { useLoanFeesPageHeader } from '@/API/Queries/mortgage/useLoanFeesPageHeader';
import type { FeeState } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import {
  adjustAtClosing,
  calculateNonSpecialFees,
  ColumnEnum,
  convertToCamelCase,
  getTotalAmount,
  updatePaymentPortions,
} from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/feesHelper';
import { calculatePayerTotals } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import { cleanAndConvertCurrency } from '@/Utils/Helpers/numberFormatter';
import { ConvertNumberToProtoDecimal } from '@/Utils/Helpers/protoDecimalConversion';

type ErrorDictionary = { [key: string]: Set<string> };

interface IFeeData {
  loanEscrowFees: LoanEscrowFeeCategory.AsObject[];
  loanPrepaidFees: LoanPrepaidFeeCategory.AsObject[];
  loanFees: LoanFeeCategory.AsObject[];
  disabledColumnsList: Array<string>;
  feePermissions: FeePermissions.AsObject | undefined;
}

export interface TotalMet {
  column: string;
  exceeded: boolean;
  feeId: string;
  message: string;
  category: LoanFeeCategoryTypeEnum | null;
}

export type SetTotalMet = Dispatch<SetStateAction<TotalMet>>;

interface IFeesContextProps {
  totalMet: TotalMet;
  overrideErrors: { [key: string]: boolean };
  setOverrideErrors: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  isDirty: boolean;
  feesError: boolean;
  showModalTabs: boolean;
  feesLoading: boolean;
  hasError: (newErrorID: string, section: LoanFeeCategoryTypeEnum) => boolean;
  handleBlurInput: (
    value: string | undefined,
    column: { key: string },
    updatedFee: LoanEscrowFee.AsObject | LoanFee.AsObject | LoanPrepaidFee.AsObject | undefined,
    category: LoanEscrowFeeCategory.AsObject | LoanFeeCategory.AsObject | LoanPrepaidFeeCategory.AsObject | undefined,
    payer?: string,
    categoryEnum?: LoanFeeCategoryTypeEnum
  ) => void;
  setTotalMet: (data: TotalMet) => void;
  disabledColumnsList: Array<string>;
  loanEscrowFees: LoanEscrowFeeCategory.AsObject[];
  loanPrepaidFees: LoanPrepaidFeeCategory.AsObject[];
  loanFees: LoanFeeCategory.AsObject[];
  sellerTotalExceeded: boolean;
  setFeesData: (data: IFeeData) => void;
  anyErrors: boolean;
  feesHeader: CardListItem.AsObject[];
  headerLoading: boolean;
  reloadFees: () => void;
  feePermissions: FeePermissions.AsObject | undefined;
}

const initialState: IFeesContextProps = {
  anyErrors: false,
  disabledColumnsList: [],
  feePermissions: undefined,
  feesError: true,
  feesHeader: [],
  feesLoading: true,
  handleBlurInput: () => { },
  hasError: () => false,
  headerLoading: true,
  isDirty: false,
  loanEscrowFees: [],
  loanFees: [],
  loanPrepaidFees: [],
  overrideErrors: {},
  reloadFees: () => null,
  sellerTotalExceeded: false,
  setFeesData: () => null,
  setOverrideErrors: () => null,
  setTotalMet: () => null,
  showModalTabs: false,
  totalMet: {
    category: null,
    column: '',
    exceeded: false,
    feeId: '',
    message: '',
  },
};

const FeesContext = createContext<IFeesContextProps>(initialState);
FeesContext.displayName = 'FeesContext';

export const FeesProvider = ({ children }: { children: ReactNode }) => {
  // Handle Data States
  const [feesData, setFeesData] = useState<IFeeData>({
    disabledColumnsList: [],
    feePermissions: undefined,
    loanEscrowFees: [],
    loanFees: [],
    loanPrepaidFees: [],
  });
  const [feesHeader, setFeesHeader] = useState<Array<CardListItem.AsObject>>([]);

  // Handle Error States
  const [isDirty, setIsDirty] = useState(false);
  const [totalMet, setTotalMet] = useState<TotalMet>({
    category: null,
    column: '',
    exceeded: false,
    feeId: '',
    message: '',
  } as TotalMet);
  const [errorArray, setErrorArray] = useState<ErrorDictionary>({});
  const [overrideErrors, setOverrideErrors] = useState<{ [key: string]: boolean }>({});
  const [showModalTabs, setShowModalTabs] = useState(true);

  // Handle Individual Fees Error states
  const [sellerTotalExceeded, setSellerTotalExceeded] = useState(false);

  const { data, isError: feesError, isLoading: feesLoading, refetch: refetchFees } = useLoanFees();
  const { data: feeDetailsHeader, isLoading: headerLoading, refetch: refetchFeesHeader } = useLoanFeesPageHeader();

  const reloadFees = () => {
    refetchFees();
    refetchFeesHeader();
  };

  useEffect(() => {
    if (!data || feesLoading) return;
    const { disabledColumnsList } = data;

    setFeesData({
      disabledColumnsList,
      feePermissions: data.feePermissions,
      loanEscrowFees: data.escrowsList,
      loanFees: data.feeCategoriesList,
      loanPrepaidFees: data.prepaidsList,
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data]);

  useEffect(() => {
    if (!feeDetailsHeader || headerLoading) return;
    setFeesHeader(feeDetailsHeader.cardListList);
  });

  useEffect(() => {
    setShowModalTabs(true);
  }, []);

  const hasError = (newErrorID: string, section: LoanFeeCategoryTypeEnum) => {
    if (errorArray[section]) {
      return errorArray[section].has(newErrorID);
    }
    return false;
  };

  const anyErrors = (() => {
    let result = false;
    Object.keys(errorArray).forEach((key) => {
      const element = errorArray[key];
      if (element && element.size > 0) {
        result = true;
      }
    });
    return result;
  })();

  const manageErrors = useCallback((newErrorID: string, category: LoanFeeCategoryTypeEnum, remove: boolean) => {
    if (remove) {
      if (errorArray[category]) {
        errorArray[category].delete(newErrorID);
      }
    } else if (!errorArray[category]) {
      errorArray[category] = new Set<string>();
      errorArray[category].add(newErrorID);
    } else {
      errorArray[category].add(newErrorID);
    }
    setErrorArray(errorArray);
  }, [errorArray]);

  const handleBlurInput = useCallback((
    value: string | undefined,
    column: { key: string },
    updatedFee: LoanEscrowFee.AsObject | LoanFee.AsObject | LoanPrepaidFee.AsObject | undefined,
    category: LoanEscrowFeeCategory.AsObject | LoanFeeCategory.AsObject | LoanPrepaidFeeCategory.AsObject | undefined,
    payer?: string,
    categoryEnum?: LoanFeeCategoryTypeEnum,
  ) => {
    if (!updatedFee || !value) return;

    if ('specialCase' in updatedFee && updatedFee.specialCase === LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT) {
      // First, check if value is '-0' and adjust it
      if (value === '-$0') {
        value = '$0';
      } else {
        // Continue with existing logic to prepend a '-' if not already negative
        value = value.startsWith('-') ? value : `-${value}`;
      }
    }

    const updatedFees: FeeState = {
      borrowerPaid: cleanAndConvertCurrency(updatedFee.borrowerPaid),
      lenderPaid: cleanAndConvertCurrency(updatedFee.lenderPaid),
      newValue: value ? cleanAndConvertCurrency(Number(value.replace(/[^0-9.-]+/g, ''))) : undefined,
      otherPaid: 'otherPaid' in updatedFee ? cleanAndConvertCurrency(updatedFee.otherPaid) : undefined,
      sellerPaid: cleanAndConvertCurrency(updatedFee.sellerPaid),
      total: cleanAndConvertCurrency(updatedFee.total),
      updateBorrower: false,
    };

    if (value) updatedFees[column.key] = cleanAndConvertCurrency(Number(value.replace(/[^0-9.-]+/g, '')));

    let paymentPortion;

    if (!categoryEnum && category && category.category === 'Prepaids') {
      categoryEnum = 2;
      paymentPortion = 'prepaidPayment';
    } else if (!categoryEnum && category && category.category === 'Initial Escrow Payment At Closing') {
      categoryEnum = 1;
      paymentPortion = 'escrowPayment';
    } else if (!categoryEnum) {
      categoryEnum = 0;
      paymentPortion = 'feePayment';
    }

    if (payer && calculatePayerTotals(updatedFee, payer)) {
      setSellerTotalExceeded(true);
    } else {
      setSellerTotalExceeded(false);
    }

    if (value && column.key === ColumnEnum.LENDER_PAID) {
      updatePaymentPortions(value, updatedFee, ColumnEnum.LENDER_PAID, categoryEnum);
      updatedFee.lenderPaid = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
    } else if (column.key === ColumnEnum.OTHER_PAID) {
      if (value && 'otherPaid' in updatedFee) {
        updatedFee.otherPaid = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
        updatePaymentPortions(value, updatedFee, ColumnEnum.OTHER_PAID, categoryEnum);
      }
    } else if (value && column.key === ColumnEnum.SELLER_PAID) {
      updatePaymentPortions(value, updatedFee, ColumnEnum.SELLER_PAID, categoryEnum);
      updatedFee.sellerPaid = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
    } else if (value && column.key === ColumnEnum.BORROWER_PAID) {
      updatePaymentPortions(value, updatedFee, ColumnEnum.BORROWER_PAID, categoryEnum);
      updatedFee.borrowerPaid = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
    } else if (value && column.key === ColumnEnum.TOTAL && category) {
      updatePaymentPortions(value, updatedFee, ColumnEnum.TOTAL, categoryEnum);

      const currentTotal = {
        total: updatedFee.total,
      };
      updatedFee.total = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
      category.sumTotal = ConvertNumberToProtoDecimal(Number(cleanAndConvertCurrency(category.sumTotal)
        .add(cleanAndConvertCurrency(updatedFee.total)
          .subtract(cleanAndConvertCurrency(currentTotal.total))))).toObject();
    }

    calculateNonSpecialFees(updatedFees, column.key, updatedFee, setIsDirty, setTotalMet, manageErrors, categoryEnum);

    const total = Number(cleanAndConvertCurrency(updatedFee.total));
    const lenderPaid = Number(cleanAndConvertCurrency(updatedFee.lenderPaid));
    const sellerPaid = Number(cleanAndConvertCurrency(updatedFee.sellerPaid));

    let borrowerPaidAmount;

    if ('specialCase' in updatedFee && updatedFee.specialCase === LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT) {
      borrowerPaidAmount = total + Math.abs(lenderPaid + sellerPaid);
    } else {
      const otherPaid = 'otherPaid' in updatedFee ? Number(cleanAndConvertCurrency(updatedFee.otherPaid || 0)) : 0;
      borrowerPaidAmount = total - Math.abs(lenderPaid + sellerPaid + otherPaid);
    }

    updatedFee.borrowerPaid = ConvertNumberToProtoDecimal(borrowerPaidAmount || 0).toObject();

    const isExceeded = Number(cleanAndConvertCurrency(updatedFee.borrowerPaid)) < 0 && (
      'specialCase' in updatedFee && updatedFee.specialCase !== LoanFeeSpecialCaseEnum.LOAN_FEE_SPECIAL_CASE_ENUM_AGGREGATE_ADJUSTMENT
    );

    setTotalMet({
      category: categoryEnum,
      column: 'borrowerPaid',
      exceeded: isExceeded,
      feeId: updatedFee.feeId,
      message: 'Amount entered exceeds the allowed total',
    });

    if (Math.abs(Number(getTotalAmount(column.key, updatedFee))) > Math.abs(Number(updatedFees.total.value))) {
      setTotalMet({
        category: categoryEnum,
        column: column.key,
        exceeded: true,
        feeId: updatedFee.feeId,
        message: 'Amount entered exceeds the allowed total',
      });
      // push error to array
      manageErrors(updatedFee.feeId, categoryEnum, false);
    }

    let paymentType;
    if (categoryEnum && categoryEnum === 2) {
      paymentType = 'prepaidPayment';
    } else if (categoryEnum && categoryEnum === 1) {
      paymentType = 'escrowPayment';
    } else if (categoryEnum && categoryEnum === 0) {
      paymentType = 'feePayment';
    }

    adjustAtClosing(
      updatedFee,
      { payer: convertToCamelCase(column.key) },
      paymentPortion || paymentType,
    );

    if (value === '') {
      setTotalMet({
        category: categoryEnum,
        column: column.key,
        exceeded: true,
        feeId: updatedFee.feeId,
        message: 'No amount entered',
      });
    }
  }, [manageErrors]);

  return (
    <FeesContext.Provider value={{
      anyErrors,
      disabledColumnsList: feesData.disabledColumnsList,
      feePermissions: feesData.feePermissions,
      feesError,
      feesHeader,
      feesLoading,
      handleBlurInput,
      hasError,
      headerLoading,
      isDirty,
      loanEscrowFees: feesData.loanEscrowFees,
      loanFees: feesData.loanFees,
      loanPrepaidFees: feesData.loanPrepaidFees,
      overrideErrors,
      reloadFees,
      sellerTotalExceeded,
      setFeesData,
      setOverrideErrors,
      setTotalMet,
      showModalTabs,
      totalMet,
    }}
    >
      {children}
    </FeesContext.Provider>
  );
};

export const useFeesContext = () => useContext(FeesContext);
