// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/AggregatedTable_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CardListItem_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/ChangeHistoryDetails_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/CollectionForm_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/DynamicText_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/EditableTable_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Form_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/InfoCard_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/IntegrationsHistory_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/MessagePanel_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/RelatedNestingContent_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/SubmitForm_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/SummaryCard_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Fusion_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb);
var Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb = require('../../../Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.ActionConfigCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.RenderType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.SettingsCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.WidgetContingency', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.repeatedFields_, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.displayName = 'proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.WidgetContingency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.displayName = 'proto.wilqo.api.mortgage_dynamic_data.WidgetContingency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.repeatedFields_ = [12];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_ = [[2,3,4,5,7,8,9,10,11,13,14,15,17,18,19,20,21,22,24,25,26]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.SettingsCase = {
  SETTINGS_NOT_SET: 0,
  CHANGE_HISTORY: 2,
  INTEGRATIONS_HISTORY: 3,
  CARD_LIST_ITEM: 4,
  TABS: 5,
  COLLECTION_FORM: 7,
  FORM: 8,
  TABLE_FORM: 9,
  MESSAGE_PANEL: 10,
  GROUPED_TABLE_FORM: 11,
  RELATED_NESTING_CONTENT: 13,
  GROUPED_WIDGETS: 14,
  TOGGLABLE_WIDGET: 15,
  INFO_CARD: 17,
  GROUPED_FORM: 18,
  MOSAIC_WIDGET_CONTAINER: 19,
  DYNAMIC_TEXT: 20,
  SUBMIT_FORM: 21,
  EDITABLE_TABLE: 22,
  SUMMARY_CARD: 24,
  AGGREGATED_TABLE: 25,
  FUSION_WIDGET: 26
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.SettingsCase}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getSettingsCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.SettingsCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    changeHistory: (f = msg.getChangeHistory()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb.ChangeHistoryWidgetSettings.toObject(includeInstance, f),
    integrationsHistory: (f = msg.getIntegrationsHistory()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb.IntegrationsHistoryWidgetSettings.toObject(includeInstance, f),
    cardListItem: (f = msg.getCardListItem()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings.toObject(includeInstance, f),
    tabs: (f = msg.getTabs()) && proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.toObject(includeInstance, f),
    collectionForm: (f = msg.getCollectionForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb.CollectionFormWidgetSettings.toObject(includeInstance, f),
    form: (f = msg.getForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings.toObject(includeInstance, f),
    tableForm: (f = msg.getTableForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.toObject(includeInstance, f),
    messagePanel: (f = msg.getMessagePanel()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb.MessagePanelWidgetSettings.toObject(includeInstance, f),
    groupedTableForm: (f = msg.getGroupedTableForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.GroupedTableFormWidgetSettings.toObject(includeInstance, f),
    relatedNestingContent: (f = msg.getRelatedNestingContent()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb.RelatedNestingContentWidgetSettings.toObject(includeInstance, f),
    groupedWidgets: (f = msg.getGroupedWidgets()) && proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.toObject(includeInstance, f),
    togglableWidget: (f = msg.getTogglableWidget()) && proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.toObject(includeInstance, f),
    infoCard: (f = msg.getInfoCard()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb.InfoCard.toObject(includeInstance, f),
    groupedForm: (f = msg.getGroupedForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.GroupedFormWidgetSettings.toObject(includeInstance, f),
    mosaicWidgetContainer: (f = msg.getMosaicWidgetContainer()) && proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.toObject(includeInstance, f),
    dynamicText: (f = msg.getDynamicText()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb.DynamicTextWidgetSettings.toObject(includeInstance, f),
    submitForm: (f = msg.getSubmitForm()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings.toObject(includeInstance, f),
    editableTable: (f = msg.getEditableTable()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb.EditableTableWidgetSettings.toObject(includeInstance, f),
    summaryCard: (f = msg.getSummaryCard()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings.toObject(includeInstance, f),
    aggregatedTable: (f = msg.getAggregatedTable()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb.AggregatedTableWidgetSettings.toObject(includeInstance, f),
    fusionWidget: (f = msg.getFusionWidget()) && Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings.toObject(includeInstance, f),
    validationMessagesList: jspb.Message.toObjectList(msg.getValidationMessagesList(),
    Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage.toObject, includeInstance),
    contingency: (f = msg.getContingency()) && proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.toObject(includeInstance, f),
    fieldGrouping: (f = msg.getFieldGrouping()) && Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings.toObject(includeInstance, f),
    changeTracking: (f = msg.getChangeTracking()) && proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget;
  return proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb.ChangeHistoryWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb.ChangeHistoryWidgetSettings.deserializeBinaryFromReader);
      msg.setChangeHistory(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb.IntegrationsHistoryWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb.IntegrationsHistoryWidgetSettings.deserializeBinaryFromReader);
      msg.setIntegrationsHistory(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings.deserializeBinaryFromReader);
      msg.setCardListItem(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.deserializeBinaryFromReader);
      msg.setTabs(value);
      break;
    case 7:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb.CollectionFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb.CollectionFormWidgetSettings.deserializeBinaryFromReader);
      msg.setCollectionForm(value);
      break;
    case 8:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    case 9:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.deserializeBinaryFromReader);
      msg.setTableForm(value);
      break;
    case 10:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb.MessagePanelWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb.MessagePanelWidgetSettings.deserializeBinaryFromReader);
      msg.setMessagePanel(value);
      break;
    case 11:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.GroupedTableFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.GroupedTableFormWidgetSettings.deserializeBinaryFromReader);
      msg.setGroupedTableForm(value);
      break;
    case 13:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb.RelatedNestingContentWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb.RelatedNestingContentWidgetSettings.deserializeBinaryFromReader);
      msg.setRelatedNestingContent(value);
      break;
    case 14:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.deserializeBinaryFromReader);
      msg.setGroupedWidgets(value);
      break;
    case 15:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.deserializeBinaryFromReader);
      msg.setTogglableWidget(value);
      break;
    case 17:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb.InfoCard;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb.InfoCard.deserializeBinaryFromReader);
      msg.setInfoCard(value);
      break;
    case 18:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.GroupedFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.GroupedFormWidgetSettings.deserializeBinaryFromReader);
      msg.setGroupedForm(value);
      break;
    case 19:
      var value = new proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.deserializeBinaryFromReader);
      msg.setMosaicWidgetContainer(value);
      break;
    case 20:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb.DynamicTextWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb.DynamicTextWidgetSettings.deserializeBinaryFromReader);
      msg.setDynamicText(value);
      break;
    case 21:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings.deserializeBinaryFromReader);
      msg.setSubmitForm(value);
      break;
    case 22:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb.EditableTableWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb.EditableTableWidgetSettings.deserializeBinaryFromReader);
      msg.setEditableTable(value);
      break;
    case 24:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings.deserializeBinaryFromReader);
      msg.setSummaryCard(value);
      break;
    case 25:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb.AggregatedTableWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb.AggregatedTableWidgetSettings.deserializeBinaryFromReader);
      msg.setAggregatedTable(value);
      break;
    case 26:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings.deserializeBinaryFromReader);
      msg.setFusionWidget(value);
      break;
    case 12:
      var value = new Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage.deserializeBinaryFromReader);
      msg.addValidationMessages(value);
      break;
    case 16:
      var value = new proto.wilqo.api.mortgage_dynamic_data.WidgetContingency;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.deserializeBinaryFromReader);
      msg.setContingency(value);
      break;
    case 23:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings.deserializeBinaryFromReader);
      msg.setFieldGrouping(value);
      break;
    case 27:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.deserializeBinaryFromReader);
      msg.setChangeTracking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getChangeHistory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb.ChangeHistoryWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getIntegrationsHistory();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb.IntegrationsHistoryWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getCardListItem();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getTabs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getCollectionForm();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb.CollectionFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getTableForm();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getMessagePanel();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb.MessagePanelWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getGroupedTableForm();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.GroupedTableFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getRelatedNestingContent();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb.RelatedNestingContentWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getGroupedWidgets();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.serializeBinaryToWriter
    );
  }
  f = message.getTogglableWidget();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.serializeBinaryToWriter
    );
  }
  f = message.getInfoCard();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb.InfoCard.serializeBinaryToWriter
    );
  }
  f = message.getGroupedForm();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.GroupedFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getMosaicWidgetContainer();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.serializeBinaryToWriter
    );
  }
  f = message.getDynamicText();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb.DynamicTextWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getSubmitForm();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getEditableTable();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb.EditableTableWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getSummaryCard();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getAggregatedTable();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb.AggregatedTableWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getFusionWidget();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getValidationMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage.serializeBinaryToWriter
    );
  }
  f = message.getContingency();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.serializeBinaryToWriter
    );
  }
  f = message.getFieldGrouping();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings.serializeBinaryToWriter
    );
  }
  f = message.getChangeTracking();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional ChangeHistoryWidgetSettings change_history = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ChangeHistoryWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getChangeHistory = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ChangeHistoryWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_ChangeHistoryDetails_pb.ChangeHistoryWidgetSettings, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ChangeHistoryWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setChangeHistory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearChangeHistory = function() {
  return this.setChangeHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasChangeHistory = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IntegrationsHistoryWidgetSettings integrations_history = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.IntegrationsHistoryWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getIntegrationsHistory = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.IntegrationsHistoryWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_IntegrationsHistory_pb.IntegrationsHistoryWidgetSettings, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.IntegrationsHistoryWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setIntegrationsHistory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearIntegrationsHistory = function() {
  return this.setIntegrationsHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasIntegrationsHistory = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CardListItemWidgetSettings card_list_item = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getCardListItem = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setCardListItem = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearCardListItem = function() {
  return this.setCardListItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasCardListItem = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TabsWidgetSettings tabs = 5;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getTabs = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings, 5));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setTabs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearTabs = function() {
  return this.setTabs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasTabs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CollectionFormWidgetSettings collection_form = 7;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.CollectionFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getCollectionForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.CollectionFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CollectionForm_pb.CollectionFormWidgetSettings, 7));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.CollectionFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setCollectionForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearCollectionForm = function() {
  return this.setCollectionForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasCollectionForm = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional FormWidgetSettings form = 8;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.FormWidgetSettings, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasForm = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TableFormWidgetSettings table_form = 9;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getTableForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.TableFormWidgetSettings, 9));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setTableForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearTableForm = function() {
  return this.setTableForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasTableForm = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional MessagePanelWidgetSettings message_panel = 10;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.MessagePanelWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getMessagePanel = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.MessagePanelWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_MessagePanel_pb.MessagePanelWidgetSettings, 10));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.MessagePanelWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setMessagePanel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearMessagePanel = function() {
  return this.setMessagePanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasMessagePanel = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GroupedTableFormWidgetSettings grouped_table_form = 11;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getGroupedTableForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_TableForm_pb.GroupedTableFormWidgetSettings, 11));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setGroupedTableForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearGroupedTableForm = function() {
  return this.setGroupedTableForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasGroupedTableForm = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional RelatedNestingContentWidgetSettings related_nesting_content = 13;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getRelatedNestingContent = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_RelatedNestingContent_pb.RelatedNestingContentWidgetSettings, 13));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.RelatedNestingContentWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setRelatedNestingContent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearRelatedNestingContent = function() {
  return this.setRelatedNestingContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasRelatedNestingContent = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional GroupedWidgetsContainerSettings grouped_widgets = 14;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getGroupedWidgets = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings, 14));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setGroupedWidgets = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearGroupedWidgets = function() {
  return this.setGroupedWidgets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasGroupedWidgets = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TogglableWidgetsContainerSettings togglable_widget = 15;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getTogglableWidget = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings, 15));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setTogglableWidget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearTogglableWidget = function() {
  return this.setTogglableWidget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasTogglableWidget = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional InfoCard info_card = 17;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.InfoCard}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getInfoCard = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.InfoCard} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_InfoCard_pb.InfoCard, 17));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.InfoCard|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setInfoCard = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearInfoCard = function() {
  return this.setInfoCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasInfoCard = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional GroupedFormWidgetSettings grouped_form = 18;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getGroupedForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Form_pb.GroupedFormWidgetSettings, 18));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.GroupedFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setGroupedForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearGroupedForm = function() {
  return this.setGroupedForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasGroupedForm = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional MosaicWidgetContainerSettings mosaic_widget_container = 19;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getMosaicWidgetContainer = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings, 19));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setMosaicWidgetContainer = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearMosaicWidgetContainer = function() {
  return this.setMosaicWidgetContainer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasMosaicWidgetContainer = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional DynamicTextWidgetSettings dynamic_text = 20;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.DynamicTextWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getDynamicText = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.DynamicTextWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_DynamicText_pb.DynamicTextWidgetSettings, 20));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.DynamicTextWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setDynamicText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearDynamicText = function() {
  return this.setDynamicText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasDynamicText = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional SubmitFormWidgetSettings submit_form = 21;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.SubmitFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getSubmitForm = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.SubmitFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SubmitForm_pb.SubmitFormWidgetSettings, 21));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.SubmitFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setSubmitForm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearSubmitForm = function() {
  return this.setSubmitForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasSubmitForm = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional EditableTableWidgetSettings editable_table = 22;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.EditableTableWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getEditableTable = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.EditableTableWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_EditableTable_pb.EditableTableWidgetSettings, 22));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.EditableTableWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setEditableTable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearEditableTable = function() {
  return this.setEditableTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasEditableTable = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional SummaryCardWidgetSettings summary_card = 24;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getSummaryCard = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings, 24));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setSummaryCard = function(value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearSummaryCard = function() {
  return this.setSummaryCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasSummaryCard = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional AggregatedTableWidgetSettings aggregated_table = 25;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.AggregatedTableWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getAggregatedTable = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.AggregatedTableWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_AggregatedTable_pb.AggregatedTableWidgetSettings, 25));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.AggregatedTableWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setAggregatedTable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearAggregatedTable = function() {
  return this.setAggregatedTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasAggregatedTable = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional FusionWidgetSettings fusion_widget = 26;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getFusionWidget = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_Fusion_pb.FusionWidgetSettings, 26));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FusionWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setFusionWidget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 26, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearFusionWidget = function() {
  return this.setFusionWidget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasFusionWidget = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * repeated ValidationMessage validation_messages = 12;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage>}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getValidationMessagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Wilqo_API_Mortgage_DynamicData_Models_pb.ValidationMessage, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setValidationMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ValidationMessage}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.addValidationMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.mortgage_dynamic_data.ValidationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearValidationMessagesList = function() {
  return this.setValidationMessagesList([]);
};


/**
 * optional WidgetContingency contingency = 16;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.WidgetContingency}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getContingency = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.WidgetContingency, 16));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.WidgetContingency|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setContingency = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearContingency = function() {
  return this.setContingency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasContingency = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional FieldGroupingSettings field_grouping = 23;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getFieldGrouping = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.FieldGroupingSettings, 23));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setFieldGrouping = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearFieldGrouping = function() {
  return this.setFieldGrouping(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasFieldGrouping = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional ChangeTrackingSettings change_tracking = 27;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.getChangeTracking = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings, 27));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.setChangeTracking = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.clearChangeTracking = function() {
  return this.setChangeTracking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.prototype.hasChangeTracking = function() {
  return jspb.Message.getField(this, 27) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    includedFieldsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    excludedFieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings;
  return proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIncludedFields(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addExcludedFields(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludedFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getExcludedFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string included_fields = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.getIncludedFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.setIncludedFieldsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.addIncludedFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.clearIncludedFieldsList = function() {
  return this.setIncludedFieldsList([]);
};


/**
 * repeated string excluded_fields = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.getExcludedFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.setExcludedFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.addExcludedFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.clearExcludedFieldsList = function() {
  return this.setExcludedFieldsList([]);
};


/**
 * repeated ChangeTrackingActionSettings actions = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSettings.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.ActionConfigCase = {
  ACTION_CONFIG_NOT_SET: 0,
  SET_FIELD_VALUE: 1
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.ActionConfigCase}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.getActionConfigCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.ActionConfigCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    setFieldValue: (f = msg.getSetFieldValue()) && proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings;
  return proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.deserializeBinaryFromReader);
      msg.setSetFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetFieldValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeTrackingSetFieldAction set_field_value = 1;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.getSetFieldValue = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction, 1));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.setSetFieldValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.clearSetFieldValue = function() {
  return this.setSetFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingActionSettings.prototype.hasSetFieldValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction;
  return proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.ChangeTrackingSetFieldAction.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cardListItemWidgetsList: jspb.Message.toObjectList(msg.getCardListItemWidgetsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings.toObject, includeInstance),
    summaryCardWidgetsList: jspb.Message.toObjectList(msg.getSummaryCardWidgetsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings.toObject, includeInstance),
    maxNumberOfColumns: jspb.Message.getFieldWithDefault(msg, 5, 0),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    fieldGroupingsMap: (f = msg.getFieldGroupingsMap()) ? f.toObject(includeInstance, proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings;
  return proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings.deserializeBinaryFromReader);
      msg.addCardListItemWidgets(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings.deserializeBinaryFromReader);
      msg.addSummaryCardWidgets(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxNumberOfColumns(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    case 7:
      var value = msg.getFieldGroupingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.deserializeBinaryFromReader, "", new proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCardListItemWidgetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getSummaryCardWidgetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getMaxNumberOfColumns();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFieldGroupingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings.serializeBinaryToWriter);
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CardListItemWidgetSettings card_list_item_widgets = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getCardListItemWidgetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_CardListItem_pb.CardListItemWidgetSettings, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.setCardListItemWidgetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.addCardListItemWidgets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.CardListItemWidgetSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.clearCardListItemWidgetsList = function() {
  return this.setCardListItemWidgetsList([]);
};


/**
 * repeated SummaryCardWidgetSettings summary_card_widgets = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getSummaryCardWidgetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Widgets_SummaryCard_pb.SummaryCardWidgetSettings, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.setSummaryCardWidgetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.addSummaryCardWidgets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.SummaryCardWidgetSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.clearSummaryCardWidgetsList = function() {
  return this.setSummaryCardWidgetsList([]);
};


/**
 * optional int32 max_number_of_columns = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getMaxNumberOfColumns = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.setMaxNumberOfColumns = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool editable = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * map<string, FieldGroupingSettings> field_groupings = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.getFieldGroupingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.wilqo.api.mortgage_dynamic_data.FieldGroupingSettings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.MosaicWidgetContainerSettings.prototype.clearFieldGroupingsMap = function() {
  this.getFieldGroupingsMap().clear();
  return this;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.toObject, includeInstance),
    widgetAdditionChipsList: jspb.Message.toObjectList(msg.getWidgetAdditionChipsList(),
    proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.deserializeBinaryFromReader);
      msg.addWidgetAdditionChips(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.serializeBinaryToWriter
    );
  }
  f = message.getWidgetAdditionChipsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    widget: (f = msg.getWidget()) && proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.toObject(includeInstance, f),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sequence: jspb.Message.getFieldWithDefault(msg, 5, 0),
    title: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.deserializeBinaryFromReader);
      msg.setWidget(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSequence(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getWidget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.serializeBinaryToWriter
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSequence();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional LoanPageWidget widget = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.getWidget = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.setWidget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.clearWidget = function() {
  return this.setWidget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.hasWidget = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sequence = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.getSequence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.setSequence = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetWidgetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetWidgetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetWidgetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string target_widget_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.getTargetWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings.prototype.setTargetWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TogglableWidgetsItemSettings items = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.TogglableWidgetsItemSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated WidgetAdditionChipsItemSettings widget_addition_chips = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.getWidgetAdditionChipsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.setWidgetAdditionChipsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.addWidgetAdditionChips = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.WidgetAdditionChipsItemSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TogglableWidgetsContainerSettings.prototype.clearWidgetAdditionChipsList = function() {
  return this.setWidgetAdditionChipsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    tabsList: jspb.Message.toObjectList(msg.getTabsList(),
    proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.deserializeBinaryFromReader);
      msg.addTabs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTabsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TabsWidgetSettingsTab tabs = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab>}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.prototype.getTabsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.prototype.setTabsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.prototype.addTabs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettings.prototype.clearTabsList = function() {
  return this.setTabsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    widgetsList: jspb.Message.toObjectList(msg.getWidgetsList(),
    proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab;
  return proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.deserializeBinaryFromReader);
      msg.addWidgets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWidgetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated LoanPageWidget widgets = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget>}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.getWidgetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.setWidgetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget}
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.addWidgets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TabsWidgetSettingsTab.prototype.clearWidgetsList = function() {
  return this.setWidgetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    descriptionParagraphsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    actionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.WidgetContingency;
  return proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDescriptionParagraphs(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActions(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescriptionParagraphsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string description_paragraphs = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.getDescriptionParagraphsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.setDescriptionParagraphsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.addDescriptionParagraphs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.clearDescriptionParagraphsList = function() {
  return this.setDescriptionParagraphsList([]);
};


/**
 * repeated SectionAction actions = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.getActionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.setActionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionAction} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.addActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetContingency} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.WidgetContingency.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.toObject, includeInstance),
    renderType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings;
  return proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.RenderType} */ (reader.readEnum());
      msg.setRenderType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.serializeBinaryToWriter
    );
  }
  f = message.getRenderType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.RenderType = {
  RENDER_TYPE_UNKNOWN: 0,
  RENDER_TYPE_TABS: 1,
  RENDER_TYPE_SECTIONS: 2
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: (f = msg.getLabel()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    badge: (f = msg.getBadge()) && Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge.toObject(includeInstance, f),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetsList: jspb.Message.toObjectList(msg.getWidgetsList(),
    proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings;
  return proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 3:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge.deserializeBinaryFromReader);
      msg.setBadge(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.deserializeBinaryFromReader);
      msg.addWidgets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getBadge();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge.serializeBinaryToWriter
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.RenderableValue label = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.getLabel = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Badge badge = 3;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.Badge}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.getBadge = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.Badge} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.Badge, 3));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.Badge|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.setBadge = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.clearBadge = function() {
  return this.setBadge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.hasBadge = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated LoanPageWidget widgets = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget>}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.getWidgetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.setWidgetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.addWidgets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPageWidget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings.prototype.clearWidgetsList = function() {
  return this.setWidgetsList([]);
};


/**
 * repeated GroupSettings groups = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.GroupSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional RenderType render_type = 2;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.RenderType}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.getRenderType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.RenderType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.RenderType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedWidgetsContainerSettings.prototype.setRenderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
