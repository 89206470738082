// source: Wilqo_API_Activity_Admin.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Models_ActivityAdminModels_pb = require('./Wilqo.Shared.Models/ActivityAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityAdminModels_pb);
var Wilqo_Shared_Models_Configuration_pb = require('./Wilqo.Shared.Models/Configuration_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Configuration_pb);
var Wilqo_Shared_Models_Timestamp_pb = require('./Wilqo.Shared.Models/Timestamp_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Timestamp_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
var Wilqo_API_Activity_Models_pb = require('./Wilqo_API_Activity_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Activity_Models_pb);
goog.exportSymbol('proto.wilqo.api.activity.AdminActivityMetaData', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AdminActivitySummary', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AdminPanelSequenceDesignActivity', null, global);
goog.exportSymbol('proto.wilqo.api.activity.BusinessProcessExtraConfig', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PanelSequenceDesign', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PanelSequenceDesignNavigationButton', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PanelSequenceDesign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.PanelSequenceDesign.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.PanelSequenceDesign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PanelSequenceDesign.displayName = 'proto.wilqo.api.activity.PanelSequenceDesign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PanelSequenceDesignNavigationButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.displayName = 'proto.wilqo.api.activity.PanelSequenceDesignNavigationButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AdminActivitySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.AdminActivitySummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.AdminActivitySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AdminActivitySummary.displayName = 'proto.wilqo.api.activity.AdminActivitySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.AdminPanelSequenceDesignActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.displayName = 'proto.wilqo.api.activity.AdminPanelSequenceDesignActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.BusinessProcessExtraConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.BusinessProcessExtraConfig.displayName = 'proto.wilqo.api.activity.BusinessProcessExtraConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AdminActivityMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.AdminActivityMetaData.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.AdminActivityMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AdminActivityMetaData.displayName = 'proto.wilqo.api.activity.AdminActivityMetaData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.PanelSequenceDesign.repeatedFields_ = [6,8,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PanelSequenceDesign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PanelSequenceDesign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceDesign.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastModified: (f = msg.getLastModified()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanel.toObject, includeInstance),
    panelSequenceVersion: jspb.Message.getFieldWithDefault(msg, 7, 0),
    navigationButtonsList: jspb.Message.toObjectList(msg.getNavigationButtonsList(),
    proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    asyncValidationsList: jspb.Message.toObjectList(msg.getAsyncValidationsList(),
    Wilqo_API_Activity_Models_pb.AsyncValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign}
 */
proto.wilqo.api.activity.PanelSequenceDesign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PanelSequenceDesign;
  return proto.wilqo.api.activity.PanelSequenceDesign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PanelSequenceDesign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign}
 */
proto.wilqo.api.activity.PanelSequenceDesign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPanelSequenceVersion(value);
      break;
    case 8:
      var value = new proto.wilqo.api.activity.PanelSequenceDesignNavigationButton;
      reader.readMessage(value,proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.deserializeBinaryFromReader);
      msg.addNavigationButtons(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = new Wilqo_API_Activity_Models_pb.AsyncValidation;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.AsyncValidation.deserializeBinaryFromReader);
      msg.addAsyncValidations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PanelSequenceDesign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PanelSequenceDesign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceDesign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanel.serializeBinaryToWriter
    );
  }
  f = message.getPanelSequenceVersion();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getNavigationButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAsyncValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Wilqo_API_Activity_Models_pb.AsyncValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string template_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp last_modified = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getLastModified = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated wilqo.shared.models.DesignerPanel panels = 6;
 * @return {!Array<!proto.wilqo.shared.models.DesignerPanel>}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DesignerPanel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityAdminModels_pb.DesignerPanel, 6));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DesignerPanel>} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.shared.models.DesignerPanel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DesignerPanel}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.shared.models.DesignerPanel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};


/**
 * optional uint32 panel_sequence_version = 7;
 * @return {number}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getPanelSequenceVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setPanelSequenceVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated PanelSequenceDesignNavigationButton navigation_buttons = 8;
 * @return {!Array<!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton>}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getNavigationButtonsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.PanelSequenceDesignNavigationButton, 8));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton>} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setNavigationButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.addNavigationButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.wilqo.api.activity.PanelSequenceDesignNavigationButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.clearNavigationButtonsList = function() {
  return this.setNavigationButtonsList([]);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated AsyncValidation async_validations = 10;
 * @return {!Array<!proto.wilqo.api.activity.AsyncValidation>}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.getAsyncValidationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.AsyncValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.AsyncValidation, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.AsyncValidation>} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
*/
proto.wilqo.api.activity.PanelSequenceDesign.prototype.setAsyncValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.activity.AsyncValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AsyncValidation}
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.addAsyncValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.activity.AsyncValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesign} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesign.prototype.clearAsyncValidationsList = function() {
  return this.setAsyncValidationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PanelSequenceDesignNavigationButton;
  return proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceDesignNavigationButton} returns this
 */
proto.wilqo.api.activity.PanelSequenceDesignNavigationButton.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.AdminActivitySummary.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AdminActivitySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AdminActivitySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AdminActivitySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastPublish: (f = msg.getLastPublish()) && Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.toObject(includeInstance, f),
    skillSetsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AdminActivitySummary}
 */
proto.wilqo.api.activity.AdminActivitySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AdminActivitySummary;
  return proto.wilqo.api.activity.AdminActivitySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AdminActivitySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AdminActivitySummary}
 */
proto.wilqo.api.activity.AdminActivitySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityType(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp;
      reader.readMessage(value,Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.deserializeBinaryFromReader);
      msg.setLastPublish(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSets(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AdminActivitySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AdminActivitySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AdminActivitySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastPublish();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 entity_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_type = 4;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getActivityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional wilqo.shared.models.WilqoTimestamp last_publish = 5;
 * @return {?proto.wilqo.shared.models.WilqoTimestamp}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getLastPublish = function() {
  return /** @type{?proto.wilqo.shared.models.WilqoTimestamp} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp, 5));
};


/**
 * @param {?proto.wilqo.shared.models.WilqoTimestamp|undefined} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
*/
proto.wilqo.api.activity.AdminActivitySummary.prototype.setLastPublish = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.clearLastPublish = function() {
  return this.setLastPublish(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.hasLastPublish = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string skill_sets = 8;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getSkillSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setSkillSetsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.addSkillSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.clearSkillSetsList = function() {
  return this.setSkillSetsList([]);
};


/**
 * optional string business_process_template_id = 9;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string internal_name = 10;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivitySummary} returns this
 */
proto.wilqo.api.activity.AdminActivitySummary.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.repeatedFields_ = [14,30];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activityType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    panelSequenceDesign: (f = msg.getPanelSequenceDesign()) && proto.wilqo.api.activity.PanelSequenceDesign.toObject(includeInstance, f),
    lastPublish: (f = msg.getLastPublish()) && Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.toObject(includeInstance, f),
    activityTemplateId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    triggerRules: (f = msg.getTriggerRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.toObject(includeInstance, f),
    skillSetsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    lastSaved: (f = msg.getLastSaved()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSavedBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    lastSavedByDisplayName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    progressType: (f = msg.getProgressType()) && Wilqo_API_Activity_Models_pb.ProgressType.toObject(includeInstance, f),
    scopeReferencesList: jspb.Message.toObjectList(msg.getScopeReferencesList(),
    Wilqo_API_Activity_Models_pb.ScopeReference.toObject, includeInstance),
    helpText: jspb.Message.getFieldWithDefault(msg, 31, ""),
    expirationDateConfiguration: (f = msg.getExpirationDateConfiguration()) && Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.toObject(includeInstance, f),
    businessProcessExtraConfig: (f = msg.getBusinessProcessExtraConfig()) && proto.wilqo.api.activity.BusinessProcessExtraConfig.toObject(includeInstance, f),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 34, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AdminPanelSequenceDesignActivity;
  return proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityType(value);
      break;
    case 7:
      var value = new proto.wilqo.api.activity.PanelSequenceDesign;
      reader.readMessage(value,proto.wilqo.api.activity.PanelSequenceDesign.deserializeBinaryFromReader);
      msg.setPanelSequenceDesign(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp;
      reader.readMessage(value,Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.deserializeBinaryFromReader);
      msg.setLastPublish(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTemplateId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 13:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSets(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSaved(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSavedBy(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSavedByDisplayName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 19:
      var value = new Wilqo_API_Activity_Models_pb.ProgressType;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ProgressType.deserializeBinaryFromReader);
      msg.setProgressType(value);
      break;
    case 30:
      var value = new Wilqo_API_Activity_Models_pb.ScopeReference;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ScopeReference.deserializeBinaryFromReader);
      msg.addScopeReferences(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 32:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.deserializeBinaryFromReader);
      msg.setExpirationDateConfiguration(value);
      break;
    case 33:
      var value = new proto.wilqo.api.activity.BusinessProcessExtraConfig;
      reader.readMessage(value,proto.wilqo.api.activity.BusinessProcessExtraConfig.deserializeBinaryFromReader);
      msg.setBusinessProcessExtraConfig(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivityType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPanelSequenceDesign();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.wilqo.api.activity.PanelSequenceDesign.serializeBinaryToWriter
    );
  }
  f = message.getLastPublish();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getActivityTemplateId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator.serializeBinaryToWriter
    );
  }
  f = message.getSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getLastSaved();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSavedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLastSavedByDisplayName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getProgressType();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      Wilqo_API_Activity_Models_pb.ProgressType.serializeBinaryToWriter
    );
  }
  f = message.getScopeReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      30,
      f,
      Wilqo_API_Activity_Models_pb.ScopeReference.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getExpirationDateConfiguration();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessExtraConfig();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.wilqo.api.activity.BusinessProcessExtraConfig.serializeBinaryToWriter
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 12;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 entity_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activity_type = 4;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getActivityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional PanelSequenceDesign panel_sequence_design = 7;
 * @return {?proto.wilqo.api.activity.PanelSequenceDesign}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getPanelSequenceDesign = function() {
  return /** @type{?proto.wilqo.api.activity.PanelSequenceDesign} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.PanelSequenceDesign, 7));
};


/**
 * @param {?proto.wilqo.api.activity.PanelSequenceDesign|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setPanelSequenceDesign = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearPanelSequenceDesign = function() {
  return this.setPanelSequenceDesign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasPanelSequenceDesign = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional wilqo.shared.models.WilqoTimestamp last_publish = 8;
 * @return {?proto.wilqo.shared.models.WilqoTimestamp}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getLastPublish = function() {
  return /** @type{?proto.wilqo.shared.models.WilqoTimestamp} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp, 8));
};


/**
 * @param {?proto.wilqo.shared.models.WilqoTimestamp|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setLastPublish = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearLastPublish = function() {
  return this.setLastPublish(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasLastPublish = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string activity_template_id = 9;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getActivityTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setActivityTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string business_process_template_id = 11;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional wilqo.shared.rulesengine.RuleAggregator trigger_rules = 13;
 * @return {?proto.wilqo.shared.rulesengine.RuleAggregator}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.RuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb.RuleAggregator, 13));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.RuleAggregator|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated string skill_sets = 14;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getSkillSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setSkillSetsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.addSkillSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearSkillSetsList = function() {
  return this.setSkillSetsList([]);
};


/**
 * optional google.protobuf.Timestamp last_saved = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getLastSaved = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setLastSaved = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearLastSaved = function() {
  return this.setLastSaved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasLastSaved = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string last_saved_by = 16;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getLastSavedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setLastSavedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string last_saved_by_display_name = 17;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getLastSavedByDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setLastSavedByDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string internal_name = 18;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional ProgressType progress_type = 19;
 * @return {?proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getProgressType = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressType} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ProgressType, 19));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressType|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setProgressType = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearProgressType = function() {
  return this.setProgressType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasProgressType = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated ScopeReference scope_references = 30;
 * @return {!Array<!proto.wilqo.api.activity.ScopeReference>}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getScopeReferencesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ScopeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ScopeReference, 30));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ScopeReference>} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setScopeReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 30, value);
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.addScopeReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 30, opt_value, proto.wilqo.api.activity.ScopeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearScopeReferencesList = function() {
  return this.setScopeReferencesList([]);
};


/**
 * optional string help_text = 31;
 * @return {string}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional wilqo.shared.models.ExpirationDateConfiguration expiration_date_configuration = 32;
 * @return {?proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getExpirationDateConfiguration = function() {
  return /** @type{?proto.wilqo.shared.models.ExpirationDateConfiguration} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration, 32));
};


/**
 * @param {?proto.wilqo.shared.models.ExpirationDateConfiguration|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setExpirationDateConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearExpirationDateConfiguration = function() {
  return this.setExpirationDateConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasExpirationDateConfiguration = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional BusinessProcessExtraConfig business_process_extra_config = 33;
 * @return {?proto.wilqo.api.activity.BusinessProcessExtraConfig}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getBusinessProcessExtraConfig = function() {
  return /** @type{?proto.wilqo.api.activity.BusinessProcessExtraConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.BusinessProcessExtraConfig, 33));
};


/**
 * @param {?proto.wilqo.api.activity.BusinessProcessExtraConfig|undefined} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
*/
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setBusinessProcessExtraConfig = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.clearBusinessProcessExtraConfig = function() {
  return this.setBusinessProcessExtraConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.hasBusinessProcessExtraConfig = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional bool archived = 34;
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.AdminPanelSequenceDesignActivity} returns this
 */
proto.wilqo.api.activity.AdminPanelSequenceDesignActivity.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.BusinessProcessExtraConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.BusinessProcessExtraConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    completeProgressItem: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    allowMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    allowConcurrence: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.BusinessProcessExtraConfig}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.BusinessProcessExtraConfig;
  return proto.wilqo.api.activity.BusinessProcessExtraConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.BusinessProcessExtraConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.BusinessProcessExtraConfig}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteProgressItem(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstances(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowConcurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.BusinessProcessExtraConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.BusinessProcessExtraConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompleteProgressItem();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAllowMultipleInstances();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAllowConcurrence();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool complete_progress_item = 1;
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.getCompleteProgressItem = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.BusinessProcessExtraConfig} returns this
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.setCompleteProgressItem = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool allow_multiple_instances = 2;
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.getAllowMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.BusinessProcessExtraConfig} returns this
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.setAllowMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool allow_concurrence = 3;
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.getAllowConcurrence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.BusinessProcessExtraConfig} returns this
 */
proto.wilqo.api.activity.BusinessProcessExtraConfig.prototype.setAllowConcurrence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.AdminActivityMetaData.repeatedFields_ = [9,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AdminActivityMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AdminActivityMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AdminActivityMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    internalName: (f = msg.getInternalName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    activityType: (f = msg.getActivityType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastPublish: jspb.Message.getFieldWithDefault(msg, 5, 0),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    skillsetsIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    progressType: (f = msg.getProgressType()) && Wilqo_API_Activity_Models_pb.ProgressType.toObject(includeInstance, f),
    scopeReferencesList: jspb.Message.toObjectList(msg.getScopeReferencesList(),
    Wilqo_API_Activity_Models_pb.ScopeReference.toObject, includeInstance),
    businessProcessConfig: (f = msg.getBusinessProcessConfig()) && proto.wilqo.api.activity.BusinessProcessExtraConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData}
 */
proto.wilqo.api.activity.AdminActivityMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AdminActivityMetaData;
  return proto.wilqo.api.activity.AdminActivityMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AdminActivityMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData}
 */
proto.wilqo.api.activity.AdminActivityMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setInternalName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActivityType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastPublish(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetsIds(value);
      break;
    case 11:
      var value = new Wilqo_API_Activity_Models_pb.ProgressType;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ProgressType.deserializeBinaryFromReader);
      msg.setProgressType(value);
      break;
    case 12:
      var value = new Wilqo_API_Activity_Models_pb.ScopeReference;
      reader.readMessage(value,Wilqo_API_Activity_Models_pb.ScopeReference.deserializeBinaryFromReader);
      msg.addScopeReferences(value);
      break;
    case 13:
      var value = new proto.wilqo.api.activity.BusinessProcessExtraConfig;
      reader.readMessage(value,proto.wilqo.api.activity.BusinessProcessExtraConfig.deserializeBinaryFromReader);
      msg.setBusinessProcessConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AdminActivityMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AdminActivityMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AdminActivityMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getInternalName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActivityType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastPublish();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSkillsetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getProgressType();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_API_Activity_Models_pb.ProgressType.serializeBinaryToWriter
    );
  }
  f = message.getScopeReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Wilqo_API_Activity_Models_pb.ScopeReference.serializeBinaryToWriter
    );
  }
  f = message.getBusinessProcessConfig();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.wilqo.api.activity.BusinessProcessExtraConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 entity_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue title = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
*/
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue internal_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getInternalName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
*/
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setInternalName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearInternalName = function() {
  return this.setInternalName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.hasInternalName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue activity_type = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getActivityType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
*/
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setActivityType = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearActivityType = function() {
  return this.setActivityType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.hasActivityType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 last_publish = 5;
 * @return {number}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getLastPublish = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setLastPublish = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string business_process_template_id = 8;
 * @return {string}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string skillsets_ids = 9;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getSkillsetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setSkillsetsIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.addSkillsetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearSkillsetsIdsList = function() {
  return this.setSkillsetsIdsList([]);
};


/**
 * optional ProgressType progress_type = 11;
 * @return {?proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getProgressType = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressType} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Activity_Models_pb.ProgressType, 11));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressType|undefined} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
*/
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setProgressType = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearProgressType = function() {
  return this.setProgressType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.hasProgressType = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated ScopeReference scope_references = 12;
 * @return {!Array<!proto.wilqo.api.activity.ScopeReference>}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getScopeReferencesList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ScopeReference>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Activity_Models_pb.ScopeReference, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ScopeReference>} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
*/
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setScopeReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.addScopeReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.activity.ScopeReference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearScopeReferencesList = function() {
  return this.setScopeReferencesList([]);
};


/**
 * optional BusinessProcessExtraConfig business_process_config = 13;
 * @return {?proto.wilqo.api.activity.BusinessProcessExtraConfig}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.getBusinessProcessConfig = function() {
  return /** @type{?proto.wilqo.api.activity.BusinessProcessExtraConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.BusinessProcessExtraConfig, 13));
};


/**
 * @param {?proto.wilqo.api.activity.BusinessProcessExtraConfig|undefined} value
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
*/
proto.wilqo.api.activity.AdminActivityMetaData.prototype.setBusinessProcessConfig = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AdminActivityMetaData} returns this
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.clearBusinessProcessConfig = function() {
  return this.setBusinessProcessConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AdminActivityMetaData.prototype.hasBusinessProcessConfig = function() {
  return jspb.Message.getField(this, 13) != null;
};


goog.object.extend(exports, proto.wilqo.api.activity);
