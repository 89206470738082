import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useNavigate } from '@/Routes/NavigationContext';
import { fromString, toString } from '@/Utils/Helpers/dynamicDataElementHelper';

import Component from './Tile.component';

export interface TileProps {
  disabled?: boolean;
  headerText: string;
  id?: string;
  type?: string;
  linkTo?: string;
  externalLink?: string;
  thumbnail?: string;
  options?: Array<PanelElementOption.AsObject>;
  path?: string;
}

const Tile = (props: TileProps) => {
  const {
    disabled = false,
    externalLink = '',
    headerText,
    id = '',
    linkTo = '',
    options = [],
    path,
    thumbnail = '',

  } = props;

  const navigate = useNavigate();

  const [, setValue, { disabled: conditionallyDisabled, hidden }] = useDynamicFormRegister(id, { fromDynamic: toString, toDynamic: fromString });

  const handleOnClick = (id: string, path?: string) => {
    setValue(id, headerText, path, true);
    if (externalLink || linkTo) {
      navigate(externalLink || linkTo);
    }
  };

  if (hidden) return null;
  return (
    <Component
      disabled={disabled || conditionallyDisabled}
      id={id}
      label={headerText}
      onClick={handleOnClick}
      options={options?.map((option) => ({
        disabled: option.disabled,
        id: option.id,
        label: option.headerText,
        path: option.path,
        thumbnail: '',
      }))}
      path={path}
      thumbnail={thumbnail}
    />
  );
};

export default Tile;
