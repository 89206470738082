import { useMemo } from 'react';
import { useParams } from 'react-router';

import type { UpsertLenderConditionTemplateCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import { useLenderCondition } from '@/API/Queries/mortgageConfig/useLenderCondition';
import { useUpsertLenderCondition } from '@/API/Queries/mortgageConfig/useUpsertLenderCondition';
import { ChickenTest } from '@/Components/Features/ChickenTest';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useNavigateContext } from '@/Routes/NavigationContext';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { ConditionTemplate } from './ConditionTemplate';

interface ConditionDetailsProps {
  handlePublish: VoidFunction;
  chicken: boolean;
  setChicken: (bool: boolean) => void;
}

export const ConditionDetails = (props: ConditionDetailsProps) => {
  const { chicken, handlePublish, setChicken } = props;

  // libraries
  const { id = '' } = useParams();
  const { showSnackBar } = useShared();

  // queries and mutations
  const { data, isLoading, refetch } = useLenderCondition(id);
  const { isLoading: isSaving, mutate } = useUpsertLenderCondition();

  // memo
  const { prompts } = useNavigateContext();
  const prompt = useMemo(() => (prompts ? prompts['Condition Detail'] : undefined), [prompts]);

  // event handlers
  const handleSubmitSuccess = (response: UpsertLenderConditionTemplateCommandResponse.AsObject, close: VoidFunction) => {
    close();
    refetch();
  };

  const handleSubmitError = (error: Error) => {
    showSnackBar({ message: error.message, open: true });
  };

  const handleSubmit = (values: DynamicDataElementValues, close: VoidFunction, _?: string, cb?: VoidFunction) => {
    mutate({
      category: Number(values.category.value.id),
      conditionId: data?.entityId,
      description: values.description.value,
      internalName: values.internalName.value,
      partyType: Number(values.satisfactionResponsibleParty.value.id),
      timeframeType: Number(values.satisfactionTimeframe.value.id),
      title: values.title.value,
    }, {
      onError: handleSubmitError,
      onSuccess: (r) => handleSubmitSuccess(r, () => {
        close();
        if (cb) cb();
      }),
    });
  };

  const handleDiscard = () => {
    prompt?.actions[0].onClick(undefined, () => {
      setChicken(false);
      handlePublish();
    });
  };
  const handleSave = () => {
    prompt?.actions[1].onClick(undefined, () => {
      setChicken(false);
      handlePublish();
    });
  };

  return (
    <>
      <div className="p-12">
        <ConditionTemplate
          condition={data}
          handleSubmit={handleSubmit}
          isEdit
          isLoading={isLoading}
          isSaving={isSaving}
        />
      </div>
      <ChickenTest
        isSaving={isSaving}
        onClose={() => setChicken(false)}
        onDiscard={handleDiscard}
        onSave={handleSave}
        open={chicken}
      />
    </>
  );
};
