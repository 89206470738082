import clsx from 'clsx';
import type { HTMLProps } from 'react';
import React from 'react';

import { Slot } from '@/Utils/Helpers/uiBuilders';

export const DialogLayout = React.forwardRef<HTMLDivElement & HTMLElement, HTMLProps<HTMLDivElement> & { asChild?: boolean }>(
  ({ asChild, children, ...rest }, ref) => {
    const Component = asChild ? Slot : 'div';

    const layoutStyle = clsx(
      'w-full h-full transition-all duration-[250ms] grid grid-rows-[minmax(auto,auto)_1fr_auto] grid-cols-2',
      '[&:has(>:not([data-dialog-sidesheet]))]:tablet:grid-cols-[1fr_auto_0]',
      '[&:has(>[data-dialog-sidesheet])]:tablet:grid-cols-[1fr_auto_320px]',
    );

    return (
      <Component {...rest} ref={ref} className={layoutStyle}>
        {children}
      </Component>
    );
  },
);
