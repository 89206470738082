import type { Ref } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';

import type { FieldAttachment } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb';

import type { TextFieldVariant } from '../TextField';
import { TextField } from '../TextField';

export enum MaskType {
  CURRENCY,
  DATE,
  SSN,
  SSN9,
  PHONE,
  PERCENTAGE,
}

export interface ComponentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  isPrivate?: boolean;
  disabled?: boolean;
  label?: string;
  inputRef?: React.Ref<any>;
  hasValue?: string;
  leftIcon?: string;
  focused?: boolean;
  rightIcon?: string;
  helperText?: string;
  tooltipMessage?: string;
  error?: boolean;
  variant?: TextFieldVariant;
  mask?: MaskType;
  type?: 'number' | 'string';
  onChange?: (value: string) => void;
  attach?: FieldAttachment.AsObject;
  value?: string;
}

const Input = forwardRef<HTMLInputElement, ComponentProps>((props: ComponentProps, ref: Ref<HTMLInputElement>) => {
  const {
    className,
    disabled = false,
    error = false,
    focused,
    hasValue,
    helperText,
    isPrivate = false,
    label = '',
    leftIcon,
    mask,
    onChange,
    placeholder,
    required = false,
    rightIcon,
    tooltipMessage = '',
    type = 'string',
    value = '',
    variant = 'default',
    ...inputProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current!, []);

  const [showPassword, setShowPassword] = useState(!isPrivate);

  const toggleShowPassword = useCallback(() => setShowPassword((current) => !current), []);

  const getRightIcon = useCallback(() => {
    if (isPrivate) {
      const icon = showPassword ? 'VisibilityOff' : 'Visibility';

      return { icon, onClick: toggleShowPassword };
    }
    if (!isPrivate && rightIcon) return { icon: rightIcon, tooltipMessage };
    return undefined;
  }, [isPrivate, showPassword, toggleShowPassword, rightIcon, tooltipMessage]);

  const fieldProps = useMemo(() => ({
    className,
    disabled,
    error,
    focused,
    id: props.id,
    isPrivate: !showPassword,
    leadingIcon: leftIcon ? { icon: leftIcon, tooltipMessage } : undefined,
    maxLength: inputProps.maxLength,
    message: helperText,
    onBlur: inputProps?.onBlur,
    placeholder: `${label}${required ? '*' : ''}`,
    trailingIcon: getRightIcon(),
    value,
    variant,
  }), [
    className,
    disabled,
    error,
    focused,
    showPassword,
    leftIcon,
    helperText,
    inputProps?.onBlur,
    label,
    required,
    tooltipMessage,
    getRightIcon,
    value,
    variant,
    props.id,
    inputProps?.maxLength,
  ]);

  // useEffect(() => {
  //   const handleWheel = (event: WheelEvent) => {
  //     event.preventDefault();
  //   };
  //
  //   inputRef.current?.addEventListener('wheel', handleWheel, { passive: false });
  // }, []);

  const maskProps = useMemo(() => {
    if (mask === undefined) return {};
    switch (mask) {
      case MaskType.CURRENCY:
        return {
          placeholder: '$',
          prefix: '$',
          thousandSeparator: true,
        };
      case MaskType.DATE:
        return {
          format: '##/##/####',
          mask: ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'],
          placeholder: 'MM/DD/YYYY',
        };
      case MaskType.SSN:
        return {
          format: '####',
          mask: isPrivate ? '' : 'X',
          placeholder: 'XXXX',
        };
      case MaskType.SSN9:
        return {
          format: '###-##-####',
          mask: isPrivate ? '' : 'X',
          placeholder: 'XXX-XX-XXXX',
        };
      case MaskType.PHONE:
        return {
          format: '(###) ### - ####',
          mask: '#',
          placeholder: '(###) ### - ####',
        };
      case MaskType.PERCENTAGE:
        return {
          suffix: '%',
          thousandSeparator: true,
        };
      default:
        return {};
    }
  }, [mask, isPrivate]);

  if (mask !== undefined) {
    if ((type === 'string' && mask !== MaskType.CURRENCY) || mask === MaskType.PHONE) {
      return (
        <PatternFormat
          allowEmptyFormatting
          customInput={TextField}
          format={maskProps.format || ''}
          getInputRef={inputRef}
          mask={maskProps.mask}
          type={!showPassword ? 'password' : 'text'}
          {...fieldProps}
          onValueChange={(value) => {
            if (onChange) {
              if (mask === MaskType.DATE) {
                onChange(value.formattedValue === 'MM/DD/YYYY' ? '' : value.formattedValue);
              } else {
                onChange(value.value);
              }
            }
          }}
        />
      );
    }

    return (
      <NumericFormat
        customInput={TextField}
        getInputRef={inputRef}
        prefix={maskProps.prefix}
        suffix={maskProps.suffix}
        thousandSeparator={maskProps.thousandSeparator}
        type={!showPassword ? 'password' : 'text'}
        {...fieldProps}
        onValueChange={(value) => {
          if (onChange) onChange(value.value);
        }}
      />
    );
  }

  return (
    <TextField
      ref={inputRef}
      type={type}
      {...fieldProps}
      onChange={!onChange ? undefined : (e) => onChange(e.target.value)}
    />
  );
});

export default Input;
