import dayjs from 'dayjs';

const isDateValid = (value: string, dateFormat: string) => dayjs(value, dateFormat).format(dateFormat) === value;

const isRangeDateValid = (from: string, to: string) => {
  const dayjsFrom = dayjs(from);
  const dayjsTo = dayjs(to);
  return dayjsFrom.diff(dayjsTo) < 0;
};

const formatDate = (value: string) => dayjs(value).format('l');

const formatDateTime = (value: string) => dayjs(value).format('l LT');

const pluralizeDays = (days: number): string  => {
  return `${days} day${days === 1 ? '' : 's'}`;
}

const pluralizeMonths = (months: number): string => {
  return `${months} month${months === 1 ? '' : 's'}`;
}

export {
  formatDate,
  formatDateTime,
  isDateValid,
  isRangeDateValid,
  pluralizeDays,
  pluralizeMonths
};
