import { useCallback, useMemo } from 'react';

import { Card } from '@/Components/Atoms/Card/Card';
import { ContingencyMessage } from '@/Components/Atoms/ContingencyMessage';
import { DropdownMenu } from '@/Components/Atoms/DropdownMenu';
import { Icon } from '@/Components/Atoms/Icon';
import { ListItem } from '@/Components/Atoms/ListItem/ListItem';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Typography } from '@/Components/Atoms/Typography';

import type { TableProps } from '..';

export const MobileTable = <T extends Record<string, unknown>>(props: TableProps<T>) => {
  const { cardProps, columns, data, hoverActions = [], isLoading, onClickRow } = props;

  const getTableActions = useCallback((item: T, index: number) => {
    if (!hoverActions) return [];
    if (Array.isArray(hoverActions)) return hoverActions;
    return hoverActions(item, index || 0);
  }, [hoverActions]);

  const cols = useMemo(() => columns.filter((c) => !c.hidden).map((col, index) => {
    if (index === 0) return { ...col, mobile: 'label' };
    if (index === 1) return { ...col, mobile: 'overline' };
    if (index === 2) return { ...col, mobile: 'caption' };
    return col;
  }), [columns]);

  const onClickAction = useMemo(() => columns.find((col) => col.key.onClick)?.key?.onClick, [columns]);

  const { captionKey, labelKey, overlineKey } = useMemo(() => ({
    captionKey: cols.find((col) => col.mobile === 'caption'),
    labelKey: cols.find((col) => col.mobile === 'label'),
    overlineKey: cols.find((col) => col.mobile === 'overline'),
  }), [cols]);

  const handleClick = (item: any) => {
    if (onClickRow) onClickRow(item);
    if (onClickAction) onClickAction(item);
  };

  const renderItems = () => (
    <>
      {
        data.map((item, index) => {
          const overline = overlineKey?.label?.text ? item[overlineKey.label.text] as string : '';
          const label = labelKey?.key?.text ? item[labelKey.key.text] as string : '';
          const caption = captionKey?.key?.text ? item[captionKey.key.text] as string : '';
          const actions = getTableActions(item, index);
          return (
            <ListItem.Root key={label} onClick={() => handleClick(item)}>
              <ListItem.Content>
                {overline && <Typography variant="overline">{overline}</Typography>}
                {label && <Typography variant="body2">{label}</Typography>}
                {caption && <Typography variant="caption">{caption}</Typography>}
              </ListItem.Content>
              {actions.length > 0 && (
                <ListItem.Railing>
                  <DropdownMenu.Root modal>
                    <DropdownMenu.Trigger>
                      <Icon icon="MoreVert" />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      {getTableActions(item, index).map((action) => (
                        <DropdownMenu.Item
                          label={typeof action.label === 'string' ? action.label : action.label(item)}
                          onClick={() => action.onClick(item)}
                        />
                      ))}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </ListItem.Railing>
              )}
            </ListItem.Root>
          );
        })
      }
    </>
  );

  const renderContent = () => {
    if (isLoading) return <Skeleton variant="rect" />;
    if (data.length === 0) {
      return (
        <ContingencyMessage
          icon=""
          subtitle="No results founds for this query"
          title="No Results"
        />
      );
    }
    return renderItems();
  };

  return (
    <Card
      {...cardProps}
      defaultOpen
      headerProps={!cardProps.headerProps ? undefined : {
        ...cardProps.headerProps,
        contextualHeaderProps: undefined,
      }}
      variant="border"
    >
      {renderContent()}
    </Card>
  );
};
