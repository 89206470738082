// source: Wilqo.Shared.Models/WilqoPartyTypeMetadataConfig.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig', null, global);
goog.exportSymbol('proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomAddFlowType', null, global);
goog.exportSymbol('proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomEditFlowType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.displayName = 'proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    addDynamicPageConfigId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    editDynamicPageConfigId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isAddFromAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isEditReadOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    logicalScopeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    iteratorId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    partyIdVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    customAddFlow: jspb.Message.getFieldWithDefault(msg, 8, 0),
    customEditFlow: jspb.Message.getFieldWithDefault(msg, 9, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    displayOrder: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig;
  return proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddDynamicPageConfigId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditDynamicPageConfigId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAddFromAdmin(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEditReadOnly(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogicalScopeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIteratorId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyIdVirtualFieldId(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomAddFlowType} */ (reader.readEnum());
      msg.setCustomAddFlow(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomEditFlowType} */ (reader.readEnum());
      msg.setCustomEditFlow(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisplayOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddDynamicPageConfigId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEditDynamicPageConfigId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsAddFromAdmin();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsEditReadOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLogicalScopeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIteratorId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPartyIdVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCustomAddFlow();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getCustomEditFlow();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDisplayOrder();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomAddFlowType = {
  CUSTOM_ADD_FLOW_TYPE_UNKNOWN: 0,
  CUSTOM_ADD_FLOW_TYPE_ADD_BORROWER: 1
};

/**
 * @enum {number}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomEditFlowType = {
  CUSTOM_EDIT_FLOW_TYPE_UNKNOWN: 0,
  CUSTOM_EDIT_FLOW_TYPE_EDIT_BORROWER: 1
};

/**
 * optional string add_dynamic_page_config_id = 1;
 * @return {string}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getAddDynamicPageConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setAddDynamicPageConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string edit_dynamic_page_config_id = 2;
 * @return {string}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getEditDynamicPageConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setEditDynamicPageConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_add_from_admin = 3;
 * @return {boolean}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getIsAddFromAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setIsAddFromAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_edit_read_only = 4;
 * @return {boolean}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getIsEditReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setIsEditReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string logical_scope_id = 5;
 * @return {string}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getLogicalScopeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setLogicalScopeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string iterator_id = 6;
 * @return {string}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getIteratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setIteratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string party_id_virtual_field_id = 7;
 * @return {string}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getPartyIdVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setPartyIdVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional CustomAddFlowType custom_add_flow = 8;
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomAddFlowType}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getCustomAddFlow = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomAddFlowType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomAddFlowType} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setCustomAddFlow = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional CustomEditFlowType custom_edit_flow = 9;
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomEditFlowType}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getCustomEditFlow = function() {
  return /** @type {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomEditFlowType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.CustomEditFlowType} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setCustomEditFlow = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string display_name = 10;
 * @return {string}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 display_order = 11;
 * @return {number}
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.getDisplayOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig} returns this
 */
proto.wilqo.shared.models.WilqoPartyTypeMetadataConfig.prototype.setDisplayOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


goog.object.extend(exports, proto.wilqo.shared.models);
