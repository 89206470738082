import clsx from 'clsx';
import type { ReactNode } from 'react';
import { cloneElement, isValidElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

import { useNavigate } from '@/Routes/NavigationContext';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { Icon } from './Icon';
import { Surface } from './Surface';
import { Typography } from './Typography';

type NavDrawerItemVariant = 'rounded' | 'square';
export interface NavDrawerItemType {
  linkTo?: string;
  onClick?: () => void;
  icon?: ReactNode | string;
  label: string;
  submenuListList?: Array<NavDrawerItemType>;
  className?: string;
  selected?: boolean;
  opened?: boolean;
}

export interface NavDrawerItemProps {
  variant?: NavDrawerItemVariant;
  navigation: NavDrawerItemType;
  expanded?: boolean;
  toggleDrawer?: () => void;
  disabled?: boolean;
}

const NavDrawerItem = (props: NavDrawerItemProps) => {
  const {
    navigation: { icon, label, linkTo, className: classSubmenus = 'pl-12', submenuListList = [], onClick, selected, opened },
    toggleDrawer,
    variant = 'rounded',
    disabled = false,
    expanded,
  } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { windowType } = useWindow();

  const hasSubmenu = submenuListList.length > 0;
  const currentPath = pathname.substring(1);

  const getIsActive = () => {
    if (hasSubmenu) {
      return submenuListList.some((item) => item.linkTo === currentPath || item.selected);
    }
    return linkTo === currentPath || Boolean(selected);
  };
  const isActive = getIsActive();
  const [isOptionOpen, setIsOptionOpen] = useState(Boolean(expanded) || isActive);

  useEffect(() => {
    if (opened !== undefined) {
      setIsOptionOpen(opened);
    }
  }, [opened]);

  const heightAnimation = useSpring({
    config: { duration: 180 },
    maxHeight: isOptionOpen ? '1000px' : '0px',
  });

  const handleNavigate = (linkTo?: string) => {
    if (windowType === 'mobile' && toggleDrawer) toggleDrawer();
    navigate(`/${linkTo}`);
  };

  const handleClickSubmenu = (item: NavDrawerItemType) => {
    if (disabled) return;
    if (windowType === 'mobile' && toggleDrawer) toggleDrawer();
    if (item.linkTo) handleNavigate(item.linkTo);
    else if (item.onClick) item.onClick();
  };

  const handleClickParent = () => {
    if (hasSubmenu) {
      setIsOptionOpen(!isOptionOpen);
    } else if (linkTo) {
      handleNavigate(linkTo);
    } else if (onClick) {
      onClick();
    }
  };

  const isMenuActive = (hasSubmenu && isActive && !isOptionOpen) || (!hasSubmenu && isActive);

  const renderIcon = (icon?: ReactNode | string, selected?: boolean) => {
    if (typeof icon === 'string') {
      return (
        <Icon
          icon={icon}
          selected={selected}
        />
      );
    }

    if (isValidElement(icon)) {
      return cloneElement(icon, { selected } as any);
    }

    return undefined;
  };

  return (
    <>
      <Surface
        className={clsx(
          'items-center cursor-pointer flex gap-x-4 flex-initial h-10 my-1 mx-0 pl-[26px] relative no-underline w-[calc(100%-8px)]',
          {
            'rounded-br-full rounded-tr-full': variant === 'rounded',
          },
        )}
        data-testid="menu"
        onClick={handleClickParent}
        selected={isMenuActive}
      >
        {hasSubmenu && (
          <div className="absolute left-0">
            <Icon
              className={clsx({
                '!text-on-surface-inactive': !isMenuActive,
                '!text-primary-on-surface': isMenuActive,
              })}
              icon={isOptionOpen ? 'ArrowDropDown' : 'ArrowRight'}
              selected={isMenuActive}
            />
          </div>
        )}
        {renderIcon(icon, isMenuActive)}
        <Typography
          className={clsx({
            '!text-on-surface-inactive': !isMenuActive,
            '!text-primary-on-surface': isMenuActive,
          })}
          variant={isMenuActive ? 'body2Bold' : 'body2'}
        >
          {label}
        </Typography>
      </Surface>

      {
        hasSubmenu && (
          <animated.div className="overflow-hidden" style={heightAnimation}>
            {
              submenuListList.map((item) => {
                const isSubmenuActive = currentPath === item.linkTo || Boolean(item.selected);
                return (
                  <Surface
                    key={item.label}
                    className={clsx(
                      'items-center gap-x-4 cursor-pointer flex h-10 w-[calc(100%-8px)] py-1 box-border',
                      classSubmenus,
                      {
                        'rounded-br-full rounded-tr-full': variant === 'rounded',
                      },
                    )}
                    onClick={() => handleClickSubmenu(item)}
                    selected={isSubmenuActive}
                  >
                    { renderIcon(item.icon, isSubmenuActive) }
                    <Typography
                      className={clsx({
                        '!text-primary-on-surface': isSubmenuActive,
                        'text-on-surface-inactive': !isSubmenuActive,
                      })}
                      variant={isSubmenuActive ? 'body2Bold' : 'body2'}
                    >
                      {item.label}
                    </Typography>
                  </Surface>
                );
              })
            }
          </animated.div>
        )
      }
    </>
  );
};

export { NavDrawerItem };
