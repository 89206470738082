import type { SelectProps as ComponentProps } from './Select.component';
import Component from './Select.component';
import type { SelectProps as FormItemProps } from './Select.dde';
import DDE from './Select.dde';

interface ExtendedComponentsProps extends ComponentProps{
  displayOnly: true;
}

interface ExtendedFormItemProps extends FormItemProps {
  displayOnly: false;
}

export type SelectProps = ExtendedComponentsProps | ExtendedFormItemProps;

const Select = (props: SelectProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as FormItemProps} />;
};

export { Select };
