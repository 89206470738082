/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { ComponentProps } from './Tile.component';
import Component from './Tile.component';
import type { TileProps as DDEProps } from './Tile.dde';
import DDE from './Tile.dde';

interface ExtendedComponentsProps extends ComponentProps{
  displayOnly: true;
}

interface ExtendedFormItemProps extends DDEProps {
  displayOnly: false;
}

export type TileProps = ExtendedComponentsProps | ExtendedFormItemProps;

const Tile = (props: TileProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { Tile };
