import { lazy, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { SnackBar } from '@/Components/Atoms/Snackbar';
import { ActivityTrigger } from '@/Routes/Pages/admin/activityTemplates/configurator/ActivityTrigger';
import { ActivityAssignment } from '@/Routes/Pages/admin/activityTemplates/configurator/assignment';
import { ActivityConfiguratorContent } from '@/Routes/Pages/admin/activityTemplates/configurator/content/content';
import { ActivityList } from '@/Routes/Pages/admin/activityTemplates/list/activitiesList';
import { BusinessProcessTemplateTriggers } from '@/Routes/Pages/admin/businessProcessTemplates/BusineesProcessTemplateTriggers';
import { BusinessProcessTemplatePage } from '@/Routes/Pages/admin/businessProcessTemplates/BusinessProcessTemplate';
import { BusinessProcessTemplateDetails } from '@/Routes/Pages/admin/businessProcessTemplates/BusinessProcessTemplateDetails';
import { BusinessProcessTemplatesList } from '@/Routes/Pages/admin/businessProcessTemplates/BusinessProcessTemplatesList';
import { Condition } from '@/Routes/Pages/admin/conditions/Condition';
import { Conditions } from '@/Routes/Pages/admin/conditions/Conditions';
import { DocumentCategoriesList } from '@/Routes/Pages/admin/documentCategories/DocumentCategoryList';
import { FeeTemplateDetails } from '@/Routes/Pages/admin/feeTemplate/FeeTemplateConfig/Details';
import { FeeTemplateTrigger } from '@/Routes/Pages/admin/feeTemplate/FeeTemplateConfig/Trigger';
import { LoanFeeTemplates } from '@/Routes/Pages/admin/feeTemplate/FeeTemplateList';
import { LeadAndReferralSourcesList } from '@/Routes/Pages/admin/leadAndReferralSources/leadAndReferralSourcesList';
import { LoanOverviewConfiguration } from '@/Routes/Pages/admin/lists/loanOverviewConfigurations';
import { LoanPageConfiguration } from '@/Routes/Pages/admin/lists/loanPageConfiguration';
import { PageConfigurationList } from '@/Routes/Pages/admin/lists/pageConfiguration';
import { QuestionnaireList } from '@/Routes/Pages/admin/lists/questionnaires';
import { ValidationChecklists } from '@/Routes/Pages/admin/lists/validationChecklists';
import { OrganizationLicense } from '@/Routes/Pages/admin/organizations/OrganizationLicenses';
import { OrganizationOverview } from '@/Routes/Pages/admin/organizations/OrganizationOverview';
import { OrganizationsList } from '@/Routes/Pages/admin/organizations/organizationsList';
import { OrganizationUsers } from '@/Routes/Pages/admin/organizations/OrganizationUsers';
import { SkillsetActivities } from '@/Routes/Pages/admin/skillset/SkillsetActivities';
import { SkillsetList } from '@/Routes/Pages/admin/skillset/SkillsetList';
import { SkillsetOverview } from '@/Routes/Pages/admin/skillset/SkillsetOverview';
import { SkillsetSkillsetGroups } from '@/Routes/Pages/admin/skillset/SkillsetSkillsetGroups';
import { UpdateSkillset } from '@/Routes/Pages/admin/skillset/UpdateSkillset';
import { SkillsetGroupsList } from '@/Routes/Pages/admin/skillsetGroup/SkillsetGroupList';
import { SkillsetGroupOverview } from '@/Routes/Pages/admin/skillsetGroup/SkillsetGroupOverview';
import { SkillsetGroupSkillsets } from '@/Routes/Pages/admin/skillsetGroup/SkillsetGroupSkillsets';
import { SkillsetGroupUsers } from '@/Routes/Pages/admin/skillsetGroup/SkillsetGroupUsers';
import { UpdateSkillsetGroup } from '@/Routes/Pages/admin/skillsetGroup/UpdateSkillsetGroup';
import { UserList } from '@/Routes/Pages/admin/user/list/userList';
import { LeadSources } from '@/Routes/Pages/admin/user/updateUser/LeadSources';
import { UpdateUser } from '@/Routes/Pages/admin/user/updateUser/UpdateUser';
import { UserLicense } from '@/Routes/Pages/admin/user/updateUser/UserLicense';
import { UserLoanAccess } from '@/Routes/Pages/admin/user/updateUser/UserLoanAccess';
import { UserOrgs } from '@/Routes/Pages/admin/user/updateUser/UserOrgs';
import { UserOverview } from '@/Routes/Pages/admin/user/updateUser/UserOverview';
import { UserPartyRoles } from '@/Routes/Pages/admin/user/updateUser/UserPartyRoles';
import { UserSkillsetGroups } from '@/Routes/Pages/admin/user/updateUser/UserSkillsetGroups';
import { Invite } from '@/Routes/Pages/consumer/Invite';
import { ConsumerAuthCallback } from '@/Routes/Pages/ConsumerAuthCallback';
import { Handoff } from '@/Routes/Pages/Handoff';
import { ChecklistCompleteTab } from '@/Routes/Pages/loan/activities/checklists/checklist/tabs/checklistCompleteTab';
import { ChecklistIncompleteTab } from '@/Routes/Pages/loan/activities/checklists/checklist/tabs/checklistIncompleteTab';
import { ValidationCompleteTab } from '@/Routes/Pages/loan/activities/checklists/validation/tabs/validationCompleteTab';
import { ValidationIncompleteTab } from '@/Routes/Pages/loan/activities/checklists/validation/tabs/validationIncompleteTab';
import { DynamicLoanPages } from '@/Routes/Pages/loan/DynamicLoanPages';
import { ChangeHistoryDetails } from '@/Routes/Pages/loan/PurposeBuilt/ChangeHistoryDetails';
import { ComplianceDetails } from '@/Routes/Pages/loan/PurposeBuilt/Compliance/ComplianceDetails';
import { Fees } from '@/Routes/Pages/loan/PurposeBuilt/Fees';
import { FraudDetails } from '@/Routes/Pages/loan/PurposeBuilt/Fraud/FraudDetails';
import { IntentToProceedDetails } from '@/Routes/Pages/loan/PurposeBuilt/IntentToProceedDetails';
import { LoanActivities } from '@/Routes/Pages/loan/PurposeBuilt/LoanActivities';
import { NotFound } from '@/Routes/Pages/NotFound';
import { Questionnaire } from '@/Routes/Pages/questionnaire/Questionnaire';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { CredentialManager } from './Pages/admin/credentialManager/CredentialManager';
import { ProcessInformation } from './Pages/loan/ProcessInformation/ProcessInformation';
import {
  PrivateActivityPerformance,
  PrivateActivityTemplateConfigurator,
  PrivateAdminList,
  PrivateChecklist,
  PrivateCreateLoan,
  PrivateFeeTemplateConfig,
  PrivateHome,
  PrivateLoanPage,
  PrivateManageDocuments,
  PrivateMultipleDocumentView,
  PrivateOrganization,
  PrivatePanelSequence,
  PrivateReset,
  PrivateRouteWrapper,
  PrivateSeedDeals,
  PrivateValidation,
} from './privateRoute';

const Login = lazy(() => import('@/Routes/Pages/Login'));

const AppRoutes = () => {
  const { snackBar } = useShared();
  const renderSnackBar = useMemo(() => {
    if (!snackBar.open) return null;
    const { buttonLabel, message, onClick } = snackBar;
    return (
      <SnackBar buttonLabel={buttonLabel} message={message} onClick={onClick} />
    );
  }, [snackBar]);

  return (
    <>
      {renderSnackBar}
      <Routes>
        <Route element={<Login />} index />
        <Route element={<Login />} path="/login" />
        <Route element={<PrivateRouteWrapper><PrivateReset /></PrivateRouteWrapper>} path="reset" />
        <Route element={<PrivateRouteWrapper><PrivateSeedDeals /></PrivateRouteWrapper>} path="seed-deals" />

        {/* loan pages */}
        <Route element={<PrivateRouteWrapper><PrivateCreateLoan /></PrivateRouteWrapper>} path="loan/create" />
        <Route element={<PrivateRouteWrapper><PrivateLoanPage /></PrivateRouteWrapper>} path="loan/:loanId">
          <Route element={<DynamicLoanPages />} path="dynamic-page" />
          <Route element={<LoanActivities />} path="activities" />
          <Route element={<ChangeHistoryDetails />} path="events/:dealVersion" />
          <Route element={<Fees />} path="progress/fees/:progressId" />
          <Route element={<IntentToProceedDetails />} path="progress/intent-to-proceed/:progressId" />
          <Route element={<FraudDetails />} path="progress/fraud" />
          <Route element={<ComplianceDetails />} path="progress/compliance" />
        </Route>
        <Route element={<PrivateManageDocuments />} path="loan/:loanId/manage-docs" />
        <Route element={<PrivateMultipleDocumentView />} path="loan/:loanId/document-view" />

        <Route element={<PrivateRouteWrapper><PrivateHome /></PrivateRouteWrapper>} path="home" />
        <Route element={<PrivateOrganization />} path="organization/:orgId">
          <Route element={<OrganizationOverview />} path="overview" />
          <Route element={<OrganizationLicense />} path="license" />
          <Route element={<OrganizationUsers />} path="users" />
        </Route>
        <Route element={<PrivateRouteWrapper><PrivateChecklist /></PrivateRouteWrapper>} path="activity/checklist/:activityId">
          <Route element={<ChecklistIncompleteTab />} path="checklist/incomplete" />
          <Route element={<ChecklistCompleteTab />} path="checklist/complete" />
        </Route>
        <Route
          element={(
            <PrivateRouteWrapper>
              <PrivateValidation />
            </PrivateRouteWrapper>
          )}
          path="activity/validation/:activityId"
        >
          <Route element={<ValidationIncompleteTab />} path="checklist/incomplete" />
          <Route element={<ValidationCompleteTab />} path="checklist/complete" />
        </Route>
        <Route
          element={<PrivateRouteWrapper><PrivateActivityTemplateConfigurator /></PrivateRouteWrapper>}
          path="admin/activity/:activityId/configurator"
        >
          <Route element={<ActivityConfiguratorContent />} path="content" />
          <Route element={<ActivityTrigger />} path="triggers" />
          <Route element={<ActivityAssignment />} path="assignments" />
        </Route>
        <Route
          element={<PrivateRouteWrapper><Condition /></PrivateRouteWrapper>}
          path="/admin/lenderCondition/:id/configurator"
        />
        <Route
          element={<PrivateRouteWrapper><PrivateFeeTemplateConfig /></PrivateRouteWrapper>}
          path="admin/loanFeeTemplate"
        >
          <Route element={<FeeTemplateDetails />} path="details" />
          <Route element={<FeeTemplateTrigger />} path="triggers" />
        </Route>

        <Route
          element={(
            <PrivateRouteWrapper>
              <PrivatePanelSequence />
            </PrivateRouteWrapper>
          )}
          path="activity/panel-sequence/:activityId"
        />
        <Route
          element={<PrivateRouteWrapper><PrivateActivityPerformance /></PrivateRouteWrapper>}
          path="admin/activity/:activityId/performance"
        />
        <Route element={<PrivateRouteWrapper><UpdateSkillsetGroup /></PrivateRouteWrapper>} path="admin/skillsetGroup/:id">
          <Route element={<SkillsetGroupOverview />} path="overview" />
          <Route element={<SkillsetGroupSkillsets />} path="skillsets" />
          <Route element={<SkillsetGroupUsers />} path="users" />
        </Route>
        <Route element={<PrivateRouteWrapper><UpdateSkillset /></PrivateRouteWrapper>} path="admin/skillset/:id">
          <Route element={<SkillsetOverview />} path="overview" />
          <Route element={<SkillsetSkillsetGroups />} path="skillsetGroups" />
          <Route element={<SkillsetActivities />} path="activities" />
        </Route>
        <Route element={<PrivateRouteWrapper><BusinessProcessTemplatePage /></PrivateRouteWrapper>} path="admin/businessProcessTemplate/:id">
          <Route element={<BusinessProcessTemplateDetails />} path="details" />
          <Route element={<BusinessProcessTemplateTriggers />} path="trigger" />
        </Route>
        <Route element={<PrivateRouteWrapper><UpdateUser /></PrivateRouteWrapper>} path="admin/user/:id">
          <Route element={<UserOverview />} path="overview" />
          <Route element={<UserLicense />} path="licensing" />
          <Route element={<UserSkillsetGroups />} path="skillsetGroups" />
          <Route element={<LeadSources />} path="leadSources" />
          <Route element={<UserOrgs />} path="organizations" />
          <Route element={<UserPartyRoles />} path="partyRoles" />
          <Route element={<UserLoanAccess />} path="loanAccess" />
        </Route>
        <Route element={<PrivateRouteWrapper><PrivateAdminList /></PrivateRouteWrapper>} path="admin/list">
          <Route element={<DocumentCategoriesList />} path="documentCategories" />
          <Route element={<ActivityList />} path="activity" />
          <Route element={<SkillsetGroupsList />} path="skillsetGroup" />
          <Route element={<SkillsetList />} path="skillset" />
          <Route element={<UserList />} path="user" />
          <Route element={<PageConfigurationList />} path="pageConfiguration" />
          <Route element={<QuestionnaireList />} path="questionnaires" />
          <Route element={<CredentialManager />} path="credentialManager" />
          <Route element={<LoanPageConfiguration />} path="loanPages" />
          <Route element={<OrganizationsList />} path="organizations" />
          <Route element={<Conditions />} path="lenderConditions" />
          <Route element={<LoanOverviewConfiguration />} path="loanOverview" />
          <Route element={<ValidationChecklists />} path="validationChecklists" />
          <Route element={<LoanFeeTemplates />} path="loanFeeTemplates" />
          <Route element={<LeadAndReferralSourcesList />} path="leadAndReferralSources" />
          <Route element={<BusinessProcessTemplatesList />} path="businessProcessTemplates" />
        </Route>
        <Route element={<PrivateRouteWrapper><ConsumerAuthCallback /></PrivateRouteWrapper>} path="consumerCallback" />
        <Route element={<Questionnaire />} path="questionnaire">
          <Route element={<Questionnaire />} path=":panelId" />
        </Route>
        <Route element={<Handoff />} path="handoff" />
        <Route element={<Invite />} path="invite/:id" />
        <Route element={<PrivateRouteWrapper><ProcessInformation /></PrivateRouteWrapper>} path="processInformation/:dealId" />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </>
  );
};

export { AppRoutes };
