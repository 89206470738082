import { useCreateSkillsetGroup } from '@/API/Queries/user/useCreateSkillsetGroup';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { getPanel, getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface CreateSkillsetGroupProps {
  closeModal: () => void;
}

export const CreateSkillsetGroup = (props: CreateSkillsetGroupProps) => {
  const { closeModal } = props;
  const { showSnackBar } = useShared();
  const { isLoading, mutate: createSkillsetGroup } = useCreateSkillsetGroup();

  const handleSubmitSuccess = () => {
    showSnackBar({ message: 'Skillset Group created', open: true });
    closeModal();
  };

  const onSubmit = (data: DynamicDataElementValues) => {
    const {
      description: { value: description },
      skillsetGroupName: { value: skillsetGroupName },
    } = data;
    const createObj = {
      displayName: skillsetGroupName,
      longDescription: description,
      skillsetIds: [],
      userIds: [],
    };
    createSkillsetGroup(createObj, { onSuccess: handleSubmitSuccess });
  };

  return (
    <FullPageStepper
      close={closeModal}
      handleSubmit={onSubmit}
      headerAction={{
        label: 'Discard',
        leftIcon: 'Delete',
        onClick: closeModal,
        variant: 'tertiary',
      }}
      openModal
      panels={[
        getPanel({
          headerText: 'Name & Description',
          id: 'name-description',
          panelElementsList: [
            getPanelElement({
              headerText: 'Skillset Group Name',
              id: 'skillsetGroupName',
              requirement: getPanelElementRequiment('Skillset Group name is required'),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Description',
              id: 'description',
              requirement: getPanelElementRequiment('Description is required'),
              type: 'text',
            }),
          ],
          summaryText: 'Enter a name and description of the skillset group',
        }),
      ]}
      saving={isLoading}
      title="Create new Skillset Group"
    />
  );
};
