import { useMemo } from 'react';

import type { ShowFieldValidation } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { ShowFieldValidationActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';

import { getValidConditionsTargetIds } from './conditionalValidation';
import type { DynamicDataElementValues } from './DynamicFormContext';

interface ConditionalFieldObject {
  hiddenFieldIds: string[];
  disabledFieldIds: string[];
  hiddenWidgetIds: string[];
  hiddenHeaderActionIds: string[];
}

export const getConditionalFieldIds = (
  conditions: Array<ShowFieldValidation.AsObject>,
  values: DynamicDataElementValues,
): ConditionalFieldObject => {
  if (conditions) {
    const disabledFieldIds = getValidConditionsTargetIds(
      conditions.filter((condition) => condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_DISABLED),
      values,
    );
    const hiddenFieldIds = getValidConditionsTargetIds(
      conditions.filter((condition) => condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN
        || condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_UNKNOWN || condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN_IGNORE_ON_SAVE),
      values,
    );
    const hiddenWidgetIds = [
      ...getValidConditionsTargetIds(
        conditions.filter((condition) => (condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN)
          || condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_UNKNOWN),
        values, 'widgetElement',
      ),
    ];
    const hiddenHeaderActionIds = getValidConditionsTargetIds(
      conditions.filter((condition) => condition.actionType === ShowFieldValidationActionType.SHOW_FIELD_VALIDATION_ACTION_TYPE_HIDDEN),
      values, 'headerAction',
    );

    return {
      disabledFieldIds,
      hiddenFieldIds,
      hiddenHeaderActionIds,
      hiddenWidgetIds,
    };
  }
  return { disabledFieldIds: [], hiddenFieldIds: [], hiddenHeaderActionIds: [], hiddenWidgetIds: [] };
};

export const useConditionalFields = (
  conditions: Array<ShowFieldValidation.AsObject>,
  values: DynamicDataElementValues,
) => useMemo(() => getConditionalFieldIds(conditions, values), [conditions, values]);
