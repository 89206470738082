import { Typography } from '../Typography';

interface MenuHeaderProps {
  title: string;
  children?: React.ReactNode;
}

export const MenuHeader = (props: MenuHeaderProps) => (
  <div className="flex flex-row justify-between py-3 px-4 items-center">
    <Typography variant="body1Bold">{props.title}</Typography>
    {props.children}
  </div>
);
