import { useMemo, useState } from 'react';

import { useSkillsetGroups } from '@/API/Queries/user/useSkillsetGroups';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useNavigate } from '@/Routes/NavigationContext';
import { normalizeString } from '@/Utils/Helpers/normalizeString';

import { CreateSkillsetGroup } from './CreateSkillsetGroup';

interface TableItemData {
  displayName: string;
  id: string;
  longDescription: string;
  skillsetsCount: number;
  usersCount: number;
}

const SkillsetGroupListComponent = () => {
  const navigate = useNavigate();
  const { data: skillsetGroups = [], isLoading, refetch } = useSkillsetGroups();

  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleSkillsetGroupsRoute = (skillsetGroup: TableItemData) => {
    navigate(`/admin/skillsetGroup/${skillsetGroup.id}/overview`);
  };

  const handleNewUserRoute = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    refetch();
    setModalOpen(false);
  };

  const columns = useMemo(
    (): Array<Column<TableItemData>> => [
      {
        header: 'Name',
        id: 'displayName',
        key: { text: 'displayName', type: 'text' },
      },
      {
        header: 'Description',
        id: 'description',
        key: { text: 'longDescription', type: 'text' },
      },
      {
        header: 'Skillsets',
        id: 'skillsets',
        key: { text: 'skillsetsCount', type: 'text' },
      },
      {
        header: 'Users',
        id: 'usersCount',
        key: { text: 'usersCount', type: 'text' },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let items = skillsetGroups.map((skillsetGroup) => ({
      displayName: skillsetGroup.displayName,
      id: skillsetGroup.id,
      longDescription: skillsetGroup.longDescription,
      skillsetsCount: skillsetGroup.skillsetsCount,
      usersCount: skillsetGroup.usersCount,
    }));
    if (search) {
      items = items
        .filter(
          (item) => normalizeString(item.displayName).includes(normalizeString(search))
          || normalizeString(item.longDescription).includes(normalizeString(search)),
        );
    }

    return items;
  }, [skillsetGroups, search]);

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            actions: [{ label: 'Create new', onClick: handleNewUserRoute }],
            searchProps: { onChange: setSearch, value: search },
            title: 'All Skillset Groups',
          },
        }}
        columns={columns}
        data={data}
        isLoading={isLoading}
        onClickRow={handleSkillsetGroupsRoute}
      />
      { modalOpen && <CreateSkillsetGroup closeModal={closeModal} />}
    </>
  );
};

export const SkillsetGroupsList = SkillsetGroupListComponent;
