import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';

import type { EditableTableColumnSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/EditableTable_pb';
import { Button } from '@/Components/Atoms/Button';
import { Card } from '@/Components/Atoms/Card/Card';
import { Icon } from '@/Components/Atoms/Icon';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { DynamicDataElementMap } from '@/Utils/Helpers/dynamicDataElementHelper';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import { getDynamicDialogAction } from '../../actions/DynamicDialogAction';
import type { SaveConfig } from '../useSaveWidget';
import { useWidgetContext } from '../WidgetContext';
import type { Props } from '.';

export type RowWarning = {
  rowIndex?: number;
  message: string;
  icon: string;
};

export const DesktopEditableTable = ({
  columnsList,
  handleDelete,
  id,
  newItemLabel,
  newItemScopeToken,
  rowsList,
  title,
}: Props) => {
  const { fieldHasChanged, imperativeSubmit, resetProgress, values: formValues } = useDynamicForm();
  const { loanId } = useDynamicLoanInfo();
  const { pageId = '' } = useWidgetContext();
  const { toggleAction } = useDynamicContext();

  const [editIndexRow, setEditIndexRow] = useState<number>();
  const [addNewRow, setAddNewRow] = useState<boolean>(false);

  const isEditing = useMemo(() => editIndexRow !== undefined, [editIndexRow]);

  const hasChanged = useMemo(() => {
    if (isEditing || addNewRow) {
      return Object.keys(formValues).some((key) => key.split('@')[2] === id && fieldHasChanged(key));
    }
    return false;
  }, [isEditing, formValues, addNewRow, fieldHasChanged, id]);

  const saveConfig = useMemo((): SaveConfig => ({
    isNew: addNewRow,
    loanId,
    pageId,
    scopeToken: '',
    type: 'editableTable',
    widgetId: id,
  }), [addNewRow, id, loanId, pageId]);

  const optimisticRowUpdate = useCallback((add: boolean) => {
    const resetEdit = () => {
      setEditIndexRow(undefined);
    };
    const resetAdd = () => {
      setAddNewRow(false);
    };
    if (hasChanged) {
      imperativeSubmit(add ? resetAdd : resetEdit, JSON.stringify(saveConfig));
    }
  }, [hasChanged, imperativeSubmit, saveConfig]);

  const handleAddNewRow = () => {
    if (isEditing || addNewRow) {
      optimisticRowUpdate(true);
    } else {
      setAddNewRow(true);
      setEditIndexRow(undefined);
    }
  };

  const handleEditMode = useCallback((rowIndex: number) => {
    if (isEditing || addNewRow) {
      optimisticRowUpdate(false);
    } else {
      const values = rowsList[rowIndex].rowDataMap.reduce((values, cell) => ({
        ...values,
        [`editable@edit@${id}@${rowsList[rowIndex].rowScopeToken}@${cell[1].columnId}`]: {
          dynamic: cell[1].editableData?.value ? DynamicDataElementMap(cell[1].editableData.value) : undefined,
        },
      }), {} as DynamicDataElementValues);
      const oldValues = Object.keys(formValues).reduce((acc, key) => {
        if (key.match(/^[^@]+@[^@]+@[^@]+@[^@]+@[^@]+$/)) {
          return acc;
        }
        return { ...acc, [key]: formValues[key] };
      }, {} as DynamicDataElementValues);
      resetProgress({ values: { ...values, ...oldValues } }, true);
      setEditIndexRow(rowIndex);
      setAddNewRow(false);
    }
  }, [isEditing, addNewRow, optimisticRowUpdate, rowsList, formValues, resetProgress, id]);

  const rows = useMemo(() => rowsList.reduce((prev: Array<any>, currentRow) => [
    ...prev,
    currentRow.rowDataMap.reduce((prevData, [colId, cellModel]) => ({ ...prevData, [colId]: cellModel }), {
      scopeToken: currentRow.rowScopeToken,
    }),
  ], []), [rowsList]);

  const renderTableCell = (rowIndex: number, row: any, col: EditableTableColumnSettings.AsObject) => {
    if (editIndexRow !== rowIndex) {
      const cell = row[col.columnId];
      if (cell.detailPageId) {
        return (
          <button
            onClick={() => {
              toggleAction(getDynamicDialogAction(
                {
                  mode: 'read',
                  pageId: cell.detailPageId,
                  scopeToken: cell.detailPageScopeToken,
                  withoutData: false,
                },
              ));
            }}
            type="button"
          >
            <Typography className="text-primary-on-surface" variant="body2">
              {renderValue(row[col.columnId].displayData, undefined, col.panelElement)}
            </Typography>
          </button>
        );
      }
      return (
        <Typography variant="body2">
          {renderValue(row[col.columnId].displayData, undefined, col.panelElement)}
        </Typography>
      );
    }
    if (col.panelElement) {
      return (
        <PanelElementRenderer
          {...col.panelElement}
          id={`editable@edit@${id}@${row.scopeToken}@${col.columnId}`}
          variant="dense-variant"
        />
      );
    }
    return null;
  };

  return (
    <Card
      disableAccordion
      headerProps={{ title }}
      variant="border"
    >
      {
        rows.length === 0 && !addNewRow ? (
          <div className="bg-surface-variant flex items-center justify-center p-4 m-4 rounded">
            <Typography className="text-status-on-status" variant="body2">
              No items added yet.
            </Typography>
          </div>
        ) : (
          <table
            cellPadding={0}
            cellSpacing={0}
            className="w-full border-collapse !border-spacing-0"
          >
            <thead className="border-b border-b-on-surface-stroke">
              <tr role="row">
                {columnsList.map((col) => col.title && !col.hidden && (
                  <th key={col.columnId} align="left" className="px-4 py-3">
                    <Typography variant="overline">{col.title}</Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>

              {
                rows.map((row, rowIndex) => (
                  <tr className="relative">
                    {columnsList.map((col) => {
                      if (row[col.columnId].displayData && !col.hidden) {
                        return (
                          <td
                            key={`row-${row[col.columnId]}-${col.title}`}
                            className={clsx({
                              'p-2': editIndexRow === rowIndex,
                              'p-4': editIndexRow !== rowIndex,
                            })}
                            id={row[col.columnId]}
                          >
                            {renderTableCell(rowIndex, row, col)}
                          </td>
                        );
                      }
                      if (col.hidden && col.panelElement) {
                        return (
                          <PanelElementRenderer
                            {...col.panelElement}
                            hidden
                            id={`editable@edit@${id}@${row.scopeToken}@${col.columnId}`}
                            variant="dense-variant"
                          />
                        );
                      }
                      return null;
                    })}
                    <td align="right">
                      {editIndexRow === rowIndex ? (
                        <div className="flex flex-row gap-2 p-2 items-center">
                          <Button label="Cancel" onClick={() => setEditIndexRow(undefined)} variant="tertiary" />
                          <Button additionalData={JSON.stringify(saveConfig)} label="Save" onClick={() => optimisticRowUpdate(false)} variant="tertiary" />
                        </div>
                      ) : (
                        <div className="flex flex-row p-2 justify-end">
                          <Icon
                            icon="Edit"
                            onClick={() => handleEditMode(rowIndex)}
                            variant="interactive"
                          />
                          <Icon
                            icon="Delete"
                            onClick={() => handleDelete(row.scopeToken)}
                            variant="interactive"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              }
              {
                addNewRow && (
                  <tr>
                    {
                      columnsList.map((col) => {
                        if (col.panelElement) {
                          if (!col.hidden) {
                            return (
                              <td
                                key={`columnsList-${col.columnId}-${col.title}`}
                                className="p-2"
                              >
                                <PanelElementRenderer
                                  key={`columnsList-${id}@${newItemScopeToken}@${col.columnId}`}
                                  {...col.panelElement}
                                  id={`editable@new@${id}@${newItemScopeToken}@${col.columnId}`}
                                  variant="dense-variant"
                                />
                              </td>
                            );
                          }
                          return (
                            <PanelElementRenderer
                              key={`columnsList-${id}@${newItemScopeToken}@${col.columnId}`}
                              hidden
                              {...col.panelElement}
                              id={`editable@new@${id}@${newItemScopeToken}@${col.columnId}`}
                            />
                          );
                        }
                        return null;
                      })
                    }
                    <td className="flex flex-row gap-2 p-2 justify-end items-center">
                      <Button label="Cancel" onClick={() => setAddNewRow(false)} variant="tertiary" />
                      <Button label="Save" onClick={() => optimisticRowUpdate(true)} variant="tertiary" />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        )
      }
      {/* footer */}
      <div className="w-full px-4 py-[18px] border-t border-t-on-surface-stroke">
        <Button
          label={newItemLabel || 'Add new'}
          onClick={handleAddNewRow}
          variant="tertiary"
        />
      </div>
    </Card>
  );
};
