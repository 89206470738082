import parse from 'html-react-parser';
import type { ReactNode } from 'react';

const convertRichText = (text: ReactNode | string) => {
  if (!text) return '';
  if (typeof text === 'string') return parse(text);
  return text;
};

export { convertRichText };
