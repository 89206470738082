import clsx from 'clsx';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Column as LibColumn, Row } from 'react-table';
import { useExpanded, usePagination, useRowSelect, useTable } from 'react-table';
import { v4 as uuid } from 'uuid';

import { Card } from '@/Components/Atoms/Card/Card';
import { DropdownMenu } from '@/Components/Atoms/DropdownMenu';
import { Icon } from '@/Components/Atoms/Icon';
import type { MenuRef } from '@/Components/Atoms/Menu';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Typography } from '@/Components/Atoms/Typography';
import { useWindow } from '@/Utils/Helpers/useWindow';

import type { ActionLabel, TableProps } from '..';
import { TableCell } from './TableCell';
import { TableCheckbox } from './TableCheckbox';
import { TableColumnHeader } from './TableColumnHeader';
import { TableFooter } from './TableFooter';
import { HoverActions } from './TableHoverActions';

export const DesktopTable = <T extends Record<string, unknown>>(props: TableProps<T>) => {
  const {
    columns,
    numberOfPages,
    data,
    noFooter = false,
    setRowSelection,
    hasOwnPagination = false,
    hoverActions,
    onClickRow,
    isLoading = false,
    rowActionType = 'button',
    expandable = false,
    preSelectedItems = [],
    handleNewPage,
    handleNewPageSize,
    currentPage = 0,
    defaultPageSize = 10,
    totalItems = data.length,
    width = 'content',
    cardProps,
    sort,
    setSort,
  } = props;

  const [totalWidth, setTotalWidth] = useState(0);
  const [scrolled, setScrolled] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<MenuRef>(null);
  const [hoverRow, setHoverRow] = useState<{ index: number; position: DOMRect; row: any } | null>(null);

  const { windowType } = useWindow();
  const theadRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (totalWidth === 0 && containerRef.current) {
      setTotalWidth(containerRef.current.offsetWidth);
    }
  }, [totalWidth]);

  const tableColumns = useMemo((): Array<LibColumn<T>> => {
    let fullCols = columns.map((column) => ({
      Cell: (cell: any) => {
        if (isLoading) return <Skeleton height="20px" variant="rect" width="150px" />;
        const { cell: { column: { id }, row: { original } } } = cell;

        const firstCol = cell.columns.findIndex((col: any) => col.id === id) === 0;
        if (column.isActionColumn) return <span />;
        return (
          <TableCell
            data={column.key}
            firstColumn={firstCol}
            id={column.id}
            original={original}
          />
        );
      },
      Header: () => (
        <TableColumnHeader
          column={column}
          setSort={setSort}
          sort={sort}
        />
      ),
      id: column.id,
    }));
    if (expandable) {
      const expandableCol: LibColumn<T> = {
        Cell: ({ row }: any) => {
          const expandableProps = row.getToggleRowExpandedProps();
          if (row.canExpand) {
            return (
              <Icon
                className="p-3"
                icon={row.isExpanded ? 'ExpandLess' : 'ExpandMore'}
                onClick={(e) => {
                  e.stopPropagation();
                  expandableProps.onClick();
                }}
              />
            );
          }
          return null;
        },
        Header: () => (
          <TableColumnHeader />
        ),
        // Build our expander column
        id: 'expander',
      };
      fullCols = [expandableCol as any, ...fullCols];
    }
    return fullCols;
  }, [columns, expandable, isLoading, setSort, sort]);

  const selectedRowIds = useMemo(() => {
    if (preSelectedItems.length > 0) {
      const preIds = preSelectedItems.map((item) => item.id);
      return data.filter((item) => preIds.some((selectedId) => selectedId === item.id));
    }
    return {};
  }, [data, preSelectedItems]);

  const tableData = useMemo(() => {
    if (isLoading) {
      return [...Array(10)].map((_, index): Record<string, unknown> => ({ index }));
    }
    return data;
  }, [isLoading, data]);

  const tableInstance = useTable(
    {
      columns: tableColumns as any,
      data: tableData,
      initialState: {
        hiddenColumns: columns.reduce((acc, col) => (col.hidden ? [...acc, col.id] : acc), []),
        pageIndex: !hasOwnPagination ? currentPage : 0,
        pageSize: defaultPageSize,
        selectedRowIds,
      },
    },
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (setRowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            Cell: function OrderItems({ row }: any) {
              return (
                <TableCheckbox
                  id={row.id}
                  name={row.values.title}
                  {...row.getToggleRowSelectedProps()}
                />
              );
            },
            Header: function OrderItems({ getToggleAllPageRowsSelectedProps }: any) {
              return (
                <TableCheckbox
                  header
                  {...getToggleAllPageRowsSelectedProps()}
                />
              );
            },
            id: 'selection',
          },
          ...columns,
        ]);
      }
    },
  );

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageOptions,
    prepareRow,
    selectedFlatRows,
    setPageSize,
    state: { pageIndex, pageSize },
    toggleAllRowsSelected,
  } = tableInstance;

  useEffect(() => {
    if (setRowSelection) {
      setRowSelection(selectedFlatRows.map((e: any) => e.original));
    }
  }, [selectedFlatRows, setRowSelection]);

  const handleClickRow = (row: T) => {
    if (onClickRow) onClickRow(row);
  };

  const onScroll = (event: any) => {
    setScrolled(event.currentTarget.scrollLeft);
  };

  const handleMouseIn = (event: MouseEvent<HTMLTableRowElement>, row: any, index: number) => {
    const position = event.currentTarget.getBoundingClientRect();
    setHoverRow({
      index,
      position,
      row,
    });
  };

  const handleChangePage = (page: number) => {
    gotoPage(page);
    if (handleNewPage) handleNewPage(page);
  };

  const handleChangePageSize = (page: number) => {
    setPageSize(page);
    if (handleChangePage) handleChangePage(0);
    if (handleNewPageSize) handleNewPageSize(page);
  };

  const deSelectAllItems = () => toggleAllRowsSelected(false);

  const renderLabel = (label: ActionLabel<T>, row: Row<T>) => (typeof label === 'string' ? label : label(row.original));

  const getHoverActions = useCallback(() => {
    if (!hoverActions) return [];
    if (Array.isArray(hoverActions)) return hoverActions;
    return hoverActions(hoverRow?.row, hoverRow?.index || 0);
  }, [hoverActions, hoverRow]);

  const tableHoverActions = useMemo(() => getHoverActions(), [getHoverActions]);

  const renderTable = () => (
    <>
      <div className="w-full overflow-auto" onScroll={onScroll}>
        <table
          cellPadding={0}
          cellSpacing={0}
          className="w-full border-collapse !border-spacing-0"
          {...getTableProps()}
        >
          <thead ref={theadRef}>
            {
              headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={uuid()}
                  className="p-0 border-b border-b-on-surface-stroke"
                >
                  {
                    headerGroup.headers.map((column, index: number) => (
                      <th
                        {...column.getHeaderProps()}
                        key={column.id}
                        className={clsx('h-full p-0', {
                          'left-0 sticky z-10 bg-inherit': index === 0 && scrolled,
                        })}
                      >
                        {
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                  {tableHoverActions.length > 0 && (
                    <th className="right-0 sticky z-10 w-10">
                      <TableColumnHeader />
                    </th>
                  )}
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              page.map((row: any, index: number) => {
                const offset = (pageSize * pageIndex) + index;
                prepareRow(row);
                return (
                  <tr
                    className={clsx(
                      'group relative',
                      {
                        'bg-on-surface-states-hover': hoverRow?.row.id === row.id,
                        'bg-on-surface-states-selected hover:bg-on-surface-states-hover': row.isSelected,
                        'cursor-pointer': Boolean(onClickRow),
                      },
                    )}
                    {...row.getRowProps()}
                    key={row.id}
                    onClick={() => handleClickRow(row.original)}
                    onMouseEnter={(event) => {
                      if (!menuRef.current?.open) handleMouseIn(event, row, offset);
                    }}
                  >
                    {
                      row.cells.map((cell: any, index: number) => (
                        <td
                          {...cell.getCellProps()}
                          key={`${cell.column.id} ${cell.row.id} ${cell.value}`}
                          className={clsx('p-0', {
                            'bg-inherit': hoverRow && hoverRow.row.id === row.id,
                            'bg-white': hoverRow && hoverRow.row.id !== row.id,
                            'left-0 sticky z-10 bg-surface': index === 0 && scrolled,
                          })}
                        >
                          {
                            cell.render('Cell')
                          }
                        </td>
                      ))
                    }
                    {!isLoading && tableHoverActions.length > 0 && (windowType !== 'desktop' || rowActionType === 'dropdown') && (
                      <td className="sticky right-0 bg-inherit py-4">
                        <DropdownMenu.Root>
                          <DropdownMenu.Trigger hidden>
                            <Icon icon="MoreVert" />
                          </DropdownMenu.Trigger>
                          <DropdownMenu.Content align="start">
                            {
                              tableHoverActions.map((button) => (button.children ? (
                                <DropdownMenu.SubItem
                                  key={`tableActions-${button.label}`}
                                  label={renderLabel(button.label, row)}
                                >
                                  {
                                    button.children.map((btn) => (
                                      <DropdownMenu.Item
                                        key={`tableActions-${btn.label}`}
                                        label={btn.label}
                                        onClick={() => btn.onClick(hoverRow?.row.original)}
                                      />
                                    ))
                                  }
                                </DropdownMenu.SubItem>
                              ) : (
                                <DropdownMenu.Item
                                  key={`tableActions-${button.label}`}
                                  label={renderLabel(button.label, row)}
                                  onClick={() => button.onClick(hoverRow?.row.original)}
                                />
                              )
                              ))
                            }
                          </DropdownMenu.Content>
                        </DropdownMenu.Root>
                      </td>
                    )}
                    {!isLoading && tableHoverActions.length > 0 && (windowType === 'desktop' && rowActionType === 'button') && (
                      <td className="sticky right-0 bg-inherit z-10 py-4">
                        <HoverActions
                          data={data}
                          hide={hoverRow?.row.id !== row.id}
                          hoverActions={tableHoverActions.map((action) => ({
                            icon: action.icon,
                            isLoading: action.isLoading,
                            label: renderLabel(action.label, row),
                            onClick: () => {
                              action.onClick(hoverRow?.row.original);
                            },
                          }))}
                          row={row}
                        />
                      </td>
                    )}
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>

      {((!noFooter && data.length > 10) || props.hasOwnPagination) && (
        <TableFooter
          gotoPage={handleChangePage}
          numberOfPages={numberOfPages || pageOptions.length}
          pageIndex={currentPage || pageIndex}
          pageSize={pageSize}
          setPageSize={handleChangePageSize}
          totalCount={totalItems || data.length}
        />
      )}
    </>
  );

  const renderNoItemsContingency = () => (
    <div className="bg-surface-variant flex items-center justify-center p-4 mx-4 mb-4 rounded">
      <Typography className="text-status-on-status" variant="body2">
        {`No ${props.cardProps.headerProps?.title} added yet.`}
      </Typography>
    </div>
  );

  return (
    <div
      ref={containerRef}
      className={clsx({
        'max-w-full w-full': width === 'stretch',
        'min-w-[770px] w-min max-w-fit': width === 'content',
      })}
    >
      <Card
        {...cardProps}
        disableAccordion={cardProps.disableAccordion || cardProps.disableAccordion === undefined}
        headerProps={cardProps.headerProps ? {
          ...cardProps.headerProps,
          contextualHeaderProps: cardProps.headerProps?.contextualHeaderProps ? {
            ...cardProps.headerProps.contextualHeaderProps,
            onClickClose: deSelectAllItems,
          } : undefined,
        } : undefined}
        variant={cardProps.variant || 'borderless'}
      >
        {data.length > 0 || isLoading ? renderTable() : renderNoItemsContingency()}
      </Card>
    </div>
  );
};
