import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { ConfigureClientSubscriptionCommandRequest, ConfigureClientSubscriptionCommandResponse } from '@/API/Models/Wilqo_Subscriptions_Commands_pb';

import { useBackend } from './useBackend';

export const useConfigureSubscription = () => {
  const { commandResponse } = useBackend();

  return useMutation(async () => {
    const request = new ConfigureClientSubscriptionCommandRequest();
    const responseMsg = await commandResponse({
      msg: request,
      msgName: 'wilqo.subscriptions.ConfigureClientSubscriptionCommandRequest',
    });

    const response = new ConfigureClientSubscriptionCommandResponse();
    ConfigureClientSubscriptionCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));

    return response.toObject();
  });
};
