import { createContext, useCallback, useMemo, useState } from 'react';

import type { DynamicDataWriteItem } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';

export interface IRootCircuit {
  circuitId: string;
  scopeToken: string;
}

interface ICircuitHistoryContext {
  rootCircuit: IRootCircuit;
  navigationHistories: ICircuitNavigation[];
}

export type ICircuitNavigation = IRootCircuit & {
  circuitPageId: string;
  skipOnPrev?: boolean;
};

export type IInititalizeCollectionTriggerEvent = 'onCircuitStart' | 'onValueChange';
export interface IInitializeCollectionMemberDetails {
  iteratorId: string;
  uniqueIdVirtualFieldId: string;
  triggerEvent: IInititalizeCollectionTriggerEvent;
  writeItems?: DynamicDataWriteItem.AsObject[];
  multipleInitializationPerPage?: boolean;
}

/**
 * @property {ICircuitNavigation} circuitNavigation - Navigation options for new circuit
 * @property {boolean?} isChild - Identifies if circuit should be loaded as a transient subsidiary of the current circuit. Upon completion, circuit context will return to whatever circuit and page was displayed prior to loading the child circuit
 * @property {IInitializeCollectionMemberDetails} initializeCollectionMember - Set to indicate that this circuit is creating a new member of a collection. Upon first save, a scope token for the created member will be returned and automatically added to the circuit context for the current circuit
 * @property {boolean?} isRoot - Identifies if circuit is a new root circuit (i.e. a new section in a flow)
 */
export interface INextCircuitOptions {
  circuitNavigation: ICircuitNavigation;
  initializeCollectionMember?: IInitializeCollectionMemberDetails;
  isChild?: boolean;
  isRoot?: boolean;
  overwriteLastStep?: boolean;
}

export interface CircuitContextProps {
  /**
   * Navigates to new circuit or circuit page
   * @param {INextCircuitOptions} options
  */
  nextCircuit: (options: INextCircuitOptions) => void;
  previousCircuit: () => void;
  /**
   * Navigates back to the parent of the current child circuit and removes the child circuit navigation history from the history stack. Call this function when a child circuit has no more internal pages to navigate to.
   */
  completeChildCircuit: () => void;
  /**
   * Completes collection member initialzation by clearing initializeCollectionMemberDetails and updates scope token for initializing circuit
   */
  completeInitialization: (circuitId: string, scopeToken: string) => void;
  /**
   * This is a hack to fix an issue with hidden loan page flow sections
   */
  convertCircuitToRoot: (circuitId: string) => void;
  /**
   * This indicates that the most recent navigation was due to a child circuit completing
   */
  didReturnFromChild: boolean;
  /**
   * Updates the given circuit to use the given scope token
   * @param circuitId The circuit that the scope token should be updated for
   * @param scopeToken Scope token that should be used for the current circuit
   */
  updateScopeToken: (circuitId: string, scopeToken: string) => void;
  /**
   * Indicates whether there are previous circuit navigations available in the history
   */
  hasPreviousCircuit: boolean;
  /**
   * Indicates whether the current circuit is a child circuit. If so, this circuit will return to its parent once it no longer has internal pages to navigate to.
  */
  isChildCircuit: boolean;
  /**
   * The actual current circuit, which may be a child circuit. This is the circuit currently active and displayed to the user.
   */
  currentCircuit: ICircuitNavigation | undefined;
  /**
   * The most current root circuit. Use this to match page flow sections.
  */
  rootCircuit: IRootCircuit | undefined;
  /**
  * Details to be used when creating a new collection member
 */
  initializeCollectionMemberDetails: IInitializeCollectionMemberDetails | undefined;
  setInitializeCollectionMemberDetails: React.Dispatch<React.SetStateAction<IInitializeCollectionMemberDetails | undefined>>;
  /**
 * update the pageId - used when it is not set automatically
 */
  updatePageId: (id: string, circuitId: string) => void;
}

export const CircuitContext = createContext<CircuitContextProps>({} as CircuitContextProps);

interface ICircuitProps {
  children: React.ReactNode;
}

const navsDiffer = (nav1: ICircuitNavigation | undefined, nav2: ICircuitNavigation | undefined) => {
  // If only one is defined
  if (!nav1 !== !nav2) return true;

  // Technically only need to check one here but checking both so typescript sees them as defined
  if (!nav1 || !nav2) return false;

  return nav1.circuitId !== nav2.circuitId
    || nav1.circuitPageId !== nav2.circuitPageId
    || nav1.scopeToken !== nav2.scopeToken;
};

const lastCircuitOf = (historyContext: ICircuitHistoryContext[]) => historyContext.at(-1)?.navigationHistories.at(-1);

export const CircuitProvider = (props: ICircuitProps) => {
  const { children } = props;

  const [circuitHistoryStack, setCircuitHistoryStack] = useState<ICircuitHistoryContext[]>([]);
  const [initializeCollectionMemberDetails, setInitializeCollectionMemberDetails] = useState<IInitializeCollectionMemberDetails | undefined>(undefined);
  const [isTransient, setIsTransient] = useState<boolean>(false);
  const [didReturnFromChild, setReturnedFromChild] = useState<boolean>(false);

  const currentCircuit = lastCircuitOf(circuitHistoryStack);
  const rootCircuit = circuitHistoryStack.at(-1)?.rootCircuit;

  const nextCircuit = useCallback((options: INextCircuitOptions) => {
    const { circuitNavigation: newCircuit, initializeCollectionMember, isChild, isRoot, overwriteLastStep } = options;
    const oldCircuit = currentCircuit;
    let newHistoryStack = [...circuitHistoryStack];
    if (didReturnFromChild) setReturnedFromChild(false);

    // If navigating to a new circuit (ex. through clicking a new section)
    if (oldCircuit?.circuitId !== newCircuit.circuitId) {
      // Clear or update initializeCollectionMemberDetails
      setInitializeCollectionMemberDetails(initializeCollectionMember);

      // Remove transient histories
      if (isTransient) {
        newHistoryStack.at(-1)!.navigationHistories = newHistoryStack.at(-1)!.navigationHistories.filter((history) => history.circuitId !== oldCircuit?.circuitId);
      }

      if (isRoot) {
        // If navigating to a new root circuit (i.e. flow section), add a new context to the history stack
        const newRootCircuitHistoryContext: ICircuitHistoryContext = {
          navigationHistories: [],
          rootCircuit: { circuitId: newCircuit.circuitId, scopeToken: newCircuit.scopeToken },
        };
        newHistoryStack = [...newHistoryStack, newRootCircuitHistoryContext];
      }

      // If this is a child circuit then its histories are transient and will be cleared once we navigate to a new circuit
      if (isTransient !== isChild) setIsTransient(isChild ?? false);
    }

    // Add new navigation to the end of the current circuit stack if it is not a duplicate
    if (navsDiffer(lastCircuitOf(newHistoryStack), newCircuit)) {
      if (overwriteLastStep) {
        const lastPrevStep = newHistoryStack.at((newHistoryStack.at(-1)?.navigationHistories.length ?? 0) === 0 ? -2 : -1)?.navigationHistories.at(-1);
        if (lastPrevStep) lastPrevStep.skipOnPrev = true;
      }
      newHistoryStack.at(-1)?.navigationHistories.push(newCircuit);
    }

    setCircuitHistoryStack(newHistoryStack);
  }, [currentCircuit, circuitHistoryStack, didReturnFromChild, isTransient, setCircuitHistoryStack, setReturnedFromChild, setInitializeCollectionMemberDetails, setIsTransient]);

  const previousCircuit = useCallback(() => {
    const newCircuitHistory = [...circuitHistoryStack];
    const prevNavigation = lastCircuitOf(newCircuitHistory);

    let foundPrevious = false;
    while (!foundPrevious) {
      if ((newCircuitHistory.at(-1)?.navigationHistories?.length ?? 0) > 1) {
        // If there is history available in the current stack, remove the current nav in
        //   the current stack to go back one
        newCircuitHistory.at(-1)!.navigationHistories.pop();
      } else {
        // No history is available in current stack, so pop a level off
        newCircuitHistory.pop();
      }

      // If the current step has the skipOnPrev property, then continue looking back
      if (!(lastCircuitOf(newCircuitHistory)?.skipOnPrev ?? false)) foundPrevious = true;
    }

    setCircuitHistoryStack(newCircuitHistory);

    // If circuit did not change, return
    if (prevNavigation?.circuitId === lastCircuitOf(newCircuitHistory)?.circuitId) return;

    // If we're backing out of an add new flow, clear the initializeCollectionMemberDetails and isTransient status
    if (initializeCollectionMemberDetails) setInitializeCollectionMemberDetails(undefined);
    if (isTransient) {
      setIsTransient(false);
      setReturnedFromChild(true);
    }
  }, [circuitHistoryStack, initializeCollectionMemberDetails, isTransient, setCircuitHistoryStack, setInitializeCollectionMemberDetails, setIsTransient, setReturnedFromChild]);

  const completeChildCircuit = useCallback(() => {
    // Remove transient histories from circuitHistoryStack
    setCircuitHistoryStack((prev) => {
      prev.at(-1)!.navigationHistories = prev.at(-1)!.navigationHistories.filter((history) => history.circuitId !== currentCircuit?.circuitId);

      // Unset skip on prev when completing circuit because we now may want the page to show since data has changed due to child circuit completion
      if (lastCircuitOf(prev)?.skipOnPrev) prev.at(-1)!.navigationHistories.at(-1)!.skipOnPrev = false;

      return [...prev];
    });
    setIsTransient(false);
    setReturnedFromChild(true);
  }, [setCircuitHistoryStack, setReturnedFromChild, setIsTransient, currentCircuit?.circuitId]);

  // Update current history level to add updated scope token
  const updateScopeToken = useCallback((circuitId: string, newScopeToken: string) => setCircuitHistoryStack((rootCircuitHistories) => {
    rootCircuitHistories.at(-1)?.navigationHistories.filter((history) => history.circuitId === circuitId).forEach((history) => { history.scopeToken = newScopeToken; });

    // NOTE: not changing rootCircuitHistories rootCircuit.scopeToken because that is supplied by the section flow and used to load subsequent circuits
    return [...rootCircuitHistories];
  }), [setCircuitHistoryStack]);

  const completeInitialization = useCallback((circuitId: string, newScopeToken: string) => {
    // Clear any initializeCollectionMemberDetails since the collection member has now
    //   been initialized and the scope token is set for further updates
    setInitializeCollectionMemberDetails(undefined);
    updateScopeToken(circuitId, newScopeToken);
  }, [setInitializeCollectionMemberDetails, updateScopeToken]);

  // I think this is a hack to automatically select a circuit page if none is selected?
  const updatePageId = useCallback((id: string, circuitId: string) => {
    setCircuitHistoryStack((prevRootCircuitHistories) => {
      prevRootCircuitHistories.at(-1)?.navigationHistories.forEach((navigationHistory) => {
        if (navigationHistory.circuitId === circuitId) navigationHistory.circuitPageId = id;
      });
      return [...prevRootCircuitHistories];
    });
  }, []);

  const hasPreviousCircuit = useMemo(() => circuitHistoryStack.length > 1 || (circuitHistoryStack[0]?.navigationHistories.length > 1), [circuitHistoryStack]);

  // This is a hack to prevent entering a new hidden section without highlighting it
  const convertCircuitToRoot = useCallback((circuitId: string) => setCircuitHistoryStack((prevRootCircuitHistories) => {
    // Should never happen
    if (!prevRootCircuitHistories.at(-1)) return prevRootCircuitHistories;

    // Split the current histories where it would have been split if the identified circuit were navigated to as a new root
    // Functionally, this should be the same as just popping off the last history, but we'll grab anything after the first
    //   matching entry just in case
    const currentHistories = prevRootCircuitHistories.at(-1)?.navigationHistories ?? [];
    const historySplitPoint = currentHistories.findIndex((history) => history.circuitId === circuitId);
    const newPriorHistory = currentHistories.slice(0, historySplitPoint);
    const newCurrentHistory = currentHistories.slice(historySplitPoint);

    // Update what will be prior history to remove the histories that we'll move to a new context
    prevRootCircuitHistories.at(-1)!.navigationHistories = newPriorHistory;

    // Create new current history context with the new histories
    const newRootCircuitHistoryContext: ICircuitHistoryContext = {
      navigationHistories: newCurrentHistory,
      rootCircuit: { circuitId, scopeToken: newCurrentHistory.at(0)?.scopeToken ?? '' },
    };
    return [...prevRootCircuitHistories, newRootCircuitHistoryContext];
  }), [setCircuitHistoryStack]);

  return (
    <CircuitContext.Provider
      value={{
        completeChildCircuit,
        completeInitialization,
        convertCircuitToRoot,
        currentCircuit,
        didReturnFromChild,
        hasPreviousCircuit,
        initializeCollectionMemberDetails,
        isChildCircuit: circuitHistoryStack.length > 1,
        nextCircuit,
        previousCircuit,
        rootCircuit,
        setInitializeCollectionMemberDetails,
        updatePageId,
        updateScopeToken,
      }}
    >
      {children}
    </CircuitContext.Provider>
  );
};
