import { useMemo } from 'react';

import { useTRIDSubscription } from '@/API/Queries/mortgage/useTRIDSubscription';
import { StepBanner } from '@/Components/Atoms/StepBanner';

const TRID_ITEMS = ['Address', 'Loan amount', 'Income', 'Estimated Property Value', 'Name', 'SSN'];

interface TRIDBannerProps {
  loanId: string;
  defaultOpen?: boolean;
  displayVertical?: boolean;
}

const TRIDBanner = (props: TRIDBannerProps) => {
  const { defaultOpen = false, displayVertical, loanId } = props;

  const { trid } = useTRIDSubscription(loanId);

  const completeTridItems = useMemo(() => {
    const completedItems = [];
    if (trid?.addresIndicator) completedItems.push(TRID_ITEMS[0]);
    if (trid?.loanAmountIndicator) completedItems.push(TRID_ITEMS[1]);
    if (trid?.incomeIndicator) completedItems.push(TRID_ITEMS[2]);
    if (trid?.estimatedPropertyValueIndicator) completedItems.push(TRID_ITEMS[3]);
    if (trid?.nameIndicator) completedItems.push(TRID_ITEMS[4]);
    if (trid?.socialSecurityNumberIndicator) completedItems.push(TRID_ITEMS[5]);
    return completedItems;
  }, [trid]);

  return (
    <StepBanner
      completedItems={completeTridItems}
      defaultOpen={defaultOpen}
      displayVertical={displayVertical}
      items={TRID_ITEMS}
      title={`TRID - ${completeTridItems.length} of 6 collected`}
    />
  );
};

export { TRIDBanner };
