import clsx from 'clsx';

interface AnimatedBackdropProps {
  className?: string;
}

export const AnimatedBackdrop = (props: AnimatedBackdropProps) => {
  const { className } = props;

  return (
    <div
      className={clsx(
        'fixed pointer-events-auto h-full z-30 bg-black/50 transition-opacity',
        className,
      )}
      id="backdrop"
    />
  );
};
