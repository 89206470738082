import type { HTMLAttributes } from 'react';

import { cn } from '@/Utils/Helpers/uiBuilders';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { Drawer } from '../Drawer';
import { SidesheetRail } from '../SideSheet/SidesheetRail';
import type { TabData } from '../Tab';

export interface DialogSidesheetProps extends HTMLAttributes<HTMLDivElement> {
  tabs?: TabData[];
  title?: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const DialogSidesheet = (props: DialogSidesheetProps) => {
  const { isMobile, isTablet } = useWindow();
  const { children, className, onOpenChange, open, tabs, title, ...rest } = props;

  const sideSheetStyle = cn(
    'relative h-auto overflow-auto tablet:row-span-2 tablet:col-start-3 tablet:row-start-2',
    className,
  );

  if (isTablet || isMobile) {
    return (
      <Drawer.Root
        onOpenChange={onOpenChange}
        open={open}
        openFrom={isMobile ? 'bottom' : 'right'}
      >
        <Drawer.Header onClickIcon={() => onOpenChange(false)} title={title ?? 'Section Details'} />

        <Drawer.Content>
          <aside
            {...rest}
            className={sideSheetStyle}
          >
            <div className="h-full w-full">
              {children}
            </div>
          </aside>
        </Drawer.Content>
      </Drawer.Root>
    );
  }

  return (
    <>
      {tabs && (
        <nav className="col-start-2 row-start-2 border-l border-l-on-surface-stroke hidden tablet:grid">
          <SidesheetRail
            tabsData={tabs}
          />
        </nav>
      )}

      <aside
        {...rest}
        className={cn(sideSheetStyle, 'bg-surface-variant border-l border-l-on-surface-stroke')}
        data-dialog-sidesheet
      >
        <div className="h-full w-full">
          {children}
        </div>
      </aside>
    </>
  );
};
