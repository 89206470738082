import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { GET_PAGE_CONFIG_MESSAGE_NAME, usePageConfiguration } from '@/API/Queries/brand/usePageConfiguration';
import { useQuestionnaire } from '@/API/Queries/questionnaire/useQuestionnaire';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

type NavigationButtonKeys = 'panel-sequence-back-button' | 'panel-sequence-create-account-button' | 'panel-sequence-next-button';

type NavigationButton = Record<NavigationButtonKeys, string>;

const defaultButtonConfig: NavigationButton = {
  'panel-sequence-back-button': 'Back',
  'panel-sequence-create-account-button': 'Create your Account',
  'panel-sequence-next-button': 'Next',
};

type CacheProgress = Record<string, DynamicDataElementValues>;

const useQuestionnaireData = () => {
  const loanId = sessionStorage.getItem('loanId') || '';
  const { data: questionnaire, isLoading: isLoadingQuestionnaire } = useQuestionnaire(loanId);
  const queryClient = useQueryClient();

  const [hasToken, setHasToken] = useState(false);
  const [navigationButtons, setNavigationButtons] = useState(defaultButtonConfig);
  const { data: pageConfiguration, isLoading: isLoadingConfiguration } = usePageConfiguration(hasToken, true);
  const [progress, setProgress] = useState <CacheProgress>({});

  const isLoading = useMemo(() => isLoadingQuestionnaire || isLoadingConfiguration,
    [isLoadingQuestionnaire, isLoadingConfiguration]);

  const { manualTokenUpdate } = useAuth();

  const updateToken = useCallback(() => {
    const questionnaireToken = questionnaire?.token;
    if (questionnaireToken) {
      manualTokenUpdate(questionnaireToken);
      setHasToken(true);
    }
  }, [manualTokenUpdate, questionnaire]);

  const updateConfigurableButton = useCallback(() => {
    const panelSequence = questionnaire?.questionnaire;
    if (panelSequence && panelSequence.navigationButtonsList.length > 0) {
      sessionStorage.setItem('loanId', questionnaire?.questionnaireRunId || '');
      const configButtons = panelSequence.navigationButtonsList.reduce((items, current) => ({
        ...items,
        [current.id as NavigationButtonKeys]: current.text,
      }), {} as NavigationButton);
      setNavigationButtons(configButtons);
    }
  }, [questionnaire]);

  const getPreviousQuestionnaireProgress = useCallback(() => {
    const previouslyStoredProgress = localStorage.getItem(`questionnaire-${loanId}`);
    if (previouslyStoredProgress) {
      const progress = JSON.parse(previouslyStoredProgress) as CacheProgress;
      setProgress(progress);
    }
  }, [loanId]);

  const saveProgressInCache = (panelId: string, values: DynamicDataElementValues) => {
    const cacheProgress = { ...progress, [panelId]: values };
    setProgress(cacheProgress);
    localStorage.setItem(`questionnaire-${loanId}`, JSON.stringify(cacheProgress));
  };

  useEffect(() => updateToken(), [updateToken]);
  useEffect(() => updateConfigurableButton(), [updateConfigurableButton]);
  useEffect(() => getPreviousQuestionnaireProgress(), [getPreviousQuestionnaireProgress]);

  useEffect(() => () => {
    localStorage.removeItem(`questionnaire-${loanId}`);
    manualTokenUpdate('');
    queryClient.invalidateQueries([GET_PAGE_CONFIG_MESSAGE_NAME]);
  }, [manualTokenUpdate, loanId, queryClient]);

  return {
    isLoading,
    navigationButtons,
    pageConfiguration,
    progress,
    questionnaire,
    saveProgressInCache,
  };
};

export { useQuestionnaireData };
