import { useQuery } from '@tanstack/react-query';

import { GetProgressSummaryQueryRequest, GetProgressSummaryQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.mortgage.GetProgressSummaryQueryRequest';

const useLoanProgressSummary = (id: string) => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useQuery([MESSAGE_NAME, id], async () => {
    const request = new GetProgressSummaryQueryRequest();
    request.setDealId(id);
    request.setBusinessProcessDomainId(bpdId);
    const response = await sendMessage<GetProgressSummaryQueryResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      GetProgressSummaryQueryResponse,
    );
    return response.progressSummary;
  }, { enabled: Boolean(id) });
};
export { useLoanProgressSummary };
