interface IconProps {
  className?: string;
}

const Number5 = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path clipRule="evenodd" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20Z" fillRule="evenodd" />
    <path d="M10.7285 12.1152L9.38086 11.793L9.86719 7.46875H14.6602V8.83398H11.2559L11.0449 10.7266C11.1582 10.6602 11.3301 10.5898 11.5605 10.5156C11.791 10.4375 12.0488 10.3984 12.334 10.3984C12.748 10.3984 13.1152 10.4629 13.4355 10.5918C13.7559 10.7207 14.0273 10.9082 14.25 11.1543C14.4766 11.4004 14.6484 11.7012 14.7656 12.0566C14.8828 12.4121 14.9414 12.8145 14.9414 13.2637C14.9414 13.6426 14.8828 14.0039 14.7656 14.3477C14.6484 14.6875 14.4707 14.9922 14.2324 15.2617C13.9941 15.5273 13.6953 15.7363 13.3359 15.8887C12.9766 16.041 12.5508 16.1172 12.0586 16.1172C11.6914 16.1172 11.3359 16.0625 10.9922 15.9531C10.6523 15.8438 10.3457 15.6816 10.0723 15.4668C9.80273 15.252 9.58594 14.9922 9.42188 14.6875C9.26172 14.3789 9.17773 14.0273 9.16992 13.6328H10.8457C10.8691 13.875 10.9316 14.084 11.0332 14.2598C11.1387 14.4316 11.2773 14.5645 11.4492 14.6582C11.6211 14.752 11.8223 14.7988 12.0527 14.7988C12.2676 14.7988 12.4512 14.7578 12.6035 14.6758C12.7559 14.5938 12.8789 14.4805 12.9727 14.3359C13.0664 14.1875 13.1348 14.0156 13.1777 13.8203C13.2246 13.6211 13.248 13.4062 13.248 13.1758C13.248 12.9453 13.2207 12.7363 13.166 12.5488C13.1113 12.3613 13.0273 12.1992 12.9141 12.0625C12.8008 11.9258 12.6562 11.8203 12.4805 11.7461C12.3086 11.6719 12.1074 11.6348 11.877 11.6348C11.5645 11.6348 11.3223 11.6836 11.1504 11.7812C10.9824 11.8789 10.8418 11.9902 10.7285 12.1152Z" />
  </svg>
);

export default Number5;
