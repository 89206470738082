import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { RemoveSkillsetGroupFromUserCommandRequest, RemoveSkillsetGroupFromUserCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.RemoveSkillsetGroupFromUserCommandRequest';

interface UpdateObject {
  skillsetGroupId: string;
  userId: string;
}

export const useRemoveUserSkillsetGroup = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const RemoveUserSkillsetGroup = async (updateObject: UpdateObject) => {
    const { skillsetGroupId, userId } = updateObject;
    const request = new RemoveSkillsetGroupFromUserCommandRequest();
    request.setSkillsetGroupId(skillsetGroupId);
    request.setBusinessProcessDomainId(bpdId);
    request.setUserId(userId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new RemoveSkillsetGroupFromUserCommandResponse();
    RemoveSkillsetGroupFromUserCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(RemoveUserSkillsetGroup);
};
