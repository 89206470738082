import { useParams } from 'react-router';

import type { ValidationChecklistItem } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { usePutActivityChecklistProgress } from '@/API/Queries/activities/usePutValidationChecklistProgress';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { ButtonGroup } from '@/Components/Features/PanelElement/ButtonGroup';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';

import { useValidationProgress } from './validationProgressContext';

interface IDynamicChecklistFormProps {
  panelElements?: Array<any>;
  isEdit?: boolean;
  checklistItem: ValidationChecklistItem.AsObject;
}

const DynamicChecklistForm = (props: IDynamicChecklistFormProps) => {
  const {
    isEdit = false,
    panelElements = [],
    checklistItem,
  } = props;

  const { activityId = '' } = useParams();

  const { addProgress, checklistProgress } = useValidationProgress();
  const { mutate: mutateValidationProgress } = usePutActivityChecklistProgress();

  const onFormSubmit = (formData: any) => {
    if (!checklistItem.panel) return;
    const panelId = checklistItem.panel.id;
    let panelElements = [];
    const formKeys = Object.keys(formData);
    if (formKeys.length > 0) {
      panelElements = formKeys.map((id) => ({
        id,
        panelId,
        value: formData[id],
      }));
    } else {
      panelElements = [{
        id: '',
        panelId,
        value: '',
      }];
    }
    mutateValidationProgress(
      {
        activityId,
        checklistItemId: checklistItem.id,
        elements: panelElements,
      },
      {
        onSuccess: () => {
          addProgress({
            checklistItem,
            value: formData,
          });
        },
      },
    );
  };

  const renderFollowUpPanel = (dynamicFormValues: DynamicDataElementValues) => {
    const values = Object.keys(dynamicFormValues).map((key) => dynamicFormValues[key].value);
    const followUp = checklistItem.followUpMap.find((followUp) => values.some((watch) => watch === followUp[0]));
    if (followUp) {
      const followUpPanel = followUp[1];
      return (
        <div className="flex flex-col py-2 px-0 w-full">
          <span className="text-xs leading-4 mb-1">Further Instructions</span>
          <span className="text-on-surface-active">{followUpPanel.headerText}</span>
          <span className="text-on-surface-active">{followUpPanel.summaryText}</span>
          <div className="pt-4">
            {
              followUpPanel.panelElementsList.map((panelElement) => (
                <PanelElementRenderer key={panelElement.id} variant="default" {...panelElement} />
              ))
            }
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="text-on-surface-inactive-input">
      <DynamicFormProvider
        onSubmit={onFormSubmit}
        progress={{ values: checklistProgress.reduce((acc, item) => ({
          ...acc,
          [item.checklistItem.id]: item.value,
        }), {}) }}
      >
        {({ values }) => (
          <>
            {
            panelElements.map((panelElement) => (
              <PanelElementRenderer variant="default" {...panelElement} />
            ))
          }
            {renderFollowUpPanel(values)}
            <ButtonGroup
              buttons={[{
                label: isEdit ? 'Update Info' : 'Mark complete',
                leftIcon: 'Done',
                noCaps: true,
                type: 'submit',
                variant: 'tertiary',
              }]}
            />
          </>
        )}
      </DynamicFormProvider>
    </div>
  );
};

export { DynamicChecklistForm };
