import type { PanelElementValidation } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';

import type { FormError } from './DynamicFormContext';

const validateMaxLength = (value: string, max: number) => value.length > max;
const validateMinLength = (value: string, min: number) => value.length < min;
const validateMax = (value: number, max: number) => value > max;
const validateMin = (value: number, min: number) => value < min;
const validateRegex = (value: string, pattern: string) => {
  const regex = RegExp(pattern);
  return !regex.test(value);
};

const checkValidators = (value: any, validators: Array<PanelElementValidation.AsObject>): FormError | undefined => {
  const validator = validators.find((validator) => {
    const cleanedValue = value.replace(/[^0-9.-]+/g, '');
    if (Number.isFinite(Number(cleanedValue))) {
      if (validator.type === 'numeric-min-value') return validateMin(Number(cleanedValue), Number(validator.value));
      if (validator.type === 'numeric-max-value') return validateMax(Number(cleanedValue), Number(validator.value));
    }
    if (validator.type === 'max-length') return validateMaxLength(value, Number(validator.value));
    if (validator.type === 'regex') return validateRegex(value, validator.value);
    if (validator.type === 'min-length') return validateMinLength(value, Number(validator.value));
    return false;
  });
  if (validator) {
    return { errorMessage: validator.errorMessage, type: validator.type };
  }
  return undefined;
};

export { checkValidators };
