import { Switch } from '@headlessui/react';
import clsx from 'clsx';

export interface ToggleProps {
  className?: string;
  disabled?: boolean;
  onClick: (value: boolean) => void;
  value?: boolean;
}

const Toggle = (props: ToggleProps) => {
  const {
    className,
    disabled = false,
    onClick,
    value,
  } = props;

  return (
    <Switch
      checked={value}
      className={
        clsx(
          'relative inline-flex h-3 w-8 cursor-pointer rounded-full transition-colors duration-200 ease-in-out',
          { 'bg-on-surface-disabled': !value, 'bg-primary-variant2': value },
          className,
        )
      }
      defaultValue={value ? 'true' : 'false'}
      disabled={disabled}
      onChange={onClick}
    >
      <span
        className={
          clsx(
            'pointer-events-none inline-block h-5 w-5 transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out',
            '-translate-y-1',
            { 'translate-x-0 bg-white': !value, 'translate-x-3 bg-primary': value },
          )
        }
      />
    </Switch>
  );
};

export { Toggle };
