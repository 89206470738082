interface IconProps {
  className?: string;
}

const Psychology = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M9 21v-3H8q-.825 0-1.412-.587Q6 16.825 6 16v-2H5q-.575 0-.85-.5t0-1L6.125 9q.2-2.625 2.213-4.312Q10.35 3 13 3q2.925 0 4.962 2.037Q20 7.075 20 10q0 2.025-1.087 3.737Q17.825 15.45 16 16.35V21Zm4-11Zm-1 4h2l.15-1.25q.2-.075.363-.175.162-.1.287-.225l1.15.5 1-1.7-1-.75q.05-.2.05-.4t-.05-.4l1-.75-1-1.7-1.15.5q-.125-.125-.287-.225-.163-.1-.363-.175L14 6h-2l-.15 1.25q-.2.075-.362.175-.163.1-.288.225l-1.15-.5-1 1.7 1 .75q-.05.2-.05.4t.05.4l-1 .75 1 1.7 1.15-.5q.125.125.288.225.162.1.362.175Zm1-2.5q-.625 0-1.062-.438Q11.5 10.625 11.5 10t.438-1.062Q12.375 8.5 13 8.5t1.062.438q.438.437.438 1.062t-.438 1.062q-.437.438-1.062.438ZM11 19h3v-3.95l.9-.4q1.4-.625 2.25-1.875Q18 11.525 18 10q0-2.075-1.462-3.537Q15.075 5 13 5q-2 0-3.438 1.375Q8.125 7.75 8 9.775L6.725 12H8v4h3Z" />
  </svg>
);

export default Psychology;
