// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ButtonAction', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionTarget', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.ButtonAction.PurposeBuiltPageType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.SectionAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.ButtonAction.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ButtonAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ButtonAction.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ButtonAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.displayName = 'proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ButtonAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    icon: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    purposeBuiltPage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    target: jspb.Message.getFieldWithDefault(msg, 5, 0),
    additionalArgsList: jspb.Message.toObjectList(msg.getAdditionalArgsList(),
    proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ButtonAction;
  return proto.wilqo.api.mortgage_dynamic_data.ButtonAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.PurposeBuiltPageType} */ (reader.readEnum());
      msg.setPurposeBuiltPage(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionTarget} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.deserializeBinaryFromReader);
      msg.addAdditionalArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ButtonAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPurposeBuiltPage();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAdditionalArgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionTarget = {
  ACTION_TARGET_UNKNWON: 0,
  ACTION_TARGET_DIALOG: 1,
  ACTION_TARGET_SELF: 2,
  ACTION_TARGET_NEW_PAGE: 3,
  ACTION_TARGET_NEW_TAB: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.PurposeBuiltPageType = {
  PURPOSE_BUILT_PAGE_TYPE_UNKNOWN: 0,
  PURPOSE_BUILT_PAGE_TYPE_ADDRESS: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg;
  return proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string icon = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PurposeBuiltPageType purpose_built_page = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.PurposeBuiltPageType}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.getPurposeBuiltPage = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.PurposeBuiltPageType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.PurposeBuiltPageType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.setPurposeBuiltPage = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ActionTarget target = 5;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionTarget}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.getTarget = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionTarget} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionTarget} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated ActionArg additional_args = 6;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg>}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.getAdditionalArgsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.setAdditionalArgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg}
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.addAdditionalArgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage_dynamic_data.ButtonAction.ActionArg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.ButtonAction} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.ButtonAction.prototype.clearAdditionalArgsList = function() {
  return this.setAdditionalArgsList([]);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.SectionAction = {
  SECTION_ACTION_CANCEL: 0,
  SECTION_ACTION_NEW_ITEM: 1,
  SECTION_ACTION_SAVE: 2,
  SECTION_ACTION_SAVE_AND_NEW_ITEM: 3
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
