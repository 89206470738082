// source: Wilqo_API_Questionnaire_Commands.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_API_Questionnaire_Entities_pb = require('./Wilqo_API_Questionnaire_Entities_pb.js');
goog.object.extend(proto, Wilqo_API_Questionnaire_Entities_pb);
goog.exportSymbol('proto.wilqo.api.questionnaire.CreateLoanCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.CreateLoanCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.displayName = 'proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.displayName = 'proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.displayName = 'proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.displayName = 'proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.displayName = 'proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.displayName = 'proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.displayName = 'proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.displayName = 'proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.displayName = 'proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.displayName = 'proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.CreateLoanCommandRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.CreateLoanCommandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.CreateLoanCommandRequest.displayName = 'proto.wilqo.api.questionnaire.CreateLoanCommandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.CreateLoanCommandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.CreateLoanCommandResponse.displayName = 'proto.wilqo.api.questionnaire.CreateLoanCommandResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElementsList: jspb.Message.toObjectList(msg.getPanelElementsList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance),
    runId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest;
  return proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addPanelElements(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.PanelElementResponse panel_elements = 3;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.getPanelElementsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} returns this
*/
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.setPanelElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.addPanelElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.clearPanelElementsList = function() {
  return this.setPanelElementsList([]);
};


/**
 * optional string run_id = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    trackId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    responsePanel: (f = msg.getResponsePanel()) && Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject(includeInstance, f),
    validationMessagesList: jspb.Message.toObjectList(msg.getValidationMessagesList(),
    Wilqo_API_Questionnaire_Entities_pb.QuestionnaireValidationMessage.toObject, includeInstance),
    inviteUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse;
  return proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.setResponsePanel(value);
      break;
    case 5:
      var value = new Wilqo_API_Questionnaire_Entities_pb.QuestionnaireValidationMessage;
      reader.readMessage(value,Wilqo_API_Questionnaire_Entities_pb.QuestionnaireValidationMessage.deserializeBinaryFromReader);
      msg.addValidationMessages(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getResponsePanel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
  f = message.getValidationMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Wilqo_API_Questionnaire_Entities_pb.QuestionnaireValidationMessage.serializeBinaryToWriter
    );
  }
  f = message.getInviteUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string track_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional wilqo.shared.models.Panel response_panel = 4;
 * @return {?proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.getResponsePanel = function() {
  return /** @type{?proto.wilqo.shared.models.Panel} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 4));
};


/**
 * @param {?proto.wilqo.shared.models.Panel|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.setResponsePanel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.clearResponsePanel = function() {
  return this.setResponsePanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.hasResponsePanel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated QuestionnaireValidationMessage validation_messages = 5;
 * @return {!Array<!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage>}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.getValidationMessagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Questionnaire_Entities_pb.QuestionnaireValidationMessage, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage>} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.setValidationMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.QuestionnaireValidationMessage}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.addValidationMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.questionnaire.QuestionnaireValidationMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.clearValidationMessagesList = function() {
  return this.setValidationMessagesList([]);
};


/**
 * optional string invite_url = 6;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.getInviteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PutQuestionnairePanelCommandResponse.prototype.setInviteUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest;
  return proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse;
  return proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.ValidatePanelInputCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest;
  return proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse;
  return proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string subscription_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.SubscribeQuestionnaireCommandResponse.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest;
  return proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscription_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse;
  return proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.UnsubscribeQuestionnaireCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    retryCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxRetryCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest;
  return proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRetryCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxRetryCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRetryCount();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMaxRetryCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string track_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 retry_count = 3;
 * @return {number}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.getRetryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.setRetryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 max_retry_count = 4;
 * @return {number}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.getMaxRetryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.setMaxRetryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    isComplete: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    responsePanel: (f = msg.getResponsePanel()) && Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject(includeInstance, f),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse;
  return proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsComplete(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.setResponsePanel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getIsComplete();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getResponsePanel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_complete = 2;
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.getIsComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.setIsComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional wilqo.shared.models.Panel response_panel = 3;
 * @return {?proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.getResponsePanel = function() {
  return /** @type{?proto.wilqo.shared.models.Panel} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 3));
};


/**
 * @param {?proto.wilqo.shared.models.Panel|undefined} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.setResponsePanel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.clearResponsePanel = function() {
  return this.setResponsePanel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.hasResponsePanel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string redirect_url = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.PingHandoffStatusCommandResponse.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.CreateLoanCommandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Questionnaire_Entities_pb.DynamicDataItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.CreateLoanCommandRequest;
  return proto.wilqo.api.questionnaire.CreateLoanCommandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 4:
      var value = new Wilqo_API_Questionnaire_Entities_pb.DynamicDataItem;
      reader.readMessage(value,Wilqo_API_Questionnaire_Entities_pb.DynamicDataItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.CreateLoanCommandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Questionnaire_Entities_pb.DynamicDataItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DynamicDataItem items = 4;
 * @return {!Array<!proto.wilqo.api.questionnaire.DynamicDataItem>}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.DynamicDataItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Questionnaire_Entities_pb.DynamicDataItem, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.DynamicDataItem>} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} returns this
*/
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.DynamicDataItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.DynamicDataItem}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.questionnaire.DynamicDataItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandRequest} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.CreateLoanCommandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.CreateLoanCommandResponse;
  return proto.wilqo.api.questionnaire.CreateLoanCommandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.CreateLoanCommandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} returns this
*/
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 deal_version = 3;
 * @return {number}
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.CreateLoanCommandResponse} returns this
 */
proto.wilqo.api.questionnaire.CreateLoanCommandResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.wilqo.api.questionnaire);
