import clsx from 'clsx';
import { useMemo } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { OperatorSummary, RuleSummary } from '@/API/Models/Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels_pb';
import { useOperators } from '@/API/Queries/activities/useOperators';
import { useSingleDataFieldTemplate } from '@/API/Queries/activities/useSingleDataFieldTemplate';
import { AutoComplete } from '@/Components/Atoms/AutoComplete';
import { Button } from '@/Components/Atoms/Button';
import { Icon } from '@/Components/Atoms/Icon';
import { ListItem } from '@/Components/Atoms/ListItem';
import { Menu } from '@/Components/Atoms/Menu';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';
import { TriggerConstant } from '@/Components/Features/trigger/Constant';
import { getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { useWindow } from '@/Utils/Helpers/useWindow';

import { getRulePanelElementType } from './helpers';
import { SearchField } from './SearchField';
import { useTriggerContext } from './TriggerContext';

interface RuleProps {
  ruleGroupId: string;
  rule: RuleSummary.AsObject;
  collapsed: boolean;
  onClickRule: () => void;
  addDefaultAutomatically?: boolean;
}

const Rule = (props: RuleProps) => {
  const { addDefaultAutomatically, collapsed, onClickRule, rule, ruleGroupId } = props;
  const { windowType } = useWindow();

  const { data: operators } = useOperators();
  const { addNewRule, removeRule, updateRuleComparable, updateRuleOperator } = useTriggerContext();

  const operatorsList = useMemo((): Array<OperatorSummary.AsObject> => {
    if (operators && rule.dataField?.dataType) {
      return operators[rule.dataField?.dataType];
    }
    return [];
  }, [operators, rule]);
  const { data: fieldTemplate } = useSingleDataFieldTemplate(rule
    .dataField?.id || '', Boolean(rule.dataField?.id));

  const handleAddRuleGroupClick = () => addNewRule(ruleGroupId);

  const handleRemoveRuleClick = () => {
    removeRule(ruleGroupId, rule.id, addDefaultAutomatically || false);
  };

  const renderActions = () => {
    switch (windowType) {
      case 'mobile':
        return (
          <div className="flex flex-row">
            <Button label="Add rule" leftIcon="Add" onClick={handleAddRuleGroupClick} variant="tertiary" />
            <Button label="Remove" leftIcon="delete" onClick={handleRemoveRuleClick} variant="tertiary" />
          </div>
        );
      case 'desktop':
        return (
          <>
            <Icon className="mr-2" icon="Add" onClick={handleAddRuleGroupClick} variant="interactive" />
            <Icon icon="Delete" onClick={handleRemoveRuleClick} variant="interactive" />
          </>
        );
      case 'tablet':
        return (
          <Menu
            options={[
              {
                label: 'Add Rule',
                onClick: handleAddRuleGroupClick,
              },
              { label: 'Remove', onClick: handleRemoveRuleClick },
            ]}
            triggerItem={<Icon className="!text-on-surface-inactive" icon="MoreVert" />}
          />
        );
      default:
        return null;
    }
  };

  const handleComparableChange = (value: any, type: string, options?: PanelElementOption.AsObject[]) => {
    let comparableValue;
    if (type === 'text' || type === 'number') {
      comparableValue = value;
    } else if (type === 'multi-select') {
      const currentValue = rule.comparableValue?.split(',') || [];
      const currentItems = options?.filter((option) => currentValue.some((value) => value === option.id));

      if (value && !Array.isArray(value)) {
        if (currentItems?.some((currentItem) => currentItem.id === value.id)) {
          const filteredItems = currentItems?.filter((e) => e.id !== value.id);
          comparableValue = filteredItems?.map((e) => e.id).toString();
        } else {
          const filteredItems = currentItems?.concat(value);
          comparableValue = filteredItems?.map((e) => e.id).toString();
        }
      } else if (Array.isArray(value)) {
        if (currentItems?.length === options?.length) {
          comparableValue = [];
        } else {
          comparableValue = options?.map((option) => option.id).toString();
        }
      } else {
        comparableValue = '';
      }
    } else if (type === 'select') {
      comparableValue = value.id;
    }
    updateRuleComparable(ruleGroupId, rule.id, comparableValue);
  };

  const getPanelElementValue = (type: string, options: PanelElementOption.AsObject[]) => {
    let value;
    if (type === 'text' || type === 'number') {
      value = rule.comparableValue;
    }

    if (type === 'multi-select') {
      const currentValue = rule.comparableValue?.split(',') || [];
      value = options?.filter((option) => currentValue.some((value) => value === option.id));
    }

    if (type === 'select') {
      value = options.find((option) => option.id === rule.comparableValue)?.headerText;
    }

    return value;
  };

  const renderPanelElement = () => {
    if (operators && rule.dataField?.dataType) {
      const operatorItem = operators[rule.dataField.dataType]?.find((item) => item.id === rule.operator?.id);
      if (fieldTemplate?.panelElementTemplate && operatorItem && operatorItem.comparableValueRequired) {
        const pe = { ...fieldTemplate.panelElementTemplate };
        pe.type = getRulePanelElementType(pe.type, operatorItem.panelElementTypeOverride);
        if (pe.type === 'constant') {
          return (
            <TriggerConstant
              optionsList={pe.optionsList}
              ruleGroupId={ruleGroupId}
              ruleId={rule.id}
            />
          );
        }
        return (
          <div className="flex-1 mr-4 sm:w-full">
            <PanelElementRenderer
              {...getPanelElement({ ...pe })}
              displayOnly
              onChange={(value) => handleComparableChange(value, pe.type, pe.optionsList)}
              onDropdownApply={(closeDropdown: VoidFunction) => {
                closeDropdown();
              }}
              value={getPanelElementValue(pe.type, pe.optionsList)}
              variant="dense"
            />
          </div>

        );
      }
    }

    return null;
  };

  const renderRuleEditor = () => (
    <div
      className={clsx(
        'flex px-4 p-4 flex-col items-start tablet:py-0 tablet:items-center tablet:flex-row',
        {
          'bg-primary-on-surface-states-activated': windowType === 'mobile' && !collapsed,
        },
      )}
    >
      <div className="flex-1 mr-4 sm:w-full">
        <SearchField
          field={rule.dataField}
          removeRule={handleRemoveRuleClick}
          ruleGroupId={ruleGroupId}
          ruleId={rule.id}
          variant="dense"
        />
      </div>
      <div className="flex-1 mr-4 sm:w-full">
        <AutoComplete
          displayOnly
          onSelect={(operator) => updateRuleOperator(ruleGroupId, rule.id, operator)}
          optionKey="displayName"
          options={operatorsList}
          placeholder="Add operand"
          value={rule.operator?.displayName}
          variant="dense"
        />
      </div>
      {renderPanelElement()}
      {renderActions()}
    </div>
  );

  const renderCollapsedRule = () => (
    <ListItem
      caption="Operator Not Selected"
      divider
      label="Field Not Selected"
      onClick={onClickRule}
    />
  );

  if (!collapsed) return renderRuleEditor();
  return renderCollapsedRule();
};

export { Rule };
