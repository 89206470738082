import { useCallback, useEffect, useMemo, useState } from 'react';

import { type FusionOptionSettings, type FusionWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Fusion_pb';
import { Input } from '@/Components/Atoms/Input';
import { Switch } from '@/Components/Atoms/Switch';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValue } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicDataElementMap, fromString, toNumberFromObject, toStringFromObject } from '@/Utils/Helpers/dynamicDataElementHelper';
import { ConvertEnum, getEnumFromParsedValue } from '@/Utils/Helpers/enumHelpers';
import { getPanelElement } from '@/Utils/Helpers/getPanelElement';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { cn } from '@/Utils/Helpers/uiBuilders';
import { useWindow } from '@/Utils/Helpers/useWindow';

enum InputModeEnum {
  INPUT_MODE_ENUM_ENTER_AMOUNT = 1,
  INPUT_MODE_ENUM_ENTER_PERCENT = 2,
}

interface IFusionCircuitComponent {
  fusionWidget: FusionWidgetSettings.AsObject;
  isLender?: boolean;
}

type IFusionCircuitComponentFieldSettings = Partial<FusionOptionSettings.AsObject> & {
  id: string;
  label: string;
};

const DEFAULT_AMOUNT_FIELD: IFusionCircuitComponentFieldSettings = {
  id: 'amountId',
  label: 'Amount',
};

const DEFAULT_PERCENT_FIELD: IFusionCircuitComponentFieldSettings = {
  id: 'percentId',
  label: 'Percent',
};

export const FusionCircuitComponent = ({
  fusionWidget: {
    caption,
    readOnly,
    switchField,
    switchOptionsList,
    title,
    valueField,
  },
  isLender,
}: IFusionCircuitComponent) => {
  const { fieldHasChanged, onChange, resetProgress, values } = useDynamicForm();
  const { isDesktop } = useWindow();
  const baseValue = useMemo(() => (valueField?.valueField?.value?.value
    ? toNumberFromObject(valueField?.valueField?.value)
    : undefined
  ), [valueField?.valueField?.value]);

  const { amountField, percentField } = useMemo(() => ({
    amountField: switchOptionsList.find((field) => field.label === 'Amount') ?? DEFAULT_AMOUNT_FIELD,
    percentField: switchOptionsList.find((field) => field.label === 'Percent') ?? DEFAULT_PERCENT_FIELD,
  }), [switchOptionsList]);

  const decimalPlaces = useMemo(() => percentField.value?.decimal?.decimalPlaces ?? 0, [percentField]);

  const [inputMode, setInputMode] = useState<InputModeEnum>(InputModeEnum.INPUT_MODE_ENUM_ENTER_AMOUNT);
  const [amount, setAmount] = useState<DynamicDataElementValue>(values[amountField.id]);
  const [percent, setPercent] = useState<DynamicDataElementValue>(values[percentField.id]);

  const handleChangeInputMode = useCallback((value: string) => {
    if (baseValue === undefined) return;

    setInputMode(getEnumFromParsedValue(InputModeEnum, value) ?? InputModeEnum.INPUT_MODE_ENUM_ENTER_AMOUNT);
  }, [baseValue]);

  // If amount changed, calculate percentage based on baseValue and save both
  const handleAmountChange = useCallback((newAmount: DynamicDataElementValue, baseValue: number) => {
    if (newAmount?.value === amount?.value) return;

    setAmount(newAmount);
    const calculatedPercent = (((newAmount?.value || 0) * 100) / baseValue).toFixed(decimalPlaces);
    onChange([percentField.id, { dynamic: fromString(calculatedPercent), value: calculatedPercent }]);
  }, [amount?.value, decimalPlaces, onChange, percentField.id]);

  // If percent changed, calculate amount based on baseValue and save both
  const handlePercentChange = useCallback((newPercent: DynamicDataElementValue, baseValue: number) => {
    if (newPercent?.value === percent?.value) return;

    setPercent(newPercent);
    const calculatedAmount = String(((newPercent?.value || 0) * baseValue) / 100);
    onChange([amountField.id, { dynamic: fromString(calculatedAmount), value: calculatedAmount }]);
  }, [amountField.id, onChange, percent?.value]);

  // Delegate between amount and percentage change handler based on the current input type and whether base value is set
  useEffect(() => {
    if (baseValue == null) return;

    if (inputMode === InputModeEnum.INPUT_MODE_ENUM_ENTER_AMOUNT && fieldHasChanged(amountField.id)) {
      handleAmountChange(values[amountField.id], baseValue);
    } else if (fieldHasChanged(percentField.id)) {
      handlePercentChange(values[percentField.id], baseValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[amountField.id], values[percentField.id]]);

  useEffect(() => {
    if (switchField?.virtualFieldIdentifier?.value) {
      let dynamicValue;
      switch (inputMode) {
        case InputModeEnum.INPUT_MODE_ENUM_ENTER_PERCENT:
          dynamicValue = switchField.optionsList[2].value ? DynamicDataElementMap(switchField.optionsList[2].value) : undefined;
          break;

        case InputModeEnum.INPUT_MODE_ENUM_ENTER_AMOUNT:
        default:
          dynamicValue = switchField.optionsList[1].value ? DynamicDataElementMap(switchField.optionsList[1].value) : undefined;
          break;
      }
      onChange([switchField.virtualFieldIdentifier?.value, { dynamic: dynamicValue }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputMode]);

  useEffect(() => {
    if (switchField?.value) {
      switch (toStringFromObject(switchField?.value)) {
        case 'CalculatePercent':
          setInputMode(InputModeEnum.INPUT_MODE_ENUM_ENTER_PERCENT);
          break;
        case 'CalculateAmount':
          setInputMode(InputModeEnum.INPUT_MODE_ENUM_ENTER_AMOUNT);
          break;
        default:
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchField?.value]);

  useEffect(() => {
    resetProgress({
      values: switchOptionsList.reduce((acc, currentField) => ({
        ...acc,
        [currentField.id ?? 'id']: {
          dynamic: currentField.value?.value ? DynamicDataElementMap(currentField.value?.value) : undefined,
        },
      }), {}),
    });
  }, [switchOptionsList, resetProgress]);

  const labels = (Object.values(InputModeEnum) as Array<keyof typeof InputModeEnum>)
    .filter((enumKey) => typeof enumKey === 'string')
    .map((enumKey) => ({
      disabled: baseValue === undefined || readOnly,
      name: ConvertEnum(InputModeEnum, InputModeEnum[enumKey]),
    }));

  return (
    <div className={cn(
      'rounded-sm',
      { 'p-4 border border-on-surface-stroke': isLender },
    )}
    >
      <Typography variant="display5">
        {title}
      </Typography>

      {
        !valueField?.hidden
        && (
          <Typography className="mb-8 mt-4 text-on-surface-inactive" variant="body2">
            {`${valueField?.label} ${renderValue(valueField?.valueField)}`}
          </Typography>
        )
      }

      <div>
        <Typography className="mb-3 mt-2" variant="body2">
          {caption}
        </Typography>

        <Switch
          className="w-fit"
          disabled={baseValue === undefined || readOnly}
          displayOnly
          onSelect={handleChangeInputMode}
          optionsLabels={labels}
          selectedItem={ConvertEnum(InputModeEnum, inputMode)}
        />

        <div className={cn('grid gap-2 mt-6', {
          'grid-cols-2': !isLender || (isLender && !isDesktop),
          'grid-cols-3': isLender && isDesktop,
        })}
        >
          <Input
            displayOnly={false}
            leftIcon="Money"
            panelElement={{
              ...getPanelElement({ ...amountField.panelElement }),
              disabled: inputMode === InputModeEnum.INPUT_MODE_ENUM_ENTER_PERCENT || readOnly,
              headerText: amountField.label,
              id: amountField.id,
            }}
            placeholder="Amount"
          />
          <Input
            displayOnly={false}
            leftIcon="Percent"
            panelElement={{
              ...getPanelElement({ ...percentField.panelElement }),
              disabled: inputMode === InputModeEnum.INPUT_MODE_ENUM_ENTER_AMOUNT || readOnly,
              headerText: percentField.label,
              id: percentField.id,
              mask: { allowEmptyFormatting: false, customFormat: '', customMask: '', type: 'percentage' },
            }}
            placeholder="Percent"
          />
        </div>
      </div>
    </div>
  );
};
