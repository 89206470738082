import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useState } from 'react';

import { PingHandoffStatusCommandRequest, PingHandoffStatusCommandResponse } from '@/API/Models/Wilqo_API_Questionnaire_Commands_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { useDebounce } from '@/Utils/Hooks/useDebounce';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.questionnaire.PingHandoffStatusCommandRequest';

const useHandoffStatus = (runId: string, trackId: string) => {
  const { commandResponse } = useBackend(true);
  const [count, setCount] = useState(0);
  const debouncedCount = useDebounce(count, 1000);

  const { signup } = useAuth();

  return useQuery([MESSAGE_NAME, runId, trackId, debouncedCount, count], async () => {
    const request = new PingHandoffStatusCommandRequest();
    request.setRunId(runId);
    request.setTrackId(trackId);
    request.setRetryCount(debouncedCount);
    request.setMaxRetryCount(10);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new PingHandoffStatusCommandResponse();
    PingHandoffStatusCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(
      MESSAGE_NAME,
      responseObj.error,
      {
        isComplete: responseObj.isComplete,
        redirectUrl: responseObj.redirectUrl,
        responsePanel: responseObj.responsePanel,
      },
    );
  },
  {
    onSuccess: (data) => {
      if (data) {
        if (!data.isComplete) {
          setCount(count + 1);
        } else if (data.redirectUrl) {
          window.location.replace(data.redirectUrl);
        } else {
          const redirectUri = `${window.location.protocol}//${window.location.host}/mortgage/login`;
          const email = localStorage.getItem('email') || '';
          localStorage.removeItem('email');
          signup(redirectUri, { runId }, email);
        }
      }
    },
  });
};
export { useHandoffStatus };
