import { useCallback, useMemo, useState } from 'react';

import { LoanPageActionType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb';
import { SectionAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb';
import { CustomRenderType, WidgetCollapsedState } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { CellDataModel, TableColumnSettings, TableFormWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm_pb';
import type { WidgetContingency } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { ValidationMessage } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Models_pb';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import type { Column, IHoverActions, TableCellProps } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { getDynamicDialogAction } from '../../actions/DynamicDialogAction';
import { getAddressAction } from '../../dialogs/CentralizedAddress/helpers';
import { DeleteItemCollection } from '../DeleteItemCollection';
import { ScopeSelector } from '../ScopeSelector';
import { useWidgetContext } from '../WidgetContext';

interface Props extends TableFormWidgetSettings.AsObject {
  validationMessages?: ValidationMessage.AsObject[];
  contingency?: WidgetContingency.AsObject;
}

export const TableCollection = (props: Props) => {
  const { actionsList, columnsList, newItemLabel, rowDataList } = props;
  const renderAsCard = props.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_TABLE_FORM_CARD;
  const { showSnackBar } = useShared();
  const { context, reloadMainPage } = useWidgetContext();
  const { setVersion } = useDynamicContext();
  const { loanId } = useDynamicLoanInfo();
  const { toggleAction } = useDynamicContext();

  const [deleteItemScopeToken, setDeleteItemScopeToken] = useState('');

  const isAddress = useMemo(() => props.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_REO_ADDRESS_TABLE || props.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_CENTRALIZED_ADDRESS_TABLE, [props.renderType]);

  const fromCellDataModelToTableCell = useCallback((columnId: string, dataModel: CellDataModel.AsObject): TableCellProps<any> => {
    const { badgeValue, badgeVariant, detailPageId, firstLineList, secondLineList } = dataModel;

    if (badgeValue && badgeVariant) {
      return {
        badge: `${columnId}-badge`,
        type: 'badge',
      };
    }
    let firstLineId = '';
    if (firstLineList && firstLineList.length > 0) {
      firstLineId = `${columnId}-firstLine`;
    }

    let secondLineId = '';
    if (secondLineList && secondLineList.length > 0) {
      secondLineId = `${columnId}-secondLine`;
    }
    return {
      caption: secondLineId,
      onClick: detailPageId || (columnId === 'Address' && isAddress) ? (row) => {
        if (columnId === 'Address' && isAddress) {
          const hiddenColId = props.columnsList.find((col) => col.hidden)?.columnId;
          toggleAction(
            {
              additionalArgsMap: [
                ['id', row[`${hiddenColId}-firstLine`]],
                ['isEdit', 'false'],
              ],
              type: LoanPageActionType.LOAN_PAGE_ACTION_TYPE_CENTRALIZED_ADDRESS,
            },
          );
        } else {
          toggleAction(getDynamicDialogAction({
            mode: 'read',
            pageId: row[`${columnId}-pageId`],
            scopeToken: row[`${columnId}-pageScopeToken`],
            withoutData: false,
          }));
        }
      } : undefined,
      text: firstLineId,
      type: 'text',
    };
  }, [props, isAddress, toggleAction]);

  const widgetColumns = useMemo(() => (rowDataList.length > 0 ? columnsList.reduce((acc: Array<Column<any>>, column: TableColumnSettings.AsObject) => {
    const cellMap = rowDataList.flatMap((e) => e.rowDataMap).find((e) => e[0] === column.columnId);
    const kebabColumnId = columnsList.find((column) => !column.title && !column.hidden)?.columnId;
    if ((column.columnId === 'Kebab' || column.columnId === kebabColumnId) && column.columnId !== 'Subject_Property_Hightlight_Id') return acc;
    const tableColumn: Column<any> = {
      header: column.title,
      hidden: column.hidden,
      id: column.columnId,
      key: cellMap ? fromCellDataModelToTableCell(column.columnId, cellMap[1]) : { text: '', type: 'text' },
    };
    return [...acc, tableColumn];
  }, []) : []), [columnsList, rowDataList, fromCellDataModelToTableCell]);

  const data = useMemo(() => rowDataList.map((data) => ({
    ...data.rowDataMap.reduce((acc, [columnId, cellConfig]) => {
      if (!cellConfig.kebab) {
        let firstLineContent = null;
        if (cellConfig.firstLineList && cellConfig.firstLineList.length > 0) {
          firstLineContent = cellConfig.firstLineList.map((content) => (content ? renderValue(content) : '--'))
            .join(cellConfig.firstLineDelimiter ? ` ${cellConfig.firstLineDelimiter} ` : ' ');
        }
        let secondLineContent = null;
        if (cellConfig.secondLineList && cellConfig.secondLineList.length > 0) {
          secondLineContent = cellConfig.secondLineList.map((content) => (content ? renderValue(content) : '--'))
            .join(cellConfig.secondLineDelimiter ? ` ${cellConfig.secondLineDelimiter} ` : ' ');
        }

        return {
          ...acc,
          [`${columnId}-badge`]: cellConfig.badgeValue ? {
            label: renderValue(cellConfig.badgeValue),
            variant: cellConfig.badgeVariant,
          } : undefined,
          [`${columnId}-pageId`]: cellConfig.detailPageId,
          [`${columnId}-pageScopeToken`]: cellConfig.detailPageScopeToken,
          [`${columnId}-firstLine`]: firstLineContent || undefined,
          [`${columnId}-secondLine`]: secondLineContent || undefined,
          [`${columnId}-icon`]: cellConfig.icon,
          [`${columnId}-iconTooltip`]: cellConfig.iconTooltip,
        };
      }
      return acc;
    }, {}),
  })), [rowDataList]);

  const renderHoverActions = <T extends Record<string, unknown>>(row?: T, index?: number) => {
    const identifier = Number(row?.id) || index;
    if (identifier == null || rowDataList[identifier] == null) return [];

    const kebabColumnId = columnsList.find((column) => !column.title && !column.hidden)?.columnId;
    const cellConfig: CellDataModel.AsObject | undefined = rowDataList[identifier].rowDataMap
      .find(([rowColumnId]) => rowColumnId === 'Kebab' || rowColumnId === kebabColumnId)?.[1];

    const kebab: Array<IHoverActions<any>> = [];

    cellConfig?.kebab?.actionsList.forEach((action) => {
      kebab.push({
        label: action.label,
        onClick: () => {
          toggleAction(action);
        },
      });
    });

    if (cellConfig?.kebab?.editEnabled) {
      const pageId = props.quickAddEditPageId || cellConfig.kebab.editPageId;
      kebab.push({
        icon: 'Edit',
        label: 'Edit',
        onClick: (row) => {
          if (isAddress) {
            const hiddenColId = props.columnsList.find((col) => col.hidden)?.columnId;
            const id = row[`${hiddenColId}-firstLine`];
            toggleAction(getAddressAction(
              {
                id,
                mode: 'edit',
              },
            ));
          } else {
            toggleAction(getDynamicDialogAction(
              {
                mode: 'edit',
                pageId,
                scopeToken: cellConfig.detailPageScopeToken,
                withoutData: false,
              },
            ));
          }
        },
      });
    }

    if (cellConfig?.kebab?.deleteEnabled) {
      kebab.push({
        icon: 'Delete',
        label: 'Delete',
        onClick: () => {
          setDeleteItemScopeToken(cellConfig.detailPageScopeToken);
        },
      });
    }

    return kebab;
  };

  const handleDeleteError = () => {
    setDeleteItemScopeToken('');
    showSnackBar({ message: 'Something went wrong', open: true });
  };

  const handleDeleteSuccess = (version: number) => {
    setVersion(version);
    setDeleteItemScopeToken('');
    showSnackBar({ message: 'Loan updated successfully', open: true });
    reloadMainPage();
  };

  const handleNewItemClick = useCallback((scopeToken = props.newItemScopeToken) => {
    let pageId = props.newItemPageId;
    if (context === 'dialog' && props.quickAddEditPageId) {
      pageId = props.quickAddEditPageId;
    }
    if (isAddress) {
      toggleAction(getAddressAction({
        id: '',
        mode: 'add',
      }));
    } else {
      toggleAction(getDynamicDialogAction({
        mode: 'add',
        pageId,
        scopeToken,
        withoutData: true,
      }));
    }
  }, [isAddress, context, props.newItemPageId, props.newItemScopeToken, props.quickAddEditPageId, toggleAction]);

  const tableActions = useMemo<CardButton[]>(() => {
    const actions: CardButton[] = [];

    if (actionsList.includes(SectionAction.SECTION_ACTION_NEW_ITEM)) {
      actions.push({
        label: newItemLabel || 'add new',
        onClick: () => handleNewItemClick(),
      });
    }
    if (props.renderType === CustomRenderType.CUSTOM_RENDER_TYPE_ADD_WILQO_PARTY_BORROWER) {
      actions.push({
        label: 'add',
        onClick: () => handleNewItemClick(),
      });
    }
    return actions;
  }, [actionsList, handleNewItemClick, newItemLabel, props.renderType]);

  const renderActions = () => {
    if (props.newItemAssociation) {
      return (
        <ScopeSelector
          label={props.newItemLabel}
          newItemVisible={props.newItemVisible}
          onSelectItem={handleNewItemClick}
          settings={props.newItemAssociation}
        />
      );
    }
    return tableActions;
  };

  const renderSubtitle = useMemo(() => (
    <div className="flex flex-col gap-1">
      {props.helpText}
      {!!props.validationMessages?.length && props.validationMessages.map((msg) => msg.messageContent)}
    </div>
  ), [props.helpText, props.validationMessages]);

  return (
    <>
      <Table
        cardProps={{
          defaultOpen: !renderAsCard || props.collapsedState === WidgetCollapsedState.WIDGET_COLLAPSED_STATE_EXPANDED,
          disableAccordion: !renderAsCard ? undefined : true,
          headerProps: {
            actions: renderActions(),
            subtitle: renderSubtitle,
            title: props.title,
          },
          variant: 'border',
        }}
        columns={widgetColumns}
        data={data}
        hoverActions={renderHoverActions}
        rowActionType="button"
        width="stretch"
      />
      {Boolean(deleteItemScopeToken) && (
        <DeleteItemCollection
          close={() => setDeleteItemScopeToken('')}
          dealId={loanId}
          handleDeleteError={handleDeleteError}
          handleDeleteSuccess={handleDeleteSuccess}
          scopeToken={deleteItemScopeToken}
        />
      )}
    </>

  );
};
