import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import type { FieldAttachment } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Circuit/CircuitComponent/FormCircuitComponent_pb';
import type { PanelElement, PanelElementMask } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useAsyncFieldOverride, useFieldOverride } from '@/Components/Features/dynamicForm/useFieldOverride';
import { getAttachId } from '@/Utils/Helpers/attachComponentHelper';
import { useDynamicValueConverters } from '@/Utils/Helpers/dynamicDataConverters';
import { DynamicDataElementMap, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { useWindow } from '@/Utils/Helpers/useWindow';

import type { TextFieldVariant } from '../TextField';
import { Typography } from '../Typography';
import Input, { MaskType } from './Input.component';

interface ExtendedPanelElementProps extends PanelElement.AsObject {
  attach?: FieldAttachment.AsObject;
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  panelElement: ExtendedPanelElementProps;
  showAsQuestion?: boolean;
  variant?: TextFieldVariant;
  leftIcon?: string;
}

export const convertPanelElementMask = (mask?: PanelElementMask.AsObject, panelElementType?: string) => {
  if (panelElementType === 'date') {
    return MaskType.DATE;
  }
  if (!mask) return undefined;
  switch (mask.type) {
    case 'currency':
      return MaskType.CURRENCY;
    case 'date':
      return MaskType.DATE;
    case 'ssn':
      return MaskType.SSN;
    case 'ssn9':
      return MaskType.SSN9;
    case 'phone':
      return MaskType.PHONE;
    case 'percentage':
      return MaskType.PERCENTAGE;
    default:
      return undefined;
  }
};

const TextInput = (props: InputProps) => {
  const { leftIcon, maxLength, panelElement: allPropsPanelElement, showAsQuestion, variant } = props;
  const { attach, ...propsPanelElement } = allPropsPanelElement;

  const override = useFieldOverride(propsPanelElement.id);
  const asyncOverride = useAsyncFieldOverride(propsPanelElement.id);
  const panelElement = useMemo((): PanelElement.AsObject => {
    if (asyncOverride) {
      return {
        ...propsPanelElement,
        disabled: asyncOverride.disabled,
        validatorsList: asyncOverride.validatorsList,
      };
    }
    if (override) {
      return {
        ...propsPanelElement,
        disabled: override?.disabled !== undefined ? override.disabled : propsPanelElement.disabled,
        validatorsList: override ? override?.validators || [] : propsPanelElement.validatorsList,
      };
    }
    return propsPanelElement;
  }, [override, propsPanelElement, asyncOverride]);

  const { converters } = useDynamicValueConverters(panelElement);

  const [value, setValue, { disabled, errorMessage, hidden, ref }] = useDynamicFormRegister(
    panelElement.id,
    converters,
    {
      order: panelElement.order,
      requirement: panelElement.requirement,
      validators: panelElement.validatorsList,
    },
  );
  const { isDesktop } = useWindow();
  // auto-select option from async config call
  useEffect(() => {
    if (asyncOverride && asyncOverride.value) {
      if (asyncOverride.value.dataType === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
        setValue('', '');
      } else {
        setValue(toString(DynamicDataElementMap(asyncOverride.value)), '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncOverride]);

  const handleOnChange = useCallback((value: string) => {
    setValue(value, value, panelElement.path);
  }, [panelElement.path, setValue]);

  const userMessage = errorMessage || panelElement.summaryText;

  const maskType = useMemo(() => convertPanelElementMask(panelElement.mask, panelElement.type), [panelElement.mask, panelElement.type]);

  const component = useMemo(() => (
    <div className={clsx('flex justify-between gap-x-3 w-full', { 'flex-col': !isDesktop, 'flex-row': isDesktop })}>
      {showAsQuestion && (
        <Typography className="w-full text-on-surface-inactive" variant="body1">
          {panelElement.headerText}
        </Typography>
      )}
      <div className={clsx('w-full', { 'max-w-xs': showAsQuestion })}>
        <Input
          ref={ref}
          autoComplete="off"
          disabled={panelElement.disabled || disabled}
          error={Boolean(errorMessage)}
          helperText={userMessage}
          id={panelElement.id}
          isPrivate={panelElement.privateInput}
          label={panelElement.headerText}
          leftIcon={leftIcon}
          mask={maskType}
          maxLength={maxLength}
          onChange={handleOnChange}
          required={Boolean(panelElement.requirement?.required)}
          type={panelElement.type === 'number' ? 'number' : 'string'}
          value={value}
          variant={variant || (panelElement.type === 'text-area' ? 'text-area' : 'default')}
        />
      </div>
    </div>
  ), [isDesktop, showAsQuestion, panelElement.headerText, panelElement.disabled, panelElement.id, panelElement.privateInput, panelElement.requirement?.required, panelElement.type, ref, disabled, errorMessage, userMessage, leftIcon, maskType, maxLength, handleOnChange, value, variant]);

  if (hidden) return null;

  if (attach) {
    const element = document.getElementById(getAttachId(attach));
    if (element) return createPortal(component, element);
  }

  return component;
};

export { TextInput };
