import type { ChecklistItem } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { usePutPanelSequenceProgress } from '@/API/Queries/activities/usePutPanelSequenceProgress';
import { PanelElementForm } from '@/Components/Features/PanelElement/PanelElementForm';

import { useChecklistProgress } from './checklistProgressContext';

interface IChecklistItemFormProps {
  panelElements?: Array<any>;
  isEdit?: boolean;
  checklistItem: ChecklistItem.AsObject;
}

const ChecklistItemForm = (props: IChecklistItemFormProps) => {
  const {
    isEdit = false,
    panelElements = [],
    checklistItem,
  } = props;

  const { addProgress, checklistProgress } = useChecklistProgress();
  const { mutate: mutateProgress } = usePutPanelSequenceProgress();

  const progress = checklistProgress
    .find((progressItem) => progressItem.checklistItem.id === checklistItem.id)?.value;

  const onFormSubmit = (formData: any) => {
    if (!formData || !checklistItem.panel) return;
    const panelId = checklistItem.panel.id;
    const panelElements = Object.keys(formData).map((id) => ({
      id,
      panelId,
      value: {
        dataType: formData[id].dynamic.array[0],
        order: formData[id].dynamic.array[2],
        value: formData[id].dynamic.array[1],
      },
    }));
    mutateProgress({
      elements: panelElements,
      panelId: checklistItem.panel.id,
      panelSequenceId: checklistItem.panelSequenceId,
    });
    addProgress({
      checklistItem,
      value: formData,
    });
  };

  return (
    <div className="text-on-surface-inactive-input">
      <PanelElementForm
        buttonGroupProps={{
          buttons: [
            {
              label: isEdit ? 'Update Info' : 'Mark complete',
              leftIcon: 'Done',
              noCaps: true,
              type: 'submit',
              variant: 'tertiary',
            },
          ],
        }}
        onSubmit={onFormSubmit}
        panelElements={panelElements}
        progress={progress}
      />
    </div>
  );
};

export { ChecklistItemForm };
