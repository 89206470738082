import type { MouseEvent, ReactNode } from 'react';
import { createContext, createElement, useCallback, useContext, useEffect, useState } from 'react';

import { PdfViewer } from '@/Components/Atoms/PdfViewer';
import { Dialog, DialogContent, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Typography } from '@/Components/Atoms/Typography';
import type { HyperLink } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { HyperLinkType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { PanelHyperlinkElement } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_UIComponents_Common_pb';
import tokenator from '@/Utils/Helpers/tokenator';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useGetFile } from '@/API/Queries/other/useGetFile';

interface ISharedProps {
  children: React.ReactNode;
}

export interface ISnackBar {
  message: string;
  open: boolean;
  onClick?: () => void;
  buttonLabel?: string;
}

interface ISharedContextProps {
  snackBar: ISnackBar;
  drawer: boolean;
  sheet: boolean;
  toggleDrawer: () => void;
  toggleSheet: (open?: boolean) => void;
  showSnackBar: (data: ISnackBar | string) => void;
  generateDialogKeywordLinks: (content: string, hyperLinks: HyperLink.AsObject[] | PanelHyperlinkElement.AsObject[]) => ReactNode[];
  openHyperLink: (hyperLink: HyperLink.AsObject) => void;
  isDialogHyperlinkOpen?: boolean;
}

const initialSnackbar: ISnackBar = {
  message: '',
  open: false,
};

const initialState = {
  drawer: false,
  generateDialogKeywordLinks: () => [],
  isDialogHyperLinkOpen: false,
  openHyperLink: () => null,
  sheet: false,
  showSnackBar: () => null,
  snackBar: initialSnackbar,
  toggleDrawer: () => null,
  toggleSheet: () => null,
};

const SharedContext = createContext<ISharedContextProps>(initialState);
SharedContext.displayName = 'useSharedContext';

interface CollapsibleProps {
  label: string;
  content: string;
}
const Collapsible = ({ content, label }: CollapsibleProps) => (
  <details>
    <summary className="text-hyperlink-unclicked">
      {label}
    </summary>
    <p>
      {content}
    </p>
  </details>
);

export const SharedProvider = (props: ISharedProps) => {
  const { children } = props;
  const { windowType } = useWindow();
  const { mutate: getDocument } = useGetFile();

  const [drawer, setDrawer] = useState(initialState.drawer);
  const [sheet, setSheet] = useState(windowType !== 'mobile' ? true : initialState.sheet);
  const [hyperLink, setHyperLink] = useState<HyperLink.AsObject>();
  const [openDialog, setOpenDialog] = useState(false);
  const [snackBar, setSnackBar] = useState(initialState.snackBar);

  useEffect(() => {
    if (windowType === 'desktop') {
      setDrawer(true);
    }
  }, [windowType]);

  const toggleDrawer = () => {
    if (!drawer && sheet && windowType !== 'desktop') {
      setSheet(false);
    }
    setDrawer(!drawer);
  };

  const toggleSheet = (open?: boolean) => {
    if (!sheet && drawer && windowType !== 'desktop') {
      setDrawer(false);
    }
    setSheet(open ?? !sheet);
  };

  const showSnackBar = useCallback((data: ISnackBar | string) => {
    setSnackBar(typeof data === 'string' ? { message: data, open: true } : data);
    setTimeout(() => {
      setSnackBar(initialSnackbar);
    }, 4500);
  }, [setSnackBar]);

  const openHyperLink = useCallback((hyperLink: HyperLink.AsObject) => {
    if ([HyperLinkType.HYPER_LINK_TYPE_MODAL, HyperLinkType.HYPER_LINK_TYPE_PDF].includes(hyperLink.type)) {
      setHyperLink(hyperLink);
      setOpenDialog(true);
    } else if ([HyperLinkType.HYPER_LINK_TYPE_LINK].includes(hyperLink.type)) {
      getDocument(hyperLink.url || '', {
        onError() {
          showSnackBar('There was an error getting the link');
        },
        onSuccess(url) {
          window.open(url, '_blank');
        },
      });
    }
  }, [setHyperLink, setOpenDialog]);

  const generateDialogKeywordLinks = useCallback((content: string, hyperLinks: HyperLink.AsObject[] | PanelHyperlinkElement.AsObject[]) => {
    const tokens = tokenator(content, ...(hyperLinks as HyperLink.AsObject[]).map((hyperlink) => hyperlink.keyword));
    return tokens.map((token) => {
      const hyperLink = (hyperLinks as HyperLink.AsObject[]).find((hyperLink) => hyperLink.keyword === token);
      if (hyperLink) {
        switch (hyperLink.type) {
          case HyperLinkType.HYPER_LINK_TYPE_PDF:
          case HyperLinkType.HYPER_LINK_TYPE_MODAL:
            return createElement('a', {
              className: 'text-primary-on-surface cursor-pointer font-bold',
              key: hyperLink.id,
              onClick: (event: MouseEvent<HTMLAnchorElement>) => {
                event.stopPropagation();
                openHyperLink(hyperLink);
              },
            }, hyperLink.label);
          case HyperLinkType.HYPER_LINK_TYPE_LINK:
            return createElement('a', {
              className: 'text-primary-on-surface cursor-pointer font-bold',
              key: hyperLink.id,
              onClick: (event: MouseEvent<HTMLAnchorElement>) => {
                event.stopPropagation();
                openHyperLink(hyperLink);
              },
            }, hyperLink.label);
          case HyperLinkType.HYPER_LINK_TYPE_COLLAPSIBLE:
            return createElement(Collapsible, {
              content: hyperLink.content,
              key: hyperLink.id,
              label: hyperLink.label,
            });
          default:
            return createElement('span', { key: token }, token);
        }
      }

      return createElement('span', { key: token }, token);
    });
  }, []);

  return (
    <SharedContext.Provider value={{
      drawer,
      generateDialogKeywordLinks,
      isDialogHyperlinkOpen: openDialog,
      openHyperLink,
      sheet,
      showSnackBar,
      snackBar,
      toggleDrawer,
      toggleSheet,
    }}
    >

      <Dialog
        className="h-80 z-[99]"
        onOpenChange={() => setOpenDialog(false)}
        open={openDialog}
        overlayClassName="z-[99]"
        variant={hyperLink?.type === HyperLinkType.HYPER_LINK_TYPE_PDF ? 'full' : 'content'}
      >
        <DialogLayout>
          <DialogHeader title={hyperLink?.title || hyperLink?.label || ''} />

          <DialogContent className="p-4">
            <Typography variant="body1">
              {hyperLink?.type === HyperLinkType.HYPER_LINK_TYPE_PDF ? (
                <PdfViewer file={hyperLink?.url || ''} />
              ) : hyperLink?.content}
            </Typography>
          </DialogContent>
        </DialogLayout>
      </Dialog>

      {children}
    </SharedContext.Provider>
  );
};

export const useShared = () => useContext(SharedContext);
