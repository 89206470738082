import clsx from 'clsx';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

export interface ISkeletonProps {
  variant: 'circle' | 'mix' | 'rect';
  width?: string;
  height?: Array<number | string> | number | string;
  margin?: string;
  rows?: number;
  color?: 'default' | 'primary' | 'white';
  delay?: string;
}

const Skeleton = (props: ISkeletonProps) => {
  const {
    color = 'default',
    delay = '1',
    height = '60px',
    margin = '6px',
    rows = 1,
    variant,
  } = props;

  const memoizeRows = useMemo(() => [...Array(rows)].map(() => ({ id: uuid() })), [rows]);
  const getColor = clsx(
    {
      'bg-on-surface-stroke': color === 'default',
      'bg-primary': color === 'primary',
      'bg-surface': color === 'white',
    },
  );

  const renderSkeletonItem = (variant: string, height: number | string, margin = '0') => {
    if (variant === 'circle') {
      return (
        <div
          key={uuid()}
          className={clsx(
            getColor,
            delay,
            `rounded-full h-[45px] my-3 ml-0 mr-4 min-w-[45px] w-[45px] animate-skeleton delay-[${delay}s]`,
          )}
        />
      );
    }

    return (
      <div
        key={uuid()}
        className={clsx(
          getColor,
          delay,
          `rounded animate-skeleton delay-[${delay}s]`,
        )}
        id={`animation-${variant}-${height}`}
        style={{ height, margin }}
      />
    );
  };

  const renderSkeleton = () => {
    if (variant === 'mix') {
      return (
        <>
          {memoizeRows.map((row) => (
            <div
              key={row.id}
              className={clsx(
                delay,
                `items-center flex flex-nowrap m-[${margin}] w-full animate-skeleton visible delay-[${delay}s]`,
              )}
            >
              {renderSkeletonItem('circle', '30px', '30px')}
              <div className="w-full">
                {renderSkeletonItem('rect', '12px', '6px 0')}
                {renderSkeletonItem('rect', '12px', '6px 0')}
              </div>
            </div>
          ))}
        </>
      );
    }
    return (
      <>
        {memoizeRows.map((_, idx) => renderSkeletonItem(variant, Array.isArray(height) ? height[idx] : height, margin)) }
      </>
    );
  };

  return renderSkeleton();
};

export { Skeleton };
