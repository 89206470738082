import { useMemo } from 'react';

import { useQuestionnaires } from '@/API/Queries/questionnaire/useQuestionnaires';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';

interface TableDataItem {
  id: string;
  title: string;
  published: string;
}

const QuestionnaireList = () => {
  const { data: questionnaires = [], isLoading } = useQuestionnaires();

  const columns = useMemo((): Array<Column<TableDataItem>> => [
    {
      header: 'Name',
      id: 'name',
      key: {
        text: 'title',
        type: 'text',
      },
    },
    {
      header: 'Published',
      id: 'published',
      key: {
        text: 'published',
        type: 'text',
      },
    },
  ], []);

  const data = useMemo(() => questionnaires.map((questionnaire) => ({
    id: questionnaire.id,
    published: getDateFromTimestamp(questionnaire.updateDate) || '',
    title: questionnaire.name,
  })), [questionnaires]);

  return (
    <Table
      cardProps={{ headerProps: { title: 'All Consumer Inquiries' } }}
      columns={columns}
      data={data}
      isLoading={isLoading}
    />
  );
};

export { QuestionnaireList };
