import { useEffect, useMemo, useRef, useState } from 'react';
import type { DragStart, DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { FormProvider, useForm } from 'react-hook-form';

import type { Panel } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DataFieldTemplateSummary } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb';
import type { BatchReorderPanelCommandRequest, PanelSequenceDesignCommandResponse } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { useAddDesignerPanel } from '@/API/Queries/activities/useAddDesignerPanel';
import { useAddDesignerPanelElement } from '@/API/Queries/activities/useAddDesignerPanelElement';
import { useAssetsSearch } from '@/API/Queries/activities/useAssetsSearch';
import { useBatchReorderPanel } from '@/API/Queries/activities/useBatchReorderPanel';
import { useDataFieldTemplate } from '@/API/Queries/activities/useDataFieldTemplate';
import { useDataFieldTemplatesForContent } from '@/API/Queries/activities/useDataFieldTemplateForContent';
import { useDeletePanel } from '@/API/Queries/activities/useDeletePanel';
import { useDeletePanelElement } from '@/API/Queries/activities/useDeletePanelElement';
import { useReorderPanelElement } from '@/API/Queries/activities/useReorderPanelElement';
import { ContextualNavDrawer } from '@/Components/Atoms/ContextualNavDrawer';
import { Dialog } from '@/Components/Atoms/Dialog';

import { useActivityConfiguratorContext } from '../activityConfiguratorContext';
import type { MainDropAreaRef } from './mainDropArea/mainDropArea';
import { MainDropArea } from './mainDropArea/mainDropArea';
import { ReorderPanelsModal } from './reorderPanelsModal/reorderPanelsModal';

const INITIAL_CONFIRMATION_MODAL = { message: '', open: false, title: '', type: '' };
export enum ContextualNavTabEnum {
  PAGES = 0,
  FIELDS,
  ASSETS,
}

const ActivityConfiguratorContent = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(ContextualNavTabEnum.PAGES);
  const [confirmationModal, setConfirmationModal] = useState(INITIAL_CONFIRMATION_MODAL);
  const [reorderPanelsModal, setReorderPanelsModal] = useState(false);
  const [fieldSearchText, setFieldSearchText] = useState('');
  const [assetsSearchText, setAssetsSearchText] = useState('');
  const [draggedId, setDraggedId] = useState('');

  const { data: fields = [] } = useDataFieldTemplatesForContent(fieldSearchText);
  const { data: assets = [] } = useAssetsSearch(assetsSearchText);
  const selectedTemplate = useDataFieldTemplate(currentTabIndex, draggedId);

  const {
    designerPanel: { runtime },
    selectedPanel,
    selectedPanelElement,
    setSelectedPanel,
    setSelectedPanelElement,
    setSidesheetType,
    updateDesignerPanel,
  } = useActivityConfiguratorContext();
  const { mutate: deletePanel } = useDeletePanel();
  const { mutate: deletePanelElement } = useDeletePanelElement();
  const { mutate: addDesignerPanelElement } = useAddDesignerPanelElement();
  const { mutate: addDesignerPanel } = useAddDesignerPanel();
  const { mutate: batchReorderPanel } = useBatchReorderPanel();
  const { mutate: reorderPanelElement } = useReorderPanelElement();
  const useFormMethods = useForm({
    criteriaMode: 'all',
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });
  const mainDropAreaRef = useRef<MainDropAreaRef>(null);

  useEffect(() => {
    setSidesheetType('none');
  }, [setSidesheetType]);

  const updateContextState = (response?: PanelSequenceDesignCommandResponse.AsObject) => {
    if (response) {
      updateDesignerPanel({
        design: response.design?.panelsList,
        runtime: response.runtime?.panelsList,
      });
    }
  };

  const onClickPanelElement = (panelIndex: number, panelElementIndex: number) => {
    setSelectedPanel(panelIndex);
    setSelectedPanelElement(panelElementIndex);
    setSidesheetType('panel-element');
    mainDropAreaRef.current?.scrollPanelIntoView(panelIndex);
  };

  const handleAddPanel = () => {
    setSelectedPanelElement(null);
    addDesignerPanel(undefined, { onSuccess: updateContextState });
    setSelectedPanel(runtime.length);
  };

  const handleDelete = (type: string) => {
    if (type === 'panel') {
      deletePanel(runtime[selectedPanel].id, {
        onSuccess: (response) => {
          if (response && response.design?.panelsList.length === 0) {
            addDesignerPanel(undefined, { onSuccess: updateContextState });
            setSelectedPanel(0);
          } else if (selectedPanel > 0) {
            setSelectedPanel(selectedPanel - 1);
            updateContextState(response);
          }
        },
      });
    }
    if (type === 'panelElement') {
      setSelectedPanelElement(null);
      const panelId = runtime[selectedPanel].id;
      const panelElementId = runtime[selectedPanel].panelElementsList[selectedPanelElement || 0].id;
      deletePanelElement({ panelElementId, panelId }, { onSuccess: updateContextState });
    }
    setConfirmationModal((prevState) => ({
      ...prevState,
      message: '',
      open: false,
      title: '',
      type: '',
    }));
  };

  const renderConfirmationModal = () => {
    const { message, open, title, type } = confirmationModal;
    if (open) {
      return (
        <Dialog
          actions={[
            {
              label: 'Delete',
              onClick: () => handleDelete(type),
              type: 'button',
            },
          ]}
          dismissButtonLabel="Cancel"
          onClickDismiss={() => setConfirmationModal(INITIAL_CONFIRMATION_MODAL)}
          size="small"
          title={title}
        >
          <span className="text-on-surface-active">{message}</span>
        </Dialog>
      );
    }
    return null;
  };

  const handleDeleteModal = (type: string, panelIndex?: number, panelElementIndex?: number) => {
    if (type === 'panel' && typeof panelIndex === 'number') {
      setSelectedPanel(panelIndex);
      setConfirmationModal({
        message: 'Are you sure you want to delete this page?',
        open: true,
        title: `Delete page ${panelIndex + 1}`,
        type: 'panel',
      });
    }
    if (type === 'panelElement' && typeof panelElementIndex === 'number' && typeof panelIndex === 'number') {
      const element = runtime[panelIndex].panelElementsList[panelElementIndex || 0];
      setSelectedPanel(panelIndex);
      setSelectedPanelElement(panelElementIndex);
      setConfirmationModal({
        message: `Are you sure you want to delete:  ${element.headerText}`,
        open: true,
        title: 'Delete Element',
        type: 'panelElement',
      });
    }
  };

  const handleOnDragEnd = async ({ destination, source }: DropResult) => {
    if (!destination) return;
    const destinationDropPanelIndex = runtime.findIndex((data) => data.id === destination.droppableId);
    if (source.droppableId === 'CONTROLS') {
      if (selectedTemplate && selectedTemplate.panelElementTemplate) {
        addDesignerPanelElement({
          index: destination.index,
          panelElementTemplateId: selectedTemplate.panelElementTemplate.id,
          panelId: runtime[destinationDropPanelIndex].id,
        }, { onSuccess: updateContextState });
      }
    } else {
      const sourceDropPanelIndex = runtime.findIndex((data) => data.id === source.droppableId);
      const panelId = runtime[sourceDropPanelIndex].id;
      const panelElementId = runtime[sourceDropPanelIndex].panelElementsList[source.index].id;
      const newPanelId = runtime[destinationDropPanelIndex].id;
      const newIndex = destination.index;
      setSelectedPanel(destinationDropPanelIndex);
      reorderPanelElement({
        newIndex,
        newPanelId,
        panelElementId,
        panelId,
      }, { onSuccess: updateContextState });
    }
  };

  const handleOnDragStart = ({ draggableId }: DragStart) => {
    setDraggedId(draggableId);
    setSelectedPanelElement(null);
  };

  const getNavFromPanel = (panel: Panel.AsObject, panelIndex: number) => ({
    id: panel.id,
    label: `Page ${panelIndex + 1}`,
    onClick: () => onClickPanelElement(panelIndex, 0),
    selected: panelIndex === selectedPanel,
    submenuListList: panel.panelElementsList.map((panelElement, panelElementIndex) => ({
      id: panelElement.id,
      label: panelElement.headerText || `Element ${panelElementIndex + 1}`,
      onClick: () => onClickPanelElement(panelIndex, panelElementIndex),
      selected: selectedPanel === panelIndex && panelElementIndex === selectedPanelElement,
    })),
  });

  const onConfirmReorderPanels = (panelMoves: BatchReorderPanelCommandRequest.MoveDetail.AsObject[]) => {
    batchReorderPanel(panelMoves, { onSuccess: updateContextState });
  };

  const renderReorderPanelsModal = () => reorderPanelsModal && (
    <ReorderPanelsModal
      closeModal={() => setReorderPanelsModal(false)}
      onConfirm={onConfirmReorderPanels}
      runtimePanels={runtime}
    />
  );
  const searchFieldItems = useMemo(() => fields.map((field) => ({
    id: field.id,
    label: field.name,
    leadingIconProps: { icon: 'DragIndicator' },
    tooltip: field.pathname,
  })), [fields]);

  const assetItems = useMemo(() => assets.map((field: DataFieldTemplateSummary.AsObject) => ({
    id: field.id,
    label: field.name,
    leadingIconProps: { icon: 'DragIndicator' },
  })), [assets]);

  return (
    <FormProvider {...useFormMethods}>
      {renderConfirmationModal()}
      {renderReorderPanelsModal()}
      <div className="flex flex-1 flex-row h-full">
        <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
          <ContextualNavDrawer
            onSelectTab={(item) => setCurrentTabIndex(Number(item.id))}
            selectedTabIndex={currentTabIndex}
            tabs={[
              {
                id: '0',
                menus: runtime.map((panel, index) => ({
                  expanded: true,
                  id: panel.id,
                  navigation: getNavFromPanel(panel, index),
                  variant: 'square',
                })),
                onClickItem: () => { },
                title: 'Pages',
                type: 'menu',
              },
              {
                id: '1',
                list: searchFieldItems,
                onChangeText: setFieldSearchText,
                searchText: fieldSearchText,
                title: 'Fields',
                type: 'search',
              },
              {
                id: '2',
                list: assetItems,
                onChangeText: setAssetsSearchText,
                searchText: assetsSearchText,
                title: 'Assets',
                type: 'search',
              },
            ]}
          />
          <div className="bg-surface-variant flex-1">
            <MainDropArea
              ref={mainDropAreaRef}
              handleAddPanel={handleAddPanel}
              handleDeleteModal={handleDeleteModal}
              onClickPanelElement={onClickPanelElement}
              setReorderPanelsModal={setReorderPanelsModal}
            />
          </div>
        </DragDropContext>
      </div>
    </FormProvider>
  );
};

export { ActivityConfiguratorContent };
