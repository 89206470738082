import { MobileLayout } from '@/Components/Layouts/NewLayoutMobile';

export const LoanListPageMobile = () => (
  <MobileLayout.Root>
    <MobileLayout.Header title="Loans" />
    <MobileLayout.Content>
      <span>Hey</span>
    </MobileLayout.Content>
  </MobileLayout.Root>
);
