import { useMemo } from 'react';

import { usePublishFeeTemplate } from '@/API/Queries/mortgageConfig/usePublishFeeTemplate';
import type { IButtonProps } from '@/Components/Atoms/Button';
import { Dialog } from '@/Components/Atoms/Dialog';

interface Props {
  onClose: () => void;
  handlePublish: () => void;
  title: string;
  innerText: string;
}

export const TriggerWarning = (props: Props) => {
  const { handlePublish, innerText, onClose, title } = props;
  const { isLoading: isPublishing } = usePublishFeeTemplate();

  const actions = useMemo((): Array<IButtonProps> => {
    const cancelAction: IButtonProps = { label: 'CANCEL', onClick: onClose, variant: 'tertiary' };
    const publishAction: IButtonProps = { isLoading: isPublishing, label: 'Yes', onClick: handlePublish, variant: 'primary' };

    return [cancelAction, publishAction];
  }, [handlePublish, isPublishing, onClose]);

  return (
    <Dialog
      actions={actions}
      onClickDismiss={onClose}
      size="medium"
      title={title}
    >
      {innerText}
    </Dialog>
  );
};
