import clsx from 'clsx';

export interface DividerProps {
  className?: string;
  type?: 'active' | 'default';
}

const Divider = (props: DividerProps) => {
  const { className, type } = props;

  const dividerCname = clsx(
    `${className} w-full border-t border-on-surface-stroke m-0`,
    {
      'border-primary-on-surface': type === 'active',
    },
  );

  return <div className={dividerCname} />;
};

export { Divider };
