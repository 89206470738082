import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import type { AdminActivitySummary } from '@/API/Models/Wilqo_API_Activity_Admin_pb';
import { UxDisplayStatusEnum } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import { Badge } from '@/Components/Atoms/Badge';
import type { TableBuilderColumn } from '@/Components/Features/TableBuilder';
import { TableBuilder, TableBuilderDataTypeEnum } from '@/Components/Features/TableBuilder';

const SkillsetActivities = () => {
  const { id = '' } = useParams();

  const renderBadge = useCallback((activity: AdminActivitySummary.AsObject) => {
    const isAssigned = activity.skillSetsList.some((skillsetId) => skillsetId === id);
    if (isAssigned) {
      return (
        <Badge
          label="Assigned"
          variant={UxDisplayStatusEnum.UX_DISPLAY_STATUS_ENUM_COMPLETE}
        />
      );
    }
    return null;
  }, [id]);

  const columns = useMemo((): TableBuilderColumn => [
    ['Name', 'title'],
    ['Assigned', { type: 'any', value: renderBadge }],
  ], [renderBadge]);

  return (
    <TableBuilder
      cardProps={{ headerProps: { title: 'All activities' } }}
      columns={columns}
      dataType={TableBuilderDataTypeEnum.ACTIVITIES}
      orderBy="Assigned"
    />
  );
};

export { SkillsetActivities };
