import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import type { Widget, WidgetContent } from '@/API/Models/Wilqo_API_Brand_Models_pb';
import type { PutQuestionnairePanelCommandResponse } from '@/API/Models/Wilqo_API_Questionnaire_Commands_pb';
import { usePutQuestionnaire } from '@/API/Queries/questionnaire/usePutQuestionnaire';
import { AccordionPanel } from '@/Components/Atoms/AccordionPanel';
import type { IButtonProps } from '@/Components/Atoms/Button';
import { Button } from '@/Components/Atoms/Button';
import { CoverImage } from '@/Components/Atoms/CoverImage';
import { Footer } from '@/Components/Atoms/Footer';
import { Loader } from '@/Components/Atoms/Loader';
import { MobileProgressAppBar } from '@/Components/Atoms/MobileProgressAppBar';
import { ProgressAppBar } from '@/Components/Atoms/ProgressAppBar';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValues, SubmitValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementForm } from '@/Components/Features/PanelElement/PanelElementForm';
import { useNavigate } from '@/Routes/NavigationContext';
import { convertRichText } from '@/Utils/Helpers/richTextConversion';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { useQuestionnaireData } from './useQuestionnaireData';

const QuestionnaireComponent = () => {
  const { panelId = '' } = useParams();
  const { windowType } = useWindow();
  const { generateDialogKeywordLinks } = useShared();
  const navigate = useNavigate();

  const {
    isLoading,
    navigationButtons,
    pageConfiguration,
    progress: questionnaireProgress,
    questionnaire,
    saveProgressInCache,
  } = useQuestionnaireData();
  const panelSequence = questionnaire?.questionnaire;
  const footer = panelSequence?.modifiedFooter;

  const {
    isLoading: saving,
    mutate: putQuestionnairePanel,
  } = usePutQuestionnaire();

  useEffect(() => {
    if (panelId === 'consumer') {
      sessionStorage.removeItem('loanId');
    }
  }, [panelId]);

  // find current panel
  const panel = useMemo(() => panelSequence?.panelsList.find((panel) => panel.id === panelId), [panelSequence, panelId]);

  const progress = useMemo(() => {
    if (questionnaireProgress[panelId]) {
      return {
        path: panel?.panelElementsList[panel.panelElementsList.length - 1].path || '',
        values: questionnaireProgress[panelId],
      };
    }
    return undefined;
  }, [questionnaireProgress, panelId, panel]);

  const currentStepIndex: number = useMemo(() => panelSequence?.panelsList?.findIndex((item) => item.id === panel?.id) || 0, [panelSequence, panel?.id]);
  const currentStep = currentStepIndex + 1;

  const config = useMemo(() => {
    if (pageConfiguration) {
      const pageData = pageConfiguration.routesList.find((route) => route.path === '/questionnaire')?.pageData;
      const mainAreaWidgets: Widget.AsObject | undefined = pageData?.widgetsList.filter((type) => type.area === 'main')[0];
      const sideAreaWidgets: Widget.AsObject | undefined = pageData?.widgetsList.filter((type) => type.area === 'sidebar')[0];
      const layoutsList = pageData?.layoutsList.map((layout) => layout.value);

      return { layoutsList, mainAreaWidgets, sideAreaWidgets };
    }
    return null;
  }, [pageConfiguration]);

  if (isLoading || !pageConfiguration || !panel || !panelSequence) return <Loader />;

  const handleSubmitSuccess = (response?: PutQuestionnairePanelCommandResponse.AsObject, path?: string, formData?: DynamicDataElementValues) => {
    if (response?.inviteUrl && response?.inviteUrl !== '') {
      navigate(response.inviteUrl.replaceAll(/^\/mortgage/g, ''));
      return;
    }

    if (path && response) {
      const loanId = sessionStorage.getItem('loanId') || '';
      if (formData?.email) {
        localStorage.setItem('email', formData?.email.value);
      }
      navigate(path, {
        state: {
          panel: response.responsePanel,
          runId: loanId,
          trackId: response.trackId,
        },
      });
    }
  };

  const handleSubmit = (formData: DynamicDataElementValues, { path }: SubmitValues, accordionId: string) => {
    saveProgressInCache(panelId, formData);
    const loanId = sessionStorage.getItem('loanId') || '';
    putQuestionnairePanel({
      accordionId,
      elements: Object.keys(formData).map((id) => ({
        id,
        panelId,
        value: {
          dataType: formData[id].dynamic.array[0],
          order: formData[id].dynamic.array[2],
          value: formData[id].dynamic.array[1],
        },
      })),
      panelId,
      questionnaireRunId: loanId,
    }, { onSuccess: (response) => handleSubmitSuccess(response, path, formData) });
  };

  const renderProgressBar = (panelHeaderText: string) => {
    if (panelSequence.panelsList.length <= 1) {
      return null;
    }

    if (windowType === 'mobile') {
      return (
        <MobileProgressAppBar
          currentStep={currentStep}
          logoExternal={panelSequence.modifiedHeader?.external}
          panelHeaderText={panelHeaderText}
          stepsLength={panelSequence.panelsList.length}
        />
      );
    }

    return (
      <ProgressAppBar
        currentStep={currentStep}
        logo={windowType !== 'desktop'}
        logoExternal={panelSequence.modifiedHeader?.external}
        logoRedirect={panelSequence.modifiedHeader?.linkTo}
        stepsLength={panelSequence?.panelsList.length}
      />
    );
  };

  const getButtonProps = (): Array<IButtonProps> => {
    if (panel.showNext) {
      return [{
        isLoading: saving,
        label: panel?.id === 'create-account'
          ? navigationButtons['panel-sequence-create-account-button'] : navigationButtons['panel-sequence-next-button'],
        type: 'submit',
        variant: 'primary',
      }];
    }
    return [];
  };

  const renderMainContent = () => {
    if (isLoading) {
      return <Skeleton height="90px" rows={4} variant="rect" />;
    }

    if (panel?.type?.value === 'accordionGroup') {
      return (
        <AccordionPanel
          navigationButtons={navigationButtons}
          onSubmit={handleSubmit}
          panel={panel}
          panels={panelSequence.panelsList}
          progress={progress}
        />
      );
    }

    return (
      <PanelElementForm
        buttonGroupProps={{ buttons: getButtonProps(), fullWidth: true, isLoading: saving }}
        onSubmit={(v, e) => handleSubmit(v, e, '')}
        panelElements={panel.panelElementsList.map((panelElement) => ({
          ...panelElement,
          enrichedHeaderText: generateDialogKeywordLinks(panelElement.headerText, panel.hyperlinksList),
        }))}
        progress={progress}
      />
    );
  };

  const renderWidget = (component: WidgetContent.AsObject) => {
    switch (component.name) {
      case 'questionnaire': {
        const panelHeaderText = panel.headerText.includes('{{') ? '' : panel.headerText;
        return (
          <div key={component.name} className="flex flex-col h-full overflow-y-auto">
            <div className="flex flex-1 flex-col justify-between overflow-y-auto">
              {renderProgressBar(panelHeaderText)}
              <div className="grid grid-cols-1">
                <div className="desktop:p-12 p-4">
                  {panel?.headerText && windowType !== 'mobile'
                    && (
                      <Typography className="mb-6 text-center" variant="display5">
                        {panelHeaderText}
                      </Typography>
                    )}
                  {panel?.summaryText
                    && <span className="block my-3 mx-0 opacity-[.6] text-center">{convertRichText(panel.summaryText)}</span>}
                  {renderMainContent()}
                  {panel?.footerText && (
                    <Typography className="mt-6 mb-6 text-on-surface-inactive" variant="body2">
                      {generateDialogKeywordLinks(panel.footerText, panel.hyperlinksList)}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="bg-surface-variant">
                <Footer dataFooter={footer} />
              </div>
            </div>
            {panelSequence?.panelsList.length > 1 && (
              <div
                className="h-[52px] py-0 px-4 md:h-[72px] md:px-8 items-center flex w-full z-[1] bg-surface border-t-[1px] border-on-surface-stroke"
              >
                <Button
                  disabled={currentStep <= 1}
                  label={navigationButtons['panel-sequence-back-button']}
                  onClick={() => window.history.back()}
                  variant="tertiary"
                />
              </div>
            )}
          </div>
        );
      }
      case 'coverImage': {
        return (
          <CoverImage
            key={component.name}
            coverImage={panel.coverImage}
            logoExternal={panelSequence.modifiedHeader?.external}
            logoRedirect={panelSequence.modifiedHeader?.linkTo}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (config) {
      const { layoutsList, mainAreaWidgets, sideAreaWidgets } = config;
      if (layoutsList && layoutsList.length >= 2) {
        return (
          <div className="grid sm:grid-cols-2 h-full">
            <div className="hidden sm:block">
              {mainAreaWidgets && mainAreaWidgets.contentList.map((component) => renderWidget(component))}
            </div>
            {sideAreaWidgets && sideAreaWidgets.contentList.map((component) => renderWidget(component))}
          </div>
        );
      }

      return (
        <div className="grid grid-cols-1 h-full">
          {sideAreaWidgets && sideAreaWidgets.contentList.map((component) => renderWidget(component))}
        </div>

      );
    }
    return null;
  };

  if (isLoading) return <Loader />;
  return (
    <div className={clsx('h-full w-full', { 'cursor-wait': saving })}>
      {renderContent()}
    </div>
  );
};

const Questionnaire = () => (
  <div className="bg-surface h-full">
    <Helmet>
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta content="black-translucent" name="apple-mobile-web-app-status-bar-style" />
    </Helmet>
    <QuestionnaireComponent />
  </div>
);

export { Questionnaire };
