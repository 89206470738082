import { useMemo } from 'react';
import Markdown from 'react-markdown';

import type { DynamicTextWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/DynamicText_pb';
import { Button } from '@/Components/Atoms/Button';
import { Icon } from '@/Components/Atoms/Icon';
import { Typography } from '@/Components/Atoms/Typography';
import { renderTemplate } from '@/Utils/Helpers/renderTemplateString';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

export const MessageCopy = (props: DynamicTextWidgetSettings.AsObject) => {
  const { showSnackBar } = useShared();

  const text = useMemo(() => props.textContentsList.reduce((acc, current) => `${acc}${renderTemplate(current)}`, ''), [props]);

  const handleClickCopy = () => {
    const divElement = document.getElementById('text') as HTMLElement;
    if (divElement) {
      const divClone = divElement.cloneNode(true) as HTMLElement;
      divClone.style.position = 'absolute';
      divClone.style.left = '-9999px';
      document.body.appendChild(divClone);

      const range = document.createRange();
      range.selectNode(divClone);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }

      try {
        navigator.clipboard.write([new ClipboardItem({ 'text/html': new Blob([divClone.innerHTML], { type: 'text/html' }) })]);
        showSnackBar({ message: 'Text copied.', open: true });
      } catch (err) {
        showSnackBar({ message: 'Unable to copy text', open: true });
      }

      if (selection) {
        selection.removeAllRanges();
      }
      document.body.removeChild(divClone);
    }
  };

  return (
    <div className="flex flex-col gap-6 items-center">
      {props.header?.icon && (
        <Icon
          icon={props.header?.icon}
          size="lg"
          variant="fixed-background"
        />
      )}
      <Typography variant="display6">
        {props.header?.title}
      </Typography>
      <div className="ignore-styles" id="text">
        <Markdown>
          {text}
        </Markdown>
      </div>
      {props.displayClipboardCopyAction && <Button label="Copy text" leftIcon="Copy" onClick={handleClickCopy} />}
    </div>
  );
};
