import type { ComponentProps } from './Input.component';
import Component from './Input.component';
import type { InputProps as DDEProps } from './Input.dde';
import { TextInput as DDE } from './Input.dde';

interface ExtendedComponentProps extends ComponentProps {
  displayOnly: true;
}

interface ExtendedDDEProps extends DDEProps {
  displayOnly: false;
}

export type InputProps = ExtendedComponentProps | ExtendedDDEProps;

const Input = (props: InputProps) => {
  const { displayOnly } = props;

  if (displayOnly) return <Component {...props as ComponentProps} />;
  return <DDE {...props as DDEProps} />;
};

export { Input };
