// source: Wilqo_API_Activity_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var Wilqo_API_Users_InstitutionModels_pb = require('./Wilqo_API_Users_InstitutionModels_pb.js');
goog.object.extend(proto, Wilqo_API_Users_InstitutionModels_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_Configuration_pb = require('./Wilqo.Shared.Models/Configuration_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Configuration_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('./Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_DynamicModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb = require('./Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Validations_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb);
goog.exportSymbol('proto.wilqo.api.activity.ActivitiesByEntityArgs', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivitiesBySkillSetArgs', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivitiesSubscriptionContext', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivitiesSubscriptionMessage', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityChecklist', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityPanelSequence', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityStatus', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivitySummary', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivitySummaryField', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivitySummaryFilters', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityTemplateSummary', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityType', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityValidationChecklist', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityVersion', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ActivityVersionGroup', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AsyncValidation', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AsyncValidationItem', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AsyncValidationPanelElementWrapper', null, global);
goog.exportSymbol('proto.wilqo.api.activity.AsyncValidationTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.activity.BusinessProcessTemplateForActivity', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ExternalActivity', null, global);
goog.exportSymbol('proto.wilqo.api.activity.FieldTypeEnum', null, global);
goog.exportSymbol('proto.wilqo.api.activity.IntegrationTypeLocalEnum', null, global);
goog.exportSymbol('proto.wilqo.api.activity.IntegrationVendorEnum', null, global);
goog.exportSymbol('proto.wilqo.api.activity.InternalNameValidation', null, global);
goog.exportSymbol('proto.wilqo.api.activity.OnDemandActivity', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PagingArgs', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PagingArgs.SortType', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PagingInfo', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PanelSequence', null, global);
goog.exportSymbol('proto.wilqo.api.activity.PanelSequenceNavigationButton', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ProgressItem', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ProgressItemSummary', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ProgressType', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ProgressTypeSummary', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ScopeReference', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ScopeReferenceAppliesToEnum', null, global);
goog.exportSymbol('proto.wilqo.api.activity.StashPanelResponseSummary', null, global);
goog.exportSymbol('proto.wilqo.api.activity.TemplateVariable', null, global);
goog.exportSymbol('proto.wilqo.api.activity.TemplateVariableDataType', null, global);
goog.exportSymbol('proto.wilqo.api.activity.TriggerType', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UnpublishedActivity', null, global);
goog.exportSymbol('proto.wilqo.api.activity.UnpublishedActivityGroup', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ValidationConfig', null, global);
goog.exportSymbol('proto.wilqo.api.activity.ValidationTypesEnum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.TemplateVariable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.TemplateVariable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.TemplateVariable.displayName = 'proto.wilqo.api.activity.TemplateVariable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivitySummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitySummary.displayName = 'proto.wilqo.api.activity.ActivitySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitySummaryFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitySummaryFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitySummaryFilters.displayName = 'proto.wilqo.api.activity.ActivitySummaryFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityPanelSequence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivityPanelSequence.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityPanelSequence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityPanelSequence.displayName = 'proto.wilqo.api.activity.ActivityPanelSequence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityChecklist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivityChecklist.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityChecklist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityChecklist.displayName = 'proto.wilqo.api.activity.ActivityChecklist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityValidationChecklist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivityValidationChecklist.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityValidationChecklist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityValidationChecklist.displayName = 'proto.wilqo.api.activity.ActivityValidationChecklist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PanelSequence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.PanelSequence.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.PanelSequence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PanelSequence.displayName = 'proto.wilqo.api.activity.PanelSequence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AsyncValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.AsyncValidation.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.AsyncValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AsyncValidation.displayName = 'proto.wilqo.api.activity.AsyncValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AsyncValidationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.AsyncValidationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AsyncValidationItem.displayName = 'proto.wilqo.api.activity.AsyncValidationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PanelSequenceNavigationButton, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PanelSequenceNavigationButton.displayName = 'proto.wilqo.api.activity.PanelSequenceNavigationButton';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityTemplateSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivityTemplateSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityTemplateSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityTemplateSummary.displayName = 'proto.wilqo.api.activity.ActivityTemplateSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.OnDemandActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.OnDemandActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.OnDemandActivity.displayName = 'proto.wilqo.api.activity.OnDemandActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitySummaryField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitySummaryField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitySummaryField.displayName = 'proto.wilqo.api.activity.ActivitySummaryField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UnpublishedActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.UnpublishedActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UnpublishedActivity.displayName = 'proto.wilqo.api.activity.UnpublishedActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.UnpublishedActivityGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.UnpublishedActivityGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.UnpublishedActivityGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.UnpublishedActivityGroup.displayName = 'proto.wilqo.api.activity.UnpublishedActivityGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityVersion.displayName = 'proto.wilqo.api.activity.ActivityVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivityVersionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivityVersionGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivityVersionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivityVersionGroup.displayName = 'proto.wilqo.api.activity.ActivityVersionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ScopeReference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ScopeReference.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ScopeReference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ScopeReference.displayName = 'proto.wilqo.api.activity.ScopeReference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ProgressItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ProgressItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ProgressItem.displayName = 'proto.wilqo.api.activity.ProgressItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ProgressItemSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ProgressItemSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ProgressItemSummary.displayName = 'proto.wilqo.api.activity.ProgressItemSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ProgressType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ProgressType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ProgressType.displayName = 'proto.wilqo.api.activity.ProgressType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ProgressTypeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ProgressTypeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ProgressTypeSummary.displayName = 'proto.wilqo.api.activity.ProgressTypeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.StashPanelResponseSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.StashPanelResponseSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.StashPanelResponseSummary.displayName = 'proto.wilqo.api.activity.StashPanelResponseSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.InternalNameValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.InternalNameValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.InternalNameValidation.displayName = 'proto.wilqo.api.activity.InternalNameValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.AsyncValidationPanelElementWrapper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.displayName = 'proto.wilqo.api.activity.AsyncValidationPanelElementWrapper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.TriggerType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.TriggerType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.TriggerType.displayName = 'proto.wilqo.api.activity.TriggerType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ValidationConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ValidationConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ValidationConfig.displayName = 'proto.wilqo.api.activity.ValidationConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ExternalActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ExternalActivity.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ExternalActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ExternalActivity.displayName = 'proto.wilqo.api.activity.ExternalActivity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.activity.ActivitiesSubscriptionContext.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitiesSubscriptionContext, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitiesSubscriptionContext.displayName = 'proto.wilqo.api.activity.ActivitiesSubscriptionContext';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitiesSubscriptionMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitiesSubscriptionMessage.displayName = 'proto.wilqo.api.activity.ActivitiesSubscriptionMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PagingArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PagingArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PagingArgs.displayName = 'proto.wilqo.api.activity.PagingArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.PagingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.PagingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.PagingInfo.displayName = 'proto.wilqo.api.activity.PagingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitiesBySkillSetArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitiesBySkillSetArgs.displayName = 'proto.wilqo.api.activity.ActivitiesBySkillSetArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.ActivitiesByEntityArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.ActivitiesByEntityArgs.displayName = 'proto.wilqo.api.activity.ActivitiesByEntityArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.activity.BusinessProcessTemplateForActivity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.activity.BusinessProcessTemplateForActivity.displayName = 'proto.wilqo.api.activity.BusinessProcessTemplateForActivity';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.TemplateVariable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.TemplateVariable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.TemplateVariable.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.TemplateVariable}
 */
proto.wilqo.api.activity.TemplateVariable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.TemplateVariable;
  return proto.wilqo.api.activity.TemplateVariable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.TemplateVariable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.TemplateVariable}
 */
proto.wilqo.api.activity.TemplateVariable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.activity.TemplateVariableDataType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.TemplateVariable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.TemplateVariable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.TemplateVariable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.TemplateVariable} returns this
 */
proto.wilqo.api.activity.TemplateVariable.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.TemplateVariable} returns this
 */
proto.wilqo.api.activity.TemplateVariable.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional TemplateVariableDataType type = 3;
 * @return {!proto.wilqo.api.activity.TemplateVariableDataType}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.activity.TemplateVariableDataType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.activity.TemplateVariableDataType} value
 * @return {!proto.wilqo.api.activity.TemplateVariable} returns this
 */
proto.wilqo.api.activity.TemplateVariable.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bytes data = 4;
 * @return {string}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes data = 4;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.TemplateVariable.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.activity.TemplateVariable} returns this
 */
proto.wilqo.api.activity.TemplateVariable.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivitySummary.repeatedFields_ = [10,14,19,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdTimestamp: (f = msg.getCreatedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    activityType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 8, 0),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    readonly: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    skillsetsIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    additionalFieldsList: jspb.Message.toObjectList(msg.getAdditionalFieldsList(),
    proto.wilqo.api.activity.ActivitySummaryField.toObject, includeInstance),
    internalName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    filters: (f = msg.getFilters()) && proto.wilqo.api.activity.ActivitySummaryFilters.toObject(includeInstance, f),
    progressItem: (f = msg.getProgressItem()) && proto.wilqo.api.activity.ProgressItemSummary.toObject(includeInstance, f),
    progressType: (f = msg.getProgressType()) && proto.wilqo.api.activity.ProgressTypeSummary.toObject(includeInstance, f),
    expirationSummariesList: jspb.Message.toObjectList(msg.getExpirationSummariesList(),
    Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary.toObject, includeInstance),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.ActivitySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitySummary;
  return proto.wilqo.api.activity.ActivitySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.ActivitySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTimestamp(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetsIds(value);
      break;
    case 14:
      var value = new proto.wilqo.api.activity.ActivitySummaryField;
      reader.readMessage(value,proto.wilqo.api.activity.ActivitySummaryField.deserializeBinaryFromReader);
      msg.addAdditionalFields(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 16:
      var value = new proto.wilqo.api.activity.ActivitySummaryFilters;
      reader.readMessage(value,proto.wilqo.api.activity.ActivitySummaryFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 17:
      var value = new proto.wilqo.api.activity.ProgressItemSummary;
      reader.readMessage(value,proto.wilqo.api.activity.ProgressItemSummary.deserializeBinaryFromReader);
      msg.setProgressItem(value);
      break;
    case 18:
      var value = new proto.wilqo.api.activity.ProgressTypeSummary;
      reader.readMessage(value,proto.wilqo.api.activity.ProgressTypeSummary.deserializeBinaryFromReader);
      msg.setProgressType(value);
      break;
    case 19:
      var value = new Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary.deserializeBinaryFromReader);
      msg.addExpirationSummaries(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getActivityType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSkillsetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getAdditionalFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.wilqo.api.activity.ActivitySummaryField.serializeBinaryToWriter
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.wilqo.api.activity.ActivitySummaryFilters.serializeBinaryToWriter
    );
  }
  f = message.getProgressItem();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.wilqo.api.activity.ProgressItemSummary.serializeBinaryToWriter
    );
  }
  f = message.getProgressType();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.wilqo.api.activity.ProgressTypeSummary.serializeBinaryToWriter
    );
  }
  f = message.getExpirationSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getCreatedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
*/
proto.wilqo.api.activity.ActivitySummary.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearCreatedTimestamp = function() {
  return this.setCreatedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.hasCreatedTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActivityStatus status = 5;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string activity_type = 7;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getActivityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 entity_version = 8;
 * @return {number}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string applies_to_entity_id = 9;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool readonly = 11;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setReadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated string skillsets_ids = 10;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getSkillsetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setSkillsetsIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.addSkillsetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearSkillsetsIdsList = function() {
  return this.setSkillsetsIdsList([]);
};


/**
 * repeated ActivitySummaryField additional_fields = 14;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummaryField>}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getAdditionalFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.ActivitySummaryField, 14));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummaryField>} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
*/
proto.wilqo.api.activity.ActivitySummary.prototype.setAdditionalFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.addAdditionalFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.api.activity.ActivitySummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearAdditionalFieldsList = function() {
  return this.setAdditionalFieldsList([]);
};


/**
 * optional string internal_name = 15;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional ActivitySummaryFilters filters = 16;
 * @return {?proto.wilqo.api.activity.ActivitySummaryFilters}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getFilters = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummaryFilters} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.ActivitySummaryFilters, 16));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummaryFilters|undefined} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
*/
proto.wilqo.api.activity.ActivitySummary.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional ProgressItemSummary progress_item = 17;
 * @return {?proto.wilqo.api.activity.ProgressItemSummary}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getProgressItem = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressItemSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.ProgressItemSummary, 17));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressItemSummary|undefined} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
*/
proto.wilqo.api.activity.ActivitySummary.prototype.setProgressItem = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearProgressItem = function() {
  return this.setProgressItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.hasProgressItem = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional ProgressTypeSummary progress_type = 18;
 * @return {?proto.wilqo.api.activity.ProgressTypeSummary}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getProgressType = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressTypeSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.ProgressTypeSummary, 18));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressTypeSummary|undefined} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
*/
proto.wilqo.api.activity.ActivitySummary.prototype.setProgressType = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearProgressType = function() {
  return this.setProgressType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.hasProgressType = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * repeated wilqo.api.users.ExpirationSummary expiration_summaries = 19;
 * @return {!Array<!proto.wilqo.api.users.ExpirationSummary>}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getExpirationSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.ExpirationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary, 19));
};


/**
 * @param {!Array<!proto.wilqo.api.users.ExpirationSummary>} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
*/
proto.wilqo.api.activity.ActivitySummary.prototype.setExpirationSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.wilqo.api.users.ExpirationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.ExpirationSummary}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.addExpirationSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.wilqo.api.users.ExpirationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearExpirationSummariesList = function() {
  return this.setExpirationSummariesList([]);
};


/**
 * repeated string organization_ids = 20;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string business_process_id = 21;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummary.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummary} returns this
 */
proto.wilqo.api.activity.ActivitySummary.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitySummaryFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitySummaryFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitySummaryFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    userHasSkillset: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    roleIsConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    roleIsLender: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    statusIsComplete: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitySummaryFilters}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitySummaryFilters;
  return proto.wilqo.api.activity.ActivitySummaryFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitySummaryFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitySummaryFilters}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserHasSkillset(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRoleIsConsumer(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRoleIsLender(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatusIsComplete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitySummaryFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitySummaryFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitySummaryFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserHasSkillset();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRoleIsConsumer();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRoleIsLender();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getStatusIsComplete();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool user_has_skillset = 1;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.getUserHasSkillset = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryFilters} returns this
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.setUserHasSkillset = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool role_is_consumer = 2;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.getRoleIsConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryFilters} returns this
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.setRoleIsConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool role_is_lender = 3;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.getRoleIsLender = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryFilters} returns this
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.setRoleIsLender = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool status_is_complete = 4;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.getStatusIsComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryFilters} returns this
 */
proto.wilqo.api.activity.ActivitySummaryFilters.prototype.setStatusIsComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivityPanelSequence.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityPanelSequence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityPanelSequence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityPanelSequence.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdTimestamp: (f = msg.getCreatedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    panelSequence: (f = msg.getPanelSequence()) && proto.wilqo.api.activity.PanelSequence.toObject(includeInstance, f),
    internalName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    expirationInformationsList: jspb.Message.toObjectList(msg.getExpirationInformationsList(),
    Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.toObject, includeInstance),
    loanNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    externalName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence}
 */
proto.wilqo.api.activity.ActivityPanelSequence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityPanelSequence;
  return proto.wilqo.api.activity.ActivityPanelSequence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityPanelSequence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence}
 */
proto.wilqo.api.activity.ActivityPanelSequence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTimestamp(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 8:
      var value = new proto.wilqo.api.activity.PanelSequence;
      reader.readMessage(value,proto.wilqo.api.activity.PanelSequence.deserializeBinaryFromReader);
      msg.setPanelSequence(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 12:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationInformation;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.deserializeBinaryFromReader);
      msg.addExpirationInformations(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanNumber(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityPanelSequence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityPanelSequence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityPanelSequence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPanelSequence();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.wilqo.api.activity.PanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getExpirationInformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.serializeBinaryToWriter
    );
  }
  f = message.getLoanNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 entity_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getCreatedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
*/
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.clearCreatedTimestamp = function() {
  return this.setCreatedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.hasCreatedTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActivityStatus status = 6;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional PanelSequence panel_sequence = 8;
 * @return {?proto.wilqo.api.activity.PanelSequence}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getPanelSequence = function() {
  return /** @type{?proto.wilqo.api.activity.PanelSequence} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.PanelSequence, 8));
};


/**
 * @param {?proto.wilqo.api.activity.PanelSequence|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
*/
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setPanelSequence = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.clearPanelSequence = function() {
  return this.setPanelSequence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.hasPanelSequence = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string internal_name = 11;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated wilqo.shared.models.ExpirationInformation expiration_informations = 12;
 * @return {!Array<!proto.wilqo.shared.models.ExpirationInformation>}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getExpirationInformationsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ExpirationInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationInformation, 12));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ExpirationInformation>} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
*/
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setExpirationInformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.shared.models.ExpirationInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.addExpirationInformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.shared.models.ExpirationInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.clearExpirationInformationsList = function() {
  return this.setExpirationInformationsList([]);
};


/**
 * optional string loan_number = 13;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getLoanNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setLoanNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string external_name = 14;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityPanelSequence} returns this
 */
proto.wilqo.api.activity.ActivityPanelSequence.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivityChecklist.repeatedFields_ = [13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityChecklist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityChecklist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityChecklist.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdTimestamp: (f = msg.getCreatedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    anticipatedTime: (f = msg.getAnticipatedTime()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    skillsetsIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    checklist: (f = msg.getChecklist()) && Wilqo_Shared_Models_ActivityModels_pb.Checklist.toObject(includeInstance, f),
    internalName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    expirationInformationsList: jspb.Message.toObjectList(msg.getExpirationInformationsList(),
    Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.toObject, includeInstance),
    externalName: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityChecklist}
 */
proto.wilqo.api.activity.ActivityChecklist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityChecklist;
  return proto.wilqo.api.activity.ActivityChecklist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityChecklist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityChecklist}
 */
proto.wilqo.api.activity.ActivityChecklist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTimestamp(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 11:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setAnticipatedTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetsIds(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Checklist;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Checklist.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 15:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationInformation;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.deserializeBinaryFromReader);
      msg.addExpirationInformations(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityChecklist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityChecklist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityChecklist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAnticipatedTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSkillsetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Checklist.serializeBinaryToWriter
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExpirationInformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.serializeBinaryToWriter
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 entity_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getCreatedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
*/
proto.wilqo.api.activity.ActivityChecklist.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.clearCreatedTimestamp = function() {
  return this.setCreatedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.hasCreatedTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActivityStatus status = 6;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
*/
proto.wilqo.api.activity.ActivityChecklist.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Duration anticipated_time = 11;
 * @return {?proto.google.protobuf.Duration}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getAnticipatedTime = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 11));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
*/
proto.wilqo.api.activity.ActivityChecklist.prototype.setAnticipatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.clearAnticipatedTime = function() {
  return this.setAnticipatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.hasAnticipatedTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string document_url = 12;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated string skillsets_ids = 13;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getSkillsetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setSkillsetsIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.addSkillsetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.clearSkillsetsIdsList = function() {
  return this.setSkillsetsIdsList([]);
};


/**
 * optional wilqo.shared.models.Checklist checklist = 8;
 * @return {?proto.wilqo.shared.models.Checklist}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getChecklist = function() {
  return /** @type{?proto.wilqo.shared.models.Checklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Checklist, 8));
};


/**
 * @param {?proto.wilqo.shared.models.Checklist|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
*/
proto.wilqo.api.activity.ActivityChecklist.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string internal_name = 14;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated wilqo.shared.models.ExpirationInformation expiration_informations = 15;
 * @return {!Array<!proto.wilqo.shared.models.ExpirationInformation>}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getExpirationInformationsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ExpirationInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationInformation, 15));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ExpirationInformation>} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
*/
proto.wilqo.api.activity.ActivityChecklist.prototype.setExpirationInformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.wilqo.shared.models.ExpirationInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.addExpirationInformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.wilqo.shared.models.ExpirationInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.clearExpirationInformationsList = function() {
  return this.setExpirationInformationsList([]);
};


/**
 * optional string external_name = 16;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityChecklist} returns this
 */
proto.wilqo.api.activity.ActivityChecklist.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivityValidationChecklist.repeatedFields_ = [13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityValidationChecklist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityValidationChecklist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityValidationChecklist.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdTimestamp: (f = msg.getCreatedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    anticipatedTime: (f = msg.getAnticipatedTime()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    documentUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    checklist: (f = msg.getChecklist()) && Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist.toObject(includeInstance, f),
    skillsetsIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    internalName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    expirationInformationsList: jspb.Message.toObjectList(msg.getExpirationInformationsList(),
    Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.toObject, includeInstance),
    externalName: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityValidationChecklist;
  return proto.wilqo.api.activity.ActivityValidationChecklist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityValidationChecklist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivityVersion(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTimestamp(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 11:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setAnticipatedTime(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentUrl(value);
      break;
    case 8:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetsIds(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 15:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationInformation;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.deserializeBinaryFromReader);
      msg.addExpirationInformations(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityValidationChecklist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityValidationChecklist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityValidationChecklist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAnticipatedTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getDocumentUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist.serializeBinaryToWriter
    );
  }
  f = message.getSkillsetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExpirationInformationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationInformation.serializeBinaryToWriter
    );
  }
  f = message.getExternalName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string activity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 activity_version = 2;
 * @return {number}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getActivityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setActivityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getCreatedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
*/
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.clearCreatedTimestamp = function() {
  return this.setCreatedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.hasCreatedTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActivityStatus status = 6;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
*/
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Duration anticipated_time = 11;
 * @return {?proto.google.protobuf.Duration}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getAnticipatedTime = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 11));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
*/
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setAnticipatedTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.clearAnticipatedTime = function() {
  return this.setAnticipatedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.hasAnticipatedTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string document_url = 12;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getDocumentUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setDocumentUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional wilqo.shared.models.ValidationChecklist checklist = 8;
 * @return {?proto.wilqo.shared.models.ValidationChecklist}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getChecklist = function() {
  return /** @type{?proto.wilqo.shared.models.ValidationChecklist} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklist, 8));
};


/**
 * @param {?proto.wilqo.shared.models.ValidationChecklist|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
*/
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string skillsets_ids = 13;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getSkillsetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setSkillsetsIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.addSkillsetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.clearSkillsetsIdsList = function() {
  return this.setSkillsetsIdsList([]);
};


/**
 * optional string internal_name = 14;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated wilqo.shared.models.ExpirationInformation expiration_informations = 15;
 * @return {!Array<!proto.wilqo.shared.models.ExpirationInformation>}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getExpirationInformationsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.ExpirationInformation>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationInformation, 15));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.ExpirationInformation>} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
*/
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setExpirationInformationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.wilqo.shared.models.ExpirationInformation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.ExpirationInformation}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.addExpirationInformations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.wilqo.shared.models.ExpirationInformation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.clearExpirationInformationsList = function() {
  return this.setExpirationInformationsList([]);
};


/**
 * optional string external_name = 16;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.getExternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityValidationChecklist} returns this
 */
proto.wilqo.api.activity.ActivityValidationChecklist.prototype.setExternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.PanelSequence.repeatedFields_ = [4,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PanelSequence.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PanelSequence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PanelSequence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequence.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    panelsList: jspb.Message.toObjectList(msg.getPanelsList(),
    Wilqo_Shared_Models_ActivityModels_pb.Panel.toObject, includeInstance),
    navigationButtonsList: jspb.Message.toObjectList(msg.getNavigationButtonsList(),
    proto.wilqo.api.activity.PanelSequenceNavigationButton.toObject, includeInstance),
    asyncValidationsList: jspb.Message.toObjectList(msg.getAsyncValidationsList(),
    proto.wilqo.api.activity.AsyncValidation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PanelSequence}
 */
proto.wilqo.api.activity.PanelSequence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PanelSequence;
  return proto.wilqo.api.activity.PanelSequence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PanelSequence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PanelSequence}
 */
proto.wilqo.api.activity.PanelSequence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.Panel;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.Panel.deserializeBinaryFromReader);
      msg.addPanels(value);
      break;
    case 6:
      var value = new proto.wilqo.api.activity.PanelSequenceNavigationButton;
      reader.readMessage(value,proto.wilqo.api.activity.PanelSequenceNavigationButton.deserializeBinaryFromReader);
      msg.addNavigationButtons(value);
      break;
    case 7:
      var value = new proto.wilqo.api.activity.AsyncValidation;
      reader.readMessage(value,proto.wilqo.api.activity.AsyncValidation.deserializeBinaryFromReader);
      msg.addAsyncValidations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PanelSequence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PanelSequence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PanelSequence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPanelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.Panel.serializeBinaryToWriter
    );
  }
  f = message.getNavigationButtonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.activity.PanelSequenceNavigationButton.serializeBinaryToWriter
    );
  }
  f = message.getAsyncValidationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.activity.AsyncValidation.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.activity.PanelSequence.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
 */
proto.wilqo.api.activity.PanelSequence.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entity_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequence.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
 */
proto.wilqo.api.activity.PanelSequence.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 entity_version = 3;
 * @return {number}
 */
proto.wilqo.api.activity.PanelSequence.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
 */
proto.wilqo.api.activity.PanelSequence.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated wilqo.shared.models.Panel panels = 4;
 * @return {!Array<!proto.wilqo.shared.models.Panel>}
 */
proto.wilqo.api.activity.PanelSequence.prototype.getPanelsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.Panel>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.Panel, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.Panel>} value
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
*/
proto.wilqo.api.activity.PanelSequence.prototype.setPanelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.Panel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.Panel}
 */
proto.wilqo.api.activity.PanelSequence.prototype.addPanels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.Panel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
 */
proto.wilqo.api.activity.PanelSequence.prototype.clearPanelsList = function() {
  return this.setPanelsList([]);
};


/**
 * repeated PanelSequenceNavigationButton navigation_buttons = 6;
 * @return {!Array<!proto.wilqo.api.activity.PanelSequenceNavigationButton>}
 */
proto.wilqo.api.activity.PanelSequence.prototype.getNavigationButtonsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.PanelSequenceNavigationButton>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.PanelSequenceNavigationButton, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.PanelSequenceNavigationButton>} value
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
*/
proto.wilqo.api.activity.PanelSequence.prototype.setNavigationButtonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.activity.PanelSequenceNavigationButton=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.PanelSequenceNavigationButton}
 */
proto.wilqo.api.activity.PanelSequence.prototype.addNavigationButtons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.activity.PanelSequenceNavigationButton, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
 */
proto.wilqo.api.activity.PanelSequence.prototype.clearNavigationButtonsList = function() {
  return this.setNavigationButtonsList([]);
};


/**
 * repeated AsyncValidation async_validations = 7;
 * @return {!Array<!proto.wilqo.api.activity.AsyncValidation>}
 */
proto.wilqo.api.activity.PanelSequence.prototype.getAsyncValidationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.AsyncValidation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.AsyncValidation, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.AsyncValidation>} value
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
*/
proto.wilqo.api.activity.PanelSequence.prototype.setAsyncValidationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.activity.AsyncValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AsyncValidation}
 */
proto.wilqo.api.activity.PanelSequence.prototype.addAsyncValidations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.activity.AsyncValidation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.PanelSequence} returns this
 */
proto.wilqo.api.activity.PanelSequence.prototype.clearAsyncValidationsList = function() {
  return this.setAsyncValidationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.AsyncValidation.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AsyncValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AsyncValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AsyncValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AsyncValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.activity.AsyncValidationItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AsyncValidation}
 */
proto.wilqo.api.activity.AsyncValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AsyncValidation;
  return proto.wilqo.api.activity.AsyncValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AsyncValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AsyncValidation}
 */
proto.wilqo.api.activity.AsyncValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.activity.AsyncValidationTypeEnum} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.wilqo.api.activity.AsyncValidationItem;
      reader.readMessage(value,proto.wilqo.api.activity.AsyncValidationItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AsyncValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AsyncValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AsyncValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AsyncValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.activity.AsyncValidationItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional AsyncValidationTypeEnum type = 1;
 * @return {!proto.wilqo.api.activity.AsyncValidationTypeEnum}
 */
proto.wilqo.api.activity.AsyncValidation.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.activity.AsyncValidationTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.activity.AsyncValidationTypeEnum} value
 * @return {!proto.wilqo.api.activity.AsyncValidation} returns this
 */
proto.wilqo.api.activity.AsyncValidation.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated AsyncValidationItem items = 2;
 * @return {!Array<!proto.wilqo.api.activity.AsyncValidationItem>}
 */
proto.wilqo.api.activity.AsyncValidation.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.AsyncValidationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.AsyncValidationItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.AsyncValidationItem>} value
 * @return {!proto.wilqo.api.activity.AsyncValidation} returns this
*/
proto.wilqo.api.activity.AsyncValidation.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.activity.AsyncValidationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.AsyncValidationItem}
 */
proto.wilqo.api.activity.AsyncValidation.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.activity.AsyncValidationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.AsyncValidation} returns this
 */
proto.wilqo.api.activity.AsyncValidation.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AsyncValidationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AsyncValidationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AsyncValidationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AsyncValidationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AsyncValidationItem}
 */
proto.wilqo.api.activity.AsyncValidationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AsyncValidationItem;
  return proto.wilqo.api.activity.AsyncValidationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AsyncValidationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AsyncValidationItem}
 */
proto.wilqo.api.activity.AsyncValidationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AsyncValidationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AsyncValidationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AsyncValidationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AsyncValidationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AsyncValidationItem.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AsyncValidationItem} returns this
 */
proto.wilqo.api.activity.AsyncValidationItem.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool required = 2;
 * @return {boolean}
 */
proto.wilqo.api.activity.AsyncValidationItem.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.AsyncValidationItem} returns this
 */
proto.wilqo.api.activity.AsyncValidationItem.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PanelSequenceNavigationButton.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PanelSequenceNavigationButton} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PanelSequenceNavigationButton}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PanelSequenceNavigationButton;
  return proto.wilqo.api.activity.PanelSequenceNavigationButton.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PanelSequenceNavigationButton} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PanelSequenceNavigationButton}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PanelSequenceNavigationButton.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PanelSequenceNavigationButton} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceNavigationButton} returns this
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceNavigationButton} returns this
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PanelSequenceNavigationButton} returns this
 */
proto.wilqo.api.activity.PanelSequenceNavigationButton.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivityTemplateSummary.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityTemplateSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityTemplateSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityTemplateSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillsetsIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    internalName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    progressType: (f = msg.getProgressType()) && proto.wilqo.api.activity.ProgressTypeSummary.toObject(includeInstance, f),
    expirationDateConfiguration: (f = msg.getExpirationDateConfiguration()) && Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityTemplateSummary;
  return proto.wilqo.api.activity.ActivityTemplateSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityTemplateSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetsIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 6:
      var value = new proto.wilqo.api.activity.ProgressTypeSummary;
      reader.readMessage(value,proto.wilqo.api.activity.ProgressTypeSummary.deserializeBinaryFromReader);
      msg.setProgressType(value);
      break;
    case 7:
      var value = new Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration;
      reader.readMessage(value,Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.deserializeBinaryFromReader);
      msg.setExpirationDateConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityTemplateSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityTemplateSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityTemplateSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillsetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProgressType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.wilqo.api.activity.ProgressTypeSummary.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDateConfiguration();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string help_text = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string skillsets_ids = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getSkillsetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setSkillsetsIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.addSkillsetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.clearSkillsetsIdsList = function() {
  return this.setSkillsetsIdsList([]);
};


/**
 * optional string internal_name = 5;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ProgressTypeSummary progress_type = 6;
 * @return {?proto.wilqo.api.activity.ProgressTypeSummary}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getProgressType = function() {
  return /** @type{?proto.wilqo.api.activity.ProgressTypeSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.ProgressTypeSummary, 6));
};


/**
 * @param {?proto.wilqo.api.activity.ProgressTypeSummary|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
*/
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setProgressType = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.clearProgressType = function() {
  return this.setProgressType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.hasProgressType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional wilqo.shared.models.ExpirationDateConfiguration expiration_date_configuration = 7;
 * @return {?proto.wilqo.shared.models.ExpirationDateConfiguration}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.getExpirationDateConfiguration = function() {
  return /** @type{?proto.wilqo.shared.models.ExpirationDateConfiguration} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Configuration_pb.ExpirationDateConfiguration, 7));
};


/**
 * @param {?proto.wilqo.shared.models.ExpirationDateConfiguration|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
*/
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.setExpirationDateConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityTemplateSummary} returns this
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.clearExpirationDateConfiguration = function() {
  return this.setExpirationDateConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityTemplateSummary.prototype.hasExpirationDateConfiguration = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.OnDemandActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.OnDemandActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.OnDemandActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    buttonDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.OnDemandActivity}
 */
proto.wilqo.api.activity.OnDemandActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.OnDemandActivity;
  return proto.wilqo.api.activity.OnDemandActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.OnDemandActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.OnDemandActivity}
 */
proto.wilqo.api.activity.OnDemandActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setButtonDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.OnDemandActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.OnDemandActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.OnDemandActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getButtonDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string activity_name = 1;
 * @return {string}
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.getActivityName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.OnDemandActivity} returns this
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.setActivityName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.OnDemandActivity} returns this
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string button_description = 3;
 * @return {string}
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.getButtonDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.OnDemandActivity} returns this
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.setButtonDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.OnDemandActivity} returns this
 */
proto.wilqo.api.activity.OnDemandActivity.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitySummaryField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitySummaryField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitySummaryField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fieldValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fieldType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numberScaleDigits: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dateFormat: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.ActivitySummaryField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitySummaryField;
  return proto.wilqo.api.activity.ActivitySummaryField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitySummaryField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.ActivitySummaryField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldValue(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.activity.FieldTypeEnum} */ (reader.readEnum());
      msg.setFieldType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberScaleDigits(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateFormat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitySummaryField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitySummaryField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitySummaryField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFieldType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNumberScaleDigits();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDateFormat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryField} returns this
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_label = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.getFieldLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryField} returns this
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.setFieldLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field_value = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.getFieldValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryField} returns this
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.setFieldValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional FieldTypeEnum field_type = 4;
 * @return {!proto.wilqo.api.activity.FieldTypeEnum}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.getFieldType = function() {
  return /** @type {!proto.wilqo.api.activity.FieldTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.activity.FieldTypeEnum} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryField} returns this
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.setFieldType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional uint32 number_scale_digits = 5;
 * @return {number}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.getNumberScaleDigits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryField} returns this
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.setNumberScaleDigits = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string date_format = 6;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.getDateFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitySummaryField} returns this
 */
proto.wilqo.api.activity.ActivitySummaryField.prototype.setDateFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UnpublishedActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UnpublishedActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnpublishedActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateTime: (f = msg.getDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UnpublishedActivity}
 */
proto.wilqo.api.activity.UnpublishedActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UnpublishedActivity;
  return proto.wilqo.api.activity.UnpublishedActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UnpublishedActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UnpublishedActivity}
 */
proto.wilqo.api.activity.UnpublishedActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UnpublishedActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UnpublishedActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnpublishedActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivity} returns this
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subject = 2;
 * @return {string}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivity} returns this
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivity} returns this
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp date_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.getDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivity} returns this
*/
proto.wilqo.api.activity.UnpublishedActivity.prototype.setDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UnpublishedActivity} returns this
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.clearDateTime = function() {
  return this.setDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.hasDateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string user_name = 5;
 * @return {string}
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivity} returns this
 */
proto.wilqo.api.activity.UnpublishedActivity.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.UnpublishedActivityGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.UnpublishedActivityGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateListList: jspb.Message.toObjectList(msg.getUpdateListList(),
    proto.wilqo.api.activity.UnpublishedActivity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.UnpublishedActivityGroup;
  return proto.wilqo.api.activity.UnpublishedActivityGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.UnpublishedActivityGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 3:
      var value = new proto.wilqo.api.activity.UnpublishedActivity;
      reader.readMessage(value,proto.wilqo.api.activity.UnpublishedActivity.deserializeBinaryFromReader);
      msg.addUpdateList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.UnpublishedActivityGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.UnpublishedActivityGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.activity.UnpublishedActivity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup} returns this
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup} returns this
*/
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup} returns this
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated UnpublishedActivity update_list = 3;
 * @return {!Array<!proto.wilqo.api.activity.UnpublishedActivity>}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.getUpdateListList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.UnpublishedActivity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.UnpublishedActivity, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.UnpublishedActivity>} value
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup} returns this
*/
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.setUpdateListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.UnpublishedActivity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.UnpublishedActivity}
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.addUpdateList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.UnpublishedActivity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.UnpublishedActivityGroup} returns this
 */
proto.wilqo.api.activity.UnpublishedActivityGroup.prototype.clearUpdateListList = function() {
  return this.setUpdateListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    versionNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateTime: (f = msg.getDateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userName: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityVersion}
 */
proto.wilqo.api.activity.ActivityVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityVersion;
  return proto.wilqo.api.activity.ActivityVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityVersion}
 */
proto.wilqo.api.activity.ActivityVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionNumber(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDateTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersionNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityVersion} returns this
 */
proto.wilqo.api.activity.ActivityVersion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityVersion} returns this
 */
proto.wilqo.api.activity.ActivityVersion.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string version_number = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.getVersionNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityVersion} returns this
 */
proto.wilqo.api.activity.ActivityVersion.prototype.setVersionNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp date_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.getDateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityVersion} returns this
*/
proto.wilqo.api.activity.ActivityVersion.prototype.setDateTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityVersion} returns this
 */
proto.wilqo.api.activity.ActivityVersion.prototype.clearDateTime = function() {
  return this.setDateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.hasDateTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string user_name = 5;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityVersion.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityVersion} returns this
 */
proto.wilqo.api.activity.ActivityVersion.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivityVersionGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivityVersionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivityVersionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityVersionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    versionListList: jspb.Message.toObjectList(msg.getVersionListList(),
    proto.wilqo.api.activity.ActivityVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup}
 */
proto.wilqo.api.activity.ActivityVersionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivityVersionGroup;
  return proto.wilqo.api.activity.ActivityVersionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivityVersionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup}
 */
proto.wilqo.api.activity.ActivityVersionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 3:
      var value = new proto.wilqo.api.activity.ActivityVersion;
      reader.readMessage(value,proto.wilqo.api.activity.ActivityVersion.deserializeBinaryFromReader);
      msg.addVersionList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivityVersionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivityVersionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivityVersionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVersionListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.activity.ActivityVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup} returns this
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup} returns this
*/
proto.wilqo.api.activity.ActivityVersionGroup.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup} returns this
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ActivityVersion version_list = 3;
 * @return {!Array<!proto.wilqo.api.activity.ActivityVersion>}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.getVersionListList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivityVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.ActivityVersion, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivityVersion>} value
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup} returns this
*/
proto.wilqo.api.activity.ActivityVersionGroup.prototype.setVersionListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivityVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivityVersion}
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.addVersionList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.activity.ActivityVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivityVersionGroup} returns this
 */
proto.wilqo.api.activity.ActivityVersionGroup.prototype.clearVersionListList = function() {
  return this.setVersionListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ScopeReference.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ScopeReference.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ScopeReference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ScopeReference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ScopeReference.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    referenceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    appliesTo: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fieldLibraryId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.ScopeReference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ScopeReference;
  return proto.wilqo.api.activity.ScopeReference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ScopeReference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ScopeReference}
 */
proto.wilqo.api.activity.ScopeReference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addScope(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 3:
      var value = /** @type {!proto.wilqo.api.activity.ScopeReferenceAppliesToEnum} */ (reader.readEnum());
      msg.setAppliesTo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ScopeReference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ScopeReference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ScopeReference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ScopeReference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAppliesTo();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFieldLibraryId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated string scope = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ScopeReference.prototype.getScopeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ScopeReference} returns this
 */
proto.wilqo.api.activity.ScopeReference.prototype.setScopeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ScopeReference} returns this
 */
proto.wilqo.api.activity.ScopeReference.prototype.addScope = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ScopeReference} returns this
 */
proto.wilqo.api.activity.ScopeReference.prototype.clearScopeList = function() {
  return this.setScopeList([]);
};


/**
 * optional string reference_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ScopeReference.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ScopeReference} returns this
 */
proto.wilqo.api.activity.ScopeReference.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ScopeReferenceAppliesToEnum applies_to = 3;
 * @return {!proto.wilqo.api.activity.ScopeReferenceAppliesToEnum}
 */
proto.wilqo.api.activity.ScopeReference.prototype.getAppliesTo = function() {
  return /** @type {!proto.wilqo.api.activity.ScopeReferenceAppliesToEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ScopeReferenceAppliesToEnum} value
 * @return {!proto.wilqo.api.activity.ScopeReference} returns this
 */
proto.wilqo.api.activity.ScopeReference.prototype.setAppliesTo = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string field_library_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.ScopeReference.prototype.getFieldLibraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ScopeReference} returns this
 */
proto.wilqo.api.activity.ScopeReference.prototype.setFieldLibraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ProgressItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ProgressItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ProgressItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lenderDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    screenOrder: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ProgressItem}
 */
proto.wilqo.api.activity.ProgressItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ProgressItem;
  return proto.wilqo.api.activity.ProgressItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ProgressItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ProgressItem}
 */
proto.wilqo.api.activity.ProgressItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScreenOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ProgressItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ProgressItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ProgressItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLenderDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScreenOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressItem.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressItem} returns this
 */
proto.wilqo.api.activity.ProgressItem.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressItem} returns this
 */
proto.wilqo.api.activity.ProgressItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_description = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressItem.prototype.getConsumerDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressItem} returns this
 */
proto.wilqo.api.activity.ProgressItem.prototype.setConsumerDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lender_description = 4;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressItem.prototype.getLenderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressItem} returns this
 */
proto.wilqo.api.activity.ProgressItem.prototype.setLenderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 screen_order = 5;
 * @return {number}
 */
proto.wilqo.api.activity.ProgressItem.prototype.getScreenOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ProgressItem} returns this
 */
proto.wilqo.api.activity.ProgressItem.prototype.setScreenOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ProgressItemSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ProgressItemSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ProgressItemSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressItemSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    screenOrder: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ProgressItemSummary}
 */
proto.wilqo.api.activity.ProgressItemSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ProgressItemSummary;
  return proto.wilqo.api.activity.ProgressItemSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ProgressItemSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ProgressItemSummary}
 */
proto.wilqo.api.activity.ProgressItemSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScreenOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ProgressItemSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ProgressItemSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ProgressItemSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressItemSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScreenOrder();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressItemSummary.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressItemSummary} returns this
 */
proto.wilqo.api.activity.ProgressItemSummary.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 screen_order = 2;
 * @return {number}
 */
proto.wilqo.api.activity.ProgressItemSummary.prototype.getScreenOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ProgressItemSummary} returns this
 */
proto.wilqo.api.activity.ProgressItemSummary.prototype.setScreenOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ProgressType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ProgressType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ProgressType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressType.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lenderDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    screenOrder: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.ProgressType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ProgressType;
  return proto.wilqo.api.activity.ProgressType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ProgressType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ProgressType}
 */
proto.wilqo.api.activity.ProgressType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLenderDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScreenOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ProgressType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ProgressType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ProgressType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLenderDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScreenOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressType.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressType} returns this
 */
proto.wilqo.api.activity.ProgressType.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressType} returns this
 */
proto.wilqo.api.activity.ProgressType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_description = 3;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressType.prototype.getConsumerDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressType} returns this
 */
proto.wilqo.api.activity.ProgressType.prototype.setConsumerDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lender_description = 4;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressType.prototype.getLenderDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressType} returns this
 */
proto.wilqo.api.activity.ProgressType.prototype.setLenderDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 screen_order = 5;
 * @return {number}
 */
proto.wilqo.api.activity.ProgressType.prototype.getScreenOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ProgressType} returns this
 */
proto.wilqo.api.activity.ProgressType.prototype.setScreenOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ProgressTypeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ProgressTypeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ProgressTypeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressTypeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    screenOrder: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ProgressTypeSummary}
 */
proto.wilqo.api.activity.ProgressTypeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ProgressTypeSummary;
  return proto.wilqo.api.activity.ProgressTypeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ProgressTypeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ProgressTypeSummary}
 */
proto.wilqo.api.activity.ProgressTypeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setScreenOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ProgressTypeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ProgressTypeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ProgressTypeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ProgressTypeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScreenOrder();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ProgressTypeSummary.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ProgressTypeSummary} returns this
 */
proto.wilqo.api.activity.ProgressTypeSummary.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 screen_order = 2;
 * @return {number}
 */
proto.wilqo.api.activity.ProgressTypeSummary.prototype.getScreenOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ProgressTypeSummary} returns this
 */
proto.wilqo.api.activity.ProgressTypeSummary.prototype.setScreenOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.StashPanelResponseSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.StashPanelResponseSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.StashPanelResponseSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementResponseId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    panelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.StashPanelResponseSummary;
  return proto.wilqo.api.activity.StashPanelResponseSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.StashPanelResponseSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementResponseId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.StashPanelResponseSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.StashPanelResponseSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.StashPanelResponseSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementResponseId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPanelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string panel_element_response_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.getPanelElementResponseId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary} returns this
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.setPanelElementResponseId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string panel_id = 2;
 * @return {string}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.getPanelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary} returns this
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.setPanelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.StashPanelResponseSummary} returns this
 */
proto.wilqo.api.activity.StashPanelResponseSummary.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.InternalNameValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.InternalNameValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.InternalNameValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InternalNameValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    internalNameExists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.InternalNameValidation}
 */
proto.wilqo.api.activity.InternalNameValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.InternalNameValidation;
  return proto.wilqo.api.activity.InternalNameValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.InternalNameValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.InternalNameValidation}
 */
proto.wilqo.api.activity.InternalNameValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInternalNameExists(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.InternalNameValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.InternalNameValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.InternalNameValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.InternalNameValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInternalNameExists();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool internal_name_exists = 1;
 * @return {boolean}
 */
proto.wilqo.api.activity.InternalNameValidation.prototype.getInternalNameExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.InternalNameValidation} returns this
 */
proto.wilqo.api.activity.InternalNameValidation.prototype.setInternalNameExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.wilqo.api.activity.InternalNameValidation.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.InternalNameValidation} returns this
 */
proto.wilqo.api.activity.InternalNameValidation.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.toObject = function(includeInstance, msg) {
  var f, obj = {
    panelElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.AsyncValidationPanelElementWrapper;
  return proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPanelElementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string panel_element_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.getPanelElementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper} returns this
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.setPanelElementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.getValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper} returns this
*/
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.AsyncValidationPanelElementWrapper} returns this
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.AsyncValidationPanelElementWrapper.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.TriggerType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.TriggerType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.TriggerType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.TriggerType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.TriggerType}
 */
proto.wilqo.api.activity.TriggerType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.TriggerType;
  return proto.wilqo.api.activity.TriggerType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.TriggerType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.TriggerType}
 */
proto.wilqo.api.activity.TriggerType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.RuleTypeEnum} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.TriggerType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.TriggerType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.TriggerType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.TriggerType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.shared.rulesengine.RuleTypeEnum id = 1;
 * @return {!proto.wilqo.shared.rulesengine.RuleTypeEnum}
 */
proto.wilqo.api.activity.TriggerType.prototype.getId = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.RuleTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.RuleTypeEnum} value
 * @return {!proto.wilqo.api.activity.TriggerType} returns this
 */
proto.wilqo.api.activity.TriggerType.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.wilqo.api.activity.TriggerType.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.TriggerType} returns this
 */
proto.wilqo.api.activity.TriggerType.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ValidationConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ValidationConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ValidationConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    failedChecklistItem: (f = msg.getFailedChecklistItem()) && Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklistItem.toObject(includeInstance, f),
    validation: (f = msg.getValidation()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb.Validation.toObject(includeInstance, f),
    validationType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ValidationConfig}
 */
proto.wilqo.api.activity.ValidationConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ValidationConfig;
  return proto.wilqo.api.activity.ValidationConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ValidationConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ValidationConfig}
 */
proto.wilqo.api.activity.ValidationConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklistItem;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklistItem.deserializeBinaryFromReader);
      msg.setFailedChecklistItem(value);
      break;
    case 3:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb.Validation;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb.Validation.deserializeBinaryFromReader);
      msg.setValidation(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.activity.ValidationTypesEnum} */ (reader.readEnum());
      msg.setValidationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ValidationConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ValidationConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ValidationConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFailedChecklistItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklistItem.serializeBinaryToWriter
    );
  }
  f = message.getValidation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb.Validation.serializeBinaryToWriter
    );
  }
  f = message.getValidationType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ValidationConfig} returns this
 */
proto.wilqo.api.activity.ValidationConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ValidationChecklistItem failed_checklist_item = 2;
 * @return {?proto.wilqo.shared.models.ValidationChecklistItem}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.getFailedChecklistItem = function() {
  return /** @type{?proto.wilqo.shared.models.ValidationChecklistItem} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.ValidationChecklistItem, 2));
};


/**
 * @param {?proto.wilqo.shared.models.ValidationChecklistItem|undefined} value
 * @return {!proto.wilqo.api.activity.ValidationConfig} returns this
*/
proto.wilqo.api.activity.ValidationConfig.prototype.setFailedChecklistItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ValidationConfig} returns this
 */
proto.wilqo.api.activity.ValidationConfig.prototype.clearFailedChecklistItem = function() {
  return this.setFailedChecklistItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.hasFailedChecklistItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.rulesengine.Validation validation = 3;
 * @return {?proto.wilqo.shared.rulesengine.Validation}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.getValidation = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.Validation} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Validations_pb.Validation, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.Validation|undefined} value
 * @return {!proto.wilqo.api.activity.ValidationConfig} returns this
*/
proto.wilqo.api.activity.ValidationConfig.prototype.setValidation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ValidationConfig} returns this
 */
proto.wilqo.api.activity.ValidationConfig.prototype.clearValidation = function() {
  return this.setValidation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.hasValidation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ValidationTypesEnum validation_type = 4;
 * @return {!proto.wilqo.api.activity.ValidationTypesEnum}
 */
proto.wilqo.api.activity.ValidationConfig.prototype.getValidationType = function() {
  return /** @type {!proto.wilqo.api.activity.ValidationTypesEnum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ValidationTypesEnum} value
 * @return {!proto.wilqo.api.activity.ValidationConfig} returns this
 */
proto.wilqo.api.activity.ValidationConfig.prototype.setValidationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ExternalActivity.repeatedFields_ = [9,10,14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ExternalActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ExternalActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExternalActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdTimestamp: (f = msg.getCreatedTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    activityType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 6, 0),
    appliesToEntityId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    readonly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    skillsetsIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    additionalFieldsList: jspb.Message.toObjectList(msg.getAdditionalFieldsList(),
    proto.wilqo.api.activity.ActivitySummaryField.toObject, includeInstance),
    helpText: jspb.Message.getFieldWithDefault(msg, 11, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    filters: (f = msg.getFilters()) && proto.wilqo.api.activity.ActivitySummaryFilters.toObject(includeInstance, f),
    expirationSummariesList: jspb.Message.toObjectList(msg.getExpirationSummariesList(),
    Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary.toObject, includeInstance),
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    businessProcessId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    state: msg.getState_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ExternalActivity}
 */
proto.wilqo.api.activity.ExternalActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ExternalActivity;
  return proto.wilqo.api.activity.ExternalActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ExternalActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ExternalActivity}
 */
proto.wilqo.api.activity.ExternalActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedTimestamp(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppliesToEntityId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetsIds(value);
      break;
    case 10:
      var value = new proto.wilqo.api.activity.ActivitySummaryField;
      reader.readMessage(value,proto.wilqo.api.activity.ActivitySummaryField.deserializeBinaryFromReader);
      msg.addAdditionalFields(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 13:
      var value = new proto.wilqo.api.activity.ActivitySummaryFilters;
      reader.readMessage(value,proto.wilqo.api.activity.ActivitySummaryFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    case 14:
      var value = new Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary.deserializeBinaryFromReader);
      msg.addExpirationSummaries(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessId(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ExternalActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ExternalActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ExternalActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getActivityType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getAppliesToEntityId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReadonly();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSkillsetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getAdditionalFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.wilqo.api.activity.ActivitySummaryField.serializeBinaryToWriter
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.wilqo.api.activity.ActivitySummaryFilters.serializeBinaryToWriter
    );
  }
  f = message.getExpirationSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary.serializeBinaryToWriter
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getBusinessProcessId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getState_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      17,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getCreatedTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
*/
proto.wilqo.api.activity.ExternalActivity.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.clearCreatedTimestamp = function() {
  return this.setCreatedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.hasCreatedTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ActivityStatus status = 4;
 * @return {!proto.wilqo.api.activity.ActivityStatus}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getStatus = function() {
  return /** @type {!proto.wilqo.api.activity.ActivityStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.activity.ActivityStatus} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string activity_type = 5;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getActivityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 entity_version = 6;
 * @return {number}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string applies_to_entity_id = 7;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getAppliesToEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setAppliesToEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool readonly = 8;
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setReadonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated string skillsets_ids = 9;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getSkillsetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setSkillsetsIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.addSkillsetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.clearSkillsetsIdsList = function() {
  return this.setSkillsetsIdsList([]);
};


/**
 * repeated ActivitySummaryField additional_fields = 10;
 * @return {!Array<!proto.wilqo.api.activity.ActivitySummaryField>}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getAdditionalFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.activity.ActivitySummaryField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.activity.ActivitySummaryField, 10));
};


/**
 * @param {!Array<!proto.wilqo.api.activity.ActivitySummaryField>} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
*/
proto.wilqo.api.activity.ExternalActivity.prototype.setAdditionalFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.wilqo.api.activity.ActivitySummaryField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitySummaryField}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.addAdditionalFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.wilqo.api.activity.ActivitySummaryField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.clearAdditionalFieldsList = function() {
  return this.setAdditionalFieldsList([]);
};


/**
 * optional string help_text = 11;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string internal_name = 12;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional ActivitySummaryFilters filters = 13;
 * @return {?proto.wilqo.api.activity.ActivitySummaryFilters}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getFilters = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummaryFilters} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.ActivitySummaryFilters, 13));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummaryFilters|undefined} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
*/
proto.wilqo.api.activity.ExternalActivity.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated wilqo.api.users.ExpirationSummary expiration_summaries = 14;
 * @return {!Array<!proto.wilqo.api.users.ExpirationSummary>}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getExpirationSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.ExpirationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.ExpirationSummary, 14));
};


/**
 * @param {!Array<!proto.wilqo.api.users.ExpirationSummary>} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
*/
proto.wilqo.api.activity.ExternalActivity.prototype.setExpirationSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.wilqo.api.users.ExpirationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.ExpirationSummary}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.addExpirationSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.wilqo.api.users.ExpirationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.clearExpirationSummariesList = function() {
  return this.setExpirationSummariesList([]);
};


/**
 * repeated string organization_ids = 15;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * optional string business_process_id = 16;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getBusinessProcessId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setBusinessProcessId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bytes state = 17;
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes state = 17;
 * This is a type-conversion wrapper around `getState()`
 * @return {string}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getState_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getState()));
};


/**
 * optional bytes state = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getState()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ExternalActivity.prototype.getState_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getState()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.activity.ExternalActivity} returns this
 */
proto.wilqo.api.activity.ExternalActivity.prototype.setState = function(value) {
  return jspb.Message.setProto3BytesField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitiesSubscriptionContext.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.toObject = function(includeInstance, msg) {
  var f, obj = {
    isConsumer: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    consumerAssociatedDealsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    filterBySkillSets: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    userSkillSetsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    entityId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    includeCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitiesSubscriptionContext;
  return proto.wilqo.api.activity.ActivitiesSubscriptionContext.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsConsumer(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addConsumerAssociatedDeals(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilterBySkillSets(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserSkillSets(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitiesSubscriptionContext.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsConsumer();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConsumerAssociatedDealsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getFilterBySkillSets();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getUserSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIncludeCompleted();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool is_consumer = 1;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.getIsConsumer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.setIsConsumer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated string consumer_associated_deals = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.getConsumerAssociatedDealsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.setConsumerAssociatedDealsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.addConsumerAssociatedDeals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.clearConsumerAssociatedDealsList = function() {
  return this.setConsumerAssociatedDealsList([]);
};


/**
 * optional bool filter_by_skill_sets = 3;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.getFilterBySkillSets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.setFilterBySkillSets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string user_skill_sets = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.getUserSkillSetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.setUserSkillSetsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.addUserSkillSets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.clearUserSkillSetsList = function() {
  return this.setUserSkillSetsList([]);
};


/**
 * optional string entity_id = 5;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool include_completed = 6;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.getIncludeCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionContext} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionContext.prototype.setIncludeCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitiesSubscriptionMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    activity: (f = msg.getActivity()) && proto.wilqo.api.activity.ActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitiesSubscriptionMessage;
  return proto.wilqo.api.activity.ActivitiesSubscriptionMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.api.activity.ActivitySummary;
      reader.readMessage(value,proto.wilqo.api.activity.ActivitySummary.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitiesSubscriptionMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.wilqo.api.activity.ActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActivitySummary activity = 1;
 * @return {?proto.wilqo.api.activity.ActivitySummary}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.prototype.getActivity = function() {
  return /** @type{?proto.wilqo.api.activity.ActivitySummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.activity.ActivitySummary, 1));
};


/**
 * @param {?proto.wilqo.api.activity.ActivitySummary|undefined} value
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage} returns this
*/
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.ActivitiesSubscriptionMessage} returns this
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitiesSubscriptionMessage.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PagingArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PagingArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PagingArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PagingArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortField: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sortType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PagingArgs}
 */
proto.wilqo.api.activity.PagingArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PagingArgs;
  return proto.wilqo.api.activity.PagingArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PagingArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PagingArgs}
 */
proto.wilqo.api.activity.PagingArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortField(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.activity.PagingArgs.SortType} */ (reader.readEnum());
      msg.setSortType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PagingArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PagingArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PagingArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PagingArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSortField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSortType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.wilqo.api.activity.PagingArgs.SortType = {
  SORT_TYPE_ASC: 0,
  SORT_TYPE_DESC: 1
};

/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.wilqo.api.activity.PagingArgs.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PagingArgs} returns this
 */
proto.wilqo.api.activity.PagingArgs.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.wilqo.api.activity.PagingArgs.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PagingArgs} returns this
 */
proto.wilqo.api.activity.PagingArgs.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort_field = 3;
 * @return {string}
 */
proto.wilqo.api.activity.PagingArgs.prototype.getSortField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.PagingArgs} returns this
 */
proto.wilqo.api.activity.PagingArgs.prototype.setSortField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SortType sort_type = 4;
 * @return {!proto.wilqo.api.activity.PagingArgs.SortType}
 */
proto.wilqo.api.activity.PagingArgs.prototype.getSortType = function() {
  return /** @type {!proto.wilqo.api.activity.PagingArgs.SortType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.activity.PagingArgs.SortType} value
 * @return {!proto.wilqo.api.activity.PagingArgs} returns this
 */
proto.wilqo.api.activity.PagingArgs.prototype.setSortType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.PagingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.PagingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.PagingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PagingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    current: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.PagingInfo}
 */
proto.wilqo.api.activity.PagingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.PagingInfo;
  return proto.wilqo.api.activity.PagingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.PagingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.PagingInfo}
 */
proto.wilqo.api.activity.PagingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.PagingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.PagingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.PagingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.PagingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int32 current = 1;
 * @return {number}
 */
proto.wilqo.api.activity.PagingInfo.prototype.getCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PagingInfo} returns this
 */
proto.wilqo.api.activity.PagingInfo.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 current_size = 2;
 * @return {number}
 */
proto.wilqo.api.activity.PagingInfo.prototype.getCurrentSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PagingInfo} returns this
 */
proto.wilqo.api.activity.PagingInfo.prototype.setCurrentSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total = 3;
 * @return {number}
 */
proto.wilqo.api.activity.PagingInfo.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.activity.PagingInfo} returns this
 */
proto.wilqo.api.activity.PagingInfo.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitiesBySkillSetArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitiesBySkillSetArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeCompleted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitiesBySkillSetArgs}
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitiesBySkillSetArgs;
  return proto.wilqo.api.activity.ActivitiesBySkillSetArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitiesBySkillSetArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitiesBySkillSetArgs}
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitiesBySkillSetArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitiesBySkillSetArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeCompleted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_completed = 1;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.prototype.getIncludeCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitiesBySkillSetArgs} returns this
 */
proto.wilqo.api.activity.ActivitiesBySkillSetArgs.prototype.setIncludeCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.ActivitiesByEntityArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.ActivitiesByEntityArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeAll: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.ActivitiesByEntityArgs}
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.ActivitiesByEntityArgs;
  return proto.wilqo.api.activity.ActivitiesByEntityArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.ActivitiesByEntityArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.ActivitiesByEntityArgs}
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAll(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.ActivitiesByEntityArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.ActivitiesByEntityArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeAll();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.ActivitiesByEntityArgs} returns this
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_all = 2;
 * @return {boolean}
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.prototype.getIncludeAll = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.ActivitiesByEntityArgs} returns this
 */
proto.wilqo.api.activity.ActivitiesByEntityArgs.prototype.setIncludeAll = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.activity.BusinessProcessTemplateForActivity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    internalName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    loanProgressItemIdentifier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    completeProgressItemOnBpCompletion: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    allowConcurrentInstances: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    allowMultipleInstances: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    dynamicTrigger: (f = msg.getDynamicTrigger()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.activity.BusinessProcessTemplateForActivity;
  return proto.wilqo.api.activity.BusinessProcessTemplateForActivity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanProgressItemIdentifier(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleteProgressItemOnBpCompletion(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowConcurrentInstances(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowMultipleInstances(value);
      break;
    case 9:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.deserializeBinaryFromReader);
      msg.setDynamicTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.activity.BusinessProcessTemplateForActivity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInternalName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLoanProgressItemIdentifier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompleteProgressItemOnBpCompletion();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getAllowConcurrentInstances();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAllowMultipleInstances();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDynamicTrigger();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_template_id = 1;
 * @return {string}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string internal_name = 3;
 * @return {string}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getInternalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setInternalName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string loan_progress_item_identifier = 5;
 * @return {string}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getLoanProgressItemIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setLoanProgressItemIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool complete_progress_item_on_bp_completion = 6;
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getCompleteProgressItemOnBpCompletion = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setCompleteProgressItemOnBpCompletion = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool allow_concurrent_instances = 7;
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getAllowConcurrentInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setAllowConcurrentInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool allow_multiple_instances = 8;
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getAllowMultipleInstances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setAllowMultipleInstances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional wilqo.shared.rulesengine.DynamicRuleAggregator dynamic_trigger = 9;
 * @return {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.getDynamicTrigger = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DynamicRuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator, 9));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator|undefined} value
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
*/
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.setDynamicTrigger = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.activity.BusinessProcessTemplateForActivity} returns this
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.clearDynamicTrigger = function() {
  return this.setDynamicTrigger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.activity.BusinessProcessTemplateForActivity.prototype.hasDynamicTrigger = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * @enum {number}
 */
proto.wilqo.api.activity.TemplateVariableDataType = {
  UNKNOWN_DATA_TYPE: 0,
  STRING: 1,
  DATE: 2,
  BOOL: 3,
  DOUBLE: 4,
  MESSAGE: 5,
  ADDRESS: 6
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.ActivityStatus = {
  UNKNOWN: 0,
  CREATED: 1,
  VIEWED: 2,
  COMPLETE: 3,
  ARCHIVED: 4,
  CANCELLED: 5
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.ActivityType = {
  CONSUMER_INQUIRY: 0,
  PANEL_SEQUENCE: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.AsyncValidationTypeEnum = {
  ASYNC_VALIDATION_TYPE_ENUM_UNKNOWN: 0,
  ASYNC_VALIDATION_TYPE_ENUM_LOAN_DUPLICATE_CHECK: 1,
  ASYNC_VALIDATION_TYPE_ENUM_LENDER_LICENSE_CHECK: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.FieldTypeEnum = {
  FIELD_TYPE_ENUM_GENERAL_TEXT: 0,
  FIELD_TYPE_ENUM_NUMBER: 1,
  FIELD_TYPE_ENUM_CURRENCY: 2,
  FIELD_TYPE_ENUM_DATE: 3,
  FIELD_TYPE_ENUM_DATE_TIME: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.ScopeReferenceAppliesToEnum = {
  SCOPE_REFERENCE_APPLIES_TO_ENUM_UNKNOWN: 0,
  SCOPE_REFERENCE_APPLIES_TO_ENUM_PANEL_ELEMENT: 1,
  SCOPE_REFERENCE_APPLIES_TO_ENUM_PANEL: 2,
  SCOPE_REFERENCE_APPLIES_TO_ENUM_ACTIVITY: 3
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.IntegrationTypeLocalEnum = {
  INTEGRATION_TYPE_LOCAL_ENUM_UNKNOWN: 0,
  INTEGRATION_TYPE_LOCAL_ENUM_CREDIT: 1,
  INTEGRATION_TYPE_LOCAL_ENUM_FRAUD: 2,
  INTEGRATION_TYPE_LOCAL_ENUM_FLOOD: 3,
  INTEGRATION_TYPE_LOCAL_ENUM_DISCLOSURES: 4,
  INTEGRATION_TYPE_LOCAL_ENUM_COMPLIANCE: 5,
  INTEGRATION_TYPE_LOCAL_ENUM_FEES: 6,
  INTEGRATION_TYPE_LOCAL_ENUM_AUS: 7,
  INTEGRATION_TYPE_LOCAL_ENUM_APPRAISAL: 8,
  INTEGRATION_TYPE_LOCAL_ENUM_PRODUCT_AND_PRICING: 9,
  INTEGRATION_TYPE_LOCAL_ENUM_MERS_REGISTRATION: 10
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.IntegrationVendorEnum = {
  INTEGRATION_VENDOR_ENUM_UNKNOWN: 0,
  INTEGRATION_VENDOR_ENUM_BLEND: 1,
  INTEGRATION_VENDOR_ENUM_FACTUAL: 2,
  INTEGRATION_VENDOR_ENUM_ARCHMORTGAGEINSURANCE: 3,
  INTEGRATION_VENDOR_ENUM_DESKTOPUNDERWRITER: 4,
  INTEGRATION_VENDOR_ENUM_OPTIMAL_BLUE: 5,
  INTEGRATION_VENDOR_ENUM_POLLY: 6,
  INTEGRATION_VENDOR_ENUM_DOCUTECH: 7,
  INTEGRATION_VENDOR_ENUM_REGGORA: 8,
  INTEGRATION_VENDOR_ENUM_XACTUS: 9,
  INTEGRATION_VENDOR_ENUM_MERS: 10
};

/**
 * @enum {number}
 */
proto.wilqo.api.activity.ValidationTypesEnum = {
  VALIDATION_TYPES_ENUM_ADDRESS: 0,
  VALIDATION_TYPES_ENUM_APPRAISAL_EXPIRATION: 1,
  VALIDATION_TYPES_ENUM_APPRAISED_VALUE_MATCH: 2,
  VALIDATION_TYPES_ENUM_ASSIGNMENT_TYPE_MATCH: 3,
  VALIDATION_TYPES_ENUM_BORROWER_NAME: 5,
  VALIDATION_TYPES_ENUM_LENDER_NAME: 6,
  VALIDATION_TYPES_ENUM_PROPERTY_VALUATION: 7,
  VALIDATION_TYPES_ENUM_SPECIAL_ASSESSMENT: 9,
  VALIDATION_TYPES_ENUM_DEAL_RULE: 10,
  VALIDATION_TYPES_ENUM_ASSIGNMENT_TYPE_REFI_LISTED_LAST_YEAR: 11,
  VALIDATION_TYPES_ENUM_ASSIGNMENT_TYPE_REFI_BORROWER_MATCH: 12,
  VALIDATION_TYPES_ENUM_ASSIGNMENT_TYPE_PURCHASE_PRICE_MATCH: 13,
  VALIDATION_TYPES_ENUM_ASSIGNMENT_TYPE_PURCHASE_DATE_MATCH: 14,
  VALIDATION_TYPES_ENUM_MANUAL_ADVERSE_CONDITION: 15,
  VALIDATION_TYPES_ENUM_MANUAL_COMMENT_1: 16,
  VALIDATION_TYPES_ENUM_MANUAL_COMMENT_2: 17,
  VALIDATION_TYPES_ENUM_MANUAL_LICENSE_2: 18,
  VALIDATION_TYPES_ENUM_MANUAL_PHOTO_CONSISTENCY: 19,
  VALIDATION_TYPES_ENUM_MANUAL_PHOTO_PRESENT: 20,
  VALIDATION_TYPES_ENUM_MANUAL_PHYSICAL_DEFICIENCIES: 21
};

goog.object.extend(exports, proto.wilqo.api.activity);
