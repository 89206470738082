// source: Wilqo.API.Mortgage.DynamicData/Circuit/CircuitSideEffect.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.SideEffectCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.repeatedFields_, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.repeatedFields_ = [2];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.SideEffectCase = {
  SIDE_EFFECT_NOT_SET: 0,
  WRITE_VALUE: 3,
  REQUEST: 4
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.SideEffectCase}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.getSideEffectCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.SideEffectCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contingencyRuleKeysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    writeValue: (f = msg.getWriteValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.toObject(includeInstance, f),
    request: (f = msg.getRequest()) && proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect;
  return proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addContingencyRuleKeys(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.deserializeBinaryFromReader);
      msg.setWriteValue(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContingencyRuleKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getWriteValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem.serializeBinaryToWriter
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string contingency_rule_keys = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.getContingencyRuleKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.setContingencyRuleKeysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.addContingencyRuleKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.clearContingencyRuleKeysList = function() {
  return this.setContingencyRuleKeysList([]);
};


/**
 * optional wilqo.shared.models.DynamicDataWriteItem write_value = 3;
 * @return {?proto.wilqo.shared.models.DynamicDataWriteItem}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.getWriteValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataWriteItem} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataWriteItem, 3));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataWriteItem|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.setWriteValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.clearWriteValue = function() {
  return this.setWriteValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.hasWriteValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CircuitSideEffectRequest request = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.getRequest = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.setRequest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffect.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest;
  return proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CircuitSideEffectRequestType type = 1;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.CircuitSideEffectRequestType = {
  CIRCUIT_SIDE_EFFECT_REQUEST_TYPE_CREATE_CURRENT_ADDRESS_REO: 0,
  CIRCUIT_SIDE_EFFECT_REQUEST_TYPE_DELETE_CURRENT_ADDRESS_REO: 1,
  CIRCUIT_SIDE_EFFECT_REQUEST_TYPE_PROMOTE_CURRENT_ADDRESS_REO_TO_SUBJECT_PROPERTY: 2
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
