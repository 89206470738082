import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { DefaultValueConfig } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/DefaultValueConfig_pb';
import type { RelatedNestingContentGroupingSettings, RelatedNestingContentGroupingSummarySettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/RelatedNestingContent_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DYNAMIC_PAGE_MESSAGE_NAME } from '@/API/Queries/mortgageDynamicData/useDynamicLoanPage';
import { Badge } from '@/Components/Atoms/Badge';
import { Icon } from '@/Components/Atoms/Icon';
import { Menu } from '@/Components/Atoms/Menu';
import { Typography } from '@/Components/Atoms/Typography';
import { useDynamicContext } from '@/Components/Features/DynamicLoanPage/DynamicContext';
import { WidgetRenderer } from '@/Components/Widgets/WidgetRenderer';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';
import { usePropsState } from '@/Utils/Hooks/usePropsState';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { DeleteItemCollection } from '../DeleteItemCollection';
import { TableCollection } from '../TableForm/TableCollection';
import { useDialogData } from '../useDialogData';
import { ExpandStatusTypeEnum, useWidgetContext } from '../WidgetContext';
import { ActivityTableCollection } from './ActivityTableCollection';

export interface PageConfig {
  pageId: string;
  scopeToken: string;
  isNew: boolean;
  isNested: boolean;
  dynamicValue?: DynamicDataElement.AsObject;
  virtualId?: string;
}

interface NestedCollectionRowProps {
  rowData: RelatedNestingContentGroupingSettings.AsObject;
  quickAddEditInfo: PageConfig | undefined;
  setQuickAddEditInfo: React.Dispatch<React.SetStateAction<PageConfig | undefined>>;
  currentRowBeingEdited: string | undefined;
  setCurrentRowBeingEdited: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const NestedCollectionCollapsibleRow = ({
  currentRowBeingEdited,
  quickAddEditInfo,
  rowData,
  setCurrentRowBeingEdited,
  setQuickAddEditInfo,
}: NestedCollectionRowProps) => {
  const { expandStatus } = useWidgetContext();
  const [openRow, setOpenRow] = usePropsState(expandStatus === ExpandStatusTypeEnum.OPEN_ALL);
  const [deleteRowScopeToken, setDeleteRowScopeToken] = useState<string | undefined>();

  const {
    addEditingWidget,
    context,
    editingWidgetIds,
    hasSavedIds,
    pageId: parentPageId,
    scopeToken: parentScopeToken,
    setHasSavedIds,
    tabId: parentTabId,
  } = useWidgetContext();
  const queryClient = useQueryClient();
  const { showSnackBar } = useShared();
  const { loanId } = useDynamicLoanInfo();
  const { setVersion, toggleAction } = useDynamicContext();

  const { data: quickAddEditWidget } = useDialogData(
    loanId,
    quickAddEditInfo?.pageId || '',
    quickAddEditInfo?.scopeToken || '',
    '',
    quickAddEditInfo?.isNew,
    quickAddEditInfo?.virtualId,
    quickAddEditInfo?.dynamicValue,
    DefaultValueConfig.WidgetType.WIDGET_TYPE_RELATED_NESTING_CONTENT,
  );

  const collapsibleRowId = useRef(uuid());
  const isCurrentRowBeingEdited = collapsibleRowId.current === currentRowBeingEdited;
  const hasNoNestedChildren = !rowData.tableForm?.rowDataList.length
    && !rowData.conditionActivitiesTable?.rowDataList.length;

  // if this row is adding the first record and you start editing other
  // this will stop adding and collapse the row
  useEffect(() => {
    if (!isCurrentRowBeingEdited && hasNoNestedChildren) {
      setOpenRow(false);
    }
  }, [hasNoNestedChildren, isCurrentRowBeingEdited, setOpenRow]);

  // if no nested children it should close automatically
  useEffect(() => {
    if (hasNoNestedChildren) {
      setOpenRow(false);
    }
  }, [hasNoNestedChildren, setOpenRow]);

  // enable the CardListItem editing state by default
  useEffect(() => {
    if (openRow && quickAddEditWidget?.page && context === 'dialog') {
      const newEditingWidgets = quickAddEditWidget.page.widgetsList
        .map((w) => w.id)
        .filter((id) => !editingWidgetIds.includes(id));

      if (newEditingWidgets.length > 0) {
        addEditingWidget(newEditingWidgets);
      }
    }
  }, [addEditingWidget, context, editingWidgetIds, openRow, quickAddEditWidget?.page]);

  // after adding new item should close the form
  useEffect(() => {
    const savedWidgetId = hasSavedIds?.find(
      (savedId) => quickAddEditWidget?.page?.widgetsList.some((w) => savedId === w.id),
    );

    if (hasSavedIds?.length && savedWidgetId) {
      if (setHasSavedIds) setHasSavedIds(hasSavedIds?.filter((id) => id !== savedWidgetId));
      setQuickAddEditInfo(undefined);
      if (hasNoNestedChildren) setOpenRow(false);
    }
  }, [hasNoNestedChildren, hasSavedIds, quickAddEditWidget?.page?.widgetsList, setHasSavedIds, setOpenRow, setQuickAddEditInfo]);

  const renderGroupCaption = (settings?: RelatedNestingContentGroupingSummarySettings.AsObject) => {
    if (settings && settings.itemsList.length > 0) {
      return settings?.itemsList.map((item) => renderValue(item)).join(` ${settings.delimiter} `);
    }
    return '';
  };

  const getKebab = useCallback((rowData: RelatedNestingContentGroupingSettings.AsObject) => {
    const items = [];

    if (rowData.expander?.kebab?.add) {
      items.push({
        label: rowData.expander?.kebab?.add?.label || 'Add',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          if (rowData.expander?.kebab?.add) {
            setCurrentRowBeingEdited(collapsibleRowId.current);
            setQuickAddEditInfo({
              ...rowData.expander?.kebab?.add,
              dynamicValue: rowData.item?.value,
              isNested: true,
              isNew: true,
              virtualId: rowData.virtualFieldId,
            });
            setOpenRow(true);
          }
        },
      });
    }

    if (rowData.expander?.kebab?.edit) {
      items.push({
        label: rowData.expander?.kebab?.edit?.label || 'Edit',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          if (rowData.expander?.kebab?.edit) {
            setCurrentRowBeingEdited(collapsibleRowId.current);
            setQuickAddEditInfo({ ...rowData.expander?.kebab?.edit, isNested: true, isNew: false });
            setOpenRow(true);
          }
        },
      });
    }

    if (rowData.expander?.kebab?.pb_delete?.enabled) {
      items.push({
        label: rowData.expander?.kebab.pb_delete.label || 'Delete',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          if (rowData.expander?.kebab?.filterParentScopeForDelete && rowData.expander?.kebab?.pb_delete?.scopeToken) {
            rowData.expander.kebab.pb_delete.scopeToken = rowData.expander.kebab.pb_delete.scopeToken.split(';').slice(1).join(';');
          }
          setDeleteRowScopeToken(rowData.expander?.kebab?.pb_delete?.scopeToken);
        },
      });
    }

    if (rowData.expander?.kebab?.actionsList) {
      rowData.expander.kebab.actionsList.forEach((action) => {
        items.push({
          label: action.label,
          onClick: () => {
            toggleAction({ ...action, additionalArgsMap: [['conditionId', action.targetId], ['conditionName', renderValue(rowData.label)]] });
          },
        });
      });
    }
    return items;
  }, [setCurrentRowBeingEdited, setOpenRow, setQuickAddEditInfo, toggleAction]);

  const renderNestedWidget = () => {
    if (isCurrentRowBeingEdited && quickAddEditInfo && quickAddEditWidget) {
      return <WidgetRenderer widgetList={quickAddEditWidget?.page?.widgetsList || []} />;
    }

    return (
      <div className="ml-11 mr-2">
        {rowData.tableForm && <TableCollection {...rowData.tableForm} />}
        {rowData.conditionActivitiesTable && <ActivityTableCollection {...rowData.conditionActivitiesTable} />}
      </div>
    );
  };

  return (
    <div className="w-full pb-4">
      <div className="w-full flex items-center gap-2 px-2 py-3">
        <button
          className="flex-1 flex items-center gap-2"
          disabled={hasNoNestedChildren}
          onClick={() => setOpenRow(!openRow)}
          type="button"
        >
          {hasNoNestedChildren ? <div className="mr-10" /> : (
            <Icon
              icon={openRow ? 'ArrowDropDown' : 'ArrowRight'}
              variant="interactive"
            />
          )}

          <div className="mr-auto text-left">
            <Typography variant="subtitle1Bold">
              {renderValue(rowData.label)}
            </Typography>

            <Typography variant="caption">
              {renderGroupCaption(openRow ? rowData.expander?.expandedSummary : rowData.expander?.collapsedSummary)}
            </Typography>
          </div>

          {rowData.expander?.badge && (
            <Badge
              className={clsx({ 'mr-2': !openRow })}
              label={renderValue(rowData.expander.badge.value)}
              variant={rowData.expander.badge.variant}
            />
          )}
        </button>

        <Menu
          closeAfterSelecting
          options={getKebab(rowData)}
          triggerItem={(
            <Icon
              icon="MoreVert"
              variant="interactive"
            />
          )}
        />
      </div>

      {openRow && (
        <div className="p-3">
          {renderNestedWidget()}
        </div>
      )}

      {deleteRowScopeToken && (
        <DeleteItemCollection
          close={() => setDeleteRowScopeToken(undefined)}
          dealId={loanId}
          handleDeleteSuccess={(version) => {
            setVersion(version);
            setDeleteRowScopeToken(undefined);
            showSnackBar({ message: 'Loan updated successfully', open: true });
            queryClient.refetchQueries([DYNAMIC_PAGE_MESSAGE_NAME, loanId, parentPageId, parentScopeToken, parentTabId || '']);
          }}
          scopeToken={deleteRowScopeToken}
        />
      )}
    </div>
  );
};
