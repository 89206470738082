import { useParams } from 'react-router';

import { useLoan } from '@/API/Queries/mortgage/useLoan';
import { useLoanChangeHistoryItem } from '@/API/Queries/mortgage/useLoanChangeHistoryItem';
import { DynamicIcon } from '@/Components/Atoms/DynamicIcon';
import { JsonDiff } from '@/Components/Atoms/JsonDiff';
import { useSearchParams } from '@/Routes/NavigationContext';

export const OMIT_UNCHANGED_SEARCH_PARAM = 'omitUnchanged';

export const ChangeHistoryDetails = () => {
  const { data: loan, isLoading: isLoanLoading } = useLoan();
  const { dealVersion = '' } = useParams();
  const [searchParams] = useSearchParams();
  const compare = searchParams.get('compare');
  const showUnchanged = searchParams.get(OMIT_UNCHANGED_SEARCH_PARAM) !== 'true';
  const dealVersionInt = parseInt(dealVersion, 10);
  const { data: primary, isLoading: isPrimaryLoading } = useLoanChangeHistoryItem(loan?.dealId, dealVersionInt);
  const { data: secondary, isLoading: isSecondaryLoading } = useLoanChangeHistoryItem(loan?.dealId, compare);
  const isLoading = isLoanLoading || isPrimaryLoading || isSecondaryLoading;

  return isLoading
    ? <DynamicIcon className="animate-spin" icon="Loop" />
    : <JsonDiff left={secondary?.entityJson} right={primary?.entityJson} showUnchanged={showUnchanged} />;
};
