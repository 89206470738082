import { useCallback, useMemo, useState } from 'react';

import type { LoanFeeSummary } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb';
import { FeeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useLoanFeeTemplateArchive } from '@/API/Queries/mortgageConfig/useLoanFeeTemplateArchive';
import { useLoanFeeTemplates } from '@/API/Queries/mortgageConfig/useLoanFeeTemplates';
import type { Column, TableSort } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useNavigate } from '@/Routes/NavigationContext';
import { ConvertEnum } from '@/Utils/Helpers/enumHelpers';
import { getDateFromTimestamp } from '@/Utils/Helpers/getDateFromTimestamp';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { CreateFeeTemplate } from './FeeTemplateConfig/Create';

const LoanFeeTemplates = () => {
  const navigate = useNavigate();
  const { showSnackBar } = useShared();

  // STATE
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [sort, setSort] = useState<TableSort>();
  const [search, setSearch] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<LoanFeeSummary.AsObject[]>([]);

  // QUERY
  const { data, isLoading, refetch } = useLoanFeeTemplates({
    page: currentPage,
    pageSize: currentPageSize,
    search,
    showArchived,
    sort,
  });

  const { mutate: archiveFeeTemplate } = useLoanFeeTemplateArchive();

  // EVENT HANDLERS
  const handleArchiveSuccess = useCallback(() => {
    showSnackBar({ message: 'Fee Template Archived', open: true });
    refetch();
  }, [showSnackBar, refetch]);

  const handleArchiveError = useCallback((error: any) => showSnackBar({ message: error.message, open: true }), [showSnackBar]);

  const handleArchiveClick = (items = selectedTemplates) => {
    archiveFeeTemplate(
      items.map((e) => e.id),
      {
        onError: handleArchiveError,
        onSuccess: handleArchiveSuccess,
      },
    );
  };

  const handleRowClick = (item: any) => navigate(`/admin/loanFeeTemplate/details?feeTemplateId=${item.id}`);

  const handleOnCloseModal = useCallback(() => {
    setOpenModal(false);
    refetch();
  }, [refetch]);

  const handleNewPageSize = useCallback((page: number) => {
    setCurrentPageSize(page);
    refetch();
  }, [setCurrentPageSize, refetch]);

  const columns = useMemo((): Array<Column<LoanFeeSummary.AsObject>> => [
    {
      header: 'Fee Name',
      id: 'name',
      key: { text: 'name', type: 'text' },
    },
    {
      header: 'Fee Type',
      id: 'type',
      key: { text: (item) => ConvertEnum(FeeEnum, item.type), type: 'text' },
    },
    {
      header: 'Last Published',
      id: 'lastPublished',
      key: {
        text: (item) => (!item.lastPublishedDate?.storage ? '--' : getDateFromTimestamp(item.lastPublishedDate.storage)?.toDateString() || ''),
        type: 'text',
      },
    },
  ], []);

  return (
    <>
      <Table
        cardProps={{
          headerProps: {
            actions: [{
              label: 'Create new',
              onClick: () => setOpenModal(true),
            }],
            contextualHeaderProps: {
              buttons: [
                { label: 'Archive', onClick: () => handleArchiveClick() },
              ],
              caption: `${selectedTemplates.length} fee templates selected`,
            },
            filtersProps: {
              items: [{ onClick: () => setShowArchived(!showArchived), selected: showArchived, value: 'Archived' }],
              variant: 'flex',
            },
            searchProps: { onChange: setSearch, value: search },
            showContextual: selectedTemplates.length > 0,
            title: 'All Loan Fees',
          },
        }}
        columns={columns}
        currentPage={currentPage}
        data={data?.loanFeeTemplatesList || []}
        handleNewPage={setCurrentPage}
        handleNewPageSize={handleNewPageSize}
        hasOwnPagination
        hoverActions={[{ label: 'Archive', onClick: (item) => handleArchiveClick([item]) }]}
        isLoading={isLoading}
        numberOfPages={data?.pageDetails?.currentPageCount ? Math.ceil(data.pageDetails.totalCount / currentPageSize) : 0}
        onClickRow={handleRowClick}
        setRowSelection={setSelectedTemplates}
        setSort={setSort}
        sort={sort}
        totalItems={data?.pageDetails?.totalCount}
      />
      {openModal && <CreateFeeTemplate onClose={handleOnCloseModal} />}
    </>
  );
};

export { LoanFeeTemplates };
