// source: Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('../Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_BrandAdminModels_pb = require('../Wilqo.Shared.Models/BrandAdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_BrandAdminModels_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_API_Mortgage_DynamicData_Models_pb = require('../Wilqo_API_Mortgage_DynamicData_Models_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_Models_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb = require('../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb);
var Wilqo_Shared_Models_UIComponents_ApplicationAction_pb = require('../Wilqo.Shared.Models/UIComponents/ApplicationAction_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_ApplicationAction_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPageType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.displayName = 'proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.displayName = 'proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string field_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.getFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.setFieldIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.addFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.clearFieldIdsList = function() {
  return this.setFieldIdsList([]);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryDefinition;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryDefinition.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FieldLibraryDefinition items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Models_pb.FieldLibraryDefinition, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.FieldLibraryDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryDefinitionQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealVersion: (f = msg.getDealVersion()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    fieldLibraryQueryList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    returnJson: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setDealVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldLibraryQuery(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnJson(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealVersion();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getFieldLibraryQueryList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getReturnJson();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.UInt32Value deal_version = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.getDealVersion = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 2));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.clearDealVersion = function() {
  return this.setDealVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.hasDealVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string field_library_query = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.getFieldLibraryQueryList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.setFieldLibraryQueryList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.addFieldLibraryQuery = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.clearFieldLibraryQueryList = function() {
  return this.setFieldLibraryQueryList([]);
};


/**
 * optional bool return_json = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.getReturnJson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.setReturnJson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject, includeInstance),
    jsonResponsesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addJsonResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = message.getJsonResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated wilqo.shared.models.DynamicDataElement responses = 2;
 * @return {!Array<!proto.wilqo.shared.models.DynamicDataElement>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.DynamicDataElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.DynamicDataElement>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.models.DynamicDataElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.models.DynamicDataElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};


/**
 * repeated string json_responses = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.getJsonResponsesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.setJsonResponsesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.addJsonResponses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFieldLibraryValuesQueryResponse.prototype.clearJsonResponsesList = function() {
  return this.setJsonResponsesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFieldIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string field_ids = 2;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.getFieldIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.setFieldIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.addFieldIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.clearFieldIdsList = function() {
  return this.setFieldIdsList([]);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsMap: (f = msg.getItemsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = msg.getItemsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, string> items = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.getItemsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealDataFromFieldLibraryIdentifiersQueryResponse.prototype.clearItemsMap = function() {
  this.getItemsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tabId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTabId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTabId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tab_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.getTabId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.setTabId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_process_domain_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    page: (f = msg.getPage()) && Wilqo_Shared_Models_BrandAdminModels_pb.Page.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_BrandAdminModels_pb.Page;
      reader.readMessage(value,Wilqo_Shared_Models_BrandAdminModels_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_BrandAdminModels_pb.Page.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.Page page = 2;
 * @return {?proto.wilqo.shared.models.Page}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.getPage = function() {
  return /** @type{?proto.wilqo.shared.models.Page} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_BrandAdminModels_pb.Page, 2));
};


/**
 * @param {?proto.wilqo.shared.models.Page|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 deal_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetPageDataQueryResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LoanHomeConfigPage pages = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage}
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetLoanHomeDataQueryResponse.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagesList: jspb.Message.toObjectList(msg.getPagesList(),
    proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig;
  return proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomain(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.deserializeBinaryFromReader);
      msg.addPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string business_process_domain = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.getBusinessProcessDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.setBusinessProcessDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated LoanHomeConfigPage pages = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage>}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.getPagesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.setPagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.addPages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfig.prototype.clearPagesList = function() {
  return this.setPagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageTitle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage;
  return proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPageType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string page_title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.getPageTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.setPageTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LoanHomeConfigPageType type = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPageType}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.getType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPageType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPageType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPage.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    pageConfig: (f = msg.getPageConfig()) && Wilqo_API_Mortgage_DynamicData_Models_pb.PageConfiguration.toObject(includeInstance, f),
    page: (f = msg.getPage()) && Wilqo_Shared_Models_BrandAdminModels_pb.Page.toObject(includeInstance, f),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_Models_pb.PageConfiguration;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Models_pb.PageConfiguration.deserializeBinaryFromReader);
      msg.setPageConfig(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_BrandAdminModels_pb.Page;
      reader.readMessage(value,Wilqo_Shared_Models_BrandAdminModels_pb.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPageConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_Models_pb.PageConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_BrandAdminModels_pb.Page.serializeBinaryToWriter
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PageConfiguration page_config = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.PageConfiguration}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.getPageConfig = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.PageConfiguration} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_Models_pb.PageConfiguration, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.PageConfiguration|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.setPageConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.clearPageConfig = function() {
  return this.setPageConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.hasPageConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional wilqo.shared.models.Page page = 3;
 * @return {?proto.wilqo.shared.models.Page}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.getPage = function() {
  return /** @type{?proto.wilqo.shared.models.Page} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_BrandAdminModels_pb.Page, 3));
};


/**
 * @param {?proto.wilqo.shared.models.Page|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 deal_version = 4;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetDealLoanHomePageConfigurationQueryResponse.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeTokenBuilderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dealVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeTokenBuilderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDealVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStage(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeTokenBuilderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDealVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string scope_token_builder_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.getScopeTokenBuilderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.setScopeTokenBuilderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 deal_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.getDealVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.setDealVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 stage = 5;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.getStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.setStage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string business_process_domain_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayValue: (f = msg.getDisplayValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem;
  return proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setDisplayValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional wilqo.shared.models.RenderableValue display_value = 1;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.getDisplayValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.setDisplayValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.clearDisplayValue = function() {
  return this.setDisplayValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.hasDisplayValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string scope_token = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ScopeTokenStageItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.ScopeTokenStageItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetScopeTokenBuilderStageQueryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    applicationActionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {!proto.wilqo.shared.models.ApplicationAction} */ (reader.readEnum());
      msg.setApplicationActionType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApplicationActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.ApplicationAction application_action_type = 2;
 * @return {!proto.wilqo.shared.models.ApplicationAction}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.getApplicationActionType = function() {
  return /** @type {!proto.wilqo.shared.models.ApplicationAction} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.wilqo.shared.models.ApplicationAction} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.setApplicationActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    triggerAction: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 4:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} */ (reader.readEnum());
      msg.setTriggerAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTriggerAction();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LoanPageActionType trigger_action = 4;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType}
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.getTriggerAction = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageActionType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetApplicationActionQueryResponse.prototype.setTriggerAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    summariesList: jspb.Message.toObjectList(msg.getSummariesList(),
    proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.toObject, includeInstance),
    addButtonEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.deserializeBinaryFromReader);
      msg.addSummaries(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddButtonEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.serializeBinaryToWriter
    );
  }
  f = message.getAddButtonEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection;
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ContactSideSheetItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initials: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    canNavigate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    partyId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    email: jspb.Message.getFieldWithDefault(msg, 9, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    order: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    wilqoUserIdentifier: jspb.Message.getFieldWithDefault(msg, 13, ""),
    wilqoUserVersionNumber: jspb.Message.getFieldWithDefault(msg, 14, 0),
    borrowerClassification: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem;
  return proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitials(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanNavigate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setWilqoUserIdentifier(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWilqoUserVersionNumber(value);
      break;
    case 15:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum} */ (reader.readEnum());
      msg.setBorrowerClassification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitials();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCanNavigate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getNationalLicensingId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getWilqoUserIdentifier();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWilqoUserVersionNumber();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getBorrowerClassification();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initials = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getInitials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setInitials = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string party_type = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getPartyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setPartyType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string scope_token = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool can_navigate = 6;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getCanNavigate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setCanNavigate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string party_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string email = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string profile_image_url = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 order = 11;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string national_licensing_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string wilqo_user_identifier = 13;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getWilqoUserIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setWilqoUserIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 wilqo_user_version_number = 14;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getWilqoUserVersionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setWilqoUserVersionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional BorrowerClassificationEnum borrower_classification = 15;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.getBorrowerClassification = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetItem.prototype.setBorrowerClassification = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ContactSideSheetSection summaries = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.getSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.setSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.addSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.ContactSideSheetSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.clearSummariesList = function() {
  return this.setSummariesList([]);
};


/**
 * optional bool add_button_enabled = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.getAddButtonEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetContactSideSheetDataQueryResponse.prototype.setAddButtonEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    progressItemsList: jspb.Message.toObjectList(msg.getProgressItemsList(),
    Wilqo_API_Mortgage_DynamicData_Models_pb.SidebarProgressItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_Models_pb.SidebarProgressItem;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_Models_pb.SidebarProgressItem.deserializeBinaryFromReader);
      msg.addProgressItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getProgressItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_Models_pb.SidebarProgressItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SidebarProgressItem progress_items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.getProgressItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_Models_pb.SidebarProgressItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.setProgressItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.addProgressItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.SidebarProgressItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetProgressItemsSideSheetDataQueryResponse.prototype.clearProgressItemsList = function() {
  return this.setProgressItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    widgetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    childFieldId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    parentFieldsList: jspb.Message.toObjectList(msg.getParentFieldsList(),
    proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.toObject, includeInstance),
    withoutData: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setChildFieldId(value);
      break;
    case 7:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.deserializeBinaryFromReader);
      msg.addParentFields(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithoutData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getChildFieldId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getParentFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.serializeBinaryToWriter
    );
  }
  f = message.getWithoutData();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldValue: (f = msg.getFieldValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField;
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DynamicDataElement field_value = 2;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.getFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.setFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.clearFieldValue = function() {
  return this.setFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField.prototype.hasFieldValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string scope_token = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string widget_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string child_field_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getChildFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setChildFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated ParentField parent_fields = 7;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getParentFieldsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField, 7));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setParentFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.addParentFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.ParentField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.clearParentFieldsList = function() {
  return this.setParentFieldsList([]);
};


/**
 * optional bool without_data = 8;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.getWithoutData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryRequest.prototype.setWithoutData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    filteredField: (f = msg.getFilteredField()) && proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.deserializeBinaryFromReader);
      msg.setFilteredField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getFilteredField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.toObject = function(includeInstance, msg) {
  var f, obj = {
    widgetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    panelElement: (f = msg.getPanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    defaultFieldValue: (f = msg.getDefaultFieldValue()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField;
  return proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidgetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldId(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setPanelElement(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setDefaultFieldValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidgetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPanelElement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getDefaultFieldValue();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
};


/**
 * optional string widget_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.getWidgetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.setWidgetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.getFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.PanelElement panel_element = 3;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.getPanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 3));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.setPanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.clearPanelElement = function() {
  return this.setPanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.hasPanelElement = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement default_field_value = 4;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.getDefaultFieldValue = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.setDefaultFieldValue = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.clearDefaultFieldValue = function() {
  return this.setDefaultFieldValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField.prototype.hasDefaultFieldValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FilteredField filtered_field = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.getFilteredField = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.FilteredField|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.setFilteredField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.clearFilteredField = function() {
  return this.setFilteredField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetFilteredFieldValueQueryResponse.prototype.hasFilteredField = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest;
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    summariesList: jspb.Message.toObjectList(msg.getSummariesList(),
    proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.toObject, includeInstance),
    addButtonEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse;
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.deserializeBinaryFromReader);
      msg.addSummaries(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddButtonEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.serializeBinaryToWriter
    );
  }
  f = message.getAddButtonEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.toObject, includeInstance),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection;
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = new proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PartySideSheetItem items = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initials: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partyTypeLabel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    partyId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    wilqoUserIdentifier: jspb.Message.getFieldWithDefault(msg, 8, ""),
    wilqoUserVersionNumber: jspb.Message.getFieldWithDefault(msg, 9, 0),
    canDelete: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isBorrower: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    borrowerClassification: jspb.Message.getFieldWithDefault(msg, 12, 0),
    order: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem;
  return proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitials(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyTypeLabel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartyId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWilqoUserIdentifier(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWilqoUserVersionNumber(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanDelete(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBorrower(value);
      break;
    case 12:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum} */ (reader.readEnum());
      msg.setBorrowerClassification(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInitials();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartyTypeLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPartyId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWilqoUserIdentifier();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWilqoUserVersionNumber();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getCanDelete();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsBorrower();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getBorrowerClassification();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initials = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getInitials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setInitials = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string party_type_label = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getPartyTypeLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setPartyTypeLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string page_id = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string scope_token = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string party_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string profile_image_url = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string wilqo_user_identifier = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getWilqoUserIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setWilqoUserIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 wilqo_user_version_number = 9;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getWilqoUserVersionNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setWilqoUserVersionNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool can_delete = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getCanDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setCanDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_borrower = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getIsBorrower = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setIsBorrower = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional BorrowerClassificationEnum borrower_classification = 12;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getBorrowerClassification = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.BorrowerClassificationEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setBorrowerClassification = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int32 order = 13;
 * @return {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetItem.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PartySideSheetSection summaries = 2;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection>}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.getSummariesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.setSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.addSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.PartySideSheetSection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.clearSummariesList = function() {
  return this.setSummariesList([]);
};


/**
 * optional bool add_button_enabled = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.getAddButtonEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GetWilqoPartiesSideSheetDataQueryResponse.prototype.setAddButtonEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.LoanHomeConfigPageType = {
  LOAN_HOME_CONFIG_PAGE_TYPE_MAIN: 0,
  LOAN_HOME_CONFIG_PAGE_TYPE_CHIP: 1
};

goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
