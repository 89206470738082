// source: Wilqo.API.MortgageConfig/Shared/DataItem.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var Wilqo_Shared_Models_DynamicData_pb = require('../../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb = require('../../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_DynamicModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_config.DependencyConfig', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.ExternalDataFieldType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanPageDataItem', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanPageDataItem.DataProviderCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.LoanPageDataItem.DefaultValueCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.RequestDataValueConfigType', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.LoanPageDataItem.repeatedFields_, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanPageDataItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanPageDataItem.displayName = 'proto.wilqo.api.mortgage_config.LoanPageDataItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.displayName = 'proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.DependencyConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.DependencyConfig.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.DependencyConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.DependencyConfig.displayName = 'proto.wilqo.api.mortgage_config.DependencyConfig';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.repeatedFields_ = [12];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_ = [[3,4,5,6],[8]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DefaultValueCase = {
  DEFAULT_VALUE_NOT_SET: 0,
  EMPTY: 3,
  CONSTANT: 4,
  USER_DATA: 5,
  REQUEST_DATA: 6
};

/**
 * @return {proto.wilqo.api.mortgage_config.LoanPageDataItem.DefaultValueCase}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getDefaultValueCase = function() {
  return /** @type {proto.wilqo.api.mortgage_config.LoanPageDataItem.DefaultValueCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataProviderCase = {
  DATA_PROVIDER_NOT_SET: 0,
  EXTERNAL_DATA: 8
};

/**
 * @return {proto.wilqo.api.mortgage_config.LoanPageDataItem.DataProviderCase}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getDataProviderCase = function() {
  return /** @type {proto.wilqo.api.mortgage_config.LoanPageDataItem.DataProviderCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanPageDataItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    virtualFieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    virtualFieldDisplaySettings: (f = msg.getVirtualFieldDisplaySettings()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    empty: (f = msg.getEmpty()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f),
    constant: (f = msg.getConstant()) && Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.toObject(includeInstance, f),
    userData: jspb.Message.getFieldWithDefault(msg, 5, 0),
    requestData: jspb.Message.getFieldWithDefault(msg, 6, 0),
    externalData: jspb.Message.getFieldWithDefault(msg, 8, 0),
    defaultIfNull: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dependency: (f = msg.getDependency()) && proto.wilqo.api.mortgage_config.DependencyConfig.toObject(includeInstance, f),
    overrideExistingValueWithDefaultValue: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    ignoreOnSave: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    overridesList: jspb.Message.toObjectList(msg.getOverridesList(),
    proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanPageDataItem;
  return proto.wilqo.api.mortgage_config.LoanPageDataItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualFieldId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setVirtualFieldDisplaySettings(value);
      break;
    case 3:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setEmpty(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement;
      reader.readMessage(value,Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.deserializeBinaryFromReader);
      msg.setConstant(value);
      break;
    case 5:
      var value = /** @type {!proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType} */ (reader.readEnum());
      msg.setUserData(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.api.mortgage_config.RequestDataValueConfigType} */ (reader.readEnum());
      msg.setRequestData(value);
      break;
    case 8:
      var value = /** @type {!proto.wilqo.api.mortgage_config.ExternalDataFieldType} */ (reader.readEnum());
      msg.setExternalData(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultIfNull(value);
      break;
    case 9:
      var value = new proto.wilqo.api.mortgage_config.DependencyConfig;
      reader.readMessage(value,proto.wilqo.api.mortgage_config.DependencyConfig.deserializeBinaryFromReader);
      msg.setDependency(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOverrideExistingValueWithDefaultValue(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreOnSave(value);
      break;
    case 12:
      var value = new proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides;
      reader.readMessage(value,proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.deserializeBinaryFromReader);
      msg.addOverrides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanPageDataItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVirtualFieldDisplaySettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getEmpty();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
  f = message.getConstant();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.RequestDataValueConfigType} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {!proto.wilqo.api.mortgage_config.ExternalDataFieldType} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDefaultIfNull();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDependency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.api.mortgage_config.DependencyConfig.serializeBinaryToWriter
    );
  }
  f = message.getOverrideExistingValueWithDefaultValue();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIgnoreOnSave();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getOverridesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.toObject = function(includeInstance, msg) {
  var f, obj = {
    rules: (f = msg.getRules()) && Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.toObject(includeInstance, f),
    settings: (f = msg.getSettings()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides;
  return proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator;
      reader.readMessage(value,Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.deserializeBinaryFromReader);
      msg.setRules(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRules();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator.serializeBinaryToWriter
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.rulesengine.DynamicRuleAggregator rules = 1;
 * @return {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.getRules = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.DynamicRuleAggregator} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_DynamicModels_pb.DynamicRuleAggregator, 1));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.DynamicRuleAggregator|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.setRules = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.clearRules = function() {
  return this.setRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.hasRules = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.shared.models.RenderableValue settings = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.getSettings = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string virtual_field_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.RenderableValue virtual_field_display_settings = 2;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getVirtualFieldDisplaySettings = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 2));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setVirtualFieldDisplaySettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearVirtualFieldDisplaySettings = function() {
  return this.setVirtualFieldDisplaySettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasVirtualFieldDisplaySettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Empty empty = 3;
 * @return {?proto.google.protobuf.Empty}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getEmpty = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 3));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setEmpty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearEmpty = function() {
  return this.setEmpty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasEmpty = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.DynamicDataElement constant = 4;
 * @return {?proto.wilqo.shared.models.DynamicDataElement}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getConstant = function() {
  return /** @type{?proto.wilqo.shared.models.DynamicDataElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_DynamicData_pb.DynamicDataElement, 4));
};


/**
 * @param {?proto.wilqo.shared.models.DynamicDataElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setConstant = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearConstant = function() {
  return this.setConstant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasConstant = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UserDataDefaultValueConfigType user_data = 5;
 * @return {!proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getUserData = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setUserData = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearUserData = function() {
  return jspb.Message.setOneofField(this, 5, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasUserData = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RequestDataValueConfigType request_data = 6;
 * @return {!proto.wilqo.api.mortgage_config.RequestDataValueConfigType}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getRequestData = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.RequestDataValueConfigType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.RequestDataValueConfigType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setRequestData = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearRequestData = function() {
  return jspb.Message.setOneofField(this, 6, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasRequestData = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ExternalDataFieldType external_data = 8;
 * @return {!proto.wilqo.api.mortgage_config.ExternalDataFieldType}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getExternalData = function() {
  return /** @type {!proto.wilqo.api.mortgage_config.ExternalDataFieldType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_config.ExternalDataFieldType} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setExternalData = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearExternalData = function() {
  return jspb.Message.setOneofField(this, 8, proto.wilqo.api.mortgage_config.LoanPageDataItem.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasExternalData = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool default_if_null = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getDefaultIfNull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setDefaultIfNull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional DependencyConfig dependency = 9;
 * @return {?proto.wilqo.api.mortgage_config.DependencyConfig}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getDependency = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.DependencyConfig} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_config.DependencyConfig, 9));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.DependencyConfig|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setDependency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearDependency = function() {
  return this.setDependency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.hasDependency = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool override_existing_value_with_default_value = 10;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getOverrideExistingValueWithDefaultValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setOverrideExistingValueWithDefaultValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool ignore_on_save = 11;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getIgnoreOnSave = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setIgnoreOnSave = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated DataItemOverrides overrides = 12;
 * @return {!Array<!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides>}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.getOverridesList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides>} value
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
*/
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.setOverridesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides}
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.addOverrides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.mortgage_config.LoanPageDataItem.DataItemOverrides, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.LoanPageDataItem} returns this
 */
proto.wilqo.api.mortgage_config.LoanPageDataItem.prototype.clearOverridesList = function() {
  return this.setOverridesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.DependencyConfig.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.DependencyConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.DependencyConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.DependencyConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    childPanelElementIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    valueSetByParentValue: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.DependencyConfig}
 */
proto.wilqo.api.mortgage_config.DependencyConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.DependencyConfig;
  return proto.wilqo.api.mortgage_config.DependencyConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.DependencyConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.DependencyConfig}
 */
proto.wilqo.api.mortgage_config.DependencyConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildPanelElementIds(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValueSetByParentValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.DependencyConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.DependencyConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.DependencyConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChildPanelElementIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getValueSetByParentValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated string child_panel_element_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.getChildPanelElementIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.mortgage_config.DependencyConfig} returns this
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.setChildPanelElementIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.DependencyConfig} returns this
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.addChildPanelElementIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.DependencyConfig} returns this
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.clearChildPanelElementIdsList = function() {
  return this.setChildPanelElementIdsList([]);
};


/**
 * optional bool value_set_by_parent_value = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.getValueSetByParentValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.DependencyConfig} returns this
 */
proto.wilqo.api.mortgage_config.DependencyConfig.prototype.setValueSetByParentValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.RequestDataValueConfigType = {
  REQUEST_DATA_VALUE_CONFIG_TYPE_UNKNOWN: 0,
  REQUEST_DATA_VALUE_CONFIG_TYPE_SUBMIT_TIME: 1
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.UserDataDefaultValueConfigType = {
  USER_DATA_DEFAULT_VALUE_CONFIG_TYPE_UNKNOWN: 0,
  USER_DATA_DEFAULT_VALUE_CONFIG_TYPE_USER_NAME: 1,
  USER_DATA_DEFAULT_VALUE_CONFIG_TYPE_USER_ID: 2
};

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_config.ExternalDataFieldType = {
  EXTERNAL_DATA_FIELD_TYPE_UNKNOWN: 0,
  EXTERNAL_DATA_FIELD_TYPE_LOAN_ORIGINATOR: 1,
  EXTERNAL_DATA_FIELD_TYPE_LOAN_ORIGINATOR_ORGS: 2,
  EXTERNAL_DATA_FIELD_TYPE_LEAD_SOURCE: 3,
  EXTERNAL_DATA_FIELD_TYPE_REFERRAL_SOURCE: 4,
  EXTERNAL_DATA_FIELD_TYPE_STATE: 5,
  EXTERNAL_DATA_FIELD_TYPE_COUNTY: 6,
  EXTERNAL_DATA_FIELD_TYPE_COUNTY_FIPS_CODE: 7,
  EXTERNAL_DATA_FIELD_TYPE_WAREHOUSE_LINE: 8,
  EXTERNAL_DATA_FIELD_TYPE_ORGANIZATION: 9
};

goog.object.extend(exports, proto.wilqo.api.mortgage_config);
