// source: Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/TableForm.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_ActivityModels_pb = require('../../../Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_Shared_Models_RenderableValue_pb = require('../../../Wilqo.Shared.Models/RenderableValue_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_RenderableValue_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/LoanPageAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_SectionAction_pb);
var Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb = require('../../../Wilqo.Shared.Models/UIComponents/UxDisplayStatusEnum_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_UIComponents_UxDisplayStatusEnum_pb);
var Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb = require('../../../Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.CellDataModel', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.NewItemScopingCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableFormRowData', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.NewItemScopingCase', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.repeatedFields_, proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.repeatedFields_, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.oneofGroups_);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.CellDataModel.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.CellDataModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.CellDataModel.displayName = 'proto.wilqo.api.mortgage_dynamic_data.CellDataModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_dynamic_data.TableFormRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.displayName = 'proto.wilqo.api.mortgage_dynamic_data.TableFormRowData';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.repeatedFields_ = [3];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.oneofGroups_ = [[7,8]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.NewItemScopingCase = {
  NEW_ITEM_SCOPING_NOT_SET: 0,
  NEW_ITEM_SCOPE_TOKEN: 7,
  NEW_ITEM_ASSOCIATION: 8
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.NewItemScopingCase}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getNewItemScopingCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.NewItemScopingCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tablesSettingsList: jspb.Message.toObjectList(msg.getTablesSettingsList(),
    proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.toObject, includeInstance),
    newItemVisible: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    newItemLabel: jspb.Message.getFieldWithDefault(msg, 5, ""),
    newItemPageId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    newItemScopeToken: jspb.Message.getFieldWithDefault(msg, 7, ""),
    newItemAssociation: (f = msg.getNewItemAssociation()) && Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings.toObject(includeInstance, f),
    collapsedState: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 3:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.deserializeBinaryFromReader);
      msg.addTablesSettings(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewItemVisible(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewItemLabel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewItemPageId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewItemScopeToken(value);
      break;
    case 8:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings.deserializeBinaryFromReader);
      msg.setNewItemAssociation(value);
      break;
    case 9:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} */ (reader.readEnum());
      msg.setCollapsedState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTablesSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getNewItemVisible();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNewItemLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNewItemPageId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNewItemAssociation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings.serializeBinaryToWriter
    );
  }
  f = message.getCollapsedState();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string help_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated TableGroupWidgetSettings tables_settings = 3;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getTablesSettingsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setTablesSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.addTablesSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.clearTablesSettingsList = function() {
  return this.setTablesSettingsList([]);
};


/**
 * optional bool new_item_visible = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getNewItemVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setNewItemVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string new_item_label = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getNewItemLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setNewItemLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string new_item_page_id = 6;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getNewItemPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setNewItemPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string new_item_scope_token = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getNewItemScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setNewItemScopeToken = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.clearNewItemScopeToken = function() {
  return jspb.Message.setOneofField(this, 7, proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.hasNewItemScopeToken = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AssociationSettings new_item_association = 8;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.AssociationSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getNewItemAssociation = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings, 8));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.AssociationSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setNewItemAssociation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.clearNewItemAssociation = function() {
  return this.setNewItemAssociation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.hasNewItemAssociation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional WidgetCollapsedState collapsed_state = 9;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState}
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.getCollapsedState = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.GroupedTableFormWidgetSettings.prototype.setCollapsedState = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupTitleLabel: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupTitleValue: (f = msg.getGroupTitleValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    groupTitlePanelElement: (f = msg.getGroupTitlePanelElement()) && Wilqo_Shared_Models_ActivityModels_pb.PanelElement.toObject(includeInstance, f),
    table: (f = msg.getTable()) && proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.toObject(includeInstance, f),
    groupVirtualFieldId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupTitleLabel(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setGroupTitleValue(value);
      break;
    case 6:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElement;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElement.deserializeBinaryFromReader);
      msg.setGroupTitlePanelElement(value);
      break;
    case 4:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.deserializeBinaryFromReader);
      msg.setTable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupVirtualFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupTitleLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupTitleValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getGroupTitlePanelElement();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElement.serializeBinaryToWriter
    );
  }
  f = message.getTable();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.serializeBinaryToWriter
    );
  }
  f = message.getGroupVirtualFieldId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string group_title_label = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.getGroupTitleLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.setGroupTitleLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional wilqo.shared.models.RenderableValue group_title_value = 3;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.getGroupTitleValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.setGroupTitleValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.clearGroupTitleValue = function() {
  return this.setGroupTitleValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.hasGroupTitleValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional wilqo.shared.models.PanelElement group_title_panel_element = 6;
 * @return {?proto.wilqo.shared.models.PanelElement}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.getGroupTitlePanelElement = function() {
  return /** @type{?proto.wilqo.shared.models.PanelElement} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElement, 6));
};


/**
 * @param {?proto.wilqo.shared.models.PanelElement|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.setGroupTitlePanelElement = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.clearGroupTitlePanelElement = function() {
  return this.setGroupTitlePanelElement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.hasGroupTitlePanelElement = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TableFormWidgetSettings table = 4;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.getTable = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.setTable = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.clearTable = function() {
  return this.setTable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.hasTable = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string group_virtual_field_id = 5;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.getGroupVirtualFieldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableGroupWidgetSettings.prototype.setGroupVirtualFieldId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.repeatedFields_ = [5,6,15];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.oneofGroups_ = [[10,11]];

/**
 * @enum {number}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.NewItemScopingCase = {
  NEW_ITEM_SCOPING_NOT_SET: 0,
  NEW_ITEM_SCOPE_TOKEN: 10,
  NEW_ITEM_ASSOCIATION: 11
};

/**
 * @return {proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.NewItemScopingCase}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNewItemScopingCase = function() {
  return /** @type {proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.NewItemScopingCase} */(jspb.Message.computeOneofCase(this, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    helpText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    captionLabel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isEditable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    columnsList: jspb.Message.toObjectList(msg.getColumnsList(),
    proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.toObject, includeInstance),
    rowDataList: jspb.Message.toObjectList(msg.getRowDataList(),
    proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.toObject, includeInstance),
    newItemVisible: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    newItemLabel: jspb.Message.getFieldWithDefault(msg, 8, ""),
    newItemPageId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    newItemScopeToken: jspb.Message.getFieldWithDefault(msg, 10, ""),
    newItemAssociation: (f = msg.getNewItemAssociation()) && Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings.toObject(includeInstance, f),
    quickAddEditPageId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    collapsedState: jspb.Message.getFieldWithDefault(msg, 13, 0),
    noHeader: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    actionsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    renderType: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelpText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaptionLabel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEditable(value);
      break;
    case 5:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.deserializeBinaryFromReader);
      msg.addColumns(value);
      break;
    case 6:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableFormRowData;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.deserializeBinaryFromReader);
      msg.addRowData(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewItemVisible(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewItemLabel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewItemPageId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewItemScopeToken(value);
      break;
    case 11:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings.deserializeBinaryFromReader);
      msg.setNewItemAssociation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuickAddEditPageId(value);
      break;
    case 13:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} */ (reader.readEnum());
      msg.setCollapsedState(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoHeader(value);
      break;
    case 15:
      var values = /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addActions(values[i]);
      }
      break;
    case 16:
      var value = /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (reader.readEnum());
      msg.setRenderType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHelpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCaptionLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsEditable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.serializeBinaryToWriter
    );
  }
  f = message.getRowDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.serializeBinaryToWriter
    );
  }
  f = message.getNewItemVisible();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNewItemLabel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNewItemPageId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNewItemAssociation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings.serializeBinaryToWriter
    );
  }
  f = message.getQuickAddEditPageId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCollapsedState();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getNoHeader();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      15,
      f
    );
  }
  f = message.getRenderType();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string help_text = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getHelpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setHelpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string caption_label = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getCaptionLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setCaptionLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_editable = 4;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getIsEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setIsEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated TableColumnSettings columns = 5;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getColumnsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings, 5));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setColumnsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.addColumns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.clearColumnsList = function() {
  return this.setColumnsList([]);
};


/**
 * repeated TableFormRowData row_data = 6;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getRowDataList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableFormRowData, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setRowDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.addRowData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.mortgage_dynamic_data.TableFormRowData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.clearRowDataList = function() {
  return this.setRowDataList([]);
};


/**
 * optional bool new_item_visible = 7;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNewItemVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setNewItemVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string new_item_label = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNewItemLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setNewItemLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string new_item_page_id = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNewItemPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setNewItemPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string new_item_scope_token = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNewItemScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setNewItemScopeToken = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.clearNewItemScopeToken = function() {
  return jspb.Message.setOneofField(this, 10, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.hasNewItemScopeToken = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AssociationSettings new_item_association = 11;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.AssociationSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNewItemAssociation = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.AssociationSettings} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_CommonConfig_pb.AssociationSettings, 11));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.AssociationSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setNewItemAssociation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.clearNewItemAssociation = function() {
  return this.setNewItemAssociation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.hasNewItemAssociation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string quick_add_edit_page_id = 12;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getQuickAddEditPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setQuickAddEditPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional WidgetCollapsedState collapsed_state = 13;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getCollapsedState = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.WidgetCollapsedState} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setCollapsedState = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional bool no_header = 14;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getNoHeader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setNoHeader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated SectionAction actions = 15;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getActionsList = function() {
  return /** @type {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.SectionAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setActionsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.SectionAction} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.addActions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional CustomRenderType render_type = 16;
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.getRenderType = function() {
  return /** @type {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CustomRenderType} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormWidgetSettings.prototype.setRenderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string column_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.getColumnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.setColumnId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool hidden = 3;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableColumnSettings.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.CellDataModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstLineList: jspb.Message.toObjectList(msg.getFirstLineList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance),
    firstLineDelimiter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secondLineList: jspb.Message.toObjectList(msg.getSecondLineList(),
    Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject, includeInstance),
    secondLineDelimiter: jspb.Message.getFieldWithDefault(msg, 4, ""),
    badgeValue: (f = msg.getBadgeValue()) && Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.toObject(includeInstance, f),
    badgeVariant: jspb.Message.getFieldWithDefault(msg, 6, 0),
    detailPageId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    detailPageScopeToken: jspb.Message.getFieldWithDefault(msg, 8, ""),
    kebab: (f = msg.getKebab()) && proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.toObject(includeInstance, f),
    icon: jspb.Message.getFieldWithDefault(msg, 10, ""),
    iconTooltip: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.CellDataModel;
  return proto.wilqo.api.mortgage_dynamic_data.CellDataModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addFirstLine(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstLineDelimiter(value);
      break;
    case 3:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.addSecondLine(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecondLineDelimiter(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_RenderableValue_pb.RenderableValue;
      reader.readMessage(value,Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.deserializeBinaryFromReader);
      msg.setBadgeValue(value);
      break;
    case 6:
      var value = /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (reader.readEnum());
      msg.setBadgeVariant(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailPageId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailPageScopeToken(value);
      break;
    case 9:
      var value = new proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings;
      reader.readMessage(value,proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.deserializeBinaryFromReader);
      msg.setKebab(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconTooltip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.CellDataModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstLineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstLineDelimiter();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecondLineList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getSecondLineDelimiter();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBadgeValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_RenderableValue_pb.RenderableValue.serializeBinaryToWriter
    );
  }
  f = message.getBadgeVariant();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getDetailPageId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDetailPageScopeToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getKebab();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIconTooltip();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * repeated wilqo.shared.models.RenderableValue first_line = 1;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getFirstLineList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setFirstLineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.addFirstLine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.clearFirstLineList = function() {
  return this.setFirstLineList([]);
};


/**
 * optional string first_line_delimiter = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getFirstLineDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setFirstLineDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated wilqo.shared.models.RenderableValue second_line = 3;
 * @return {!Array<!proto.wilqo.shared.models.RenderableValue>}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getSecondLineList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.RenderableValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.RenderableValue>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setSecondLineList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.models.RenderableValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.addSecondLine = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.models.RenderableValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.clearSecondLineList = function() {
  return this.setSecondLineList([]);
};


/**
 * optional string second_line_delimiter = 4;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getSecondLineDelimiter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setSecondLineDelimiter = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional wilqo.shared.models.RenderableValue badge_value = 5;
 * @return {?proto.wilqo.shared.models.RenderableValue}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getBadgeValue = function() {
  return /** @type{?proto.wilqo.shared.models.RenderableValue} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_RenderableValue_pb.RenderableValue, 5));
};


/**
 * @param {?proto.wilqo.shared.models.RenderableValue|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setBadgeValue = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.clearBadgeValue = function() {
  return this.setBadgeValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.hasBadgeValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional wilqo.shared.models.UxDisplayStatusEnum badge_variant = 6;
 * @return {!proto.wilqo.shared.models.UxDisplayStatusEnum}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getBadgeVariant = function() {
  return /** @type {!proto.wilqo.shared.models.UxDisplayStatusEnum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.wilqo.shared.models.UxDisplayStatusEnum} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setBadgeVariant = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string detail_page_id = 7;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getDetailPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setDetailPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string detail_page_scope_token = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getDetailPageScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setDetailPageScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional TableCellKebabSettings kebab = 9;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getKebab = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings, 9));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings|undefined} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setKebab = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.clearKebab = function() {
  return this.setKebab(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.hasKebab = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string icon = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string icon_tooltip = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.getIconTooltip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.CellDataModel} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.CellDataModel.prototype.setIconTooltip = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    editEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    deleteEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    editPageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings;
  return proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteEnabled(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditPageId(value);
      break;
    case 4:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDeleteEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getEditPageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool edit_enabled = 1;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.getEditEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.setEditEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool delete_enabled = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.getDeleteEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.setDeleteEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string edit_page_id = 3;
 * @return {string}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.getEditPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.setEditPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated LoanPageAction actions = 4;
 * @return {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_Actions_LoanPageAction_pb.LoanPageAction, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction>} value
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} returns this
*/
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_dynamic_data.LoanPageAction}
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.mortgage_dynamic_data.LoanPageAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableCellKebabSettings.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowDataMap: (f = msg.getRowDataMap()) ? f.toObject(includeInstance, proto.wilqo.api.mortgage_dynamic_data.CellDataModel.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_dynamic_data.TableFormRowData;
  return proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getRowDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.wilqo.api.mortgage_dynamic_data.CellDataModel.deserializeBinaryFromReader, "", new proto.wilqo.api.mortgage_dynamic_data.CellDataModel());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.wilqo.api.mortgage_dynamic_data.CellDataModel.serializeBinaryToWriter);
  }
};


/**
 * map<string, CellDataModel> row_data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.wilqo.api.mortgage_dynamic_data.CellDataModel>}
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.prototype.getRowDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.wilqo.api.mortgage_dynamic_data.CellDataModel>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.wilqo.api.mortgage_dynamic_data.CellDataModel));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.wilqo.api.mortgage_dynamic_data.TableFormRowData} returns this
 */
proto.wilqo.api.mortgage_dynamic_data.TableFormRowData.prototype.clearRowDataMap = function() {
  this.getRowDataMap().clear();
  return this;
};


goog.object.extend(exports, proto.wilqo.api.mortgage_dynamic_data);
