// source: Wilqo_API_Questionnaire_Queries.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb = require('./Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb.js');
goog.object.extend(proto, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb);
var Wilqo_Shared_Messaging_WilqoMessaging_pb = require('./Wilqo.Shared.Messaging/WilqoMessaging_pb.js');
goog.object.extend(proto, Wilqo_Shared_Messaging_WilqoMessaging_pb);
var Wilqo_Shared_Models_ActivityModels_pb = require('./Wilqo.Shared.Models/ActivityModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_ActivityModels_pb);
var Wilqo_API_Questionnaire_Entities_pb = require('./Wilqo_API_Questionnaire_Entities_pb.js');
goog.object.extend(proto, Wilqo_API_Questionnaire_Entities_pb);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetLoanPageQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetLoanPageQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest', null, global);
goog.exportSymbol('proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.displayName = 'proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.displayName = 'proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.displayName = 'proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.displayName = 'proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.displayName = 'proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.displayName = 'proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.displayName = 'proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.displayName = 'proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetLoanPageQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.displayName = 'proto.wilqo.api.questionnaire.GetLoanPageQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.questionnaire.GetLoanPageQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.displayName = 'proto.wilqo.api.questionnaire.GetLoanPageQueryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanOfficerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest;
  return proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOfficerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanOfficerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loan_officer_id = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.getLoanOfficerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryRequest.prototype.setLoanOfficerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    questionnaire: (f = msg.getQuestionnaire()) && Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence.toObject(includeInstance, f),
    questionnaireRunId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    token: jspb.Message.getFieldWithDefault(msg, 6, ""),
    loanOfficerId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse;
  return proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence;
      reader.readMessage(value,Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionnaireRunId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOfficerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaireRunId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLoanOfficerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QuestionnairePanelSequence questionnaire = 3;
 * @return {?proto.wilqo.api.questionnaire.QuestionnairePanelSequence}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.getQuestionnaire = function() {
  return /** @type{?proto.wilqo.api.questionnaire.QuestionnairePanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence, 3));
};


/**
 * @param {?proto.wilqo.api.questionnaire.QuestionnairePanelSequence|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string questionnaire_run_id = 5;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.getQuestionnaireRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.setQuestionnaireRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string token = 6;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string loan_officer_id = 7;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.getLoanOfficerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireQueryResponse.prototype.setLoanOfficerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    runId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    loanOfficerId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest;
  return proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanOfficerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLoanOfficerId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string run_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_domain_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string loan_officer_id = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.getLoanOfficerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryRequest.prototype.setLoanOfficerId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    questionnaire: (f = msg.getQuestionnaire()) && Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence.toObject(includeInstance, f),
    questionnaireRunId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    token: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse;
  return proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence;
      reader.readMessage(value,Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence.deserializeBinaryFromReader);
      msg.setQuestionnaire(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionnaireRunId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaire();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnaireRunId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional QuestionnairePanelSequence questionnaire = 3;
 * @return {?proto.wilqo.api.questionnaire.QuestionnairePanelSequence}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.getQuestionnaire = function() {
  return /** @type{?proto.wilqo.api.questionnaire.QuestionnairePanelSequence} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Questionnaire_Entities_pb.QuestionnairePanelSequence, 3));
};


/**
 * @param {?proto.wilqo.api.questionnaire.QuestionnairePanelSequence|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.setQuestionnaire = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.clearQuestionnaire = function() {
  return this.setQuestionnaire(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.hasQuestionnaire = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string questionnaire_run_id = 5;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.getQuestionnaireRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.setQuestionnaireRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string token = 6;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnairePanelSequenceQueryResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest;
  return proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    questionnairesList: jspb.Message.toObjectList(msg.getQuestionnairesList(),
    Wilqo_API_Questionnaire_Entities_pb.AvailableQuestionnaireSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse;
  return proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Questionnaire_Entities_pb.AvailableQuestionnaireSummary;
      reader.readMessage(value,Wilqo_API_Questionnaire_Entities_pb.AvailableQuestionnaireSummary.deserializeBinaryFromReader);
      msg.addQuestionnaires(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getQuestionnairesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      Wilqo_API_Questionnaire_Entities_pb.AvailableQuestionnaireSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AvailableQuestionnaireSummary questionnaires = 2;
 * @return {!Array<!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary>}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.getQuestionnairesList = function() {
  return /** @type{!Array<!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Questionnaire_Entities_pb.AvailableQuestionnaireSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary>} value
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.setQuestionnairesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary}
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.addQuestionnaires = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.questionnaire.AvailableQuestionnaireSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetAvailableQuestionnairesQueryResponse.prototype.clearQuestionnairesList = function() {
  return this.setQuestionnairesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    questionnaireRunId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loanId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest;
  return proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionnaireRunId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getQuestionnaireRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoanId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string questionnaire_run_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.getQuestionnaireRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.setQuestionnaireRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string loan_id = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.getLoanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.setLoanId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string business_process_domain_id = 4;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse;
  return proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 4:
      var value = new Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse;
      reader.readMessage(value,Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 version = 1;
 * @return {number}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional wilqo.messaging.WilqoError error = 2;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 2));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated wilqo.shared.models.PanelElementResponse responses = 4;
 * @return {!Array<!proto.wilqo.shared.models.PanelElementResponse>}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.models.PanelElementResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_Shared_Models_ActivityModels_pb.PanelElementResponse, 4));
};


/**
 * @param {!Array<!proto.wilqo.shared.models.PanelElementResponse>} value
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.shared.models.PanelElementResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.models.PanelElementResponse}
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.shared.models.PanelElementResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetQuestionnaireResponsesQueryResponse.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopeToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetLoanPageQueryRequest;
  return proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScopeToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopeToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page_id = 2;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.getPageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.setPageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string scope_token = 3;
 * @return {string}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.getScopeToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryRequest} returns this
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryRequest.prototype.setScopeToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: (f = msg.getError()) && Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.toObject(includeInstance, f),
    page: (f = msg.getPage()) && Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.questionnaire.GetLoanPageQueryResponse;
  return proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError;
      reader.readMessage(value,Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 2:
      var value = new Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage;
      reader.readMessage(value,Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.messaging.WilqoError error = 1;
 * @return {?proto.wilqo.messaging.WilqoError}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.getError = function() {
  return /** @type{?proto.wilqo.messaging.WilqoError} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Messaging_WilqoMessaging_pb.WilqoError, 1));
};


/**
 * @param {?proto.wilqo.messaging.WilqoError|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional wilqo.api.mortgage_dynamic_data.LoanPage page = 2;
 * @return {?proto.wilqo.api.mortgage_dynamic_data.LoanPage}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.getPage = function() {
  return /** @type{?proto.wilqo.api.mortgage_dynamic_data.LoanPage} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_Mortgage_DynamicData_LoanPage_LoanPage_pb.LoanPage, 2));
};


/**
 * @param {?proto.wilqo.api.mortgage_dynamic_data.LoanPage|undefined} value
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} returns this
*/
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.questionnaire.GetLoanPageQueryResponse} returns this
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.questionnaire.GetLoanPageQueryResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.wilqo.api.questionnaire);
