import { useEffect, useMemo } from 'react';

import type { FieldGroupingSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { WidgetCollapsedState } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { CardListItem } from '@/Components/Atoms/Card/CardListItem';
import { Typography } from '@/Components/Atoms/Typography';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { mapCardListItemFieldToPanelElement } from '@/Utils/Helpers/widgetHelpers';

import { ExpandStatusTypeEnum, useWidgetContext } from './WidgetContext';

interface LoanPageWidgetExtended extends LoanPageWidget.AsObject {
  allowPrompt?: boolean;
  ignoreContext?: boolean;
  fieldGrouping?: FieldGroupingSettings.AsObject;
}

export const CardListItemWidget = (props: LoanPageWidgetExtended) => {
  const { allowPrompt, cardListItem, fieldGrouping, ignoreContext } = props;
  const { setFieldGrouping } = useDynamicForm();

  const {
    addEditingWidget,
    context,
    editAllWidgets,
    editingWidgetIds,
    expandStatus,
    pageId,
    removeEditingWidget,
    savingWidgetIds,
    scopeToken,
  } = useWidgetContext();

  const isEditing = useMemo(() => editingWidgetIds?.some((widgetId) => widgetId === props.id)
  || editAllWidgets, [editAllWidgets, editingWidgetIds, props.id]);

  useEffect(() => {
    if (isEditing && fieldGrouping) setFieldGrouping(fieldGrouping);
  }, [fieldGrouping, isEditing, setFieldGrouping]);

  // hide actions when on a dialog like URLA
  const hideActions = useMemo(
    () => (!ignoreContext && context !== 'page') || !cardListItem?.editable,
    [ignoreContext, context, cardListItem?.editable],
  );
  const { hiddenFieldIds } = useDynamicForm();

  // disable actions when is editing other items in page context
  const disableActions = useMemo(() => context === 'page' && editingWidgetIds.length > 0 && !isEditing, [context, editingWidgetIds, isEditing]);
  const isSaving = useMemo(() => savingWidgetIds?.some((widgetId) => widgetId === props.id), [savingWidgetIds, props.id]);

  const listItems = useMemo(() => cardListItem?.fieldsList.map((i) => mapCardListItemFieldToPanelElement(
    i,
    hiddenFieldIds,
    fieldGrouping?.googleAddress?.addressLineFieldId === i.id,
  )), [cardListItem?.fieldsList, fieldGrouping?.googleAddress?.addressLineFieldId, hiddenFieldIds]);

  const handleIsEditing = (isEditing: boolean) => {
    if (isEditing) addEditingWidget(props.id);
    else removeEditingWidget(props.id);
  };

  const numberOfColumns = cardListItem?.numberOfColumns ?? 0 > 1 ? cardListItem?.numberOfColumns : 3;

  const subtitle = useMemo(() => {
    if (cardListItem?.summary) {
      return cardListItem.summary.linesList.map((line) => {
        const fullLine = line.fieldsList.map((field) => renderValue(field.value)).join(line.delimiter);
        return (
          <Typography className="text-on-surface-inactive" variant="body2">
            {fullLine}
          </Typography>
        );
      });
    }
    return cardListItem?.helpText;
  }, [cardListItem]);

  return (
    <CardListItem
      additionalSubmitInfo={JSON.stringify({ pageId, scopeToken, type: 'cardListItem', widgetId: props.id, widgets: [props] })}
      allowPrompt={allowPrompt}
      defaultOpen={cardListItem?.collapsedState === WidgetCollapsedState.WIDGET_COLLAPSED_STATE_EXPANDED}
      disableActions={disableActions}
      displayOnly
      editByDefault={isEditing}
      handleIsEditing={handleIsEditing}
      headerProps={{
        open: context === 'dialog' || expandStatus !== ExpandStatusTypeEnum.COLLAPSE_ALL,
        subtitle,
        title: cardListItem?.label || '',
      }}
      hideActions={hideActions}
      hideRead={cardListItem?.displaySummaryOnRead}
      isSaving={isSaving}
      listItems={listItems || []}
      numberOfColumns={numberOfColumns}
      variant="border"
      widgetId={props.id}
    />
  );
};
