import { useMemo } from 'react';

import type { TabsWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import { Tabs as TabComponent } from '@/Components/Atoms/Tab';
import { useSearchParams } from '@/Routes/NavigationContext';

import { WidgetRenderer } from '../WidgetRenderer';

interface TabsSettings {
  tabsWidgetSettings: TabsWidgetSettings.AsObject;
  allowPrompt?: boolean;
  className?: string;
}

export const TabsWidget = (props: TabsSettings) => {
  const { tabsList } = props.tabsWidgetSettings;

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTab = useMemo(() => {
    const searchTabId = searchParams?.get('tabId');
    if (!searchTabId) { return tabsList[0]; }
    return tabsList.find((x) => x.id === searchTabId) || tabsList[0];
  }, [searchParams, tabsList]);

  const tabsData = tabsList.map((tab) => ({
    active: selectedTab.id === tab.id,
    label: tab.label,
    linkTo: '',
    onClick: () => {
      if (setSearchParams && searchParams) setSearchParams({ ...Object.fromEntries([...searchParams]), tabId: tab.id });
    },
  }));

  return (
    <div>
      <TabComponent
        className="mb-6"
        stretch={tabsData.length <= 2}
        tabsData={tabsData}
      />
      <WidgetRenderer allowPrompt={props.allowPrompt} className={props.className} widgetList={selectedTab.widgetsList} />
    </div>
  );
};
