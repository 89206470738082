import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';
import { useParams } from 'react-router';

import { GetLoanFeeDetailQueryRequest, GetLoanFeeDetailQueryResponse } from '@/API/Models/Wilqo_API_Mortgage_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

export const FEE_DETAILS_MESSAGE_NAME = 'wilqo.api.mortgage.GetLoanFeeDetailQueryRequest';

export type Column = {
  id: string;
  header: string;
  hidden?: boolean;
};

const useLoanFees = () => {
  const { commandResponse } = useBackend();
  const { loanId = '' } = useParams();

  return useQuery([FEE_DETAILS_MESSAGE_NAME, loanId], async () => {
    const request = new GetLoanFeeDetailQueryRequest();
    request.setLoanId('');
    request.setDealId(loanId);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: FEE_DETAILS_MESSAGE_NAME,
    });
    const response = new GetLoanFeeDetailQueryResponse();
    GetLoanFeeDetailQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();

    return endpointResponseMiddleware(
      FEE_DETAILS_MESSAGE_NAME,
      responseObj.error,
      {
        ...responseObj,
      },
    );
  }, {
    cacheTime: 0,
  });
};

export { useLoanFees };
