import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';

import Input, { MaskType } from '@/Components/Atoms/Input/Input.component';
import { useWindow } from '@/Utils/Helpers/useWindow';

import type { AnimatedIconInputProps } from '../AnimatedIconInput';
import { AnimatedIconInput } from '../AnimatedIconInput';
import type { IBadgeProps } from '../Badge';
import { Badge } from '../Badge';
import type { IButtonProps } from '../Button';
import { Button } from '../Button';
import { Icon } from '../Icon';
import type { MenuProps } from '../Menu';
import { Menu } from '../Menu';
import { Typography } from '../Typography';
import type { CardHeaderFilterProps } from './CardFilters';
import { CardFilters } from './CardFilters';
import type { ContextualCardHeaderProps } from './ContextualCardHeader';
import { ContextualCardHeader } from './ContextualCardHeader';

export interface CardButton extends Omit<IButtonProps, 'onClick'> {
  onClick?: (close: () => void, refetch?: any) => void;
  animation?: string;
  hidden?: boolean;
  key?: string;
  children?: CardButton[];
}

export interface CardHeaderProps {
  title: string;
  subtitle?: ReactNode | string;
  open: boolean;
  showTitleAction?: boolean;
  actions?: CardButton[] | React.ReactNode;
  caption?: string;
  captionStrong?: string;
  searchProps?: AnimatedIconInputProps;
  filtersProps?: Array<CardHeaderFilterProps> | CardHeaderFilterProps;
  badge?: IBadgeProps;
  close: () => void;
  contextualHeaderProps?: ContextualCardHeaderProps;
  disableAccordion: boolean;
  showContextual?: boolean;
  headerActionStyle?: 'default' | 'dropdown';
  dropdownAction?: () => void;
  onBlurHandler?: () => void;
  animation?: string;
  headerIcon?: {
    icon: string;
    onClick: () => void;
  };
  kebabActions?: MenuProps;
  showDangerStyle?: boolean;
  multipleCaptions?: Array<{ caption: string;captionStrong: string; edit: boolean;onBlurHandler?: (value: string) => void;value?: number }>;
}

const ACTION_WIDTH = 120;

const CardHeader = (props: CardHeaderProps) => {
  const {
    subtitle,
    title,
    actions = [],
    caption = '',
    captionStrong = '',
    headerIcon,
    searchProps,
    badge,
    filtersProps,
    disableAccordion,
    open,
    close,
    showTitleAction = false,
    headerActionStyle = 'default',
    dropdownAction,
    contextualHeaderProps,
    kebabActions,
    showContextual = false,
    onBlurHandler,
    showDangerStyle,
    multipleCaptions = [],
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [currentWidth, setCurrentWidth] = useState<number>(0);
  const [contextualControl, setContextualControl] = useState(showContextual);
  const { isMobile } = useWindow();

  const showMobile = (titleRef.current?.offsetWidth ?? 0) > currentWidth - ACTION_WIDTH;

  useEffect(() => setContextualControl(showContextual), [showContextual]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setCurrentWidth(ref.current?.getBoundingClientRect().width || 0);
    };

    if (currentWidth === 0) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [currentWidth]);

  const handleActionClick = (action: CardButton) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (action.onClick) action.onClick(() => { });
  };

  const handleCloseContextual = () => {
    if (contextualHeaderProps?.onClickClose) contextualHeaderProps.onClickClose();
    setContextualControl(false);
  };

  if (contextualControl && contextualHeaderProps) {
    return <ContextualCardHeader {...contextualHeaderProps} onClickClose={handleCloseContextual} />;
  }

  const renderActions = () => {
    if (!Array.isArray(actions)) return actions;
    const [firstAction, secondAction, ...moreActions] = actions;
    if (headerActionStyle === 'dropdown') {
      const copyActions = [...actions];
      return (
        <Menu
          closeAfterSelecting
          options={copyActions.map((action) => ({
            animation: action.animation,
            hidden: action.hidden,
            icon: action.leftIcon,
            key: action.key,
            label: action.label,
            onClick: handleActionClick(action),
          }))}
          triggerItem={(
            <Button
              label="Add"
              onBlurHandler={onBlurHandler}
              onClick={() => {
                if (dropdownAction) dropdownAction();
              }}
              rightIcon="ArrowDropDown"
              variant="tertiary"
            />
          )}
        />
      );
    }
    if (actions.length > 0) {
      return (
        <div className="gap-2 flex flex-1 flex-row flex-wrap items-center max-w-[160px] tablet:max-w-full">
          {firstAction && (
            <Button className="flex-grow" {...firstAction} onClick={handleActionClick(firstAction)} variant="tertiary" />
          )}
          {secondAction && (
            <Button className="flex-grow" {...secondAction} onClick={handleActionClick(secondAction)} />
          )}
          {moreActions && moreActions.length === 1 && (
            <Button className="flex-grow" {...moreActions[0]} onClick={handleActionClick(moreActions[0])} />
          )}
          {moreActions && moreActions.length > 1 && (
            <Menu
              className="flex-grow"
              options={moreActions.map((action) => ({ label: action.label, onClick: handleActionClick(action) }))}
              triggerItem={<Button className="w-full" label="More" rightIcon="ArrowDropDown" />}
            />
          )}
        </div>
      );
    }
    return null;
  };

  const renderCaption = () => (
    <>
      {caption && (
        <Typography
          className={clsx({ '!text-danger': showDangerStyle, 'mr-1': Boolean(captionStrong), 'mr-[10px]': Boolean(!captionStrong), 'text-status-on-status': showDangerStyle !== undefined && !showDangerStyle })}
          variant="caption"
        >
          {caption}
        </Typography>
      )}
      {captionStrong && <Typography className={clsx({ '!text-danger': showDangerStyle, 'text-status-on-status': showDangerStyle !== undefined && !showDangerStyle }, 'mr-[10px]')} variant="captionBold">{captionStrong}</Typography>}
    </>
  );
  const renderMultipleCaptions = (caption: string, captionStrong: string, edit: boolean, onBlurHandler: (value: string) => void, value?: number) => (
    <div key={caption} className="flex items-center justify-between">
      {caption && (
        <Typography
          className={clsx({ '!text-danger': showDangerStyle, 'mr-1': Boolean(captionStrong), 'mr-[10px]': Boolean(!captionStrong), 'text-status-on-status': showDangerStyle !== undefined && !showDangerStyle })}
          variant="caption"
        >
          {caption}
        </Typography>
      )}
      {captionStrong && (
        edit ? (
          <Input
            className="mr-[10px]"
            id="test"
            mask={MaskType.CURRENCY}
            onBlur={(e) => onBlurHandler(e.target.value)}
            value={value?.toString() || ''}
          />
        ) : (
          <Typography
            className={clsx({ '!text-danger': showDangerStyle, 'text-status-on-status': showDangerStyle !== undefined && !showDangerStyle }, 'mr-[10px]')}
            variant="captionBold"
          >
            {captionStrong}
          </Typography>
        )
      )}
    </div>
  );

  const renderFilterContainer = () => {
    if (filtersProps || (Array.isArray(actions) && actions.length && showMobile)) {
      return (
        <div className="w-full mt-[10px] flex flex-col gap-3">
          {filtersProps && !Array.isArray(filtersProps) && <CardFilters {...filtersProps} />}
          {filtersProps && Array.isArray(filtersProps) && (
            <div className="flex flex-row gap-3 overflow-x-auto tablet:overflow-x-visible">
              {filtersProps.map((filter) => <CardFilters key={filter.noItemsLabel} {...filter} />)}
            </div>
          )}
          <div className={clsx({ flex: showMobile, hidden: !showMobile })}>
            {renderActions()}
          </div>
        </div>
      );
    }

    return null;
  };

  const renderCaptionBadge = () => {
    if (badge) return <Badge className="mr-[10px]" {...badge} />;
    if (!showMobile && multipleCaptions.length > 0) {
      return (
        <div>
          {multipleCaptions.map((item) => renderMultipleCaptions(item.caption, item.captionStrong, item.edit, item.onBlurHandler || (() => {}), item.value))}
        </div>
      );
    }
    if (caption && !showMobile && multipleCaptions.length <= 0) return renderCaption();

    return null;
  };

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') return <Markdown>{subtitle}</Markdown>;
    return subtitle;
  };

  return (
    <div ref={ref} className="w-full flex bg-surface p-4 pr-2 justify-center flex-col">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="flex-1 flex flex-col flex-wrap gap-x-2">
          <div className={clsx('flex flex-row items-center gap-2 justify-between tablet:justify-start', { 'mb-1': Boolean(subtitle) })}>
            <div className="flex flex-ror items-center">
              <Typography
                ref={titleRef}
                className={clsx({ 'text-on-surface-active': open, 'text-on-surface-inactive': !open })}
                variant={isMobile ? 'body1Bold' : 'display6'}
              >
                {title}
              </Typography>
              {showTitleAction && (
              <Button
                label="Edit"
                onClick={() => {
                  if (dropdownAction) dropdownAction();
                }}
                variant="tertiary"
              />
              )}
              <div className={clsx({ flex: showMobile || badge, hidden: !showMobile && !badge })}>
                {renderCaption()}
              </div>
            </div>
            <div className={clsx({ flex: open, hidden: !open })}>
              {renderActions()}
            </div>
          </div>
          {subtitle && (
            <Typography variant="body2">
              {renderSubtitle()}
            </Typography>
          )}
        </div>
        <div className="flex flex-row items-center">
          {renderCaptionBadge()}
          {searchProps && <div><AnimatedIconInput className="ml-[10px]" {...searchProps} /></div>}
          {!disableAccordion && <Icon icon={open ? 'ExpandLess' : 'ExpandMore'} onClick={close} variant="interactive" />}
          {kebabActions && <Menu {...kebabActions} />}
          {headerIcon && <Icon icon={headerIcon.icon} onClick={headerIcon.onClick} variant="interactive" />}

        </div>
      </div>
      <div className={clsx({ flex: !showMobile || open, hidden: showMobile || !open })}>
        {renderFilterContainer()}
      </div>
    </div>
  );
};

export { CardHeader };
