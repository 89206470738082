import { padEnd } from "lodash";

export const normalizeString = (value: string) => value.toLocaleLowerCase().trim();

/**
 * Method to allow clean a string from non-printable UTF-8 chars, replacing it, and later join with a comma
 * @param {string} input the string with non-printable UTF-8 characters
 * @returns {string} a string replacing all the non-printable characters
 */
export function cleanString(input: string) {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) > 31) {
      output += input.charAt(i);
    } else {
      output += ' ';
    }
  }

  return output.trim().replace(/\s{2,}/g, ', ');
}

const base64Regex = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/gi;
/**
 * Method to validate if a string is possible a base64 encoded
 * @param {string} input A string that could be base64 enconded or not
 * @returns {boolean} true if is a posible base64 encoded string
 */
export function isBase64String(input: string) {
  base64Regex.lastIndex = 0;
  return input.length > 4 && base64Regex.test(input);
}

/**
 * Method to format a regular string to a phoneNumber
 * @param {string} value the string number to format
 * @returns {string} the number format as (###) ### - ####
 */
export function formatPhone(value?: string) {
  let number = (value || '').replace(/[^\d]/g, '');
  if (number === '') return '--';
  return number.replace(
    /^(\d{1,3})(\d{0,3})(\d{0,4})$/,
    (group, p1, p2, p3) => `(${padEnd(p1, 3, '#')}) ${padEnd(p2, 3, '#')} - ${padEnd(p3, 4, '#')}`,
  );
}

export function capitalizeFirstAndLowerRest(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}