import type currency from 'currency.js';

import type {
  LoanEscrowFee,
  LoanFee,
  LoanFeeCategory,
  LoanPrepaidFee,
} from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { FeePercentBasisEnum, LoanFeeCategoryTypeEnum } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import type { Column } from '@/API/Queries/mortgage/useLoanFees';
import type { TotalMet } from '@/Routes/Pages/loan/PurposeBuilt/Fees/FeesContext';
import type { TableContent } from '@/Routes/Pages/loan/PurposeBuilt/Fees/helpers/individualFeesHelper';
import { cleanAndConvertCurrency, numberFormatter } from '@/Utils/Helpers/numberFormatter';
import {
  ConvertNumberToProtoDecimal,
  ConvertProtoDecimalAsObjectToNumber,
} from '@/Utils/Helpers/protoDecimalConversion';

const FEES_ERROR_MSG = 'Amount entered exceeds the allowed total';

const TOTAL_CAN_BE_EDITED = [
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_UNKNOWN,
  FeePercentBasisEnum.FEE_PERCENT_BASIS_ENUM_VARIABLE,
];

export const PAYERS = [
  'Borrower',
  'Seller',
  'Lender',
  'Other',
];

export const ESCROWS_PREPAIDS_COLUMNS: Column[] = [
  { header: 'Fee name', id: 'name' },
  { header: 'Amount / TimeFrame', id: 'amount' },
  { header: 'Borrower paid', id: 'borrowerPaid' },
  { header: 'Seller paid', id: 'sellerPaid' },
  { header: 'Lender paid', id: 'lenderPaid' },
  { header: 'Total', id: 'total' },
  { header: 'Data source', id: 'dataSource' },
];

export const ESCROWS_PREPAIDS_COLUMNS_EDIT: Column[] = [
  { header: 'Fee name', id: 'name' },
  { header: 'Amount / TimeFrame', id: 'amount' },
  { header: 'Borrower paid', id: 'borrowerPaid' },
  { header: 'Seller paid', id: 'sellerPaid' },
  { header: 'Lender paid', id: 'lenderPaid' },
  { header: 'Total', id: 'total' },
];

export const FEES_COLUMNS: Column[] = [
  { header: 'Fee name', id: 'name' },
  { header: 'Fixed + % Basis', id: 'basis' },
  { header: 'Borrower paid', id: 'borrowerPaid' },
  { header: 'Seller paid', id: 'sellerPaid' },
  { header: 'Lender paid', id: 'lenderPaid' },
  { header: 'Paid by Others', id: 'otherPaid' },
  { header: 'Total', id: 'total' },
];

export const INDIVIDUAL_FEES_COLUMNS: Column[] = [
  { header: 'payer', id: 'payer' },
  { header: 'total amount paid', id: 'totalAmount' },
  { header: 'financed', id: 'financedAmount' },
  { header: 'seller/lender Credit', id: 'creditAmount' },
  { header: 'paid outside of closing', id: 'outsideOfClosingAmount' },
  { header: 'paid at closing', id: 'atClosingTotalAmount' },
];

export interface FeeState {
  updateBorrower: boolean;
  lenderPaid: currency | number;
  otherPaid: currency | undefined;
  sellerPaid: currency;
  borrowerPaid: currency;
  newValue: currency | undefined;
  total: currency;
  [key: string]: any;
}

export enum ColumnEnum {
  LENDER_PAID = 'lenderPaid',
  OTHER_PAID = 'otherPaid',
  SELLER_PAID = 'sellerPaid',
  BORROWER_PAID = 'borrowerPaid',
  TOTAL = 'total',
  TOTAL_AMOUNT = 'totalAmount',
  CREDIT_AMOUNT = 'creditAmount',
  FINANCED_AMOUNT = 'financedAmount',
  BASIS = 'basis',
  AMOUNT = 'amount',
  NAME = 'name',
  OUTSIDE_OF_CLOSING_AMOUNTS = 'outsideOfClosingAmount',
}

export const adjustAtClosing = (fee: any, editedRow: any, key: any) => {
  const total = cleanAndConvertCurrency(fee[key][`paidBy${editedRow.payer}TotalAmount`])
    .subtract(cleanAndConvertCurrency(fee[key][`paidBy${editedRow.payer}OutsideOfClosingAmount`]))
    .subtract(cleanAndConvertCurrency(fee[key][`paidBy${editedRow.payer}FinancedAmount`]));
  fee[key][`paidBy${editedRow.payer}AtClosingTotalAmount`] = ConvertNumberToProtoDecimal(Number(total)).toObject();
};

export const emptyValue = () => numberFormatter.format(0.00);

export const convertToCamelCase = (inputString: string): string => {
  const words = inputString.split('_');
  const capitalizedWords = words.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()));
  return capitalizedWords.join('').slice(0, -4);
};

export const mapPaymentChanges = (value: string, column: string, editedRow: TableContent, fee: any, category: any) => {
  const { payer } = editedRow;
  let paymentType = '';

  switch (column) {
    case ColumnEnum.FINANCED_AMOUNT:
      paymentType = 'FinancedAmount';
      break;
    case ColumnEnum.OUTSIDE_OF_CLOSING_AMOUNTS:
      paymentType = 'OutsideOfClosingAmount';
      break;
    case ColumnEnum.CREDIT_AMOUNT:
      paymentType = 'CreditAmount';
      break;
    default:
      return;
  }
  const paymentKey = `paidBy${payer}${paymentType}`;
  let paymentPortion = '';
  if (category === 0) {
    fee.feePayment[paymentKey] = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
    paymentPortion = 'feePayment';
  } else if (category === 1) {
    fee.escrowPayment[paymentKey] = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
    paymentPortion = 'escrowPayment';
  } else if (category === 2) {
    fee.prepaidPayment[paymentKey] = ConvertNumberToProtoDecimal(Number(value.replace(/[^0-9.-]+/g, ''))).toObject();
    paymentPortion = 'prepaidPayment';
  }
  adjustAtClosing(
    fee,
    editedRow,
    paymentPortion,
  );
};

export const updateLoanFees = (fee: any, category: LoanFeeCategory.AsObject, action: 'add' | 'remove') => {
  const updatedFeesList = action === 'add'
    ? [...category.feesList, fee]
    : category.feesList.filter((existingFee: any) => existingFee.name !== fee.name);

  const updatedSumTotal = action === 'add'
    ? cleanAndConvertCurrency(category.sumTotal).add(cleanAndConvertCurrency(fee.total))
    : cleanAndConvertCurrency(category.sumTotal).subtract(cleanAndConvertCurrency(fee.total));

  return {
    category: category.category,
    categoryType: category.categoryType,
    feesList: updatedFeesList,
    sumTotal: ConvertNumberToProtoDecimal(Number(updatedSumTotal)).toObject(),
  };
};

export function canTotalBeEditedOnFeeType(basedOn: FeePercentBasisEnum | undefined) {
  // If basedOn is not set, we will assume unknown.
  return basedOn == null || TOTAL_CAN_BE_EDITED.indexOf(basedOn) >= 0;
}

export const updatePaymentPortions = (value: any, updatedFee: any, column: string, categoryType: LoanFeeCategoryTypeEnum) => {
  const paymentTypes: Record<string, string> = {
    borrowerPaid: 'Borrower',
    lenderPaid: 'Lender',
    otherPaid: 'Other',
    sellerPaid: 'Seller',
  };
  const totalAmountKey = `paidBy${paymentTypes[column]}TotalAmount`;
  const sanitizedNumber = Number(value.replace(/[^0-9.-]+/g, ''));
  const protoDecimalValue = ConvertNumberToProtoDecimal(sanitizedNumber).toObject();

  const paymentTypeMap = {
    [LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_FEE]: 'feePayment',
    [LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_ESCROW]: 'escrowPayment',
    [LoanFeeCategoryTypeEnum.LOAN_FEE_CATEGORY_TYPE_ENUM_PREPAID]: 'prepaidPayment',
  };

  const paymentType = paymentTypeMap[categoryType];
  if (paymentType) {
    // updates total amount portion of the payment
    updatedFee[paymentType][totalAmountKey] = protoDecimalValue;
    // Clear out seller / lender credit if the user changes the paid amount
    const payerType = paymentTypes[column];
    const isSeller = payerType === 'Seller';
    const isLender = payerType === 'Lender';
    const sanitizedUpdatedFee = Number(cleanAndConvertCurrency(isSeller ? updatedFee.sellerPaid : updatedFee.lenderPaid));

    if ((isSeller || isLender) && sanitizedNumber !== sanitizedUpdatedFee) {
      const creditTypeKey = isSeller ? 'paidBySellerCreditAmount' : 'paidByLenderCreditAmount';
      updatedFee[paymentType][creditTypeKey] = ConvertNumberToProtoDecimal(0).toObject();
    }
  }
};

export function getTotalAmount(column: string, updatedFee: LoanEscrowFee.AsObject | LoanFee.AsObject | LoanPrepaidFee.AsObject) {
  if (column === ColumnEnum.BORROWER_PAID) {
    // if borrower paid is being changed get the total of the all payers except seller, so that the balance can be set to seller
    return Number(cleanAndConvertCurrency(updatedFee.lenderPaid))
      + Number(cleanAndConvertCurrency(updatedFee.borrowerPaid))
      + Number(cleanAndConvertCurrency(updatedFee.sellerPaid))
      + ('otherPaid' in updatedFee ? Number(cleanAndConvertCurrency(updatedFee.otherPaid)) : 0);
  }

  // if seller paid is being changed get the total of the all payers except borrower, so that the balance can be set to borrower
  return Number(cleanAndConvertCurrency(updatedFee.lenderPaid))
    + Number(cleanAndConvertCurrency(updatedFee.sellerPaid))
    + ('otherPaid' in updatedFee ? Number(cleanAndConvertCurrency(updatedFee.otherPaid)) : 0);
}

export function borrowerCalculations(column: any, updatedFees: any, updatedFee: any, setIsDirty: any, setTotalMet: any, manageErrors: (newErrorID: string, section: LoanFeeCategoryTypeEnum, remove: boolean) => void,
  category: LoanFeeCategoryTypeEnum) {
  if (Number(updatedFees.borrowerPaid) === updatedFees.total.value) {
    updatedFee.sellerPaid = ConvertNumberToProtoDecimal(Number(0)).toObject();
    updatedFee.lenderPaid = ConvertNumberToProtoDecimal(Number(0)).toObject();
    updatedFee.otherPaid = ConvertNumberToProtoDecimal(Number(0)).toObject();
    setIsDirty(false);
    updatedFees.updateBorrower = false;
    setTotalMet(() => ({
      category,
      column,
      exceeded: false,
      feeId: updatedFee.feeId,
      message: FEES_ERROR_MSG,
    }));
    // remove error from array
    manageErrors(updatedFee.feeId, category, true);
  } else if (Math.abs(Number(updatedFees.borrowerPaid)) > Math.abs(updatedFees.total.value)) {
    setTotalMet(() => ({
      category,
      column,
      exceeded: true,
      feeId: updatedFee.feeId,
      message: FEES_ERROR_MSG,
    }));
    updatedFees.updateBorrower = false;
    // push error to array
    manageErrors(updatedFee.feeId, category, false);
  } else if (Math.abs(Number(updatedFees.borrowerPaid)) < Math.abs(updatedFees.total.value)
    && (Math.abs(getTotalAmount(column, updatedFee)) < Math.abs(updatedFees.total.value) || Math.abs(getTotalAmount(column, updatedFee)) > Math.abs(updatedFees.total.value))
  ) {
    setTotalMet(() => ({
      category,
      column,
      exceeded: true,
      feeId: updatedFee.feeId,
      message: FEES_ERROR_MSG,
    }));
    // push error to array
    manageErrors(updatedFee.feeId, category, false);
  }
}

export function calculateNonSpecialFees(
  updatedFees: FeeState,
  column: string,
  updatedFee: any,
  setIsDirty: any,
  setTotalMet: any,
  manageErrors: (newErrorID: string, section: LoanFeeCategoryTypeEnum, remove: boolean) => void,
  categoryType: LoanFeeCategoryTypeEnum,
) {
  if (column === ColumnEnum.BORROWER_PAID) {
    borrowerCalculations(column, updatedFees, updatedFee, setIsDirty, setTotalMet, manageErrors, categoryType);
  }

  if (column !== ColumnEnum.BORROWER_PAID) {
    if (updatedFees.newValue && Math.abs(updatedFees.newValue.value) > Math.abs(updatedFees.total.value)) {
      setTotalMet(() => ({
        category: categoryType,
        column,
        exceeded: true,
        feeId: updatedFee.feeId,
        message: FEES_ERROR_MSG,
      }));
      setIsDirty(true);
      updatedFees.updateBorrower = false;
      // push error to array
      manageErrors(updatedFee.feeId, categoryType, false);
    } else {
      const payers = ['lenderPaid', 'sellerPaid', 'otherPaid'];
      setTotalMet((value: any) => {
        if (value.column !== 'creditAmount') {
          return {
            category: categoryType,
            column,
            exceeded: !((payers.includes(value.column) && payers.includes(column)) || value),
            feeId: updatedFee.feeId,
            message: FEES_ERROR_MSG,
          };
        }
        return value;
      });
      // remove error from array
      manageErrors(updatedFee.feeId, categoryType, true);
    }

    if (column !== ColumnEnum.BORROWER_PAID) {
      if (Math.abs(Number(getTotalAmount(column, updatedFee))) <= Math.abs(Number(updatedFees.total.value))) {
        updatedFees.borrowerPaid = updatedFees.total.subtract(updatedFees.lenderPaid)
          .subtract(updatedFees.otherPaid ? updatedFees.otherPaid : 0)
          .subtract(updatedFees.sellerPaid ? updatedFees.sellerPaid : 0);
        updatedFee.borrowerPaid = ConvertNumberToProtoDecimal(Number(updatedFees.borrowerPaid)).toObject();
        if (categoryType === 0) {
          updatedFee.feePayment.paidByBorrowerTotalAmount = updatedFee.borrowerPaid;
        } else if (categoryType === 1) {
          updatedFee.escrowPayment.paidByBorrowerTotalAmount = updatedFee.borrowerPaid;
        } else if (categoryType === 2) {
          updatedFee.prepaidPayment.paidByBorrowerTotalAmount = updatedFee.borrowerPaid;
        }
        setIsDirty(false);
        updatedFees.updateBorrower = true;
        // remove error from array
        manageErrors(updatedFee.feeId, categoryType, true);
      } else {
        setIsDirty(true);
        updatedFees.updateBorrower = false;
        setTotalMet(() => ({
          category: categoryType,
          column,
          exceeded: true,
          feeId: updatedFee.feeId,
          message: FEES_ERROR_MSG,
        }));
        // push error to array
        manageErrors(updatedFee.feeId, categoryType, false);
      }
    }
  }
  let paymentPortion = '';
  if (categoryType === 0) {
    paymentPortion = 'feePayment';
  } else if (categoryType === 1) {
    paymentPortion = 'escrowPayment';
  } else if (categoryType === 2) {
    paymentPortion = 'prepaidPayment';
  }
  if (Math.abs(ConvertProtoDecimalAsObjectToNumber(updatedFee.total))
    < Math.abs(ConvertProtoDecimalAsObjectToNumber(updatedFee[paymentPortion].paidByBorrowerFinancedAmount))) {
    setTotalMet(() => ({
      category: categoryType,
      column: 'financedAmount',
      exceeded: true,
      feeId: updatedFee.feeId,
      message: 'FEES_ERROR_MSG',
    }));
  } else {
    setTotalMet(() => ({
      category: categoryType,
      column: 'financedAmount',
      exceeded: false,
      feeId: updatedFee.feeId,
      message: '',
    }));
  }
  if (Math.abs(ConvertProtoDecimalAsObjectToNumber(updatedFee[paymentPortion].paidBySellerCreditAmount))
    > Math.abs(ConvertProtoDecimalAsObjectToNumber(updatedFee.sellerPaid))) {
    setTotalMet(() => ({
      category: categoryType,
      column: 'creditAmount',
      exceeded: true,
      feeId: updatedFee.feeId,
      message: FEES_ERROR_MSG,
    }));
  } else {
    setTotalMet(() => ({
      category: categoryType,
      column: 'creditAmount',
      exceeded: false,
      feeId: updatedFee.feeId,
      message: '',
    }));
  }

  if (Math.abs(ConvertProtoDecimalAsObjectToNumber(updatedFee[paymentPortion].paidByBorrowerTotalAmount))
    < Math.abs(ConvertProtoDecimalAsObjectToNumber(updatedFee[paymentPortion].paidByBorrowerOutsideOfClosingAmount))) {
    setTotalMet(() => ({
      category: categoryType,
      column: 'outsideOfClosingAmount',
      exceeded: true,
      feeId: updatedFee.feeId,
      message: FEES_ERROR_MSG,
    }));
  } else {
    setTotalMet((value: TotalMet) => {
      if (value.column === column) {
        return {
          category: categoryType,
          column: 'outsideOfClosingAmount',
          exceeded: false,
          feeId: updatedFee.feeId,
          message: '',
        };
      }
      return value;
    });
  }
}
