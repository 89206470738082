import { useMemo, useState } from 'react';

import type { Panel } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { FormProgress } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { ButtonGroup } from '@/Components/Features/PanelElement/ButtonGroup';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';

import type { IButtonProps } from './Button';
import { Button } from './Button';
import type { IconProps } from './Icon';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogLayout } from './RadixDialog';
import { Typography } from './Typography';

export interface FullPageStepperProps {
  handleSubmit: (data: any) => void;
  panels: Panel.AsObject[];
  saving?: boolean;
  progress?: FormProgress;
  openModal: boolean;
  title: string;
  close: () => void;
  headerAction?: Array<IButtonProps> | IButtonProps;
  subHeader?: React.ReactNode;
  defaultAction?: IconProps;
  isLoading?: boolean;
  renderFooter?: () => React.ReactNode;
}

export const FullPageStepper = (props: FullPageStepperProps) => {
  const { handleSubmit, panels, progress, saving, ...DialogProps } = props;
  const { close, defaultAction, headerAction, openModal, title } = DialogProps;

  const [currentPanelIndex, setCurrentPanelIndex] = useState(0);
  const currentPanel = useMemo(() => panels[currentPanelIndex], [panels, currentPanelIndex]);

  const getActions = (hasError: boolean): IButtonProps[] => {
    const actions: IButtonProps[] = [];
    const button1: IButtonProps = {
      label: 'Back',
      onClick: () => setCurrentPanelIndex(currentPanelIndex - 1),
      variant: 'tertiary',
    };
    const isLastPanel = currentPanelIndex === panels.length - 1;
    const button2: IButtonProps = {
      disabled: hasError,
      isLoading: saving,
      label: isLastPanel ? 'Submit' : 'Next',
      onClick: isLastPanel ? undefined : () => setCurrentPanelIndex(currentPanelIndex + 1),
      type: 'submit',
    };

    if (currentPanelIndex !== 0) actions.push(button1);
    return [...actions, button2];
  };

  return (
    <Dialog
      onOpenChange={close}
      open={openModal}
    >
      <DialogLayout asChild>
        <DynamicFormProvider onSubmit={handleSubmit} progress={progress} shouldUnregister>
          {
            ({ hasError }) => {
              const actions = getActions(hasError);
              return (
                <>
                  <DialogHeader
                    actions={(
                      headerAction
                        ? <ButtonGroup buttons={Array.isArray(headerAction) ? headerAction : [headerAction]} />
                        : undefined
                    )}
                    icon={defaultAction?.icon}
                    iconAction={defaultAction?.onClick ? () => defaultAction?.onClick?.({}) : undefined}
                    title={title}
                  />

                  <DialogContent>
                    <div key={currentPanel.id} className="tablet:mt-12 p-4 tablet:w-[540px] w-full self-center flex-1 mx-auto">
                      {currentPanel.headerText && <Typography variant="display4">{currentPanel.headerText}</Typography>}
                      {currentPanel.summaryText && <Typography className="mt-6" variant="body1">{currentPanel.summaryText}</Typography>}
                      <div className="flex flex-col gap-4 mt-10">
                        {
                          currentPanel.panelElementsList.map((panelElement) => (
                            <PanelElementRenderer key={panelElement.id} variant="default" {...panelElement} />
                          ))
                        }
                      </div>
                    </div>
                  </DialogContent>

                  <DialogFooter borderTop span={actions.length > 1}>
                    {actions.map((btn) => <Button {...btn} />)}
                  </DialogFooter>
                </>
              );
            }
          }
        </DynamicFormProvider>
      </DialogLayout>
    </Dialog>
  );
};
