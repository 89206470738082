import { useMutation } from '@tanstack/react-query';

import {
  PublishBusinessProcessTemplateDesignCommandRequest,
  PublishBusinessProcessTemplateDesignCommandResponse,
} from '@/API/Models/Wilqo.API.BusinessProcess/Wilqo_API_BusinessProcess_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useWilqoMessage } from '../useWilqoMessage';

const MESSAGE_NAME = 'wilqo.api.business_process.PublishBusinessProcessTemplateDesignCommandRequest';

export const usePublishBusinessProcessTemplate = () => {
  const sendMessage = useWilqoMessage();
  const bpdId = useBPDId();

  return useMutation(async (templateId: string) => {
    const request = new PublishBusinessProcessTemplateDesignCommandRequest();
    request.setBusinessProcessDomainId(bpdId);
    request.setBusinessProcessTemplateId(templateId);
    return sendMessage<PublishBusinessProcessTemplateDesignCommandResponse.AsObject>(
      {
        msg: request,
        msgName: MESSAGE_NAME,
      },
      PublishBusinessProcessTemplateDesignCommandResponse,
    );
  });
};
