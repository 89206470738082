// source: Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Admin.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var Wilqo_Shared_Models_Timestamp_pb = require('../Wilqo.Shared.Models/Timestamp_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Timestamp_pb);
var Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb = require('../Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Models_pb.js');
goog.object.extend(proto, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb);
goog.exportSymbol('proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate', null, global);
goog.exportSymbol('proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.displayName = 'proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.displayName = 'proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    businessProcessTemplateId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    entityVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    loanFeeTemplate: (f = msg.getLoanFeeTemplate()) && Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LoanFeeTemplate.toObject(includeInstance, f),
    lastPublish: (f = msg.getLastPublish()) && Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.toObject(includeInstance, f),
    triggerRules: (f = msg.getTriggerRules()) && proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.toObject(includeInstance, f),
    lastSaved: (f = msg.getLastSaved()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSavedBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastSavedByDisplayName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    title: jspb.Message.getFieldWithDefault(msg, 10, ""),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate;
  return proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessTemplateId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityVersion(value);
      break;
    case 4:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LoanFeeTemplate;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LoanFeeTemplate.deserializeBinaryFromReader);
      msg.setLoanFeeTemplate(value);
      break;
    case 5:
      var value = new Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp;
      reader.readMessage(value,Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.deserializeBinaryFromReader);
      msg.setLastPublish(value);
      break;
    case 12:
      var value = new proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary;
      reader.readMessage(value,proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.deserializeBinaryFromReader);
      msg.setTriggerRules(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSaved(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSavedBy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastSavedByDisplayName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBusinessProcessTemplateId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEntityVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getLoanFeeTemplate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LoanFeeTemplate.serializeBinaryToWriter
    );
  }
  f = message.getLastPublish();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp.serializeBinaryToWriter
    );
  }
  f = message.getTriggerRules();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.serializeBinaryToWriter
    );
  }
  f = message.getLastSaved();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSavedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastSavedByDisplayName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string entity_id = 1;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getEntityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string business_process_template_id = 11;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getBusinessProcessTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setBusinessProcessTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 entity_version = 3;
 * @return {number}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getEntityVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setEntityVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional LoanFeeTemplate loan_fee_template = 4;
 * @return {?proto.wilqo.api.mortgage_config.LoanFeeTemplate}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getLoanFeeTemplate = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.LoanFeeTemplate} */ (
    jspb.Message.getWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.LoanFeeTemplate, 4));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.LoanFeeTemplate|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
*/
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setLoanFeeTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.clearLoanFeeTemplate = function() {
  return this.setLoanFeeTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.hasLoanFeeTemplate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional wilqo.shared.models.WilqoTimestamp last_publish = 5;
 * @return {?proto.wilqo.shared.models.WilqoTimestamp}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getLastPublish = function() {
  return /** @type{?proto.wilqo.shared.models.WilqoTimestamp} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Timestamp_pb.WilqoTimestamp, 5));
};


/**
 * @param {?proto.wilqo.shared.models.WilqoTimestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
*/
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setLastPublish = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.clearLastPublish = function() {
  return this.setLastPublish(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.hasLastPublish = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FeeParametrizedRuleAggregatorSummary trigger_rules = 12;
 * @return {?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getTriggerRules = function() {
  return /** @type{?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary, 12));
};


/**
 * @param {?proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
*/
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setTriggerRules = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.clearTriggerRules = function() {
  return this.setTriggerRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.hasTriggerRules = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp last_saved = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getLastSaved = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
*/
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setLastSaved = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.clearLastSaved = function() {
  return this.setLastSaved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.hasLastSaved = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string last_saved_by = 8;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getLastSavedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setLastSavedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string last_saved_by_display_name = 9;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getLastSavedByDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setLastSavedByDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string title = 10;
 * @return {string}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool archived = 13;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate} returns this
 */
proto.wilqo.api.mortgage_config.AdminLoanFeeTemplate.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.FeeParametrizedRuleGroupSummary.toObject, includeInstance),
    applyAndBetweenGroups: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary;
  return proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.FeeParametrizedRuleGroupSummary;
      reader.readMessage(value,Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.FeeParametrizedRuleGroupSummary.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.FeeParametrizedRuleGroupSummary.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenGroups();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated FeeParametrizedRuleGroupSummary groups = 1;
 * @return {!Array<!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary>}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_MortgageConfig_Wilqo_API_MortgageConfig_Models_pb.FeeParametrizedRuleGroupSummary, 1));
};


/**
 * @param {!Array<!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary>} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} returns this
*/
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.api.mortgage_config.FeeParametrizedRuleGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional bool apply_and_between_groups = 2;
 * @return {boolean}
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.getApplyAndBetweenGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary} returns this
 */
proto.wilqo.api.mortgage_config.FeeParametrizedRuleAggregatorSummary.prototype.setApplyAndBetweenGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.wilqo.api.mortgage_config);
