import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { FullPageSaveItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DealWriteCommandResponse, FullPageSaveCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';

interface UpdateObject {
  dealId: string;
  dealVersion: number;
  pageId: string;
  itemsList: FullPageSaveItem[];
  createNew: boolean;
}

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.FullPageSaveCommandRequest';
export const dynamicPageSaveMutationKey = [MESSAGE_NAME];

const useDynamicPageSave = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const PutSectionWidgetDataCommand = async (updateObject: UpdateObject) => {
    const request = new FullPageSaveCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setDealVersion(updateObject.dealVersion);
    request.setBusinessProcessDomainId(bpdId);
    request.setPageId(updateObject.pageId || '');
    request.setItemsList(updateObject.itemsList);
    request.setCreateNew(updateObject.createNew);
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new DealWriteCommandResponse();
    DealWriteCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    /// NOTE: Not using middleware here because api can return error and good data at the same time
    return response.toObject();
  };
  return useMutation({
    mutationFn: PutSectionWidgetDataCommand,
    mutationKey: dynamicPageSaveMutationKey,
  });
};

export { useDynamicPageSave };
