import clsx from 'clsx';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useNavigate } from '@/Routes/NavigationContext';

export type LogoSizeVariant = 'lg' | 'md';
export type LogoVariant = 'default' | 'transparent';

export interface ILogoProps {
  logoRedirect?: string;
  className?: string;
  external?: boolean;
  redirectUrl?: string;
  imageUrl?: string;
  size?: LogoSizeVariant;
  variant?: LogoVariant;
}

const Logo = (props: ILogoProps) => {
  const {
    className,
    external = false,
    imageUrl,
    redirectUrl,
    size = 'md',
    variant = 'default',
  } = props;

  const navigate = useNavigate();

  const logoURL = useMemo(() => {
    if (variant === 'transparent') return '/mortgage/api/configuration/logoDark';
    if (imageUrl) return imageUrl;
    return '/mortgage/api/configuration/logo';
  }, [imageUrl, variant]);

  const renderLogo = () => (
    <div
      className={clsx({
        'h-10 w-[128px]': size === 'md',
        'w-[320px]': size === 'lg',
      }, className)}
    >
      <svg
        data-src={logoURL}
        data-testid="logo"
        height="100%"
        width={size === 'lg' ? '100%' : undefined}
      />
    </div>
  );

  if (!external && redirectUrl) {
    return (
      <Link to={redirectUrl}>
        {renderLogo()}
      </Link>
    );
  }

  const handleExternalClick = () => {
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      navigate('/home');
    }
  };

  return (
    <div
      onClick={handleExternalClick}
      onKeyDown={handleExternalClick}
      role="button"
      tabIndex={0}
    >
      {renderLogo()}
    </div>
  );
};

export { Logo };
