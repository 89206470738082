import { useParams } from 'react-router';

import { useActivityChecklist } from '@/API/Queries/activities/useActivityChecklist';

import { IncompleteList } from '../../incompleteList';
import { useChecklistProgress } from '../checklistProgressContext';

const ChecklistIncompleteTab = () => {
  const { checklistProgress } = useChecklistProgress();
  const { activityId = '' } = useParams();
  const { data: activity, isLoading } = useActivityChecklist(activityId);

  return (
    <IncompleteList
      checklistProgress={checklistProgress}
      isLoading={isLoading}
      items={activity?.checklist?.itemsList || []}
      type="checklist"
    />
  );
};

export { ChecklistIncompleteTab };
