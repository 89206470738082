import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { LoanFeeTemplateCreateCommandRequest, LoanFeeTemplateCreateCommandResponse } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Commands_pb';
import type { DefaultFeePaymentTimingTypeEnum, FeeEnum } from '@/API/Models/Wilqo.API.MortgageConfig/Wilqo_API_MortgageConfig_Reflections_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.mortgage_config.LoanFeeTemplateCreateCommandRequest';

interface CreateObject {
  defaultFeePaymentTimingType: DefaultFeePaymentTimingTypeEnum;
  description: string;
  name: string;
  type: FeeEnum;
}

const useCreateLoanFeeTemplate = () => {
  const { commandResponse } = useBackend();
  const auth = useAuth();

  const createLoanFeeTemplateCommand = async (createObject: CreateObject) => {
    const {
      defaultFeePaymentTimingType,
      description,
      name,
      type,
    } = createObject;
    const request = new LoanFeeTemplateCreateCommandRequest();
    request.setBusinessProcessDomainId(auth.secureAuthValues.authorizedBusinessProcessDomainIds[0]);
    request.setDefaultFeePaymentTimingType(defaultFeePaymentTimingType);
    request.setDescription(description);
    request.setName(name);
    request.setType(type);
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new LoanFeeTemplateCreateCommandResponse();
    LoanFeeTemplateCreateCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();

    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj);
  };

  return useMutation(createLoanFeeTemplateCommand);
};

export { useCreateLoanFeeTemplate };
