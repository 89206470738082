import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import type { SaveFieldItem } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';
import { DealWriteCommandResponse, SaveFieldSectionWidgetDataCommandRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Commands_pb';

import { useBackend } from '../useBackend';

interface UpdateObject {
  dealId: string;
  dealVersion: number;
  businessProcessDomainId: string;
  pageId: string;
  scopeToken: string;
  widgetId: string;
  itemsList: Array<SaveFieldItem>;
}

const MESSAGE_NAME = 'wilqo.api.mortgage_dynamic_data.SaveFieldSectionWidgetDataCommandRequest';

const usePutSectionWidgetData = () => {
  const { commandResponse } = useBackend();

  const PutSectionWidgetDataCommand = async (updateObject: UpdateObject) => {
    const request = new SaveFieldSectionWidgetDataCommandRequest();
    request.setDealId(updateObject.dealId);
    request.setDealVersion(updateObject.dealVersion);
    request.setBusinessProcessDomainId(updateObject.businessProcessDomainId || '');
    request.setPageId(updateObject.pageId || '');
    request.setScopeToken(updateObject.scopeToken || '');
    request.setWidgetId(updateObject.widgetId || '');
    request.setItemsList(updateObject.itemsList);
    const msg = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(msg);
    const response = new DealWriteCommandResponse();
    DealWriteCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    /// NOTE: Not using middleware here because api can return error and good data at the same time
    return response.toObject();
  };
  return useMutation(PutSectionWidgetDataCommand);
};

export { usePutSectionWidgetData };
