import { useMemo } from 'react';

import { usePageConfigurations } from '@/API/Queries/brand/usePageConfigurations';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';

interface TableDataItem {
  id: string;
  title: string;
}

const PageConfigurationList = () => {
  const { data: pageConfigList, isLoading } = usePageConfigurations();

  const columns = useMemo((): Array<Column<TableDataItem>> => [
    {
      header: 'Name',
      id: 'name',
      key: {
        text: 'title',
        type: 'text',
      },
    },
  ], []);

  const data = useMemo(() => pageConfigList?.map((config) => ({
    id: config.id,
    title: config.displayName,
  })), [pageConfigList]);

  return (
    <Table
      cardProps={{ headerProps: { title: 'Page Configurations' } }}
      columns={columns}
      data={data || []}
      isLoading={isLoading}
    />
  );
};

export { PageConfigurationList };
