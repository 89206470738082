// source: Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_AdminModels.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_Shared_Models_DynamicData_pb = require('../Wilqo.Shared.Models/DynamicData_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_DynamicData_pb);
var Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb = require('../Wilqo.Shared.Models/Wilqo_Shared_Models_DynamicData_AdminModels_pb.js');
goog.object.extend(proto, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb);
var Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb = require('../Wilqo.Shared.RulesEngine.Models/Wilqo_Shared_RulesEngine_Models_pb.js');
goog.object.extend(proto, Wilqo_Shared_RulesEngine_Models_Wilqo_Shared_RulesEngine_Models_pb);
goog.exportSymbol('proto.wilqo.shared.rulesengine.DataTypeSummary', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.OperatorSummary', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleAggregatorSummary', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleGroupSummary', null, global);
goog.exportSymbol('proto.wilqo.shared.rulesengine.RuleSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.DataTypeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.DataTypeSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.DataTypeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.DataTypeSummary.displayName = 'proto.wilqo.shared.rulesengine.DataTypeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.OperatorSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.OperatorSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.OperatorSummary.displayName = 'proto.wilqo.shared.rulesengine.OperatorSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.RuleSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.RuleSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.RuleSummary.displayName = 'proto.wilqo.shared.rulesengine.RuleSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.RuleGroupSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.RuleGroupSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.RuleGroupSummary.displayName = 'proto.wilqo.shared.rulesengine.RuleGroupSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.shared.rulesengine.RuleAggregatorSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.shared.rulesengine.RuleAggregatorSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.shared.rulesengine.RuleAggregatorSummary.displayName = 'proto.wilqo.shared.rulesengine.RuleAggregatorSummary';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.DataTypeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.DataTypeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    operatorsList: jspb.Message.toObjectList(msg.getOperatorsList(),
    proto.wilqo.shared.rulesengine.OperatorSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.DataTypeSummary}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.DataTypeSummary;
  return proto.wilqo.shared.rulesengine.DataTypeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.DataTypeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.DataTypeSummary}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (reader.readEnum());
      msg.setDataType(value);
      break;
    case 2:
      var value = new proto.wilqo.shared.rulesengine.OperatorSummary;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.OperatorSummary.deserializeBinaryFromReader);
      msg.addOperators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.DataTypeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.DataTypeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOperatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.shared.rulesengine.OperatorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional wilqo.shared.models.DataTypeEnum data_type = 1;
 * @return {!proto.wilqo.shared.models.DataTypeEnum}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.getDataType = function() {
  return /** @type {!proto.wilqo.shared.models.DataTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.models.DataTypeEnum} value
 * @return {!proto.wilqo.shared.rulesengine.DataTypeSummary} returns this
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.setDataType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated OperatorSummary operators = 2;
 * @return {!Array<!proto.wilqo.shared.rulesengine.OperatorSummary>}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.getOperatorsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.OperatorSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.OperatorSummary, 2));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.OperatorSummary>} value
 * @return {!proto.wilqo.shared.rulesengine.DataTypeSummary} returns this
*/
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.setOperatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.OperatorSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary}
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.addOperators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.shared.rulesengine.OperatorSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.DataTypeSummary} returns this
 */
proto.wilqo.shared.rulesengine.DataTypeSummary.prototype.clearOperatorsList = function() {
  return this.setOperatorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.OperatorSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.OperatorSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.OperatorSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comparableValueRequired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    panelElementTypeOverride: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.OperatorSummary;
  return proto.wilqo.shared.rulesengine.OperatorSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.OperatorSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComparableValueRequired(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPanelElementTypeOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.OperatorSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.OperatorSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.OperatorSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComparableValueRequired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPanelElementTypeOverride();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional OperatorEnum id = 1;
 * @return {!proto.wilqo.shared.rulesengine.OperatorEnum}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.getId = function() {
  return /** @type {!proto.wilqo.shared.rulesengine.OperatorEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.shared.rulesengine.OperatorEnum} value
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary} returns this
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary} returns this
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool comparable_value_required = 3;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.getComparableValueRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary} returns this
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.setComparableValueRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string panel_element_type_override = 4;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.getPanelElementTypeOverride = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.OperatorSummary} returns this
 */
proto.wilqo.shared.rulesengine.OperatorSummary.prototype.setPanelElementTypeOverride = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.RuleSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.RuleSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataField: (f = msg.getDataField()) && Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary.toObject(includeInstance, f),
    operator: (f = msg.getOperator()) && proto.wilqo.shared.rulesengine.OperatorSummary.toObject(includeInstance, f),
    comparableValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fieldLibraryOptionId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary}
 */
proto.wilqo.shared.rulesengine.RuleSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.RuleSummary;
  return proto.wilqo.shared.rulesengine.RuleSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.RuleSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary}
 */
proto.wilqo.shared.rulesengine.RuleSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary;
      reader.readMessage(value,Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary.deserializeBinaryFromReader);
      msg.setDataField(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.OperatorSummary;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.OperatorSummary.deserializeBinaryFromReader);
      msg.setOperator(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComparableValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldLibraryOptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.RuleSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.RuleSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataField();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary.serializeBinaryToWriter
    );
  }
  f = message.getOperator();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.OperatorSummary.serializeBinaryToWriter
    );
  }
  f = message.getComparableValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFieldLibraryOptionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional wilqo.shared.models.DataFieldTemplateSummary data_field = 2;
 * @return {?proto.wilqo.shared.models.DataFieldTemplateSummary}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.getDataField = function() {
  return /** @type{?proto.wilqo.shared.models.DataFieldTemplateSummary} */ (
    jspb.Message.getWrapperField(this, Wilqo_Shared_Models_Wilqo_Shared_Models_DynamicData_AdminModels_pb.DataFieldTemplateSummary, 2));
};


/**
 * @param {?proto.wilqo.shared.models.DataFieldTemplateSummary|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
*/
proto.wilqo.shared.rulesengine.RuleSummary.prototype.setDataField = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.clearDataField = function() {
  return this.setDataField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.hasDataField = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OperatorSummary operator = 3;
 * @return {?proto.wilqo.shared.rulesengine.OperatorSummary}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.getOperator = function() {
  return /** @type{?proto.wilqo.shared.rulesengine.OperatorSummary} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.shared.rulesengine.OperatorSummary, 3));
};


/**
 * @param {?proto.wilqo.shared.rulesengine.OperatorSummary|undefined} value
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
*/
proto.wilqo.shared.rulesengine.RuleSummary.prototype.setOperator = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.clearOperator = function() {
  return this.setOperator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.hasOperator = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string comparable_value = 4;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.getComparableValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.setComparableValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string field_library_option_id = 5;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.getFieldLibraryOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleSummary.prototype.setFieldLibraryOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.RuleGroupSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.wilqo.shared.rulesengine.RuleSummary.toObject, includeInstance),
    applyAndBetweenRules: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.RuleGroupSummary;
  return proto.wilqo.shared.rulesengine.RuleGroupSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 3:
      var value = new proto.wilqo.shared.rulesengine.RuleSummary;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.RuleSummary.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.RuleGroupSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.shared.rulesengine.RuleSummary.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenRules();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool read_only = 2;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated RuleSummary rules = 3;
 * @return {!Array<!proto.wilqo.shared.rulesengine.RuleSummary>}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.RuleSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.RuleSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.RuleSummary>} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary} returns this
*/
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.RuleSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.RuleSummary}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.shared.rulesengine.RuleSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * optional bool apply_and_between_rules = 4;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.getApplyAndBetweenRules = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleGroupSummary.prototype.setApplyAndBetweenRules = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.shared.rulesengine.RuleAggregatorSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.wilqo.shared.rulesengine.RuleGroupSummary.toObject, includeInstance),
    applyAndBetweenGroups: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.shared.rulesengine.RuleAggregatorSummary;
  return proto.wilqo.shared.rulesengine.RuleAggregatorSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.wilqo.shared.rulesengine.RuleGroupSummary;
      reader.readMessage(value,proto.wilqo.shared.rulesengine.RuleGroupSummary.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApplyAndBetweenGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.shared.rulesengine.RuleAggregatorSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.wilqo.shared.rulesengine.RuleGroupSummary.serializeBinaryToWriter
    );
  }
  f = message.getApplyAndBetweenGroups();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated RuleGroupSummary groups = 1;
 * @return {!Array<!proto.wilqo.shared.rulesengine.RuleGroupSummary>}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.shared.rulesengine.RuleGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.shared.rulesengine.RuleGroupSummary, 1));
};


/**
 * @param {!Array<!proto.wilqo.shared.rulesengine.RuleGroupSummary>} value
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary} returns this
*/
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.wilqo.shared.rulesengine.RuleGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.shared.rulesengine.RuleGroupSummary}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.wilqo.shared.rulesengine.RuleGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional bool apply_and_between_groups = 2;
 * @return {boolean}
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.getApplyAndBetweenGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.shared.rulesengine.RuleAggregatorSummary} returns this
 */
proto.wilqo.shared.rulesengine.RuleAggregatorSummary.prototype.setApplyAndBetweenGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.wilqo.shared.rulesengine);
