import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import type { LeadSource } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useUpdateUserLeadSource } from '@/API/Queries/user/useUpdateUserLeadSource';
import { DOMAIN_USER_LEAD_SOURCE, useUserLeadSources } from '@/API/Queries/user/useUserLeadSources';
import { Toggle } from '@/Components/Atoms/Toggle';
import type { Column } from '@/Components/Features/table';
import { Table } from '@/Components/Features/table';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

interface LeadSourceItem extends LeadSource.AsObject {
  referralSource: string;
}

const LeadSources = () => {
  const { id = '' } = useParams();
  const queryClient = useQueryClient();
  const { showSnackBar } = useShared();
  const { data: leadSources, isLoading } = useUserLeadSources(id);
  const { mutate: updateUserLeadSource } = useUpdateUserLeadSource(id);

  const handleUpdateUserLeadSource = useCallback(
    (leadSource: LeadSource.AsObject) => {
      leadSource.active = !leadSource.active;
      updateUserLeadSource(leadSource, {
        onError: () => {
          showSnackBar({ message: 'There was a problem updating the lead source status', open: true });
        },
        onSuccess: () => {
          queryClient.invalidateQueries([DOMAIN_USER_LEAD_SOURCE, id]);
        },
      });
    },
    [id, queryClient, showSnackBar, updateUserLeadSource],
  );

  const columns = useMemo((): Array<Column<LeadSourceItem>> => [{
    header: '',
    id: 'active',
    key: {
      children: 'active',
      type: 'generic',
    },
  }, {
    header: 'Lead Source',
    id: 'name',
    key: {
      text: 'name',
      type: 'text',
    },
  }, {
    header: 'Referal Sources',
    id: 'referralSource',
    key: {
      text: 'referralSource',
      type: 'text',
    },
  }], []);

  const data = useMemo(() => leadSources?.map((leadSource) => {
    const referralSources = leadSource.referralSourcesList;
    let referralSource = referralSources.length > 0 ? referralSources[0].name : '';

    if (referralSources.length > 1) {
      referralSource += ` + ${referralSources.length - 1}`;
    }

    return {
      active: (
        <Toggle onClick={() => handleUpdateUserLeadSource(leadSource)} value={leadSource.active} />
      ),
      name: leadSource.name,
      referralSource,
    };
  }), [handleUpdateUserLeadSource, leadSources]);

  return (
    <Table
      cardProps={{ headerProps: { title: 'Assigned lead sources' } }}
      columns={columns}
      data={data || []}
      isLoading={isLoading}
    />
  );
};

export { LeadSources };
