import type { ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';

interface OpenChildren {
  title: string;
  children: React.ReactNode;
}

interface State {
  openChildren?: OpenChildren;
  setOpenChildren: (children: OpenChildren | undefined) => void;
}

const state: State = {
  setOpenChildren: () => {},
};

const MenuContext = createContext(state);
MenuContext.displayName = 'MenuContext';

export const MenuProvider = (props: { children: ReactNode }) => {
  const [openChildren, setOpenChildren] = useState<OpenChildren>();

  return (
    <MenuContext.Provider value={{ openChildren, setOpenChildren }}>
      {props.children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
