import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { CreateSkillsetCommandRequest, SkillsetCommandResponse } from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.CreateSkillsetCommandRequest';

interface UpdateObject {
  displayName: string;
  longDescription: string;
  skillsetGroupIds: string[];
}

export const useCreateSkillset = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const CreateSkillsetCommand = async (updateObject: UpdateObject) => {
    const { displayName, longDescription, skillsetGroupIds } = updateObject;
    const request = new CreateSkillsetCommandRequest();
    request.setDisplayName(displayName);
    request.setLongDescription(longDescription);
    request.setSkillsetGroupIdsList(skillsetGroupIds);
    request.setBusinessProcessDomainId(bpdId);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new SkillsetCommandResponse();
    SkillsetCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(CreateSkillsetCommand);
};
