import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { isArray } from 'lodash';
import { useMemo, useState } from 'react';

import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';

import { Button } from '../Button';
import { Chip } from '../Chip';
import { Icon } from '../Icon';
import type { TextFieldVariant } from '../TextField';
import { TextField } from '../TextField';
import { Toggle } from '../Toggle';
import { Typography } from '../Typography';

export interface ComponentProps extends PanelElement.AsObject {
  selected?: Array<PanelElementOption.AsObject>;
  title?: string;
  onSelect: (item: any) => void;
  onSelectAll: () => void;
  onClear: () => void;
  onApply?: (close: VoidFunction) => void;
  trigger?: 'chip' | 'text-field';
  variant?: TextFieldVariant;
}

const MultiSelect = ({
  trigger = 'chip',
  ...props
}: ComponentProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { optionsList, selected } = props;

  const handleApply = () => {
    props.onApply?.(() => setOpen(false));
  };

  const textFieldValue = useMemo(() => {
    const selectedOptions = selected?.map((option) => option?.headerText);
    if (selectedOptions?.length === 1) return selectedOptions[0];
    if (selectedOptions && selectedOptions?.length > 1) {
      return `${selectedOptions[0]} +${selectedOptions?.length - 1}`;
    }
    return '';
  }, [selected]);

  const renderOptionLabel = (option: PanelElementOption.AsObject) => {
    if (option.path) return `${option.headerText} - ${option.path}`;
    return option.headerText;
  };

  return (
    <DropdownMenu.Root onOpenChange={setOpen} open={open}>
      <DropdownMenu.Trigger className="w-full">
        {trigger === 'chip' ? (
          <div className="w-min">
            <Chip
              label={`${props.headerText}`}
              rightIcon="ArrowDropDown"
            />
          </div>
        ) : (
          <div>
            <TextField
              disabled={props.disabled}
              placeholder={`${props.headerText}${props.requirement?.required ? '*' : ''}`}
              trailingIcon={{ icon: 'ArrowDropDown' }}
              value={textFieldValue}
              variant={props.variant}
            />
          </div>
        )}
      </DropdownMenu.Trigger>

      <DropdownMenu.Content
        align="start"
        className="shadow-two bg-surface z-50 py-2 min-w-4 w-72"
      >
        <div className="flex flex-row justify-between py-3 px-4 items-center">
          <Typography variant="body1Bold">{props.title || 'Select all'}</Typography>
          <Toggle
            onClick={props.onSelectAll}
            value={props.selected?.length === optionsList.length}
          />
        </div>
        {optionsList.map((option) => {
          const isSelected = isArray(selected) ? selected?.some((v) => v?.value?.value === option?.value?.value) : false;

          return (
            <DropdownMenu.Item
              key={`key-${option.headerText}-${option?.id}`}
              className="flex flex-row items-center py-3 px-4"
              onClick={(event) => {
                event.preventDefault();
                props.onSelect(option);
              }}
            >
              <div className="flex-1 flex flex-row items-center">
                <Icon
                  className={clsx('mr-[20px]', {
                    'text-on-surface-inactive': option.disabled,
                  })}
                  icon={option.disabled || isSelected ? 'CheckBox' : 'CheckBoxBlank'}
                  selected={isSelected}
                  size="md"
                />
                <Typography variant="body2">
                  {renderOptionLabel(option)}
                </Typography>
              </div>
            </DropdownMenu.Item>
          );
        })}
        <div className="flex flex-row justify-end pt-2 gap-1 border-t border-t-on-surface-stroke">
          <Button label="Clear" onClick={props.onClear} variant="tertiary" />
          <Button label="Apply" onClick={handleApply} variant="tertiary" />
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default MultiSelect;
