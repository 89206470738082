import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import {
  UpdateUserLeadSourceCommandRequest,
  UpdateUserLeadSourceCommandResponse,
} from '@/API/Models/Wilqo_API_Users_Commands_pb';
import { LeadSource, ReferralSource } from '@/API/Models/Wilqo_API_Users_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.users.UpdateUserLeadSourceCommandRequest';

const useUpdateUserLeadSource = (userId: string) => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();

  const updateUserLeadSource = async (updateObject: LeadSource.AsObject) => {
    const request = new UpdateUserLeadSourceCommandRequest();
    const {
      active,
      id,
      name,
      referralSourcesList,
    } = updateObject;
    const leadSource = new LeadSource();
    leadSource.setActive(active);
    leadSource.setName(name);
    leadSource.setId(id);

    leadSource.setReferralSourcesList(
      referralSourcesList.map((rs) => {
        const referralSource = new ReferralSource();
        referralSource.setActive(rs.active);
        referralSource.setId(rs.id);
        referralSource.setName(rs.name);
        return referralSource;
      }),
    );

    request.setBusinessProcessDomainId(bpdId);
    request.setUserId(userId);
    request.setLeadSource(leadSource);
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };
    const responseMsg = await commandResponse(message);
    const response = new UpdateUserLeadSourceCommandResponse();
    UpdateUserLeadSourceCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error);
  };

  return useMutation(updateUserLeadSource);
};

export { useUpdateUserLeadSource };
