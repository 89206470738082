import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';

export const MenuTrigger = ({ hidden, ...props }: DropdownMenu.DropdownMenuTriggerProps) => (
  <DropdownMenu.Trigger
    className={clsx({
      flex: !hidden,
      'group-hover:opacity-100 opacity-0 hover:opacity-100 z-0': hidden,
    })}
    {...props}
    onClick={(event) => {
      event.stopPropagation();
    }}
  />
);
