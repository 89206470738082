import { useMutation } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { BusinessProcessExtraConfig } from '@/API/Models/Wilqo_API_Activity_Admin_pb';
import { ActivityDesignCommandResponse, EditActivityDesignNamesCommandRequest } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { ProgressType } from '@/API/Models/Wilqo_API_Activity_Models_pb';
import { useBPDId } from '@/Routes/Auth/AppAuthContext';
import { useActivityConfiguratorContext } from '@/Routes/Pages/admin/activityTemplates/configurator/activityConfiguratorContext';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.EditActivityDesignNamesCommandRequest';

interface UpdateObject {
  externalName: string;
  internalName: string;
  progressType?: ProgressType.AsObject;
  entityId?: string;
  allowConcurrence: boolean;
  allowMultipleInstances: boolean;
}

const useUpdateActivityDesign = () => {
  const { commandResponse } = useBackend();
  const bpdId = useBPDId();
  const { designerPanel } = useActivityConfiguratorContext();

  const UpdateActivityDesignCommand = async (updateObject: UpdateObject) => {
    const { allowConcurrence, allowMultipleInstances, entityId = '', externalName, internalName, progressType } = updateObject;
    const request = new EditActivityDesignNamesCommandRequest();
    request.setTitle(externalName);
    request.setActivityDesignId(designerPanel.entityId || entityId);
    request.setInternalName(internalName);
    request.setBusinessProcessDomainId(bpdId);
    const bpConfig = new BusinessProcessExtraConfig();
    bpConfig.setAllowConcurrence(allowConcurrence);
    bpConfig.setAllowMultipleInstances(allowMultipleInstances);
    request.setBusinessProcessConfig(bpConfig);
    if (progressType) {
      const requestProgressType = new ProgressType();
      requestProgressType.setName(progressType.name);
      requestProgressType.setConsumerDescription(progressType.consumerDescription);
      requestProgressType.setLenderDescription(progressType.lenderDescription);
      requestProgressType.setScreenOrder(progressType.screenOrder);
      request.setProgressType(requestProgressType);
    }
    const message = {
      msg: request,
      msgName: MESSAGE_NAME,
    };

    const responseMsg = await commandResponse(message);
    const response = new ActivityDesignCommandResponse();
    ActivityDesignCommandResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const obj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, obj.error, obj.activityDesign);
  };

  return useMutation(UpdateActivityDesignCommand);
};

export { useUpdateActivityDesign };
