import { useCallback, useEffect, useMemo } from 'react';
import useEvent from 'react-use-event-hook';

import type { LoanPageWidget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/Widget_pb';
import type { PanelElement, PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { DynamicDataElement } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { DynamicDataElementDataTypeEnum } from '@/API/Models/Wilqo.Shared.Models/DynamicData_pb';
import { Card } from '@/Components/Atoms/Card/Card';
import type { CardButton } from '@/Components/Atoms/Card/CardHeader';
import { Checkbox } from '@/Components/Atoms/Checkbox';
import { Input } from '@/Components/Atoms/Input';
import { ListItem } from '@/Components/Atoms/ListItem';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useDynamicForm } from '@/Components/Features/dynamicForm/DynamicFormContext';
import type { ExtendedConverter } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useDynamicFormRegister } from '@/Components/Features/dynamicForm/useDynamicFormRegister';
import { useBlocker } from '@/Routes/NavigationContext';
import { manageList } from '@/Utils/Helpers/arrayHelpers';
import { ddeAreEqual, DynamicDataElementMap, fromList, fromNumber, toList, toString } from '@/Utils/Helpers/dynamicDataElementHelper';
import { useDynamicLoanInfo } from '@/Utils/Hooks/useDynamicLoanInfo';

import type { SaveConfig } from '../useSaveWidget';
import { ExpandStatusTypeEnum, useWidgetContext } from '../WidgetContext';

const denialOtherReasonId = 'R60az21ZQZZecXK8ZREp2';
const denialReasonId = 'khExW1tp8xAZkaks_ecLD';

interface HMDAOption extends PanelElementOption.AsObject {
  options?: Array<PanelElement.AsObject | PanelElementOption.AsObject>;
}

export const HMDADenialReason = (props: LoanPageWidget.AsObject & { allowPrompt?: boolean }) => {
  const {
    addEditingWidget,
    context,
    editingWidgetIds,
    expandStatus,
    pageId = '',
    removeEditingWidget,
    savingWidgetIds,
    scopeToken = '',
  } = useWidgetContext();

  const isEditing = useMemo(() => editingWidgetIds.some((w) => w === props.id), [editingWidgetIds, props.id]);
  const isSaving = useMemo(() => savingWidgetIds.some((w) => w === props.id), [savingWidgetIds, props.id]);
  const { loanId } = useDynamicLoanInfo();
  const { resetProgress, values } = useDynamicForm();

  useEffect(() => {
    if (context === 'dialog' && !isEditing) {
      addEditingWidget(props.id);
    }
  }, [context, isEditing, props.id, addEditingWidget]);

  const denialReasonField = useMemo(
    () => props.cardListItem?.fieldsList.find((field) => field.id === denialReasonId),
    [props.cardListItem?.fieldsList],
  );
  const denialOtherReasonField = useMemo(
    () => props.cardListItem?.fieldsList.find((field) => field.id === denialOtherReasonId),
    [props.cardListItem?.fieldsList],
  );
  const maxCharsLeft = useMemo(() => 255 - (values[denialOtherReasonId]?.value?.length ?? 0), [values]);
  const denialReasonOptions = useMemo(
    (): Array<PanelElementOption.AsObject> => denialReasonField?.panelElement?.optionsList.filter(
      (e) => e?.headerText !== '--',
    ).sort(
      (option) => (option?.headerText.toLowerCase() === 'other' ? 1 : -1),
    ) || [],
    [denialReasonField?.panelElement?.optionsList],
  );

  const getConverter = useCallback((options: HMDAOption[]): ExtendedConverter<Array<PanelElementOption.AsObject>> => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fromDynamic: (dynamicList: DynamicDataElement) => {
      if (dynamicList.getDataType() === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) return [];
      return fromList(dynamicList).reduce((selectedOptions: Array<PanelElementOption.AsObject>, currentDDE) => {
        const selectedOptionFromDynamic = options.find((option) => (option.value ? ddeAreEqual(DynamicDataElementMap(option.value), currentDDE) : false));
        if (selectedOptionFromDynamic) {
          return selectedOptions.concat(selectedOptionFromDynamic);
        }
        return selectedOptions;
      }, []);
    },
    toDynamic: (v = []) => toList(v.map((e) => (e.value ? DynamicDataElementMap(e?.value) : fromNumber(0)))),
  }), []);

  const [denialReason = [], setDenialReason] = useDynamicFormRegister<Array<PanelElementOption.AsObject>>(denialReasonId, getConverter(denialReasonOptions));

  useEffect(() => {
    const values = props.cardListItem?.fieldsList.reduce((progress: DynamicDataElementValues, field) => {
      let fieldDynamicValue: DynamicDataElement | undefined = field.value?.value ? DynamicDataElementMap(field.value?.value) : undefined;
      if (field.value?.value && field.panelElement?.type === 'select' && field.value.value.dataType === DynamicDataElementDataTypeEnum.DYNAMIC_DATA_ELEMENT_DATA_TYPE_ENUM_NULL) {
        fieldDynamicValue = toList([]);
      }
      const value = field.panelElement?.type === 'text' && fieldDynamicValue ? toString(fieldDynamicValue) : undefined;
      return {
        ...progress,
        [field.id]: { dynamic: fieldDynamicValue, value },
      };
    }, {});
    if (values) resetProgress({ values });
  }, [resetProgress, isEditing, props.cardListItem?.fieldsList]);

  const disableActions = useMemo(() => context === 'page' && editingWidgetIds.length > 0 && !isEditing, [context, editingWidgetIds, isEditing]);

  const actions = useMemo((): CardButton[] => {
    if (context === 'dialog') return [];
    if (isEditing) {
      const additionalData: SaveConfig = {
        loanId,
        pageId,
        scopeToken,
        type: 'cardListItem',
        widgetId: props.id,
        widgets: [props],
      };
      return [
        { label: 'Cancel', onClick: () => removeEditingWidget(props.id), variant: 'tertiary' },
        { additionalData: JSON.stringify(additionalData), isLoading: isSaving, label: 'Save', type: 'submit' },
      ];
    }
    return [
      { disabled: disableActions, label: 'Edit', onClick: () => addEditingWidget(props.id), variant: 'tertiary' },
    ];
  }, [context, isEditing, disableActions, loanId, pageId, scopeToken, props, isSaving, removeEditingWidget, addEditingWidget]);
  const showOtherReson = useMemo(() => denialReason.map((denial) => denial.headerText.toLowerCase()).includes('other'), [denialReason]);

  const renderEditMode = () => (
    <div className="w-full grid grid-cols-1 gap-4">
      <div>
        <Typography variant="body1Bold">
          {`${denialReasonField?.leadingText}:`}
        </Typography>
        <Typography className="mt-1" variant="caption">Select all that apply</Typography>
        <div className="flex flex-col gap-4 pt-4">
          {
            denialReasonOptions.map((option) => (
              <Checkbox
                displayOnly
                label={option?.headerText}
                onClick={() => setDenialReason(manageList(denialReason, option, 'id'), '')}
                value={denialReason.some((item: PanelElementOption.AsObject) => item.id === option.id)}
              />
            ))
          }
          {showOtherReson && (
            <Input
              displayOnly={false}
              maxLength={255}
              panelElement={{
                ...denialOtherReasonField?.panelElement,
                headerText: denialOtherReasonField?.leadingText,
                id: denialOtherReasonId,
                summaryText: `${maxCharsLeft} character left`,
              } as PanelElement.AsObject}
            />
          )}
        </div>
      </div>
    </div>
  );

  const selectedDisplayValue = useMemo(() => values[denialReasonId]?.value?.map((e: any) => e?.headerText).join(', ') || '--', [values]);
  const handleDiscardAnywayClick = (_: any, callback?: any) => {
    removeEditingWidget(props.id);
    if (callback) callback();
  };

  const handleSaveBeforeLeaving = useEvent((_: any, callback?: any) => {
    document.getElementById('hmda-denial-reason-submit')?.click();
    callback();
  });

  useBlocker(context === 'page' && isEditing && Boolean(props.allowPrompt), {
    actions: [
      { danger: true, label: 'Discard', onClick: handleDiscardAnywayClick, variant: 'tertiary' },
      { label: 'Save', onClick: handleSaveBeforeLeaving, variant: 'tertiary' },
    ],
    children: (
      <Typography variant="body2">
        To continue navigation, you must select Discard or Save.
      </Typography>
    ),
    id: props.id,
    onClickDismiss: () => removeEditingWidget(props.id),
    title: 'Selection Needed',
  });

  const renderReadMode = () => (
    <div className="grid grid-cols-1 gap-4 w-full">
      <ListItem
        label={selectedDisplayValue}
        overline={denialReasonField?.leadingText}
      />
      {showOtherReson && (
        <ListItem
          label={values[denialOtherReasonId]?.value || '--'}
          overline={denialOtherReasonField?.leadingText}
        />
      )}
    </div>
  );

  const renderContent = () => {
    if (isEditing) return renderEditMode();
    return renderReadMode();
  };

  return (
    <Card
      defaultOpen
      headerProps={{
        actions,
        open: expandStatus !== ExpandStatusTypeEnum.COLLAPSE_ALL || context === 'dialog',
        title: props.cardListItem?.label || '',
      }}
      variant="border"
    >
      <div className="p-4 flex flex-row flex-wrap gap-4">
        {renderContent()}
        <button
          data-additionalinfo={JSON.stringify({
            loanId,
            pageId,
            scopeToken,
            type: 'cardListItem',
            widgetId: props.id,
            widgets: [props],
          })}
          hidden
          id="hmda-denial-reason-submit"
          type="submit"
        >
          submit
        </button>
      </div>
    </Card>
  );
};
