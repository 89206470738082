import { useCallback } from 'react';

import type { GetFilteredFieldValueQueryRequest } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/Wilqo_API_Mortgage_DynamicData_Queries_pb';
import { useGetFilteredFieldValue } from '@/API/Queries/mortgageDynamicData/useGetFilteredFieldValue';

export interface ChildrenField {
  widgetId: string;
  childFieldId: string;
  parentFieldIdsList: GetFilteredFieldValueQueryRequest.ParentField.AsObject[];
}

export const useAsyncOptions = (pageId: string, scopeToken: string, dealId = '') => {
  const { isLoading, mutateAsync: filter } = useGetFilteredFieldValue();

  return useCallback(async (
    childFieldId: string,
    widgetId: string,
    parentFieldsList: GetFilteredFieldValueQueryRequest.ParentField.AsObject[],
  ) => {
    if (isLoading) return undefined;
    const response = await filter({ childFieldId, dealId, pageId, parentFieldsList, scopeToken, widgetId, withoutData: false }); // todo: add without data prop
    if (!response.error) {
      return response.filteredField;
    }

    return undefined;
  }, [filter, pageId, scopeToken, dealId, isLoading]);
};
