import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetAssetsQueryRequest, GetAssetsQueryResponse } from '@/API/Models/Wilqo_API_Activity_AdminQueries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetAssetsQueryRequest';
const supportedAssets = ['divider', 'link', 'document-view', 'file-uploader', 'radioGroup'];

const useAssetsSearch = (searchTerm: string) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, searchTerm], async () => {
    const request = new GetAssetsQueryRequest();
    request.setSearchTerm(searchTerm);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetAssetsQueryResponse();
    GetAssetsQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.assetsList);
  },
  {
    select: (data) => data?.filter((item) => supportedAssets.some((supported) => supported === item.panelElementType)),
  });
};
export { useAssetsSearch };
