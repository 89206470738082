import { useQuery } from '@tanstack/react-query';
import { BinaryReader } from 'google-protobuf';

import { GetChecklistActivityQueryRequest, GetChecklistActivityQueryResponse } from '@/API/Models/Wilqo_API_Activity_Queries_pb';

import { useBackend } from '../useBackend';
import { endpointResponseMiddleware } from '../util';

const MESSAGE_NAME = 'wilqo.api.activity.GetChecklistActivityQueryRequest';

const useActivityChecklist = (id: string) => {
  const { commandResponse } = useBackend();

  return useQuery([MESSAGE_NAME, id], async () => {
    const request = new GetChecklistActivityQueryRequest();
    request.setActivityId(id);
    const responseMsg = await commandResponse({
      msg: request,
      msgName: MESSAGE_NAME,
    });
    const response = new GetChecklistActivityQueryResponse();
    GetChecklistActivityQueryResponse.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    const responseObj = response.toObject();
    return endpointResponseMiddleware(MESSAGE_NAME, responseObj.error, responseObj.activity);
  });
};
export { useActivityChecklist };
