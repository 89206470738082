import { useState } from 'react';

import type { LoanPageBanner } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Banner_pb';
import { Icon } from '@/Components/Atoms/Icon';
import { Typography } from '@/Components/Atoms/Typography';
import { cn } from '@/Utils/Helpers/uiBuilders';

import type { BannerRendererProps } from './BannerRenderer';
import { BannerRenderer } from './BannerRenderer';

export const BannerList = ({
  bannerRendererProps,
  banners,
}: {
  banners: LoanPageBanner.AsObject[];
  bannerRendererProps?: Omit<BannerRendererProps, 'banner'>;
}) => {
  const [currentBanner, setCurrentBanner] = useState(0);

  const handleNextBanner = () => {
    if (currentBanner + 1 < banners.length) setCurrentBanner(currentBanner + 1);
  };

  const handlePrevBanner = () => {
    if (currentBanner - 1 > -1) setCurrentBanner(currentBanner - 1);
  };

  return (
    <div className="w-full">
      <BannerRenderer banner={banners[currentBanner]} {...bannerRendererProps} />

      <div
        className={cn(
          'border-b flex items-center justify-center gap-2 py-1',
          { hidden: banners.length < 2 },
        )}
      >
        <Icon icon="ChevronLeft" onClick={handlePrevBanner} />
        <Typography variant="caption">
          {`${currentBanner + 1} of ${banners.length}`}

        </Typography>
        <Icon icon="ChevronRight" onClick={handleNextBanner} />
      </div>
    </div>
  );
};
