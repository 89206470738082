import type { SectionAction } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Actions/SectionAction_pb';
import type { LoanPage } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/LoanPage_pb';

import { PageDialog } from './DynamicDialog';
import { Togglable } from './Togglable';

export interface DynamicDialogProps {
  page?: LoanPage.AsObject;
  isNew: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
  editMode?: boolean;
  scopeToken?: string;
  actions?: SectionAction[];
  isEditable?: boolean;
}

export const DynamicDialog = (props: DynamicDialogProps) => {
  // if page config is togglable
  if (props.page?.widgetsList[0].togglableWidget) {
    return <Togglable {...props} />;
  }
  return <PageDialog {...props} />;
};
