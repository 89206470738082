import { BinaryReader } from 'google-protobuf';

import { WilqoError } from '../Models/Wilqo.Shared.Messaging/WilqoMessaging_pb';
import type { WilqoMessage } from './useBackend';
import { useBackend } from './useBackend';

const useWilqoMessageWrapper = () => {
  const { commandResponse } = useBackend();

  return async <T>(message: WilqoMessage, ResponseType: any): Promise<T> => {
    const response = new ResponseType();
    try {
      const responseMsg = await commandResponse(message);
      ResponseType.deserializeBinaryFromReader(response, new BinaryReader(responseMsg.getValue()));
    } catch (e: any) {
      const errorMessage = new WilqoError();
      errorMessage.setErrorMessage('An error ocurred calling an endpoint');
      response.setError(errorMessage);
    }
    return response.toObject();
  };
};

export type OmitBPD<T> = Omit<T, 'businessProcessDomainId' | 'institutionId'>;

export { useWilqoMessageWrapper };
