import { useGetFeesOptionParameters } from '@/API/Queries/mortgage/useGetFeesOptionParameters';
import { Button } from '@/Components/Atoms/Button';
import { DialogContent, DialogFooter, DialogHeader, DialogLayout } from '@/Components/Atoms/RadixDialog';
import { Skeleton } from '@/Components/Atoms/Skeleton';
import { Typography } from '@/Components/Atoms/Typography';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { DynamicFormProvider } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { PanelElementRenderer } from '@/Components/Features/PanelElement/PanelElementRenderer';

interface TitleCompanyProps {
  dealId?: string;
  closeModal: VoidFunction;
  nextStep: (agentId?: string) => void;
  isSaving: boolean;
}

const TitleCompany = ({
  closeModal,
  dealId,
  isSaving,
  nextStep,
}: TitleCompanyProps) => {
  const { data: panelLists, isLoading } = useGetFeesOptionParameters(dealId);

  const handleSubmit = (values: DynamicDataElementValues) => {
    nextStep(values?.agent?.value?.id);
  };

  return (
    <DialogLayout asChild>
      <DynamicFormProvider
        className="w-full h-full"
        onSubmit={handleSubmit}
        shouldUnregister
      >
        {({ hasError }) => (
          <>
            <DialogHeader
              actions={(
                <Button
                  label="Discard"
                  leftIcon="Delete"
                  onClick={closeModal}
                  variant="tertiary"
                />
              )}
              title="Select Title Company"
            />
            <DialogContent
              className="sm:p-12 p-4 w-full h-full mx-auto max-w-2xl"
            >
              {isLoading ? (
                <Skeleton
                  height={100}
                  rows={2}
                  variant="rect"
                />
              ) : (
                <div className="flex flex-col gap-y-4">
                  {panelLists?.map((panel) => (
                    <div
                      key={panel.id}
                      className="flex flex-col gap-y-6"
                    >
                      <div className="flex flex-col gap-y-2">
                        <Typography className="text-on-surface-active" variant="display6">
                          {panel.headerText}
                        </Typography>
                        <Typography className="text-on-surface-inactive" variant="body1">
                          {panel.summaryText}
                        </Typography>
                      </div>

                      <div className="flex flex-col gap-y-4">
                        {panel.panelElementsList.map((panelElement) => (
                          <PanelElementRenderer
                            key={panelElement.id}
                            variant="default"
                            {...panelElement}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </DialogContent>
            <DialogFooter borderTop span>
              <Button
                label="Cancel"
                leftIcon="ChevronLeft"
                onClick={() => closeModal()}
                variant="tertiary"
              />
              <Button
                disabled={isLoading || hasError}
                isLoading={isSaving}
                label="Submit"
                rightIcon="ChevronRight"
                type="submit"
              />
            </DialogFooter>
          </>
        )}
      </DynamicFormProvider>
    </DialogLayout>
  );
};

export default TitleCompany;
