// source: Wilqo_API_Users_Models.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var Wilqo_API_Users_InstitutionModels_pb = require('./Wilqo_API_Users_InstitutionModels_pb.js');
goog.object.extend(proto, Wilqo_API_Users_InstitutionModels_pb);
goog.exportSymbol('proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum', null, global);
goog.exportSymbol('proto.wilqo.api.users.AssociatedLeadSource', null, global);
goog.exportSymbol('proto.wilqo.api.users.BorrowerInvitation', null, global);
goog.exportSymbol('proto.wilqo.api.users.BusinessProcessDomainEntityId', null, global);
goog.exportSymbol('proto.wilqo.api.users.LeadSource', null, global);
goog.exportSymbol('proto.wilqo.api.users.LicenseValidationMessages', null, global);
goog.exportSymbol('proto.wilqo.api.users.LoanOfficer', null, global);
goog.exportSymbol('proto.wilqo.api.users.LoanPermission', null, global);
goog.exportSymbol('proto.wilqo.api.users.NotificationMethod', null, global);
goog.exportSymbol('proto.wilqo.api.users.PartyRoleType', null, global);
goog.exportSymbol('proto.wilqo.api.users.ReferralSource', null, global);
goog.exportSymbol('proto.wilqo.api.users.Skill', null, global);
goog.exportSymbol('proto.wilqo.api.users.SkillSet', null, global);
goog.exportSymbol('proto.wilqo.api.users.SkillSetSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.SkillsetGroup', null, global);
goog.exportSymbol('proto.wilqo.api.users.SkillsetGroupSummary', null, global);
goog.exportSymbol('proto.wilqo.api.users.User', null, global);
goog.exportSymbol('proto.wilqo.api.users.UserNotificationPreference', null, global);
goog.exportSymbol('proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination', null, global);
goog.exportSymbol('proto.wilqo.api.users.UserNotificationType', null, global);
goog.exportSymbol('proto.wilqo.api.users.UserSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.BusinessProcessDomainEntityId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BusinessProcessDomainEntityId.displayName = 'proto.wilqo.api.users.BusinessProcessDomainEntityId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.Skill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.Skill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.Skill.displayName = 'proto.wilqo.api.users.Skill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SkillSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.SkillSet.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.SkillSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SkillSet.displayName = 'proto.wilqo.api.users.SkillSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SkillsetGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.SkillsetGroup.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.SkillsetGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SkillsetGroup.displayName = 'proto.wilqo.api.users.SkillsetGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SkillsetGroupSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.SkillsetGroupSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.SkillsetGroupSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SkillsetGroupSummary.displayName = 'proto.wilqo.api.users.SkillsetGroupSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.User.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.User.displayName = 'proto.wilqo.api.users.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.LoanOfficer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.LoanOfficer.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.LoanOfficer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.LoanOfficer.displayName = 'proto.wilqo.api.users.LoanOfficer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.SkillSetSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.SkillSetSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.SkillSetSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.SkillSetSummary.displayName = 'proto.wilqo.api.users.SkillSetSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.PartyRoleType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.PartyRoleType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.PartyRoleType.displayName = 'proto.wilqo.api.users.PartyRoleType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.AssociatedLeadSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.AssociatedLeadSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.AssociatedLeadSource.displayName = 'proto.wilqo.api.users.AssociatedLeadSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.ReferralSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.ReferralSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.ReferralSource.displayName = 'proto.wilqo.api.users.ReferralSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.LeadSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.LeadSource.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.LeadSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.LeadSource.displayName = 'proto.wilqo.api.users.LeadSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.BorrowerInvitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.BorrowerInvitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.BorrowerInvitation.displayName = 'proto.wilqo.api.users.BorrowerInvitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.LoanPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.LoanPermission.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.LoanPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.LoanPermission.displayName = 'proto.wilqo.api.users.LoanPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UserSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UserSummary.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UserSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UserSummary.displayName = 'proto.wilqo.api.users.UserSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UserNotificationPreference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.wilqo.api.users.UserNotificationPreference.repeatedFields_, null);
};
goog.inherits(proto.wilqo.api.users.UserNotificationPreference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UserNotificationPreference.displayName = 'proto.wilqo.api.users.UserNotificationPreference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.displayName = 'proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.wilqo.api.users.LicenseValidationMessages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.wilqo.api.users.LicenseValidationMessages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.wilqo.api.users.LicenseValidationMessages.displayName = 'proto.wilqo.api.users.LicenseValidationMessages';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BusinessProcessDomainEntityId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BusinessProcessDomainEntityId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.toObject = function(includeInstance, msg) {
  var f, obj = {
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainEntityId}
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BusinessProcessDomainEntityId;
  return proto.wilqo.api.users.BusinessProcessDomainEntityId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainEntityId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BusinessProcessDomainEntityId}
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BusinessProcessDomainEntityId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BusinessProcessDomainEntityId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string business_process_domain_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainEntityId} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BusinessProcessDomainEntityId} returns this
 */
proto.wilqo.api.users.BusinessProcessDomainEntityId.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.Skill.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.Skill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.Skill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Skill.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.Skill}
 */
proto.wilqo.api.users.Skill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.Skill;
  return proto.wilqo.api.users.Skill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.Skill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.Skill}
 */
proto.wilqo.api.users.Skill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.Skill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.Skill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.Skill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.Skill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.Skill.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Skill} returns this
 */
proto.wilqo.api.users.Skill.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.Skill.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Skill} returns this
 */
proto.wilqo.api.users.Skill.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.api.users.Skill.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Skill} returns this
 */
proto.wilqo.api.users.Skill.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.wilqo.api.users.Skill.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.Skill} returns this
 */
proto.wilqo.api.users.Skill.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.SkillSet.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SkillSet.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SkillSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SkillSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    skillsList: jspb.Message.toObjectList(msg.getSkillsList(),
    proto.wilqo.api.users.Skill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SkillSet}
 */
proto.wilqo.api.users.SkillSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SkillSet;
  return proto.wilqo.api.users.SkillSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SkillSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SkillSet}
 */
proto.wilqo.api.users.SkillSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 6:
      var value = new proto.wilqo.api.users.Skill;
      reader.readMessage(value,proto.wilqo.api.users.Skill.deserializeBinaryFromReader);
      msg.addSkills(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SkillSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SkillSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SkillSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.users.Skill.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SkillSet.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSet} returns this
 */
proto.wilqo.api.users.SkillSet.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SkillSet.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSet} returns this
 */
proto.wilqo.api.users.SkillSet.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SkillSet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSet} returns this
 */
proto.wilqo.api.users.SkillSet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.wilqo.api.users.SkillSet.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSet} returns this
 */
proto.wilqo.api.users.SkillSet.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string long_description = 5;
 * @return {string}
 */
proto.wilqo.api.users.SkillSet.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSet} returns this
 */
proto.wilqo.api.users.SkillSet.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Skill skills = 6;
 * @return {!Array<!proto.wilqo.api.users.Skill>}
 */
proto.wilqo.api.users.SkillSet.prototype.getSkillsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.Skill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.Skill, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.users.Skill>} value
 * @return {!proto.wilqo.api.users.SkillSet} returns this
*/
proto.wilqo.api.users.SkillSet.prototype.setSkillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.users.Skill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.Skill}
 */
proto.wilqo.api.users.SkillSet.prototype.addSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.users.Skill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillSet} returns this
 */
proto.wilqo.api.users.SkillSet.prototype.clearSkillsList = function() {
  return this.setSkillsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.SkillsetGroup.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SkillsetGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SkillsetGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    skillSetsList: jspb.Message.toObjectList(msg.getSkillSetsList(),
    proto.wilqo.api.users.SkillSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SkillsetGroup}
 */
proto.wilqo.api.users.SkillsetGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SkillsetGroup;
  return proto.wilqo.api.users.SkillsetGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SkillsetGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SkillsetGroup}
 */
proto.wilqo.api.users.SkillsetGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 6:
      var value = new proto.wilqo.api.users.SkillSet;
      reader.readMessage(value,proto.wilqo.api.users.SkillSet.deserializeBinaryFromReader);
      msg.addSkillSets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SkillsetGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SkillsetGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSkillSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.users.SkillSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
 */
proto.wilqo.api.users.SkillsetGroup.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
 */
proto.wilqo.api.users.SkillsetGroup.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
 */
proto.wilqo.api.users.SkillsetGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
 */
proto.wilqo.api.users.SkillsetGroup.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string long_description = 5;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
 */
proto.wilqo.api.users.SkillsetGroup.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated SkillSet skill_sets = 6;
 * @return {!Array<!proto.wilqo.api.users.SkillSet>}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.getSkillSetsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.SkillSet, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillSet>} value
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
*/
proto.wilqo.api.users.SkillsetGroup.prototype.setSkillSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSet}
 */
proto.wilqo.api.users.SkillsetGroup.prototype.addSkillSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.users.SkillSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillsetGroup} returns this
 */
proto.wilqo.api.users.SkillsetGroup.prototype.clearSkillSetsList = function() {
  return this.setSkillSetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.SkillsetGroupSummary.repeatedFields_ = [9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SkillsetGroupSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetGroupSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    skillsetsCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    skillsCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    usersCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    skillSetsIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    skillsIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    usersIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.SkillsetGroupSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SkillsetGroupSummary;
  return proto.wilqo.api.users.SkillsetGroupSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.SkillsetGroupSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSkillsetsCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSkillsCount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsersCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillSetsIds(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsIds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsersIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SkillsetGroupSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillsetGroupSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSkillsetsCount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getSkillsCount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getUsersCount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSkillSetsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getSkillsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getUsersIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string long_description = 5;
 * @return {string}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 skillsets_count = 6;
 * @return {number}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getSkillsetsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setSkillsetsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 skills_count = 7;
 * @return {number}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getSkillsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setSkillsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 users_count = 8;
 * @return {number}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated string skill_sets_ids = 9;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getSkillSetsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setSkillSetsIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.addSkillSetsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.clearSkillSetsIdsList = function() {
  return this.setSkillSetsIdsList([]);
};


/**
 * repeated string skills_ids = 10;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getSkillsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setSkillsIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.addSkillsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.clearSkillsIdsList = function() {
  return this.setSkillsIdsList([]);
};


/**
 * repeated string users_ids = 11;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.getUsersIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.setUsersIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.addUsersIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary} returns this
 */
proto.wilqo.api.users.SkillsetGroupSummary.prototype.clearUsersIdsList = function() {
  return this.setUsersIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.User.repeatedFields_ = [4,9,10,11,12,13,15,20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.User.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skillsetGroupList: jspb.Message.toObjectList(msg.getSkillsetGroupList(),
    proto.wilqo.api.users.SkillsetGroupSummary.toObject, includeInstance),
    roleMask: msg.getRoleMask_asB64(),
    firstName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    email: jspb.Message.getFieldWithDefault(msg, 8, ""),
    loanPartyRoleTypesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    organizationIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    associatedDealsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    licensesList: jspb.Message.toObjectList(msg.getLicensesList(),
    Wilqo_API_Users_InstitutionModels_pb.License.toObject, includeInstance),
    associatedLeadSourcesList: jspb.Message.toObjectList(msg.getAssociatedLeadSourcesList(),
    proto.wilqo.api.users.AssociatedLeadSource.toObject, includeInstance),
    nationalLicensingId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    partyRoleTypesList: jspb.Message.toObjectList(msg.getPartyRoleTypesList(),
    proto.wilqo.api.users.PartyRoleType.toObject, includeInstance),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    loanPermission: (f = msg.getLoanPermission()) && proto.wilqo.api.users.LoanPermission.toObject(includeInstance, f),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    appliedEventCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    allowedNotificationTypesList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.User;
  return proto.wilqo.api.users.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.User}
 */
proto.wilqo.api.users.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.SkillsetGroupSummary;
      reader.readMessage(value,proto.wilqo.api.users.SkillsetGroupSummary.deserializeBinaryFromReader);
      msg.addSkillsetGroup(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRoleMask(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLoanPartyRoleTypes(values[i]);
      }
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizationIds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssociatedDeals(value);
      break;
    case 12:
      var value = new Wilqo_API_Users_InstitutionModels_pb.License;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.License.deserializeBinaryFromReader);
      msg.addLicenses(value);
      break;
    case 13:
      var value = new proto.wilqo.api.users.AssociatedLeadSource;
      reader.readMessage(value,proto.wilqo.api.users.AssociatedLeadSource.deserializeBinaryFromReader);
      msg.addAssociatedLeadSources(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalLicensingId(value);
      break;
    case 15:
      var value = new proto.wilqo.api.users.PartyRoleType;
      reader.readMessage(value,proto.wilqo.api.users.PartyRoleType.deserializeBinaryFromReader);
      msg.addPartyRoleTypes(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 17:
      var value = new proto.wilqo.api.users.LoanPermission;
      reader.readMessage(value,proto.wilqo.api.users.LoanPermission.deserializeBinaryFromReader);
      msg.setLoanPermission(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAppliedEventCount(value);
      break;
    case 20:
      var values = /** @type {!Array<!proto.wilqo.api.users.UserNotificationType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllowedNotificationTypes(values[i]);
      }
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkillsetGroupList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.users.SkillsetGroupSummary.serializeBinaryToWriter
    );
  }
  f = message.getRoleMask_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLoanPartyRoleTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
  f = message.getOrganizationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getAssociatedDealsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getLicensesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      Wilqo_API_Users_InstitutionModels_pb.License.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedLeadSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.wilqo.api.users.AssociatedLeadSource.serializeBinaryToWriter
    );
  }
  f = message.getNationalLicensingId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPartyRoleTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.wilqo.api.users.PartyRoleType.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLoanPermission();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.wilqo.api.users.LoanPermission.serializeBinaryToWriter
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAppliedEventCount();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getAllowedNotificationTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      20,
      f
    );
  }
  f = message.getEmailVerified();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated SkillsetGroupSummary skillset_group = 4;
 * @return {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>}
 */
proto.wilqo.api.users.User.prototype.getSkillsetGroupList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.SkillsetGroupSummary, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} value
 * @return {!proto.wilqo.api.users.User} returns this
*/
proto.wilqo.api.users.User.prototype.setSkillsetGroupList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.User.prototype.addSkillsetGroup = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.SkillsetGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearSkillsetGroupList = function() {
  return this.setSkillsetGroupList([]);
};


/**
 * optional bytes role_mask = 5;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getRoleMask = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes role_mask = 5;
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getRoleMask_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRoleMask()));
};


/**
 * optional bytes role_mask = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRoleMask()`
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.User.prototype.getRoleMask_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRoleMask()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setRoleMask = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};


/**
 * optional string first_name = 6;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string last_name = 7;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string email = 8;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated AdminLoanPartyRoleTypesEnum loan_party_role_types = 9;
 * @return {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>}
 */
proto.wilqo.api.users.User.prototype.getLoanPartyRoleTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum>} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setLoanPartyRoleTypesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.addLoanPartyRoleTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearLoanPartyRoleTypesList = function() {
  return this.setLoanPartyRoleTypesList([]);
};


/**
 * repeated string organization_ids = 10;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.User.prototype.getOrganizationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setOrganizationIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.addOrganizationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearOrganizationIdsList = function() {
  return this.setOrganizationIdsList([]);
};


/**
 * repeated string associated_deals = 11;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.User.prototype.getAssociatedDealsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setAssociatedDealsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.addAssociatedDeals = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearAssociatedDealsList = function() {
  return this.setAssociatedDealsList([]);
};


/**
 * repeated License licenses = 12;
 * @return {!Array<!proto.wilqo.api.users.License>}
 */
proto.wilqo.api.users.User.prototype.getLicensesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.License>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.License, 12));
};


/**
 * @param {!Array<!proto.wilqo.api.users.License>} value
 * @return {!proto.wilqo.api.users.User} returns this
*/
proto.wilqo.api.users.User.prototype.setLicensesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.wilqo.api.users.License=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.License}
 */
proto.wilqo.api.users.User.prototype.addLicenses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.wilqo.api.users.License, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearLicensesList = function() {
  return this.setLicensesList([]);
};


/**
 * repeated AssociatedLeadSource associated_lead_sources = 13;
 * @return {!Array<!proto.wilqo.api.users.AssociatedLeadSource>}
 */
proto.wilqo.api.users.User.prototype.getAssociatedLeadSourcesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.AssociatedLeadSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.AssociatedLeadSource, 13));
};


/**
 * @param {!Array<!proto.wilqo.api.users.AssociatedLeadSource>} value
 * @return {!proto.wilqo.api.users.User} returns this
*/
proto.wilqo.api.users.User.prototype.setAssociatedLeadSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.wilqo.api.users.AssociatedLeadSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.AssociatedLeadSource}
 */
proto.wilqo.api.users.User.prototype.addAssociatedLeadSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.wilqo.api.users.AssociatedLeadSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearAssociatedLeadSourcesList = function() {
  return this.setAssociatedLeadSourcesList([]);
};


/**
 * optional string national_licensing_id = 14;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getNationalLicensingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setNationalLicensingId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated PartyRoleType party_role_types = 15;
 * @return {!Array<!proto.wilqo.api.users.PartyRoleType>}
 */
proto.wilqo.api.users.User.prototype.getPartyRoleTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.PartyRoleType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.PartyRoleType, 15));
};


/**
 * @param {!Array<!proto.wilqo.api.users.PartyRoleType>} value
 * @return {!proto.wilqo.api.users.User} returns this
*/
proto.wilqo.api.users.User.prototype.setPartyRoleTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.wilqo.api.users.PartyRoleType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.PartyRoleType}
 */
proto.wilqo.api.users.User.prototype.addPartyRoleTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.wilqo.api.users.PartyRoleType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearPartyRoleTypesList = function() {
  return this.setPartyRoleTypesList([]);
};


/**
 * optional string phone_number = 16;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional LoanPermission loan_permission = 17;
 * @return {?proto.wilqo.api.users.LoanPermission}
 */
proto.wilqo.api.users.User.prototype.getLoanPermission = function() {
  return /** @type{?proto.wilqo.api.users.LoanPermission} */ (
    jspb.Message.getWrapperField(this, proto.wilqo.api.users.LoanPermission, 17));
};


/**
 * @param {?proto.wilqo.api.users.LoanPermission|undefined} value
 * @return {!proto.wilqo.api.users.User} returns this
*/
proto.wilqo.api.users.User.prototype.setLoanPermission = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearLoanPermission = function() {
  return this.setLoanPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.wilqo.api.users.User.prototype.hasLoanPermission = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string profile_image_url = 18;
 * @return {string}
 */
proto.wilqo.api.users.User.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional uint32 applied_event_count = 19;
 * @return {number}
 */
proto.wilqo.api.users.User.prototype.getAppliedEventCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setAppliedEventCount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * repeated UserNotificationType allowed_notification_types = 20;
 * @return {!Array<!proto.wilqo.api.users.UserNotificationType>}
 */
proto.wilqo.api.users.User.prototype.getAllowedNotificationTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.UserNotificationType>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<!proto.wilqo.api.users.UserNotificationType>} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setAllowedNotificationTypesList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {!proto.wilqo.api.users.UserNotificationType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.addAllowedNotificationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.clearAllowedNotificationTypesList = function() {
  return this.setAllowedNotificationTypesList([]);
};


/**
 * optional bool email_verified = 21;
 * @return {boolean}
 */
proto.wilqo.api.users.User.prototype.getEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.User} returns this
 */
proto.wilqo.api.users.User.prototype.setEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.LoanOfficer.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.LoanOfficer.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.LoanOfficer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.LoanOfficer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LoanOfficer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.LoanOfficer}
 */
proto.wilqo.api.users.LoanOfficer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.LoanOfficer;
  return proto.wilqo.api.users.LoanOfficer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.LoanOfficer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.LoanOfficer}
 */
proto.wilqo.api.users.LoanOfficer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = new Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary;
      reader.readMessage(value,Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.LoanOfficer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.LoanOfficer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.LoanOfficer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LoanOfficer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.LoanOfficer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LoanOfficer} returns this
 */
proto.wilqo.api.users.LoanOfficer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.wilqo.api.users.LoanOfficer.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LoanOfficer} returns this
 */
proto.wilqo.api.users.LoanOfficer.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OrganizationSummary organizations = 3;
 * @return {!Array<!proto.wilqo.api.users.OrganizationSummary>}
 */
proto.wilqo.api.users.LoanOfficer.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.OrganizationSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, Wilqo_API_Users_InstitutionModels_pb.OrganizationSummary, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.OrganizationSummary>} value
 * @return {!proto.wilqo.api.users.LoanOfficer} returns this
*/
proto.wilqo.api.users.LoanOfficer.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.OrganizationSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.OrganizationSummary}
 */
proto.wilqo.api.users.LoanOfficer.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.OrganizationSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.LoanOfficer} returns this
 */
proto.wilqo.api.users.LoanOfficer.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.SkillSetSummary.repeatedFields_ = [6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.SkillSetSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.SkillSetSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillSetSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    institutionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    businessProcessDomainId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    longDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    skillsetGroupsList: jspb.Message.toObjectList(msg.getSkillsetGroupsList(),
    proto.wilqo.api.users.SkillsetGroupSummary.toObject, includeInstance),
    usersCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    skillsCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    skillsIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.SkillSetSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.SkillSetSummary;
  return proto.wilqo.api.users.SkillSetSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.SkillSetSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.SkillSetSummary}
 */
proto.wilqo.api.users.SkillSetSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessProcessDomainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongDescription(value);
      break;
    case 6:
      var value = new proto.wilqo.api.users.SkillsetGroupSummary;
      reader.readMessage(value,proto.wilqo.api.users.SkillsetGroupSummary.deserializeBinaryFromReader);
      msg.addSkillsetGroups(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsersCount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSkillsCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.SkillSetSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.SkillSetSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.SkillSetSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBusinessProcessDomainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLongDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSkillsetGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.users.SkillsetGroupSummary.serializeBinaryToWriter
    );
  }
  f = message.getUsersCount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getSkillsCount();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSkillsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional string institution_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string business_process_domain_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getBusinessProcessDomainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setBusinessProcessDomainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string long_description = 5;
 * @return {string}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getLongDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setLongDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated SkillsetGroupSummary skillset_groups = 6;
 * @return {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getSkillsetGroupsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.SkillsetGroupSummary, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.users.SkillsetGroupSummary>} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
*/
proto.wilqo.api.users.SkillSetSummary.prototype.setSkillsetGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.users.SkillsetGroupSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillsetGroupSummary}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.addSkillsetGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.users.SkillsetGroupSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.clearSkillsetGroupsList = function() {
  return this.setSkillsetGroupsList([]);
};


/**
 * optional uint32 users_count = 7;
 * @return {number}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getUsersCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setUsersCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 skills_count = 8;
 * @return {number}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getSkillsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setSkillsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated string skills_ids = 9;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.SkillSetSummary.prototype.getSkillsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.setSkillsIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.addSkillsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.SkillSetSummary} returns this
 */
proto.wilqo.api.users.SkillSetSummary.prototype.clearSkillsIdsList = function() {
  return this.setSkillsIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.PartyRoleType.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.PartyRoleType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.PartyRoleType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.PartyRoleType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.PartyRoleType}
 */
proto.wilqo.api.users.PartyRoleType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.PartyRoleType;
  return proto.wilqo.api.users.PartyRoleType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.PartyRoleType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.PartyRoleType}
 */
proto.wilqo.api.users.PartyRoleType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.PartyRoleType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.PartyRoleType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.PartyRoleType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.PartyRoleType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AdminLoanPartyRoleTypesEnum id = 1;
 * @return {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum}
 */
proto.wilqo.api.users.PartyRoleType.prototype.getId = function() {
  return /** @type {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum} value
 * @return {!proto.wilqo.api.users.PartyRoleType} returns this
 */
proto.wilqo.api.users.PartyRoleType.prototype.setId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.users.PartyRoleType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.PartyRoleType} returns this
 */
proto.wilqo.api.users.PartyRoleType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.AssociatedLeadSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.AssociatedLeadSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AssociatedLeadSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    leadSourceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    leadSourceActive: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    userId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.AssociatedLeadSource}
 */
proto.wilqo.api.users.AssociatedLeadSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.AssociatedLeadSource;
  return proto.wilqo.api.users.AssociatedLeadSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.AssociatedLeadSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.AssociatedLeadSource}
 */
proto.wilqo.api.users.AssociatedLeadSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadSourceId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLeadSourceActive(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.AssociatedLeadSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.AssociatedLeadSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.AssociatedLeadSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeadSourceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLeadSourceActive();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string lead_source_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.getLeadSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AssociatedLeadSource} returns this
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.setLeadSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool lead_source_active = 2;
 * @return {boolean}
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.getLeadSourceActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.AssociatedLeadSource} returns this
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.setLeadSourceActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string user_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.AssociatedLeadSource} returns this
 */
proto.wilqo.api.users.AssociatedLeadSource.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.ReferralSource.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.ReferralSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.ReferralSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ReferralSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.ReferralSource}
 */
proto.wilqo.api.users.ReferralSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.ReferralSource;
  return proto.wilqo.api.users.ReferralSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.ReferralSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.ReferralSource}
 */
proto.wilqo.api.users.ReferralSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.ReferralSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.ReferralSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.ReferralSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.ReferralSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.ReferralSource.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.ReferralSource} returns this
 */
proto.wilqo.api.users.ReferralSource.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.users.ReferralSource.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.ReferralSource} returns this
 */
proto.wilqo.api.users.ReferralSource.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.ReferralSource.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.ReferralSource} returns this
 */
proto.wilqo.api.users.ReferralSource.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.LeadSource.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.LeadSource.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.LeadSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.LeadSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LeadSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    referralSourcesList: jspb.Message.toObjectList(msg.getReferralSourcesList(),
    proto.wilqo.api.users.ReferralSource.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.LeadSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.LeadSource;
  return proto.wilqo.api.users.LeadSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.LeadSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.LeadSource}
 */
proto.wilqo.api.users.LeadSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 4:
      var value = new proto.wilqo.api.users.ReferralSource;
      reader.readMessage(value,proto.wilqo.api.users.ReferralSource.deserializeBinaryFromReader);
      msg.addReferralSources(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.LeadSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.LeadSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.LeadSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LeadSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReferralSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.wilqo.api.users.ReferralSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.LeadSource.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LeadSource} returns this
 */
proto.wilqo.api.users.LeadSource.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.wilqo.api.users.LeadSource.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LeadSource} returns this
 */
proto.wilqo.api.users.LeadSource.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.wilqo.api.users.LeadSource.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.wilqo.api.users.LeadSource} returns this
 */
proto.wilqo.api.users.LeadSource.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated ReferralSource referral_sources = 4;
 * @return {!Array<!proto.wilqo.api.users.ReferralSource>}
 */
proto.wilqo.api.users.LeadSource.prototype.getReferralSourcesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.ReferralSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.ReferralSource, 4));
};


/**
 * @param {!Array<!proto.wilqo.api.users.ReferralSource>} value
 * @return {!proto.wilqo.api.users.LeadSource} returns this
*/
proto.wilqo.api.users.LeadSource.prototype.setReferralSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.wilqo.api.users.ReferralSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.ReferralSource}
 */
proto.wilqo.api.users.LeadSource.prototype.addReferralSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.wilqo.api.users.ReferralSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.LeadSource} returns this
 */
proto.wilqo.api.users.LeadSource.prototype.clearReferralSourcesList = function() {
  return this.setReferralSourcesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.BorrowerInvitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.BorrowerInvitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BorrowerInvitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dealId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    borrowerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    borrowerEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inviteUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    associatedUser: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.BorrowerInvitation}
 */
proto.wilqo.api.users.BorrowerInvitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.BorrowerInvitation;
  return proto.wilqo.api.users.BorrowerInvitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.BorrowerInvitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.BorrowerInvitation}
 */
proto.wilqo.api.users.BorrowerInvitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorrowerEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.BorrowerInvitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.BorrowerInvitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.BorrowerInvitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorrowerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBorrowerEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInviteUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssociatedUser();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BorrowerInvitation} returns this
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deal_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BorrowerInvitation} returns this
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string borrower_id = 3;
 * @return {string}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.getBorrowerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BorrowerInvitation} returns this
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.setBorrowerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string borrower_email = 4;
 * @return {string}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.getBorrowerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BorrowerInvitation} returns this
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.setBorrowerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string invite_url = 5;
 * @return {string}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.getInviteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BorrowerInvitation} returns this
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.setInviteUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string associated_user = 6;
 * @return {string}
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.getAssociatedUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.BorrowerInvitation} returns this
 */
proto.wilqo.api.users.BorrowerInvitation.prototype.setAssociatedUser = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.LoanPermission.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.LoanPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.LoanPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.LoanPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LoanPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowedOrgIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    allowedPartyRoleTypesList: jspb.Message.toObjectList(msg.getAllowedPartyRoleTypesList(),
    proto.wilqo.api.users.PartyRoleType.toObject, includeInstance),
    allowedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.LoanPermission}
 */
proto.wilqo.api.users.LoanPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.LoanPermission;
  return proto.wilqo.api.users.LoanPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.LoanPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.LoanPermission}
 */
proto.wilqo.api.users.LoanPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedOrgIds(value);
      break;
    case 2:
      var value = new proto.wilqo.api.users.PartyRoleType;
      reader.readMessage(value,proto.wilqo.api.users.PartyRoleType.deserializeBinaryFromReader);
      msg.addAllowedPartyRoleTypes(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.LoanPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.LoanPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.LoanPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LoanPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowedOrgIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getAllowedPartyRoleTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.wilqo.api.users.PartyRoleType.serializeBinaryToWriter
    );
  }
  f = message.getAllowedUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated string allowed_org_ids = 1;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.LoanPermission.prototype.getAllowedOrgIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.setAllowedOrgIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.addAllowedOrgIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.clearAllowedOrgIdsList = function() {
  return this.setAllowedOrgIdsList([]);
};


/**
 * repeated PartyRoleType allowed_party_role_types = 2;
 * @return {!Array<!proto.wilqo.api.users.PartyRoleType>}
 */
proto.wilqo.api.users.LoanPermission.prototype.getAllowedPartyRoleTypesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.PartyRoleType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.PartyRoleType, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.PartyRoleType>} value
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
*/
proto.wilqo.api.users.LoanPermission.prototype.setAllowedPartyRoleTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.wilqo.api.users.PartyRoleType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.PartyRoleType}
 */
proto.wilqo.api.users.LoanPermission.prototype.addAllowedPartyRoleTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.wilqo.api.users.PartyRoleType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.clearAllowedPartyRoleTypesList = function() {
  return this.setAllowedPartyRoleTypesList([]);
};


/**
 * repeated string allowed_user_ids = 3;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.LoanPermission.prototype.getAllowedUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.setAllowedUserIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.addAllowedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.LoanPermission} returns this
 */
proto.wilqo.api.users.LoanPermission.prototype.clearAllowedUserIdsList = function() {
  return this.setAllowedUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UserSummary.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UserSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UserSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UserSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    institutionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organizationsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    skillsetGroupsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    partyRolesList: jspb.Message.toObjectList(msg.getPartyRolesList(),
    proto.wilqo.api.users.PartyRoleType.toObject, includeInstance),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UserSummary}
 */
proto.wilqo.api.users.UserSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UserSummary;
  return proto.wilqo.api.users.UserSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UserSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UserSummary}
 */
proto.wilqo.api.users.UserSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOrganizations(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSkillsetGroups(value);
      break;
    case 6:
      var value = new proto.wilqo.api.users.PartyRoleType;
      reader.readMessage(value,proto.wilqo.api.users.PartyRoleType.deserializeBinaryFromReader);
      msg.addPartyRoles(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UserSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UserSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UserSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstitutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getSkillsetGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPartyRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.wilqo.api.users.PartyRoleType.serializeBinaryToWriter
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UserSummary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string institution_id = 2;
 * @return {string}
 */
proto.wilqo.api.users.UserSummary.prototype.getInstitutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.setInstitutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.wilqo.api.users.UserSummary.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string organizations = 4;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UserSummary.prototype.getOrganizationsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.addOrganizations = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};


/**
 * repeated string skillset_groups = 5;
 * @return {!Array<string>}
 */
proto.wilqo.api.users.UserSummary.prototype.getSkillsetGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.setSkillsetGroupsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.addSkillsetGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.clearSkillsetGroupsList = function() {
  return this.setSkillsetGroupsList([]);
};


/**
 * repeated PartyRoleType party_roles = 6;
 * @return {!Array<!proto.wilqo.api.users.PartyRoleType>}
 */
proto.wilqo.api.users.UserSummary.prototype.getPartyRolesList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.PartyRoleType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.PartyRoleType, 6));
};


/**
 * @param {!Array<!proto.wilqo.api.users.PartyRoleType>} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
*/
proto.wilqo.api.users.UserSummary.prototype.setPartyRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.wilqo.api.users.PartyRoleType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.PartyRoleType}
 */
proto.wilqo.api.users.UserSummary.prototype.addPartyRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.wilqo.api.users.PartyRoleType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.clearPartyRolesList = function() {
  return this.setPartyRolesList([]);
};


/**
 * optional string profile_image_url = 7;
 * @return {string}
 */
proto.wilqo.api.users.UserSummary.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UserSummary} returns this
 */
proto.wilqo.api.users.UserSummary.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.wilqo.api.users.UserNotificationPreference.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UserNotificationPreference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UserNotificationPreference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserNotificationPreference.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allowedNotificationTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    destinationsList: jspb.Message.toObjectList(msg.getDestinationsList(),
    proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UserNotificationPreference}
 */
proto.wilqo.api.users.UserNotificationPreference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UserNotificationPreference;
  return proto.wilqo.api.users.UserNotificationPreference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UserNotificationPreference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UserNotificationPreference}
 */
proto.wilqo.api.users.UserNotificationPreference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.wilqo.api.users.UserNotificationType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAllowedNotificationTypes(values[i]);
      }
      break;
    case 3:
      var value = new proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination;
      reader.readMessage(value,proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.deserializeBinaryFromReader);
      msg.addDestinations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UserNotificationPreference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UserNotificationPreference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserNotificationPreference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllowedNotificationTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      2,
      f
    );
  }
  f = message.getDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.toObject = function(includeInstance, msg) {
  var f, obj = {
    method: jspb.Message.getFieldWithDefault(msg, 1, 0),
    destination: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination}
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination;
  return proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination}
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.wilqo.api.users.NotificationMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional NotificationMethod method = 1;
 * @return {!proto.wilqo.api.users.NotificationMethod}
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.prototype.getMethod = function() {
  return /** @type {!proto.wilqo.api.users.NotificationMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.wilqo.api.users.NotificationMethod} value
 * @return {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string destination = 2;
 * @return {string}
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.UserNotificationPreference} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UserNotificationType allowed_notification_types = 2;
 * @return {!Array<!proto.wilqo.api.users.UserNotificationType>}
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.getAllowedNotificationTypesList = function() {
  return /** @type {!Array<!proto.wilqo.api.users.UserNotificationType>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.wilqo.api.users.UserNotificationType>} value
 * @return {!proto.wilqo.api.users.UserNotificationPreference} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.setAllowedNotificationTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.wilqo.api.users.UserNotificationType} value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UserNotificationPreference} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.addAllowedNotificationTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UserNotificationPreference} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.clearAllowedNotificationTypesList = function() {
  return this.setAllowedNotificationTypesList([]);
};


/**
 * repeated UserNotificationDestination destinations = 3;
 * @return {!Array<!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination>}
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.getDestinationsList = function() {
  return /** @type{!Array<!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination, 3));
};


/**
 * @param {!Array<!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination>} value
 * @return {!proto.wilqo.api.users.UserNotificationPreference} returns this
*/
proto.wilqo.api.users.UserNotificationPreference.prototype.setDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination}
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.addDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.wilqo.api.users.UserNotificationPreference.UserNotificationDestination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.wilqo.api.users.UserNotificationPreference} returns this
 */
proto.wilqo.api.users.UserNotificationPreference.prototype.clearDestinationsList = function() {
  return this.setDestinationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.toObject = function(opt_includeInstance) {
  return proto.wilqo.api.users.LicenseValidationMessages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.wilqo.api.users.LicenseValidationMessages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LicenseValidationMessages.toObject = function(includeInstance, msg) {
  var f, obj = {
    organizationLicenseFailureMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userLicenseFailureMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    consumerInquiryStateLicenseFailureMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.wilqo.api.users.LicenseValidationMessages}
 */
proto.wilqo.api.users.LicenseValidationMessages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.wilqo.api.users.LicenseValidationMessages;
  return proto.wilqo.api.users.LicenseValidationMessages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.wilqo.api.users.LicenseValidationMessages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.wilqo.api.users.LicenseValidationMessages}
 */
proto.wilqo.api.users.LicenseValidationMessages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationLicenseFailureMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserLicenseFailureMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConsumerInquiryStateLicenseFailureMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.wilqo.api.users.LicenseValidationMessages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.wilqo.api.users.LicenseValidationMessages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.wilqo.api.users.LicenseValidationMessages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationLicenseFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserLicenseFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConsumerInquiryStateLicenseFailureMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string organization_license_failure_message = 1;
 * @return {string}
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.getOrganizationLicenseFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseValidationMessages} returns this
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.setOrganizationLicenseFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_license_failure_message = 2;
 * @return {string}
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.getUserLicenseFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseValidationMessages} returns this
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.setUserLicenseFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string consumer_inquiry_state_license_failure_message = 3;
 * @return {string}
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.getConsumerInquiryStateLicenseFailureMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.wilqo.api.users.LicenseValidationMessages} returns this
 */
proto.wilqo.api.users.LicenseValidationMessages.prototype.setConsumerInquiryStateLicenseFailureMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.wilqo.api.users.AdminLoanPartyRoleTypesEnum = {
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_ORIGINATOR: 0,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_PROCESSOR: 1,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_UNDERWRITER: 2,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_CLOSER: 3,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_FUNDER: 4,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_DELIVER_FILE_PREPARER: 5,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_LOAN_ASSISTANT: 6,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_UNKNOWN: 7,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_FILE_STARTER: 8,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_REFERRING_LOAN_ORIGINATOR: 9,
  ADMIN_LOAN_PARTY_ROLE_TYPES_ENUM_SHIPPER: 10
};

/**
 * @enum {number}
 */
proto.wilqo.api.users.UserNotificationType = {
  USER_NOTIFICATION_TYPE_UNKNOWN: 0,
  USER_NOTIFICATION_TYPE_MORTGAGE_NEW_LOAN: 1,
  USER_NOTIFICATION_TYPE_MORTGAGE_NEW_BORROWER: 2,
  USER_NOTIFICATION_TYPE_MORTGAGE_DOCUMENT_ADDED_BY_BORROWER: 3,
  USER_NOTIFICATION_TYPE_MORTGAGE_APPLICATION_SUBMITTED: 4
};

/**
 * @enum {number}
 */
proto.wilqo.api.users.NotificationMethod = {
  NOTIFICATION_METHOD_UNKNOWN: 0,
  NOTIFICATION_METHOD_SMS: 1,
  NOTIFICATION_METHOD_EMAIL: 2,
  NOTIFICATION_METHOD_MICROSOFT_TEAMS: 3
};

goog.object.extend(exports, proto.wilqo.api.users);
