import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import type { Panel } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import type { BatchReorderPanelCommandRequest } from '@/API/Models/Wilqo_API_Activity_AdminCommands_pb';
import { Dialog } from '@/Components/Atoms/Dialog';
import { Icon } from '@/Components/Atoms/Icon';

interface IEditPanelProps {
  runtimePanels: Array<Panel.AsObject>;
  closeModal: () => void;
  onConfirm: (moves: BatchReorderPanelCommandRequest.MoveDetail.AsObject[]) => void;
}

const reorder = (
  list: Array<Panel.AsObject>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  background: isDragging ? 'rgba(0 0 0 / 12%)' : 'transparent',
  padding: '12px',
  ...draggableStyle,
});

const ReorderPanelsModal = (props: IEditPanelProps) => {
  const { closeModal, onConfirm, runtimePanels } = props;
  const [panels, setPanels] = useState(runtimePanels);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      panels,
      result.source.index,
      result.destination.index,
    );

    setPanels(updatedItems);
  };

  const onClickConfirm = () => {
    closeModal();
    const requestPanels = panels.map((panel, index) => ({
      newIndex: index,
      panelId: panel.id,
    }));
    onConfirm(requestPanels);
  };

  const onClickDismiss = () => {
    closeModal();
    setPanels(runtimePanels);
  };

  return (
    <Dialog
      actions={[
        { label: 'Save', onClick: onClickConfirm, type: 'button' },
      ]}
      dismissButtonLabel="Cancel"
      onClickDismiss={onClickDismiss}
      title="Reorder pages"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef.bind(this)}
            >
              {panels.map((panel, index: number) => (
                <Draggable key={panel.id} draggableId={panel.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef.bind(this)}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div className="flex items-center w-full">
                        <Icon
                          className="block relative right-[6px] bg-on-surface-active text-2xl"
                          icon="DragIndicator"
                        />
                        <div>
                          <p className="m-0 text-sm font-bold leading-5">{`Page ${index + 1} of ${panels.length}`}</p>
                          <span className="text-on-surface-active text-xs font-normal leading-4">{panel.headerText}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Dialog>
  );
};

export { ReorderPanelsModal };
