import clsx from 'clsx';

import type { HyperLinkType } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import { HyperLinkTarget } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/CommonConfig_pb';
import type { PanelHyperlinkElement } from '@/API/Models/Wilqo.Shared.Models/Wilqo_Shared_Models_UIComponents_Common_pb';
import type { VerifiedListItem } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { DisplayType } from '@/API/Models/Wilqo_API_Mortgage_Models_pb';
import { useAuth } from '@/Routes/Auth/AppAuthContext';
import { ExtendedStatusEnum } from '@/Utils/Helpers/getUxStatus';
import { convertByType } from '@/Utils/Helpers/numberFormatter';
import { renderValue } from '@/Utils/Helpers/renderableHelper';
import { cn } from '@/Utils/Helpers/uiBuilders';
import { useWindow } from '@/Utils/Helpers/useWindow';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { Button } from './Button';
import { Typography } from './Typography';

export interface InfoCardProps {
  caption: string;
  value?: any;
  type?: DisplayType;
  items?: Array<Partial<VerifiedListItem.AsObject>>; // Existing static pages
  itemsList?: Array<{ // Dynamic Pages
    caption?: string;
    value?: any;
    hyperlinksList?: PanelHyperlinkElement.AsObject[];
  }>;
  variant?: ExtendedStatusEnum;
  isMobile?: boolean;
}

const RenderCaptionValue = (value: any, legacyType?: DisplayType) => {
  if (['string', 'number'].includes(typeof value)) { // Legacy static page implementation w string value and DisplayType
    return convertByType(value, legacyType);
  }

  // Else it's a dynamic page value: RenderableValue
  if (value?.value === null || value?.value === undefined) {
    return '--';
  }
  return renderValue(value);
};

const breakStringIfLong = (str?: string) => {
  if (!str || str.length <= 18) {
    return str;
  }

  const regex = /(\s|\/)/;
  const parts = str.split(regex);

  let result = '';
  let currentLength = 0;

  parts.forEach((part) => {
    if (currentLength + part.length > 18 && result.length > 0) {
      result += '\n';
      currentLength = 0;
    }
    result += part;
    currentLength += part.length;
  });

  return result;
};

const InfoCard = (props: InfoCardProps) => {
  const { openHyperLink } = useShared();
  const {
    items = [],
    itemsList = [],
    value,
    caption,
    variant = ExtendedStatusEnum.DEFAULT,
    type = DisplayType.DISPLAY_TYPE_DEFAULT,
    isMobile: mobile,
  } = props;

  const { isMobile } = useWindow();
  const { isConsumer } = useAuth();

  const responsive = mobile || isMobile;

  const handleOpenLink = (hyperLink: PanelHyperlinkElement.AsObject) => {
    openHyperLink({
      ...hyperLink,
      hyperlinkTarget: HyperLinkTarget.HYPER_LINK_TARGET_BLANK,
      id: hyperLink.keyword,
      type: hyperLink.type as unknown as HyperLinkType,
    });
  };

  const mainValue = breakStringIfLong(RenderCaptionValue(value, type)) ?? '';

  return (
    <div
      className={clsx(
        'px-4 py-2 flex flex-col gap-1 w-full rounded-md desktop:min-w-[210px] h-auto',
        {
          'bg-status-completed': variant === ExtendedStatusEnum.COMPLETE,
          'bg-status-danger': ExtendedStatusEnum.DANGER === variant,
          'bg-status-inprogress': ExtendedStatusEnum.PROGRESS === variant,
          'bg-status-new': ExtendedStatusEnum.NEW === variant,
          'bg-status-success': ExtendedStatusEnum.SUCCESS === variant,
          'bg-status-warning': ExtendedStatusEnum.WARNING === variant,
          'bg-surface': ExtendedStatusEnum.NEUTRAL === variant || ([ExtendedStatusEnum.DEFAULT, ExtendedStatusEnum.RESTING].includes(variant) && isConsumer),
          'bg-surface-variant': ExtendedStatusEnum.RESTING === variant && !isConsumer,
          'tablet:w-fit desktop:min-h-[100px]': !responsive,
        },
      )}
    >
      <div className={cn('flex flex-row justify-between items-center', {
        'flex-col items-start': !responsive,
      })}
      >
        <Typography className="tablet:mb-1 block text-status-on-status" variant="overline">{caption}</Typography>
        <Typography
          className={cn({ 'w-min': mainValue.length >= 18 })}
          variant={responsive ? 'body1Bold' : 'display5'}
        >
          {mainValue}
        </Typography>
      </div>
      {
        items.length > 0 && items.map((item) => (
          <div
            key={`${item.id}-${item.caption}`}
            className="flex flex-row justify-between gap-x-2"
          >
            <Typography className="text-on-surface-inactive" variant={responsive ? 'overline' : 'caption'}>{item.caption}</Typography>
            <Typography className="text-on-surface-inactive" variant="captionBold">
              {convertByType(item?.value, item.type)}
            </Typography>
          </div>
        ))
      }
      {
        itemsList.length > 0 && itemsList.map((item) => (
          <div
            key={item.caption}
            className="flex flex-row justify-between gap-x-2"
          >
            {(item.hyperlinksList?.length || 0) > 0 ? item.hyperlinksList?.map((hyperLink) => (
              <Button
                key={`${hyperLink.id}-${hyperLink.label}`}
                label={hyperLink.label}
                onClick={() => handleOpenLink(hyperLink)}
                variant="tertiary"
              />
            )) : (
              <>
                <Typography className="text-on-surface-inactive" variant={responsive ? 'overline' : 'caption'}>{item.caption}</Typography>
                <Typography className="text-on-surface-inactive" variant="captionBold">{RenderCaptionValue(item.value, undefined)}</Typography>
              </>
            )}
          </div>
        ))
      }
    </div>
  );
};

export { InfoCard };
