import { useMemo } from 'react';

import type { PanelElementOption } from '@/API/Models/Wilqo.Shared.Models/ActivityModels_pb';
import { useCreateLoanFeeTemplate } from '@/API/Queries/mortgageConfig/useCreateLoanFeeTemplate';
import { FullPageStepper } from '@/Components/Atoms/FullPageStepper';
import type { DynamicDataElementValues } from '@/Components/Features/dynamicForm/DynamicFormContext';
import { useNavigate } from '@/Routes/NavigationContext';
import { getPanel, getPanelElement, getPanelElementRequiment } from '@/Utils/Helpers/getPanelElement';
import { useShared } from '@/Utils/Hooks/useShared/useShared';

import { defaultFeePaymentTimingTypeOptionList, getFeeEnumPanelElementOptionList } from './FeeTemplateHelper';

interface Props {
  onClose: () => void;
}

const CreateFeeTemplate = (props: Props) => {
  const { onClose } = props;
  const navigate = useNavigate();

  const { isLoading, mutate: createFeeTemplate } = useCreateLoanFeeTemplate();
  const { showSnackBar } = useShared();

  const FEE_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => getFeeEnumPanelElementOptionList(), []);

  const DEFAULT_FEE_PAYMENT_TIMING_TYPE_OPTIONS: Array<PanelElementOption.AsObject> = useMemo(() => defaultFeePaymentTimingTypeOptionList, []);

  const handleSubmit = (formData: DynamicDataElementValues) => {
    createFeeTemplate({
      defaultFeePaymentTimingType: formData.defaultFeePaymentTimingType?.value.id,
      description: formData.description?.value,
      name: formData.name?.value,
      type: formData.type?.value.id,
    }, {
      onError: () => {
        showSnackBar({ message: 'Something wrong happened, try again later.', open: true });
      },
      onSuccess: (data) => {
        showSnackBar({ message: 'Loan fee template successfully created', open: true });
        if (data) {
          navigate(`/admin/loanFeeTemplate/details?feeTemplateId=${data.id}`);
        } else {
          onClose();
        }
      },
    });
  };

  return (
    <FullPageStepper
      close={onClose}
      handleSubmit={handleSubmit}
      headerAction={{
        label: 'Discard',
        leftIcon: 'Delete',
        onClick: onClose,
        variant: 'tertiary',
      }}
      openModal
      panels={[
        getPanel({
          headerText: 'Fee Template Details',
          id: 'loan-fee-template',
          panelElementsList: [
            getPanelElement({
              headerText: 'Internal Fee Name',
              id: 'name',
              order: 1,
              requirement: getPanelElementRequiment('Internal Fee Name is required'),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'External Name',
              id: 'description',
              order: 2,
              requirement: getPanelElementRequiment('External Name is required'),
              type: 'text',
            }),
            getPanelElement({
              headerText: 'Fee Type',
              id: 'type',
              optionsList: FEE_TYPE_OPTIONS,
              order: 3,
              requirement: getPanelElementRequiment('Fee Type is required'),
              type: 'select',
            }),
            getPanelElement({
              headerText: 'Default fee payment timing type',
              id: 'defaultFeePaymentTimingType',
              optionsList: DEFAULT_FEE_PAYMENT_TIMING_TYPE_OPTIONS,
              order: 8,
              requirement: getPanelElementRequiment('Default fee payment timing type is required'),
              type: 'select',
            }),
          ],
          summaryText: 'Create a new fee template',
        }),
      ]}
      saving={isLoading}
      title="Create a new Fee Template"
    />
  );
};

export { CreateFeeTemplate };
