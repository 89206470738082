interface IconProps {
  className?: string;
}

const RequestQuote = ({ className }: IconProps) => (
  <svg
    className={className}
    fill="currentColor"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M11 19h2v-1h1q.425 0 .713-.288Q15 17.425 15 17v-3q0-.425-.287-.713Q14.425 13 14 13h-3v-1h4v-2h-2V9h-2v1h-1q-.425 0-.712.287Q9 10.575 9 11v3q0 .425.288.712Q9.575 15 10 15h3v1H9v2h2Zm-5 3q-.825 0-1.412-.587Q4 20.825 4 20V4q0-.825.588-1.413Q5.175 2 6 2h8l6 6v12q0 .825-.587 1.413Q18.825 22 18 22Zm7-14V4H6v16h12V8ZM6 4v4-4 16V4Z" />
  </svg>
);

export default RequestQuote;
