import type { MessagePanelWidgetSettings } from '@/API/Models/Wilqo.API.Mortgage.DynamicData/LoanPage/Widgets/MessagePanel_pb';
import { Typography } from '@/Components/Atoms/Typography';

export const MessageWidget = (props: MessagePanelWidgetSettings.AsObject) => {
  const { description, messagesList, title } = props;
  return (
    <div className="flex flex-col gap-y-1 w-full pb-4 border-b border-b-on-surface-stroke">
      <Typography
        className="mb-4"
        variant="display5"
      >
        {title}
      </Typography>
      {description && (
        <Typography
          className="mb-2"
          variant="subtitle1"
        >
          {description}
        </Typography>
      )}
      {(messagesList || []).map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ul key={`message-${index}`} className="list-disc pl-6">
          <li>
            <Typography
              key={message}
              variant="body2"
            >
              {message}
            </Typography>
          </li>
        </ul>
      ))}
    </div>
  );
};
