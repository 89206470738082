import clsx from 'clsx';

import { usePropsState } from '@/Utils/Hooks/usePropsState';

import type { CardHeaderProps } from './CardHeader';
import { CardHeader } from './CardHeader';

export type CardVariant = 'border' | 'borderless';

export type CardHeaderType = Omit<CardHeaderProps, 'close' | 'disableAccordion' | 'open' | 'variant'> & { open?: boolean };
export interface CardProps {
  headerProps?: CardHeaderType;
  children: React.ReactNode;
  variant: CardVariant;
  defaultOpen?: boolean;
  disableAccordion?: boolean;
  animation?: string;
}

const Card = (props: CardProps) => {
  const {
    animation,
    children,
    defaultOpen = false,
    disableAccordion = false,
    headerProps,
    variant,
  } = props;

  const [isOpen, setIsOpen] = usePropsState<boolean>(headerProps?.open, defaultOpen || disableAccordion);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={clsx(
      'rounded-sm',
      { 'border border-on-surface-stroke': variant === 'border' },
    )}
    >
      {headerProps && (
        <CardHeader
          {...headerProps}
          animation={animation}
          close={toggleOpen}
          disableAccordion={disableAccordion}
          open={isOpen || false}
        />
      )}

      {isOpen && (
        <div className="bg-surface w-full">
          {children}
        </div>
      )}
    </div>
  );
};

export { Card };
